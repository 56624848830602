import './App.css';

import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { Helmet } from 'react-helmet';
import { useCredential } from './contexts/credential';
import { useGlobalMessage } from './contexts/globalMessage';

import { GlobalMessage } from './components/organisms/GlobalMessage';

import { URCLASS_PAGE_ROUTES } from './util/route';
import RouteMiddleware from './util/RouteMiddleware';

import AttendanceLoginAlert from './domain/lms/components/attendanceLoginAlert';
import AttendanceChecker from './domain/lms/components/attendanceChecker';
import QrAttendanceChecker from './domain/lms/components/qrAttendanceChecker';
import { useAttendanceStatus } from './domain/lms/contexts/AttendanceStatus';
import { ZkStatus, QrStatus } from './domain/lms/apollo/attendance/type';

const UrclassApp: React.FC = () => {
  const { isLogin, isRequiredTermsAgreements } = useCredential();
  const { zkType, qrStatus } = useAttendanceStatus();
  const { message, notificationVisible, hideGlobalMessage } = useGlobalMessage();

  const history = useHistory();

  if ('Notification' in window) {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }
  if (isLogin && !isRequiredTermsAgreements && window.location.pathname !== '/term') {
    history.push('/term');
  }
  return (
    <>
      <RouteMiddleware />
      <Helmet>
        <title>유어클래스 | 코드스테이츠 학습 플랫폼 UrClass</title>
        <link rel="canonical" href="https://urclass.codestates.com" />
        <meta
          name="description"
          content="유어클래스는 코드스테이츠 수강생을 위한 학습 플랫폼입니다. 수강생들이 자유롭게 학습과 성장에 대한 경험을 공유하는 매거진과 수강생 전용 강의 자료가 모여있는 공간입니다."
        />
        <meta property="og:title" content="유어클래스 | 코드스테이츠 학습 플랫폼 UrClass" />
        <meta
          property="og:description"
          content="유어클래스는 코드스테이츠 수강생을 위한 학습 플랫폼입니다. 수강생들이 자유롭게 학습과 성장에 대한 경험을 공유하는 매거진과 수강생 전용 강의 자료가 모여있는 공간입니다."
        />
        <meta
          name="og:keyword"
          content="코드스테이츠, codestates, 유어클래스, urclass, 코드스테이츠매거진, 코드스테이츠후기, 코드스테이츠강의플랫폼, 코드스테이츠학습플랫폼, LMS"
        />
        <meta property="og:site_name" content="코드스테이츠" />
        <meta property="og:locale" content="ko_KR" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urclass.codestates.com" />
        <meta
          property="og:image"
          content="https://codestates-photo.s3.ap-northeast-2.amazonaws.com/codestates_logo_thumbnail.jpg"
        />
        <meta property="twitter:title" content="유어클래스 | 코드스테이츠 학습 플랫폼 UrClass" />
        <meta
          property="twitter:description"
          content="유어클래스는 코드스테이츠 수강생을 위한 학습 플랫폼입니다. 수강생들이 자유롭게 학습과 성장에 대한 경험을 공유하는 매거진과 수강생 전용 강의 자료가 모여있는 공간입니다."
        />
      </Helmet>
      <Switch>
        {URCLASS_PAGE_ROUTES.map(({ pathname, exact, component, redirect_component, access }) =>
          access.requireAuth ? (
            <Route exact={exact} path={pathname} component={isLogin ? component : redirect_component} key={pathname} />
          ) : (
            <Route exact={exact} path={pathname} component={component} key={pathname} />
          )
        )}
      </Switch>
      <Modal width="334px" footer={false} visible={notificationVisible} onCancel={hideGlobalMessage}>
        <GlobalMessage message={message} onOk={hideGlobalMessage} />
      </Modal>
      <AttendanceLoginAlert visible={zkType === ZkStatus.ACTIVE && isLogin} />
      <AttendanceChecker visible={zkType !== ZkStatus.NONE && isLogin} />
      <QrAttendanceChecker visible={qrStatus !== QrStatus.NONE && isLogin} />
    </>
  );
};

export default UrclassApp;
