import { useCredential } from './../../../../contexts/credential';
import { useAttendanceStatus } from '../../contexts/AttendanceStatus';
import { useState, useEffect } from 'react';
import { ZkStatus } from '../../apollo/attendance/type';

export const useController = () => {
  const { isLogin } = useCredential();
  const {
    zkType,
    isZkBtnClick,
    isCheckInZkPopupOpen,
    isCheckOutZkPopupOpen,
    isUserCheckIn,
    isZkErr,
    fetchMyLearningStatus,
    handleCheckIn,
    setIsCheckOutZkPopupOpen,
    setIsDataErr,
    setIsZkBtnClick,
    setIsZkSwitch,
    resetErr
  } = useAttendanceStatus();

  const [isHistoryPopup, setIsHistoryPopup] = useState<boolean>(false);

  // handler
  const handleClickZkBtn = () => {
    setIsZkBtnClick(!isZkBtnClick);
    fetchMyLearningStatus();
    if (isCheckOutZkPopupOpen) {
      //? 퇴실 확인 팝업이 열려 있을 때, 째깍이를 클릭 했을 경우
      setIsCheckOutZkPopupOpen(false);
      return;
    }
    if (isHistoryPopup) {
      //? 출결 기록 팝업이 열려 있을 때, 째깍이를 클릭 했을 경우
      setIsHistoryPopup(false);
      return;
    }
  };
  const handleClickZkBackBtn = () => {
    setIsHistoryPopup(false);
  };
  const handleClickZkCloseBtn = () => {
    if (isZkErr) {
      resetErr();
    }
    setIsZkBtnClick(false);
    setIsHistoryPopup(false);
  };
  const handleToggleSwitch = () => {
    if (zkType === ZkStatus.ACTIVE && !isUserCheckIn) {
      //? 퇴실 상태에서 스위치 동작 할 경우
      handleCheckIn();
      setIsZkSwitch(true);
    } else if (zkType === ZkStatus.ACTIVE && isUserCheckIn) {
      //? 입실 상태에서 스위치 동작 할 경우
      setIsZkSwitch(false);
      setTimeout(() => {
        setIsCheckOutZkPopupOpen(true);
      }, 500);
    }
  };
  const handleClickHistoryPopup = () => {
    setIsHistoryPopup(true);
  };

  const isMainViewOpen = isZkBtnClick && !isHistoryPopup && !isCheckInZkPopupOpen && !isCheckOutZkPopupOpen && !isZkErr;

  useEffect(() => {
    try {
      if (isLogin) {
        fetchMyLearningStatus();
      }
    } catch {
      setIsDataErr(true);
    }
  }, [isLogin]);

  return {
    isMainViewOpen,
    isZkBtnClick,
    isHistoryPopup,
    handleClickZkBtn,
    handleClickZkCloseBtn,
    handleClickZkBackBtn,
    handleClickHistoryPopup,
    handleToggleSwitch
  };
};
