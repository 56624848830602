import '@react-pdf-viewer/core/lib/styles/index.css';

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Modal } from 'antd';

import { useGlobalMessage } from '../../contexts/globalMessage';
import { GeneralTemplate } from '../../templates';
import { GeneralHeader } from '../../components/organisms/Header';
import { Typography, TypographyType } from '../../components/atoms/Typography';

import { ColorPalette, ColorPaletteType } from '../../styles/color';
import { isMobile } from '../../util/checkDevice';
import { CertType } from '../../util/enums/CertType';

import { EduCertificateComp } from './components/EduCertificate';
import { AttendanceCertificateComp } from './components/AttendanceCertificate';

const StyledCertificateDownloadPage = styled.div`
  .btn-download {
    position: absolute;
    top: 10%;
    left: 90%;
  }
`;

const StyledErrorModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .message-error {
    margin-top: 15px;
    text-align: center;
    max-width: 200px;
  }
  .btn {
    &:hover {
      cursor: pointer;
    }
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 300px;
    height: 52px;
    background-color: ${ColorPalette.Gray1};
  }
`;

export const CertificateDownloadPage = () => {
  const history = useHistory();
  const { displayGlobalMessage } = useGlobalMessage();

  const searchParams = new URLSearchParams(history.location.search);

  const cohortUuid = searchParams.get('cohort_uuid') || '';
  const certType = searchParams.get('cert_type') || '';

  const isMobileDevice = isMobile();

  // uuid가 없거나 휴대폰이면 조회 조차 하지 않음.

  useEffect(() => {
    if (!cohortUuid) {
      // cohort uuid를 달지 않고 옴
      displayGlobalMessage('잘못 된 접근 입니다.');
      history.push('/mypage/certificates');
      return;
    }

    if (isMobileDevice) {
      // 휴대폰으로 접근
      displayGlobalMessage('증명서는 PC 환경에서만 발급 가능합니다. PC에서 다시 시도해주세요!');
      history.push('/mypage/certificates');
    }
  }, [cohortUuid, isMobileDevice]);

  const renderCertification = () => {
    switch (certType) {
      case CertType.EDU_CERT:
        return <EduCertificateComp />;
      case CertType.ATTENDANCE_URCLASS_CERT:
        return <AttendanceCertificateComp certificateType={certType} />;
      case CertType.ATTENDANCE_HRD_CERT:
        return <AttendanceCertificateComp certificateType={certType} />;
      default:
        return <span>지원하지 않는 증명서 양식 입니다.</span>;
    }
  };

  return (
    <GeneralTemplate>
      {{
        header: <GeneralHeader />,
        body: <StyledCertificateDownloadPage>{renderCertification()}</StyledCertificateDownloadPage>
      }}
    </GeneralTemplate>
  );
};

export default CertificateDownloadPage;
