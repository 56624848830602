import moment from 'moment';
import 'moment/locale/ko';

import { StatusType } from '../../../components/molecules/Button/type';
import type { Application, ApplicationProduct } from '../../../apollo/mypage/type';
import { ApplyingStatus, ApplyServiceType, BoardingStatus, EvaluationStatus } from '../../../apollo/mypage/type';

import { ColorPaletteType } from '../../../styles/color';

import { ApplicationFullResponse } from '../../../rest/generated';
import { ApplicationAnswerStatusType } from '../../detailApplication/type';
import { ROLLING_BASE_PRODUCT_SLUG_URLS } from '../../../util/constant';

enum ApplicantStatus {
  UNKNOWN = 'unknown', // 지원 절차가 없어, 판단 불가
  PROCEEDING = 'proceeding', // 지원 진행중
  COMPLETED = 'completed', // 지원 완료
  NOT_COMPLETED = 'not-completed' // 지원 미완료
}

interface ICardAndButtonStyleState {
  cardStatus: 'active' | 'inactive';
  buttonText: string;
  buttonTextColor: ColorPaletteType;
  buttonStatus: StatusType;
}

const PMB_SIXTH_COHORT_PRODUCT_ID = 102;

export default class ApplicationCardViewModel {
  constructor(application: Application, admissionApplicationData?: ApplicationFullResponse) {
    this._application = application;
    this._admissionApplicationData = admissionApplicationData || undefined;
    this._product = application.product;
  }

  private _application: Application;
  private _product: ApplicationProduct;
  private _admissionApplicationData?: ApplicationFullResponse;
  // https://codestates.atlassian.net/browse/LMS-89 참고

  // 1-1. 상단 분류 문구
  public get topPhrase(): string {
    // 지원서가 취소/보류/하차 상태인 경우에는 그 경우에 따라 변경됩니다.
    if (this._application.applyingStatus === ApplyingStatus.APPLYING) {
      return this.isCDSProgram ? '진행 중' : '지원 중';
    }

    if (this._application.applyingStatus === ApplyingStatus.CANCELLED) {
      return this.isCDSProgram ? '제출 취소' : '지원취소';
    }

    if (this._application.applyingStatus === ApplyingStatus.UNSUBMITTED) {
      return '지원 미완료';
    }

    if (this._application.boardingStatus === BoardingStatus.CANCEL) {
      return '수강 취소';
    }

    if (this._application.boardingStatus === BoardingStatus.GET_OFF) {
      return '하차';
    }

    // 지원서가 대기/합격/탈락 상태일 경우에만 지원자 상태에 따라 변경됩니다.
    switch (this.applicantStatus) {
      case ApplicantStatus.PROCEEDING:
        return this.isCDSProgram ? '진행 중' : '지원 중';
      case ApplicantStatus.NOT_COMPLETED:
        return this.isCDSProgram ? '제출 미완료' : '지원 미완료';
      case ApplicantStatus.COMPLETED:
        /*
          A. 결과 발표 이후
          */
        if (this._application.boardingStatus === BoardingStatus.BOARDED) {
          if (
            // A-1. 수강 중인 경우
            this.isCourseOngoing
          ) {
            return '수강중';
          } else if (
            // A-2. 수강기간이 끝난 경우
            new Date() > this._product.serviceEndDate
          ) {
            return '수강완료';
          } else {
            // A-3. 수강 이전의 경우
            return this.isCDSProgram ? '제출 완료' : '지원완료';
          }
        } else {
          return this.isCDSProgram ? '제출 완료' : '지원완료';
        }
      case ApplicantStatus.UNKNOWN:
      default:
        /*
          A. 결과 발표 이후
          */
        if (this._application.boardingStatus === BoardingStatus.BOARDED) {
          if (
            // A-1. 수강 중인 경우
            this.isCourseOngoing
          ) {
            return '수강중';
          } else if (
            // A-2. 수강기간이 끝난 경우
            new Date() > this._product.serviceEndDate
          ) {
            return '수강완료';
          } else {
            // A-3. 수강 이전의 경우
            return this.isCDSProgram ? '제출 완료' : '지원완료';
          }
          /*
          B. 합격이 아닌 경우
        */
        } else if (this._application.evaluationStatus === EvaluationStatus.FAILED) {
          return this.isCDSProgram ? '제출 완료' : '지원완료';
        } else {
          return this.isCDSProgram ? '진행 중' : '지원중';
        }
    }
  }

  // 1-2. 상품(코스)명
  public get productName(): string {
    return this._product.name;
  }

  // 1-3. 상품(코스) 서비스 기간 안내
  public get productServiceGuide(): string {
    const { serviceStartDate, serviceEndDate, weekDuration } = this._product;

    if (this.isCDSProgram) {
      return '';
    }

    const displayStartDate = moment(serviceStartDate).format('YYYY.MM.DD');
    const displayEndDate = moment(serviceEndDate).format('YYYY.MM.DD');

    const realDuration = moment(serviceEndDate).diff(serviceStartDate, 'weeks');

    if (this.isLevel1VODProgram) {
      return `수강 가능 기간 | ${displayStartDate} - ${displayEndDate}`;
    }

    return `${weekDuration ? weekDuration : realDuration}주 과정 | ${displayStartDate} - ${displayEndDate}`;
  }

  // 1-4. 합격자 발표 날짜 & 롤링베이스 지원 상품 여부 구분
  public get productAnnouncementDate(): string {
    const announcementDate = this._product.announcementDate;

    const date = moment(announcementDate).locale('ko').format('L').slice(5, -1);
    const dayOfWeek = moment(announcementDate).locale('ko').format('dd');
    const time = moment(announcementDate).format('HH:mm');

    return `${date} (${dayOfWeek}) ${time}`;
  }

  public get isRollingBaseCourse(): boolean {
    return ROLLING_BASE_PRODUCT_SLUG_URLS.includes(this._admissionApplicationData?.template.slugUrl || '');
  }

  public get isCDSProgram(): boolean {
    return this._application.product.programId === 33;
  }

  public get isResumeUploadProgram(): boolean {
    return this._application.product.programId === 37;
  }

  public get isLevel1VODProgram(): boolean {
    return this._application.product.programId === 35;
  }

  // 1-5. 결제 모델
  public get paymentType(): string {
    return this._application.paymentType;
  }

  // 1-6. 날짜 안내
  public get dateGuide(): string {
    // 지원서가 취소/보류/하차 상태인 경우에는 표현하지 않습니다.
    if (
      this._application.applyingStatus === ApplyingStatus.CANCELLED ||
      this._application.applyingStatus === ApplyingStatus.UNSUBMITTED ||
      this._application.boardingStatus === BoardingStatus.CANCEL ||
      this._application.boardingStatus === BoardingStatus.GET_OFF
    ) {
      return ' ';
    }

    // 지원서가 대기/합격/탈락 상태일 경우에만 지원자 상태에 따라 변경됩니다.
    switch (this.applicantStatus) {
      case ApplicantStatus.PROCEEDING:
        return `D-${moment(this.productApplyEndDate).diff(new Date(), 'days').toString()}`;
      case ApplicantStatus.NOT_COMPLETED:
        return ' ';
      case ApplicantStatus.COMPLETED:
        if (
          // 합격 발표 이후
          this._application.evaluationStatus === EvaluationStatus.PASSED ||
          this._application.evaluationStatus === EvaluationStatus.FAILED
        ) {
          return ' ';
        } else {
          // 합격 발표 이전
          if (this.productApplyEndDate && new Date() < this.productApplyEndDate) {
            // 1) 지원 마감 이전
            return `D-${moment(this.productApplyEndDate).diff(new Date(), 'days').toString()}`;
          } else {
            // 2) 지원 마감 이후 (지원 마감일이 없는 경우는 표기 안함.)
            return ' ';
          }
        }
      case ApplicantStatus.UNKNOWN:
      default:
        if (
          this._application.evaluationStatus === EvaluationStatus.PASSED ||
          this._application.evaluationStatus === EvaluationStatus.FAILED
        ) {
          return ' ';
        } else {
          // 지원 마감일을 알 수 없으므로, D-day 안내 불가
          return ' ';
        }
    }
  }

  // 1-7. 지원상태 안내 여부
  public get isDisplayProgressStatus(): boolean {
    return (
      this.isSetApplyDate &&
      this.isSetApplicationStep &&
      this.applicantStatus === ApplicantStatus.PROCEEDING &&
      this._application.applyingStatus === ApplyingStatus.APPLYING
    );
  }

  public get numericStepProgrees(): number {
    if (this._application.product.applyServiceType === ApplyServiceType.TYPEFORM) {
      return this.calculateApplicationStepProgress(
        this._application.submissions.length,
        this._application.userApplicationSteps.length
      );
    } else {
      if (
        this._admissionApplicationData &&
        this._admissionApplicationData.template?.items &&
        this._admissionApplicationData?.answers
      ) {
        return this.calculateApplicationStepProgress(
          this._admissionApplicationData.answers.filter(
            (answer: any) => answer.status === ApplicationAnswerStatusType.SUBMITTED
          ).length,
          this._admissionApplicationData.template.items.length
        );
      }
      return 0;
    }
  }

  // 1-1. 카드 아웃라인 상태
  // 1-8. 버튼 상태
  public get cardAndButtonState(): ICardAndButtonStyleState {
    // 지원서가 취소/보류/하차 상태인 경우에는 그 경우에 따라 변경됩니다.
    if (this._application.applyingStatus === ApplyingStatus.CANCELLED) {
      return {
        buttonText: this.isCDSProgram ? '제출이 취소되었습니다.' : '지원이 취소되었습니다.',
        buttonTextColor: ColorPaletteType.GRAY7,
        buttonStatus: StatusType.DISABLED,
        cardStatus: 'inactive'
      };
    }

    if (this._application.applyingStatus === ApplyingStatus.UNSUBMITTED) {
      return {
        buttonText: this.isCDSProgram ? '제출 기간이 지났습니다.' : '지원 기간이 지났습니다.',
        buttonTextColor: ColorPaletteType.GRAY7,
        buttonStatus: StatusType.DISABLED,
        cardStatus: 'inactive'
      };
    }

    if (this._application.boardingStatus === BoardingStatus.GET_OFF) {
      return {
        buttonText: '하차처리 되었습니다.',
        buttonTextColor: ColorPaletteType.GRAY7,
        buttonStatus: StatusType.DISABLED,
        cardStatus: 'inactive'
      };
    }

    switch (this.applicantStatus) {
      case ApplicantStatus.PROCEEDING:
        return {
          buttonText: this.isCDSProgram ? '진행하기' : '지원하기',
          buttonTextColor: ColorPaletteType.WHITE,
          buttonStatus: StatusType.ACTIVE,
          cardStatus: 'active'
        };
      case ApplicantStatus.NOT_COMPLETED:
        return {
          buttonText: this.isCDSProgram ? '제출 기간이 지났습니다.' : '지원 기간이 지났습니다.',
          buttonTextColor: ColorPaletteType.GRAY7,
          buttonStatus: StatusType.DISABLED,
          cardStatus: 'inactive'
        };
      case ApplicantStatus.COMPLETED:
        // 대기의 경우, 지원 완료 표기.
        if (
          this._application.applyingStatus === ApplyingStatus.SUBMITTED &&
          (this._application.evaluationStatus === EvaluationStatus.INITIALIZE ||
            this._application.evaluationStatus === EvaluationStatus.EVALUATING)
        ) {
          return {
            buttonText: this.isCDSProgram ? '제출 완료' : '지원 완료',
            buttonTextColor: ColorPaletteType.GRAY7,
            buttonStatus: StatusType.DISABLED,
            cardStatus: 'inactive'
          };
        }

        // 탈락의 경우, 지원결과 확인을 항상 보여준다.
        if (this._application.evaluationStatus === EvaluationStatus.FAILED) {
          return {
            buttonText: this.isCDSProgram ? '제출 완료' : '지원 완료',
            buttonTextColor: ColorPaletteType.GRAY7,
            buttonStatus: StatusType.DISABLED,
            cardStatus: 'inactive'
          };
        }

        if (this._application.evaluationStatus === EvaluationStatus.PASSED) {
          // 개강 이전
          if (new Date() < new Date(this._product.serviceStartDate)) {
            return {
              buttonText: this.isCDSProgram ? '제출 완료' : this.applicationNeedToPay() ? '결제하기' : '지원 완료',
              buttonTextColor: this.applicationNeedToPay() ? ColorPaletteType.WHITE : ColorPaletteType.GRAY7,
              buttonStatus: this.applicationNeedToPay() ? StatusType.ACTIVE : StatusType.DISABLED,
              cardStatus: this.applicationNeedToPay() ? 'active' : 'inactive'
            };
          } else {
            if (this._application.boardingStatus === BoardingStatus.BOARDED) {
              // 수강 기간
              return {
                buttonText: '수강 페이지 가기',
                buttonTextColor: ColorPaletteType.WHITE,
                buttonStatus: StatusType.ACTIVE,
                cardStatus: 'active'
              };
            }
          }
        }

        return {
          buttonText: this.isCDSProgram ? '제출 완료' : '지원 완료',
          buttonTextColor: ColorPaletteType.GRAY7,
          buttonStatus: StatusType.DISABLED,
          cardStatus: 'inactive'
        };

      case ApplicantStatus.UNKNOWN:
      default:
        if (this._application.boardingStatus === BoardingStatus.BOARDED) {
          // 개강 이전
          if (new Date() < this._product.serviceStartDate) {
            return {
              buttonText: this.isCDSProgram ? '제출 완료' : '지원 완료',
              buttonTextColor: ColorPaletteType.GRAY7,
              buttonStatus: StatusType.DISABLED,
              cardStatus: 'inactive'
            };
          } else {
            // 개강 이후
            return {
              cardStatus: 'active',
              buttonText: '수강 페이지 가기',
              buttonTextColor: ColorPaletteType.BLACK,
              buttonStatus: StatusType.DEFAULT
            };
          }
        }

        if (this._application.evaluationStatus === EvaluationStatus.FAILED) {
          return {
            buttonText: this.isCDSProgram ? '제출 완료' : '지원 완료',
            buttonTextColor: ColorPaletteType.GRAY7,
            buttonStatus: StatusType.DISABLED,
            cardStatus: 'inactive'
          };
        }

        if (new Date() < new Date(this._product.serviceStartDate)) {
          return {
            buttonText: this.isCDSProgram ? '진행하기' : '지원하기',
            buttonTextColor: ColorPaletteType.WHITE,
            buttonStatus: StatusType.ACTIVE,
            cardStatus: 'active'
          };
        } else {
          // 개강 이후
        }
        return {
          buttonText: this.isCDSProgram ? '제출 기간이 지났습니다.' : '지원 기간이 지났습니다.',
          buttonTextColor: ColorPaletteType.GRAY7,
          buttonStatus: StatusType.DISABLED,
          cardStatus: 'inactive'
        };
    }
  }

  // 지원자 상태
  private get applicantStatus(): ApplicantStatus {
    // 지원 마감일/지원절차 가 이 설정되지 않은 경우
    if (!this.isSetApplyDate || !this.isSetApplicationStep) {
      return ApplicantStatus.UNKNOWN;
    }

    // 지원 마감 이후
    if (this.productApplyEndDate! < new Date()) {
      if (!this.isDoneApplicationStep) {
        return ApplicantStatus.NOT_COMPLETED;
      } else {
        return ApplicantStatus.COMPLETED;
      }
      // 지원 마감 이전
    } else {
      if (this._application.applyingStatus === ApplyingStatus.SUBMITTED) {
        return ApplicantStatus.COMPLETED;
      } else if (!this.isDoneApplicationStep) {
        return ApplicantStatus.PROCEEDING;
      } else {
        return ApplicantStatus.COMPLETED;
      }
    }
  }

  // 합격발표일 안내 여부
  private get isBeforeAnnouncementDate(): boolean {
    const year = Number(moment(this._product.announcementDate).format('YYYY'));
    const month = Number(moment(this._product.announcementDate).format('MM'));
    const day = Number(moment(this._product.announcementDate).format('DD'));
    // 지원완료 && 합격발표일 23:59:59 이전 => true
    return (
      this._application.applyingStatus === ApplyingStatus.SUBMITTED &&
      new Date().getTime() < new Date(year, month - 1, day + 1).getTime()
    );
  }
  // 지원일 및 지원 마감일 보정
  private get productApplyStartDate(): Date | undefined {
    return this._product.applyStartDate;
  }

  private get productApplyEndDate(): Date | undefined {
    if (this._product.id === PMB_SIXTH_COHORT_PRODUCT_ID) {
      // Wewin / impact_campus
      if (this.paymentType === 'WEWIN' || this.paymentType === 'IMPACT_CAMPUS') {
        // (KST) 4월 20일 18:00 분
        return new Date(2021, 3, 20, 18);
      }

      // Upfront
      // (KST) 21일 18:00 분
      return new Date(2021, 3, 21, 18);
    }

    return this._product.applyEndDate;
  }

  public get hasViewMoreButtonDropdown(): boolean {
    const applyingOrSubmitted =
      this._application.applyingStatus === ApplyingStatus.APPLYING ||
      this._application.applyingStatus === ApplyingStatus.SUBMITTED;
    const passedOrWaiting =
      this._admissionApplicationData?.evaluationStatus === 'PASSED' ||
      this._admissionApplicationData?.evaluationStatus === 'WAITING';
    const isRollingBaseProduct = ROLLING_BASE_PRODUCT_SLUG_URLS.includes(
      this._admissionApplicationData?.template.slugUrl || ''
    );

    return applyingOrSubmitted && (!passedOrWaiting || !isRollingBaseProduct);
  }

  public get canApplicationCancelled(): boolean {
    if (this._product.applyEndDate && this._product.applyEndDate > new Date()) return false;
    // 지원 기간 지났으면 안됨.
    // 지원 기간 안지났으면,
    // 이미 지원 취소면 안됨.
    // 지원 완료인데 불합격이면 안됨.
    if (this._application.applyingStatus === ApplyingStatus.CANCELLED) return false;
    if ([EvaluationStatus.RESIGNED, EvaluationStatus.FAILED].includes(this._application.evaluationStatus)) return false;
    return true;
  }

  public get hasViewMoreButtonForEdit(): boolean {
    return (
      this._application.applyingStatus === ApplyingStatus.SUBMITTED &&
      !ROLLING_BASE_PRODUCT_SLUG_URLS.includes(this._admissionApplicationData?.template.slugUrl || '')
    );
  }

  // 지원 가능 일자 설정 여부
  private get isSetApplyDate(): boolean {
    return !!this._product?.applyStartDate && !!this._product?.applyEndDate;
  }

  // 지원 절차 설정 여부
  private get isSetApplicationStep(): boolean {
    if (
      this._application.product.applyServiceType === ApplyServiceType.TYPEFORM &&
      !this._application.userApplicationSteps.length
    ) {
      return false;
    }

    if (
      this._application.product.applyServiceType === ApplyServiceType.ADMISSION &&
      !this._admissionApplicationData?.template?.items?.length
    ) {
      return false;
    }

    return true;
  }

  // 지원 완료 여부
  private get isDoneApplicationStep(): boolean {
    if (this._application.product.applyServiceType === ApplyServiceType.TYPEFORM)
      return (
        this._application.submissions.length === this._application.userApplicationSteps.length &&
        this.isSetApplicationStep
      );
    if (this._application.product.applyServiceType === ApplyServiceType.ADMISSION) {
      if (
        this._admissionApplicationData &&
        this._admissionApplicationData?.answers &&
        this._admissionApplicationData?.template
      )
        return (
          this._admissionApplicationData?.answers.filter(
            (answer: any) => answer.status === ApplicationAnswerStatusType.SUBMITTED
          ).length === this._admissionApplicationData?.template.items?.length && this.isSetApplicationStep
        );
      else return false;
    } else return false;
  }

  // 수강 기간 여부
  private get isCourseOngoing(): boolean {
    return new Date() >= this._product.serviceStartDate && new Date() <= this._product.serviceEndDate;
  }

  //
  private calculateApplicationStepProgress = (done: number, total: number): number => {
    const ceiledNumber = Math.ceil(((done / total) * 100) / 10) * 10;

    if (isNaN(ceiledNumber)) {
      return -1;
    }

    if (done !== total && ceiledNumber === 100) {
      return 90;
    } else {
      return ceiledNumber;
    }
  };

  private applicationNeedToPay(): boolean {
    return this._application.needToPay;
  }
}
