import React from 'react';
import styled from '@emotion/styled';
import { Route } from 'react-router-dom';

import { GeneralTemplate } from '../../../../templates';
import { useController } from './controller';
import { PlaylistCardGroup } from '../../components/playlist/PlaylistCardGroup';
import BreadCrumbIco from '../../assets/svg/ico-breadcrumb.svg';

const PlaylistsPageWrapper = styled.div`
  .playlist-header {
    background-image: url('https://codestates-urclass.s3.ap-northeast-2.amazonaws.com/common/banner_dark.png');
    height: 300px;
    object-fit: cover;
    background-size: cover;
    position: absolute;
    top: 65px;
    right: 0px;
    left: 0px;

    .title {
      font-size: 48px;
      font-weight: bold;
      color: #ffffff;
      margin-top: 115px;
      text-align: center;
    }
  }
  .playlist-breadcrumb-wrapper {
    display: block;
    position: absolute;
    background-color: #f5f7f9;
    top: 365px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px 0;
    height: 44px;
    .playlist-breadcrumb {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 8px;

      .playlist-breadcrumb-txt {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        color: #455573;
        height: 24px;
        line-height: 24px;
        vertical-align: middle;
        &:after {
          content: url(${BreadCrumbIco});
          display: inline-block;
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin: 0 5px 2px;
        }
        :last-child {
          &:after {
            content: '';
          }
        }
      }
    }
  }

  .playlist-content {
    margin-top: 410px;
    .content {
      text-align: center;
    }
  }
`;

const PlaylistsPage = () => {
  const { loading, error, name, playlists } = useController();

  return (
    <GeneralTemplate loading={loading} error={!!error}>
      {{
        body: (
          <PlaylistsPageWrapper>
            <div className="playlist-header">
              <div className="title">{name}</div>
            </div>
            <div className="playlist-breadcrumb-wrapper">
              <div className="playlist-breadcrumb">
                <a href="/courses/active" className="playlist-breadcrumb-txt">
                  수강 중인 코스
                </a>
                <span className="playlist-breadcrumb-txt">{name}</span>
              </div>
            </div>
            <div className="playlist-content">
              <div className="content">
                <Route path={'/course/:id/curriculum'} render={() => <PlaylistCardGroup playlists={playlists} />} />
              </div>
            </div>
          </PlaylistsPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default PlaylistsPage;
