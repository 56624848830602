import gql from 'graphql-tag';

export const PRODUCT_GQL = gql`
  query product($uuid: String!) {
    urclassProduct(uuid: $uuid) {
      id
      uuid
      name
      description
      applyServiceType
      weekDuration
      program {
        id
        title
        subTitle
        description
      }
      paymentModels {
        id
        uuid
        paymentType
        name
        description
        price
        originPrice
        sharingRate
      }
      serviceStartDate
      serviceEndDate
      useApplicationSteps
    }
  }
`;

export const PRODUCT_BUNDLE_GQL = gql`
  query productBundle($uuid: String!) {
    urclassProductBundle(uuid: $uuid) {
      id
      uuid
      name
      products {
        id
        uuid
        name
        description
        applyServiceType
        weekDuration
        program {
          id
          title
          subTitle
          description
        }
        paymentModels {
          id
          uuid
          paymentType
          name
          description
          price
          originPrice
          sharingRate
        }
        serviceStartDate
        serviceEndDate
        useApplicationSteps
      }
    }
  }
`;
