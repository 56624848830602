import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as ProfileIcon } from '../../assets/svg/ico-profile.svg';
import { ReactComponent as AdminIcon } from '../../assets/svg/ico-admin.svg';
import { ReactComponent as LogoutIcon } from '../../assets/svg/ico-logout.svg';
import { ReactComponent as MypageIcon } from '../../assets/svg/ico-mypage.svg';
import { ReactComponent as HelpdeskIcon } from '../../assets/svg/ico-helpdesk.svg';

import Urls from '../../util/urls';

const HeaderAvatarWrapper = styled.div`
  fill: #455573;
  display: inline-block;
  cursor: pointer;
  margin: 5px 0 0 15px;
  &:hover {
    fill: #262f40;
    .list__menu {
      display: block;
    }
  }
  @media (max-width: 992px) {
    display: none;
  }
  .list__menu {
    position: absolute;
    z-index: 1;
    display: none;
    cursor: auto;
    right: 10px;
    color: #455573;
    background-color: #fff;
    min-width: 200px;
    border-radius: 6px;
    border: 1px solid #dfe3ec;
    :before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: #dfe3ec transparent;
      display: block;
      width: 0;
      z-index: 0;
      top: -11px;
      right: 18px;
    }
    :after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: #fff transparent;
      display: block;
      width: 0;
      z-index: 1;
      top: -9px;
      right: 18px;
    }

    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.18));
    li {
      :first-of-type {
        text-align: center;
        padding: 22px 0;
        color: #262f40;

        &:hover {
          background-color: #fff;
          cursor: default;
        }
      }
      :last-child {
        border-bottom: none;
      }
      border-bottom: 1px solid #dfe3ec;
      padding: 12px 24px;
      font-size: 15px;
      &:hover {
        background-color: rgba(20, 23, 28, 0.05);
        cursor: pointer;
      }
      .txt__header--name {
        color: #452cdd;
      }
      .link__header--menu {
        display: block;
        color: #525c65;
        font-weight: 400;
      }

      .ico__menu--link {
        margin-right: 24px;
        vertical-align: middle;
      }
    }
  }
`;

interface IHeaderAvatar {
  hasCohort: boolean;
  name: string;
  email: string;
  role: string;
  handleLogout: () => void;
}

const HeaderAvatar: React.FC<IHeaderAvatar> = ({ hasCohort, name, role, handleLogout }) => {
  const { admin: adminURL, zendesk: zendeskURL } = Urls();
  return (
    <HeaderAvatarWrapper>
      <ProfileIcon />

      <ul className="list__menu">
        <li>
          <span className="txt__header--name">{name}</span>
          <span>님, 안녕하세요!</span>
        </li>
        {role === 'admin' && (
          <li>
            <a href={adminURL} target="_blank" rel="noopener noreferrer" className="link__header--menu">
              <AdminIcon className="ico__menu--link" />
              <span>어드민페이지</span>
            </a>
          </li>
        )}
        {/*{hasCohort && (*/}
        {/*  <li>*/}
        {/*    <a href={} target="_blank" rel="noopener noreferrer" className="link__header--menu">*/}
        {/*      <HelpdeskIcon className="ico__menu--link" />*/}
        {/*      <span>수강생 헬프센터</span>*/}
        {/*    </a>*/}
        {/*  </li>*/}
        {/*)}*/}
        <li>
          <Link to="/mypage" className="link__header--menu">
            <MypageIcon className="ico__menu--link" />
            <span>마이페이지</span>
          </Link>
        </li>

        <li onClick={handleLogout}>
          <LogoutIcon className="ico__menu--link" />
          <span>로그아웃</span>
        </li>
      </ul>
    </HeaderAvatarWrapper>
  );
};

export default HeaderAvatar;
