import 'github-markdown-css/github-markdown.css';

import React from 'react';
import styled from '@emotion/styled';
import ReactMarkdown, { ReactMarkdownProps } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';

type MarkdownPreviewerProps = {
  markdownBody?: string;
} & ReactMarkdownProps;

const MarkdownPreviewerWrapper = styled.div`
  h1,
  h2,
  h3,
  p {
    color: #262f40;
  }
  ul {
    list-style-type: disc;
    list-style-position: initial;
  }
  ol {
    list-style-type: decimal;
    list-style-position: initial;
  }
  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: initial;
    margin-left: 15px;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: initial;
    margin-left: 15px;
  }
  pre {
    background-color: #f2f4f7 !important ;
  }

  white-space: pre-line;
  padding: 5px;
`;

export const MarkdownPreviewer: React.FC<MarkdownPreviewerProps> = props => {
  const CodeRenderer: React.FC<{ language: string; value: string }> = ({ language, value }) => {
    return (
      <SyntaxHighlighter style={prism} language={language}>
        {value}
      </SyntaxHighlighter>
    );
  };

  return (
    <MarkdownPreviewerWrapper>
      <ReactMarkdown
        className="markdown-body"
        linkTarget="_blank"
        escapeHtml={false}
        renderers={{ code: CodeRenderer }}
      >
        {props.markdownBody}
      </ReactMarkdown>
    </MarkdownPreviewerWrapper>
  );
};
