import Switch from 'react-switch';
import styled from '@emotion/styled';
import CheckInIcon from '../../assets/image/attendance/switch-checkin.png';
import CheckOutIcon from '../../assets/image/attendance/switch-checkout.png';

type AttendanceSwitchProps = {
  isZkSwitch: boolean;
  checkInLoading: boolean;
  checkOutLoading?: boolean;
  handleToggleSwitch: () => void;
};
const AttendanceSwitchWrapper = styled.div`
  width: 184px;
  margin: 0 auto;
  overflow: hidden;
  .btn__switch {
    .react-switch-handle {
      background: none !important;
      box-shadow: none !important;
    }
    .txt__switch--checkin {
      position: absolute;
      color: #295ce0;
      width: 184px;
      left: -50px;
      top: 12px;
    }
    .txt__switch--checkout {
      position: absolute;
      color: #455573;
      width: 184px;
      top: 12px;
      left: 24px;
    }
  }
`;

const AttendanceSwitch: React.FC<AttendanceSwitchProps> = ({
  handleToggleSwitch,
  isZkSwitch,
  checkInLoading,
  checkOutLoading
}) => {
  return (
    <AttendanceSwitchWrapper>
      <Switch
        checked={isZkSwitch}
        className="btn__switch"
        onChange={handleToggleSwitch}
        width={184}
        height={48}
        onColor={'#eceef3'}
        offColor={'#f2f5fd'}
        disabled={checkInLoading || checkOutLoading}
        checkedHandleIcon={
          <img
            onClick={handleToggleSwitch}
            src={CheckOutIcon}
            alt="checkout icon"
            style={{ width: '60px', height: '60px', position: 'absolute', top: '-6px', left: '-5px' }}
          />
        }
        uncheckedHandleIcon={
          <img
            onClick={handleToggleSwitch}
            src={CheckInIcon}
            alt="checkin icon"
            style={{ width: '60px', height: '60px', position: 'absolute', top: '-6px', left: '-5px' }}
          />
        }
        checkedIcon={<span className="txt__switch--checkout">째깍이 재워 퇴실</span>}
        uncheckedIcon={<span className="txt__switch--checkin">째깍이 깨워 입실</span>}
      />
    </AttendanceSwitchWrapper>
  );
};

export default AttendanceSwitch;
