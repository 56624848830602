import gql from 'graphql-tag';

export const GET_PLAYLIST_CONTENTS_GQL = gql`
  query playlist($id: Int) {
    playlist(id: $id) {
      id
      uuid
      name
      course {
        id
        uuid
        name
        description
      }
      playlist_content {
        id
        content {
          id
          uuid
          version {
            id
            type
            title
            body
            description
          }
          isPublished
          quizSetId
        }
        isUserCompleted
        order
      }
    }
  }
`;
