import styled from '@emotion/styled';

export const Device = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP'
} as const;
export type DeviceType = typeof Device[keyof typeof Device];

export type BreakPoint = {
  minPx: number;
  maxPx: number | null;
};

export const NewBreakPoints: Record<DeviceType, BreakPoint> = {
  MOBILE: {
    minPx: 0,
    maxPx: 767
  },
  TABLET: {
    minPx: 768,
    maxPx: 1279
  },
  DESKTOP: {
    minPx: 1280,
    maxPx: null
  }
};

export const NewMQ = {
  MOBILE: `@media only screen and (max-width: ${NewBreakPoints.MOBILE.maxPx}px)`,
  TABLET: `@media only screen and (min-width: ${NewBreakPoints.TABLET.minPx}px)`,
  DESKTOP: `@media only screen and (min-width: ${NewBreakPoints.DESKTOP.minPx}px)`
};

export const NewMQContainer: Record<DeviceType, ReturnType<typeof styled.div>> = {
  MOBILE: styled.div`
    display: block;
    ${NewMQ.TABLET} {
      display: none;
    }
  `,
  TABLET: styled.div`
    display: none;
    ${NewMQ.TABLET} {
      display: block;
    }
    ${NewMQ.DESKTOP} {
      display: none;
    }
  `,
  DESKTOP: styled.div`
    display: none;
    ${NewMQ.DESKTOP} {
      display: block;
    }
  `
};

export const generateMQContainer = (devices: DeviceType[]): ReturnType<typeof styled.div> => {
  return styled.div`
    ${NewMQ.MOBILE} {
      display: ${devices.includes('MOBILE') ? 'block' : 'none'};
    }
    ${NewMQ.TABLET} {
      display: ${devices.includes('TABLET') ? 'block' : 'none'};
    }
    ${NewMQ.DESKTOP} {
      display: ${devices.includes('DESKTOP') ? 'block' : 'none'};
    }
  `;
};

export const NewMQContainerForTabletAndDesktop = generateMQContainer([Device.TABLET, Device.DESKTOP]);
export const NewMQContainerForMobileAndTablet = generateMQContainer([Device.MOBILE, Device.TABLET]);
