import React from 'react';
import styled from '@emotion/styled';

type IconLabelProps = {
  title: string | number;
  Icon: React.ReactNode;
  color?: string;
};

const IconLabelWrapper = styled.div<{ color?: string }>`
  color: ${({ color }) => (color ? color : '#aaa')};
  .icon-lable-title {
    margin: 0px 10px;
  }
`;

export const IconLabel: React.FC<IconLabelProps> = ({ title, Icon, color }) => (
  <IconLabelWrapper color={color}>
    {Icon}
    <span className="icon-lable-title">{title}</span>
  </IconLabelWrapper>
);
