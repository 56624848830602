import React from 'react';
import { CreditCardOutlined, ScheduleOutlined } from '@ant-design/icons'
import moment from 'moment';
import { DashboardEntry, GuideMsg } from './style';

import type { Product, PaymentModel } from '../../../apollo/product/type';

interface IProductInfo {
  product: Product;
  paymentModel?: PaymentModel;
}

const ProductInfo: React.FC<IProductInfo> = ({ product, paymentModel }) => {
  return (
    <React.Fragment>
      <DashboardEntry>
        <div className="entry-main-title">{product.name}</div>
        <div className="entry-main-description">{product.description}</div>
      </DashboardEntry>

      <DashboardEntry>
        <div>
          <ScheduleOutlined className="icon" />
          <span className="entry-title">{`${moment(
            product.serviceStartDate
          ).format('YYYY.MM.DD')} - ${moment(product.serviceEndDate).format(
            'YYYY.MM.DD'
          )}`}</span>
        </div>
      </DashboardEntry>

      <DashboardEntry>
        {paymentModel ? (
          <div>
            <CreditCardOutlined className="icon" />
            <span className="entry-title">{paymentModel.name}</span>
          </div>
        ) : (
          <GuideMsg>결제 모델을 선택해 주세요.</GuideMsg>
        )}
      </DashboardEntry>
    </React.Fragment>
  );
};

export default ProductInfo;
