export const ColorPalette = {
  Gray10: '#F7F7F7',
  Gray50: '#F2F2F2',
  Gray100: '#F0F0F0',
  Gray200: '#E6E6E6',
  Gray300: '#D6D6D6',
  Gray400: '#C2C2C2',
  Gray500: '#A3A3A3',
  Gray600: '#858585',
  Gray700: '#5C5C5C',
  Gray800: '#333333',
  Gray900: '#141414',
  BluishGray10: '#F5F7F9',
  BluishGray50: '#F2F4F7',
  BluishGray100: '#ECEFF4',
  BluishGray200: '#DFE4EC',
  BluishGray300: '#CCD3E0',
  BluishGray400: '#B2BDD1',
  BluishGray500: '#8C9CBA',
  BluishGray600: '#667BA3',
  BluishGray700: '#455573',
  BluishGray800: '#262F40',
  BluishGray900: '#0F1319',
  Green50: '#E1FEF2',
  Green100: '#C9FDE7',
  Green200: '#B0FDDD',
  Green300: '#88FBCB',
  Green400: '#52FAB4',
  Green500: '#07E98B',
  Green600: '#06C676',
  Green700: '#05A864',
  Peach50: '#FFECEB',
  Peach100: '#FFE2E0',
  Peach200: '#FFCFCC',
  Peach300: '#FFB3AD',
  Peach400: '#FF8D85',
  Peach500: '#FF5447',
  Peach600: '#FD1808',
  CozBlue10: '#F2F5FD',
  CozBlue50: '#EDF2FC',
  CozBlue100: '#E4EBFB',
  CozBlue200: '#D2DDF9',
  CozBlue300: '#B8C9F5',
  CozBlue400: '#94ADF0',
  CozBlue500: '#5E85E8',
  CozBlue600: '#295CE0',
  CozBlue700: '#173EA1',
  CozBlue800: '#0D2259',
  CozBlue900: '#050E24',
  CozPurple10: '#F4F2FD',
  CozPurple50: '#EFEDFC',
  CozPurple100: '#E8E5FB',
  CozPurple200: '#D8D3F8',
  CozPurple300: '#C0B9F4',
  CozPurple400: '#A296EE',
  CozPurple500: '#7361E5',
  CozPurple600: '#452CDD',
  CozPurple700: '#2C1A9E',
  CozPurple800: '#190E58',
  CozPurple900: '#0A0623'
};

export type ColorPaletteType = typeof ColorPalette[keyof typeof ColorPalette];
