import React from 'react';
import { ReactComponent as SwitchOn } from '../../../../assets/svg/ico-switch-on.svg';
import { ReactComponent as SwitchOff } from '../../../../assets/svg/ico-switch-off.svg';

type SwitchProps = {
  className?: string;
  checked?: boolean;
  onClick?: () => void;
};

const Switch: React.FC<SwitchProps> = props => {
  return (
    <div
      className={props.className}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {props.checked ? <SwitchOn /> : <SwitchOff />}
    </div>
  );
};

export default Switch;
