import gql from 'graphql-tag';

export const INCREASE_RESUME_PAGE_VIEW_COUNT_GQL = gql`
  mutation increaseResumePageViewCount($publicUID: String!) {
    urclassIncreaseResumePageViewCount(publicUID: $publicUID)
  }
`;

export const INCREASE_RESUME_DOWNLOAD_COUNT_GQL = gql`
  mutation increaseResumeDownloadCount($publicUID: String!) {
    urclassIncreaseResumeDownloadCount(publicUID: $publicUID)
  }
`;

export const INCREASE_RESUME_CONTACT_VIEW_COUNT_GQL = gql`
  mutation increaseResumeContactViewCount($publicUID: String!) {
    urclassIncreaseResumeContactViewCount(publicUID: $publicUID)
  }
`;
