import React from 'react';
import { Result, Button } from 'antd';
import { ResultItemWrapper } from '../style';
import { PaymentStatusType } from '../../../../pages/apply/type';

const PaymentConfirmFail: React.FC<{ setResultStatus: Function }> = ({
  setResultStatus
}) => (
  <div>
    <Result
      status="warning"
      title={'결제 진행에 문제가 있습니다.'}
      subTitle={'communication@codestates.com 으로 문의 바랍니다.'}
    >
      <ResultItemWrapper>
        <Button
          size="large"
          type="link"
          onClick={() => setResultStatus(PaymentStatusType.STANDBY)}
        >
          옵션 선택으로 돌아가기
        </Button>
      </ResultItemWrapper>
    </Result>
  </div>
);

export default PaymentConfirmFail;
