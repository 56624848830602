import { makeToast } from './../../util/makeToast';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useMutation as apolloUseMutation, useQuery as apolloUseQuery } from '@apollo/react-hooks';
import { useCredential } from '../../contexts/credential';

import { PRODUCT_GQL } from '../../apollo/product/query';
import type {
  ApplyGovFundingData,
  ApplyGovFundingVars,
  ApplyImpactCampusData,
  ApplyImpactCampusVars,
  ApplyData,
  ApplyVars,
  CreatePaymentOrderData,
  CreatePaymentOrderVars,
  PaymentModel,
  PaymentOrder,
  Product,
  ProductData,
  ProductVars
} from '../../apollo/product/type';
import { ApplyB2BData, ApplyB2BVars, ApplyWewinData, ApplyWewinVars, PaymentType } from '../../apollo/product/type';
import {
  APPLY_B2B_GQL,
  APPLY_GOV_FUNDING_GQL,
  APPLY_GQL,
  APPLY_IMPACT_CAMPUS_GQL,
  APPLY_WEWIN_GQL,
  CREATE_PAYMENT_ORDER_GQL
} from '../../apollo/product/mutation';
import { GET_USER_GQL } from '../../apollo/account/query';
import { APPLICATIONS_WITH_PRODUCT_UUID_GQL as APPLICATIONS_GQL } from '../../apollo/application/query';

import { parseSlugUrl, store } from '../../util/';
import * as kp from '../../util/marketingTools/kakaoPixel';
import { googleAdsConversion } from '../../util/marketingTools/googleAdsConversion';

import type { IVbank, User } from './type';
import { ApplyErrorCode, PaymentStatusType, PayMethodType } from './type';
import { requestTossPayment } from '../../lib/tossPayment';
import type { UserProfile } from '../../apollo/account/type';
import { ApplyServiceType } from '../../apollo/mypage/type';
import { ApplicationWithProductUuid as Application } from '../../apollo/application/type';

//마케팅툴
import { fbqTrack, generateEventId } from '../../util/marketingTools/fbq';
import { useTrack } from '@hackler/react-sdk';
import { sendPageView } from '../../util/marketingTools/googleTagAssistant';
import { Modal } from 'antd';

import queryKeys from '../../query/queryKeys';
import {
  changeApplicationState,
  checkApplicationAnswersSubmitted,
  getApplicationData,
  getSlugUrlRequest,
  ReviveApplicationByUserUuidAndApplicationUuidRequest
} from './request';
import { AxiosError, AxiosResponse } from 'axios';
import { ApplicationAnswerStatusType } from '../detailApplication/type';
import { dataLayerForApplyEvent } from '../../util/marketingTools/datalayerGa4';

import { GO_TO_FIRST_STEP_SLUG_URLS, ROLLING_BASE_PRODUCT_SLUG_URLS } from '../../util/constant';
import { sleep } from '../../util/sleep';
import Urls from '../../util/urls';
import TagManager from 'react-gtm-module';
import { detectDevice } from '../../util/detectDevice/detectDevice';

export const SEB_PROGRAM_ID = 1;
export const GMB_PROGRAM_ID = 2;
export const DS_PROGRAM_ID = 3;
export const PMB_PROGRAM_ID = 4;
export const DA_PROGRAM_ID = 5;
export const AIB_PROGRAM_ID = 6;
export const KDT_AIB_PROGRAM_ID = 6;
export const KDT_BEB_PROGRAM_ID = 7;
export const KDT_PMB_PROGRAM_ID = 8;
export const KDT_GMB_PROGRAM_ID = 9;
export const TAJAN_SCHOOL_PROGRAM_ID = 10;
export const KDT_DEV_OPS_PROGRAM_ID = 11;
export const KDT_SEB_FE_PROGRAM_ID = 16;
export const KDT_SEB_BE_PROGRAM_ID = 17;
export const KDT_UUB_PROGRAM_ID = 27;

export const KDT_BOOTCAMP_PROGRAM_IDS = [
  KDT_AIB_PROGRAM_ID,
  KDT_BEB_PROGRAM_ID,
  KDT_PMB_PROGRAM_ID,
  KDT_GMB_PROGRAM_ID,
  KDT_DEV_OPS_PROGRAM_ID,
  KDT_SEB_FE_PROGRAM_ID,
  KDT_SEB_BE_PROGRAM_ID,
  KDT_UUB_PROGRAM_ID
];

export const convertProgramToHackleCourseName = (programId: number) => {
  switch (programId) {
    case KDT_SEB_FE_PROGRAM_ID:
      return 'SEBFE';
    case KDT_SEB_BE_PROGRAM_ID:
      return 'SEBBE';
    case KDT_PMB_PROGRAM_ID:
      return 'PMB';
    case KDT_GMB_PROGRAM_ID:
      return 'GMB';
    case KDT_AIB_PROGRAM_ID:
      return 'AIB';
    case KDT_BEB_PROGRAM_ID:
      return 'BEB';
    case KDT_DEV_OPS_PROGRAM_ID:
      return 'DOB';
    case KDT_UUB_PROGRAM_ID:
      return 'UUB';
    default:
      return null;
  }
};

const convertProgramToGAPayloadForGovFunding = (product: Product) => {
  const program = product.program;
  switch (program.id) {
    case KDT_AIB_PROGRAM_ID:
      return 'Ai | Full | gov';
    case KDT_BEB_PROGRAM_ID:
      return 'Be | Full | gov';
    case KDT_PMB_PROGRAM_ID:
      return 'Pm | Full | gov';
    case KDT_GMB_PROGRAM_ID:
      return 'Gm | Full | gov';
    case KDT_DEV_OPS_PROGRAM_ID:
      return 'DevOps | Full | gov';
    case KDT_SEB_FE_PROGRAM_ID:
      return 'Seb-Fe | Full | gov';
    case KDT_SEB_BE_PROGRAM_ID:
      return 'Seb-Be | Full | gov';

    default:
      return '';
  }
};

const convertProductToAdsProgramType = (product: Product) => {
  const program = product.program;

  switch (program.id) {
    // SEB
    case SEB_PROGRAM_ID:
      return 'web';

    // GMB
    case GMB_PROGRAM_ID:
      return 'growth';

    // AIB - DS
    case DS_PROGRAM_ID:
      return 'Ds';

    // PMB
    case PMB_PROGRAM_ID:
      return 'Pm';

    // DA
    case DA_PROGRAM_ID:
      return 'da';

    default:
      return program.title;
  }
};

const convertProductToProgramType = (product: Product) => {
  const program = product.program;
  switch (program.id) {
    // SEB
    case SEB_PROGRAM_ID:
      return `Dev | ${
        product.name.includes('20주')
          ? '20 week'
          : product.name.includes('31주')
          ? '31 week'
          : product.name.includes('51주')
          ? '51 week'
          : product.name.includes('22주')
          ? '22 week'
          : ''
      } |`;

    // GMB
    case GMB_PROGRAM_ID:
      return 'Gm | 12 week |';

    // AIB - DS
    case DS_PROGRAM_ID:
      return 'Ds | Full |';

    // PMB
    case PMB_PROGRAM_ID:
      return 'Pm | 12 week |';

    // DA
    case DA_PROGRAM_ID:
      return 'da | part |';

    default:
      return program.title;
  }
};

export const useController = () => {
  const history = useHistory();

  const { profile } = useCredential();
  const location = useLocation();
  const urlQueries = location.search;
  // urclass.codestates/apply/uuid?payment=upfront|wewin
  const { uuid } = useParams<{ uuid: string; payment: string }>();

  /* State */
  const [product, setProduct] = useState<Product>();
  const [user, setUser] = useState<User>();

  const [selectedPaymentModel, setSelectedPaymentModel] = useState<PaymentModel | undefined>(undefined);

  const [selectedPayMethod, setSelectedPayMethod] = useState<PayMethodType | undefined>();

  const [isImpactCampusModalVisible, setIsImpactCampusModalVisible] = useState<boolean>(false);

  const [selectedScholarshipOption, setSelectedScholarshipOption] = useState<string | undefined>();

  const [vbankInfo, setVbankInfo] = useState<IVbank | undefined>(undefined);

  const [resultStatus, setResultStatus] = useState(PaymentStatusType.STANDBY);

  const [matchedApplication, setMatchedApplication] = useState<Application>();
  const [course, setCourse] = useState('');
  /* local storage */
  store.redirectURL.remove();
  uuid && store.applyUuid.set(uuid);

  /* Query */
  const {
    data: productData,
    loading: productLoading,
    error: productError
  } = apolloUseQuery<ProductData, ProductVars>(PRODUCT_GQL, {
    variables: { uuid }
  });

  const queryParams = {
    getSlugUrlByUuidParams: {
      productUuid: uuid
    },
    getReviveByUuidParams: {
      productUuid: uuid,
      userUuid: profile?.uuid || ''
    }
  };

  const { data: userData } = apolloUseQuery<{
    user: UserProfile;
  }>(GET_USER_GQL);

  const { data: applicationsData } = apolloUseQuery<{
    urclassMyApplications: Application[];
  }>(APPLICATIONS_GQL);

  const admissionToken = applicationsData?.urclassMyApplications[0]?.admissionApplierToken;
  admissionToken && store.admissionToken.set(admissionToken);

  const { data: slugUrlData } = useQuery(
    queryKeys.APPLY_PRODUCT_SLUG_URL_BY_UUID(queryParams.getSlugUrlByUuidParams),
    () => getSlugUrlRequest(queryParams.getSlugUrlByUuidParams),
    {
      enabled: productData?.urclassProduct.applyServiceType === ApplyServiceType.ADMISSION,
      onSuccess: slugUrlData => {
        const _course = parseSlugUrl(slugUrlData?.slugUrl).course;
        setCourse(_course);
      }
    }
  );

  /* Mutation */
  const { mutateAsync: reviveApplication } = useMutation<
    AxiosResponse,
    AxiosError,
    ReviveApplicationByUserUuidAndApplicationUuidRequest,
    unknown
  >(variables => changeApplicationState(variables));

  const [createPaymentOrderMutation] = apolloUseMutation<CreatePaymentOrderData, CreatePaymentOrderVars>(
    CREATE_PAYMENT_ORDER_GQL
  );

  const [applyWewinMutation, { loading: loadingApplyWewin }] = apolloUseMutation<ApplyWewinData, ApplyWewinVars>(
    APPLY_WEWIN_GQL,
    {
      onCompleted: data => {
        const { id: applicationId, product, user } = data.urclassApplyWewin;
        TagManager.dataLayer({
          dataLayer: {
            event: `apply_course_urclass`,
            // TODO : slugURL 기능개발 되면 추가작업, 그전까진 상품명을 보낸다.
            courseName: product.name,
            UUID: user.uuid,
            applyId: applicationId,
            device: detectDevice()
          }
        });
      }
    }
  );

  const [applyGovFundingMutation, { loading: loadingApplyGovFunding }] = apolloUseMutation<
    ApplyGovFundingData,
    ApplyGovFundingVars
  >(APPLY_GOV_FUNDING_GQL);

  const [applyB2BMutation, { loading: loadingApplyB2B }] = apolloUseMutation<ApplyB2BData, ApplyB2BVars>(APPLY_B2B_GQL);

  const [applyImpactCampusMutation, { loading: loadingApplyImpactCampus }] = apolloUseMutation<
    ApplyImpactCampusData,
    ApplyImpactCampusVars
  >(APPLY_IMPACT_CAMPUS_GQL);

  const [applyMutation, { loading: loadingApply, error: errorApply }] = apolloUseMutation<ApplyData, ApplyVars>(
    APPLY_GQL
  );

  const isApplyMutationLoading = loadingApplyGovFunding || loadingApplyImpactCampus || loadingApplyB2B;

  // 핵클 이벤트 전송 트랙 변수
  const track = useTrack();

  /* useEffect */
  useEffect(() => {
    if (productData && productData.urclassProduct.paymentModels.length) {
      setProduct(productData.urclassProduct);
      setSelectedPaymentModel(productData.urclassProduct.paymentModels[0]);
      store.serviceStartDate.set(productData.urclassProduct.serviceStartDate);
      store.applyProductName.set(productData.urclassProduct.name);
    }
  }, [productData]);

  useEffect(() => {
    if (!product) return;
    sendPageView(`apply/${product.name}`);
  }, [product]);

  useEffect(() => {
    if (userData) {
      setUser({
        id: userData.user.id.toString(),
        name: userData.user.name,
        email: userData.user.email,
        role: userData.user.role,
        mobile: userData.user.mobile
      });
    }
  }, [userData]);

  useEffect(() => {
    fbqTrack('InitiateCheckout');
  }, []);

  useEffect(() => {
    if (applicationsData && product) {
      const { urclassMyApplications } = applicationsData;
      const matchedMyApplication = urclassMyApplications.find(app => app.product.uuid === product.uuid);
      setMatchedApplication(matchedMyApplication);
    }
  }, [applicationsData, product]);

  /* handler */
  const handleChangeSelectedPaymentModel = (paymentModel: PaymentModel) => {
    // 결제 모델 선택시 GA

    setSelectedPaymentModel(paymentModel);
  };
  const handleChangePayMethod = (payMethod: PayMethodType) => {
    // 결제 방법 선택시 GA
    setSelectedPayMethod(payMethod);
  };

  const handleChangeScholarshipOption = (option: string) => {
    setSelectedScholarshipOption(option);
  };

  const openApplyImpactCampusModal = () => {
    setIsImpactCampusModalVisible(true);
  };

  const closeApplyImpactCampusModal = () => {
    setIsImpactCampusModalVisible(false);
    setSelectedScholarshipOption(undefined);
  };

  const sendApplyEventToHackle = (product: Product) => {
    const event = {
      key: `${convertProgramToHackleCourseName(product.program.id)}_click_urclass_apply`,
      value: 10000
    };
    track(event);
  };
  // 정상적으로 완료시 모두 ApplyResultPage
  const handlePaymentFormSubmit = async () => {
    switch (selectedPaymentModel?.paymentType) {
      case PaymentType.GOV_FUNDING:
        await handleGovFundingApply();
        break;
      case PaymentType.B2B:
        await handleB2BApply();
        break;
      case PaymentType.UPFRONT:
      case PaymentType.HYBRID:
        await handleCheckout();
        break;
      case PaymentType.IMPACT_CAMPUS:
        openApplyImpactCampusModal();
        break;
      default:
        await handleWewinApply();
    }
    return;
  };

  const handleRequestPayment = async (paymentOrder: PaymentOrder, buyer: User, payMethod: PayMethodType) => {
    const { merchantUID, orderName, totalAmount } = paymentOrder;
    let repay = false;
    let applicationData: Application | undefined;

    try {
      if (applicationsData && profile) {
        applicationData = applicationsData.urclassMyApplications?.filter(v => v.product.uuid === uuid)[0];
        const isCancelledApplication =
          applicationData &&
          applicationData.uuid &&
          applicationData.applyingStatus === ApplicationAnswerStatusType.CANCELLED;

        if (isCancelledApplication) {
          repay = true;
        }
      }

      // 결제 결과 페이지에서 쓸거에요.
      store.paidProgramType.set(convertProductToAdsProgramType(product!));
      const paymentData = {
        orderName,
        amount: totalAmount,
        orderId: merchantUID,
        customerName: buyer.name,
        customerEmail: buyer.email,
        customerMobilePhone: buyer.mobile,
        taxFreeAmount: totalAmount,
        payMethod,
        repay,
        productUuid: product?.uuid
      };

      await requestTossPayment(paymentData);
    } catch (e) {
      // 토스 모듈이 알수없는 에러를 뱉는 경우

      setResultStatus(PaymentStatusType.PAYMENT_FAIL);
    }

    // duhyundev : ! 아맞다 토스로 바뀌지...
    // jin3378s : 그래서 바뀌었습니다!
  };

  const checkApplicationStateChange = async () => {
    if (admissionToken && profile) {
      const userUuid = profile.uuid;
      const productUuid = uuid;

      try {
        const applicationData = await getApplicationData({ userUuid, productUuid });
        const isCancelledApplication =
          applicationData && applicationData.applyingStatus === ApplicationAnswerStatusType.CANCELLED;

        if (isCancelledApplication && applicationData.uuid) {
          // 지원 취소 내역이 있는 상품 재지원 시, 기존 지원서 부활(타입폼에선 적용안됨)
          handleReviveApplication(applicationData.uuid);
          return true;
        }
      } catch (error) {
        console.log(error);
      }
    }

    return false;
  };

  const handleCheckout = async () => {
    const mediaType = store.adsMediaType.get() ?? 'DIRECT';

    if (!selectedPayMethod) {
      makeToast({ type: 'error', content: '결제 방식을 선택해 주세요.' });
      return;
    }

    if (user && product && selectedPaymentModel) {
      try {
        const eventId = generateEventId();
        const { data } = await createPaymentOrderMutation({
          variables: {
            paymentModelId: selectedPaymentModel.id,
            mediaType,
            eventId
          }
        });

        if (data && data.urclassCreatePaymentOrder) {
          const buyer = user;
          const adsProgramType = convertProductToProgramType(product);
          const kpTag =
            product.program.id === 1
              ? 'SEB Apply'
              : product.program.id === 2
              ? 'GMB Apply'
              : product.program.id === 4
              ? 'PMB Apply'
              : '';

          kp.purchase(kpTag);

          fbqTrack('Purchase', { currency: 'KRW', value: 1.0, content_name: course }, eventId);

          handleRequestPayment(data.urclassCreatePaymentOrder, buyer, selectedPayMethod!);
        }
      } catch (err) {
        handleApplyError(err);
      }
    } else {
      makeToast({ type: 'error', content: '결제에 필요한 정보를 모두 입력해 주세요.' });
    }
  };

  const handleWewinApply = async () => {
    const hasApplicationRevived = await checkApplicationStateChange();
    if (hasApplicationRevived) return;

    const mediaType = store.adsMediaType.get() ?? 'DIRECT';

    if (product && selectedPaymentModel) {
      try {
        const eventId = generateEventId();
        const { data } = await applyWewinMutation({
          variables: {
            mediaType,
            eventId,
            paymentModelId: selectedPaymentModel.id
          }
        });

        if (data && data.urclassApplyWewin && slugUrlData && slugUrlData.slugUrl) {
          const adsProgramType = convertProductToAdsProgramType(product);
          googleAdsConversion(Urls().urclass, adsProgramType);

          const kpTag =
            product.program.id === 1
              ? 'SEB Apply'
              : product.program.id === 2
              ? 'GMB Apply'
              : product.program.id === 4
              ? 'PMB Apply'
              : '';

          kp.purchase(kpTag);

          fbqTrack('Purchase', { currency: 'KRW', value: 1.0, content_name: course }, eventId);

          dataLayerForApplyEvent({
            data: data.urclassApplyWewin,
            slugUrl: slugUrlData.slugUrl,
            urlQueries: window.location.search
          });
          // apply success
          handleSuccessApply(PaymentType.WEWIN, data.urclassApplyWewin.uuid);
        }
      } catch (err) {
        handleApplyError(err);
      }
    } else {
      makeToast({ type: 'error', content: '지원에 필요한 정보를 모두 입력해 주세요.' });
    }
  };

  const handleGovFundingApply = async () => {
    const hasApplicationRevived = await checkApplicationStateChange();
    if (hasApplicationRevived) return;

    const mediaType = store.adsMediaType.get() ?? 'DIRECT';

    if (product && selectedPaymentModel) {
      try {
        const eventId = generateEventId();
        const { data } = await applyGovFundingMutation({
          variables: {
            mediaType,
            eventId,
            paymentModelId: selectedPaymentModel.id
          }
        });

        if (data && data.urclassApplyGovFunding) {
          fbqTrack('Purchase', { currency: 'KRW', value: 1.0, content_name: course }, eventId);
          sendApplyEventToHackle(product);
          if (slugUrlData && slugUrlData.slugUrl) {
            const { slugUrl } = slugUrlData;
            dataLayerForApplyEvent({
              data: data.urclassApplyGovFunding,
              slugUrl: slugUrlData.slugUrl,
              urlQueries: window.location.search
            });

            //지원선발로 만든 kdt에서는 result페이지를 생략한다.
            handleSuccessNoPaymentApply(data.urclassApplyGovFunding.uuid, slugUrl);
          }
        }
      } catch (err) {
        handleApplyError(err);
      }
    } else {
      makeToast({ type: 'error', content: '지원에 필요한 정보를 모두 입력해 주세요.' });
    }
  };

  const handleB2BApply = async () => {
    const hasApplicationRevived = await checkApplicationStateChange();
    if (hasApplicationRevived) return;

    const mediaType = store.adsMediaType.get() ?? 'DIRECT';

    if (product && selectedPaymentModel) {
      try {
        const programId = product?.program.id;
        const eventId = generateEventId();
        const { data } = await applyB2BMutation({
          variables: {
            mediaType,
            eventId,
            paymentModelId: selectedPaymentModel.id
          }
        });

        if (data && data.urclassApplyB2B && slugUrlData && slugUrlData.slugUrl) {
          fbqTrack('Purchase', { currency: 'KRW', value: 1.0, content_name: course }, eventId);
          googleAdsConversion(Urls().urclass, 'ur_apply_b2b');
          sendApplyEventToHackle(product);
          dataLayerForApplyEvent({
            data: data.urclassApplyB2B,
            slugUrl: slugUrlData.slugUrl,
            urlQueries: window.location.search
          });
          // apply success
          const { slugUrl } = slugUrlData;
          // CDS역량진단평가는 쇼파사람페이지 생략
          programId === 33
            ? handleSuccessNoPaymentApply(data.urclassApplyB2B.uuid, slugUrl)
            : handleSuccessApply(PaymentType.B2B, data.urclassApplyB2B.uuid);
        }
      } catch (err) {
        handleApplyError(err);
      }
    } else {
      makeToast({ type: 'error', content: '지원에 필요한 정보를 모두 입력해 주세요.' });
    }
  };

  const handleApplyPaymentForm = async () => {
    const hasApplicationRevived = await checkApplicationStateChange();
    if (hasApplicationRevived) return;

    if (product) {
      const mediaType = store.adsMediaType.get() ?? 'DIRECT';
      const eventId = generateEventId();

      try {
        const { data } = await applyMutation({
          variables: {
            productId: product.id,
            eventId,
            mediaType,
            paymentType: selectedPaymentModel?.paymentType!
          }
        });
        if (data && data.urclassApply && slugUrlData && slugUrlData.slugUrl) {
          const adsProgramType = convertProductToAdsProgramType(product);
          googleAdsConversion(Urls().urclass, adsProgramType);

          const kpTag =
            product.program.id === 1
              ? 'SEB Apply'
              : product.program.id === 2
              ? 'GMB Apply'
              : product.program.id === 4
              ? 'PMB Apply'
              : '';

          kp.purchase(kpTag);

          fbqTrack('Purchase', { currency: 'KRW', value: 1.0, content_name: course }, eventId);

          dataLayerForApplyEvent({
            data: data.urclassApply,
            slugUrl: slugUrlData.slugUrl,
            urlQueries: window.location.search
          });
          // apply success
          handleSuccessApply(selectedPaymentModel?.paymentType!, data.urclassApply.uuid);
        }
      } catch (err) {
        handleApplyError(err);
      }
    }
  };

  const handleApplyImpactCampus = async () => {
    const hasApplicationRevived = await checkApplicationStateChange();
    if (hasApplicationRevived) return;

    const mediaType = store.adsMediaType.get() ?? 'DIRECT';

    if (product && selectedPaymentModel) {
      try {
        const eventId = generateEventId();
        const { data } = await applyImpactCampusMutation({
          variables: {
            mediaType,
            eventId,
            paymentModelId: selectedPaymentModel.id
          }
        });

        if (data && data.urclassApplyImpactCampus && slugUrlData && slugUrlData.slugUrl) {
          const adsProgramType = convertProductToAdsProgramType(product);
          fbqTrack('Purchase', { currency: 'KRW', value: 1.0, content_name: course }, eventId);
          googleAdsConversion(Urls().urclass, adsProgramType);
          sendApplyEventToHackle(product);
          dataLayerForApplyEvent({
            data: data.urclassApplyImpactCampus,
            slugUrl: slugUrlData.slugUrl,
            urlQueries: window.location.search
          });

          // apply success
          handleSuccessApply(PaymentType.IMPACT_CAMPUS, data.urclassApplyImpactCampus.uuid);
        }
      } catch (err) {
        handleApplyError(err);
      }
    } else {
      makeToast({ type: 'error', content: '지원에 필요한 정보를 모두 입력해 주세요.' });
    }
  };

  const handleApplyError = (e: unknown) => {
    const error = e as Error;
    const errorCode = error.message;
    switch (errorCode) {
      case ApplyErrorCode.ALREADY_APPLIED_ANOTHER_PRODUCT:
        return Modal.confirm({
          title: '이미 지원 중인 과정이 있습니다.',
          content: '마이페이지에서 지원 중인 과정을 취소한 후 다시 지원해주세요 .',
          okText: '마이페이지 가기',
          onOk: () => {
            history.push('/mypage');
          },
          cancelText: '닫기'
        });

      case ApplyErrorCode.ALREADY_APPLIED:
        if (matchedApplication) {
          try {
            makeToast({ type: 'success', content: '이미 진행중인 단계가 있습니다. 확인해 주세요.' });
            history.push(`/applications/${slugUrlData?.slugUrl}/list/${matchedApplication?.uuid}${urlQueries}`);
          } catch (err) {
            console.log(err);
          }
        } else {
          makeToast({ type: 'error', content: '연결된 지원서를 찾을 수 없습니다.' });
        }
        return;

      default:
        makeToast({ type: 'error', content: '결제진행에 문제가 있습니다.' });
    }
  };

  const handleSuccessApply = (paymentType: PaymentType, applicationUuid: string) => {
    // 유입 미디어 로컬 스토리지 제거
    store.adsMediaType.remove();
    store.applyUuid.remove();
    store.applyProductName.remove();

    if (paymentType === PaymentType.B2B) {
      history.push({
        pathname: `/apply/result-b2b/${uuid}`,
        state: { applicationUuid }
      });
      return;
    }

    history.push({
      pathname: `/apply/result/${uuid}`,
      state: { applicationUuid }
    });
  };

  const handleSuccessNoPaymentApply = async (applicationUuid: string, slugUrl: string) => {
    // 유입 미디어 로컬 스토리지 제거
    store.adsMediaType.remove();

    store.applyUuid.remove();
    store.applyProductName.remove();

    const goToFirstStep = GO_TO_FIRST_STEP_SLUG_URLS.includes(slugUrl) || product?.program.id === 35;

    if (goToFirstStep) {
      history.push({ pathname: `/application/${slugUrl}/1${urlQueries}`, state: { uuid: applicationUuid } });
    } else history.push(`/applications/${slugUrl}/list/${applicationUuid}${urlQueries}`);
    return;
  };

  const handleReviveApplication = async (applicationUuid: string) => {
    if (!slugUrlData) return;
    const modalContent = `${
      ROLLING_BASE_PRODUCT_SLUG_URLS.includes(slugUrlData.slugUrl || '')
        ? '취소한 지원서를 복구합니다.'
        : '이어서 지원서 작성을 진행하시겠어요?'
    }`;

    const modalOkText = `${
      ROLLING_BASE_PRODUCT_SLUG_URLS.includes(slugUrlData.slugUrl || '') ? '확인' : '지원서 작성'
    }`;

    const isApplicationAnswersSubmitted = await checkApplicationAnswersSubmitted(profile?.uuid, applicationUuid);

    return Modal.warning({
      title: '해당 코스에 지원 취소한 기록이 있어요.',
      content: modalContent,
      okText: modalOkText,
      onOk: async () => {
        try {
          const isRollingBaseProduct = ROLLING_BASE_PRODUCT_SLUG_URLS.includes(slugUrlData.slugUrl || '');

          const { data: reviveApplicationData } = await reviveApplication({
            applicationUuid,
            userUuid: profile?.uuid || '',
            changeTo: isApplicationAnswersSubmitted ? 'COMPLETED' : 'IN_PROGRESS'
          });
          if (reviveApplicationData) {
            await sleep(1500);
            const pathname = isRollingBaseProduct
              ? '/mypage'
              : `/applications/${slugUrlData.slugUrl}/list/${applicationUuid}`;
            history.push({
              pathname: `${pathname}`,
              state: { revive: true }
            });
          }
        } catch (err) {
          const { response } = err as unknown as AxiosError;

          if (
            response &&
            response.data &&
            response.data.message &&
            response.data.message === '접수중인 지원서가 이미 존재합니다.'
          ) {
            handleApplyError({ message: ApplyErrorCode.ALREADY_APPLIED_ANOTHER_PRODUCT });
          } else {
            handleApplyError({ message: 'default' });
          }
        }
      }
    });
  };

  return {
    profile,

    user,
    product,
    slugUrlData,

    productData,
    productLoading,
    productError,

    selectedPaymentModel,
    handleChangeSelectedPaymentModel,

    selectedPayMethod,
    handleChangePayMethod,

    isImpactCampusModalVisible,
    selectedScholarshipOption,
    handleChangeScholarshipOption,
    closeApplyImpactCampusModal,
    handleApplyImpactCampus,

    handlePaymentFormSubmit,
    isApplyMutationLoading,

    vbankInfo,
    setVbankInfo,
    handleSuccessApply,

    resultStatus,
    setResultStatus,

    handleApplyPaymentForm
  };
};
