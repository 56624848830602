import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';
import SignupBannerMobileA from '../../../assets/image/signup/banner_mobile_A.png';
import SignupBannerMobileB from '../../../assets/image/signup/banner_mobile_B.png';
import SignupBannerMobileC from '../../../assets/image/signup/banner_mobile_C.png';
import SignupBannerTabletA from '../../../assets/image/signup/banner_tablet_A.png';
import SignupBannerTabletB from '../../../assets/image/signup/banner_tablet_B.png';
import SignupBannerTabletC from '../../../assets/image/signup/banner_tablet_C.png';
import SignupBannerDesktopA from '../../../assets/image/signup/banner_desktop_A.png';
import SignupBannerDesktopB from '../../../assets/image/signup/banner_desktop_B.png';
import SignupBannerDesktopC from '../../../assets/image/signup/banner_desktop_C.png';
import { NewBreakPoints, NewMQ } from '../../../style/mediaQuery';
import { useWindowSize } from '../../../util/hooks/useWindowSize';

const SignupBanner = () => {
  const bannerImages = [
    {
      mobileSrc: SignupBannerMobileA,
      tabletSrc: SignupBannerTabletA,
      desktopSrc: SignupBannerDesktopA,
      imgIndex: 1,
      alt: 'K디지털 트레이닝 부트캠프 수강생 수 1위'
    },
    {
      mobileSrc: SignupBannerMobileB,
      tabletSrc: SignupBannerTabletB,
      desktopSrc: SignupBannerDesktopB,
      imgIndex: 2,
      alt: '누적 취업자 1828명, 누적 취업률 81.4%'
    },
    {
      mobileSrc: SignupBannerMobileC,
      tabletSrc: SignupBannerTabletC,
      desktopSrc: SignupBannerDesktopC,
      imgIndex: 3,
      alt: '먼저 도전에 성공한 선배들의 이야기',
      redirectUrl: 'https://codestates.com/about/review'
    }
  ];

  const INTERVAL_DELAY = 4000;
  const TRANSITION_TIME = 1000;
  const TRANSITION_STYLE = `transform ${TRANSITION_TIME}ms`;
  const OUTER_SLIDE_NUMBER = 2;

  const { width } = useWindowSize();
  const isMobile = width ? width <= (NewBreakPoints.MOBILE.maxPx as number) : false;
  const isTablet = width ? width <= (NewBreakPoints.TABLET.maxPx as number) : false;

  const [bannerWidth, setBannerWidth] = useState<number>(0);
  const [distanceBetweenBanners, setDistanceBetweenBanners] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(OUTER_SLIDE_NUMBER);
  const [transition, setTransition] = useState<string>(TRANSITION_STYLE);
  const [delay, setDelay] = useState<number>(INTERVAL_DELAY);
  const [stopAutoPlay, setStopAutoPlay] = useState<boolean>(false);
  const [touchStartPosition, setTouchStartPosition] = useState<number | null>(null);

  const addedBefore = bannerImages.slice(bannerImages.length - OUTER_SLIDE_NUMBER);
  const addedAfter = bannerImages.slice(0, OUTER_SLIDE_NUMBER);
  const bannerImageList = bannerImages.length > 1 ? [...addedBefore, ...bannerImages, ...addedAfter] : bannerImages;
  const xPosition: number =
    bannerImages.length > 1
      ? currentIndex * (bannerWidth + distanceBetweenBanners) * -1
      : ((width || 0) - bannerWidth) / 2;

  const totalPageLength = bannerImages.length;
  const currentPageIndex = useMemo(() => (currentIndex > totalPageLength + 1 ? 1 : currentIndex - 1), [currentIndex]);

  useEffect(() => {
    if (width) {
      const bannerwidth = getBannerWidth();
      setBannerWidth(bannerwidth);
      setDistanceBetweenBanners(20);
    }
  }, [width, bannerWidth, distanceBetweenBanners]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!stopAutoPlay && bannerImageList.length > 1) {
        moveNextSlide();
      }
    }, delay);
    return () => {
      clearInterval(interval);
    };
  }, [bannerImageList, delay, stopAutoPlay, transition]);

  const getBannerWidth = () => {
    if (!width) {
      return 0;
    }

    if (width <= (NewBreakPoints.MOBILE.maxPx as number)) {
      return 343;
    } else if (width <= (NewBreakPoints.TABLET.maxPx as number)) {
      return 320;
    } else {
      return 384;
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    const touchDown = e.touches[0].clientX;
    setTouchStartPosition(touchDown);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (touchStartPosition === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchStartPosition - currentTouch;
    if (diff > 5) {
      moveNextSlide();
    }

    if (diff < -5) {
      movePrevSlide();
    }

    setTouchStartPosition(null);
  };

  const moveNextSlide = () => {
    if (currentIndex === bannerImageList.length - OUTER_SLIDE_NUMBER) {
      setDelay(0);
      setTransition('');
      setCurrentIndex(OUTER_SLIDE_NUMBER);
    } else {
      setDelay(INTERVAL_DELAY);
      setTransition(TRANSITION_STYLE);
      setCurrentIndex(prev => prev + 1);
    }
  };

  const movePrevSlide = () => {
    if (currentIndex === OUTER_SLIDE_NUMBER) {
      setTransition('');
      setCurrentIndex(bannerImages.length + OUTER_SLIDE_NUMBER);
      setTimeout(() => {
        setTransition(TRANSITION_STYLE);
        setCurrentIndex(bannerImages.length + OUTER_SLIDE_NUMBER - 1);
      }, 0);
    } else {
      setCurrentIndex(prev => prev - 1);
      setDelay(INTERVAL_DELAY);
      setTransition(TRANSITION_STYLE);
    }
  };

  const moveSpecificSlide = (slideIdx: number) => {
    if (isMobile) {
      return;
    }

    setTransition('');
    setCurrentIndex(slideIdx);
  };

  return (
    <SignupBannerTrack>
      <SignupBanners transition={transition} xPosition={xPosition}>
        {bannerImageList.map(banner => {
          const { mobileSrc, tabletSrc, desktopSrc, redirectUrl, alt } = banner;
          return (
            <BannerWrapper onTouchStart={e => handleTouchStart(e)} onTouchMove={e => handleTouchMove(e)}>
              {redirectUrl ? (
                <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
                  <img src={isMobile ? mobileSrc : isTablet ? tabletSrc : desktopSrc} alt={alt} />
                </a>
              ) : (
                <img src={isMobile ? mobileSrc : isTablet ? tabletSrc : desktopSrc} alt={alt} />
              )}
            </BannerWrapper>
          );
        })}
      </SignupBanners>
      <BannerNavigation>
        {bannerImages.map(banner => (
          <NavigationDot
            isActive={banner.imgIndex === currentPageIndex}
            onClick={() => moveSpecificSlide(OUTER_SLIDE_NUMBER + banner.imgIndex - 1)}
          />
        ))}
      </BannerNavigation>
    </SignupBannerTrack>
  );
};

export default SignupBanner;

const SignupBannerTrack = styled.div`
  width: 343px;
  height: 118px;
  overflow: hidden;
  position: relative;

  ${NewMQ.TABLET} {
    width: 320px;
    height: 406px;
  }

  ${NewMQ.DESKTOP} {
    width: 384px;
    height: 440px;
  }
`;

const SignupBanners = styled.div<{ transition: string; xPosition: number }>`
  transform: ${({ xPosition }) => `translateX(${xPosition}px)`};
  transition: ${({ transition }) => transition};
  display: flex;
  height: 100%;
  gap: 20px;
`;

const BannerWrapper = styled.div`
  width: 343px;
  height: 100%;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  ${NewMQ.TABLET} {
    width: 320px;
  }

  ${NewMQ.DESKTOP} {
    width: 384px;
  }
`;

const BannerNavigation = styled.div`
  position: absolute;
  top: 8px;
  z-index: 3;
  display: flex;
  gap: 4px;
  left: 50%;
  transform: translateX(-50%);

  ${NewMQ.TABLET} {
    top: 12px;
    gap: 6px;
  }
`;

const NavigationDot = styled.button<{ isActive: boolean }>`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: white;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  border: none;
  padding: 0;
  cursor: pointer;

  ${NewMQ.TABLET} {
    width: 6px;
    height: 6px;
    border-radius: 3px;
  }
`;
