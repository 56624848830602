import React from 'react';
import styled from '@emotion/styled';

interface IHighlightSubTitleWrapper {
  width?: string;
}

interface HighlightSubTitleProps {
  children: React.ReactNode;
  width?: string;
}

const HighlightSubTitleWrapper = styled.div<IHighlightSubTitleWrapper>`
  display: block;
  font-weight: 700;
  font-size: 17px;
  line-height: 28px;
  word-break: keep-all;
  width: ${({ width }) => (width ? width : '600px')};

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

const HighlightSubTitle: React.FC<HighlightSubTitleProps> = ({
  children,
  width
}) => (
  <HighlightSubTitleWrapper width={width}>{children}</HighlightSubTitleWrapper>
);

export default HighlightSubTitle;
