import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../assets/svg/ico-back.svg';

import { ColorPaletteType } from '../../../styles/color';
import { Typography, TypographyType } from '../../../components/atoms/Typography';
const NoAttendanceRecordWrapper = styled.div`
  text-align: center;
  margin-top: 350px;
  .txt__content--empty {
    margin-bottom: 24px;
  }
  .link__content--empty {
    background-color: #452cdd;
    display: inline-block;
    margin-top: 40px;
    width: 164px;
    height: 48px;
    color: #fff;
    border-radius: 6px;
    padding-top: 12px;
    text-align: center;
    .txt__btn {
      vertical-align: top;
    }
    .ico__btn {
      display: inline-block;
    }
  }
`;
const NoAttendanceRecordPage = () => {
  return (
    <NoAttendanceRecordWrapper>
      <img
        className="ico__content--empty"
        alt="콘텐츠 없음"
        src="https://codestates-urclass.s3.ap-northeast-2.amazonaws.com/common/img_exclamation.svg"
      />
      <Typography
        className="txt__content--empty"
        color={ColorPaletteType.BLUISHGRAY5}
        type={TypographyType.H4}
        text="출석기록이 없습니다."
      />
      <Link to="/mypage/certificates" className="link__content--empty">
        <BackIcon className="ico__btn" />
        <span className="txt__btn">돌아가기</span>
      </Link>
    </NoAttendanceRecordWrapper>
  );
};

export default NoAttendanceRecordPage;
