import React from 'react';
import styled from '@emotion/styled';
import { Progress } from 'antd';
import { useHistory, Link } from 'react-router-dom';

type PlaylistEntry = {
  id: number;
  name: string;
  description: string;
  thumbnailUrl: string;
  isPublished: boolean;
  userPlaylistProgress: number;
};

type PlaylistCardWrapperProps = {
  isCurrentPlaylist: boolean;
  leftAligned: boolean;
};

type PlaylistCardProps = {
  playlistData: PlaylistEntry;
  leftAligned: boolean;
};

const PlaylistCardWrapper = styled.div<PlaylistCardWrapperProps>`
  width: ${({ leftAligned }) => (leftAligned ? '292px' : 'calc(33% - 21px)')};

  .playlist-shortcut-card-container {
    height: 297px;
    border-radius: 10px;
    border: ${({ isCurrentPlaylist }: any) => (isCurrentPlaylist ? 'solid 2px #295CE0' : 'solid 1px #dfe3ec')};
    background: ${({ isCurrentPlaylist }: any) => (isCurrentPlaylist ? '#F2F5FD' : '')};
    margin-right: ${({ leftAligned }) => (leftAligned ? '32px' : '0px')};
    padding-top: 0;
  }

  .playlist-shortcut-card-container:hover {
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .thumbnail {
    height: 165px;
    width: 100%;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
  }

  .body {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .title {
    color: #455573;
    font-weight: 700;
    font-size: 1.2rem;
    margin: 20px 5px 0 5px;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    width: 70%;
  }

  .description {
    color: #8c9cba;
    font-weight: 400;
    font-size: 0.8rem;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    width: 70%;
  }

  .progress-bar {
    color: #295ce0;
    width: 50%;
    margin-top: 10px;
  }

  .progress-percent {
    color: #295ce0;
    font-weight: 700;
    font-size: 0.8rem;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;

    .playlist-shortcut-card-container {
      height: 107px;
      display: flex;
      flex-direction: row;
      margin: 0.75rem 0;
    }

    .thumbnail {
      width: 110px;
      height: 100%;
      border-radius: 10px 0 0 10px;
    }

    .body {
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: center;
      align-items: start;
      margin-left: 1rem;
      width: 100%;
    }

    .title {
      margin: 0;
      width: 70%;
    }

    .description {
      width: 70%;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .progress-bar {
      width: 40%;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 994px) {
    width: ${({ leftAligned }) => (leftAligned ? '292px' : 'calc(33% - 21px)')};

    .playlist-shortcut-card-container {
      margin-right: ${({ leftAligned }) => (leftAligned ? '32px' : '0px')};
      height: 242px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .thumbnail {
      height: 110px;
      width: 100%;
      border-radius: 10px 10px 0 0;
      object-fit: cover;
    }

    .body {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      width: 100%;
      height: 132px;
      z-index: 9;
      margin: 0;
    }

    .title {
      color: #455573;
      font-weight: 700;
      font-size: 0.9rem;
    }
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    .playlist-shortcut-card-container {
      height: 107px;
      display: flex;
      flex-direction: row;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin: 0.75rem 0;
    }

    .thumbnail {
      height: 100%;
      width: 20%;
      border-radius: 10px 0 0 10px;
    }

    .body {
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: center;
      align-items: start;
      margin-left: 1rem;
      width: 80%;
    }

    .title {
      margin: 0;
      width: 70%;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    .description {
      width: 70%;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .progress-bar {
      width: 40%;
      margin-top: 0;
    }
  }
`;

export const PlaylistCard: React.FC<PlaylistCardProps> = ({ playlistData, leftAligned }) => {
  const history = useHistory();
  const progress = Math.round(playlistData.userPlaylistProgress * 100);
  const playlistId = Number(history.location.search.split('playlist=')[1]);
  return (
    <PlaylistCardWrapper isCurrentPlaylist={playlistId === playlistData.id} leftAligned={leftAligned}>
      <Link to={`/playlist/${playlistData.id}`} key={playlistData.id} className="playlist-link">
        <div className="playlist-shortcut-card-container">
          <img className="thumbnail" src={playlistData.thumbnailUrl} />
          <div className="body">
            <div className="title">{playlistData.name}</div>
            <div className="description">{playlistData.description}</div>

            <div className="progress-bar">
              <Progress size="small" showInfo={false} percent={progress} strokeColor={'#295CE0'} />
            </div>
            <div className="progress-percent">
              {progress ? `${progress}% Complete` : <div style={{ color: '#D6D6D6' }}>Please begin studying!</div>}
            </div>
          </div>
        </div>
      </Link>
    </PlaylistCardWrapper>
  );
};
