import { HRDFrame } from './types';

export class HRDOTP {
  static HRDOTP_ORIGIN = 'https://fds.hrdkorea.or.kr';

  private hrdFrame: HRDFrame;

  /* codestates static */
  private agtId = 'codestates';
  private courseAgentPK = '37';
  private evalCode = '01';
  private evalType = '진도';
  private classTime = '01';
  private sessionId = 'SESSIONID';
  public userId: string;
  public classAgentPK: string;

  constructor({
    userId,
    classAgentPK
  }: {
    userId: string;
    classAgentPK: string;
  }) {
    this.hrdFrame = (window as any).hrdFrame as HRDFrame;
    this.userId = userId;
    this.classAgentPK = classAgentPK;

    if (!(window as any).loadAPIReceiver) {
      (window as any).loadAPIReceiver = function () {};
    }
    if (!(window as any).loadKeypadReceiver) {
      (window as any).loadKeypadReceiver = function () {};
    }
  }

  public loadHrdFrame() {
    const isDebug = process.env.REACT_APP_ENV !== 'production';
    this.hrdFrame.loadHrdFrame(false);
  }

  public loadOTP() {
    // 클라우드 형
    const otpType = '102';

    this.hrdFrame.loadOTP(this.agtId, this.userId, this.sessionId, otpType);
  }

  public loadKeyPad(ele: EventTarget, padType: string, fWidth: string) {
    this.hrdFrame.loadKeyPad(ele, padType, fWidth);
  }

  public hideKeyPad() {
    this.hrdFrame.hideKeyPad();
  }

  public async checkStatus() {
    const data = await this.fetch(
      '/fdsService/hrdOTP/JSP/clientService/chkOtpDeviceStatus.jsp',
      {
        AGTID: this.agtId,
        USRID: this.userId
      }
    );
    const connStatus = data.querySelector('ConnStatus')?.textContent;
    const otpStatus = data.querySelector('OtpStatus')?.textContent;
    const errorContent = data.querySelector('ErrCnt')?.textContent;
    const remark = data.querySelector('Remark')?.textContent;

    return {
      connStatus,
      otpStatus,
      errorContent,
      remark
    };
  }

  public async validate(options: { otpNumber: string }) {
    const data = await this.fetch(
      '/fdsService/hrdOTP/JSP/validateOtp/chkOtpResult.jsp',
      {
        AGTID: this.agtId,
        USRID: this.userId,
        SESSIONID: this.sessionId,
        OTPNO: options.otpNumber,
        COURSE_AGENT_PK: this.courseAgentPK,
        CLASS_AGENT_PK: this.classAgentPK,
        EVAL_CD: this.evalCode,
        EVAL_TYPE: this.evalType,
        CLASS_TME: this.classTime,
        UT: this.getUT()
      }
    );

    const retVal = data.querySelector('RetVal')?.textContent;
    const errorContent = data.querySelector('ErrCnt')?.textContent;
    const retMessage = data.querySelector('RetMsg')?.textContent;
    const remark = data.querySelector('Remark')?.textContent;

    return {
      retVal,
      errorContent,
      retMessage,
      remark
    };
  }

  public async resetStatus() {
    /* 휴대전화 인증 미적용으로 인한 임의값 부여 */
    const m_Ret = 'T';
    const m_retCD = '000000';
    const m_trnID = '12345';
    const m_trnDT = this.hrdFrame.getUT() || '';

    const data = await this.fetch(
      '/fdsService/hrdOTP/JSP/clientService/regiPhoneAuthLog.jsp',
      {
        m_Ret,
        m_retCD,
        m_trnID,
        m_trnDT,
        AGTID: this.agtId,
        USRID: this.userId
      }
    );

    const retVal = data.querySelector('RetVal')?.textContent;
    const errorContent = data.querySelector('ErrCnt')?.textContent;
    const retMessage = data.querySelector('RetMsg')?.textContent;
    const remark = data.querySelector('Remark')?.textContent;

    return {
      retVal,
      errorContent,
      retMessage,
      remark
    };
  }

  private async fetch(path: string, payload: { [key: string]: any }) {
    const params = new URLSearchParams();

    for (let key in payload) {
      params.set(key, payload[key]);
    }
    return fetch(`${HRDOTP.HRDOTP_ORIGIN}${path}`, {
      method: 'POST',
      cache: 'no-cache',
      mode: 'cors',
      body: params
    })
      .then(response => response.text())
      .then(str => new window.DOMParser().parseFromString(str, 'text/xml'));
  }

  private getUT() {
    return this.hrdFrame.getUT();
  }
}
