import React from 'react';
import styled from '@emotion/styled';
import CourseCover from '../../assets/image/common/img-cover.jpg';

type ImageCardProps = {
  title: string;
  description?: string;
  imageUrl?: string;
  footer?: React.ReactNode;
  disabled?: boolean;
  height?: number;
};

const ImageCardWrapper = styled.div<{
  disabled?: boolean;
  height?: number;
  imageUrl: string;
}>`
  height: ${({ height }) => (height ? `${height}px` : '')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .image-container {
    width: 100%;
  }

  .image {
    background-image: url(${({ imageUrl }) => imageUrl});
    background-size: cover;
    padding-top: 56.25%;
    border-radius: 10px;
    border: 1px solid #eee;

    :hover {
      opacity: 0.6;
    }
  }

  .title {
    margin: 15px 0px 10px 0px;
    font-size: 25px;
    font-weight: bold;
    color: #455573;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    :hover {
      opacity: 0.6;
    }
  }

  .description {
    height: 40px;
    font-size: 13px;
    line-height: 1.54;
    color: #8c939f;
    margin-bottom: 16px;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const ImageCard: React.FC<ImageCardProps> = ({
  title,
  description,
  imageUrl = CourseCover,
  footer,
  disabled = false,
  height
}) => (
  <ImageCardWrapper disabled={disabled} height={height} imageUrl={imageUrl}>
    <div className="content">
      <div className="image-container">
        <div className="image" />
      </div>
      <div className="title" title={title}>
        {title}
      </div>
      <div className="description" title={description}>
        {description}
      </div>
    </div>
    <div className="footer">{footer}</div>
  </ImageCardWrapper>
);
