import React from 'react';
import styled from '@emotion/styled';

import { useController } from './controller';
import { GeneralTemplate } from '../../../../templates';

import { Classrooms } from '../../components/coplit/Classrooms';
import { ClassroomsStatusCard } from '../../components/coplit/ClassroomsStatusCard';
import { ClassroomsNotiCard } from '../../components/coplit/ClassroomsNotiCard';
import ErrorPage from '../../../../components/ErrorPage';
import NoContentPage from '../../../../components/NoContentPage';

type SectionLabelProps = {
  children: string | number | React.ReactNode;
};

const SectionLabelWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 600;
  color: #455573;
`;

const SectionLabel: React.FC<SectionLabelProps> = ({ children }) => (
  <SectionLabelWrapper>{children}</SectionLabelWrapper>
);

const ClassroomsPageWrapper = styled.div`
  .status {
    margin: 40px 0px;
  }

  .classrooms {
    margin-bottom: 100px;
  }
`;

const ClassroomsPage: React.FC = () => {
  const {
    classrooms,
    classroomsNoti,
    loading,
    error,
    hasNoClassroomError,
    totalCodeProblemCount,
    restCodeProblemCount
  } = useController();

  const renderError = () => {
    if (hasNoClassroomError) {
      return <NoContentPage />;
    }

    return <ErrorPage />;
  };

  return (
    <>
      {error ? (
        renderError()
      ) : (
        <GeneralTemplate loading={loading} error={!!error}>
          {{
            body: (
              <ClassroomsPageWrapper>
                <div className="status w3-row">
                  <div className="w3-half">
                    <ClassroomsStatusCard
                      totalCodeProblemCount={totalCodeProblemCount}
                      restCodeProblemCount={restCodeProblemCount}
                    />
                  </div>
                  <div className="w3-half">
                    <ClassroomsNotiCard classrooms={classroomsNoti} />
                  </div>
                </div>
                <SectionLabel>코플릿</SectionLabel>
                <div className="classrooms">
                  <Classrooms classrooms={classrooms} />
                </div>
              </ClassroomsPageWrapper>
            )
          }}
        </GeneralTemplate>
      )}
    </>
  );
};

export default ClassroomsPage;
