import gql from 'graphql-tag';

export const CARD_NEWS_LIST_GQL = gql`
  query urclassCardNewsList(
    $page: Int
    $perPage: Int
    $orderBy: CardNewsOrderByOptions
  ) {
    urclassCardNewsList(page: $page, perPage: $perPage, orderBy: $orderBy) {
      items {
        id
        uuid
        title
        description
        thumbnailURL
        url
        viewCount
      }
      total
    }
  }
`;

export const GUEST_CARD_NEWS_LIST_GQL = gql`
  query urclassGuestCardNewsList(
    $page: Int
    $perPage: Int
    $orderBy: CardNewsOrderByOptions
  ) {
    urclassGuestCardNewsList(
      page: $page
      perPage: $perPage
      orderBy: $orderBy
    ) {
      items {
        id
        uuid
        title
        description
        thumbnailURL
        url
        viewCount
      }
      total
    }
  }
`;
