import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as CheckedOnIcon } from '../../../assets/svg/ico-check-on2.svg';
import { ReactComponent as LoaderIcon } from '../../../assets/svg/ico-loader.svg';
import { ReactComponent as ClearIcon } from '../../../assets/svg/ico-clear.svg';
import { Typography, TypographyType } from '../../../components/atoms/Typography';

import { SocialAuthURI } from '../../../util';
import { ColorPaletteType } from '../../../styles/color';
import { useState } from 'react';

const { GithubAuth, GoogleAuth } = SocialAuthURI;

const SocialAccountViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .wrap__auth {
    background-color: #f2f5fd;
    padding: 6px 16px 6px 20px;
    border-radius: 99px;
    margin: 0 6px;
    min-width: 148px;
    .txt__auth {
      display: inline-block;
      vertical-align: middle;
      color: #295ce0;
    }
    .ico__auth--success {
      display: inline-block;
      margin-left: 11px;
      vertical-align: middle;
    }
  }
  .wrap__auth--clear {
    background-color: #ffeeeb;
    padding: 6px 16px 6px 20px;
    border-radius: 99px;
    margin: 0 6px;
    cursor: pointer;
    .txt__auth--clear {
      display: inline-block;
      vertical-align: middle;
      color: #ff360a;
    }
    .ico__auth--clear {
      display: inline-block;
      margin-left: 11px;
      vertical-align: middle;
    }
  }
  .link__auth--loading {
    background-color: #fff2e9;
    padding: 6px 16px 6px 20px;
    border-radius: 99px;
    margin: 0 6px;

    .txt__auth--loading {
      color: #ff6e0a;
      display: inline-block;
      vertical-align: middle;
    }
    .ico__auth--loading {
      display: inline-block;
      margin-left: 11px;
      vertical-align: middle;
    }
  }

  .txt__auth--github {
    margin-left: 17px;

    .txt__auth--github {
      display: inline-block;
      vertical-align: middle;
    }

    .github-mark {
      display: inline-block;
      margin-left: 11px;
      vertical-align: middle;
    }
  }

  @media screen and (max-width: 1094px) {
    display: block;
    align-items: center;

    .wrap__auth {
      display: block;
      margin: 12px 0;
      width: 176px;
    }
    .google-auth {
      display: block;
      margin-left: 0px;
      margin-bottom: 11px;
    }

    .github-auth {
      display: block;
      margin-left: 0px;
      margin-bottom: 11px;
    }

    .mark-block {
      margin-bottom: 11px;
    }
  }

  @media screen and (max-width: 375px) {
    .mark-block {
      display: none;
    }
  }
`;

type SoicalAccountViewProps = {
  isGithubAccountNeed: boolean;
  isGithubAuthenticated: boolean;
  isGoogleAuthenticated: boolean;
  handleDeleteAccount: () => void;
  githubAuthText: string;
  handleChangeGithubAuthText: (text: string) => void;
  isToggleAuthCheckIcon: boolean;
  handleChangeIsToogleCheckIcon: () => void;
};

const SocialAccountView: React.FC<SoicalAccountViewProps> = ({
  isGoogleAuthenticated,
  isGithubAccountNeed,
  isGithubAuthenticated,
  handleDeleteAccount,
  githubAuthText,
  handleChangeGithubAuthText,
  isToggleAuthCheckIcon,
  handleChangeIsToogleCheckIcon
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <SocialAccountViewWrapper>
      {isGoogleAuthenticated ? (
        <div className="wrap__auth">
          <Typography
            className="txt__auth"
            type={TypographyType.B1}
            color={ColorPaletteType.GRAY2}
            text="구글 인증 완료"
          />
          <CheckedOnIcon className="ico__auth--success" />
        </div>
      ) : (
        <a
          className="link__auth--loading"
          href={GoogleAuth}
          onClick={() => {
            if (!isGoogleAuthenticated) {
              localStorage.removeItem('authenticationType');
            }
          }}
        >
          <Typography
            className="txt__auth--loading"
            type={TypographyType.B1}
            color={ColorPaletteType.RED}
            text="구글 인증 필요"
          />
          <LoaderIcon className="ico__auth--loading" />
        </a>
      )}

      {/* 깃허브 계정 정보 */}
      {isGithubAuthenticated ? (
        <a
          href={!isGithubAuthenticated ? GithubAuth : '/mypage'}
          className={isHover ? 'wrap__auth wrap__auth--clear' : 'wrap__auth'}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => {
            setIsHover(false);
          }}
          onClick={() => {
            if (!isGithubAuthenticated) {
              localStorage.removeItem('authenticationType');
            } else {
              handleDeleteAccount();
            }
          }}
        >
          {!isHover ? (
            <>
              <Typography
                className="txt__auth"
                type={TypographyType.B1}
                color={ColorPaletteType.GRAY2}
                text="Github 인증 완료"
              />
              <CheckedOnIcon className="ico__auth--success" />
            </>
          ) : (
            <>
              <Typography
                className="txt__auth--clear"
                type={TypographyType.B1}
                color={ColorPaletteType.GRAY2}
                text="Github 인증 해제"
              />
              <ClearIcon className="ico__auth--clear" />
            </>
          )}
        </a>
      ) : (
        <a
          className="link__auth--loading"
          href={GithubAuth}
          onClick={() => {
            if (!isGithubAuthenticated) {
              localStorage.removeItem('authenticationType');
            } else {
              handleDeleteAccount();
            }
          }}
        >
          <Typography
            className="txt__auth--loading"
            type={TypographyType.B1}
            color={ColorPaletteType.RED}
            text="Github 인증 필요"
          />
          <LoaderIcon className="ico__auth--loading" />
        </a>
      )}
    </SocialAccountViewWrapper>
  );
};

export default SocialAccountView;
