import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import SplitPane from 'react-split-pane';
import MonacoEditor from '@monaco-editor/react';

import { useController } from './controller';
import { CodeEditorTemplate } from '../../../../templates';

import { MarkdownPreviewer } from '../../components/common/StyledMarkdown';
import { CodeEditorConsole } from '../../components/coplit/CodeEditorConsole';
import { CodeEditorSubmitModalCard } from '../../components/coplit/CodeEditorSubmitModalCard';
import { CodeEditorSolutionModalCard } from '../../components/coplit/CodeEditorSolutionModalCard';
import { CodeEditorFooter } from '../../components/coplit/CodeEditorFooter';

import { CodeProblemPageWrapper } from './style';

const FIXED_COMPONENT_HEIGHT = 275;
const DEFAULT_SCREEN_WIDTH = Math.floor(window.innerWidth * 0.33);
const DEFAULT_SCREEN_HEIGHT = Math.floor(window.innerHeight * 0.5);
const DEFAULT_SCREEN_MIN_WIDTH = 400;

const CodeProblemPage: React.FC = () => {
  const controller = useController();

  const editorOption = {
    minimap: {
      enabled: false
    },
    fontSize: 15,
    tabSize: 2,
    scrollbar: {
      verticalScrollbarSize: 10
    },
    automaticLayout: true,
    allowEditorOverflow: false
  };

  useEffect(() => {
    if (window.outerWidth < DEFAULT_SCREEN_MIN_WIDTH) {
      Modal.warning({
        title: '해당 기능은 모바일 환경을 지원하지 않습니다.',
        content: '데스크탑 혹은 테블릿기기를 이용해주세요.'
      });
    }

    if (!controller.editorWidth && !controller.editorHeight) {
      controller.setEditorWidth(DEFAULT_SCREEN_WIDTH);
      controller.setEditorHeight(DEFAULT_SCREEN_HEIGHT);
    }
    const resizeEvent = window.addEventListener('resize', controller.handleEditorResizing);
    return resizeEvent;
  }, []);

  return (
    <CodeEditorTemplate>
      {{
        body: (
          <CodeProblemPageWrapper>
            <div className="editor-header">
              <Link to={`/classroom/${controller.codeProblemClassroomId}`}>
                <ArrowLeftOutlined className="icon" />
                <span className="problem-title">{controller.codeProblemTitle}</span>
              </Link>
            </div>
            <div className="editor-body">
              <SplitPane
                className="split"
                split="vertical"
                defaultSize={DEFAULT_SCREEN_WIDTH}
                minSize={DEFAULT_SCREEN_MIN_WIDTH}
                onChange={controller.handleEditorResizing}
              >
                <div className="codeproblem-description">
                  <MarkdownPreviewer markdownBody={controller.codeProblemDescription} />
                </div>

                <SplitPane
                  split="horizontal"
                  defaultSize={DEFAULT_SCREEN_HEIGHT}
                  maxSize={window.innerHeight - DEFAULT_SCREEN_MIN_WIDTH}
                  onChange={controller.handleEditorResizing}
                >
                  <div className="codeproblem-editor" ref={controller.editorSection}>
                    <MonacoEditor
                      width={controller.editorWidth}
                      height={controller.editorHeight}
                      language={controller.codeProblemLanguage}
                      onChange={newValue => controller.setCode(newValue ?? '')}
                      options={editorOption}
                      value={controller.code}
                    />
                  </div>
                  <div className="codeproblem-console" ref={controller.codeEditorConsoleRef}>
                    <div className="codeproblem-console-title">실행 결과</div>
                    <div
                      className="codeproblem-console-content"
                      style={{
                        height: `calc(100vh - ${controller.editorHeight}px - ${FIXED_COMPONENT_HEIGHT}px)`
                      }}
                    >
                      <CodeEditorConsole testingResult={controller.testingResult} />
                    </div>
                  </div>
                </SplitPane>
              </SplitPane>
            </div>
            <CodeEditorFooter
              templateCode={controller.codeProblemTemplateCode}
              nextCodeProblemUuid={controller.nextCodeProblemUuid}
              prevCodeProblemUuid={controller.prevCodeProblemUuid}
              handleClickSubmit={controller.handleClickSubmit}
              handleClickTest={controller.handleClickTest}
              handleClickReference={controller.handleClickReference}
              isTestRunning={controller.isTestRunning}
              setCode={controller.setCode}
            />

            <Modal
              visible={controller.submitModalVisible}
              title="제출 하기"
              footer={false}
              onCancel={controller.handleSubmitModalClose}
            >
              <CodeEditorSubmitModalCard
                handleSubmitModalClose={controller.handleSubmitModalClose}
                testingResult={controller.testingResult}
                submitCodeProblem={controller.handleSubmitCodeProblem}
              />
            </Modal>

            <Modal
              className="wrapper__modal"
              visible={controller.referenceModalVisible}
              title="Reference Code"
              footer={false}
              onCancel={controller.handleReferenceModalClose}
              width={700}
            >
              <CodeEditorSolutionModalCard
                isSubmitted={controller.isSubmitted}
                codeProblemId={controller.id}
                codeProblemLanguage={controller.codeProblemLanguage}
              />
            </Modal>
          </CodeProblemPageWrapper>
        )
      }}
    </CodeEditorTemplate>
  );
};

export default CodeProblemPage;
