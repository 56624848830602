import gql from 'graphql-tag';

export const URCLASS_TEMPARARY_LOGIN_GQL = gql`
  mutation urclassTempararyLogin($uuid: String!) {
    urclassTempararyLogin(uuid: $uuid) {
      token
    }
  }
`;

export const LOGIN_EMAIL_GQL = gql`
  mutation urclassLoginEmail($input: Urclass_EmailLoginInput!) {
    urclassLoginEmail(input: $input) {
      token
    }
  }
`;
export const LOGIN_GITHUB_GQL = gql`
  mutation loginGithub($code: String!, $redirectURI: String!) {
    urclassLoginGithub(code: $code, redirectURI: $redirectURI) {
      token
    }
  }
`;

export const LOGIN_GOOGLE_GQL = gql`
  mutation loginGoogle($code: String!, $redirectURI: String!) {
    urclassLoginGoogle(code: $code, redirectURI: $redirectURI) {
      token
    }
  }
`;

export const LOGIN_NAVER_GQL = gql`
  mutation loginNaver($code: String!, $redirectURI: String!) {
    urclassLoginNaver(code: $code, redirectURI: $redirectURI) {
      token
    }
  }
`;

export const LOGIN_KAKAO_GQL = gql`
  mutation loginKakao($code: String!, $redirectURI: String!) {
    urclassLoginKakao(code: $code, redirectURI: $redirectURI) {
      token
      userLoginMetrics {
        loginCount
        uuid
        recentlyLoginedAt
      }
    }
  }
`;

export const SIGN_UP_GQL = gql`
  mutation urclassSignUp(
    $name: String!
    $mobile: String!
    $email: String!
    $marketingUseAgreement: Boolean!
    $serviceTermsAgreement: Boolean!
    $privacyTermsAgreement: Boolean!
    $receivingMessagesAgreement: Boolean!
    $service: SocialProvider!
    $accessToken: String!
    $mediaType: String
  ) {
    urclassSignUp(
      name: $name
      mobile: $mobile
      email: $email
      marketingUseAgreement: $marketingUseAgreement
      serviceTermsAgreement: $serviceTermsAgreement
      privacyTermsAgreement: $privacyTermsAgreement
      receivingMessagesAgreement: $receivingMessagesAgreement
      service: $service
      accessToken: $accessToken
      mediaType: $mediaType
    ) {
      token
      user {
        uuid
      }
    }
  }
`;

export const ADD_SOCIAL_ACCOUNT_GQL = gql`
  mutation addSocialAccount($service: SocialProvider!, $redirectURI: String!, $code: String!) {
    urclassAddSocialAccount(service: $service, redirectURI: $redirectURI, code: $code)
  }
`;

export const LOG_OUT_GQL = gql`
  mutation urclassLogOut {
    urclassLogOut
  }
`;

export const LOGIN_IDENTITY_VERIFICATION_CODE_GQL = gql`
  mutation urclassLoginIdentityVerificationCode($code: String!, $uuid: String!) {
    urclassLoginIdentityVerificationCode(code: $code, uuid: $uuid) {
      token
    }
  }
`;
