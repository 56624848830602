export enum ZkStatus {
  NONE = 'NONE',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}
export enum InActiveDayType {
  WEEKEND = 'WEEKEND',
  HOLIDAY = 'HOLIDAY'
}
export enum CheckInHistoryCategory {
  CHECKIN = 'CHECKIN',
  CHECKOUT = 'CHECKOUT',
  CHECKOUT_BATCH = 'CHECKOUT_BATCH',
  CHECKIN_BY_ADMIN = 'CHECKIN_BY_ADMIN'
}

export enum AttendanceType {
  NONE = 'none',
  QR = 'qr',
  CHECK_IN_OUT = 'checkInOut'
}

export enum QrStatus {
  NONE = 'none',
  IN_CLASS_GUIDE = 'inClassGuide',
  CHECK_IN_GUIDE = 'checkInGuide',
  CHECK_OUT_GUIDE = 'checkOutGuide',
  INACTIVE = 'inactive'
}

export type Bootcamp = {
  id: number;
  name: string;
  isConfigSet: boolean;
  trainingStartDate?: Date;
  trainingEndDate?: Date;
  isTrainingNow?: boolean;
  isActiveAttendanceSystem?: boolean;
};

export type MyLearningStatusData = {
  urclassMyLearning: {
    userId: number;
    name: string;
    isCheckIn: boolean;
    zkStatus: ZkStatus;
    inActiveDayType?: InActiveDayType;
    bootcamp?: Bootcamp;
    myCheckInHistoryList: CheckInHistoryList;
    attendanceType: AttendanceType;
    qrConfig: QrConfig;
    checkInOutConfig: CheckInOutConfig;
  };
};
export type MyCheckInHistory = {
  id: number;
  uuid: string;
  revisionedAt: Date;
  category: CheckInHistoryCategory;
  ip?: string;
  userAgent?: string;
  clientType?: string;
  clientName?: string;
  deviceInformation?: string;
  os?: string;
};
export type QrConfig = {
  __typename: string;
  qrCodeImageUrl: string | null;
  qrStatus: QrStatus;
};
export type CheckInOutConfig = {
  isCheckIn: boolean;
  zkStatus: ZkStatus;
};
export type CheckInHistoryList = {
  total: number;
  items: MyCheckInHistory[];
};
export type MyCheckInHistoryListSearchQueryLessThanOrEqual = {
  revisionedAt?: Date;
};
export type MyCheckInHistoryListSearchQueryGreaterThanOrEqual = {
  revisionedAt?: Date;
};
export type MyCheckInHistoryListQuery = {
  lt?: MyCheckInHistoryListSearchQueryLessThanOrEqual;
  gt?: MyCheckInHistoryListSearchQueryGreaterThanOrEqual;
};

export type MyLearningStatusVar = {
  page?: number;
  perPage?: number;
  query?: MyCheckInHistoryListQuery;
};

export type MyIssuableCertificateData = {
  urclassMyIssuableCertificates: {
    name: string;
    bootcampName: string;
  }[];
};
