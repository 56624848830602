import { loadTossPayments } from '@tosspayments/sdk';
import Urls from '../../util/urls';

type PaymentData = {
  orderName: string;
  amount: number;
  orderId: string;
  customerName: string;
  customerEmail: string;
  customerMobilePhone?: string;
  taxFreeAmount: number;
  payMethod: 'card' | 'vbank';
  isOnetimePayment?: boolean;
  repay?: boolean;
  productUuid?: string;
};

export const requestTossPayment = async (paymentData: PaymentData) => {
  const repay = paymentData?.repay || false;
  const productUuid = paymentData?.productUuid || '';
  const successUrl =
    window.location.origin +
    (!repay ? '/payment/success?repay=false' : `/payment/success?repay=true&productUuid=${productUuid}`);

  const tosspayments = await loadTossPayments(
    process.env.REACT_APP_TOSS_PAYMENT_CLIENT_KEY || 'live_ck_k6bJXmgo28ev5anxlbe3LAnGKWx4'
  );
  const { orderName, amount, orderId, customerName, customerEmail, customerMobilePhone, taxFreeAmount, payMethod } =
    paymentData;

  switch (payMethod) {
    case 'card': {
      await tosspayments.requestPayment('카드', {
        orderName,
        amount,
        orderId,
        customerName,
        customerEmail,
        // 면세
        taxFreeAmount,
        successUrl,
        failUrl: window.location.origin + '/payment/fail'
      });
      break;
    }
    case 'vbank': {
      const isKorMobile =
        !!customerMobilePhone?.match(/^01[0-9]-?[0-9]{3,4}-?[0-9]{4}$/) ||
        !!customerMobilePhone?.match(/^\+82 1[0-9]-?[0-9]{3,4}-?[0-9]{4}$/);
      const formattedMobile = customerMobilePhone?.replace(/\+82 /, '0').split('-').join('');

      const onetimePaymentUrl = !repay
        ? 'tosspayments/v1/vbank/onetime/callback'
        : 'tosspayments/v1/vbank/onetime/repay/callback';
      const originPaymentUrl = !repay ? 'tosspayments/v1/vbank/callback' : 'tosspayments/v1/vbank/repay/callback';

      const virtualAccountCallbackUrl = Urls(paymentData.isOnetimePayment ? onetimePaymentUrl : originPaymentUrl).http;

      await tosspayments.requestPayment('가상계좌', {
        orderName,
        amount,
        orderId,
        customerName,
        customerEmail,

        virtualAccountCallbackUrl,
        customerMobilePhone: isKorMobile ? formattedMobile : undefined,
        useEscrow: false,
        taxFreeAmount, // 면세 옵션
        successUrl,
        failUrl: window.location.origin + '/payment/fail',
        // 가상계좌 옵션
        validHours: 24
      });
      break;
    }
    default: {
      throw new Error('결제 방식은 카드 또는 가상계좌만 선택 가능합니다.');
    }
  }
};
