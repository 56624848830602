import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ISSUE_EMAIL_AUTH_CODE_GQL,
  UPDATE_USER_PROFILE_GQL,
  UPDATE_VERIFIED_EMAIL_GQL,
  VERIFY_EMAIL_AUTH_CODE_GQL
} from '../../apollo/user/mutation';
import {
  AuthCodeStatus,
  IssueEmailAuthCodeData,
  IssueEmailAuthCodeVars,
  UpdateUserProfileData,
  UpdateUserProfileVars,
  UpdateVerifiedEmailData,
  UpdateVerifiedEmailVars,
  VerifyEmailAuthCodeData,
  VerifyEmailAuthCodeVars
} from '../../apollo/user/type';
import { useCredential } from '../../contexts/credential';
import { makeToast } from '../../util/makeToast';
import { initialState, reducer } from './reducer';

const convertSecondToMSSFormat = (sec: number) => {
  const minute = Math.floor(sec / 60);
  const restSecond = sec % 60;
  return `${minute}:${restSecond > 9 ? restSecond : `0${restSecond}`}`;
};

// 이름은 한글만 입력 가능
const nameRegExp = /^[ㄱ-ㅎ|가-힣]*$/;
// 숫자와 +,-, 공백 가능
const mobileRegExp = /^[0-9 +-]*$/;

// YYYY.MM.DD 형식
const birthdayRegExp = /^\d{4}\.(0?[1-9]|1[012])\.(0?[1-9]|[12][0-9]|3[01])$/;

// email validator
const emailRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const useController = () => {
  const history = useHistory();
  const { profile } = useCredential();
  const [updateUserProfile] = useMutation<UpdateUserProfileData, UpdateUserProfileVars>(UPDATE_USER_PROFILE_GQL);
  const [issueEmailAuthCode, { loading: issueEmailAuthCodeLoading }] = useMutation<
    IssueEmailAuthCodeData,
    IssueEmailAuthCodeVars
  >(ISSUE_EMAIL_AUTH_CODE_GQL);
  const [verifyEmailAuthCode] = useMutation<VerifyEmailAuthCodeData, VerifyEmailAuthCodeVars>(
    VERIFY_EMAIL_AUTH_CODE_GQL
  );
  const [updateVerifiedEmail] = useMutation<UpdateVerifiedEmailData, UpdateVerifiedEmailVars>(
    UPDATE_VERIFIED_EMAIL_GQL
  );
  const [state, dispatch] = useReducer(reducer, initialState);

  const { name, mobile, birthday, email, authCode } = state;

  const isNameValid = nameRegExp.test(name) && name.length > 1;

  const isMobileValid = mobile.length > 9 && mobileRegExp.test(mobile);

  const isBirthdayValid = birthdayRegExp.test(birthday);

  const isEmailValid = !!email.length && emailRegExp.test(email);

  const isFormValid = isNameValid && isMobileValid && isBirthdayValid;

  const formatBirthday = (input?: string) => {
    if (!input) {
      return '';
    }

    const filtered = input.replace(/[^0-9]/g, '');

    // 숫자로 이루어진 생년월일 문자열을 "."으로 연,월,일 분리
    const splitted = filtered.split('');
    splitted.splice(4, 0, '.');
    splitted.splice(7, 0, '.');

    return splitted.join('').split('.').filter(Boolean).join('.');
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'CHANGE_NAME_INPUT_VALUE', payload: { name: e.target.value } });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'CHANGE_EMAIL_INPUT_VALUE', payload: { email: e.target.value } });
  };

  const handleAuthCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 6) {
      // 6자리 이상 입력  무시
      return;
    }
    dispatch({ type: 'CHANGE_AUTH_CODE_INPUT_VALUE', payload: { authCode: e.target.value } });
  };

  const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // TODO : XXX-XXXX-XXXX 형식으로 자동포맷.

    if (mobileRegExp.test(e.target.value)) {
      dispatch({ type: 'CHANGE_MOBILE_INPUT_VALUE', payload: { mobile: e.target.value } });
    }
  };

  const handleBirthdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    // 생년월일 입력값에서 숫자만 필터링

    const formattedBirthday = formatBirthday(input);

    if (formattedBirthday.length > 10) {
      return;
    }

    dispatch({
      type: 'CHANGE_BIRTHDAY_INPUT_VALUE',
      payload: {
        birthday: formattedBirthday
      }
    });
  };

  const handleSubmitUserProfile = async () => {
    try {
      await updateUserProfile({ variables: { input: { name, mobile, birthday } } });
      makeToast({ type: 'success', content: '프로필 변경에 성공 하였습니다.' });

      history.push('/mypage');
    } catch (e) {
      makeToast({ type: 'error', content: '프로필 변경에 실패 하였습니다.' });
    }
  };

  const handleIssueEmailAuthCode = async () => {
    try {
      // code 발급 및 전송
      await issueEmailAuthCode({ variables: { email } });

      // 이미 시간초 흐르고 있으면 삭제
      if (state.timer) {
        clearInterval(state.timer);
      }

      // 타이머 시작
      const timer = setInterval(() => {
        dispatch({ type: 'TICK_AUTH_TIME' });
      }, 1000);

      // 재전송 인지 확인.
      if (state.authCodeStatus === AuthCodeStatus.SENT) {
        dispatch({ type: 'CLICK_RE_SEND_AUTH_CODE', payload: { timer } });
      } else {
        dispatch({ type: 'CLICK_SEND_AUTH_CODE', payload: { timer } });
      }
    } catch {
      makeToast({ type: 'error', content: '인증코드 전송에 실패 하였습니다.' });
    }
  };
  //인증과 동시에 이메일 업데이트
  const handleVerifyEmailAuthCode = async () => {
    try {
      await verifyEmailAuthCode({ variables: { input: { email, code: authCode } } });
      if (state.timer) {
        clearInterval(state.timer);
      }
      await updateVerifiedEmail({ variables: { input: { email, code: authCode } } });
      dispatch({ type: 'SUCCESS_VERIFY_EMAIL_AUTH_CODE' });
      dispatch({ type: 'EDITING_VIEW' });
      makeToast({ type: 'success', content: `이메일 ${profile?.isEmailVerified ? '수정' : '인증'}을 완료했습니다.` });
    } catch (e) {
      dispatch({ type: 'FAIL_VERIFY_EMAIL_AUTH_CODE' });
    }
  };

  useEffect(() => {
    if (profile) {
      dispatch({
        type: 'INIT_PROFILE',
        payload: {
          email: profile.email,
          name: profile.name,
          birthday: formatBirthday(profile.birthday),
          mobile: profile.mobile
        }
      });
    }
  }, [profile]);

  return {
    issueEmailAuthCodeLoading,

    handleAuthCodeChange,
    handleSubmitUserProfile,
    handleNameChange,
    handleEmailChange,
    handleMobileChange,
    handleVerifyEmailAuthCode,
    handleIssueEmailAuthCode,
    handleBirthdayChange,
    convertSecondToMSSFormat,

    isEmailValid,
    isFormValid,

    profile,
    state,
    dispatch
  };
};
