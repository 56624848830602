import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import throttle from 'lodash/throttle';
import moment from 'moment';

import { useCredential } from '../../contexts/credential';

import { MY_APPLICATIONS_GQL } from '../../apollo/mypage/query';
import { CARD_NEWS_LIST_GQL } from '../../apollo/cardNews/query';
import { INCREASE_CARD_NEWS_VIEW_COUNT_GQL } from '../../apollo/cardNews/mutation';
import { OrderType } from '../../apollo/cardNews/type';
import { ApplyingStatus } from '../../apollo/mypage/type';

import { eventCallbackOnScreenFrame, store } from '../../util';

import type {
  CardNewsListData,
  CardNewsListVars,
  IncreaseCardNewsViewCountData,
  IncreaseCardNewsViewCountVars
} from '../../apollo/cardNews/type';
import type { MyApplicationsData, Application } from '../../apollo/mypage/type';

export const useController = () => {
  const applyUuid = store.applyUuid.get();
  const applyProductName = store.applyProductName.get();
  const serviceStartDate = store.serviceStartDate.get();
  const [newestFilterPage, setNewestFilterPage] = useState(1);
  const [viewCountFilterPage, setViewCountFilterPage] = useState(1);
  const [notificationVisible, setNotificationVisible] = useState(true);
  const [orderBy, setOrderBy] = useState<OrderType | undefined>();
  const { isLogin, profile, loadingProfile } = useCredential();

  const {
    data,
    loading: cardNewsListLoading,
    error: cardNewsError,
    fetchMore
  } = useQuery<CardNewsListData, CardNewsListVars>(CARD_NEWS_LIST_GQL, {
    variables: {
      perPage: 12,
      orderBy: orderBy ? { viewCount: orderBy } : undefined
    }
  });

  const {
    data: myApplicationData,
    loading: myApplicationLoading,
    error: myApplicationDataError
  } = useQuery<MyApplicationsData>(MY_APPLICATIONS_GQL);

  const [urclassIncreaseCardNewsViewCount] = useMutation<IncreaseCardNewsViewCountData, IncreaseCardNewsViewCountVars>(
    INCREASE_CARD_NEWS_VIEW_COUNT_GQL
  );
  const handleScrollEvent = (page: number, currentCount: number, total: number, setPage: Function) => {
    const pageHeight = window.document.documentElement.scrollHeight;
    const screenHeight = window.document.documentElement.offsetHeight;
    const scrollTop = window.pageYOffset;

    if (screenHeight + scrollTop >= pageHeight - 450) {
      if (currentCount < total) {
        fetchMore({
          variables: {
            page: page + 1,
            perPage: 12,
            orderBy: orderBy ? { viewCount: orderBy } : undefined
          },
          updateQuery: (previousQueryResult: CardNewsListData, { fetchMoreResult }: any) => {
            setPage(page + 1);
            return {
              urclassCardNewsList: {
                items: [...previousQueryResult.urclassCardNewsList.items, ...fetchMoreResult.urclassCardNewsList.items],
                total: fetchMoreResult.urclassCardNewsList.total,
                __typename: '_CardNewsList'
              }
            };
          }
        });
      }
    }
  };

  const handleClickCardNews = async (cardNewsId: number) => {
    await urclassIncreaseCardNewsViewCount({
      variables: { cardNewsId }
    });
  };

  const handleChangeOrdering = (value: string | number | undefined) => {
    setOrderBy(value ? (value as OrderType) : undefined);
  };

  const handleCloseHeaderNotification = () => {
    setNotificationVisible(false);
    store.applyProductName.remove();
    store.applyUuid.remove();
    store.serviceStartDate.remove();
  };

  useEffect(() => {
    const handler = throttle(
      eventCallbackOnScreenFrame(() => {
        const cardNewsCnt = data && data.urclassCardNewsList ? data.urclassCardNewsList.items.length : 0;
        const total = data && data.urclassCardNewsList ? data.urclassCardNewsList.total : 0;

        handleScrollEvent(
          orderBy ? viewCountFilterPage : newestFilterPage,
          cardNewsCnt,
          total,
          orderBy ? setViewCountFilterPage : setNewestFilterPage
        );
      }, {}),
      400
    );

    window.addEventListener('scroll', handler, {
      passive: true
    });
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [data]);

  const cardNewsList = data && data.urclassCardNewsList ? data.urclassCardNewsList.items : [];

  const isApplicationProcess =
    myApplicationData &&
    myApplicationData.urclassMyApplications.find(
      (application: Application) => application.applyingStatus === ApplyingStatus.APPLYING
    );

  const loading = loadingProfile || cardNewsListLoading || myApplicationLoading;
  const error = cardNewsError || myApplicationDataError;
  const displayAccountAuthHeaderNotification = isLogin && profile && !profile.googleId;
  const displayApplicationHeaderNotification =
    isLogin &&
    serviceStartDate &&
    moment(serviceStartDate).diff(moment(), 'days') >= 0 &&
    applyUuid &&
    applyProductName &&
    notificationVisible;
  const displayApplicationStepLinkBanner = isApplicationProcess;
  const displayGotoWebsiteBanner = !myApplicationData?.urclassMyApplications.length;

  return {
    orderBy,
    applyProductName,
    applyUuid,

    loading,
    error,
    cardNewsList,

    displayAccountAuthHeaderNotification,
    displayApplicationHeaderNotification,
    displayApplicationStepLinkBanner,
    displayGotoWebsiteBanner,

    handleChangeOrdering,
    handleClickCardNews,
    handleCloseHeaderNotification
  };
};
