import React from 'react';
import styled from '@emotion/styled';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import { BlockMessage } from '../common/BlockMessage';

import type { TestingResult, FieldTestingDetailResult } from '../../apollo/coplit/type';

const { Panel } = Collapse;

interface ICodeEditorConsole {
  testingResult?: TestingResult;
}

const CodeEditorConsoleWrapper = styled.div`
  margin: 15px;
  padding-bottom: 30px;
`;

const TeestingResultMessage = styled.div`
  margin-bottom: 10px;
  font-weight: 600;
`;

const ConsoleContent = styled.div<{ isPass?: boolean }>`
  .title {
    font-weight: 700;
    font-size: 16px;
  }

  pre {
    margin: 10px 0px 20px 0px;
    background-color: ${({ isPass }) => (isPass ? '#EFF3EE' : '#fef2f4')};
    padding: 14px;
  }
`;

export const CodeEditorConsole: React.FC<ICodeEditorConsole> = ({ testingResult }) => {
  const displayTestingResult = (testingResult: TestingResult) => {
    const { isAllPassed, runtimeError, isInfinityLoop, contents } = testingResult;

    if (isAllPassed) {
      return (
        <>
          <TeestingResultMessage>모든 테스트를 통과했습니다.</TeestingResultMessage>
          <Collapse
            style={{ backgroundColor: '#fbfbfb' }}
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          >
            {contents.map((result: FieldTestingDetailResult, idx: number) => (
              <Panel
                header={result.title}
                key={idx}
                style={{
                  background: 'rgba(55, 197, 85, 0.16)',
                  borderRadius: 4,
                  marginBottom: 15,
                  border: '1px solid rgba(55, 197, 85, 1)',
                  overflow: 'hidden'
                }}
              >
                <ConsoleContent isPass={true}>
                  <div className="title">Test Code</div>
                  <pre>{result.test}</pre>
                </ConsoleContent>
              </Panel>
            ))}
          </Collapse>
        </>
      );
    }

    if (runtimeError) {
      return (
        <>
          <TeestingResultMessage>문법 오류가 있습니다. 코드를 다시한번 확인해주세요.</TeestingResultMessage>
          <ConsoleContent isPass={false}>
            <pre>{runtimeError}</pre>
          </ConsoleContent>
        </>
      );
    }

    if (isInfinityLoop) {
      return <TeestingResultMessage>실행 시간을 초과하였습니다. 코드를 다시한번 확인해주세요.</TeestingResultMessage>;
    }

    return (
      <>
        <TeestingResultMessage>
          {`${testingResult.contents.length} 개 테스트 중 ${
            testingResult.contents.filter(result => result.isPassed).length
          } 개 통과했습니다.`}
        </TeestingResultMessage>
        <Collapse
          style={{ backgroundColor: '#fbfbfb' }}
          bordered={false}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
          {contents.map((result: FieldTestingDetailResult, idx: number) => (
            <Panel
              header={result.title}
              key={idx}
              style={{
                background: result.isPassed ? 'rgba(55, 197, 85, 0.16)' : 'rgba(255, 192, 203, 0.44)',
                borderRadius: 4,
                marginBottom: 15,
                border: `1px solid ${result.isPassed ? 'rgba(55, 197, 85, 1)' : 'rgba(255, 92, 92, 1)'}`,
                overflow: 'hidden',
                whiteSpace: 'pre'
              }}
            >
              <ConsoleContent>
                <div className="title">Test Result</div>
                <pre>{result.detail}</pre>
              </ConsoleContent>

              <ConsoleContent>
                <div className="title">Test Code</div>
                <pre>{result.test}</pre>
              </ConsoleContent>
            </Panel>
          ))}
        </Collapse>
      </>
    );
  };

  return (
    <CodeEditorConsoleWrapper>
      {testingResult ? displayTestingResult(testingResult) : <BlockMessage>테스트 결과가 없습니다.</BlockMessage>}
    </CodeEditorConsoleWrapper>
  );
};
