import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { ReactComponent as Logo } from '../../../assets/svg/logo-urclass.svg';
import { ReactComponent as ProfileAvatar } from '../../../assets/svg/ico-profile-avatar.svg';
import { ReactComponent as ProfileCircle } from '../../../assets/svg/ico-profile-circle.svg';

import { ColorPalette } from '../../../styles/color';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 36px;

  height: 65px;
  background-color: rgb(251, 251, 251);

  box-shadow: 0 0.5px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: ${ColorPalette.White};

  .apply-header__content--logo {
    width: 112px;
    height: 28px;
  }

  .profile-icon {
    position: absolute;
    top: 12px;
    right: 10px;
    cursor: pointer;

    .icon__profile-avatar {
      display: inline-block;
      position: absolute;
      margin-top: 8px;
      right: 27px;
    }
    .icon__profile-border {
      display: inline-block;
      position: absolute;
      right: 18px;
    }
  }

  @media screen and (max-width: 992px) {
    padding: 0px 40px;
  }
`;

const Header: React.FC = () => {
  const history = useHistory();

  return (
    <HeaderWrapper>
      <Link to="/">
        <Logo className="apply-header__content--logo" />
      </Link>

      <div
        className="profile-icon"
        onClick={() => {
          history.push('/mypage');
        }}
      >
        <ProfileCircle className="icon__profile-border" />
        <ProfileAvatar className="icon__profile-avatar" />
      </div>
    </HeaderWrapper>
  );
};

export default Header;
