import React from 'react';
import { Result } from 'antd';
import styled from '@emotion/styled';

type ApplySuccessProps = {
  email?: string;
  programId: number;
};

const ApplySuccessWrapper = styled.div`
  .ant-result-content {
    background-color: #f3f3f3;
  }

  .success-message {
    text-align: center;
    font-size: 20px;
    word-break: keep-all;
    margin-bottom: 0;

    .focus-text {
      font-weight: 700;
      color: #2e66fd;
    }
  }
`;

const ApplySuccess: React.FC<ApplySuccessProps> = props => {
  return (
    <ApplySuccessWrapper>
      <Result
        className="cover"
        status="success"
        title={'1단계 지원서 제출에 성공했습니다.'}
      >
        {props.programId === 6 ? (
          <p className="success-message">
            마이페이지로 이동해 다음 단계를 진행해 주세요.
          </p>
        ) : props.email ? (
          <p className="success-message">
            <span className="focus-text">지원 절차 및 일정에 관한 안내</span>가{' '}
            {props.email}로 발송되었으니 꼭 확인 부탁드립니다.
          </p>
        ) : (
          <p className="success-message">
            자세한 정보는 이메일로 전달되었습니다. 확인 부탁드립니다.
          </p>
        )}
      </Result>
    </ApplySuccessWrapper>
  );
};

export default ApplySuccess;
