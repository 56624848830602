import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import BasicTab from '../../atoms/Tab/BasicTab';
import { loginState } from '../../../apollo/client';

type HeaderTabGroupProps = {
  isLogin: boolean;
};

const HeaderTabGroupWrapper = styled.div``;

const HeaderTabGroup: React.FC<HeaderTabGroupProps> = ({ isLogin }) => {
  const history = useHistory();

  const handleClickMypage = () => {
    history.push('/mypage');
  };

  const handleClickDashboard = () => {
    history.push('/');
  };

  const handleClickLogout = () => {
    loginState(false).then(() => history.push('/signin'));
  };

  return (
    <HeaderTabGroupWrapper>
      {isLogin && (
        <div>
          <BasicTab onClick={handleClickDashboard}>채용</BasicTab>
          <BasicTab onClick={handleClickMypage}>Mypage</BasicTab>
          <BasicTab onClick={handleClickLogout}>Logout</BasicTab>
        </div>
      )}
    </HeaderTabGroupWrapper>
  );
};

export default HeaderTabGroup;