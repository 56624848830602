import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { CourseStatus } from '../../apollo/course/type';
import { GET_COURSES_OF_USER_GQL } from '../../apollo/course/query';

import type { CoursesOfUser, Course } from '../../apollo/course/type';

export const useController = () => {
  const history = useHistory();
  const [activeCourses, setActiveCourses] = useState<Course[]>([]);
  const [scheduledCourses, setScheduledCourses] = useState<Course[]>([]);
  const [expiredCourses, setExpiredCourses] = useState<Course[]>([]);

  const { data, loading, error } = useQuery<CoursesOfUser>(GET_COURSES_OF_USER_GQL);

  useEffect(() => {
    if (history.location.pathname === `/courses` || history.location.pathname === `/courses/`) {
      history.replace(`/courses/active`);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (data && data.urclassCoursesOfUser) {
      const active: Course[] = [];
      const scheduled: Course[] = [];
      const expired: Course[] = [];

      data.urclassCoursesOfUser.forEach((course: Course) => {
        switch (course.status) {
          case CourseStatus.ACTIVE:
            active.push(course);
            return;

          case CourseStatus.SCHEDULED:
            scheduled.push(course);
            return;

          default:
            expired.push(course);

            return;
        }
      });

      setActiveCourses(active);
      setScheduledCourses(scheduled);
      setExpiredCourses(expired);
    }
  }, [data]);

  return {
    loading,
    error,
    activeCourses,
    scheduledCourses,
    expiredCourses
  };
};
