import React from 'react';
import styled from '@emotion/styled';

import Grid from '../../atoms/Grid';
import { OptionContainer } from '../../atoms/Container';
import { GuideMsg, OptionTitle } from './style';

import type { PaymentModel } from '../../../apollo/product/type';

type PaymentModelOptionsProps = {
  paymentModels: PaymentModel[];
  selectedPaymentModel: PaymentModel | undefined;
  setSelectedPaymentModel: any;
};

const PaymentModelOptionsWrapper = styled.div`
  margin-bottom: 80px;
`;

const PaymentModelOptions: React.FC<PaymentModelOptionsProps> = ({
  paymentModels,
  selectedPaymentModel,
  setSelectedPaymentModel
}) => (
  <PaymentModelOptionsWrapper>
    <OptionTitle>결제 모델</OptionTitle>
    {paymentModels.length ? (
      <Grid itemWidth={250}>
        {paymentModels.map((paymentModel: PaymentModel) => (
          <OptionContainer
            className={selectedPaymentModel && selectedPaymentModel.id === paymentModel.id ? ' selected' : ''}
            onClick={() => {
              setSelectedPaymentModel(paymentModel);
            }}
            key={paymentModel.id}
          >
            <div>{paymentModel.name}</div>
            <div className="description">{paymentModel.paymentType}</div>
          </OptionContainer>
        ))}
      </Grid>
    ) : (
      <GuideMsg>선택 가능한 결제 모델이 없습니다.</GuideMsg>
    )}
  </PaymentModelOptionsWrapper>
);

export default PaymentModelOptions;
