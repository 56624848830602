import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { ReactComponent as Logo } from '../../../assets/svg/logo-urclass.svg';
import { ReactComponent as SidebarOpenIcon } from '../../../assets/svg/ico-sidebar-open.svg';
import { ReactComponent as SidebarCloseIcon } from '../../../assets/svg/ico-sidebar-close.svg';
import { ReactComponent as ArrowUpRight } from '../../../assets/svg/arrow-up-right.svg';

import MobileMenu from './MobileMenu';
import HeaderMenu from './HeaderMenu';
import BasicTab from '../../atoms/Tab/BasicTab';

import { useCredential } from '../../../contexts/credential';
import { TextButton } from '../../atoms/Button/TextButton';
import { useWindowSize } from '../../../util/hooks/useWindowSize';

type HeaderProps = {
  handleToggleMobileMenu?: () => void;
  isMobileMenuVisible?: boolean;
};

const GeneralHeaderWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 65px;
  z-index: 10;
  display: flex;
  align-items: center;

  .general-header__content {
    width: 100%;
    margin: 0 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .ico__sidebar {
      display: none;
    }

    .general-header__content--logo {
      width: 127px;
      margin-top: 4px;
    }

    .general-header__content--darkmode-logo {
      width: 100px;
      margin-top: 4px;
    }

    .btn__login {
      border-radius: 4px;
      width: 81px;
      height: 36px;
      border: 1px solid #455573;
      padding: 6px 20px;
    }

    @media screen and (max-width: 992px) {
      margin: 0 20px;
    }
    @media screen and (max-width: 992px) {
      .general-header__content--logo--small {
        width: 95px;
        height: 25px;
        vertical-align: middle;
      }

      .ico__sidebar {
        display: inline-block;
        cursor: pointer;
        margin-right: 18px;
        vertical-align: middle;
      }
    }
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  
  .logo-link {
    margin-right: 28px;
  }
`;

const ButtonItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const GeneralHeader: React.FC<HeaderProps> = ({ handleToggleMobileMenu, isMobileMenuVisible }) => {
  const { width } = useWindowSize();
  const isMobile = width ? width <= 992 : false;
  const history = useHistory();
  const pathname = history.location.pathname;
  const [mobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);
  const { isLogin, logout, profile, checkout } = useCredential();

  const playlistId = pathname.indexOf('playlist') !== -1 || history.location.search.split('playlist=')[1];

  const handleLogout = async () => {
    await checkout();
    logout();
    history.push('/');
  };

  return (
    <GeneralHeaderWrapper>
      <div className='general-header__content'>
        <HeaderLeft>
          {!!playlistId ? (
            isMobileMenuVisible ? (
              <SidebarCloseIcon className='ico__sidebar' onClick={handleToggleMobileMenu} />
            ) : (
              <SidebarOpenIcon className='ico__sidebar' onClick={handleToggleMobileMenu} />
            )
          ) : (
            <></>
          )}
          <Link to='/' className="logo-link">
            {' '}
            <Logo className={!!playlistId ? 'general-header__content--logo--small' : 'general-header__content--logo'}
            />
          </Link>
          {!isMobile && (
            <a href='https://www.codestates.com/' target='_blank'>
              <TextButton>
                <ButtonItem>
                  코드스테이츠 홈
                  <ArrowUpRight />
                </ButtonItem>
              </TextButton>
            </a>)}
        </HeaderLeft>
        {isLogin && profile ? (
          <HeaderMenu
            hasCohort={profile.hasCohort}
            isContentPage={!!playlistId}
            visible={mobileMenuVisible}
            toggleVisible={setMobileMenuVisible}
            handleLogout={handleLogout}
            userProfile={profile}
          />
        ) : (
          <Link
            to='/login'
            style={{
              display: `${pathname === '/login' || pathname === '/login/' || pathname === '/additional' ? 'none' : ''}`
            }}
          >
            <BasicTab className='btn__login'>로그인</BasicTab>
          </Link>
        )}

        {isLogin && mobileMenuVisible && profile && (
          <MobileMenu
            isHasCohort={profile.hasCohort}
            handleCloseMenu={() => setMobileMenuVisible(false)}
            handleLogout={handleLogout}
            role={profile.role}
          />
        )}
      </div>
    </GeneralHeaderWrapper>
  );
};
export default GeneralHeader;
