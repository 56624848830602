import { CardNewsType } from './types';

export const data: CardNewsType[] = [
  {
    id: 1,
    course: 'SEBBE',
    url: 'https://velog.io/@dongoc21hj/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-Pre-Course-4%EC%A3%BC%EC%B0%A8-%ED%9A%8C%EA%B3%A0',
    title: '코드스테이츠 Pre Course 4주차 회고',
    description: 'CodeStates Pre-Course Review',
    thumbnailURL: 'https://images.velog.io/velog.png'
  },
  {
    id: 2,
    course: 'SEBBE',
    url: 'https://medium.com/positivelypassionate/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-%EC%9D%B4%EB%A8%B8%EC%8B%9C%EB%B8%8C-%EC%BD%94%EC%8A%A4%EB%A5%BC-%EB%A7%88%EC%B9%98%EB%A9%B0-8cefa4d7d594',
    title: '코드스테이츠 이머시브 20기를 마치며',
    description:
      '인터뷰를 보고, 합격 메일을 받고, 슬랙 노션 줌 등 코드스테이츠에서 사용하는 도구들에 익숙해졌던 기억이 난다. 당시 귀국하고 오래 지나지 않은 상황이었는데 직급과 나이에 관계없이 모두 ‘-님’으로 부르는 문화가 영국 생활이 떠오르게도 하고 무척 좋았다.',
    thumbnailURL: 'https://miro.medium.com/fit/c/160/160/1*V-oGDEj8_J8_i80d8j9rfA.png'
  },
  {
    id: 3,
    course: 'SEBBE',
    url: 'https://o-yeon.tistory.com/108',
    title: '코드스테이츠 기업 협업 프로젝트 후기 (feat. 휴먼스케이프)',
    description:
      '코드스테이츠에 등록하던 6개월 전이 정말로 엊그제 같은데 일주일에 한 번 외출하는 생활로 자발적 자가격리를 하며, 집에서 공부만 하는 정신없는 하루 하루들을 보내다보니 오지않을 것만 같던 8월 중순이 왔다..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F1kuSG%2FbtqGZHfkmr5%2Fl6k9SvgD26pg4naHvvjjLK%2Fimg.png'
  },
  {
    id: 4,
    course: 'SEBBE',
    url: 'https://velog.io/@chachagogogo/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-pre%EA%B3%BC%EC%A0%95-%ED%9A%8C%EA%B3%A0%EB%A1%9D',
    title: '코드스테이츠 pre과정 회고록',
    description:
      '코드스테이츠에서 지난 4주 간 pre과정을 수강했습니다.간단히 회고록을 적어볼까 합니다.제 소개글은 여기에 적어놨습니다. => 0. 개발로의 진로 변경부터 합격 통지까지그래도 짧게나마 소개를 하자면, 저는 비상경계 문과 전공 출신+개발의 개(...)도 모르는 채로 코드',
    thumbnailURL:
      'https://images.velog.io/images/chachagogogo/post/23e22143-df38-4fba-9cee-60490538e1be/thought-catalog-505eectW54k-unsplash.jpg'
  },
  {
    id: 5,
    course: 'SEBBE',
    url: 'https://velog.io/@rameau17/Final-Project-%ED%9A%8C%EA%B3%A0',
    title: 'Final Project 회고',
    description:
      '끝날 것 같지 않던 파이널 프로젝트가 끝나고 발표까지 마친 지금, 시원섭섭함과 불안함이 공존하는 상태이다. 불안함은 내 실력에 대한 불신에서 비롯되는 것이기에, 이를 극복하기 위해선 더 많은 노력만이 답이라는 것을 알고 있다. 그 노력의 시작으로, 파이널 프로젝트 회고',
    thumbnailURL: 'https://images.velog.io/images/rameau17/post/c290b6f6-8448-4fc5-acc6-7e9c3a573d5b/빚다로고.png'
  },
  {
    id: 6,
    course: 'SEBBE',
    url: 'https://velog.io/@hwanieee/Final-Project-%ED%9A%8C%EA%B3%A0%EB%A1%9D',
    title: 'Final Project 회고록',
    description:
      "Bitda 링크git hub 링크1주 동안 새로운 스택을 공부하고, 2주 동안 3명의 팀원들과 함께 작업한 전통주 추천 웹사이트, '빚다'.퍼스트 프로젝트와 같이 나는 프론트 엔드를 맡았다.프로젝트를 하면서 내가 했던 Works :전반적인 와이어 프래임 및 전체 화면구",
    thumbnailURL: 'https://images.velog.io/images/hwanieee/post/d9358864-9355-451e-8645-e8649807e028/bitdalanding.gif'
  },
  {
    id: 7,
    course: 'SEBBE',
    url: 'https://velog.io/@yrkimyy/Knocking-On-Final-Projects-Door-%ED%9A%8C%EA%B3%A0',
    title: "Knocking On Final Project's Door, 회고",
    description:
      '🖥 Cominciamo, 파이널 프로젝트와 코드스테이츠의 코스가 드디어 끝났다. 끝이 있다면 새로운 시작도 있는 법. 이제는 본격적으로 개발자의 길을 시작해야 할 때다. 생각보다 4주, 아니 5개월이 빠르게 지나갔다. 아직 더위가 가시지 않을 때쯤 시작한 것 같은',
    thumbnailURL:
      'https://images.velog.io/images/yrkimyy/post/ceac9a1c-4d56-44cd-98d9-f5ae2f1c847d/2번째[메인].png'
  },
  {
    id: 8,
    course: 'SEBBE',
    url: 'https://jun931223.tistory.com/203?category=913317',
    title: '파이널 프로젝트 : Knock-Knock',
    description:
      '성취감과 자존감을 높여줬던 4주 프로젝트 knock-knock! 시간이 정말 말도 안 되게 빨리 지나갔다.. 처음 코드스테이츠에 들어왔을 때만 해도 ,,, 티 안 내려고 당당한 척했지만 나 정말 수료할 수 있을까? 이거..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FKnpk1%2FbtqVV4ncDpr%2FvRMj5ViBB0KOKfuNVgW2R0%2Fimg.gif'
  },
  {
    id: 9,
    course: 'SEBBE',
    url: 'https://bit.ly/2Z8xq4J',
    title: '파이널 프로젝트 회고',
    description:
      '한 달이라는 시간동안 모든 시간과 집중을 쏟은 내 마지막 프로젝트가 끝이 나고 수료까지 마쳤다. 수료하면 마음이 마냥 편할 줄 알았는데, 사회로 내던져진 20살의 나로 돌아간 기분이어서 싱숭생숭하다.  이러한 마음도 잠시, 너무 바빠졌다. 수료하니까 더욱더 마음이 조급해지고 해야 할 것 투성이... 그 중 하나인 파이널 프로젝트에 대한 회고를 써보려 한다...!',
    thumbnailURL:
      'https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F42d6c8da-de90-4a69-bac3-ac4ae9e3bb67%2FUntitled.png?table=block&id=7cbf4c55-fb41-4739-a7e5-3cfdae5926b2&spaceId=c2aaa0e2-767e-4e6c-96ce-026f70c63316&width=3800&userId=f2a94833-4d63-4709-9ba2-31fd40404559&cache=v2'
  },
  {
    id: 10,
    course: 'SEBBE',
    url: 'https://velog.io/@qmasem/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-Final-Project-%ED%9A%8C%EA%B3%A0',
    title: '코드스테이츠 Final Project 회고',
    description:
      '파이널 프로젝트 회고를 하며 지난 한 달 동안 제가 어떤 개발자였고, 앞으로는 어떻게 성장해 나갈 것인지 모색하는 시간을 가져보겠습니다.아이디어 회의가장 먼저 한 일은 각자 아이디어를 준비해 발표하는 것이였습니다.저는 Slow TV장르 영상 컨텐츠를 제공하는 서비스를',
    thumbnailURL:
      'https://media.vlpt.us/images/qmasem/post/c55ef1ea-69ba-4961-b215-1170a602208e/ezgif.com-gif-maker.gif'
  },
  {
    id: 11,
    course: 'SEBBE',
    url: 'https://velog.io/@young_mason/Momojito-%EC%B9%B5%ED%85%8C%EC%9D%BC-%EC%BB%A8%ED%85%90%EC%B8%A0-%EC%84%9C%EB%B9%84%EC%8A%A4-ReactNext.js-4%EC%A3%BC-%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8-%EB%8F%8C%EC%95%84%EB%B3%B4%EA%B8%B0',
    title: '[Momojito] 칵테일 컨텐츠 제공 서비스 :: React&Next.js 4주 프로젝트 돌아보기',
    description:
      '개요 코드스테이츠 파이널 프로젝트로 약 4주간 진행했던 프로젝트가 마무리 됐습니다. 퍼스트 프로젝트에 이어 새로운 팀에서 다시 한 번 팀장 역할을 맡았고 마찬가지로 프론트엔드 개발을 담당하였습니다. 서비스이름은 Momojito (모모히또) 입니다. 모모히또는 초',
    thumbnailURL:
      'https://s3.us-west-2.amazonaws.com/secure.notion-static.com/5fc539b8-1450-467b-b7f9-2d0b2928ff85/.gif?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAT73L2G45O3KS52Y5%2F20210208%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20210208T081302Z&X-Amz-Expires=86400&X-Amz-Signature=5e3946af357e6a9c83e45d613e227c94c7aa8264a709e0421a6b874eb75309a1&X-Amz-SignedHeaders=host'
  },
  {
    id: 12,
    course: 'SEBBE',
    url: 'https://velog.io/@hwanieee/Final-Project-%ED%9A%8C%EA%B3%A0%EB%A1%9D',
    title: 'Final Project(빚다) 회고록',
    description:
      "Bitda 링크git hub 링크1주 동안 새로운 스택을 공부하고, 2주 동안 3명의 팀원들과 함께 작업한 전통주 추천 웹사이트, '빚다'.퍼스트 프로젝트와 같이 나는 프론트 엔드를 맡았다.프로젝트를 하면서 내가 했던 Works :전반적인 와이어 프래임 및 전체 화면구",
    thumbnailURL: 'https://media.vlpt.us/images/hwanieee/post/d9c39a63-0989-4512-a22e-40cfe2b63aff/good%20gif.gif'
  },
  {
    id: 13,
    course: 'SEBBE',
    url: 'https://breezebm.tistory.com/40',
    title: '리코딩 : 4주 프로젝트 회고록! Fin (feat. 코드스테이츠)',
    description:
      '💡프로젝트 돌아보면서 생각보다 4주라는 시간이 금방지나 갔다. 처음에는 시간이 정말 많고 여유롭게 진행 할것 같아지만 생각보다 SR부터 치열했던 것 같다. 그렇다고 해서 SR이 힘들었지만 싫은 것은 아니였..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FBzohT%2FbtqVIKPYLwV%2F0uZ0XgsZJ5CUYb6ouNXXcK%2Fimg.gif'
  },
  {
    id: 14,
    course: 'SEBBE',
    url: 'https://velog.io/@hangoook/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-Pre-course%EB%A5%BC-%EB%81%9D%EB%A7%88%EC%B9%98%EB%A9%B0',
    title: '코드스테이츠 Pre-course를 끝마치며',
    description: 'pre course 회고',
    thumbnailURL:
      'https://images.velog.io/images/hangoook/post/c2124701-5cc6-4655-8db1-d6ca046ef4fa/codeStates logo.png'
  },
  {
    id: 15,
    course: 'SEBBE',
    url: 'https://velog.io/@baymaxx/Pre-course-4%EC%A3%BC-%ED%9A%8C%EA%B3%A0%EB%A1%9D',
    title: 'Pre course 4주 회고록',
    description: '🔥Intro',
    thumbnailURL: 'https://images.velog.io/images/baymaxx/post/00a206ff-395c-42b6-804a-0757caba9746/post.jpg'
  },
  {
    id: 16,
    course: 'SEBBE',
    url: 'https://velog.io/@kkt12121/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-Pre-course-%EB%81%9D',
    title: '[코드스테이츠] Pre-course 끝 !',
    description:
      '길다고 하면 길고 짧다고 하면 짧은 프리코스가 드디어 끝이 났다 !!!벌써 4주가 지났다고 생각하니 시간이 참 빠르다고 생각이든다...프리코스의 마지막 관문인 ha를 통과한게 아직 믿기지가 않는다비전공자로 도전한 나로써는 개발자 분야로써 배움이 너무 어려웠다... 코드',
    thumbnailURL: 'https://images.velog.io/velog.png'
  },
  {
    id: 17,
    course: 'SEBBE',
    url: 'https://velog.io/@jimmy0417/CodeStates-Software-Engineering-Full-Pre-10%EA%B8%B0%EB%A5%BC-%EB%A7%88%EC%B9%98%EB%A9%B0',
    title: '[CodeStates] Software Engineering Full Pre 10기를 마치며',
    description: '코드스테이츠의 Pre Course를 졸업하고 쓰는 회고록 입니다.',
    thumbnailURL:
      'https://images.velog.io/images/jimmy0417/post/e6b0d70a-7eb4-4db8-8686-464c280bc226/codestates_logo_yellow.jpg'
  },
  {
    id: 18,
    course: 'SEBBE',
    url: 'https://velog.io/@flobeeee/%EB%91%90%EB%B2%88%EC%A7%B8-%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8-%EB%95%85%EB%95%85%EB%A7%88%EC%BC%93TTMK',
    title: '두번째 프로젝트 / 땅땅마켓(TTMK)',
    description:
      '드디어 나의 두 번째 프로젝트가 끝이 났다.🎉이번 프로젝트는 땅땅마켓이다.위치기반 중고거래 서비스인 당근마켓과 비슷하지만, 우리는 경매시스템을 접목했다.디자인도 모든 팀원이 만족하게끔 나와서, 너무 행복하다.지금부터 우리 사이트를 소개하겠다.',
    thumbnailURL: 'https://images.velog.io/images/flobeeee/post/7ff983ae-535e-408f-aa1d-0bd7e0e36f05/Landing_Page.gif'
  },
  {
    id: 19,
    course: 'SEBBE',
    url: 'https://yeonjewon.tistory.com/78',
    title: '코드스테이츠 파이널 프로젝트 회고',
    description: '배포 링크 깃헙 링크',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F17n8e%2Fbtq3UTXKWjc%2FK3JluJyWlKic3or5jc4Hn1%2Fimg.png'
  },
  {
    id: 20,
    course: 'SEBBE',
    url: 'https://kavoom2.github.io/project/codestates/Retrospect-final-project/',
    title: '팀 프로젝트 Logoyogo 회고',
    description: '로고를 누구나 쉽게 만들 수 있는 서비스',
    thumbnailURL: 'https://drive.google.com/uc?export=view&id=1GjkeT9Ot4P2vQnw0tOKSIAPWDftS6-_K'
  },
  {
    id: 21,
    course: 'SEBBE',
    url: 'https://velog.io/@monocrom1018/%EA%B8%B0%EC%97%85%ED%98%91%EC%97%85-%EA%B2%B0%EA%B3%BC%EB%AC%BC',
    title: '4주간의 기업협업 프로젝트 결산',
    description:
      '0. 들어가며 마냥 길 것만 같았던 4주간의 기업협업도 어느덧 끝이 났다. 아무것도 모른 채 헤헤헤 뽑혔다 하면서 들어간 인썸니아 사무실에는 좋은 동료들, 좋은 사수들을 비롯해 근무환경이 거의 완벽하게 갖춰져 있었고 딱 한가지 유난히 부족했던건 내 실력이었다. 좋',
    thumbnailURL:
      'https://media.vlpt.us/images/monocrom1018/post/9423fad0-b95c-40fa-a693-ba5a56588d9f/%E1%84%89%E1%85%B3%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AB%E1%84%89%E1%85%A3%E1%86%BA%202021-04-26%20%E1%84%8B%E1%85%A9%E1%84%92%E1%85%AE%209.00.59.png'
  },
  {
    id: 22,
    course: 'SEBBE',
    url: 'https://velog.io/@flobeeee/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-20%EC%A3%BC-%ED%9B%84%EA%B8%B0-26%EA%B8%B0-%EC%88%98%EB%A3%8C%EC%83%9D',
    title: '행복한 😈 코드스테이츠 수료 (IM 26기)',
    description: '진짜 솔직하게 추천하냐고..?',
    thumbnailURL: 'https://images.velog.io/images/flobeeee/post/7bffd6d5-b290-4929-ab80-72d587a8497a/done.gif'
  },
  {
    id: 23,
    course: 'SEBBE',
    url: 'https://velog.io/@afashs/Codestates-Software-Engineering-Bootcamp-Course-%EC%88%98%EA%B0%95-%ED%9B%84%EA%B8%B0',
    title: 'Codestates Software Engineering Bootcamp Course 수강 후기 (IM 26기)',
    description: '코드스테이츠 수강생 신분으로 자바스크립트 풀스택 과정을 수강한 후기입니다.',
    thumbnailURL: 'https://images.velog.io/images/afashs/post/40d0e301-0699-4b43-84eb-153197991397/1.png'
  },
  {
    id: 24,
    course: 'SEBBE',
    url: 'https://velog.io/@eensungkim/5%EC%A3%BC-%EA%B0%84%EC%9D%98-%ED%9A%8C%EA%B3%A0-TIL-33%EC%9D%BC%EC%B0%A8',
    title: '5주 간의 회고 (TIL 33일차)',
    description: '쉼 없이 달려온 5주(사실 아직 1/4 밖에 안 지났지만 말이죠..) 였습니다. ',
    thumbnailURL: 'https://images.velog.io/images/eensungkim/post/36d2da2a-797e-41e9-9cac-02bbb98f4e53/img.png'
  },
  {
    id: 25,
    course: 'SEBBE',
    url: 'https://medium.com/jongah-tech-blog/full-im-29%EA%B8%B0-5%EC%A3%BC-%ED%9A%8C%EA%B3%A0%EB%A1%9D-aeb732fae0c7',
    title: 'Full IM 29기 5주 회고록',
    description: '1+1=2를 학습하고 과제는 187382973+1928303194 =? 이런 느낌…',
    thumbnailURL: 'https://miro.medium.com/max/670/1*ITxclPF8TC5hgkmiJAzOQg.png'
  },
  {
    id: 26,
    course: 'SEBBE',
    url: 'https://p-story-js.tistory.com/72',
    title: 'Code States Software Engineering Bootcamp 회고록',
    description:
      '길었던 20주가 끝났다. 이제 프로젝트 할 일만 남았다. 20주간 공부해왔던 과정을 한마디로 표현하면 마치 디도스 공격 같았다. 디도스란? DDoS (Distributed Denial of Service. 분산서비스거부공격)는 다수의 악..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbLbM1Z%2Fbtq6z4Qj17b%2FBZWDkQVsvKdKQsf2NSYLQK%2Fimg.jpg'
  },
  {
    id: 27,
    course: 'SEBBE',
    url: 'https://vvs1.tistory.com/37?category=1023184',
    title: '코드스테이츠 후기 (한 달 동안 느낀 점 / Section 1 후기)',
    description:
      '코딩에 대한 교육은 정말 많은데, 국비도 많이 알아 봤지만 저는 많은 돈을 들여서라도 하게 되는 이유는 다른 교육보다 온전히 몰입을 할 수 있는 장점과, 체계적인 시간표, 그리고 집 밖에 나가지 않아도 되는..',
    thumbnailURL:
      'https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80'
  },
  {
    id: 28,
    course: 'SEBBE',
    url: 'https://velog.io/@eensungkim/Section-2-%EC%B4%9D%EC%A0%95%EB%A6%AC-TIL-67%EC%9D%BC%EC%B0%A8',
    title: 'Section 2 총정리 (TIL 67일차)',
    description: '페어 프로그래밍의 덕을 본 한 달이었습니다.',
    thumbnailURL:
      'https://images.velog.io/images/eensungkim/post/053ce72a-ab6a-4b60-bab8-c135b45527de/16216_20640_5034.jpeg'
  },
  {
    id: 29,
    course: 'SEBBE',
    url: 'https://www.notion.so/Section-2-495024ce3c614251822a088c77616dfc',
    title: 'Section2 후기',
    description: '이번 섹션에서 소흘했던 것은 깃헙 잔디 관리하는것! 다음 섹션에선 이것도 보완해야겠다.',
    thumbnailURL:
      'https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2552&q=80'
  },
  {
    id: 30,
    course: 'SEBBE',
    url: 'https://medium.com/jongah-tech-blog/full-im-29%EA%B8%B0-10%EC%A3%BC-%ED%9A%8C%EA%B3%A0%EB%A1%9D-689527013e8c',
    title: 'Full IM 29기 10주 회고록',
    description: '매일이 멘붕이었는데 HA test는 어떻게 풀었지?',
    thumbnailURL:
      'https://images.unsplash.com/photo-1580927752452-89d86da3fa0a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80'
  },
  {
    id: 31,
    course: 'SEBBE',
    url: 'https://velog.io/@eensungkim/Section-3-%EC%B4%9D%EC%A0%95%EB%A6%AC-feat.-Programming-101',
    title: 'Section 3 총정리 (feat. Programming 101)',
    description: '7월 14일, 하루 하고도 반나절이 걸린 마지막 HA 를 무사히 마쳤습니다. ',
    thumbnailURL:
      'https://images.velog.io/images/eensungkim/post/318ef157-223a-464c-b4df-8ab93fd57dab/19cf681b90171bb49e58da8dab766be7.jpeg'
  },
  {
    id: 32,
    course: 'SEBBE',
    url: 'https://medium.com/jongah-tech-blog/full-im-29%EA%B8%B0-15%EC%A3%BC-%ED%9A%8C%EA%B3%A0%EB%A1%9D-e71e2b0e4bf',
    title: 'Full IM 29기 15주 회고록',
    description: '#멘탈관리가_중요했던_섹션3 #마지막_HA #프로젝트_시작',
    thumbnailURL:
      'https://images.unsplash.com/photo-1562813733-b31f71025d54?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2549&q=80'
  },
  {
    id: 33,
    course: 'SEBBE',
    url: 'https://velog.io/@shitaikoto/weeklyreview-week10',
    title: '[Week 10] 주간 학습 정리 및 회고',
    description: '건강 관리, 수면 패턴 관리의 중요성을 뼈저리게 느꼈던 5주였습니다... ',
    thumbnailURL: 'https://images.velog.io/images/shitaikoto/post/6ef6c0c8-8a12-4ce0-a59b-1ff5f6e9de42/nose.jpg'
  },
  {
    id: 34,
    course: 'SEBBE',
    url: 'https://velog.io/@planethoon/Section-1-%ED%9A%8C%EA%B3%A0',
    title: 'Full SEB 31기 Section 1 회고',
    description: '이제 출발점에 선 코린이',
    thumbnailURL: 'https://images.velog.io/images/planethoon/post/244f529a-73ca-42d0-8511-0a34f6bd323b/giphy.gif'
  },
  {
    id: 35,
    course: 'SEBBE',
    url: 'https://loko1124.tistory.com/40',
    title: '[Code States] Section 1 <산뜻한 첫 출발>',
    description:
      "1. 국비지원 학원과 Code States의 차이 나는 20년 8월 중순부터 21년 2월 초까지 국비지원 학원(이하 '국비')의 교육과정을 겪었다. 그렇다 보니 이번에 Code States에서 겪었던 5주간의 학습 과정 안에서 두 교..",
    thumbnailURL: 'https://t1.daumcdn.net/tistory_admin/static/images/openGraph/opengraph.png'
  },
  {
    id: 36,
    course: 'SEBBE',
    url: 'https://velog.io/@beablessing/SECTION3-%ED%9A%8C%EA%B3%A0',
    title: 'SECTION3 회고',
    description:
      '스터디원들과 가장 자주 했던 말은왜 벌써 금요일이죠? ,오늘 무슨요일이에요?.정신없이 주어진 일정을 소화하다보니 눈 감았다 뜨면 일주일씩 훅훅 지나있는 느낌이었다.섹션2를 두번 보내본 사람으로써 " 일정에 성실히참여 + 복습 " 이렇게만 잘 해내도 ha를 통과하는데는',
    thumbnailURL:
      'https://media.vlpt.us/images/beablessing/post/8e9d0e1f-02ba-4b06-990e-227c2072893c/pocket-watch-598039_960_720.webp?w=768'
  },
  {
    id: 37,
    course: 'SEBBE',
    url: 'https://ribbon-alyssum-680.notion.site/3-fcf2029ba86546f9a14dbe936b7a68a0',
    title: '코드 스테이츠 부트캠프 섹션3 회고',
    description: '섹션2가 끝난 다음주부터 섹션3 과정이 시작되었다.',
    thumbnailURL:
      'https://images.unsplash.com/photo-1627398242454-45a1465c2479?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=4800'
  },
  {
    id: 38,
    course: 'SEBBE',
    url: 'https://velog.io/@bbaa3218/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-SEB-Section-2%EB%A5%BC-%EB%8F%8C%EC%95%84%EB%B3%B4%EB%A9%B0',
    title: '[코드스테이츠] SEB Section 2를 돌아보며',
    description:
      'Section 2는 1과 비교해서 시간이 훨씬 빨리 지나갔다. 확실히 1보다 심화적인 내용을 학습하다 보니 난이도도 더 높았지만 새로운 개념을 배우고 코드를 해석하는 능력도 길러져서 그만큼 더 재미있다고 느꼈다. 순식간에 지나간 Section 2를 돌아보며 고치거나 추',
    thumbnailURL:
      'https://media.vlpt.us/images/bbaa3218/post/d9c53089-13d9-4b7e-a872-b83ea11542ee/코드스테이츠.png?w=768'
  },
  {
    id: 39,
    course: 'SEBBE',
    url: 'https://loko1124.tistory.com/78',
    title: 'Section 2 <개발하는 즐거움에 대해서>',
    description:
      "1. 본격적으로 마주하게 된 Algorithm & Data Structure Section 2의 백미는 역시 Algorithm과 자료 구조였다. 이 파트는 Section 1 때 코플릿을 조금 풀었던 것이 그야말로 '준비운동에 가까웠구나' 싶을 정도로..",
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FHSLKr%2FbtrcA2zug9B%2FY1jfK4K5muRVMUgnZQdpGK%2Fimg.png'
  },
  {
    id: 40,
    course: 'SEBBE',
    url: 'https://velog.io/@skagns211/Codestates-FullSEB33-Section1-%ED%95%9C%EB%8B%AC%EA%B0%84%EC%9D%98-%ED%9A%8C%EA%B3%A0',
    title: '<Codestates Full_SEB_33> Section1, 한달간의 회고',
    description:
      '📝 걱정과 나에대한 불신 그리고 안도 태어나서 처음으로 접해본 프로그래밍. 잘할 수 있을거라고 근거없는 자신감으로 시작했다. 그 자신감은 넷째 날에 와르르 무너졌다! 반복문이라는 어마무시한 녀석을 만났기 때문이다. 반복문을 배우기 전 변수, 조건문까지',
    thumbnailURL:
      'https://media.vlpt.us/images/skagns211/post/fab67bb7-69b8-4384-83a5-ae4ea3435d81/%E1%84%80%E1%85%A2%E1%84%83%E1%85%A1%E1%84%92%E1%85%A2%E1%86%BC.jpeg'
  },
  {
    id: 41,
    course: 'SEBBE',
    url: 'https://wnsdufdl.tistory.com/64',
    title: 'Full SEB 33기 Section1 회고 (+32일차)',
    description:
      '3번 문제를 풀고 있었다. 인상을 찌푸리며 모니터를 노려보다가 한 생각이 스쳤다. 손가락이 반응했고 테스트 실행 버튼을 눌렀을 때 모두 통과했다는 메시지가 떴다. .. 이게 되네..?? HA 후기 어제 리액트 HA..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FclmVHw%2FbtrcEvuxuyQ%2FhjJctBAQ7UsdetNohsiYy0%2Fimg.png'
  },
  {
    id: 42,
    course: 'SEBBE',
    url: 'https://velog.io/@eensungkim/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-SEB-29%EA%B8%B0-2021040520210903',
    title: '코드스테이츠 SEB 29기 (2021/04/05~2021/09/03)',
    description: '끝났다! ',
    thumbnailURL: 'https://media.vlpt.us/images/eensungkim/post/bcc2a2e9-3bba-40f7-94ce-04fcec44c818/수료.png?w=768'
  },
  {
    id: 43,
    course: 'SEBBE',
    url: 'https://medium.com/jongah-tech-blog/full-im-29%EA%B8%B0-22%EC%A3%BC-%ED%9A%8C%EA%B3%A0%EB%A1%9D-7bbcbcc106a7',
    title: 'Full IM 29기 22주 회고록',
    description: '야, 너두 2주&4주 프로젝트 할 수 있어 (5주, 10주, 15주에 이은 진짜 마지막 편)',
    thumbnailURL: 'https://miro.medium.com/max/861/0*UNw4UoJR-8zMd8MX'
  },
  {
    id: 44,
    course: 'SEBBE',
    url: 'https://loko1124.tistory.com/116',
    title: 'Section 3 <어느새 100일>',
    description:
      "1. '눈 깜짝할 새' Section 2의 회고에다가 시간이 빠르게 지나가는 느낌을 받는다고 적었다. 하지만 Section 3는 그 정도 수준을 넘어섰다. 정말 자고 일어나니 어느새 HA까지 도달해 있는 느낌이었다. 왜 그랬는..",
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fcr3eVW%2FbtrgnqPT81k%2F4BjkV1qiOesDK1R7bDKeOK%2Fimg.png'
  },
  {
    id: 45,
    course: 'SEBBE',
    url: 'https://velog.io/@planethoon/Full-SEB-31%EA%B8%B0-Section-3-%ED%9A%8C%EA%B3%A0',
    title: 'Full SEB 31기 Section 3 회고',
    description: '코아가가 코린이가 되었어요!',
    thumbnailURL: 'https://codestates-urclass.s3.ap-northeast-2.amazonaws.com/card/cardnews_289_catgiphy.gif'
  },
  {
    id: 46,
    course: 'SEBBE',
    url: 'https://chemical-hotel-5f0.notion.site/Codestates-Full_SEB_33-Section2-3496b74935c9437181d058a05e139a41',
    title: '<Codestates Full_SEB_33> Section2, 나 살아 있네..?',
    description: '내 머리가 이렇게 멍청했었군! 이라는 생각을 정말 많이 하게 해준 Section2였다!',
    thumbnailURL:
      'https://chemical-hotel-5f0.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F7f4f0ac6-2208-479d-8b55-a12058f7ef48%2F%E1%84%89%E1%85%B3%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AB%E1%84%89%E1%85%A3%E1%86%BA_2021-10-01_01.32.39.png?table=block&id=f280ea35-6130-479f-81e1-213cc9faa580&spaceId=fcde359b-6aa9-4724-9ad6-aedf89ca98c6&width=2000&userId=&cache=v2'
  },
  {
    id: 47,
    course: 'SEBBE',
    url: 'https://ionc635.github.io/Section-2/',
    title: '<Codestates Full_SEB_33> Section 2, 우리는 깐부잖아.',
    description: '9월 30일 오전, 한 통의 메일을 받았다. 코드스테이츠 섹션 2 HA 테스트를 통과했다는 소식이었다.',
    thumbnailURL: 'https://ionc635.github.io/assets/images/%EC%84%B9%EC%85%98%202%20%ED%9A%8C%EA%B3%A01.png'
  },
  {
    id: 48,
    course: 'SEBBE',
    url: 'https://lesil.tistory.com/34',
    title: 'SECTION 1 회고',
    description:
      'Section 1에서 학습한 내용 * js/node : 조건문, 문자열, 반복문, 배열/객체, 자료형, 스코프, 클로저, spread/rest 문법 ,얕은/깊은 복사, DOM, 고차함수 * html/css : 기초 문법 , 레이아웃, selector, DOM * li..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpIrKr%2FbtrgkUEQndx%2FSgEgNuKzX6bwgnpnKVzc00%2Fimg.jpg'
  },
  {
    id: 49,
    course: 'SEBBE',
    url: 'https://wnsdufdl.tistory.com/216',
    title: 'Full SEB 33기 Section 3 회고 (112일차)',
    description:
      'HA 후기 확실히 코플릿은 쉬웠다. Section3에서 가장 중요하게 다룬 부분이 인증/보안 부분이었고 Section3 HA는 통과기준이 훨씬 높다고 들었기에 , 매일 인증/보안 스프린트를 복습했다. HA를 보기 1주일전에..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FYDKz0%2Fbtrj5dUbZE6%2F5KzSCHKvB5ZWNpzfstchs1%2Fimg.png'
  },
  {
    id: 50,
    course: 'SEBBE',
    url: 'https://lesil.tistory.com/64',
    title: 'SECTION 2 회고',
    description:
      '지난 SECTION 2에서 학습한 내용? 지난 섹션2에서 배운 내용들을 캘린더를 보면서 복기해보았다... [JS/Node] * 객체지향 자바스크립트 - 클래스형 모듈 작성하기 * 비동기 - Underbar 구현하기(내장 메서드 만들..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdDJcDk%2FbtrkgIsWjiP%2Fw669BBvyhyHsJRnI8OwCiK%2Fimg.png'
  },
  {
    id: 51,
    course: 'SEBBE',
    url: 'https://velog.io/@kapuist/FULLSEB35%EA%B8%B0-SECTION-1-%ED%9A%8C%EA%B3%A0%EB%A1%9D',
    title: 'FULL_SEB_35기 !SECTION 1 회고록!!',
    description:
      '나는 비전공자로 어학을공부하다가 우연히 현직 개발자 친구의 소개로 코드스테이츠에 합류 하게되었다 ㅋㅋㅋ 처음에 수업을들으면서 나름 이해하기 쉽다고 생각하였고 그냥 뭐... 생각나는대로 ... 배운대로 ... 그렇게만 풀어가면 술술풀리고있었다... 하지만 나의 그러한 자',
    thumbnailURL: 'https://media.vlpt.us/images/kapuist/post/3707a420-c9d5-4e04-a661-2f36fc6bd33f/img.png?w=768'
  },
  {
    id: 52,
    course: 'SEBBE',
    url: 'https://loko1124.tistory.com/122',
    title: '<이젠 정말 취업 뿐이야!>',
    description: 'Code States 전체 후기 _ 32기 수료생',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdPyf6p%2FbtrlD2K89z5%2Fxm8VSSWspslYIfQVct5gKK%2Fimg.jpg'
  },
  {
    id: 53,
    course: 'SEBBE',
    url: 'https://lion284.tistory.com/74',
    title: 'SECTION 3 회고블로그(2021. 10. 05. - 2021. 12. 09.)',
    description:
      '2021. 12. 09. 드디어 오늘부로 코드스테이츠의 모든 SECTION 1, 2, 3가 끝이 났다. 부끄럽다면 부끄러운 이야기이지만 나는 지금까지 진행한 SECTION 1, 2, 3를 단 한번도 한번에 통과한 적이 없다. 한번씩 기수..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FsaYrl%2FbtrnAXtm5yh%2FAVIFepHO6jm47MRvR2uj7k%2Fimg.png'
  },
  {
    id: 54,
    course: 'SEBBE',
    url: 'https://velog.io/@tok1324/section2%ED%9A%8C%EA%B3%A0',
    title: '걸어온 길을 되짚어보기, Section 2 회고',
    description: '코드스테이츠를 접하면서 해본 생각들..',
    thumbnailURL:
      'https://media.vlpt.us/images/tok1324/post/7872bedf-3a4a-4b86-b2d2-6cb73ee956e8/%EB%8C%80%ED%95%99%EC%88%98%EC%97%85.gif'
  },
  {
    id: 55,
    course: 'SEBBE',
    url: 'https://hsly22xk.tistory.com/78',
    title: '211208 36기에서 Section 1을 통과하며',
    description:
      '1. 기수 이동, 그리고 이슈 쉐어링 상담 처음 코드스테이츠에 35기로 탑승하여 첫 기수 이동 메일을 받았을 때는 정말 씁쓸하고 힘들었다. 내 공부 방법이 틀렸던 것인가, 다른 사람보다 내가 못한 것인가, 하는..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdbClSV%2FbtrnpyAgnAF%2FN77mLfFkPY0SQbEjFMPPK0%2Fimg.png'
  },
  {
    id: 56,
    course: 'SEBBE',
    url: 'https://swimmingindev.tistory.com/86',
    title: '드디어 모든 섹션이 끝났다! Section3 회고 (SEB 35)',
    description:
      '드디어 35기 모든 섹션이 종료되었다. 분명 시작할 때는 코딩에 대해 아무것도 모르는 상태였는데, 지난 3개월간 놀랍도록 성장한 자신을 보면 약간의 뿌듯함을 느끼게 된다. 하지만 아무리 그래도 힘든 건 어쩔..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbpFxBu%2FbtrqT0UuuU0%2FVG8hXEWBEGz2Jt7N03Dtq0%2Fimg.jpg'
  },
  {
    id: 57,
    course: 'SEBBE',
    url: 'https://velog.io/@estell/%EC%83%88%EB%A1%9C%EC%9A%B4-%EC%B6%A9%EA%B2%A9%EC%9D%98-%EC%8B%9C%EC%9E%91-%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-Section-2-%ED%9A%8C%EA%B3%A0',
    title: '새로운 충격의 시작, 코드스테이츠 Section 2 회고',
    description:
      '우선 결론부터 말하자면, 섹션 2의 HA 시험을 통과하여 섹션 3로 넘어가게 되었다.그리고, 내가 섹션 1을 진행하고 섹션 2로 넘어와서 가장 성장한 부분이 무엇이었는지 말하고 싶다.나는 섹션 2를 혼자 힘으로 넘어갔다고 생각하지않는다.정말 좋은 페어분들을 만나게 되어',
    thumbnailURL:
      'https://media.vlpt.us/images/estell/post/bdb8092d-9b00-47d2-9c68-1d8542292937/20180116_5a5d856369709.gif?w=768'
  },
  {
    id: 58,
    course: 'SEBBE',
    url: 'https://fireballer150.tistory.com/34',
    title: '코드스테이츠 섹션2의 기억',
    description:
      '0번째 지치지 않고 앞으로 아니요. 지치지 않았습니다. 왜냐구요? 여덟번째 이야기로! 첫번째 두려움과 기대 섹션2가 어떨까에대한 막연한 두려움...그리고 새로운 내용을 학습한다는 기분좋은 떨림 아마도 후자..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpfzfW%2FbtrqPhoxAqL%2FBJFIulhxncLPFEVnkq5KoK%2Fimg.jpg'
  },
  {
    id: 59,
    course: 'SEBBE',
    url: 'https://lumpy-sturgeon-f79.notion.site/31-Section1-HA-d138592ba9934943bfca9d53b1381870',
    title: '<31일차> Section1 HA 회고록 ',
    description: '변수, 함수, 문자열, 반복문 ,HTML, CSS, GIT, DOM, REACT 등 엄청나게 많은걸 배웠다.',
    thumbnailURL:
      'https://lumpy-sturgeon-f79.notion.site/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fnasa_robert_stewart_spacewalk.jpg?table=block&id=d138592b-a993-4943-bfca-9d53b1381870&spaceId=4da62200-8dd9-473f-b3e3-9a8a88b8158e&width=2000&userId=&cache=v2'
  },
  {
    id: 60,
    course: 'SEBBE',
    url: 'https://blog.naver.com/company_know/222621114708',
    title: 'TIL_코딩부트캠프 섹션1_회고 { AgeNeverMatters }',
    description:
      '#코딩부트캠프 #age_never_matters #섹션1통과 #40대후반_코딩입문 #코드스테이츠 글 시작 전에 앞서 이제 ...',
    thumbnailURL:
      'https://blogthumb.pstatic.net/MjAyMjAxMTRfNTYg/MDAxNjQyMDk1NjA4NTU1.aTXg18VgrGrj92X1ExeJFaN5RPgk1QtG6idNGVhaX_gg.UdPV6gaijjyC6XbrogSKwPbpn8oNYo3D3EiLQag2r0sg.JPEG.company_know/%BC%BD%BC%C71_%B9%E8%BF%EE%B0%CD%B5%E9_%C0%CC%B9%CC%C1%F62.jpg?type=w2'
  },
  {
    id: 61,
    course: 'SEBBE',
    url: 'https://velog.io/@ww3ysq/TodayILearn24',
    title: 'TodayILearn24',
    description: '기초부터 확실하게 이해해서 기능 구현에 충실했다면 HA3 스프린트 시험 별 거 아니다. 하지만...',
    thumbnailURL: 'https://media.vlpt.us/images/ww3ysq/post/846ec165-7316-45a5-b64f-d15877951f8f/image.png'
  },
  {
    id: 62,
    course: 'SEBBE',
    url: 'https://lumpy-sturgeon-f79.notion.site/69-Section2-24a72c3410d14e57a3b5eab5ccdfdb4c',
    title: '<70일차> Section2를 마치며..',
    description:
      'Section2 HA가 드디어 끝났다!! 한달동안 정말 기분이 오르락 내리락 반복하면서 좌절하고 극복하고  롤러코스터를 탄 기분이었다. 앞으로는 어떤 시련과 고난이 찾아올까?.. 개발자의 길은 멀고도 험하다.',
    thumbnailURL:
      'https://lumpy-sturgeon-f79.notion.site/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fnasa_space_shuttle_challenger.jpg?table=block&id=24a72c34-10d1-4e57-a3b5-eab5ccdfdb4c&spaceId=4da62200-8dd9-473f-b3e3-9a8a88b8158e&width=2000&userId=&cache=v2'
  },
  {
    id: 63,
    course: 'SEBBE',
    url: 'https://velog.io/@suxxzzy/%EC%84%B9%EC%85%98-2-%ED%9A%8C%EA%B3%A0',
    title: '섹션 2 회고',
    description: '길다면 길고 짧다면 짧다고도 할 수 있는 섹션2가 끝이 났다.',
    thumbnailURL:
      'https://media.vlpt.us/images/suxxzzy/post/2c0b733a-9849-4fe2-bdb1-a9e59fb9760b/30647AC1-B772-4607-BF3C3D43456E7103_source.jpg'
  },
  {
    id: 64,
    course: 'SEBBE',
    url: 'https://danc9921.tistory.com/88',
    title: 'Section 1 돌아보기',
    description:
      'Section 1 종료 부트캠프를 시작하고 첫 한 달 + 첫 section을 오늘로써 마무리하게 되었다. 처음 시작할때 매일매일 회고하고, 주 단위로 회고하고 월 단위 (섹션)로 회고해야지~ 했던 게 벌써 한 cycle을 돌았..',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/uuv7sZB5r-OT8g6K03s43-1657705143893.jpeg'
  },
  {
    id: 65,
    course: 'SEBBE',
    url: 'https://nelljundev.tistory.com/145',
    title: '[회고] 백엔드 부트캠프 벌써 첫 한 달!',
    description:
      '벌써 한 달? 미쳤다 미쳤어... 과정 시작 전에 줌, 디스코드 아이디 만들고, 줌에서 어색하게 첫인사를 했던 게 엊그제 같은데... 역시 시간은 당시에는 모르는데 지나고 보면 항상 말도 안 되게 빠르게 지나간..',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/U9JelqY351kwttuSH2DCt-1657705238275.gif'
  },
  {
    id: 66,
    course: 'PMB',
    url: 'https://blog.naver.com/nuck_z/222560188996',
    title: '멘토를 만나다.',
    description: '코드스테이츠 Product management 부트캠프 8기',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/vypCFRmuMYeKKUpIhYjbK-1637918206827.png'
  },
  {
    id: 67,
    course: 'PMB',
    url: 'https://smart-consumer544.tistory.com/57?category=1019809',
    title: "코드스테이츠는 PM으로 가는 '발판'이다 (코드스테이츠 PMB 회고록)",
    description: '코드스테이츠 Product management 부트캠프 8기',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdSpER2%2FbtrmaikTjKq%2FKk4ppoKmUWxzkorRklV5pk%2Fimg.jpg'
  },
  {
    id: 68,
    course: 'PMB',
    url: 'https://brunch.co.kr/@alice-n-moment/43',
    title: '나를 확장했던 3개월의 시간, 코드스테이츠 PMB 회고',
    description: '코드스테이츠 PMB 8기',
    thumbnailURL:
      '//img1.daumcdn.net/thumb/R1280x0.fjpg/?fname=http://t1.daumcdn.net/brunch/service/user/UHU/image/Ah--TbYMAKOIg90EMdKX0CWM-WY.jpg'
  },
  {
    id: 69,
    course: 'PMB',
    url: 'https://eunmee910.tistory.com/7',
    title: '[코드스테이츠 PMB 10기] 1주차 회고 - 칭찬만큼 짜릿한 도움 받는 기분',
    description:
      '1. 드디어… 1주차가 끝났다 이 문을 열고 들어오시면 프로덕트 매니저가 됩니다 2022년 1월 17일, 드디어 코드스테이츠 PMB 10기 첫 수업이 시작되었다. 코드스테이츠에 지원하기 전의 나는 외로운 취업준..',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/-pZ8PMsqWToy98aaocQAy-1643294130858.png'
  },
  {
    id: 70,
    course: 'PMB',
    url: 'https://blog.naver.com/cyw090397/222634123461',
    title: '[코드스테이츠 PMB 10기] Week2, 어쩌면 몇 년을 되돌아 톺아보기 : 좋음과 변화에 관한 회고',
    description:
      '우와. 폭풍 같았던 1주가 또 지나갔다. 이번주는 새로운 개념이 잔뜩 들어와서 그런지, 너무 어려웠고 매일...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/GaCZh5Gc3hDs_tx1BHqp5-1643457378560.png'
  },
  {
    id: 71,
    course: 'PMB',
    url: 'https://blog.naver.com/wpgjs0717/222627841436',
    title: '훈련소에서는 시키는 것만 제대로 하면 돼 (Week. 01)',
    description:
      '이렇게 일주일 내내 바쁜 적은 정말 오랜만이다. 매일 꽤 많은 양의 자료와 아티클을 읽고 매일 나의 과제...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/C-yaJqJRQsi9LljWHoLDe-1643457969991.png'
  },
  {
    id: 72,
    course: 'PMB',
    url: 'https://blog.naver.com/dahbee/222649537120',
    title: '[코드스테이츠 PMB 10기] Week 4 회고 : 내가 조금은 성장했을까.',
    description:
      '매주 회고를 하지 않으면 그 주에 무엇을 했는지 너무 빨리 잊어버릴 것 같아 마지막 날에 잊지 않고 회고...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/2ruWZOt4KFLxnPiH6MT29-1645333306640.png'
  },
  {
    id: 73,
    course: 'PMB',
    url: 'https://only-tina.tistory.com/83',
    title: '6주짜리 밀린 회고 적어보기 코드스테이츠 PMB 10기',
    description:
      '윤택님이 그랬다. 회고는 매주 쓰면 좋다고. 하지만 나는 그 말을 사뿐히 넘겨 들었고 지금은 살짝(?) 후회 중이다. 그때마다 내 감정을 길게 좀 남겨놓을걸!! 그래도 다행이다. 나에게는 일기가 있지롱!😝 일..',
    thumbnailURL: ' https://s3.ap-northeast-2.amazonaws.com/urclass-images/F8IT9aAW8FrwLBzb2ycdL-1646370317740.png'
  },
  {
    id: 74,
    course: 'PMB',
    url: 'https://blog.naver.com/hyunee1997/222662301844',
    title: '[코드스테이츠 PMB 9기] 코드스테이츠 PMB 9 회고',
    description:
      '13주 동안 프로덕트 매니지먼트 부트캠프가 끝이 났다. 고생했다! ? 이전에 인턴하던 곳에서 처음으로 서비...',
    thumbnailURL:
      'https://search.pstatic.net/common/?src=http%3A%2F%2Fblogfiles.naver.net%2FMjAyMTA4MTNfMjcz%2FMDAxNjI4ODE5NzEzOTc1.sGNMvRHja2c8MfhjlxjyZhHOnh_znhdEOfL60bO5ZjMg.TFt8KL8lp2wEPYy-iuaaMJTrHoaTc_7-Y54SrOqEuSog.JPEG.jwh5274%2FIMG_5018.jpg&type=sc960_832'
  },
  {
    id: 75,
    course: 'PMB',
    url: 'https://brunch.co.kr/@otter-jieon/33',
    title: '능이버섯이지만, 나는 할 수 있다!',
    description:
      '[코드스테이츠 PMB 10기] 6주차 회고 | 회고를 하자, 회고를! 1. 회고를 안 한 지.. 어언.. 부끄럽게도 5주째 맨처음 코드스테이츠에 탑승할 때만 해도 PM님께서 하시는 말씀은 모두 따를 거라고 다짐을 했었다. 그리고 은택 PM님께서는 매주 회고를 쓰는 게 좋다고 했고, 처음에는 정말 호기롭게 좋아! 해볼테다! 하면서 은택님이 말씀해 주셨던 회고법대로 글을 작성했었다. 하지만',
    thumbnailURL:
      'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80'
  },
  {
    id: 76,
    course: 'PMB',
    url: 'https://blog.naver.com/nose8691/222676580568',
    title: '코드스테이츠 PMB 10기_후기',
    description: '🥳 축! 코드스테이츠 8주 과정 완료 🥳 나 자신 꽤나 열심히 살았어...기특해 ^^b 두둥탁! 코드스테이...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/FKEP8-a42O1ZOzAHiisrj-1647961713898.png'
  },
  {
    id: 77,
    course: 'PMB',
    url: 'https://blog.naver.com/qksl9635/222675725559',
    title: '코드 스테이츠 PMB_10기/회고를 합ㅅ ㅣ ㄷr',
    description:
      '1월 17일 부터 달려 왔는데 가지 않을것만 같았던 시간을 지나 마지막 학습 과정을 끝낸 오늘은 그 후로 딱...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/4ynqqiJvx9Js2_Gz4MsLr-1647961911297.png'
  },
  {
    id: 78,
    course: 'PMB',
    url: 'https://hsfpg.tistory.com/44',
    title: '코드스테이츠 PMB 회고: 기운을 한곳에 모으면 폭발적인 힘이 나온다',
    description:
      '부트캠프 8주가 끝났다. 팀 프로젝트만이 남았다. 이제 기본적인 교육과 과제제출은 끝난 것이다. 부트캠프의 전반적 느낌은 그대로 훈련소 같았다. 시간이 갈수록 빡센 느낌은 좀 줄었다. 그래도 그만큼 적응해..',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/UtEJpI7GYnZL11VDGn0Qa-1647961953979.png'
  },
  {
    id: 79,
    course: 'PMB',
    url: 'https://blog.naver.com/justiancode/222672876184',
    title: '[코드스테이츠PMB]WEEK 6,7 회고 : 목적지를 잘 찾아가고 있어?',
    description: 'KEEP 맞춤법 체크 - 과제를 할 때 처음은 Typed로 초안을 작성하고 블로그로 전부 불러와 최종 편집을...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/bUEb5GmFlBLjO76SGya3F-1647962129186.png'
  },
  {
    id: 80,
    course: 'PMB',
    url: 'https://ongalclassic.tistory.com/31',
    title: '멘토-멘티 밋업 행사 Mento Menti Meet up 회고',
    description:
      "게더타운 Meet-up 게더타운은 '바람의 나라' 처럼 캐릭터들이 돌아다니면서 타일위에 올라간다. 그러면 거기서 대화하고 있는 사람들의 얼굴이 나오면서 화상으로 이야기를 나눌 수 있는 메타버스 화상회의 서비..",
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/iPYpJJB0LPNWF1XYS7gc9-1647962183729.png'
  },
  {
    id: 81,
    course: 'PMB',
    url: 'https://hsfpg.tistory.com/33?category=1046251',
    title: '연속된 실패로 20대를 보낸 내가 PMB10에 뽑힌 후기',
    description:
      '오늘은 맨토 멘티 밋업 행사가 열렸다. 끝나고, 모인 사람들끼리 이야기를 나누기도 했다. 대화를 하다가 개인의 커리어에 대한 과거를 이야기하는 시간을 가지게 되었다. PMB10기에 지원 과정에 대한 이야기..',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/Me1-B8R9DB05eoBtB4n51-1647962481199.png'
  },
  {
    id: 82,
    course: 'PMB',
    url: 'https://blog.naver.com/day9402/222682272780',
    title: 'PMB 2주동안 늦잠 실컷 자던 내가 정신차리게된 ssul.txt',
    description:
      '솔직히 2주 동안 밤 늦게까지 과제하는 스스로에 취해있었다. 다들 잠들어 있는 시간동안 뭘 하는게 되게 ...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/_S6ks0k6otmtGlh5GFjd6-1648806615375.png'
  },
  {
    id: 83,
    course: 'PMB',
    url: 'https://blog.naver.com/jhw28/222683610069',
    title: '[코드스테이츠 PMB 11기 ] WEEK 1,2 회고_오랜만에 느낀 몰입의 경험',
    description: '지난 3월 14일부터 시작된 코드스테이츠 PM부트캠프. PMB11기에 탑승하고 벌써 2주가 지났다. PMB ...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/-IfH_gpyCtPC6S_q183un-1648807022108.png'
  },
  {
    id: 84,
    course: 'PMB',
    url: 'https://blog.naver.com/jhw28/222695353958',
    title: '[코드스테이츠 PMB 11기 ] WEEK 3,4 회고_부트캠프 3분의 1지점에 온 지금, 잘 가고 있는 것인가',
    description: 'PM 부트캠프 과정을 시작한지 벌써 한 달이 지났다. 벌써 한 달이 지났다고? 라는 생각과 함께 아직 두 ...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/WH0Kj_UR8x9D8hgaqjj2g-1649811578432.png'
  },
  {
    id: 85,
    course: 'PMB',
    url: 'https://blog.naver.com/elliesmansion/222690693165',
    title: '[코드스테이츠 PMB 11기] 3주차 회고록 : 눈을 감고 걸어도 맞는 길을 고르지',
    description:
      '&quot;제 스스로의 선택에 대해서 자신감이 없을 때가 분명히 있잖아요 그럴 때마다 저는 그게 진짜 부적이...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/cG1QVCisbebO0X05sEq2W-1649812021030.png'
  },
  {
    id: 86,
    course: 'PMB',
    url: 'https://sutella-plant.tistory.com/66?category=1013536',
    title: '(Week 6) 다사다난 일주일을 마무리하며 _ 코드스테이츠 PMB 11기| 회고',
    description:
      '기다리고 기다리던 금요일이 돌아왔다. 지난 주 회고를 쓴 지가 엊그제 같은데 벌써 또 일주일이 흘렀다니... 말도 안되는 시간흐름이다. 13주 과정 중에 벌써 절반...? 지나온 길이 뿌듯하면서도 앞으로의 방..',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/8yyhhefL7E3QGpC2rHvs6-1650936842541.png'
  },
  {
    id: 87,
    course: 'PMB',
    url: 'https://brunch.co.kr/@oeun33/26',
    title: '좋은 일과 나쁜 일이 함께했던 4,5주 차 회고',
    description:
      '[코드스테이츠 PMB 11기] 모듈 2_회고록 | 좋지 않은 일도 있었고, 좋은 일도 함께 공존했던 모듈 2!! 회고록은 임시 저장해 두고 그때그때 써야 하나 싶다... 아니 나중에 써야지~ 하고 나중으로 오니 정작 쓰기 싫어지는 magic... 아무튼 모듈 2도 한번 정리해보자 가즈아! 1. 기대했던 UI/UX... 그리고 코로나 지금은 PM의 길을 걷고 있지만 초딩때는 화가가 꿈일 정도로 그림을',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/7swpmI4fPqlCj29cRQKnp-1650940970642.png'
  },
  {
    id: 88,
    course: 'PMB',
    url: 'https://blog.naver.com/sola4114/222731378785',
    title: '[코드스테이츠 PMB 12기] 1주차 회고록 : 잘하고 있는거 맞겠지..?',
    description: '✏️1주차 회고록',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/fa6xQXWebE4wrl-41iF0A-1653303043234.png'
  },
  {
    id: 89,
    course: 'PMB',
    url: 'https://blog.naver.com/wednesacc/222739799294',
    title: '🚀 2주차 회고 | 코드스테이츠 PMB 12기',
    description: '웬즈스페이스',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/RiqN1HGs62_PAGjRj3m5a-1653303226550.png'
  },
  {
    id: 90,
    course: 'PMB',
    url: 'https://blog.naver.com/blue_530/222740073905',
    title: '[코드스테이츠 PMB 12기 2주차] 연장근무에 이어 새벽근무까지 해내버린 2주차 회고',
    description:
      '불타오르는 주말을 보내고(공부아니고 술마셨어요..ㅎ) 월요일을 맞이한지 얼마 되지 않은 것 같은데, 벌써...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/IzaVhffSpapq6heTHEN8P-1653303350494.png'
  },
  {
    id: 91,
    course: 'PMB',
    url: 'https://brunch.co.kr/@meetcute/65',
    title: "'잘'가는 것보다 '가고 있음'이 중요한 때",
    description:
      '[코드스테이츠 PMB 12] 4주 차 회고록 | 2주 차에 작성했던 첫 번째 회고를 지나, 4주 차를 마무리하는 시점에 회고를 다시 작성하게 되었다. 나의 시간은 2주를 간격으로 흘러가나 보다. 지난번엔 KPT 방식으로 회고를 진행했는데, 오늘은 느낀 점에 대하여 이야기해보고자 한다. 어느덧 4주 차의 마지막 날. 그리고 내일이면 5주 차가 시작된다. 수업을 들으며 재미있는 부분들도 있고, 어려',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/Yth8xNpMoClhNqJNsj_sw-1655047133495.png'
  },
  {
    id: 92,
    course: 'PMB',
    url: 'https://blog.naver.com/samyoona/222772753651',
    title: 'Week5. "할 수 있다! X3" | 코드스테이츠 PMB 12기',
    description: '4-5주 차에 쌓은 지식 UX/UI 4주 차 회고록에 뭘 배웠는지 언급을 안 했었다. 어떤 형식으로 적을지만 ...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/zJl94mv_d2yQx6xH0rRGR-1655263011826.png'
  },
  {
    id: 93,
    course: 'PMB',
    url: 'https://blog.naver.com/comcom0509/222773410632',
    title: '[코드스테이츠 PMB 12기] W1 ~ W5 회고',
    description:
      '여지껏 회고는 작성하지 않았다. 1주차에는 모든 학습 콘텐츠를 소비하느라. 2,3주차에는 주간 과제를 하느...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/cYOj-AUd8BxhCCdvBWR2m-1655263375175.png'
  },
  {
    id: 94,
    course: 'PMB',
    url: 'https://peaceful-dotori.tistory.com/77',
    title: '코드스테이츠 13기 (10): Week2 회고.',
    description:
      '요즘 지역경제 활성화에 적극적으로 이바지하고 있다. 집에서 공부하기가 너무 싫어서 동네 카페에 성실하게 출석하고 있기 때문이다. 힘들게 번 돈 설탕물 마시는데 써버리는 거 실화냐 근데 아아 마시기로 결심..',
    thumbnailURL: 'https://codestates-urclass.s3.ap-northeast-2.amazonaws.com/cardnews_default_thumbnail.png'
  },
  {
    id: 95,
    course: 'PMB',
    url: 'https://sxxxn2.tistory.com/14?category=1071529',
    title: '🌱 Week 2 회고',
    description:
      'Keep : 현재 만족하고 있는 부분 / 계속 이어나갔으면 하는 부분 Problem : 불편하게 느끼는 부분 / 개선이 필요하다고 생각되는 부분 Try : Problem에 대한 해결책 / 다음 회고 때 판별 가능한 것 / 당장 실행가..',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/xaIOlVRlxRU__Fa_glAjt-1657500047286.png'
  },
  {
    id: 96,
    course: 'PMB',
    url: 'https://blog.naver.com/glariness_liah/222804892237',
    title: '[코드 스테이츠 PMB 13 / W2D5] 두 번째 회고록 - 1주차보다 나은 2주차가 됐을까?',
    description: '벌써 부트캠프 2주차의 회고를 쓰는 날이다. 난 분명 책상에만 있었는데 시간이 언제간거야🤣🤣🤣 부...',
    thumbnailURL: 'https://codestates-urclass.s3.ap-northeast-2.amazonaws.com/cardnews_default_thumbnail.png'
  },
  {
    id: 97,
    course: 'PMB',
    url: 'https://brunch.co.kr/@bluebillowy/16',
    title: '[코드스테이츠 PMB 13기] 1쿼터 종료 3주차 회고',
    description:
      '들어가며 저희 벌써 3주차 끝자락에 있고 1모듈을 마쳤네요!!이 말은 벌써 1/4 정도 왔다는 뜻입니다 이번 주가 코드스테이츠 PMB 13기 3주차인줄은 알았지만 순간 총 몇 주였는지 가물가물해서 찾아보려는 중이었다. 13주 초압축 성장 프로덕트 매니지먼트 부트캠프!! 그랬구나. 13주였구나. 정말 1/4, 1쿼터, 25%가 지나갔다는 거구나!! 3주만큼',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/45LE6S0FjUMNbLjBxMIHA-1658105177461.jpg'
  },
  {
    id: 98,
    course: 'PMB',
    url: 'https://blog.naver.com/seol-0111/222813286432',
    title: '🚀 PM부트캠프 탑승일지 :: 2&3주차_티타늄보다 강한 멘탈이 필요해 (부제 : 눈물의 1session 종료)',
    description: '기억하고 싶은 한 주와, 잊고 싶은 한 주였다! 슬프게도,, 나 정말 짱 짱 짱 나약한 인간이었구나,,,😞 ...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/QbYovbgiOTX7vQYyN-EVI-1658105223695.png'
  },
  {
    id: 99,
    course: 'PMB',
    url: 'https://blog.naver.com/kyhkyh08155/222866707821',
    title: '[코드스테이츠 PM부트캠프 14기]2주차 회고록',
    description:
      '2주차가 끝이 났다. 시간이 생각보다 빠르게 간다. 인터넷을 보는데 피식했다. 공감의 피식이었을까? 이번...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/uzRbBs9wJhSbq7fwnnkeY-1662337822167.jpeg'
  },
  {
    id: 100,
    course: 'PMB',
    url: 'https://blog.naver.com/bb_131013/222858952167',
    title: '[코드스테이츠 PMB 14기] 바람처럼 지나간 1주차 회고',
    description: '👣PMB 1주차 후기 PMB에 탑승하고 1주차가 시간 가는지도 모르고 정신없이 지나갔다. 시작하기 전...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/tzTtBVA-Xa98mP2CxJuPW-1662337897535.jpeg'
  },
  {
    id: 101,
    course: 'PMB',
    url: 'https://makewooju.tistory.com/12?category=1038132',
    title: '[PMB14 W2 회고] 잠 못드는 밤',
    description:
      '코드스테이츠 PMB14 W2 : 22.08.29~22.09.04 9월 4일 일요일 밤 8시 45분. 겨우겨우 위클리 과제를 제출하고, 오늘 첫번째 식사를 하고(이상하게 요즘 컴퓨터 앞에 앉아 있을 때, 입맛이 없다. 몰입해서 그런지,..',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/Dgl_mfSd5oueNGV832zWq-1662337975782.gif'
  },
  {
    id: 102,
    course: 'PMB',
    url: 'https://xxxo0o.tistory.com/8?category=1038129',
    title: '[코드스테이츠 PMB 14기] 부트캠프 1주차 후기, 이거 만만하게 볼 게 아니야...',
    description:
      'PMB 부트캠프를 지원하면서 생각이 많았다. 일단 전혀 다른 직종에서, (심지어 이과, 문과 둘 다 아니다...) 전혀 다른 내용을 공부하게 됐는데 잘 따라올 수 있을까? 그런 의미에서 PMB 부트캠프 나에게 일종의..',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/sFvU38gZXKYbH0N-Yj3iP-1662338042138.png'
  },
  {
    id: 103,
    course: 'PMB',
    url: 'https://blog.naver.com/parkjy9811/222858974817',
    title: '"완벽한 글을 없어"라고 생각하고 글쓰기 | 코드스테이츠 PMB 14기 W1 회고',
    description: 'W1을 마치며.. KPT 회고하기 Keep (현재 만족하고 있는 부분, 계속 이어갔으면 하는 부분) Problem (...',
    thumbnailURL: 'https://codestates-urclass.s3.ap-northeast-2.amazonaws.com/cardnews_default_thumbnail.png'
  },
  {
    id: 104,
    course: 'PMB',
    url: 'https://gaea.tistory.com/entry/PM%EC%9D%80-%ED%98%B9%EC%8B%9C-Problem-Master%EC%9D%98-%EC%95%BD%EC%9E%90%EC%9D%BC%EA%B9%8C-PMB-%ED%9A%8C%EA%B3%A0-7%EC%A3%BC%EC%B0%A8',
    title: 'PM은 혹시 Problem Master의 약자일까? | PMB 회고 7주차',
    description:
      '한 달이 넘는 기간 동안 수업을 듣고, 과제를 하고, 토론을 진행하면서 멘탈이 나간 시점이 종종 있었다. PM은 전지전능한 존재라는 생각이 든다. 개발적 지식까지 어느 정도 겸비해야 하는 것이 당연하면서도 조..',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/2UNyFuhrFRcLu-GYdGggq-1665710986409.png'
  },
  {
    id: 105,
    course: 'PMB',
    url: 'https://blog.naver.com/bb_131013/222899133432',
    title: '[코드스테이츠 PMB 14기] 4-7주차 회고 : 어쩌다보니 여기까지 왔다',
    description:
      '어쩌다보니 여기까지 왔다 몰아 쓰는 4-7주차 회고 3주차 회고를 끝으로 계속 블로그에 회고를 작성하지 못...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/sQGiHAaSJ6qifcyqhpEx9-1665711647697.png'
  },
  {
    id: 106,
    course: 'PMB',
    url: 'https://bbottang.tistory.com/39',
    title: '[코드스테이츠 PMB14] Week 7 회고',
    description:
      '벌써 7주차가 마무리된다니 믿기지 않습니다. 또 갑자기 추워진 날씨 덕에 시간이 더욱 훌쩍 지난 기분이고요. Week 8 부터는 프로덕트 개발 방법론을 학습할텐데 궁금합니다. #유저스토리, #스크럼가이드, #PM과..',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/QcbDD6ino3I35hxd7HaPg-1665712510208.png'
  },
  {
    id: 107,
    course: 'PMB',
    url: 'https://blog.naver.com/jooyeon926/222906711503',
    title: '[PMB 15기] 부트캠프를 시작한 지 벌써 일주일! W1 회고',
    description: '#PM부트캠프 #코드스테이츠 PM 부트캠프를 시작한 지 벌써 일주일!... 드디어 첫 회고를 써보려고 한다...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/BVKR8MqkvzF5mELPWja9R-1666585985760.png'
  },
  {
    id: 108,
    course: 'PMB',
    url: 'https://blog.naver.com/nlnl5813/222908435778',
    title: '[PMB 15기] 1주차 회고_우리 블로그 정상영업합니다.',
    description:
      '하하 드디어 1주차가 지나갔다. 코드스테이츠 합격 문자를 받고 우왕~~!! 기대된다~!!! 했던 것이 엊그제같...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/moqB1YLBTyIj0rDj3_lt1-1666586188430.png'
  },
  {
    id: 109,
    course: 'PMB',
    url: 'https://blog.naver.com/topd35/222906532079',
    title: '[PMB 15기] 1주차 회고록: 두렵고 막막해, 그래도 해야지 어쩌겠나?',
    description:
      '입시 교육업계에 재직하며 많은 한계를 느꼈고, 더 늦기 전에 퇴사해 새로운 길을 찾기로 결심했다. 퇴사를...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/uxH6Jy9ul6kQTZ2Iy4IzF-1666586345931.png'
  },
  {
    id: 110,
    course: 'PMB',
    url: 'https://blog.naver.com/jsm6566/222921732065',
    title: '[코드스테이츠 PMB 15기] 3마리 토끼다 놓친 한주',
    description: '본인은 MBTI의 P 성향이 검사지를 뚫고 나갈 정도의 극한에 치 닿는다는 것을 언급한 적이 있었다.(인...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/vJS8HIthCfEs9r9p9Y3A_-1667801788688.png'
  },
  {
    id: 111,
    course: 'PMB',
    url: 'https://blog.naver.com/wavetoyouth/222921960754',
    title: '[PMB 15기] 섹션2 시작하기 전 허겁지겁 섹션1 회고',
    description:
      '3주 간의 과정이 끝나고 5시간 후면 섹션2가 시작된다. 새로운 마음가짐이 필요한 것 같아 자기 전에 회고...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/05_15dbID1lb3GRof8-1t-1667801924889.png'
  },
  {
    id: 112,
    course: 'PMB',
    url: 'https://blog.naver.com/guswjd836/222913336170',
    title: '[PMB 15기] 애자일한 삶을 살게 해주는 부트캠프 최고👍 W2 회고',
    description: '1주차 회고내용 KEEP (유지할 것), 루틴을 잘 지키기, 체력 기르기! 운동도 열심히!, 책 읽기! PRO...',
    thumbnailURL: 'https://s3.ap-northeast-2.amazonaws.com/urclass-images/Y8loRKzhKthWQwXDYnUpv-1667802000225.png'
  },
  {
    id: 113,
    course: 'GMB',
    url: 'https://medium.com/@cinedejames/%ED%98%BC%EC%9E%90-%ED%95%98%EB%8A%94-%EA%B2%83-vs-%ED%98%91%EC%97%85-%ED%95%98%EB%8A%94-%EA%B2%83-3397082ec8ae',
    title: '혼자 하는 것 vs 협업 하는 것',
    description: '나는 일잘러가 되고 싶었고, 조금이라도 전문가가 되고 싶었다.',
    thumbnailURL: 'https://miro.medium.com/max/1200/1*RBAEf5_yUQRykMxOe1ZLBg.jpeg'
  },
  {
    id: 114,
    course: 'GMB',
    url: 'https://brunch.co.kr/@acdc/44',
    title: '코드스테이츠 그로스마케팅 부트캠프에서 배운 것',
    description:
      "기록의 힘, 복습의 힘, 회고의 힘! | 처음 접한 부트캠프는 정말 '부트캠프'의 뜻이 무엇인지 보여주는 듯했다. 영문으로 이루어진 아주 빡센(?) 읽기 자료들, 그리고 그것을 해석해야만 할 수 있는 일일 과제들. 그리고 아침 10시부터 시작되는 페어와의 협업 과제, 오후 1시부터는 식곤증을 극복하며 들어야 하는 라이브 세션까지... 아침부터 저녁, 때로는 밤늦게까지도 하루 종일 '그로스란 무엇인",
    thumbnailURL:
      '//img1.daumcdn.net/thumb/R1280x0.fjpg/?fname=http://t1.daumcdn.net/brunch/service/user/1dYh/image/4yji75DS-FQT6KmatY-Gon0d9rk.jpg'
  },
  {
    id: 115,
    course: 'GMB',
    url: 'https://blog.naver.com/ung4778/222776556811',
    title: "[코드스테이츠 GMB 9기] 세상에 이런 회고는 없었다. 진짜 '마케팅이란?'",
    description: '그로스한 마케터가 되기 위해 GMB(Growth Marketing Bootcamp)에 참여한 지 한 달이 지났다. 처음에 ...',
    thumbnailURL: 'https://cdn.discordapp.com/attachments/983660201410449438/993786607666741278/0c0fb992f2e88c07.png'
  },
  {
    id: 116,
    course: 'GMB',
    url: 'https://blog.naver.com/ha_yns/222777784188',
    title: '[GMB 9기] 팀과제 5주차 회고 (아니 지금 5주 차라고?)',
    description:
      '체감상 3달은 한 것 같은 기분이ㅋㅋㅋㅋㅋ 대학교 과제랑 비교하자면 한 달 반 동안 하는 과제를 2주 동안...',
    thumbnailURL: 'https://cdn.discordapp.com/attachments/993780932790194298/994062111552577617/3_3.png'
  },
  {
    id: 117,
    course: 'GMB',
    url: 'https://blog.naver.com/yesrosy/222912896371',
    title: '팀활동 회고 - GMB 11기 코드스테이츠',
    description:
      '팀활동 회고 코드스테이츠라는 곳에서 , 마케팅을 배운지도 벌써 5주차가 되었다. (겨우 5주차라니 ㅋㅋㅋ)...',
    thumbnailURL: 'https://ifh.cc/g/hW4s5A.jpg'
  },
  {
    id: 118,
    course: 'GMB',
    url: 'https://blog.naver.com/harriii/222812103290',
    title: '[GMB 10기] W1-2 회고 / 개인프로젝트를 마무리하며!',
    description:
      '쬐금 늦게 써보는 위클리 회고. 지난 주 목요일 부로, 브랜드 분석 리포트 제출에 이어 발표까지 마치면서 ...',
    thumbnailURL: 'https://ifh.cc/g/7Z3ZRd.jpg'
  },
  {
    id: 119,
    course: 'GMB',
    url: 'https://blog.naver.com/yookson0402/222655880129',
    title: '코드스테이츠 GMB 7기) 팀 프로젝트 회고',
    description:
      '안녕하세요 Sonn입니다! 현재 팀 프로젝트를 진행하고 있는데, 저희는 &#x27;술&#x27;에 관한 컨텐츠를 다...',
    thumbnailURL: 'https://ifh.cc/g/ac7LmB.jpg'
  },
  {
    id: 120,
    course: 'GMB',
    url: 'https://blog.naver.com/bion07133/222621046609',
    title: '[코드스테이츠 GMB 6기] What if. 8주 간의 회고, 그리고 기업 협업에 임하는 각오',
    description: '내가 생각하는 ‘마케팅’이란? GMB 수업을 듣기 전 마케터는 단순히 제품의 홍보에 주력하여 매출액을 ...',
    thumbnailURL: 'https://ifh.cc/g/Pxk9sO.png'
  },
  {
    id: 121,
    course: 'GMB',
    url: 'https://blog.naver.com/k0761k/222620946026',
    title: '[코드스테이츠 GMB 6기] 8주의 회고',
    description: '내가 생각하는 ‘마케팅’이란? 내가 가진 가치를 가지고 고객과 소통하며 끊임없이 설득하는 것. 사업이 ...',
    thumbnailURL: 'https://codestates-urclass.s3.ap-northeast-2.amazonaws.com/cardnews_default_thumbnail.png'
  },
  {
    id: 122,
    course: 'GMB',
    url: 'https://blog.naver.com/aralog1/222834022671',
    title: '[코드스테이츠 GMB 10기] 한 달간의 회고, 마케팅 가보자고!',
    description: '✨ 코드스테이츠 GMB 10기 그로스 마케팅 과정 🙄 벌써 5주 차.. 한 달이 지났다니..?! 📝 열공... ...',
    thumbnailURL: 'https://ifh.cc/g/71RLh7.jpg'
  },
  {
    id: 123,
    course: 'GMB',
    url: 'https://blog.naver.com/aralog1/222847010113',
    title: '[코드스테이츠 GMB 10기] 팀 프로젝트 그로스 하기, 회고',
    description: '🎢 결실을 맺어가는 팀 프로젝트, 그로스 하기 😎 팀 프로젝트 중간 보고서 작성 &amp; 발표 🚍 1-2...',
    thumbnailURL: 'https://ifh.cc/g/7oRMBF.jpg'
  },
  {
    id: 124,
    course: 'DOB',
    url: 'https://thereisnotherway.tistory.com/35',
    title: '[DevOps 프로젝트 회고] WAS로 투표 서비스 만들기!',
    description:
      '나는 Codestates의 DevOps 개발자 과정을 수강하고 있다. 2월 3일부로 오늘까지 무려 한달의 과정이 지났다. 지난 나는 한낱 경제학을 좋아하는 졸업예정자였다. 막연히 개발자의 꿈을 품고 있었다. 지난 과정을..',
    thumbnailURL:
      'https://images.unsplash.com/photo-1461773518188-b3e86f98242f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80'
  },
  {
    id: 125,
    course: 'DOB',
    url: 'https://bravesunfish.tistory.com/30',
    title: 'DevOps 첫 프로젝트 회고 - [Back-end][Project1]TEAM_C',
    description:
      '회 고 이번 글은 프로젝트 1의 회고로서, 코드스테이츠에서 권고하는 회고 방법인 사실-발견-배운점-다짐 의 내용을 최대한 모두 담으려했습니다. Github 주소 : https://github.com/cs-devops-bootcamp/devops..',
    thumbnailURL:
      'https://images.unsplash.com/photo-1494599948593-3dafe8338d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
  },
  {
    id: 126,
    course: 'DOB',
    url: 'https://liveloper-jay.tistory.com/69',
    title: '[코드스테이츠] Section 1을 마치며...',
    description:
      '길다면 길고 짧다면 짧은 약 1달간의 DevOps 부트캠프 Section.01이 종료되었다. 역시나 처음에 예상했던대로 쉽지만은 않았지만, 지원전에 걱정했던 부분들과 달리 생각보다 괜찮았던 부분들도 상당히 많았다. 그..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbyQiWe%2FbtrvMJhcjIC%2F8tD1jeKKHY2kRki4Ph9i7K%2Fimg.png'
  },
  {
    id: 127,
    course: 'DOB',
    url: 'https://dadidadi.tistory.com/25',
    title: 'DevOps 1기 부트캠프 첫 섹션 회고',
    description:
      '코드스테이츠 DevOps 부트캠프 1기 수강생이 된 지 벌써 한 달이 지났다! 이때까지 개인 공부나 스터디를 진행하면서 회고를 써본 적이 한 번도 없는데, 회고를 작성해봄으로써 내가 어떻게 공부했는지, 무엇이 부..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F5HQZh%2FbtrvWGW9fUX%2FHSvUGKAG50KFlYxag8cpsk%2Fimg.png'
  },
  {
    id: 128,
    course: 'DOB',
    url: 'https://blog.naver.com/akeka0303/222692509377',
    title: 'DevOps 1기 - DevOps로의 두 번째 프로젝트',
    description:
      '프로젝트에 대한 회고글을 쓰면서 이번 프로젝트를 진행하면서 내 자신의 부족함과 지금까지 스프린트를 무...',
    thumbnailURL:
      'https://images.unsplash.com/photo-1549548763-124aafbe2601?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1476&q=80'
  },
  {
    id: 129,
    course: 'DOB',
    url: 'https://velog.io/@dnflekf2748/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-%EB%B6%80%ED%8A%B8%EC%BA%A0%ED%94%84-4%EC%9B%94%EC%97%90-%EC%93%B0%EB%8A%94-3%EC%9B%94-%ED%9A%8C%EA%B3%A0%EB%A1%9D',
    title: '코드스테이츠 부트캠프 4월에 쓰는 3월 회고록',
    description:
      '📌데브옵스 부트캠프 부트캠프를 이수 한지도 2달이 지났다. 많은 것을 배우고 많은 삽질들을 겪으며 지난달 보다 성장한 느낌이 든다. 불과 2달만에 내가 전혀 상관없는 IT분야를 공부를 하고 있다는 것이 낮설지만 배울수록 무한한 가능성을 느끼는 중이다. 그리고 2달',
    thumbnailURL:
      'https://velog.velcdn.com/images/dnflekf2748/post/50da1eb1-54da-4e9c-931e-fa1552f4e00b/%EB%8B%A4%EC%9A%B4%EB%A1%9C%EB%93%9C.png'
  },
  {
    id: 130,
    course: 'DOB',
    url: 'https://dadidadi.tistory.com/38',
    title: 'DevOps 1기 부트캠프 두 번째 섹션 회고 : ?밀당의 달인 AWS',
    description:
      '첫 회고 쓴지 얼마 되지도 않은 것 같은데 벌써 두 번째 회고를 작성하게 되었다... 일단 두 번째 섹션 동안 가장 많이 느꼈던 것은 AWS랑 많이 투닥투닥하지만 친한 친구가 된 것 같은 그런 느낌이 많이 들었다ㅋ..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdDj5F2%2FbtryRXXBXzE%2FxaSYLooP3KkXWIg6DzNX6k%2Fimg.png'
  },
  {
    id: 131,
    course: 'DOB',
    url: 'https://velog.io/@junis/DevOps-%EC%A0%88%EB%B0%98%EC%9D%98-%EA%B3%BC%EC%A0%95-%EA%B0%90%EC%9E%90%EC%97%90%EC%84%9C-%EA%B0%90%EC%9E%90%ED%8A%80%EA%B9%80%EC%9C%BC%EB%A1%9C',
    title: 'DevOps 절반의 과정, 감자에서 감자튀김으로',
    description: '(분명 시작이 반이라고 했는데, 난 시작했고 절반을 했는데 왜 아직 하고있는건지)',
    thumbnailURL: 'https://velog.velcdn.com/images/junis/post/fd506987-3912-4575-b1d8-8549da76b169/image.jpg'
  },
  {
    id: 132,
    course: 'DOB',
    url: 'https://hstory0208.notion.site/DevOps-2-d0cf09a88bf24e7cab269eae011e9f11',
    title: 'DevOps 부트캠프. 어느 덧 2달',
    description:
      '### 나는 비전공자다.  ---  **DevOps를 수강하기 전의 난 ? 개발과 관련된 지식을 한번도 배우지도 듣지도 못했었다.**  **여러 경험들을 해보는 것을 좋아 했던 나는** **취미가 많았다.**   그래서 **요식업**에 관심이 많아 꾸준히 여러 **요리**도 해보며 판매해도 될 정도의 맛이 나올 때 까지 **레시피**를 수정하며 완성해 기록하기도 하고 그림그리기에도 흥미를 느껴 **그림**도 공부해가며 그려도 보고 차도 인터넷에서 찾아보고 **튜닝**도 해보고 **캠핑**도 다녀보고  학생 때 댄스 동아리에서 댄스를 배우며 여러 무대도 올라보고 연기 학원을 등록해 연기도 배워보고...',
    thumbnailURL:
      'https://hstory0208.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Ff29d4801-9118-42e1-ada2-2dcacf173127%2Fsearch.pstatic.gif?table=block&id=578dab31-c579-4626-8aac-75d5114f756f&spaceId=288da1b0-f30c-4244-98f9-116cbac5c671&userId=&cache=v2'
  },
  {
    id: 133,
    course: 'DOB',
    url: 'https://passingbreeze.notion.site/Adieu-DevOps-Bootcamp-0265835297074979b3993c7e67330f31',
    title: 'Adieu! DevOps Bootcamp',
    description:
      '*2022. 2. 7 - 2022. 6. 14*  장장 17주에 걸친 DevOps Bootcamp가 끝이 났습니다.  여느 프로젝트보다도 길게 느껴졌던 프로젝트였다는 생각이 듭니다. ',
    thumbnailURL:
      'https://passingbreeze.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F8993f865-c4fb-46da-98f6-88c4ef96ada5%2FUntitled.png?table=block&id=4456802d-ddcc-4d54-b777-5e4a2c833df8&spaceId=740bb0b0-d529-469d-adcf-ed6a88123e80&width=670&userId=&cache=v2'
  },
  {
    id: 134,
    course: 'DOB',
    url: 'https://velog.io/@xgro/%ED%95%9C%EB%8B%AC-%EB%B0%98%EC%9D%98-%EA%B8%B0%EC%A0%81',
    title: '세번째 프로젝트 회고 (한달 반의 기적)',
    description:
      '프로젝트 03 마저 순식간에 지나갔다.?두번째 섹션을 마치고, 세번째 섹션을 진입하면서 체력적으로나 실력적으로나 더욱 큰 부담을 느끼게 되었던 것 같다. 이러한 부담이 결국에는 큰 벽으로 다가와 학습에 대한 의욕을 저하시키는 요인으로 작용했었다.특히 세션 내용이 비교적',
    thumbnailURL: 'https://velog.velcdn.com/images/xgro/post/83675a72-e35f-4e1e-8448-5d7f1ae4163b/image.jpeg'
  },
  {
    id: 135,
    course: 'DOB',
    url: 'https://tigercoin.tistory.com/286',
    title: '케베 맥주보다 한방 능이백숙같은 DevOps',
    description:
      "지난 프로젝트2를 기억하면 늦게 합류해서 정신없이 하다가 애매하게 마무리했던지라, 이번 프로젝트3는 '1인분이라도 하자'는 심정으로 시작했다. (프로젝트2는 도움을 많이 받았으나, 이번에는 내가 누군가에게..",
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FQpm9L%2FbtrHAHZO7mE%2FFOMDjMTlijnB6YG47aIFx1%2Fimg.png'
  },
  {
    id: 136,
    course: 'BEB',
    url: 'https://about-tech.tistory.com/115',
    title: '[회고록] 코드스테이츠 블록체인 부트캠프 국비 후기 (Digital Training 과정)',
    description:
      '코드스테이츠 부트캠프 그리고 블록체인 코드스테이츠는 온라인 교육기관이다. 온라인 상에서 교육을 진행하는 유명한 곳은 많다. 등등.. 부트캠프 특성상 한번 선택하면 6개월간의 시간이 소비되는 것이기 때문에 현재 직장을 다니고 있으면서 커리어 전환을 생각하거나, 컴공 학생이지만 좀더 실무적인 걸 배우고 취업의 길로 들어서고자 하는 분들이라면 부트캠프를 찾게 된다. 나 또한 마침 시간이 6개월간 생기게 되었고, 마침 코드스테이츠에서 진행하는 부트캠프를 만나게 되었다. 주저 없이 신청했고, 운 좋게 수강생으로 선정되었다. Digital Training 과정으로 수강생으로 모집하고 있었던 과정이었고, 신청자가 100명이 넘었었는데, 정원 45명 선발 기준은 정확히 모르겠다. 원래 정원 초과로 대기 3번이었고 대기..',
    thumbnailURL: 'https://ifh.cc/g/l8rJsN.jpg'
  },
  {
    id: 137,
    course: 'BEB',
    url: 'https://behoney.tistory.com/entry/BEB-5%EA%B8%B0-%EC%B2%AB-%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8-%ED%9A%8C%EA%B3%A0-%F0%9F%A7%90',
    title: 'BEB 5기 첫 프로젝트 회고 🧐',
    description:
      "🛩 팀명 KAL2 각 팀원의 성을 따왔는데, 이씨가 두분이여서 L뒤에 2를 붙였다 비행기 이름 같아서 비행기 이모티콘을 갖다 붙였다. L 하나가 사라진건 안비밀.. 👩‍👩‍👧‍👦 팀원 팀장 : 나 ( 컴공졸업생 (진) ) 역할 : FE, SC, Github Doc 팀원 : 경력직 게임 개발자 ( 유니티, 언리얼 등등 실무에서 다 해보심 ㄷㄷ ) 역할 : Resource Manager , FE sub 팀원 : 비전공자 ( 부트캠프 오시기 전에 뭐 하셨는지 몰루..) 역할 : FE , Github Doc 팀원 : 가치평가사(?) 정확히 기억이 안난다. ( 2일차에 일정이 있으시다 하셨고 3일차에 하차하셨다.. ) 이타치가 왜 강한지 아는가.. 역할 : Sasuke's bro 🎯 목표 NFT 거래 최대 사이트..",
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcT1gQT%2FbtrJLSkX5Lh%2FL6bwkdakdFbu7K2eC1hmS1%2Fimg.jpg'
  },
  {
    id: 138,
    course: 'BEB',
    url: 'https://about-tech.tistory.com/entry/%ED%9A%8C%EA%B3%A0%EB%A1%9D-%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-%EB%B8%94%EB%A1%9D%EC%B2%B4%EC%9D%B8-%ED%8C%8C%EC%9D%B4%EB%84%90-%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8-%EC%99%84%EC%88%98-%ED%9B%84%EA%B8%B0',
    title: '[회고록] 코드스테이츠 블록체인 파이널 프로젝트 완수 후기',
    description:
      '코드스테이츠 블록체인 파이널 프로젝트 완료 한달의 기간이 주어졌던 파이널 프로젝트가 드디어 완료되었습니다. 프로젝트 주제는 4주동안 퍼블릭 블록체인을 활용한 DID 서비스를 제작했습니다. 팀장을 포함한 총 4명의 팀원이 구성되었고, 중간에 1명이 잠수를 타버려서 결국 한명 분은 제외하고 남은 3명이서 Backend, Frontend, Smart Contract 부분으로 Role을 나눠서 프로젝트를 진행했습니다. 프로젝트 진행하면서 꼭 1명씩은 잠수를 타버리는건 질량 보존의 법직 같은건가요? 더 최악인건 잠수탄 사람이 일부 Role을 받은 상황에서 잠수를 타버려 구현하고자 했던 기능 자체를 구현 못하는 상황에 이르게 되었습니다. 결국 해당 부분은 차후 개선하기로 표시하고, 구현 하지 못한채로 발표에 임하게..',
    thumbnailURL: 'https://ifh.cc/g/T6lW1j.jpg'
  },
  {
    id: 139,
    course: 'BEB',
    url: 'https://like-money-programer.tistory.com/entry/%EB%B6%80%ED%8A%B8%EC%BA%A0%ED%94%84-BEB-5-%EA%B8%B0-%ED%9B%84%EA%B8%B02022%EB%85%84-4%EC%9B%94-2022%EB%85%84-10%EC%9B%94',
    title: '코드스테이츠 부트캠프 BEB 5 기 후기(2022년 4월 ~ 2022년 10월 )',
    description:
      "2022 년 10월 6일자로 부트캠프에서 진행한 블록체인 강의가 끝났습니다. 오늘은 그 후기에 대한 내용을 적고자 합니다. 코드스테이츠 부트캠프 BEB 5 약 3년 동안 java 웹 개발자로 잘 다니고 있던 SI 회사를 그만두고 블록체인 개발자로 이직 하고자 했던 이유중 가장 큰 이유는 '돈이 될거 같아서' 입니다. SI 회사 특성상 기술스택이나 이러한 점이 크게 변하는것도 없을 뿐더러 이에 맞춰 봉급 또한 거의 최저 시급을 받기 때문에 이직을 결심하였습니다. 회사를 다니면서 비트코인이 역대급 호황을 누리면서 P2E 또한 급속도로 성장을 하는 모습을 보았습니다.저는 이러한 모습을 보고 시장조사를 하였고 결론은 추후 어떤식으로든 성공할만한 아이템 이다 라는 생각에 이직을 하였습니다. 국내 에서 국비로 진행..",
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fc1d7PT%2FbtrOciEEvhV%2F1HD6EGGvickv915Us0nLPK%2Fimg.png'
  },
  {
    id: 140,
    course: 'AIB',
    url: 'https://blog.naver.com/didpql533/222709226037',
    title: '[Bootcamp] AI 부트캠프 벌써 한 달!',
    description:
      '시간이 정말 빠르다. 대학원 합격 포기하고 코드스테이츠 들어가고싶어서 이런 경솔한 글을 쓴 것이 ...^^ ...',
    thumbnailURL:
      'https://media2.giphy.com/media/zAow7d16Sf0xq/giphy.gif?cid=790b7611319cb30b0b18c9066ac97252e9058bc79dd32abc&rid=giphy.gif&ct=g'
  },
  {
    id: 141,
    course: 'AIB',
    url: 'https://blog.naver.com/didpql533/222732522277',
    title: '[Bootcamp] 코드스테이츠 AI 부트캠프 5-6주차 회고',
    description:
      '지난 주 스프린트 챌린지(이하 스챌) 에서 2연타를 맞고 자괴감에 회고를 쓰지 않아 2주나 지각해버린 5,6...',
    thumbnailURL: 'https://cdn.discordapp.com/attachments/973133885670064188/975644866757423114/giphy_5.gif'
  },
  {
    id: 142,
    course: 'AIB',
    url: 'https://m.blog.naver.com/didpql533/222746776353',
    title: '[Bootcamp] 어느새 두 달?',
    description:
      '대망의 섹션 2를 정리할 때가 되었다. 참 열심히 사는 시기에는 시간이 너무너무 빠르다고 느끼는데 그게 ...',
    thumbnailURL: 'https://cdn.discordapp.com/attachments/973133885670064188/979177580236271626/giphy.webp'
  },
  {
    id: 143,
    course: 'AIB',
    url: 'https://velog.io/@cualquier/220519-0524%EB%A8%B8%EC%8B%A0%EB%9F%AC%EB%8B%9D-%EA%B0%9C%EC%9D%B8-%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8-%ED%9A%8C%EA%B3%A0',
    title: '220519-0524 머신러닝 개인 프로젝트 회고',
    description:
      '진행 기간: 5월 19일 ~ 5월 24일 프로젝트의 목표데이터셋을 사용하여 머신러닝 모델을 만든 후 성능 및 인사이트를 도출 / 공유하는 것 (데이터셋 선정부터 모델 해석까지의 결과로 보여주어야 한다)문제, 데이터셋, 모델 선정 등 모든 과정에서 완전한 자유도가 주어졌',
    thumbnailURL: 'https://codestates-urclass.s3.ap-northeast-2.amazonaws.com/cardnews_default_thumbnail.png'
  },
  {
    id: 144,
    course: 'AIB',
    url: 'https://blog.naver.com/didpql533/222900633762',
    title: '[AIB 13기] 코드스테이츠 AIB 최종 프로젝트 회고',
    description:
      '기분이 싱숭생숭한 요즘.. 본인은 어제부로 코드스 CP2 프로젝트를 끝냈다..! (축하해 나자신) 그래서 오...',
    thumbnailURL: 'https://cdn.discordapp.com/attachments/973133885670064188/1040158824440549376/03e99c8b19790360.gif'
  },
  {
    id: 145,
    course: 'AIB',
    url: 'https://icdath95.tistory.com/4',
    title: '🔥Motivation in CODESTATES🔥',
    description:
      '코트 스테이츠에서 진행하는 콘텐츠 어워즈! 코드 스테이츠에선 달마다 콘텐츠 어워즈를 진행한다! 블로그에 그 달에 주어진 주제와 관련해서 글을 작성하고 투표를 통해 최고의 콘텐츠를 뽑는다!! 글쓰기에 대한 연습도 할 수 있고! 블로그를 꾸준히 운영할 수 있게 도와주는 점이 장점이라고 생각한다! 처음 블로그를 운영하는거라 좋은 주제가 없을까 하다가, 이번 어워즈의 주제가 동기부여를 되짚어 보는 시간이 될 수 있을 것 같아 끄적여 본다...ㅎ 1. 코드스테이츠 만나고 내가 달라진 점 1) 규칙적인 생활! 코드 스테이츠 초기에는 늦잠 + 낮잠 마저 때려버리고 새벽에 공부했다면, 요새는 최대한 오전 오후 공부와 함께 저녁에는 간단한 코딩 또는 취미생활 등으로 윤택한(?) 삶을 살아가고 있다 ㅎ 이전 직장의 스케줄..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F5VuzV%2Fbtrq7UOIR9E%2FbiouRe4RXkvWJLLBDQhh3K%2Fimg.jpg'
  },
  {
    id: 146,
    course: 'AIB',
    url: 'https://blog.naver.com/hyhy0582/222496912408',
    title: '코드스테이츠에서 내가 얻은 것',
    description:
      '[2021.9] 주제 : 코드스테이츠에 들어오길 잘했다고 생각한 순간은? 코드스테이츠에서 어떻게 지내고 있나?...',
    thumbnailURL: 'https://ifh.cc/g/sqomTH.png'
  },
  {
    id: 147,
    course: 'AIB',
    url: 'https://blog.naver.com/didpql533/222907361046',
    title: '[Bootcamp] 코드스테이츠 AIB13th 수료후기 + Tips',
    description: '2022. 03. 28 대학원 합격 포기 후 코드스 부트캠프 탑승 2022. 10. 19 코드스 AIB 13기 수료 7개월 대…',
    thumbnailURL: 'https://ifh.cc/g/7gYYpl.jpg'
  },
  {
    id: 148,
    course: 'AIB',
    url: 'https://velog.io/@ljs7463/2021%EB%85%84-%ED%9A%8C%EA%B3%A0%ED%8F%90%EC%97%85%EB%B6%80%ED%84%B0-%EC%B7%A8%EC%97%85%EA%B9%8C%EC%A7%80',
    title: '비전공 신입 데이터 분석가 2021년 회고(폐업부터 취업까지)',
    description:
      '2021년은 정말 많은일이 일어났고 도전해온 1년이었다.2020년에 창업을 시작했지만 2021년 2분기에 창업을 갑작스럽게 정리하게 되었고, 고민을 하다가 데이터 분석가의 길을 결심하였다. 사실 창업을 정리한 가장큰 이유이면서 데이터 분석가의 길을 결심한 이유인 담대한',
    thumbnailURL:
      'https://velog.velcdn.com/images/ljs7463/post/add0bbe6-0a0b-4a00-8df5-ee231969e140/%EC%8A%A4%ED%81%AC%EB%A6%B0%EC%83%B7%202022-01-01%20%EC%98%A4%EC%A0%84%2011.11.12.png'
  },
  {
    id: 149,
    course: 'AIB',
    url: 'https://welcomec.tistory.com/entry/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-AI-%EB%B6%80%ED%8A%B8%EC%BA%A0%ED%94%84-14%EA%B8%B0-%EC%9E%85%EC%86%8C%EC%9E%90-1%EC%A3%BC%EC%9D%BC-%ED%9B%84%EA%B8%B0',
    title: '코드스테이츠 AI 부트캠프 14기 입소자 1주일 후기',
    description:
      '최근에 AI BootCamp에 입학(?)해서 뭔가를 열심히 주입하고 있는 중입니다. 벌써 1주일이 됐는데.. 뭔갈 막 열심히 하고는 있는데.. 하는도중에는 정확히 뭘하는지는 잘 모른채 땅만 열심히 파고있는 느낌입니다.. 네 그렇습니다. 일주일 소감입니다. ㅎㅎ 좀 더 자세하게 정리 나열해보면 일주일 지난뒤 지금 회고해보니 데이터프레임, 행렬, 시리즈, merge, groupby, read_csv 등등의 함수들과 pandas, numpy, 구글코랩, 깃, 슬라이도, 노션!, 디스코드 등등 새로운툴과 사용법을 많이도 배웠네요. Git만해도 우선 Fork 하고 코랩 확장해서 작업하고 git으로 사본저장 해서 Pull Request 까지 하는게 과제제출의 한사이클이고. 음.. 다른사람의 코드도 볼 수 있고 질문..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbZVaqj%2FbtrDU1A4WZN%2F2CyufWE6iKeHB5MlKqGv61%2Fimg.png'
  },
  {
    id: 150,
    course: 'AIB',
    url: 'https://blog.naver.com/oromio5/222849645752',
    title: '[회고] 코드스테이츠 AI Bootcamp 1-2주차',
    description: '🧡 FACT 걱정 가득 안고 시작했던 부트캠프는 내 걱정을 초월할 만큼 내게 버거웠다. 부족한 부분은 ...',
    thumbnailURL: 'https://ifh.cc/g/nwkRvy.jpg'
  },
  {
    id: 151,
    course: 'SEBFE',
    url: 'https://velog.io/@dongoc21hj/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-Pre-Course-4%EC%A3%BC%EC%B0%A8-%ED%9A%8C%EA%B3%A0',
    title: '코드스테이츠 Pre Course 4주차 회고',
    description: 'CodeStates Pre-Course Review',
    thumbnailURL: 'https://images.velog.io/velog.png'
  },
  {
    id: 152,
    course: 'SEBFE',
    url: 'https://medium.com/positivelypassionate/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-%EC%9D%B4%EB%A8%B8%EC%8B%9C%EB%B8%8C-%EC%BD%94%EC%8A%A4%EB%A5%BC-%EB%A7%88%EC%B9%98%EB%A9%B0-8cefa4d7d594',
    title: '코드스테이츠 이머시브 20기를 마치며',
    description:
      '인터뷰를 보고, 합격 메일을 받고, 슬랙 노션 줌 등 코드스테이츠에서 사용하는 도구들에 익숙해졌던 기억이 난다. 당시 귀국하고 오래 지나지 않은 상황이었는데 직급과 나이에 관계없이 모두 ‘-님’으로 부르는 문화가 영국 생활이 떠오르게도 하고 무척 좋았다.',
    thumbnailURL: 'https://miro.medium.com/fit/c/160/160/1*V-oGDEj8_J8_i80d8j9rfA.png'
  },
  {
    id: 153,
    course: 'SEBFE',
    url: 'https://o-yeon.tistory.com/108',
    title: '코드스테이츠 기업 협업 프로젝트 후기 (feat. 휴먼스케이프)',
    description:
      '코드스테이츠에 등록하던 6개월 전이 정말로 엊그제 같은데 일주일에 한 번 외출하는 생활로 자발적 자가격리를 하며, 집에서 공부만 하는 정신없는 하루 하루들을 보내다보니 오지않을 것만 같던 8월 중순이 왔다..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F1kuSG%2FbtqGZHfkmr5%2Fl6k9SvgD26pg4naHvvjjLK%2Fimg.png'
  },
  {
    id: 154,
    course: 'SEBFE',
    url: 'https://velog.io/@chachagogogo/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-pre%EA%B3%BC%EC%A0%95-%ED%9A%8C%EA%B3%A0%EB%A1%9D',
    title: '코드스테이츠 pre과정 회고록',
    description:
      '코드스테이츠에서 지난 4주 간 pre과정을 수강했습니다.간단히 회고록을 적어볼까 합니다.제 소개글은 여기에 적어놨습니다. => 0. 개발로의 진로 변경부터 합격 통지까지그래도 짧게나마 소개를 하자면, 저는 비상경계 문과 전공 출신+개발의 개(...)도 모르는 채로 코드',
    thumbnailURL:
      'https://images.velog.io/images/chachagogogo/post/23e22143-df38-4fba-9cee-60490538e1be/thought-catalog-505eectW54k-unsplash.jpg'
  },
  {
    id: 155,
    course: 'SEBFE',
    url: 'https://velog.io/@rameau17/Final-Project-%ED%9A%8C%EA%B3%A0',
    title: 'Final Project 회고',
    description:
      '끝날 것 같지 않던 파이널 프로젝트가 끝나고 발표까지 마친 지금, 시원섭섭함과 불안함이 공존하는 상태이다. 불안함은 내 실력에 대한 불신에서 비롯되는 것이기에, 이를 극복하기 위해선 더 많은 노력만이 답이라는 것을 알고 있다. 그 노력의 시작으로, 파이널 프로젝트 회고',
    thumbnailURL: 'https://images.velog.io/images/rameau17/post/c290b6f6-8448-4fc5-acc6-7e9c3a573d5b/빚다로고.png'
  },
  {
    id: 156,
    course: 'SEBFE',
    url: 'https://velog.io/@hwanieee/Final-Project-%ED%9A%8C%EA%B3%A0%EB%A1%9D',
    title: 'Final Project 회고록',
    description:
      "Bitda 링크git hub 링크1주 동안 새로운 스택을 공부하고, 2주 동안 3명의 팀원들과 함께 작업한 전통주 추천 웹사이트, '빚다'.퍼스트 프로젝트와 같이 나는 프론트 엔드를 맡았다.프로젝트를 하면서 내가 했던 Works :전반적인 와이어 프래임 및 전체 화면구",
    thumbnailURL: 'https://images.velog.io/images/hwanieee/post/d9358864-9355-451e-8645-e8649807e028/bitdalanding.gif'
  },
  {
    id: 157,
    course: 'SEBFE',
    url: 'https://velog.io/@yrkimyy/Knocking-On-Final-Projects-Door-%ED%9A%8C%EA%B3%A0',
    title: "Knocking On Final Project's Door, 회고",
    description:
      '🖥 Cominciamo, 파이널 프로젝트와 코드스테이츠의 코스가 드디어 끝났다. 끝이 있다면 새로운 시작도 있는 법. 이제는 본격적으로 개발자의 길을 시작해야 할 때다. 생각보다 4주, 아니 5개월이 빠르게 지나갔다. 아직 더위가 가시지 않을 때쯤 시작한 것 같은',
    thumbnailURL:
      'https://images.velog.io/images/yrkimyy/post/ceac9a1c-4d56-44cd-98d9-f5ae2f1c847d/2번째[메인].png'
  },
  {
    id: 158,
    course: 'SEBFE',
    url: 'https://jun931223.tistory.com/203?category=913317',
    title: '파이널 프로젝트 : Knock-Knock',
    description:
      '성취감과 자존감을 높여줬던 4주 프로젝트 knock-knock! 시간이 정말 말도 안 되게 빨리 지나갔다.. 처음 코드스테이츠에 들어왔을 때만 해도 ,,, 티 안 내려고 당당한 척했지만 나 정말 수료할 수 있을까? 이거..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FKnpk1%2FbtqVV4ncDpr%2FvRMj5ViBB0KOKfuNVgW2R0%2Fimg.gif'
  },
  {
    id: 159,
    course: 'SEBFE',
    url: 'https://bit.ly/2Z8xq4J',
    title: '파이널 프로젝트 회고',
    description:
      '한 달이라는 시간동안 모든 시간과 집중을 쏟은 내 마지막 프로젝트가 끝이 나고 수료까지 마쳤다. 수료하면 마음이 마냥 편할 줄 알았는데, 사회로 내던져진 20살의 나로 돌아간 기분이어서 싱숭생숭하다.  이러한 마음도 잠시, 너무 바빠졌다. 수료하니까 더욱더 마음이 조급해지고 해야 할 것 투성이... 그 중 하나인 파이널 프로젝트에 대한 회고를 써보려 한다...!',
    thumbnailURL:
      'https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F42d6c8da-de90-4a69-bac3-ac4ae9e3bb67%2FUntitled.png?table=block&id=7cbf4c55-fb41-4739-a7e5-3cfdae5926b2&spaceId=c2aaa0e2-767e-4e6c-96ce-026f70c63316&width=3800&userId=f2a94833-4d63-4709-9ba2-31fd40404559&cache=v2'
  },
  {
    id: 160,
    course: 'SEBFE',
    url: 'https://velog.io/@qmasem/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-Final-Project-%ED%9A%8C%EA%B3%A0',
    title: '코드스테이츠 Final Project 회고',
    description:
      '파이널 프로젝트 회고를 하며 지난 한 달 동안 제가 어떤 개발자였고, 앞으로는 어떻게 성장해 나갈 것인지 모색하는 시간을 가져보겠습니다.아이디어 회의가장 먼저 한 일은 각자 아이디어를 준비해 발표하는 것이였습니다.저는 Slow TV장르 영상 컨텐츠를 제공하는 서비스를',
    thumbnailURL:
      'https://media.vlpt.us/images/qmasem/post/c55ef1ea-69ba-4961-b215-1170a602208e/ezgif.com-gif-maker.gif'
  },
  {
    id: 161,
    course: 'SEBFE',
    url: 'https://velog.io/@young_mason/Momojito-%EC%B9%B5%ED%85%8C%EC%9D%BC-%EC%BB%A8%ED%85%90%EC%B8%A0-%EC%84%9C%EB%B9%84%EC%8A%A4-ReactNext.js-4%EC%A3%BC-%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8-%EB%8F%8C%EC%95%84%EB%B3%B4%EA%B8%B0',
    title: '[Momojito] 칵테일 컨텐츠 제공 서비스 :: React&Next.js 4주 프로젝트 돌아보기',
    description:
      '개요 코드스테이츠 파이널 프로젝트로 약 4주간 진행했던 프로젝트가 마무리 됐습니다. 퍼스트 프로젝트에 이어 새로운 팀에서 다시 한 번 팀장 역할을 맡았고 마찬가지로 프론트엔드 개발을 담당하였습니다. 서비스이름은 Momojito (모모히또) 입니다. 모모히또는 초',
    thumbnailURL:
      'https://s3.us-west-2.amazonaws.com/secure.notion-static.com/5fc539b8-1450-467b-b7f9-2d0b2928ff85/.gif?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAT73L2G45O3KS52Y5%2F20210208%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20210208T081302Z&X-Amz-Expires=86400&X-Amz-Signature=5e3946af357e6a9c83e45d613e227c94c7aa8264a709e0421a6b874eb75309a1&X-Amz-SignedHeaders=host'
  },
  {
    id: 162,
    course: 'SEBFE',
    url: 'https://velog.io/@hwanieee/Final-Project-%ED%9A%8C%EA%B3%A0%EB%A1%9D',
    title: 'Final Project(빚다) 회고록',
    description:
      "Bitda 링크git hub 링크1주 동안 새로운 스택을 공부하고, 2주 동안 3명의 팀원들과 함께 작업한 전통주 추천 웹사이트, '빚다'.퍼스트 프로젝트와 같이 나는 프론트 엔드를 맡았다.프로젝트를 하면서 내가 했던 Works :전반적인 와이어 프래임 및 전체 화면구",
    thumbnailURL: 'https://media.vlpt.us/images/hwanieee/post/d9c39a63-0989-4512-a22e-40cfe2b63aff/good%20gif.gif'
  },
  {
    id: 163,
    course: 'SEBFE',
    url: 'https://breezebm.tistory.com/40',
    title: '리코딩 : 4주 프로젝트 회고록! Fin (feat. 코드스테이츠)',
    description:
      '💡프로젝트 돌아보면서 생각보다 4주라는 시간이 금방지나 갔다. 처음에는 시간이 정말 많고 여유롭게 진행 할것 같아지만 생각보다 SR부터 치열했던 것 같다. 그렇다고 해서 SR이 힘들었지만 싫은 것은 아니였..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FBzohT%2FbtqVIKPYLwV%2F0uZ0XgsZJ5CUYb6ouNXXcK%2Fimg.gif'
  },
  {
    id: 164,
    course: 'SEBFE',
    url: 'https://velog.io/@hangoook/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-Pre-course%EB%A5%BC-%EB%81%9D%EB%A7%88%EC%B9%98%EB%A9%B0',
    title: '코드스테이츠 Pre-course를 끝마치며',
    description: 'pre course 회고',
    thumbnailURL:
      'https://images.velog.io/images/hangoook/post/c2124701-5cc6-4655-8db1-d6ca046ef4fa/codeStates logo.png'
  },
  {
    id: 165,
    course: 'SEBFE',
    url: 'https://velog.io/@baymaxx/Pre-course-4%EC%A3%BC-%ED%9A%8C%EA%B3%A0%EB%A1%9D',
    title: 'Pre course 4주 회고록',
    description: '🔥Intro',
    thumbnailURL: 'https://images.velog.io/images/baymaxx/post/00a206ff-395c-42b6-804a-0757caba9746/post.jpg'
  },
  {
    id: 166,
    course: 'SEBFE',
    url: 'https://velog.io/@kkt12121/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-Pre-course-%EB%81%9D',
    title: '[코드스테이츠] Pre-course 끝 !',
    description:
      '길다고 하면 길고 짧다고 하면 짧은 프리코스가 드디어 끝이 났다 !!!벌써 4주가 지났다고 생각하니 시간이 참 빠르다고 생각이든다...프리코스의 마지막 관문인 ha를 통과한게 아직 믿기지가 않는다비전공자로 도전한 나로써는 개발자 분야로써 배움이 너무 어려웠다... 코드',
    thumbnailURL: 'https://images.velog.io/velog.png'
  },
  {
    id: 167,
    course: 'SEBFE',
    url: 'https://velog.io/@jimmy0417/CodeStates-Software-Engineering-Full-Pre-10%EA%B8%B0%EB%A5%BC-%EB%A7%88%EC%B9%98%EB%A9%B0',
    title: '[CodeStates] Software Engineering Full Pre 10기를 마치며',
    description: '코드스테이츠의 Pre Course를 졸업하고 쓰는 회고록 입니다.',
    thumbnailURL:
      'https://images.velog.io/images/jimmy0417/post/e6b0d70a-7eb4-4db8-8686-464c280bc226/codestates_logo_yellow.jpg'
  },
  {
    id: 168,
    course: 'SEBFE',
    url: 'https://velog.io/@flobeeee/%EB%91%90%EB%B2%88%EC%A7%B8-%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8-%EB%95%85%EB%95%85%EB%A7%88%EC%BC%93TTMK',
    title: '두번째 프로젝트 / 땅땅마켓(TTMK)',
    description:
      '드디어 나의 두 번째 프로젝트가 끝이 났다.🎉이번 프로젝트는 땅땅마켓이다.위치기반 중고거래 서비스인 당근마켓과 비슷하지만, 우리는 경매시스템을 접목했다.디자인도 모든 팀원이 만족하게끔 나와서, 너무 행복하다.지금부터 우리 사이트를 소개하겠다.',
    thumbnailURL: 'https://images.velog.io/images/flobeeee/post/7ff983ae-535e-408f-aa1d-0bd7e0e36f05/Landing_Page.gif'
  },
  {
    id: 169,
    course: 'SEBFE',
    url: 'https://yeonjewon.tistory.com/78',
    title: '코드스테이츠 파이널 프로젝트 회고',
    description: '배포 링크 깃헙 링크',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F17n8e%2Fbtq3UTXKWjc%2FK3JluJyWlKic3or5jc4Hn1%2Fimg.png'
  },
  {
    id: 170,
    course: 'SEBFE',
    url: 'https://kavoom2.github.io/project/codestates/Retrospect-final-project/',
    title: '팀 프로젝트 Logoyogo 회고',
    description: '로고를 누구나 쉽게 만들 수 있는 서비스',
    thumbnailURL: 'https://drive.google.com/uc?export=view&id=1GjkeT9Ot4P2vQnw0tOKSIAPWDftS6-_K'
  },
  {
    id: 171,
    course: 'SEBFE',
    url: 'https://velog.io/@monocrom1018/%EA%B8%B0%EC%97%85%ED%98%91%EC%97%85-%EA%B2%B0%EA%B3%BC%EB%AC%BC',
    title: '4주간의 기업협업 프로젝트 결산',
    description:
      '0. 들어가며 마냥 길 것만 같았던 4주간의 기업협업도 어느덧 끝이 났다. 아무것도 모른 채 헤헤헤 뽑혔다 하면서 들어간 인썸니아 사무실에는 좋은 동료들, 좋은 사수들을 비롯해 근무환경이 거의 완벽하게 갖춰져 있었고 딱 한가지 유난히 부족했던건 내 실력이었다. 좋',
    thumbnailURL:
      'https://media.vlpt.us/images/monocrom1018/post/9423fad0-b95c-40fa-a693-ba5a56588d9f/%E1%84%89%E1%85%B3%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AB%E1%84%89%E1%85%A3%E1%86%BA%202021-04-26%20%E1%84%8B%E1%85%A9%E1%84%92%E1%85%AE%209.00.59.png'
  },
  {
    id: 172,
    course: 'SEBFE',
    url: 'https://velog.io/@flobeeee/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-20%EC%A3%BC-%ED%9B%84%EA%B8%B0-26%EA%B8%B0-%EC%88%98%EB%A3%8C%EC%83%9D',
    title: '행복한 😈 코드스테이츠 수료 (IM 26기)',
    description: '진짜 솔직하게 추천하냐고..?',
    thumbnailURL: 'https://images.velog.io/images/flobeeee/post/7bffd6d5-b290-4929-ab80-72d587a8497a/done.gif'
  },
  {
    id: 173,
    course: 'SEBFE',
    url: 'https://velog.io/@afashs/Codestates-Software-Engineering-Bootcamp-Course-%EC%88%98%EA%B0%95-%ED%9B%84%EA%B8%B0',
    title: 'Codestates Software Engineering Bootcamp Course 수강 후기 (IM 26기)',
    description: '코드스테이츠 수강생 신분으로 자바스크립트 풀스택 과정을 수강한 후기입니다.',
    thumbnailURL: 'https://images.velog.io/images/afashs/post/40d0e301-0699-4b43-84eb-153197991397/1.png'
  },
  {
    id: 174,
    course: 'SEBFE',
    url: 'https://velog.io/@eensungkim/5%EC%A3%BC-%EA%B0%84%EC%9D%98-%ED%9A%8C%EA%B3%A0-TIL-33%EC%9D%BC%EC%B0%A8',
    title: '5주 간의 회고 (TIL 33일차)',
    description: '쉼 없이 달려온 5주(사실 아직 1/4 밖에 안 지났지만 말이죠..) 였습니다. ',
    thumbnailURL: 'https://images.velog.io/images/eensungkim/post/36d2da2a-797e-41e9-9cac-02bbb98f4e53/img.png'
  },
  {
    id: 175,
    course: 'SEBFE',
    url: 'https://medium.com/jongah-tech-blog/full-im-29%EA%B8%B0-5%EC%A3%BC-%ED%9A%8C%EA%B3%A0%EB%A1%9D-aeb732fae0c7',
    title: 'Full IM 29기 5주 회고록',
    description: '1+1=2를 학습하고 과제는 187382973+1928303194 =? 이런 느낌…',
    thumbnailURL: 'https://miro.medium.com/max/670/1*ITxclPF8TC5hgkmiJAzOQg.png'
  },
  {
    id: 176,
    course: 'SEBFE',
    url: 'https://p-story-js.tistory.com/72',
    title: 'Code States Software Engineering Bootcamp 회고록',
    description:
      '길었던 20주가 끝났다. 이제 프로젝트 할 일만 남았다. 20주간 공부해왔던 과정을 한마디로 표현하면 마치 디도스 공격 같았다. 디도스란? DDoS (Distributed Denial of Service. 분산서비스거부공격)는 다수의 악..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbLbM1Z%2Fbtq6z4Qj17b%2FBZWDkQVsvKdKQsf2NSYLQK%2Fimg.jpg'
  },
  {
    id: 177,
    course: 'SEBFE',
    url: 'https://vvs1.tistory.com/37?category=1023184',
    title: '코드스테이츠 후기 (한 달 동안 느낀 점 / Section 1 후기)',
    description:
      '코딩에 대한 교육은 정말 많은데, 국비도 많이 알아 봤지만 저는 많은 돈을 들여서라도 하게 되는 이유는 다른 교육보다 온전히 몰입을 할 수 있는 장점과, 체계적인 시간표, 그리고 집 밖에 나가지 않아도 되는..',
    thumbnailURL:
      'https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80'
  },
  {
    id: 178,
    course: 'SEBFE',
    url: 'https://velog.io/@eensungkim/Section-2-%EC%B4%9D%EC%A0%95%EB%A6%AC-TIL-67%EC%9D%BC%EC%B0%A8',
    title: 'Section 2 총정리 (TIL 67일차)',
    description: '페어 프로그래밍의 덕을 본 한 달이었습니다.',
    thumbnailURL:
      'https://images.velog.io/images/eensungkim/post/053ce72a-ab6a-4b60-bab8-c135b45527de/16216_20640_5034.jpeg'
  },
  {
    id: 179,
    course: 'SEBFE',
    url: 'https://www.notion.so/Section-2-495024ce3c614251822a088c77616dfc',
    title: 'Section2 후기',
    description: '이번 섹션에서 소흘했던 것은 깃헙 잔디 관리하는것! 다음 섹션에선 이것도 보완해야겠다.',
    thumbnailURL:
      'https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2552&q=80'
  },
  {
    id: 180,
    course: 'SEBFE',
    url: 'https://medium.com/jongah-tech-blog/full-im-29%EA%B8%B0-10%EC%A3%BC-%ED%9A%8C%EA%B3%A0%EB%A1%9D-689527013e8c',
    title: 'Full IM 29기 10주 회고록',
    description: '매일이 멘붕이었는데 HA test는 어떻게 풀었지?',
    thumbnailURL:
      'https://images.unsplash.com/photo-1580927752452-89d86da3fa0a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80'
  },
  {
    id: 181,
    course: 'SEBFE',
    url: 'https://velog.io/@eensungkim/Section-3-%EC%B4%9D%EC%A0%95%EB%A6%AC-feat.-Programming-101',
    title: 'Section 3 총정리 (feat. Programming 101)',
    description: '7월 14일, 하루 하고도 반나절이 걸린 마지막 HA 를 무사히 마쳤습니다. ',
    thumbnailURL:
      'https://images.velog.io/images/eensungkim/post/318ef157-223a-464c-b4df-8ab93fd57dab/19cf681b90171bb49e58da8dab766be7.jpeg'
  },
  {
    id: 182,
    course: 'SEBFE',
    url: 'https://medium.com/jongah-tech-blog/full-im-29%EA%B8%B0-15%EC%A3%BC-%ED%9A%8C%EA%B3%A0%EB%A1%9D-e71e2b0e4bf',
    title: 'Full IM 29기 15주 회고록',
    description: '#멘탈관리가_중요했던_섹션3 #마지막_HA #프로젝트_시작',
    thumbnailURL:
      'https://images.unsplash.com/photo-1562813733-b31f71025d54?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2549&q=80'
  },
  {
    id: 183,
    course: 'SEBFE',
    url: 'https://velog.io/@shitaikoto/weeklyreview-week10',
    title: '[Week 10] 주간 학습 정리 및 회고',
    description: '건강 관리, 수면 패턴 관리의 중요성을 뼈저리게 느꼈던 5주였습니다... ',
    thumbnailURL: 'https://images.velog.io/images/shitaikoto/post/6ef6c0c8-8a12-4ce0-a59b-1ff5f6e9de42/nose.jpg'
  },
  {
    id: 184,
    course: 'SEBFE',
    url: 'https://velog.io/@planethoon/Section-1-%ED%9A%8C%EA%B3%A0',
    title: 'Full SEB 31기 Section 1 회고',
    description: '이제 출발점에 선 코린이',
    thumbnailURL: 'https://images.velog.io/images/planethoon/post/244f529a-73ca-42d0-8511-0a34f6bd323b/giphy.gif'
  },
  {
    id: 185,
    course: 'SEBFE',
    url: 'https://loko1124.tistory.com/40',
    title: '[Code States] Section 1 <산뜻한 첫 출발>',
    description:
      "1. 국비지원 학원과 Code States의 차이 나는 20년 8월 중순부터 21년 2월 초까지 국비지원 학원(이하 '국비')의 교육과정을 겪었다. 그렇다 보니 이번에 Code States에서 겪었던 5주간의 학습 과정 안에서 두 교..",
    thumbnailURL: 'https://t1.daumcdn.net/tistory_admin/static/images/openGraph/opengraph.png'
  },
  {
    id: 186,
    course: 'SEBFE',
    url: 'https://velog.io/@beablessing/SECTION3-%ED%9A%8C%EA%B3%A0',
    title: 'SECTION3 회고',
    description:
      '스터디원들과 가장 자주 했던 말은왜 벌써 금요일이죠? ,오늘 무슨요일이에요?.정신없이 주어진 일정을 소화하다보니 눈 감았다 뜨면 일주일씩 훅훅 지나있는 느낌이었다.섹션2를 두번 보내본 사람으로써 " 일정에 성실히참여 + 복습 " 이렇게만 잘 해내도 ha를 통과하는데는',
    thumbnailURL:
      'https://media.vlpt.us/images/beablessing/post/8e9d0e1f-02ba-4b06-990e-227c2072893c/pocket-watch-598039_960_720.webp?w=768'
  },
  {
    id: 187,
    course: 'SEBFE',
    url: 'https://ribbon-alyssum-680.notion.site/3-fcf2029ba86546f9a14dbe936b7a68a0',
    title: '코드 스테이츠 부트캠프 섹션3 회고',
    description: '섹션2가 끝난 다음주부터 섹션3 과정이 시작되었다.',
    thumbnailURL:
      'https://images.unsplash.com/photo-1627398242454-45a1465c2479?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=4800'
  },
  {
    id: 188,
    course: 'SEBFE',
    url: 'https://velog.io/@bbaa3218/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-SEB-Section-2%EB%A5%BC-%EB%8F%8C%EC%95%84%EB%B3%B4%EB%A9%B0',
    title: '[코드스테이츠] SEB Section 2를 돌아보며',
    description:
      'Section 2는 1과 비교해서 시간이 훨씬 빨리 지나갔다. 확실히 1보다 심화적인 내용을 학습하다 보니 난이도도 더 높았지만 새로운 개념을 배우고 코드를 해석하는 능력도 길러져서 그만큼 더 재미있다고 느꼈다. 순식간에 지나간 Section 2를 돌아보며 고치거나 추',
    thumbnailURL:
      'https://media.vlpt.us/images/bbaa3218/post/d9c53089-13d9-4b7e-a872-b83ea11542ee/코드스테이츠.png?w=768'
  },
  {
    id: 189,
    course: 'SEBFE',
    url: 'https://loko1124.tistory.com/78',
    title: 'Section 2 <개발하는 즐거움에 대해서>',
    description:
      "1. 본격적으로 마주하게 된 Algorithm & Data Structure Section 2의 백미는 역시 Algorithm과 자료 구조였다. 이 파트는 Section 1 때 코플릿을 조금 풀었던 것이 그야말로 '준비운동에 가까웠구나' 싶을 정도로..",
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FHSLKr%2FbtrcA2zug9B%2FY1jfK4K5muRVMUgnZQdpGK%2Fimg.png'
  },
  {
    id: 190,
    course: 'SEBFE',
    url: 'https://velog.io/@skagns211/Codestates-FullSEB33-Section1-%ED%95%9C%EB%8B%AC%EA%B0%84%EC%9D%98-%ED%9A%8C%EA%B3%A0',
    title: '<Codestates Full_SEB_33> Section1, 한달간의 회고',
    description:
      '📝 걱정과 나에대한 불신 그리고 안도 태어나서 처음으로 접해본 프로그래밍. 잘할 수 있을거라고 근거없는 자신감으로 시작했다. 그 자신감은 넷째 날에 와르르 무너졌다! 반복문이라는 어마무시한 녀석을 만났기 때문이다. 반복문을 배우기 전 변수, 조건문까지',
    thumbnailURL:
      'https://media.vlpt.us/images/skagns211/post/fab67bb7-69b8-4384-83a5-ae4ea3435d81/%E1%84%80%E1%85%A2%E1%84%83%E1%85%A1%E1%84%92%E1%85%A2%E1%86%BC.jpeg'
  },
  {
    id: 191,
    course: 'SEBFE',
    url: 'https://wnsdufdl.tistory.com/64',
    title: 'Full SEB 33기 Section1 회고 (+32일차)',
    description:
      '3번 문제를 풀고 있었다. 인상을 찌푸리며 모니터를 노려보다가 한 생각이 스쳤다. 손가락이 반응했고 테스트 실행 버튼을 눌렀을 때 모두 통과했다는 메시지가 떴다. .. 이게 되네..?? HA 후기 어제 리액트 HA..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FclmVHw%2FbtrcEvuxuyQ%2FhjJctBAQ7UsdetNohsiYy0%2Fimg.png'
  },
  {
    id: 192,
    course: 'SEBFE',
    url: 'https://velog.io/@eensungkim/%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-SEB-29%EA%B8%B0-2021040520210903',
    title: '코드스테이츠 SEB 29기 (2021/04/05~2021/09/03)',
    description: '끝났다! ',
    thumbnailURL: 'https://media.vlpt.us/images/eensungkim/post/bcc2a2e9-3bba-40f7-94ce-04fcec44c818/수료.png?w=768'
  },
  {
    id: 193,
    course: 'SEBFE',
    url: 'https://medium.com/jongah-tech-blog/full-im-29%EA%B8%B0-22%EC%A3%BC-%ED%9A%8C%EA%B3%A0%EB%A1%9D-7bbcbcc106a7',
    title: 'Full IM 29기 22주 회고록',
    description: '야, 너두 2주&4주 프로젝트 할 수 있어 (5주, 10주, 15주에 이은 진짜 마지막 편)',
    thumbnailURL: 'https://miro.medium.com/max/861/0*UNw4UoJR-8zMd8MX'
  },
  {
    id: 194,
    course: 'SEBFE',
    url: 'https://loko1124.tistory.com/116',
    title: 'Section 3 <어느새 100일>',
    description:
      "1. '눈 깜짝할 새' Section 2의 회고에다가 시간이 빠르게 지나가는 느낌을 받는다고 적었다. 하지만 Section 3는 그 정도 수준을 넘어섰다. 정말 자고 일어나니 어느새 HA까지 도달해 있는 느낌이었다. 왜 그랬는..",
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fcr3eVW%2FbtrgnqPT81k%2F4BjkV1qiOesDK1R7bDKeOK%2Fimg.png'
  },
  {
    id: 195,
    course: 'SEBFE',
    url: 'https://velog.io/@planethoon/Full-SEB-31%EA%B8%B0-Section-3-%ED%9A%8C%EA%B3%A0',
    title: 'Full SEB 31기 Section 3 회고',
    description: '코아가가 코린이가 되었어요!',
    thumbnailURL: 'https://codestates-urclass.s3.ap-northeast-2.amazonaws.com/card/cardnews_289_catgiphy.gif'
  },
  {
    id: 196,
    course: 'SEBFE',
    url: 'https://chemical-hotel-5f0.notion.site/Codestates-Full_SEB_33-Section2-3496b74935c9437181d058a05e139a41',
    title: '<Codestates Full_SEB_33> Section2, 나 살아 있네..?',
    description: '내 머리가 이렇게 멍청했었군! 이라는 생각을 정말 많이 하게 해준 Section2였다!',
    thumbnailURL:
      'https://chemical-hotel-5f0.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F7f4f0ac6-2208-479d-8b55-a12058f7ef48%2F%E1%84%89%E1%85%B3%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AB%E1%84%89%E1%85%A3%E1%86%BA_2021-10-01_01.32.39.png?table=block&id=f280ea35-6130-479f-81e1-213cc9faa580&spaceId=fcde359b-6aa9-4724-9ad6-aedf89ca98c6&width=2000&userId=&cache=v2'
  },
  {
    id: 197,
    course: 'SEBFE',
    url: 'https://ionc635.github.io/Section-2/',
    title: '<Codestates Full_SEB_33> Section 2, 우리는 깐부잖아.',
    description: '9월 30일 오전, 한 통의 메일을 받았다. 코드스테이츠 섹션 2 HA 테스트를 통과했다는 소식이었다.',
    thumbnailURL: 'https://ionc635.github.io/assets/images/%EC%84%B9%EC%85%98%202%20%ED%9A%8C%EA%B3%A01.png'
  },
  {
    id: 198,
    course: 'SEBFE',
    url: 'https://lesil.tistory.com/34',
    title: 'SECTION 1 회고',
    description:
      'Section 1에서 학습한 내용 * js/node : 조건문, 문자열, 반복문, 배열/객체, 자료형, 스코프, 클로저, spread/rest 문법 ,얕은/깊은 복사, DOM, 고차함수 * html/css : 기초 문법 , 레이아웃, selector, DOM * li..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpIrKr%2FbtrgkUEQndx%2FSgEgNuKzX6bwgnpnKVzc00%2Fimg.jpg'
  },
  {
    id: 199,
    course: 'SEBFE',
    url: 'https://wnsdufdl.tistory.com/216',
    title: 'Full SEB 33기 Section 3 회고 (112일차)',
    description:
      'HA 후기 확실히 코플릿은 쉬웠다. Section3에서 가장 중요하게 다룬 부분이 인증/보안 부분이었고 Section3 HA는 통과기준이 훨씬 높다고 들었기에 , 매일 인증/보안 스프린트를 복습했다. HA를 보기 1주일전에..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FYDKz0%2Fbtrj5dUbZE6%2F5KzSCHKvB5ZWNpzfstchs1%2Fimg.png'
  },
  {
    id: 200,
    course: 'SEBFE',
    url: 'https://lesil.tistory.com/64',
    title: 'SECTION 2 회고',
    description:
      '지난 SECTION 2에서 학습한 내용? 지난 섹션2에서 배운 내용들을 캘린더를 보면서 복기해보았다... [JS/Node] * 객체지향 자바스크립트 - 클래스형 모듈 작성하기 * 비동기 - Underbar 구현하기(내장 메서드 만들..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdDJcDk%2FbtrkgIsWjiP%2Fw669BBvyhyHsJRnI8OwCiK%2Fimg.png'
  },
  {
    id: 201,
    course: 'SEBFE',
    url: 'https://velog.io/@kapuist/FULLSEB35%EA%B8%B0-SECTION-1-%ED%9A%8C%EA%B3%A0%EB%A1%9D',
    title: 'FULL_SEB_35기 !SECTION 1 회고록!!',
    description:
      '나는 비전공자로 어학을공부하다가 우연히 현직 개발자 친구의 소개로 코드스테이츠에 합류 하게되었다 ㅋㅋㅋ 처음에 수업을들으면서 나름 이해하기 쉽다고 생각하였고 그냥 뭐... 생각나는대로 ... 배운대로 ... 그렇게만 풀어가면 술술풀리고있었다... 하지만 나의 그러한 자',
    thumbnailURL: 'https://media.vlpt.us/images/kapuist/post/3707a420-c9d5-4e04-a661-2f36fc6bd33f/img.png?w=768'
  },
  {
    id: 202,
    course: 'SEBFE',
    url: 'https://loko1124.tistory.com/122',
    title: '<이젠 정말 취업 뿐이야!>',
    description: 'Code States 전체 후기 _ 32기 수료생',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdPyf6p%2FbtrlD2K89z5%2Fxm8VSSWspslYIfQVct5gKK%2Fimg.jpg'
  },
  {
    id: 203,
    course: 'SEBFE',
    url: 'https://lion284.tistory.com/74',
    title: 'SECTION 3 회고블로그(2021. 10. 05. - 2021. 12. 09.)',
    description:
      '2021. 12. 09. 드디어 오늘부로 코드스테이츠의 모든 SECTION 1, 2, 3가 끝이 났다. 부끄럽다면 부끄러운 이야기이지만 나는 지금까지 진행한 SECTION 1, 2, 3를 단 한번도 한번에 통과한 적이 없다. 한번씩 기수..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FsaYrl%2FbtrnAXtm5yh%2FAVIFepHO6jm47MRvR2uj7k%2Fimg.png'
  },
  {
    id: 204,
    course: 'SEBFE',
    url: 'https://velog.io/@tok1324/section2%ED%9A%8C%EA%B3%A0',
    title: '걸어온 길을 되짚어보기, Section 2 회고',
    description: '코드스테이츠를 접하면서 해본 생각들..',
    thumbnailURL:
      'https://media.vlpt.us/images/tok1324/post/7872bedf-3a4a-4b86-b2d2-6cb73ee956e8/%EB%8C%80%ED%95%99%EC%88%98%EC%97%85.gif'
  },
  {
    id: 205,
    course: 'SEBFE',
    url: 'https://hsly22xk.tistory.com/78',
    title: '211208 36기에서 Section 1을 통과하며',
    description:
      '1. 기수 이동, 그리고 이슈 쉐어링 상담 처음 코드스테이츠에 35기로 탑승하여 첫 기수 이동 메일을 받았을 때는 정말 씁쓸하고 힘들었다. 내 공부 방법이 틀렸던 것인가, 다른 사람보다 내가 못한 것인가, 하는..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdbClSV%2FbtrnpyAgnAF%2FN77mLfFkPY0SQbEjFMPPK0%2Fimg.png'
  },
  {
    id: 206,
    course: 'SEBFE',
    url: 'https://swimmingindev.tistory.com/86',
    title: '드디어 모든 섹션이 끝났다! Section3 회고 (SEB 35)',
    description:
      '드디어 35기 모든 섹션이 종료되었다. 분명 시작할 때는 코딩에 대해 아무것도 모르는 상태였는데, 지난 3개월간 놀랍도록 성장한 자신을 보면 약간의 뿌듯함을 느끼게 된다. 하지만 아무리 그래도 힘든 건 어쩔..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbpFxBu%2FbtrqT0UuuU0%2FVG8hXEWBEGz2Jt7N03Dtq0%2Fimg.jpg'
  },
  {
    id: 207,
    course: 'SEBFE',
    url: 'https://velog.io/@estell/%EC%83%88%EB%A1%9C%EC%9A%B4-%EC%B6%A9%EA%B2%A9%EC%9D%98-%EC%8B%9C%EC%9E%91-%EC%BD%94%EB%93%9C%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%B8%A0-Section-2-%ED%9A%8C%EA%B3%A0',
    title: '새로운 충격의 시작, 코드스테이츠 Section 2 회고',
    description:
      '우선 결론부터 말하자면, 섹션 2의 HA 시험을 통과하여 섹션 3로 넘어가게 되었다.그리고, 내가 섹션 1을 진행하고 섹션 2로 넘어와서 가장 성장한 부분이 무엇이었는지 말하고 싶다.나는 섹션 2를 혼자 힘으로 넘어갔다고 생각하지않는다.정말 좋은 페어분들을 만나게 되어',
    thumbnailURL:
      'https://media.vlpt.us/images/estell/post/bdb8092d-9b00-47d2-9c68-1d8542292937/20180116_5a5d856369709.gif?w=768'
  },
  {
    id: 208,
    course: 'SEBFE',
    url: 'https://fireballer150.tistory.com/34',
    title: '코드스테이츠 섹션2의 기억',
    description:
      '0번째 지치지 않고 앞으로 아니요. 지치지 않았습니다. 왜냐구요? 여덟번째 이야기로! 첫번째 두려움과 기대 섹션2가 어떨까에대한 막연한 두려움...그리고 새로운 내용을 학습한다는 기분좋은 떨림 아마도 후자..',
    thumbnailURL:
      'https://img1.daumcdn.net/thumb/R800x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FpfzfW%2FbtrqPhoxAqL%2FBJFIulhxncLPFEVnkq5KoK%2Fimg.jpg'
  },
  {
    id: 209,
    course: 'SEBFE',
    url: 'https://lumpy-sturgeon-f79.notion.site/31-Section1-HA-d138592ba9934943bfca9d53b1381870',
    title: '<31일차> Section1 HA 회고록 ',
    description: '변수, 함수, 문자열, 반복문 ,HTML, CSS, GIT, DOM, REACT 등 엄청나게 많은걸 배웠다.',
    thumbnailURL:
      'https://lumpy-sturgeon-f79.notion.site/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fnasa_robert_stewart_spacewalk.jpg?table=block&id=d138592b-a993-4943-bfca-9d53b1381870&spaceId=4da62200-8dd9-473f-b3e3-9a8a88b8158e&width=2000&userId=&cache=v2'
  },
  {
    id: 210,
    course: 'SEBFE',
    url: 'https://blog.naver.com/company_know/222621114708',
    title: 'TIL_코딩부트캠프 섹션1_회고 { AgeNeverMatters }',
    description:
      '#코딩부트캠프 #age_never_matters #섹션1통과 #40대후반_코딩입문 #코드스테이츠 글 시작 전에 앞서 이제 ...',
    thumbnailURL:
      'https://blogthumb.pstatic.net/MjAyMjAxMTRfNTYg/MDAxNjQyMDk1NjA4NTU1.aTXg18VgrGrj92X1ExeJFaN5RPgk1QtG6idNGVhaX_gg.UdPV6gaijjyC6XbrogSKwPbpn8oNYo3D3EiLQag2r0sg.JPEG.company_know/%BC%BD%BC%C71_%B9%E8%BF%EE%B0%CD%B5%E9_%C0%CC%B9%CC%C1%F62.jpg?type=w2'
  },
  {
    id: 211,
    course: 'SEBFE',
    url: 'https://velog.io/@ww3ysq/TodayILearn24',
    title: 'TodayILearn24',
    description: '기초부터 확실하게 이해해서 기능 구현에 충실했다면 HA3 스프린트 시험 별 거 아니다. 하지만...',
    thumbnailURL: 'https://media.vlpt.us/images/ww3ysq/post/846ec165-7316-45a5-b64f-d15877951f8f/image.png'
  },
  {
    id: 212,
    course: 'SEBFE',
    url: 'https://lumpy-sturgeon-f79.notion.site/69-Section2-24a72c3410d14e57a3b5eab5ccdfdb4c',
    title: '<70일차> Section2를 마치며..',
    description:
      'Section2 HA가 드디어 끝났다!! 한달동안 정말 기분이 오르락 내리락 반복하면서 좌절하고 극복하고  롤러코스터를 탄 기분이었다. 앞으로는 어떤 시련과 고난이 찾아올까?.. 개발자의 길은 멀고도 험하다.',
    thumbnailURL:
      'https://lumpy-sturgeon-f79.notion.site/image/https%3A%2F%2Fwww.notion.so%2Fimages%2Fpage-cover%2Fnasa_space_shuttle_challenger.jpg?table=block&id=24a72c34-10d1-4e57-a3b5-eab5ccdfdb4c&spaceId=4da62200-8dd9-473f-b3e3-9a8a88b8158e&width=2000&userId=&cache=v2'
  },
  {
    id: 213,
    course: 'SEBFE',
    url: 'https://velog.io/@suxxzzy/%EC%84%B9%EC%85%98-2-%ED%9A%8C%EA%B3%A0',
    title: '섹션 2 회고',
    description: '길다면 길고 짧다면 짧다고도 할 수 있는 섹션2가 끝이 났다.',
    thumbnailURL:
      'https://media.vlpt.us/images/suxxzzy/post/2c0b733a-9849-4fe2-bdb1-a9e59fb9760b/30647AC1-B772-4607-BF3C3D43456E7103_source.jpg'
  }
];
