/* Graphql Type */
export enum QuizType {
  CHOICE = 'CHOICE',
  DESCRIPTIVE = 'DESCRIPTIVE'
}

export type QuizSet = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  isAssessment: boolean;
  quizzes: Quiz[];
};

export type Quiz = {
  id: number;
  uuid: string;
  quizSetId: number;
  question: string;
  type: QuizType;
  explanation: string;
  order: number;
  choices: QuizChoice[];
  hasSolution: boolean;
  submission: QuizSubmission;
  isMultiChoiceType: boolean;
  solution?: QuizSolution;
};

export type QuizChoice = {
  id: number;
  uuid: string;
  quizId: number;
  content: string;
  order: number;
};

export type QuizSubmission = {
  id: number;
  uuid: string;
  quizId: number;
  isCorrect: boolean;
  descriptiveAnswer: string;
  choices: QuizChoice[];
};

export type QuizSolution = {
  quizId: number;
  choices: QuizChoice[];
  descriptiveAnswers: string[];
};

/* Query, Mutation */

export type QuizSetData = {
  urclassQuizSet: QuizSet;
};

export type QuizSolutionData = {
  urclassQuiz: {
    solution: QuizSolution;
  };
};

export type ResetQuizSubmissionsData = {
  urclassResetQuizSubmissions: QuizSet;
};

export type ResetQuizSubmissionsVars = {
  quizSetId: number;
};

export type SubmitQuizSetData = {
  urclassSubmitQuizSet: QuizSet;
};

export type QuizSubmissionInput = {
  quizId: number;
  selectedChoiceIds?: number[];
  descriptiveAnswerInput?: string;
};

export type SubmitQuizSetVars = {
  quizSetId: number;
  quizSubmissions: QuizSubmissionInput[];
};
