// JobProfileForm page 3
import styled from '@emotion/styled';
import { Typography, Checkbox, message } from 'antd';
import { RequireMark } from '../../../../components/atoms/RequireMark';
import Grid from '../../../../components/atoms/Grid';
import type { InterestDomain } from '../apollo/jobs/type';
import { StyledButton } from './JobProfileForm';

const StyledInterestDomainForm = styled.div`
  section {
    padding-left: 30px;

    .title {
      display: flex;
    }

    .content {
      max-width: 800px;
      width: 100%;

      /* 관심분야 */
      .interest-domain__checkbox {
        margin-left: 0px;
      }

      .container {
        width: 50%;
        margin-bottom: 20px;
      }
    }
  }
`;

type InterestDomainFormProps = {
  edit?: boolean;

  createJobProfileLoading: boolean;
  updateJobProfileLoading: boolean;
  handleCreateJobProfile: () => Promise<void>;
  handleUpdateJobProfile: () => Promise<void>;

  isNoInterestDomain?: boolean;
  interestDomains: InterestDomain[];
  jobProfileInterestDomains: InterestDomain[];
  handleInterestDomainCheckBox: (checked: boolean, interestDomain: InterestDomain) => void;
  handleNoInterestDomainCheckBox: (checked: boolean) => void;

  /* 페이지 이동 */
  handleChangePreviousPage: () => void;
};

const InterestDomainForm: React.FC<InterestDomainFormProps> = ({
  edit,
  handleCreateJobProfile,
  handleUpdateJobProfile,

  createJobProfileLoading,
  updateJobProfileLoading,

  interestDomains,
  isNoInterestDomain,
  jobProfileInterestDomains,
  handleInterestDomainCheckBox,
  handleNoInterestDomainCheckBox,
  handleChangePreviousPage
}) => {
  return (
    <StyledInterestDomainForm>
      <section>
        <div className="title">
          <Typography.Title level={4}>관심분야</Typography.Title>
          <RequireMark />
        </div>
        <div className="content">
          <div className="container">
            <Checkbox
              checked={isNoInterestDomain}
              onChange={e => {
                handleNoInterestDomainCheckBox(e.target.checked);
              }}
            >
              무관
            </Checkbox>
          </div>
          <div className="container"></div>

          <Grid itemWidth={160} rowGap={10}>
            {interestDomains.map(domain => (
              <Checkbox
                disabled={isNoInterestDomain}
                className="interest-domain__checkbox"
                onChange={e => {
                  handleInterestDomainCheckBox(e.target.checked, domain);
                }}
                key={domain.id}
                checked={jobProfileInterestDomains.some(jpid => jpid.id === domain.id)}
              >
                {domain.name}
              </Checkbox>
            ))}
          </Grid>
        </div>
      </section>

      <div className="modal-navigate-container">
        <div className="button">
          <StyledButton
            type="link"
            disabled={false}
            onClick={() => {
              handleChangePreviousPage();
            }}
          >
            이전
          </StyledButton>
        </div>

        <div className="button">
          <StyledButton
            type="link"
            disabled={
              !(jobProfileInterestDomains.length >= 1 || isNoInterestDomain === true) ||
              createJobProfileLoading ||
              updateJobProfileLoading
            }
            onClick={() => {
              if (edit) {
                handleUpdateJobProfile();
                message.loading('프로필을 수정 중 입니다. 잠시만 기다려주세요');
              } else {
                handleCreateJobProfile();
                message.loading('프로필을 생성 중 입니다. 잠시만 기다려주세요');
              }
            }}
          >
            {edit ? '수정' : '생성'}
          </StyledButton>
        </div>
      </div>
    </StyledInterestDomainForm>
  );
};

export default InterestDomainForm;
