import React from 'react';
import styled from '@emotion/styled';
import Urls from '../../../util/urls';
import { Link } from 'react-router-dom';

import { ReactComponent as CourseIcon } from '../../../assets/svg/ico-course.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/svg/ico-logout.svg';
import { ReactComponent as MypageIcon } from '../../../assets/svg/ico-mypage.svg';
import { ReactComponent as CoplitIcon } from '../../../assets/svg/ico-coplit.svg';
import { ReactComponent as AdminIcon } from '../../../assets/svg/ico-admin.svg';
import { ReactComponent as JobsIcon } from '../../../assets/svg/ico-jobs.svg';
import { ReactComponent as HelpdeskIcon } from '../../../assets/svg/ico-helpdesk.svg';
import { ReactComponent as ArrowUpRightIcon } from '../../../assets/svg/arrow-up-right.svg';
import { ReactComponent as FeatherIcon } from '../../../assets/svg/feather.svg';

import { TextButton } from '../../atoms/Button/TextButton';

type MobileMenuProps = {
  isHasCohort: boolean;
  handleCloseMenu: () => void;
  handleLogout: () => void;
  role: string;
};

export const MobileMenuWrapper = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
  background-color: #fff;
  margin-top: 65px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: auto;
  filter: drop-shadow(0px 8px 6px rgba(0, 0, 0, 0.3));

  .list__menu--mobile {
    li {
      padding: 8px 24px;
      margin: 0 5px;
      line-height: 40px;

      .link__menu--mobile {
        display: block;
      }

      .ico__menu--mobile {
        vertical-align: middle;
        margin-right: 24px;
        color: #455573;
      }

      .txt__menu--mobile {
        color: #455573;
        letter-spacing: -0.006em;
      }

      &:hover {
        background-color: rgba(20, 23, 28, 0.05);
      }
    }
  }

  .wrap__menu--item {
    padding: 8px 24px;
    margin: 0 5px;
    color: #455573;
    line-height: 40px;
    cursor: pointer;

    &:hover {
      background-color: rgba(20, 23, 28, 0.05);
    }

    .link__menu--mobile {
      display: block;
    }

    .ico__menu--mobile {
      vertical-align: middle;
      margin-right: 24px;
    }

    .txt__menu--mobile {
      color: #455573;
      letter-spacing: -0.006em;
    }
  }
`;

const ButtonItem = styled.div`
  display: flex;
  align-items: center;

  > :first-of-type {
    margin-right: 24px;
  }

  > span {
    position: relative;

    svg {
      position: relative;
      margin-left: 4px;
      top: 3px;
    }
  }
`;

const MobileMenu: React.FC<MobileMenuProps> = ({ isHasCohort, handleCloseMenu, handleLogout, role }) => {
  const { zendesk: zendeskURL, admin: adminURL } = Urls();
  return (
    <MobileMenuWrapper onClick={handleCloseMenu}>
      {isHasCohort ? (
        <ul className='list__menu--mobile'>
          <li>
            <a className='link__menu--mobile' href='https://www.codestates.com/' target='_blank'>
              <ButtonItem>
                <FeatherIcon />
                <span className='txt__menu--mobile'>코드스테이츠 홈
              <ArrowUpRightIcon />
            </span>
              </ButtonItem>
            </a>
          </li>
          {role === 'admin' && (
            <li>
              <a className='link__menu--mobile' href={adminURL} target='_blank' rel='noopener noreferrer'>
                <AdminIcon className='ico__menu--mobile' />
                <span className='txt__menu--mobile'>어드민페이지</span>
              </a>
            </li>
          )}

          <li>
            <Link className='link__menu--mobile' to='/courses'>
              <CourseIcon className='ico__menu--mobile' />
              <span className='txt__menu--mobile'>수강 중인 코스</span>
            </Link>
          </li>
          <li>
            <Link className='link__menu--mobile' to='/classrooms'>
              <CoplitIcon className='ico__menu--mobile' />
              <span className='txt__menu--mobile'>코플릿</span>
            </Link>
          </li>
          <li>
            <Link className='link__menu--mobile' to='/jobs'>
              <JobsIcon className='ico__menu--mobile' />
              <span className='txt__menu--mobile'>Jobs</span>
            </Link>
          </li>
          {/*<li>*/}
          {/*  <a className='link__menu--mobile' href={} target='_blank' rel='noopener noreferrer'>*/}
          {/*    <HelpdeskIcon className='ico__menu--mobile' />*/}
          {/*    <span className='txt__menu--mobile'>수강생 헬프데스크</span>*/}
          {/*  </a>*/}
          {/*</li>*/}
        </ul>
      ) : (
        <></>
      )}
      <div className='wrap__menu--item'>
        <Link className='link__menu--mobile' to='/mypage'>
          <MypageIcon className='ico__menu--mobile' />
          <span className='txt__menu--mobile'>마이페이지</span>
        </Link>
      </div>
      <div className='wrap__menu--item' onClick={handleLogout}>
        <LogoutIcon className='ico__menu--mobile' />
        <span className='txt__menu--mobile'>로그아웃</span>
      </div>
    </MobileMenuWrapper>
  );
};

export default MobileMenu;
