import styled from '@emotion/styled';

import { NewMQ } from '../../../style/mediaQuery';
import { Button } from '../../../components/molecules';
import { SizeType, StatusType } from '../../../components/molecules/Button/type';
import { Typography, TypographyType } from '../../../components/atoms/Typography';
import { ColorPalette, ColorPaletteType } from '../../../styles/color';

import { ReactComponent as KakaoIcon } from '../../../assets/svg/ico-kakao.svg';
import { ReactComponent as LoginIcon } from '../../../assets/svg/ico-login.svg';
import CozLogo from '../../../assets/image/signup/coz-logo.png';

import SignupBanner from '../components/SignupBanner';

interface SignupFormProps {
  kakaoAuth: string;
  googleAuth: string;

  handleChangeIsPreApplicant: () => void;
}

const SignupForm = ({ kakaoAuth, handleChangeIsPreApplicant, googleAuth }: SignupFormProps) => {
  return (
    <SignUpFormWrapper>
      <div className='signup-form__box'>
        <img className='signup-form__logo' src={CozLogo} alt='코드스테이츠 로고' />
        <p className='signup-form__title'>
          코드스테이츠와 함께하는 <br /> 꿈을 향한 한걸음!
        </p>
        <SignupFormButtonBox>
          <Button
            className='signup-form__button signup-form__button--kakao'
            status={StatusType.DEFAULT}
            size={SizeType.MEDIUM}
            onClick={() => {
              window.location.assign(kakaoAuth);
              localStorage.setItem('authenticationType', 'login');
            }}
          >
            <KakaoIcon className='signup-form__button-kakao-icon' />
            <Typography
              className='signup-form__button-title'
              type={TypographyType.B1}
              color={ColorPaletteType.GRAY1}
              text='카카오로 1초만에 시작'
            />
          </Button>
          <GoogleLoginButton
            className='signup-form__button'
            status={StatusType.DEFAULT}
            size={SizeType.MEDIUM}
            onClick={() => {
              window.location.assign(googleAuth);
              localStorage.setItem('authenticationType', 'login');
            }}
          >
            <LoginIcon className='login-form__button-icon'></LoginIcon>
            <Typography
              className='signup-form__button-title'
              type={TypographyType.B1}
              color={ColorPaletteType.GRAY6}
              text='구글 계정으로 로그인하기'
            />
          </GoogleLoginButton>
          <div className='signup-form__button-divider'>
            <span className='signup-form__button-divider--line'></span>
            <span className='signup-form__button-divider--text'>아직 회원이 아니라면?</span>
            <span className='signup-form__button-divider--line'></span>
          </div>
          <Button
            className='signup-form__button signup-form__button--login'
            status={StatusType.DEFAULT}
            size={SizeType.MEDIUM}
            onClick={() => {
              window.location.assign(kakaoAuth);
              localStorage.setItem('authenticationType', 'login');
            }}
          >
            <Typography
              className='signup-form__button-title'
              type={TypographyType.B1}
              color={ColorPaletteType.WHITE}
              text='카카오로 회원가입'
            />
          </Button>
        </SignupFormButtonBox>
      </div>
      <SignupBanner />
    </SignUpFormWrapper>
  );
};

export default SignupForm;

const SignUpFormWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 343px;
  height: 402px;

  .signup-form__box {
    background-color: ${ColorPalette.White};
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 32px;
    letter-spacing: -0.025em;
    margin-bottom: 19px;

    .signup-form__logo {
      width: 87px;
      margin-bottom: 20px;
    }

    .signup-form__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #262f40;
      margin-bottom: 42px;
    }
  }

  ${NewMQ.TABLET} {
    width: 680px;
    height: 438px;
    display: flex;
    background-color: ${ColorPalette.White};
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 16px;

    .signup-form__box {
      box-shadow: none;
      padding: 24px 32px 24px 16px;
      margin-bottom: 0px;

      .signup-form__logo {
        width: 110px;
        margin-bottom: 20px;
      }

      .signup-form__title {
        margin-bottom: 55px;
      }
    }
  }

  ${NewMQ.DESKTOP} {
    width: 760px;
    height: 472px;
    display: flex;

    .signup-form__box {
      padding: 40px 40px 40px 24px;
      letter-spacing: -0.025em;

      .signup-form__title {
        margin-bottom: 57px;
      }
    }
  }
`;

const SignupFormButtonBox = styled.div`
  .signup-form__button {
    padding: 2px 6px 1px;
    display: block;
    border: none;
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: center;

    .signup-form__button-title {
      display: inline-block;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.025em;
      color: ${ColorPalette.BluishGray4}
    }

    .signup-form__button-kakao-icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  .signup-form__button--kakao {
    background-color: ${ColorPalette.KakaoYellow};
    margin-bottom: 12px;
  }

  .signup-form__button--login {
    background-color: ${ColorPalette.Purple};

    > .signup-form__button-title {
      color: ${ColorPalette.White};
    }
  }

  .login-form__divider {
    margin-bottom: 14px;
  }

  .signup-form__button-divider {
    display: flex;
    margin-bottom: 8px;

    .signup-form__button-divider--line {
      flex-grow: 1;
      border-top: 1px solid #667ba3;
      opacity: 0.2;
      transform: translateY(50%);
    }

    .signup-form__button-divider--text {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.025em;
      color: #667ba3;
      margin: 0 8px;
    }
  }
`;

const GoogleLoginButton = styled(Button)`
  background-color: ${ColorPalette.BluishGray1};
  margin-bottom: 24px;

  .login-form__button-title {
    display: inline-block;
  }

  .login-form__button-icon {
    vertical-align: top;
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
`;
