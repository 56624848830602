import gql from 'graphql-tag';

export const CANCEL_APPLICATION_GQL = gql`
  mutation urclassCancelApplication(
    $input: Urclass_CancelApplicationInput!
    $refundReceiveAccount: Urlcass_RefundReceiveAccountInput!
  ) {
    urclassCancelApplication(input: $input, refundReceiveAccount: $refundReceiveAccount) {
      id
      uuid
      status
      confirmedAt
      paymentType
      product {
        id
        name
        programId
        serviceStartDate
        serviceEndDate
        weekDuration
        applyStartDate
        applyEndDate
      }

      userApplicationSteps {
        id
        title
        typeformId
        type
        order
      }

      submissions {
        id
        uuid
        body
        applicationStepId
        createdAt
      }
    }
  }
`;
