import { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';

import ErrorPage from '../../components/ErrorPage';
import LoadingPage from '../../components/LoadingPage';
import { VALIDATE_PAYMENT_ORDER_GQL } from '../../apollo/product/mutation';

import type {
  ValidatePaymentOrderData,
  ValidatePaymentOrderVars
} from '../../apollo/payment/type';

const PaymentMobileRedirectPage = () => {
  const searchParam = new URLSearchParams(window.location.search);
  const impUID = searchParam.get('imp_uid') || '';
  const merchantUID = searchParam.get('merchant_uid') || '';
  const [urclassValidatePaymentOrder, { loading, data, error }] = useMutation<
    ValidatePaymentOrderData,
    ValidatePaymentOrderVars
  >(VALIDATE_PAYMENT_ORDER_GQL, {
    variables: {
      input: {
        impUID,
        merchantUID
      }
    }
  });

  useEffect(() => {
    urclassValidatePaymentOrder();
  }, []);

  if (loading) return <LoadingPage />;
  if (error || !data) {
    return (
      <ErrorPage errorList={['결제 주문에 실패 했어요. 다시 시도해주세요.']} />
    );
  }
  return (
    <Redirect
      to={`/payment/result?imp_uid=${impUID}&is_success=${data.urclassValidatePaymentOrder}`}
    />
  );
};

export default PaymentMobileRedirectPage;
