export const koreanStrings = {
  pagePrevText: '이전',
  pageNextText: '다음',
  completeText: '완료',
  previewText: '미리보기',
  editText: '수정',
  startSurveyText: '시작',
  otherItemText: '기타',
  noneItemText: '없음',
  selectAllItemText: '모두 선택',
  progressText: '{0}/{1}',
  panelDynamicProgressText: '{0} / {1} 기록',
  questionsProgressText: '{0} / {1} 개의 질문에 답변 함',
  emptySurvey: '비어있는 질문지입니다.',
  completingSurvey: '질문지 제출을 완료해 주셔서 감사합니다!',
  completingSurveyBefore: '해당 질문지를 이미 제출하셨습니다.',
  loadingSurvey: '질문지가 로드중입니다...',
  optionsCaption: '선택하십시오...',
  value: '값',
  requiredError: '질문에 답변해 주세요.',
  requiredErrorInPanel: '하나 이상의 질문에 답변해 주세요.',
  requiredInAllRowsError: '모든 행에 있는 질문에 답변해 주세요.',
  numericError: '값은 숫자여야 합니다.',
  textMinLength: '답변의 길이는 최소 {0}자여야 입니다.',
  textMaxLength: '답변의 길이는 {0}자를 초과 할 수 없습니다.',
  textMinMaxLength: '답변의 길이는 {0} - {1}자 사이여야 합니다.',
  minRowCountError: '최소 {0}개의 행을 채워주세요.',
  minSelectError: '최소 {0}개의 변수를 선택해주세요.',
  maxSelectError: '최대 {0}개의 변수를 선택해주세요.',
  numericMinMax: "'{0}'은 {1}보다 크거나 같고 {2}보다 작거나 같아야합니다.",
  numericMin: "'{0}'은 {1}보다 크거나 같아야합니다.",
  numericMax: "'{0}'은 {1}보다 작거나 같아야합니다.",
  invalidEmail: '올바른 이메일 주소를 입력해주세요.',
  invalidExpression: "표현식: {0}은 '참'이어야 합니다.",
  urlRequestError: "'{0}'으로 잘못된 요청입니다. {1}",
  urlGetChoicesError: '비어있는 데이터를 요청했거나 잘못된 속성의 경로입니다.',
  exceedMaxSize: '파일 크기가 {0}을 초과 할 수 없습니다.',
  otherRequiredError: '다른 질문을 작성해주세요.',
  uploadingFile: '파일을 업로드 중입니다. 잠시 후 다시 시도해주세요.',
  loadingFile: '로드 중...',
  chooseFile: '파일 선택...',
  noFileChosen: '선택된 파일이 없습니다',
  confirmDelete: '기록을 삭제하시겠습니까?',
  keyDuplicationError: ' 이 값은 고유해야합니다.',
  addColumn: '열 추가',
  addRow: '행 추가',
  removeRow: '제거',
  addPanel: '새롭게 추가',
  removePanel: '제거',
  choices_Item: '항목',
  matrix_column: '열',
  matrix_row: '행',
  savingData: '결과를 서버에 저장하는 중입니다...',
  savingDataError: '오류가 발생하여 결과를 저장할 수 없습니다.',
  savingDataSuccess: '결과가 성공적으로 저장되었습니다!',
  saveAgainButton: '다시 시도하십시오',
  timerMin: '분',
  timerSec: '초',
  timerSpentAll: '현재 페이지에서 {0}, 총 {1} 지났습니다.',
  timerSpentPage: '현재 페이지에서 {0} 지났습니다.',
  timerSpentSurvey: '페이지 답변 시간 {1} 중 {0} 지났습니다.',
  timerLimitAll: '{0}/{1}/{2}/{3}',
  timerLimitPage: '{0}/{1}',
  timerLimitSurvey: '{0}/{1}',
  cleanCaption: '파일 업로드 취소',
  clearCaption: '캡션 지우기',
  chooseFileCaption: '파일 업로드',
  removeFileCaption: '이 파일 제거',
  booleanCheckedLabel: '예',
  booleanUncheckedLabel: '아니오',
  confirmRemoveFile: '{0} 파일을 제거 하시겠습니까?',
  confirmRemoveAllFiles: '모든 파일을 제거 하시겠습니까?',
  questionTitlePatternText: '질문 제목'
};
