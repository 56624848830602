import queryAxios from '../../query/queryAxios';
import { ProductUuidResponse } from '../../rest/generated';

export interface GetSlugUrlByProductUuidRequest {
  productUuid: string;
}

export const getSlugUrlRequest = async (request: GetSlugUrlByProductUuidRequest) => {
  return await queryAxios()
    .get<ProductUuidResponse>(`products/uuid/${request.productUuid}`)
    .then(data => data.data);
};