import { ActivityCategory, CertificateDegree, LanguageSkillLevel } from '../apollo/jobs/type';

export function convertCertificateDegreeToDisplayName(certificateDegree?: CertificateDegree | string) {
  switch (certificateDegree) {
    case CertificateDegree.DOCTOR_DEGREE:
    case 'DOCTOR_DEGREE':
      return '대학원(박사)';
    case CertificateDegree.MASTER_DEGREE:
    case 'MASTER_DEGREE':
      return '대학원(석사)';
    case CertificateDegree.BACHELOR_DEGREE:
    case 'BACHELOR_DEGREE':
      return '대학교';
    case CertificateDegree.ASSOCIATE_DEGREE:
    case 'ASSOCIATTE_DEGREE':
      return '전문대학';
    case CertificateDegree.HIGH_SCHOOL:
    case 'HIGH_SCHOOL':
      return '고등학교';
    default:
      return '-';
  }
}

export function convertActivityCategoryToDisplayName(activityCategory: ActivityCategory) {
  switch (activityCategory) {
    case ActivityCategory.PRIZE:
      return '수상';
    case ActivityCategory.OTHER_EDUCATION:
      return '기타 교육';
    case ActivityCategory.CERTIFICATION:
      return '자격증';
    case ActivityCategory.JOB_EXPERIENCE:
      return '직무 관련 경험';
    default:
      return '-';
  }
}

export function convertLanguageSkillLevelToDisplayName(languageSkillLevel: LanguageSkillLevel) {
  switch (languageSkillLevel) {
    case LanguageSkillLevel.LOW:
      return '하';
    case LanguageSkillLevel.MEDIUM:
      return '중';
    case LanguageSkillLevel.HIGH:
      return '상';
    case LanguageSkillLevel.NATIVE:
      return '원어민';
    default:
      return '-';
  }
}
