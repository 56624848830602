import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { GET_PLAYLIST_GQL } from '../../apollo/course/query';

export const useController = () => {
  const params = useParams<{ id: string }>()
  const courseId = parseInt(params.id, 10);
  const history = useHistory();
  const [playlists, setPlaylists] = useState([]);
  const [name, setName] = useState('');

  const { data, error, loading } = useQuery(GET_PLAYLIST_GQL, {
    variables: { id: courseId }
  });

  useEffect(() => {
    if (
      history.location.pathname === `/course/${courseId}` ||
      history.location.pathname === `/course/${courseId}/`
    ) {
      history.replace(`/course/${courseId}/curriculum`);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (data?.course) {
      setPlaylists(data.course.playlists);
      setName(data.course.name);
    }
  }, [data]);

  return {
    loading,
    error,
    name,
    playlists
  };
};
