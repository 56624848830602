import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Skeleton, notification } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import MonacoEditor from '@monaco-editor/react';
import { CODE_PROBLEM_REFERENCE_GQL } from '../../apollo/coplit/mutation';

import type { CodeProblemReferenceData, CodeProblemReferenceVars } from '../../apollo/coplit/type';

const CodeEditorSolutionModalCardWrapper = styled.div`
  .confirm-message {
    text-align: center;
    .message {
      font-size: 17px;
      font-weight: 600;
      margin-top: 20px;
      color: #455573;
    }

    .button {
      display: inline-block;
      margin: 40px 0px 10px;
      padding: 10px 38px;
      border-radius: 6px;
      background-color: #eceef4;
      color: #455573;
      font-size: 15px;
    }
  }
`;

type ICodeEditorSolutionModalCard = {
  isSubmitted: boolean;
  codeProblemId: number;
  codeProblemLanguage: string;
};

export const CodeEditorSolutionModalCard: React.FC<ICodeEditorSolutionModalCard> = ({
  isSubmitted,
  codeProblemId,
  codeProblemLanguage
}) => {
  const [isVisible, setIsVisible] = useState(isSubmitted);
  const [referenceCode, setReferenceCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [codeProblemReference] = useMutation<CodeProblemReferenceData, CodeProblemReferenceVars>(
    CODE_PROBLEM_REFERENCE_GQL
  );

  const handleConfirm = () => {
    setLoading(true);
    setIsVisible(true);
  };
  const editorOption = {
    minimap: {
      enabled: false
    },
    fontSize: 15,
    tabSize: 2,
    scrollbar: {
      verticalScrollbarSize: 10
    },
    automaticLayout: true,
    allowEditorOverflow: false,
    readOnly: true
  };

  useEffect(() => {
    setIsVisible(isSubmitted);
  }, [isSubmitted]);

  useEffect(() => {
    const handleMutation = async () => {
      try {
        const { data } = await codeProblemReference({
          variables: { id: codeProblemId }
        });

        if (data && data.urclassCodeProblemReference) {
          setReferenceCode(data.urclassCodeProblemReference);
          setLoading(false);
        }
      } catch (error: any) {
        const isAssessment = error.graphQLErrors.find((err: any) => err.message === 'no-access-reference-code');
        if (isAssessment) {
          notification.open({
            message: '해당 문제는 시험 문제 입니다',
            description: '본 시험 문제는 시험 기간 중 레퍼런스를 제공 하지 않습니다. ',
            placement: 'bottomLeft'
          });
          setReferenceCode('해당 문제는 시험문제 이므로, 레퍼런스는 추후 제공 됩니다.');
        } else {
          setReferenceCode('레퍼런스 조회에 문제가 있습니다.');
        }
        setLoading(false);
      }
    };

    if (isVisible) {
      handleMutation();
    }
  }, [isVisible, codeProblemId]);

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <CodeEditorSolutionModalCardWrapper>
      {isVisible ? (
        <MonacoEditor height={600} options={editorOption} language={codeProblemLanguage} value={referenceCode} />
      ) : (
        <div className="confirm-message">
          <div className="message">아직 코드를 제출하지 않았습니다. 그래도 확인하시겠습니까?</div>
          <a className="button" onClick={handleConfirm}>
            확인하기
          </a>
        </div>
      )}
    </CodeEditorSolutionModalCardWrapper>
  );
};
