import React from 'react';
import styled from '@emotion/styled';

import Grid from '../../atoms/Grid';
import { OptionContainer } from '../../atoms/Container';
import { PaymentType } from '../../../apollo/product/type';
import { PayMethodType } from '../../../pages/apply/type';
import { OptionTitle } from './style';

import type { PaymentModel } from '../../../apollo/product/type';

type PaymentMethodOptionsProps = {
  selectedPaymentModel?: PaymentModel;
  payMethod: PayMethodType;
  setPayMethod: Function;
};

const PaymentMethodOptionsWrapper = styled.div`
  margin-bottom: 150px;
`;

const PaymentMethodOptions: React.FC<PaymentMethodOptionsProps> = ({
  selectedPaymentModel,
  setPayMethod,
  payMethod
}) => {
  return selectedPaymentModel && selectedPaymentModel.paymentType === PaymentType.UPFRONT ? (
    <PaymentMethodOptionsWrapper>
      <OptionTitle>결제 수단</OptionTitle>
      <Grid itemWidth={250}>
        <OptionContainer
          className={payMethod === PayMethodType.CARD ? 'selected ' : ''}
          onClick={() => {
            setPayMethod(PayMethodType.CARD);
          }}
        >
          신용카드
        </OptionContainer>
        <OptionContainer
          className={payMethod === PayMethodType.VBANK ? ' selected ' : ''}
          onClick={() => {
            setPayMethod(PayMethodType.VBANK);
          }}
        >
          계좌 이체
        </OptionContainer>
      </Grid>
    </PaymentMethodOptionsWrapper>
  ) : (
    <div />
  );
};

export default PaymentMethodOptions;
