import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';

import {
  DA_PROGRAM_ID,
  DS_PROGRAM_ID,
  GMB_PROGRAM_ID,
  PMB_PROGRAM_ID,
  SEB_PROGRAM_ID,
  TAJAN_SCHOOL_PROGRAM_ID
} from '../controller';

import { Typography, TypographyType } from '../../../components/atoms/Typography';
import { Button } from '../../../components/molecules/Button';
import { SizeType, StatusType } from '../../../components/molecules/Button/type';
import { Checkbox } from '../../../components/molecules/Input/Checkbox';
import { PaymentType } from '../../../apollo/product/type';

import { PayMethodType } from '../type';
import { mapPaymentTypeToText } from '../../../util/textMapper/mapPaymentTypeToText';
import { numberFormatter } from '../../../util';

import { ColorPalette, ColorPaletteType } from '../../../styles/color';

import type { PaymentModel } from '../../../apollo/product/type';

const PaymentFormWrapper = styled.div`
  margin: auto;
  margin-top: 80px;

  width: 310px;

  padding: 47px 22px 22px;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${ColorPalette.Gray4};
  background-color: ${ColorPalette.White};

  .payment-form__title {
    text-align: center;

    .payment-form__title-product-name {
      line-height: 1.3;
      font-weight: 700;

      margin-bottom: 8px;
    }

    .payment-form__title-service-date {
      margin-bottom: 28px;
    }
  }

  .payment-form__payment-type-select {
    .payment-type-select {
      .payment-type-select__title {
        margin: 4px 14px 7px 0px;
      }
    }

    .payment-info {
      margin: 4px 0px 4px 0px;

      .payment-info__label {
        display: inline-block;

        margin: 0px 14px 0px 14px;
      }

      .payment-info__value {
        display: inline-block;

        margin: 0px 14px 0px 14px;
        vertical-align: middle;
        float: right;
      }
    }

    hr {
      margin: 13.5px 0px 13.5px 0px;
      color: ${ColorPalette.Gray4};
    }

    .pay-method-select {
      .pay-method-select__title {
        margin: 4px 14px 7px 14px;
      }

      .pay-method-select__checkbox {
        line-height: 1.3;
        margin: 4px 14px 6px 14px;
      }
    }

    .wewin-info__container {
      margin: 37px 0px 37px 0px;

      .wewin-info__text {
        margin: 0px 14px 0px 14px;
      }

      .emphasis {
        font-weight: 700;
      }

      /* https://stackoverflow.com/questions/1409649/how-to-change-the-height-of-a-br  */
      br {
        display: block;
        content: '';
        margin-top: 10px;
      }
    }
  }

  .payment-form__submit {
    margin-top: 3px;
  }
`;

const PaymentTypeSelectCardContainer = styled.div`
  width: 264px;

  display: grid;

  grid-template-columns: 130px 130px;
  column-gap: 2px;
  row-gap: 3px;

  justify-content: space-between;

  .payment-type-select__card {
    width: 130px;

    padding: 15px 14px 15px 14px;

    box-sizing: border-box;
    border-radius: 5px;

    cursor: pointer;

    .payment-type-select__card--label {
      margin-bottom: 14px;

      word-break: keep-all;
    }

    .payment-type-select__card--value {
      line-height: 1.3;
      font-weight: 700;
    }

    .payment-type-select__card--desc {
    }

    box-shadow: 0 0 0 1px ${ColorPalette.Gray4} inset;

    &.selected {
      box-shadow: 0 0 0 2px ${ColorPalette.LightBlue} inset;
    }
  }
`;

const getProgramISAInfo = (programId: number) => {
  switch (programId) {
    // SEB
    case SEB_PROGRAM_ID:
      return {
        sharingRate: 17,
        minSalary: 3000,
        months: 24
      };

    // GMB
    case GMB_PROGRAM_ID:
      return {
        sharingRate: 12,
        minSalary: 2800,
        months: 18
      };

    // AIB - DS
    // 1기 한정 : 12% | 18회 | 3000만원
    case DS_PROGRAM_ID:
      return {
        sharingRate: 20,
        minSalary: 3000,
        months: 24
      };

    // PMB
    case PMB_PROGRAM_ID:
      return {
        sharingRate: 14,
        minSalary: 3000,
        months: 18
      };

    case TAJAN_SCHOOL_PROGRAM_ID:
      return {
        sharingRate: 18,
        months: 11,
        minSalary: 180,
        salaryStandard: 'month'
      };
    // DA
    case DA_PROGRAM_ID:
    default:
      return {
        sharingRate: 12,
        minSalary: 3000,
        months: 18
      };
  }
};

type PaymentFormProps = {
  loading: boolean;
  programId: number;
  productName: string;
  hideDate?: boolean;
  productServiceStartDate: Date;
  productServiceEndDate: Date;
  paymentModels: PaymentModel[];
  selectedPaymentModel?: PaymentModel;
  handleChangeSelectedPaymentModel: (paymentModel: PaymentModel) => void;
  selectedPayMethod: PayMethodType | undefined;
  handleChangePayMethod: (payMethod: PayMethodType) => void;
  handlePaymentFormSubmit: () => void;
};

const PaymentForm: React.FC<PaymentFormProps> = ({
  loading,
  programId,
  productName,
  productServiceStartDate,
  productServiceEndDate,

  paymentModels,

  selectedPaymentModel,
  handleChangeSelectedPaymentModel,

  selectedPayMethod,
  handleChangePayMethod,

  handlePaymentFormSubmit,

  hideDate = false
}) => {
  const { months, minSalary, sharingRate, salaryStandard } = getProgramISAInfo(programId);

  return (
    <PaymentFormWrapper>
      <div className="payment-form__title">
        <Typography
          className="payment-form__title-product-name"
          type={TypographyType.H3}
          color={ColorPaletteType.GRAY1}
          text={productName}
        />
        {!hideDate && <Typography
          className='payment-form__title-service-date'
          type={TypographyType.B1}
          color={ColorPaletteType.GRAY2}
          text={`${moment(productServiceStartDate).format('YYYY.MM.DD')} ~ ${moment(productServiceEndDate).format(
            'YYYY.MM.DD'
          )}`}
        />}
      </div>

      <div className="payment-form__payment-type-select">
        <div className="payment-type-select">
          <Typography
            className="payment-type-select__title"
            type={TypographyType.B2}
            color={ColorPaletteType.GRAY2}
            text="결제 모델 선택"
          />

          <PaymentTypeSelectCardContainer>
            {selectedPaymentModel &&
              paymentModels.map(paymentModel => {
                const isSelected = paymentModel.id === selectedPaymentModel.id;

                return (
                  <div
                    className={`payment-type-select__card ${isSelected ? 'selected' : ''}`}
                    key={paymentModel.id}
                    onClick={handleChangeSelectedPaymentModel.bind(null, paymentModel)}
                  >
                    <Typography
                      className="payment-type-select__card--label"
                      type={TypographyType.B2}
                      color={ColorPaletteType.GRAY2}
                      text={`${mapPaymentTypeToText(paymentModel.paymentType)}`}
                    />
                    <Typography
                      className="payment-type-select__card--value"
                      type={TypographyType.B1}
                      color={ColorPaletteType.GRAY1}
                      text={`${numberFormatter(paymentModel.price)}원`}
                    />
                    <Typography
                      className="payment-type-select__card--desc"
                      type={TypographyType.B1}
                      color={ColorPaletteType.GRAY1}
                      text="선결제"
                    />
                  </div>
                );
              })}
          </PaymentTypeSelectCardContainer>
        </div>

        <hr />

        {selectedPaymentModel?.paymentType === PaymentType.UPFRONT ||
        selectedPaymentModel?.paymentType === PaymentType.HYBRID ? (
          <>
            {/* 3-1 상품금액 타이틀  + 가격 */}
            <div className="payment-info">
              <Typography
                className="payment-info__label"
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY1}
                text="총 상품 금액"
              />
              <Typography
                className="payment-info__value"
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY1}
                text={`${numberFormatter(selectedPaymentModel.originPrice)}원`}
              />
            </div>

            {/* 3-1 할인 금액 타이틀 + 가격 */}
            <div className="payment-info">
              <Typography
                className="payment-info__label"
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY1}
                text="할인 금액"
              />
              <Typography
                className="payment-info__value"
                type={TypographyType.B2}
                color={ColorPaletteType.RED}
                text={`- ${numberFormatter(selectedPaymentModel.originPrice - selectedPaymentModel.price)}원`}
              />
            </div>

            <hr />
            {/* 3-3 결제 방식 선택 */}
            <div className="pay-method-select">
              <Typography
                className="pay-method-select__title"
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY2}
                text="결제 방식 선택"
              />

              <Checkbox
                className="pay-method-select__checkbox"
                checked={PayMethodType.CARD === selectedPayMethod}
                onChange={handleChangePayMethod.bind(null, PayMethodType.CARD)}
                children={
                  <Typography
                    className="pay-method-select__checkbox-label"
                    type={TypographyType.B2}
                    color={ColorPaletteType.GRAY1}
                    text="신용카드 결제"
                  />
                }
              />
              <Checkbox
                className="pay-method-select__checkbox"
                checked={PayMethodType.VBANK === selectedPayMethod}
                onChange={handleChangePayMethod.bind(null, PayMethodType.VBANK)}
                children={
                  <Typography
                    className="pay-method-select__checkbox-label"
                    type={TypographyType.B2}
                    color={ColorPaletteType.GRAY1}
                    text="계좌이체"
                  />
                }
              />
            </div>
          </>
        ) : (
          <div className="wewin-info__container">
            <Typography
              className="wewin-info__text emphasis"
              type={TypographyType.B1}
              color={ColorPaletteType.GRAY1}
              text="취업 전까지 학생이 부담하는"
            />
            <Typography
              className="wewin-info__text emphasis"
              type={TypographyType.B1}
              color={ColorPaletteType.GRAY1}
              text="비용이 없는 결제 모델입니다."
            />

            <br />

            <Typography
              className="wewin-info__text"
              type={TypographyType.B2}
              color={ColorPaletteType.GRAY1}
              text={`${salaryStandard === 'month' ? '월' : ''} 소득 ${numberFormatter(minSalary)}만원 이상 취업 시,`}
            />
            <Typography
              className="wewin-info__text"
              type={TypographyType.B2}
              color={ColorPaletteType.GRAY1}
              text={`코드스테이츠와 월 소득 ${sharingRate}%를 ${
                selectedPaymentModel?.paymentType === PaymentType.IMPACT_CAMPUS ? '15' : months
              }회 공유`}
            />
          </div>
        )}

        <hr />
        <Button
          className="payment-form__submit"
          status={StatusType.ACTIVE}
          size={SizeType.BASIC}
          disabled={loading}
          children={
            <Typography
              text={selectedPaymentModel?.paymentType === PaymentType.UPFRONT ? '바로 결제하기' : '지원하러 가기'}
              type={TypographyType.B1}
              color={ColorPaletteType.WHITE}
            />
          }
          onClick={handlePaymentFormSubmit}
        />
      </div>
    </PaymentFormWrapper>
  );
};

export default PaymentForm;
