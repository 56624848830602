import React from 'react';
import styled from '@emotion/styled';
import { ColorPalette } from '../../../../../styles/color';
import { useHistory } from 'react-router-dom';
const NoContentContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .wrap__noti {
    width: 324px;
    padding: 76px 0 65px;
    border-radius: 44px;
    box-shadow: 5px 5px 28px rgba(0, 0, 0, 0.05);
    border: solid 1px ${ColorPalette.BluishGray1};
    background-color: ${ColorPalette.White};
    text-align: center;

    .txt__wrapper {
      display: block;
      margin: 33px 0 51px;
      font-size: 20px;
      font-weight: 700;
      color: #455573;
    }
    .btn__wrapper {
      width: 224px;
      height: 48px;
      border-radius: 6px;
      background-color: #452cdd;
      border: none;
      color: #fff;
      cursor: pointer;
      .txt__btn {
        vertical-align: middle;
      }
    }
  }
  .img__noti {
    display: block;
    width: 84px;
    margin: 51px auto 0;
  }
`;
const NoContent = () => {
  const history = useHistory();
  const handleClick = () => {
    history.goBack();
  };

  return (
    <NoContentContainer>
      <div className="wrap__noti">
        <img
          className="img__wrapper"
          alt="콘텐츠 없음"
          src="https://codestates-urclass.s3.ap-northeast-2.amazonaws.com/common/img_exclamation.svg"
        />
        <strong className="txt__wrapper">콘텐츠가 비어 있습니다.</strong>
        <button className="btn__wrapper" onClick={handleClick}>
          <img
            className="ico__btn"
            alt="버튼 아이콘"
            src="https://codestates-urclass.s3.ap-northeast-2.amazonaws.com/common/Icon.svg"
          />
          <span className="txt__btn">목록으로 돌아가기</span>
        </button>
      </div>
      <img
        className="img__noti"
        alt="콘텐츠 없음"
        src="https://codestates-urclass.s3.ap-northeast-2.amazonaws.com/common/logo.svg"
      />
    </NoContentContainer>
  );
};

export default NoContent;
