import React from 'react';
import styled from '@emotion/styled';
import { Redirect } from 'react-router-dom';
import { Modal, Spin, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { GeneralTemplate } from '../../../templates';
import { useController } from './controller';

import JobsProfile from './components/JobsProfile';
import JobsResume from './components/JobsResume';

import ErrorPage from '../../../components/ErrorPage';
import JobProfileForm, { MAX_FORM_MODAL_PAGE } from '../common/components/JobProfileForm';

const JobsPageWrapper = styled.div`
  margin-bottom: 40px;

  .jobs-container {
    display: flex;

    @media screen and (max-width: 992px) {
      display: block;
    }

    .section-profile {
      flex: 1;
      margin-right: 15px;

      @media screen and (max-width: 992px) {
        margin-right: 0px;
      }
    }

    .section-resume {
      flex: 2;
      margin-left: 15px;

      @media screen and (max-width: 992px) {
        margin-left: 0px;
      }
    }
  }
`;

const JobsPage: React.FC = () => {
  const controller = useController();

  const handleDeleteResumeWithConfirm = (resumeId: number) => {
    Modal.confirm({
      title: '해당 이력서를 삭제 하시겠습니까?',
      content: '삭제된 이력서는 복구되지 않습니다.',
      okText: '삭제',
      okType: 'danger',
      cancelText: '취소',
      onOk() {
        controller.deleteResume(resumeId);
      }
    });
  };

  const handleSetDefaultResume = (resumeId: number, hasUploadedResume: boolean) => {
    const modalStyle: React.CSSProperties = {
      fontSize: '14px',
      fontWeight: 600
    };

    hasUploadedResume
      ? Modal.confirm({
          title: <span style={modalStyle}>업로드한 이력서를 기본 이력서로 지정하시겠습니까?</span>,
          content: '기본 이력서 지정은 이후에도 가능합니다.',
          okText: '예',
          cancelText: '아니오',
          onOk: () => {
            controller.publishResume(resumeId);
          },
          icon: <QuestionCircleOutlined />
        })
      : Modal.confirm({
          title: '해당 이력서를 기본 이력서로 등록 하시겠습니까?',
          content: '기본 이력서는 파트너사에 공유되는 이력서 입니다.',
          okText: '등록',
          okType: 'primary',
          cancelText: '취소',
          onOk() {
            controller.publishResume(resumeId);
          }
        });
  };

  if (controller.resumeError) {
    if (controller.resumeError.message.indexOf('no-jobProfile') === -1) {
      return <ErrorPage />;
    }
  }

  if (!controller.profile) {
    return <Redirect to="/jobs/new" />;
  }

  return (
    <GeneralTemplate error={!!(controller.resumeError || controller.profileError)} loading={controller.loading}>
      {{
        body: (
          <JobsPageWrapper>
            <div className="jobs-container">
              {controller.loading ? (
                <Spin />
              ) : (
                <JobsProfile profile={controller.profile} handleEdit={controller.openJobProfileModal} />
              )}
              <JobsResume
                options={controller.options}
                isUploading={controller.isUploading}
                selectedOptions={controller.selectedOptions}
                resumes={controller.resumes}
                setIsUploading={controller.setIsUploading}
                setSelectedOptions={controller.setSelectedOptions}
                hasUploadedResume={controller.hasUploadedResume}
                handleDeleteResume={handleDeleteResumeWithConfirm}
                handleSetDefaultResume={handleSetDefaultResume}
                handleResumeFileUpload={controller.resumeFileUpload}
                handleChangeUploadStatus={controller.changeUploadStatus}
              />
            </div>

            <Modal
              title={
                <Typography.Text strong style={{ fontSize: 20 }}>
                  {
                    [
                      `채용 필수 정보 입력(${controller.page}/${MAX_FORM_MODAL_PAGE})`,
                      `채용 상세 정보 입력(${controller.page}/${MAX_FORM_MODAL_PAGE})`,
                      `관심 분야 입력(${controller.page}/${MAX_FORM_MODAL_PAGE})`
                    ][controller.page - 1]
                  }
                </Typography.Text>
              }
              visible={controller.jobProfileModalVisible}
              onCancel={controller.closeJobProfileModal}
              width="80vw"
              footer={false}
            >
              <JobProfileForm edit page={controller.page} jobProfileFormModel={controller.jobProfileFormModel} />
            </Modal>
          </JobsPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default JobsPage;
