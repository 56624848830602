import React, { useEffect, useReducer } from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import { Modal } from 'antd';
import styled from '@emotion/styled';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';

import { useCredential } from '../../contexts/credential';

import { GeneralTemplate } from '../../templates';
import { Typography, TypographyType } from '../../components/atoms/Typography';
import { Button } from '../../components/molecules/Button';
import { SizeType, StatusType } from '../../components/molecules/Button/type';
import { GeneralHeader } from '../../components/organisms/Header';
import Table from '../../components/molecules/Table';

import { UPDATE_USER_PROFILE_GQL } from '../../apollo/user/mutation';

import { getServerError } from '../../util/errors/serverErrors';
import { isMobile } from '../../util/checkDevice';
import { ColorPaletteType } from '../../styles/color';

import type { UpdateUserProfileData, UpdateUserProfileVars } from '../../apollo/user/type';

// TODO : cohort common domain으로 수정
import { MY_COHORTS_GQL } from '../../domain/lms/apollo/course/query';
import type { MyCohortsData } from '../../domain/lms/apollo/course/type';

import { BirthdayForm } from './components/BirthdayForm';
import { AttendanceDurationForm } from './components/AttendanceDurationForm';
import { formatDateStream } from '../../util/date';
import { makeToast } from '../../util/makeToast';
import { CertificateType } from '../../apollo/certificate/type';
import { GET_CERTIFICATIES_GQL } from '../../domain/lms/apollo/attendance/query';
import { MyIssuableCertificateData } from '../../domain/lms/apollo/attendance/type';

const StyledCertificatesPage = styled.div`
  padding: 70px 0;

  .page-title {
  }

  .certificates-table {
    border-radius: 6px;
    width: 100%;
    margin-top: 22px;
    color: #455573;
    thead {
      tr {
        border: 1px solid #dfe4ec;
        th {
          background-color: #f5f7f9;

          font-weight: 700;
        }
      }
    }
    tbody {
      tr {
        border: 1px solid #dfe4ec;
        font-weight: 400;
        .btn__certificate {
          background-color: #295ce0;
          border-radius: 7px;
        }
      }
    }
  }
`;

const StyledDeviceCheckModal = styled.div`
  padding-top: 30px;

  .message {
    text-align: center;
  }

  .btn {
    background-color: #295ce0;
    width: 100%;
    margin-top: 22px;
  }
`;

enum CertType {
  EDU_CERT = 'eduCert',
  ATTENDANCE_URCLASS_CERT = 'attendanceUrclassCert',
  ATTENDANCE_HRD_CERT = 'attendanceHrdCert'
}

type Certificate = {
  cohortId: number;
  cohortUuid: string;
  certType: CertType;
  userName: string;
  birthday?: string;
  courseName?: string;
  eduStartDate?: string;
  eduEndDate?: string;
};

type State = {
  cohortId: number;
  isMobile: boolean;
  isBirthdayFormModalVisible: boolean;
  birthdayInputValue: string;
  isBirthdayValid: boolean;
  isBirthdayValidMessageVisible: boolean;
  certDownloadURL: string;
  certificates: Certificate[];
  certificateType: CertificateType;
  isAttendanceDurationFormModalVisible: boolean;
  attendanceStartDate: string;
  attendanceEndDate: string;
};

type Action =
  | {
      type: 'SET_CERTIFICATES';
      payload: {
        certificates: Certificate[];
      };
    }
  | {
      type: 'OPEN_BIRTHDAY_FORM_MODAL';
      payload: {
        certDownloadURL: string;
      };
    }
  | {
      type: 'CLOSE_BIRTHDAY_FORM_MODAL';
    }
  | {
      type: 'CHANGE_BIRTHDAY_INPUT_VALUE';
      payload: {
        isValid: boolean;
        value: string;
      };
    }
  | {
      type: 'CHANGE_ATTENDANCE_DURATION';
      payload: {
        startDateValue?: string;
        endDateValue?: string;
      };
    }
  | {
      type: 'OPEN_ATTENDANCE_DURATION_MODAL';
      payload: { certDownloadURL: string; cohortId: number; certificateType: CertificateType };
    }
  | { type: 'CLOSE_ATTENDANCE_DURATION_MODAL' };

const getCertificateName = (certType: CertType) => {
  switch (certType) {
    case CertType.ATTENDANCE_URCLASS_CERT:
      return '출석 확인서(URCLASS)';
    case CertType.ATTENDANCE_HRD_CERT:
      return '출석 확인서(HRD_NET)';
    case CertType.EDU_CERT:
      return '수강 증명서';
    default:
      return '';
  }
};

const initialState: State = {
  isMobile: isMobile(),
  isBirthdayFormModalVisible: false,
  birthdayInputValue: '',
  isBirthdayValid: false,
  isBirthdayValidMessageVisible: false,
  certDownloadURL: '',
  certificates: [],
  isAttendanceDurationFormModalVisible: false,
  attendanceStartDate: '',
  attendanceEndDate: '',
  cohortId: 0,
  certificateType: CertificateType.URCLASS
};

const reducer: React.Reducer<State, Action> = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CERTIFICATES': {
      return {
        ...state,
        certificates: action.payload.certificates
      };
    }
    case 'OPEN_BIRTHDAY_FORM_MODAL': {
      return {
        ...state,
        isBirthdayFormModalVisible: true,
        certDownloadURL: action.payload.certDownloadURL
      };
    }
    case 'CLOSE_BIRTHDAY_FORM_MODAL': {
      return {
        ...state,
        isBirthdayFormModalVisible: false
      };
    }
    case 'CHANGE_BIRTHDAY_INPUT_VALUE': {
      return {
        ...state,
        isBirthdayValid: action.payload.isValid,
        isBirthdayValidMessageVisible: !!(action.payload.value && !action.payload.isValid),
        birthdayInputValue: action.payload.value
      };
    }

    case 'CHANGE_ATTENDANCE_DURATION': {
      return {
        ...state,
        attendanceStartDate: action.payload.startDateValue ?? state.attendanceStartDate,
        attendanceEndDate: action.payload.endDateValue ?? state.attendanceEndDate
      };
    }

    case 'OPEN_ATTENDANCE_DURATION_MODAL':
      return {
        ...state,
        isAttendanceDurationFormModalVisible: true,
        certDownloadURL: action.payload.certDownloadURL,
        cohortId: action.payload.cohortId,
        certificateType: action.payload.certificateType
      };

    case 'CLOSE_ATTENDANCE_DURATION_MODAL':
      return {
        ...state,
        isAttendanceDurationFormModalVisible: false,
        attendanceStartDate: '',
        attendanceEndDate: '',
        certDownloadURL: ''
      };

    default: {
      return state;
    }
  }
};

const CertificatesPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { profile, loadingProfile } = useCredential();
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const { data: cohortsData, loading: cohortsLoading, error: cohortsError } = useQuery<MyCohortsData>(MY_COHORTS_GQL, {
    fetchPolicy: 'network-only'
  });

  const [getCertificateData, { data: certificateData }] = useLazyQuery<MyIssuableCertificateData>(
    GET_CERTIFICATIES_GQL,
    {
      variables: {
        bootcampId: cohortsData?.urclassMyCohorts[0]?.id ? cohortsData?.urclassMyCohorts[0]?.id : 0
      }
    }
  );
  const [updateUserProfile] = useMutation<UpdateUserProfileData, UpdateUserProfileVars>(UPDATE_USER_PROFILE_GQL);

  // 개강반 조회 완료시 증명서 목록 상태 업데이트
  useEffect(() => {
    if (cohortsData && profile) {
      // certIssuable 상태의 개강반만 필터링 후
      // Certificate 타입으로 매핑하여 상태 업데이트
      //
      const certificates: Certificate[] = [];
      getCertificateData();
      cohortsData.urclassMyCohorts.forEach(cohort => {
        // 수강 증명서 발급
        if (cohort.certIssuable) {
          certificates.push({
            cohortId: cohort.id,
            cohortUuid: cohort.uuid,
            certType: CertType.EDU_CERT,
            userName: profile?.name ?? '',
            birthday: profile?.birthday ?? '-',
            courseName: cohort.certCourseName,
            eduStartDate: moment(cohort.certEduStartDate).format('YYYY.MM.DD'),
            eduEndDate: moment(cohort.certEduEndDate).format('YYYY.MM.DD')
          });
        }
        // 출석 확인서 발급(urclass)
        if (certificateData) {
          certificateData.urclassMyIssuableCertificates.forEach(cert => {
            certificates.push({
              cohortId: cohort.id,
              cohortUuid: cohort.uuid,
              certType: cert.name.includes('urclass') ? CertType.ATTENDANCE_URCLASS_CERT : CertType.ATTENDANCE_HRD_CERT,
              userName: profile?.name ?? '-',
              birthday: '(해당 없음)',
              courseName: cohort.name
            });
          });
        }
      });

      dispatch({
        type: 'SET_CERTIFICATES',
        payload: {
          certificates
        }
      });
    }
  }, [cohortsData, profile, certificateData]);

  // 개강반 조회 에러 처리
  useEffect(() => {
    if (cohortsError) {
      const error = getServerError(cohortsError.message);
      makeToast({ type: 'error', content: error.message });
    }
  }, [cohortsError]);

  // 마이페이지로 리다이렉트
  const handleClickConfirmRedirectToMyPage = () => {
    history.push('/mypage');
  };

  // 발급 버튼 클릭 처리 핸들러
  const handleClickIssueCert = (cert: Certificate) => {
    const certDownloadURL = `/mypage/certificates/download?cohort_uuid=${cert.cohortUuid}&cert_type=${cert.certType}`;

    if (cert.certType === CertType.EDU_CERT) {
      // 프로필에 생년월일이 등록되지 않은 경우
      if (!profile?.birthday) {
        // 생년월일 입력 받기 위해 모달 오픈
        // 완료 후 다운로드 페이지로 이동하기 위해 URL 저장
        dispatch({
          type: 'OPEN_BIRTHDAY_FORM_MODAL',
          payload: {
            certDownloadURL
          }
        });
        return;
      }

      // 증명서 발급 페이지로 리다이렉트
      history.push(certDownloadURL);
    } else if (cert.certType === CertType.ATTENDANCE_URCLASS_CERT) {
      dispatch({
        type: 'OPEN_ATTENDANCE_DURATION_MODAL',
        payload: { certDownloadURL, cohortId: cert.cohortId, certificateType: CertificateType.URCLASS }
      });
    } else if (cert.certType === CertType.ATTENDANCE_HRD_CERT) {
      dispatch({
        type: 'OPEN_ATTENDANCE_DURATION_MODAL',
        payload: { certDownloadURL, cohortId: cert.cohortId, certificateType: CertificateType.HRD_NET }
      });
    }
  };
  const closeIssueCert = () => {
    dispatch({ type: 'CLOSE_ATTENDANCE_DURATION_MODAL' });
  };

  const handleCancelBirthdayFormModal = () => {
    dispatch({
      type: 'CLOSE_BIRTHDAY_FORM_MODAL'
    });
  };

  const handleClickConfirmBirthdayForm = async () => {
    if (!state.isBirthdayValid) {
      makeToast({ type: 'error', content: '생년월일을 올바르게 입력해주세요.' });
      return;
    }

    try {
      await updateUserProfile({
        variables: {
          input: {
            birthday: state.birthdayInputValue
          }
        }
      });

      // 생년월일 업데이트 완료시 다운로드 페이지로 이동
      history.push(state.certDownloadURL);
    } catch (err) {
      const error = getServerError(err.message);
      makeToast({ type: 'error', content: error.message });
    }
  };

  const handleChangeBirthdayInput = (input: string) => {
    // 생년월일 입력값에서 숫자만 필터링
    const filtered = input.replace(/[^0-9]/g, '');

    // 8자리 모두 입력되었으면 추가 입력 무시
    if (filtered.length > 8) {
      return;
    }

    // 생년월일 8자리 입력되었는지 확인
    const isValid = !!filtered && filtered.length === 8;

    // 숫자로 이루어진 생년월일 문자열을 "."으로 연,월,일 분리
    const formattedBirthday = formatDateStream(input);
    dispatch({
      type: 'CHANGE_BIRTHDAY_INPUT_VALUE',
      payload: {
        value: formattedBirthday,
        isValid: isValid
      }
    });
  };

  const handleAttendanceStartDateChange = (input: string) => {
    const filtered = input.replace(/[^0-9]/g, '');

    // 8자리 모두 입력되었으면 추가 입력 무시
    if (filtered.length > 8) {
      return;
    }

    dispatch({
      type: 'CHANGE_ATTENDANCE_DURATION',
      payload: {
        startDateValue: formatDateStream(filtered)
      }
    });
  };

  const handleAttendanceEndDateChange = (input: string) => {
    const filtered = input.replace(/[^0-9]/g, '');

    // 8자리 모두 입력되었으면 추가 입력 무시
    if (filtered.length > 8) {
      return;
    }
    dispatch({
      type: 'CHANGE_ATTENDANCE_DURATION',
      payload: {
        endDateValue: formatDateStream(filtered)
      }
    });
  };

  const handleConfirmAttendanceDuration = () => {
    history.push(
      `${state.certDownloadURL}&cert_start_date=${state.attendanceStartDate.replaceAll(
        '.',
        ''
      )}&cert_end_date=${state.attendanceEndDate.replaceAll('.', '')}&cohort_id=${state.cohortId}`
    );
  };
  const isDateAfterJuly = (dateString: string, type: CertificateType) => {
    if (dateString.length > 9) {
      if (type === CertificateType.HRD_NET) {
        //hrd-net일 경우 7월이전이더라도 출석부 생성 가능
        return true;
      } else {
        return moment(dateString, 'YYYY-MM-DD').isAfter('2022-06-30');
      }
    } else if (dateString.length === 0) {
      return true;
    } else {
      return true;
    }
  };

  const isToday = (date: string) => {
    const today = moment().format('YYYY-MM-DD');
    return moment(date, 'YYYY-MM-DD').isAfter(moment(today).subtract(1, 'day'));
  };

  const isDayAfterToday = (date: string) => {
    const today = moment().format('YYYY-MM-DD');
    return moment(date, 'YYYY-MM-DD').isAfter(moment(today));
  };

  const isAttendanceDateValid = () => {
    const dayDiff = moment(state.attendanceEndDate, 'YYYY-MM-DD').diff(
      moment(state.attendanceStartDate, 'YYYY-MM-DD'),
      'days'
    );
    //입퇴실째깍이가 22.07.25 배포
    return (
      state.attendanceStartDate.length === 10 &&
      state.attendanceEndDate.length === 10 &&
      dayDiff < 63 &&
      dayDiff > 0 &&
      isDateAfterJuly(state.attendanceStartDate, state.certificateType) &&
      isDateAfterJuly(state.attendanceEndDate, state.certificateType) &&
      !isDayAfterToday(state.attendanceEndDate) &&
      !isToday(state.attendanceStartDate)
    );
  };

  return (
    <>
      <GeneralTemplate>
        {{
          loading: loadingProfile || !profile || cohortsLoading,
          error: cohortsError,
          header: <GeneralHeader />,
          footer: state.isMobile && <></>,
          body: state.isMobile ? (
            <Modal centered={true} footer={false} visible={state.isMobile} closable={false}>
              <StyledDeviceCheckModal>
                <Typography
                  className="message"
                  type={TypographyType.B1}
                  color={ColorPaletteType.GRAY2}
                  text="증명서는 PC 환경에서만 발급 가능합니다."
                />
                <Typography
                  className="message"
                  type={TypographyType.B1}
                  color={ColorPaletteType.GRAY2}
                  text="PC에서 다시 시도해주세요!"
                />
                <Button
                  className="btn"
                  size={SizeType.BASIC}
                  status={StatusType.ACTIVE}
                  children={<Typography type={TypographyType.B1} color={ColorPaletteType.WHITE} text={'확인'} />}
                  onClick={handleClickConfirmRedirectToMyPage}
                />
              </StyledDeviceCheckModal>
            </Modal>
          ) : (
            <StyledCertificatesPage>
              <Typography
                className="page-title"
                type={TypographyType.H1}
                color={ColorPaletteType.GRAY1}
                text={'증명서 발급'}
              />

              <Table
                className="certificates-table"
                columns={['증명서 유형', '이름', '생년월일', '과정명', '수강기간', '처리 상태']}
                rows={state.certificates.map(cert => {
                  return [
                    getCertificateName(cert.certType),
                    cert.userName,
                    cert.birthday,
                    cert.courseName,
                    cert.eduStartDate && cert.eduEndDate ? `${cert.eduStartDate} ~ ${cert.eduEndDate}` : '-',
                    <Button
                      size={SizeType.SMALL}
                      status={StatusType.ACTIVE}
                      className="btn__certificate"
                      children={<Typography type={TypographyType.B1} color={ColorPaletteType.WHITE} text={'발급'} />}
                      onClick={() => handleClickIssueCert(cert)}
                    />
                  ];
                })}
              />
              <Modal
                className="modal-birthday"
                centered={true}
                footer={false}
                visible={state.isBirthdayFormModalVisible}
                width={340}
                onCancel={handleCancelBirthdayFormModal}
              >
                <BirthdayForm
                  isBirthdayValid={state.isBirthdayValid}
                  birthdayInputValue={state.birthdayInputValue}
                  onBirthdayInputChange={handleChangeBirthdayInput}
                  onBirthdayFormSubmit={handleClickConfirmBirthdayForm}
                />
              </Modal>
              <Modal
                className="modal-certificate"
                visible={state.isAttendanceDurationFormModalVisible}
                centered={true}
                footer={false}
                width={450}
                style={{ padding: '17px' }}
                onCancel={closeIssueCert}
              >
                <AttendanceDurationForm
                  startDate={state.attendanceStartDate}
                  endDate={state.attendanceEndDate}
                  isValid={isAttendanceDateValid}
                  isDateAfterJuly={isDateAfterJuly}
                  isToday={isToday}
                  isDayAfterToday={isDayAfterToday}
                  certificateType={state.certificateType}
                  onStartDateChange={val => {
                    handleAttendanceStartDateChange(val);
                  }}
                  onEndDateChange={val => {
                    handleAttendanceEndDateChange(val);
                  }}
                  onSubmitAttendanceDuration={handleConfirmAttendanceDuration}
                />
              </Modal>
            </StyledCertificatesPage>
          )
        }}
      </GeneralTemplate>
    </>
  );
};

export default withRouter(CertificatesPage);
