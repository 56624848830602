import styled from '@emotion/styled';

import { ColorPaletteType } from '../../../styles/color';
import { Typography, TypographyType } from '../../../components/atoms/Typography';
import { SizeType, StatusType } from '../../../components/molecules/Button/type';
import { Button } from '../../../components/molecules/Button';
import { ReactComponent as CalendarIco } from '../../../assets/svg/ico_calendar.svg';
import { CertificateType } from '../../../apollo/certificate/type';
import moment from 'moment';

const StyledAttendanceDurationForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-bottom: 32px;
  }
  .description {
    margin-bottom: 24px;
  }

  .duration {
    align-items: center;
    margin-bottom: 10px;
    .wrap__duration {
      color: #8c9cba;
      display: inline-block;
      .txt__duration {
        font-weight: 400;
        font-size: 12px;
        display: block;
        margin-bottom: 6px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      .wrap__duration--input {
        text-align: left;
        border: 1px solid #dfe3ec;
        border-radius: 8px;
        width: 150px;
        height: 44px;
        padding: 8px 16px;
        &:focus-within {
          border: 1px solid #b8c9f5;
        }

        .img__duration {
          vertical-align: middle;
          margin-right: 12px;
        }

        .input__duration--start {
          width: 80px;
          border: none;
          margin-top: 2px;
          padding: 0;
          vertical-align: middle;
          &:focus {
            outline: none;
          }
        }

        .input__duration--end {
          width: 80px;
          border: none;
          margin-top: 2px;
          padding: 0;
          vertical-align: middle;
          &:focus {
            outline: none;
          }
        }
      }
      .wrap__duration--invalid {
        border: 1px solid #ff360a;
      }
    }
    .input-date {
      padding: 8px 16px;
      width: 134px;
      height: 45px;
      border: 1px solid #e2e8f0;
      border-radius: 6px;
    }
    .tilde {
      color: #8c9cba;
      margin: 0px 16px;
    }
  }
  .btn__confirm {
    background-color: #295ce0;
    width: calc(100% - 28px);
    height: 48px;
  }
  .btn__confirm--disabled {
    background-color: #f0f0f0;
  }
  .wrap__noti {
    width: 100%;
    text-align: left;
    margin: 16px 0 38px;
    .txt__noti {
      font-weight: 400;
      font-size: 12px;
      margin-left: 14px;
      color: #455573;
      display: block;
    }
    .txt__noti--invalid {
      color: #ff360a;
    }
  }

  .message-validation {
    margin-bottom: 9px;
  }
`;

type Props = {
  startDate: string;
  endDate: string;
  isValid: () => boolean;
  onStartDateChange: (startDate: string) => void;
  onEndDateChange: (endDate: string) => void;
  onSubmitAttendanceDuration: () => void;
  isDateAfterJuly: (dateString: string, type: CertificateType) => boolean;
  isToday: (date: string) => boolean;
  isDayAfterToday: (date: string) => boolean;
  certificateType: CertificateType;
};
export const AttendanceDurationForm = ({
  startDate,
  endDate,
  isValid,
  onStartDateChange,
  onEndDateChange,
  onSubmitAttendanceDuration,
  isDateAfterJuly,
  isToday,
  isDayAfterToday,
  certificateType
}: Props) => {
  return (
    <StyledAttendanceDurationForm>
      <Typography className="title" type={TypographyType.H2} color={ColorPaletteType.GRAY1} text="출석 기간 입력" />
      <div className="duration">
        <div className="wrap__duration wrap__duration--start">
          <span className="txt__duration">시작일</span>
          <div
            className={
              isDateAfterJuly(startDate, certificateType)
                ? 'wrap__duration--input'
                : 'wrap__duration--input  wrap__duration--invalid'
            }
          >
            <CalendarIco className="img__duration" />
            <input
              className="input__duration--start"
              value={startDate}
              placeholder="yyyy.mm.dd"
              autoFocus={true}
              onChange={e => {
                onStartDateChange(e.target.value);
              }}
            />
          </div>
        </div>
        <span className="tilde">~</span>
        <div className="wrap__duration wrap__duration--end">
          <span className="txt__duration">종료일</span>
          <div
            className={
              isDateAfterJuly(endDate, certificateType) && !isToday(endDate)
                ? 'wrap__duration--input'
                : 'wrap__duration--input  wrap__duration--invalid'
            }
          >
            <CalendarIco className="img__duration" />
            <input
              className="input__duration--end"
              value={endDate}
              placeholder="yyyy.mm.dd"
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  if (
                    certificateType === CertificateType.URCLASS &&
                    moment(startDate, 'YYYY-MM-DD').isBefore('2022-07-01')
                  ) {
                    return;
                  }
                  onSubmitAttendanceDuration();
                }
              }}
              onChange={e => {
                onEndDateChange(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="wrap__noti">
        <span className="txt__noti">*형식에 맞춰 숫자만 입력해주세요.</span>
        <span className="txt__noti">*출석 기간은 2개월 이내로 입력해주세요.</span>
        {certificateType === CertificateType.URCLASS && (
          <span
            className={
              isDateAfterJuly(startDate, certificateType) && isDateAfterJuly(endDate, certificateType)
                ? 'txt__noti'
                : 'txt__noti txt__noti--invalid'
            }
          >
            *출석확인서(URCLASS) 발급 가능 기간: 22년 7월부터
          </span>
        )}
        <span
          className={!isToday(startDate) && !isDayAfterToday(endDate) ? 'txt__noti' : 'txt__noti txt__noti--invalid'}
        >
          *당일 이후 날짜는 입력할 수 없습니다.
        </span>
      </div>
      <Button
        className={isValid() ? 'btn__confirm' : 'btn__confirm btn__confirm--disabled'}
        size={SizeType.BASIC}
        disabled={!isValid()}
        status={isValid() ? StatusType.ACTIVE : StatusType.DISABLED}
        onClick={onSubmitAttendanceDuration}
        children={
          <Typography
            type={TypographyType.B5}
            color={isValid() ? ColorPaletteType.WHITE : ColorPaletteType.GRAY9}
            text="발급 받기 >"
          />
        }
      />
    </StyledAttendanceDurationForm>
  );
};
