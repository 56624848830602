export enum SocialProvider {
  GITHUB = 'GITHUB',
  GOOGLE = 'GOOGLE',
  NAVER = 'NAVER',
  KAKAO = 'KAKAO'
}

export type UserProfile = {
  id: number;
  uuid: string;
  email: string;
  name: string;
  mobile: string;
  role: string;
  birthday?: string;
  githubId?: string;
  googleId?: string;
  kakaoId?: string;
  githubUserName?: string;
  hasCohort: boolean;
  cohortName: string;
  isWorking: boolean;
  useAttendanceFeature: boolean;
  isRequiredTermsAgreements: boolean;
  isEmailVerified: boolean;
  isNeedIdentityVerification: boolean;
  lastEnrolledAt: string;
};

export type Credentials = {
  token: string;
};

export type LoginEmailVars = {
  input: {
    email: string;
    password: string;
  };
};
export type LoginEmailData = {
  urclassLoginEmail: Credentials;
};
export type MyProfileData = {
  urclassMe: UserProfile;
};

export interface LoginGithubVars {
  code: string;
  redirectURI: string;
}

export interface LoginGoogleVars {
  code: string;
  redirectURI: string;
}

export interface LoginNaverVars {
  code: string;
  redirectURI: string;
}

export interface LoginKakaoVars {
  code: string;
  redirectURI: string;
}

export interface LoginGithubData {
  urclassLoginGithub: {
    token: string;
  };
}

export interface LoginGoogleData {
  urclassLoginGoogle: {
    token: string;
  };
}

export interface LoginNaverData {
  urclassLoginNaver: {
    token: string;
  };
}

export interface LoginKakaoData {
  urclassLoginKakao: {
    token: string;
    userLoginMetrics?: UserLoginMetrics;
  };
}

export interface UserLoginMetrics {
  loginCount: number;
  recentlyLoginedAt: string;
  uuid: string;
}

export interface SignUpVars {
  name: string;
  mobile: string;
  email: string;
  marketingUseAgreement: boolean;
  serviceTermsAgreement: boolean;
  privacyTermsAgreement: boolean;
  receivingMessagesAgreement: boolean;
  service: SocialProvider;
  accessToken: string;
  mediaType: string;
}

export interface SignUpData {
  urclassSignUp: {
    token: string;
    user: UserProfile;
  };
}

export interface AddSocialAccountVars {
  service: SocialProvider;
  redirectURI: string;
  code: string;
}

export interface AddSocialAccountData {
  urclassAddSocialAccount: boolean;
}

export interface LoginIdentityVerificationTokenData {
  urclassLoginIdentityVerificationToken: {
    token: string;
  };
}

export interface LoginIdentityVerificationTokenVars {
  token: string;
}

export interface LoginIdentityVerificationCodeData {
  urclassLoginIdentityVerificationCode: {
    token: string;
  };
}

export interface LoginIdentityVerificationCodeVars {
  code: string;
  uuid: string;
}

export interface MyTokenHistoryType {
  urclassMyTokenHistory: {
    issuedAt: Date;
    expiredAt: Date;
  };
}
