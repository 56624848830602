import { makeToast } from './../../../../util/makeToast';
import { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { CodeProblemSubmissionStatus } from '../../apollo/coplit/type';
import { CODE_PROBLEM_GQL } from '../../apollo/coplit/query';
import {
  START_CODE_PROBLEM_GQL,
  SUBMIT_CODE_PROBLEM_GQL,
  RUN_CODE_PROBLEM_TEST_GQL
} from '../../apollo/coplit/mutation';

import type {
  TestingResult,
  StartCodeProblemData,
  StartCodeProblemVars,
  SubmitCodeProblemData,
  SubmitCodeProblemVars,
  RunCodeProblemTestData,
  RunCodeProblemTestVars,
  CodeProblemData,
  CodeProblemVars
} from '../../apollo/coplit/type';

export const useController = () => {
  const params = useParams<{ uuid: string }>();
  const codeProblemUuid = params.uuid;
  const savedCode = localStorage.getItem(codeProblemUuid);
  const editorSection = useRef<HTMLHeadingElement>(null);
  const codeEditorConsoleRef = useRef<HTMLHeadingElement>(null);
  const [codeProblemClassroomId, setCodeProblemClassroomId] = useState(0);
  const [codeProblemTitle, setCodeProblemTitle] = useState<string>('');
  const [codeProblemLanguage, setCodeProblemLanguage] = useState<string>('');
  const [codeProblemDescription, setCodeProblemDescription] = useState<string>('');
  const [codeProblemTemplateCode, setCodeProblemTemplateCode] = useState<string>('');
  const [nextCodeProblemUuid, setNextCodeProblemUuid] = useState<string | undefined>();
  const [prevCodeProblemUuid, setPrevCodeProblemUuid] = useState<string | undefined>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [submitModalVisible, setSubmitModalVisible] = useState(false);
  const [referenceModalVisible, setReferenceModalVisible] = useState(false);
  const [editorWidth, setEditorWidth] = useState(0);
  const [editorHeight, setEditorHeight] = useState(0);
  const [code, setCode] = useState('');
  const [id, setId] = useState(0);

  const [isTestRunning, setIsTestRunning] = useState(false);

  const [testingResult, setTestingResult] = useState<TestingResult | undefined>(undefined);

  const handleCodeSave = (code: string, codeProblemUuid: string) => {
    localStorage.setItem(codeProblemUuid, code);
  };

  const debounceRef = useRef(debounce(handleCodeSave, 500));

  const [startCodeProblem] = useMutation<StartCodeProblemData, StartCodeProblemVars>(START_CODE_PROBLEM_GQL);

  const [submitCodeProblem] = useMutation<SubmitCodeProblemData, SubmitCodeProblemVars>(SUBMIT_CODE_PROBLEM_GQL);

  const [runCodeProblemTest] = useMutation<RunCodeProblemTestData, RunCodeProblemTestVars>(RUN_CODE_PROBLEM_TEST_GQL);

  const { data, loading, error } = useQuery<CodeProblemData, CodeProblemVars>(CODE_PROBLEM_GQL, {
    variables: {
      codeProblemUuid
    },
    fetchPolicy: 'network-only'
  });

  const handleSubmitModalClose = () => setSubmitModalVisible(false);

  const handleReferenceModalClose = () => setReferenceModalVisible(false);

  const handleEditorResizing = () => {
    if (editorSection.current) {
      setEditorWidth(editorSection.current.offsetWidth);
      setEditorHeight(editorSection.current.offsetHeight);
    }
  };

  const handleSubmitCodeProblem = async () => {
    try {
      const { data } = await submitCodeProblem({
        variables: { id },
        refetchQueries: [{ query: CODE_PROBLEM_GQL, variables: { codeProblemUuid } }]
      });

      if (data && data.urclassSubmitCodeProblem) {
        makeToast({ type: 'success', content: '제출되었습니다.' });
        setSubmitModalVisible(false);
        return;
      }
      makeToast({ type: 'error', content: '제출과정에 문제가 있습니다.' });
    } catch (error) {
      makeToast({ type: 'error', content: '제출과정에 문제가 있습니다.' });
    }
  };

  const handleCodeProblemTest = async (codeProblemId: number, userCode: string, isSubmit: boolean = false) => {
    try {
      const { data } = await runCodeProblemTest({
        variables: { codeProblemId, userCode }
      });

      if (data && data.urclassRunCodeProblemTest) {
        setIsTestRunning(false);
        setTestingResult(data.urclassRunCodeProblemTest);
        isSubmit && setSubmitModalVisible(true);
        return;
      }
      makeToast({ type: 'error', content: '테스트 실행중에 문제가 발생했습니다. 다시 시도해주세요.' });
      setIsTestRunning(false);
    } catch (error) {
      makeToast({ type: 'error', content: '테스트 실행중에 문제가 발생했습니다. 다시 시도해주세요.' });
      setIsTestRunning(false);
    }
  };

  const handleClickSubmit = async () => {
    try {
      setIsTestRunning(true);
      await handleCodeProblemTest(id, code, true);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleClickTest = () => {
    setIsTestRunning(true);
    handleCodeProblemTest(id, code);
  };

  const handleClickReference = () => {
    setReferenceModalVisible(true);
  };

  useEffect(() => {
    debounceRef.current(code, codeProblemUuid);
  }, [code, codeProblemUuid]);

  useEffect(() => {
    if (editorSection.current) {
      setEditorWidth(editorSection.current.offsetWidth);
      setEditorHeight(editorSection.current.offsetHeight);
    }
  }, [editorSection.current]);

  useEffect(() => {
    if (data && data.urclassMyCodeProblem) {
      const {
        id: codeProblemId,
        templateCode,
        myCode,
        language,
        title,
        description,
        nextCodeProblemUuid,
        previousCodeProblemUuid,
        isSubmitted,
        classroomId,
        status
      } = data.urclassMyCodeProblem;
      setCodeProblemLanguage(language);
      setTestingResult(undefined);
      setId(codeProblemId);
      setCodeProblemTitle(title);
      setCodeProblemDescription(description);
      setNextCodeProblemUuid(nextCodeProblemUuid);
      setPrevCodeProblemUuid(previousCodeProblemUuid);
      setIsSubmitted(isSubmitted);
      setCodeProblemTemplateCode(templateCode);
      setCodeProblemClassroomId(classroomId);

      if (savedCode && status !== CodeProblemSubmissionStatus.NONE) {
        setCode(savedCode);
        return;
      }

      startCodeProblem({ variables: { id: codeProblemId } });
      setCode(myCode || templateCode);
    }
  }, [data]);

  return {
    loading,
    error,
    id,
    code,
    isTestRunning,
    codeProblemClassroomId,
    codeProblemLanguage,
    codeProblemTitle,
    codeProblemDescription,
    nextCodeProblemUuid,
    prevCodeProblemUuid,
    isSubmitted,
    codeProblemTemplateCode,
    editorWidth,
    editorHeight,
    setEditorWidth,
    setEditorHeight,
    setCode,

    submitModalVisible,
    codeEditorConsoleRef,
    referenceModalVisible,
    testingResult,

    handleEditorResizing,
    handleSubmitModalClose,
    handleReferenceModalClose,
    handleSubmitCodeProblem,

    editorSection,
    handleClickSubmit,
    handleClickTest,
    handleClickReference
  };
};
