import React from 'react';

import BasicButton from '../../../components/atoms/Button/BasicButton';
import { DashboardEntry, HybridSummaryWrapper } from './style';
import { numberFormatter } from '../../../util';

import type { PaymentModel } from '../../../apollo/product/type';

type IHybridSummary = {
  paymentModel: PaymentModel;
  handleCheckout?: () => void;
  loading: boolean;
  programId: number;
};

const HybridSummary: React.FC<IHybridSummary> = ({
  paymentModel,
  handleCheckout,
  loading,
  programId
}) => {
  const displayWewinMessage = (programId: number) => {
    if (programId === 4) {
      return (
        <>
          <div>
            <div className="isa-title">비용 지불 없이 시작합니다.</div>
            <div className="isa-description">
              연 소득 3,000만원 이상일 경우 월 소득 14%를 18회 공유합니다.
            </div>
          </div>
        </>
      );
    }

    if (programId === 3) {
      return (
        <>
          <div>
            <div className="isa-title">비용 지불 없이 시작합니다.</div>
            <div className="isa-description">
              소득 3000만원 이상으로 취업하면, 소득의 일부를 코드스테이츠와
              공유합니다.
            </div>
          </div>

          <div className="isa-description">
            <div>취업 시 월 소득의 20% 24회 공유</div>
          </div>
        </>
      );
    }

    if (programId === 2) {
      return (
        <>
          <div>
            <div className="isa-title">비용 지불 없이 시작합니다.</div>
            <div className="isa-description">
              소득 2800만원 이상으로 취업하면, 소득의 일부를 코드스테이츠와
              공유합니다.
            </div>
          </div>

          <div className="isa-description">
            <div>취업 시 월 소득의 12% 18회 공유</div>
          </div>
        </>
      );
    }

    return (
      <>
        <div>
          <div className="isa-title">비용 지불 없이 시작합니다.</div>
          <div className="isa-description">
            소득 3000만원 이상으로 취업하면, 소득의 일부를 코드스테이츠와
            공유합니다.
          </div>
        </div>

        <div className="isa-description">
          <div>취업 시 월 소득의 17% 24회 공유</div>
        </div>
      </>
    );
  };

  return (
    <HybridSummaryWrapper>
      <DashboardEntry>{displayWewinMessage(programId)}</DashboardEntry>
      <DashboardEntry className="last-entry">
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <div className="payment-nav">총 상품 금액</div>
            <div className="payment-nav point">할인 금액</div>
          </div>
          <div style={{ flex: 1, textAlign: 'right' }}>
            <div className="payment-nav">
              {numberFormatter(paymentModel.originPrice)} 원
            </div>
            <div className="payment-nav point">
              {`(-)
        ${
          paymentModel &&
          numberFormatter(paymentModel.originPrice - paymentModel.price)
        } 
        원`}
            </div>
          </div>
        </div>
      </DashboardEntry>

      <DashboardEntry className="last-entry">
        <div className="total-price">
          <div className="total-price-nav">총 결제 금액</div>
          <div className="total-price-value">
            {numberFormatter(paymentModel.price)}원
          </div>
        </div>
        <BasicButton
          color="#00B9EE"
          style={{
            margin: '20px 0px 0px 0px',
            width: '100%'
          }}
          onClick={handleCheckout}
          size="large"
          loading={loading}
        >
          결제 하기
        </BasicButton>
      </DashboardEntry>
    </HybridSummaryWrapper>
  );
};

export default HybridSummary;
