import gql from 'graphql-tag';

export const UPDATE_USER_TERMS_GQL = gql`
  mutation urclassUpdateUserTerms($input: Urclass_UpdateUserTermsInput!) {
    urclassUpdateUserTerms(input: $input) {
      id
      email
      name
      mobile
      role
      birthday
      githubId
      googleId
      kakaoId
      githubUserName
      hasCohort
      isWorking
      useAttendanceFeature
      isRequiredTermsAgreements
      isEmailVerified
    }
  }
`;

export const UPDATE_USER_PROFILE_GQL = gql`
  mutation urclassUpdateUserProfile($input: Urclass_UpdateUserProfileInput!) {
    urclassUpdateUserProfile(input: $input) {
      id
      email
      name
      mobile
      role
      birthday
      githubId
      googleId
      kakaoId
      githubUserName
      hasCohort
      isWorking
      useAttendanceFeature
      isRequiredTermsAgreements
      isEmailVerified
    }
  }
`;

export const ISSUE_EMAIL_AUTH_CODE_GQL = gql`
  mutation urclassIssueEmailAuthCode($email: String!) {
    urclassIssueEmailAuthCode(email: $email)
  }
`;

export const VERIFY_EMAIL_AUTH_CODE_GQL = gql`
  mutation urclassVerifyEmailAuthCode($input: Urclass_VerifyEmailAuthCodeInput!) {
    urclassVerifyEmailAuthCode(input: $input)
  }
`;

export const UPDATE_VERIFIED_EMAIL_GQL = gql`
  mutation urclassUpdateVerifiedEmail($input: Urclass_UpdateVerifiedEmailInput!) {
    urclassUpdateVerifiedEmail(input: $input) {
      id
      email
      name
      mobile
      role
      birthday
      githubId
      googleId
      kakaoId
      githubUserName
      hasCohort
      isWorking
      useAttendanceFeature
      isRequiredTermsAgreements
      isEmailVerified
    }
  }
`;
