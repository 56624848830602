import React from 'react';
import styled from '@emotion/styled';
import classNames from 'classnames';

import LoadingPage from '../components/LoadingPage';
import ErrorPage from '../components/ErrorPage';
import { GeneralHeader } from '../components/organisms/Header';
import { GeneralFooter } from '../components/organisms/Footer';

type GeneralTemplateProps = {
  fixedHeader?: boolean;
  loading?: boolean;
  error?: boolean;
  backgroundColor?: string;
  children: {
    header?: React.ReactNode;
    notification?: React.ReactNode;
    body: React.ReactNode;
    footer?: React.ReactNode;
  };
  mobileSidePadding?: number;
};

const GeneralTemplateWrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #fff;

  .header {
    z-index: 100;

    &.fixed {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
    }
  }

  .general-template__body {
    position: relative;
    padding: 65px 0 150px;
    width: 100%;
    flex: 1;
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#fff')};
  }

  .general-template__footer {
    background-color: #fff;
    border-top: 1px solid #ececec;
  }
`;

const HeaderWrapper = styled.div``;

const BodyWrapper = styled.div<{ mobileSidePadding: number }>`
  max-width: 1200px;
  margin: 0px auto;
  padding: 0px 8px;

  @media screen and (max-width: 992px) {
    margin: 0px ${({ mobileSidePadding }) => mobileSidePadding}px;
    padding: 0px;
  }
`;

const FooterWrapper = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding: 0px 24px;
`;

const GeneralTemplate = ({
  backgroundColor,
  fixedHeader = true,
  loading = false,
  error = false,
  children,
  mobileSidePadding = 32
}: GeneralTemplateProps) => {
  const { header, notification, body, footer } = children;

  const displayBody = () => {
    if (error) {
      return <ErrorPage />;
    }

    if (loading) {
      return <LoadingPage />;
    }

    return body;
  };

  return (
    <GeneralTemplateWrapper backgroundColor={backgroundColor}>
      <HeaderWrapper className={classNames('header', { fixed: fixedHeader })}>
        {header ? header : <GeneralHeader />}
      </HeaderWrapper>
      {notification ? notification : <div />}
      <div className="general-template__body">
        <BodyWrapper mobileSidePadding={mobileSidePadding}>{displayBody()}</BodyWrapper>
      </div>
      <div className="general-template__footer">
        <FooterWrapper>{footer ? footer : <GeneralFooter />}</FooterWrapper>
      </div>
    </GeneralTemplateWrapper>
  );
};

export default GeneralTemplate;
