import React from 'react';
import styled from '@emotion/styled';

const BasicTabWrapper = styled.div`
  letter-spacing: -0.006em;
  text-align: center;
  color: #455573;
  font-weight: 400;
  cursor: pointer;
  padding: 10px 16px 6px;
  width: auto;
  float: left;

  &:hover {
    background-color: rgba(20, 23, 28, 0.05);
    border-radius: 6px;
  }
`;

interface IBasicTab {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const BasicTab: React.FC<IBasicTab> = ({ children, className, onClick }) => (
  <BasicTabWrapper className={className} onClick={onClick}>
    {children}
  </BasicTabWrapper>
);

export default BasicTab;
