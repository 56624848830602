import styled from '@emotion/styled';

import { VbankResult } from './organisms';
import { useController } from './controller';
import { GeneralTemplate } from '../../templates';

import { GeneralHeader } from '../../components/organisms/Header';
import { Typography, TypographyType } from '../../components/atoms/Typography';
import { SizeType, StatusType } from '../../components/molecules/Button/type';
import { Button } from '../../components/molecules/Button';

import { ReactComponent as PaymentSuccessImg } from '../../assets/svg/paymentSuccess.svg';

import { ColorPalette, ColorPaletteType } from '../../styles/color';

import type { IVbank } from '../apply/type';

const ApplyResultPageWrapper = styled.div`
  .emphasis {
    font-weight: 700;
  }

  .inline {
    display: inline-block;
  }

  .apply-result__vbank {
    margin-top: 131px;
    text-align: center;

    .apply-result__vbank-title {
      line-height: 36px;
      margin-bottom: 40px;
    }

    .apply-result__vbank--info-container {
      margin: auto;
      text-align: left;

      width: 480px;
      background: ${ColorPalette.Gray5};

      padding: 2px 0px 23px 47px;
      margin-bottom: 15px;

      .vbank--info__label {
        display: inline-block;

        margin-right: 11px;
        margin-top: 18px;
      }

      .vbank--info__text {
        display: inline-block;
      }

      .second-line {
        margin-left: 72px;
      }
    }

    .vbank--info__button {
      margin-top: 13px;
    }
  }

  .apply-result__card {
    margin-top: 131px;
    text-align: center;

    .apply-result__card-title {
      margin-bottom: 67px;
    }

    .apply-result__card-subtitle {
      margin-top: 43px;
    }

    .card--info__button {
      margin-top: 18px;
    }
  }

  .apply-result__gov-funding {
    margin-top: 131px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .apply-result__gov-funding-title {
      margin-bottom: 71px;
    }

    .ga-urclass-apply-btn {
      margin-top: 73px;
    }
  }
`;

// method : "vbank" | "card" | null
const switchRender = (
  method: string | null,
  handleClickMyPageButton: () => void,
  vbankInfo?: IVbank,
  programId?: number
) => {
  switch (method) {
    case 'vbank':
      return <VbankResult vbankInfo={vbankInfo!} />;

    case 'card':
      return (
        <div className="apply-result__card">
          <Typography
            className="apply-result__card-title"
            type={TypographyType.H1}
            color={ColorPaletteType.GRAY1}
            text="결제 완료!"
          />

          <PaymentSuccessImg />

          <div>
            <Typography
              className="apply-result__card-subtitle inline emphasis"
              type={TypographyType.B2}
              color={ColorPaletteType.GRAY2}
              text="지원자 마이페이지"
            />
            <Typography
              className="apply-result__card-subtitle inline"
              type={TypographyType.B2}
              color={ColorPaletteType.GRAY2}
              text="에서 나머지 지원단계를 진행해주세요!"
            />
          </div>

          <Button
            className="card--info__button"
            size={SizeType.BASIC}
            status={StatusType.ACTIVE}
            children={<Typography type={TypographyType.B1} color={ColorPaletteType.WHITE} text="확인" />}
            onClick={handleClickMyPageButton}
          />
        </div>
      );
    default:
      return (
        <div className="apply-result__gov-funding">
          <Typography
            className="apply-result__gov-funding-title"
            type={TypographyType.H1}
            color={ColorPaletteType.GRAY1}
            text={'부트캠프 지원서 작성을 시작해 볼까요?'}
          />

          <PaymentSuccessImg />

          <Button
            className="ga-urclass-apply-btn"
            size={SizeType.BASIC}
            status={StatusType.ACTIVE}
            children={<Typography type={TypographyType.B1} color={ColorPaletteType.WHITE} text="확인" />}
            onClick={handleClickMyPageButton}
          />
        </div>
      );
  }
};

const ApplyResultPage = () => {
  const {
    method,

    vbankInfo,
    isVbankError,
    cozApplicationLoading,
    cozApplicationData,
    handleClickMyPageButton
  } = useController();

  const programId = cozApplicationData?.urclassMyApplication.product.programId;

  return (
    <GeneralTemplate loading={cozApplicationLoading} fixedHeader={true} error={isVbankError}>
      {{
        header: <GeneralHeader />,
        body: (
          <ApplyResultPageWrapper>
            {switchRender(method, handleClickMyPageButton, vbankInfo, programId)}
          </ApplyResultPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default ApplyResultPage;
