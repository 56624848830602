import gql from 'graphql-tag';

export const CREATE_JOB_PROFILE_GQL = gql`
  mutation createJobProfile(
    $favorPositionIds: [Int!]!
    $interestDomainIds: [Int!]!
    $skillSetNames: [String!]!
    $educationDetails: [EducationDetailInput!]!
    $languageDetails: [LanguageDetailInput!]!
    $activityDetails: [ActivityDetailInput!]!
    $experienceDetails: [ExperienceDetailInput!]!
    $jobProfile: NewJobProfileInput!
  ) {
    urclassCreateMyJobProfile(
      favorPositionIds: $favorPositionIds
      interestDomainIds: $interestDomainIds
      skillSetNames: $skillSetNames
      jobProfile: $jobProfile
      educationDetails: $educationDetails
      languageDetails: $languageDetails
      activityDetails: $activityDetails
      experienceDetails: $experienceDetails
    ) {
      id
    }
  }
`;

export const UPDATE_JOB_PROFILE_GQL = gql`
  mutation updateJobProfile(
    $favorPositionIds: [Int!]!
    $interestDomainIds: [Int!]!
    $skillSetNames: [String!]!
    $educationDetails: [EducationDetailInput!]!
    $languageDetails: [LanguageDetailInput!]!
    $activityDetails: [ActivityDetailInput!]!
    $experienceDetails: [ExperienceDetailInput!]!
    $jobProfile: UpdateJobProfileInput!
  ) {
    urclassUpdateMyJobProfile(
      favorPositionIds: $favorPositionIds
      interestDomainIds: $interestDomainIds
      skillSetNames: $skillSetNames
      educationDetails: $educationDetails
      languageDetails: $languageDetails
      activityDetails: $activityDetails
      experienceDetails: $experienceDetails
      jobProfile: $jobProfile
    ) {
      id
    }
  }
`;

export const CREATE_RESUME_UPLOAD_URL_GQL = gql`
  mutation urclassCreateUploadUrl($fileName: String!) {
    urclassCreateResumeUploadURL(fileName: $fileName) {
      url
      fields
    }
  }
`;

export const CREATE_RESUME_GQL = gql`
  mutation urclassCreateResume($input: CreateResumeInput!) {
    urclassCreateResume(input: $input) {
      id
    }
  }
`;

export const DELETE_RESUME_GQL = gql`
  mutation urclassDeleteResume($resumeId: Int!) {
    urclassDeleteResume(resumeId: $resumeId)
  }
`;

export const PUBLISH_RESUME_GQL = gql`
  mutation urclassPublishResume($resumeId: Int!) {
    urclassPublishResume(resumeId: $resumeId)
  }
`;
