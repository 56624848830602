// ⚛ React & Hooks
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCredential } from '../../contexts/credential';
import { useWindowSize } from '../../util/hooks/useWindowSize';

// 💖 Data Fetching
import { useQuery as useApolloQuery } from '@apollo/react-hooks';
import { MY_APPLICATION_GQL } from '../../apollo/mypage/query';
import { MyApplicationVars, MyApplicationData } from '../../apollo/mypage/type';

import { AxiosError, AxiosResponse } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import queryKeys from '../../query/queryKeys';
import { CreateApplicationAnswerBySurveyIdRequest } from '../detailApplication/request';
import {
  createApplicationAnswerRequest,
  getApplicationDetailRequest,
  updateApplicationAnswerRequest,
  UpdateApplicationAnswerRequest,
  updateApplicationAnswerStatusRequest,
  UpdateApplicationAnswerStatusRequest,
  uploadFiles,
  deleteFile
} from './request';

// 🧱 Components & style
import styled from '@emotion/styled';
import '../../style/coz-survey.css';
import { Header } from '../../components/organisms/Header';
import { GeneralTemplate } from '../../templates';
import Timer from './components/Timer';
import ProgressBar from './components/ProgressBar';
import SurveyBoardContent from './components/SurveyBoardContent';
import SurveyBoardHeader from './components/SurveyBoardHeader';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right-formpage.svg';

// 🏷 Marketing
import { useTrack } from '@hackler/react-sdk';
import { sendPageView } from '../../util/marketingTools/googleTagAssistant';

// 🖇 ETC
import { parseSlugUrl, store } from '../../util';
import { calculateNextOrder, checkIsEditable, checkIsLastSurvey } from '../../util/admission';
import { koreanStrings } from '../../util/admissionSurveyJs/surveyLocalization';
import { convertProgramToHackleCourseName } from '../apply/controller';
import { StylesManager, Model, surveyLocalization } from 'survey-core';
import { ApplicationAnswerStatusType } from '../detailApplication/type';
import { makeToast } from '../../util/makeToast';
import { delayFunctionOnSafari, detectBrowser } from '../../util/browser';
import { dataLayerForSurveySubmit } from '../../util/marketingTools/datalayerGa4';
import { Modal } from 'antd';
import { fbqTrackSingleCustom, generateEventId } from '../../util/marketingTools/fbq';

type TimerPanelMode = 'all' | 'survey' | 'page' | null;
type ProgressBarType = 'off' | 'top' | 'bottom' | 'both';
type RecordAnswerActionType = 'save' | 'submit';
type TimerType = 'surveyTimer' | 'pageTimer' | '';
interface SurveyProgress {
  total: number | null;
  current: number | null;
  percent: number | null;
}
export interface TimerInfoType {
  surveySpentTime: null | number;
  surveyLimitTime: null | number;
  surveyRemainTime: null | number;
  pageSpentTime: null | number;
  pageLimitTime: null | number;
  pageRemainTime: null | number;
}
interface AutoSavedDataType {
  isSavedDataLoaded: boolean;
  answer: any;
  timeSpent: number;
  currentPageNo: number;
  lastAnsweredPage: string;
}

interface CustomSurveyFile {
  content: string;
  name: string;
  type: string;
  fileId: number;
}

surveyLocalization.locales['ko'] = koreanStrings;
surveyLocalization.localeNames['ko'] = '한국어';

StylesManager.applyTheme('modern');

const AdmissionApplicationFormPage = () => {
  const browser = detectBrowser();
  const history = useHistory();

  const urlQueries = window.location.search;

  const location = useLocation<{ uuid: string; from: 'edit' | 'notEdit' }>();
  const { uuid: applicationUuid, from } = location.state;

  const { profile, loadingProfile } = useCredential();

  const { steps, slugUrl } = useParams<{ steps: string; slugUrl: string }>();

  const finalOrOrder = steps.split('?')[0];

  const { width } = useWindowSize();
  const isMobile = width ? width < 992 : false;
  const track = useTrack();
  const capturedSaveCallback = useRef<() => void>();

  const [order, setOrder] = useState<number>(0);
  const [headerTitle, setHeaderTitle] = useState('');
  const [survey, setSurvey] = useState<any>();
  const [surveyDefinition, setSurveyDefinition] = useState<
    | {
        showProgressBar?: ProgressBarType;
        showTimerPanel?: TimerPanelMode;
      }
    | undefined
  >();

  const [isCompleteBtnClicked, setIsCompleteBtnClicked] = useState<boolean>(false);
  const [isAnswerAlreadySubmitted, setIsAnswerAlreadySubmitted] = useState<boolean>(false);
  const [isNextBtnVisible, setIsNextBtnVisible] = useState(false);
  const [isLastSurvey, setIsLastSurvey] = useState<boolean>(false);
  const [isSurveyInfoModalOn, setIsSurveyInfoModalOn] = useState<boolean>(false);
  const [isTimer, setIsTimer] = useState<boolean>(false);
  const [isProgressBar, setIsProgressBar] = useState<boolean>(false);
  const [isErrorModalShowed, setIsErrorModalShowed] = useState<boolean>(false);
  const [timerPanelMode, setTimerPanelmode] = useState<TimerPanelMode>(null);
  const [timerInfo, setTimerInfo] = useState<TimerInfoType>({
    surveySpentTime: null,
    surveyLimitTime: null,
    surveyRemainTime: null,
    pageSpentTime: null,
    pageLimitTime: null,
    pageRemainTime: null
  });
  const [surveyProgress, setSurveyProgress] = useState<SurveyProgress>({
    total: null,
    current: null,
    percent: null
  });
  const [autoSavedData, setAutoSavedData] = useState<AutoSavedDataType>({
    isSavedDataLoaded: false,
    answer: '',
    timeSpent: 0,
    currentPageNo: 0,
    lastAnsweredPage: ''
  });
  const [timerType, setTimerType] = useState<TimerType>('');
  const [pageTimerSpentTime, SetPageTimerSpentTime] = useState<number>(0);
  const [isSurveySubmitted, setIsSurveySubmitted] = useState<boolean>(false);

  const {
    data: codApplicationData,
    loading: isCodApplicationLoading,
    error: codApplicationError
  } = useApolloQuery<MyApplicationData, MyApplicationVars>(MY_APPLICATION_GQL, {
    variables: { uuid: applicationUuid },
    fetchPolicy: 'network-only',
    onCompleted: ({ urclassMyApplication }) => {
      store.admissionToken.set(urclassMyApplication.admissionApplierToken || '');
    }
  });

  const getAdmissionApplicationByUuidParams = {
    applicationUuid,
    userUuid: profile?.uuid || ''
  };

  const {
    data: admissionApplicationData,
    isLoading: isAdmissionApplicationLoading,
    isError: isAdmissionApplicationError,
    refetch: refetchAdmissionApplication
  } = useQuery(
    queryKeys.APPLICATION_DETAIL_BY_UUID(getAdmissionApplicationByUuidParams),
    () => getApplicationDetailRequest(profile?.uuid || '', applicationUuid),
    {
      enabled: !!profile?.uuid && !!codApplicationData?.urclassMyApplication.admissionApplierToken,
      onSuccess: ({ data }) => {
        const { answers, template } = data;

        const templateItemsLength = template?.items?.length || 0;
        const tempOrder = finalOrOrder === 'final' ? templateItemsLength : Number(finalOrOrder);

        const thisStepItem = template?.items?.find(item => item.sequence === tempOrder - 1);
        const thisStepAnswer = answers?.find(answer => answer.itemId === thisStepItem?.id);

        const checkLastSurveyResult = checkIsLastSurvey(answers || [], template?.items || []);
        setIsLastSurvey(checkLastSurveyResult);

        if (!autoSavedData.isSavedDataLoaded) {
          if (!thisStepAnswer) {
            setAutoSavedData({
              ...autoSavedData,
              isSavedDataLoaded: true
            });
          } else {
            setAutoSavedData({
              isSavedDataLoaded: true,
              answer: thisStepAnswer.answer,
              timeSpent: thisStepAnswer.spentTime || 0,
              currentPageNo: Number(thisStepAnswer.lastAnswerPageNumber) || 0,
              lastAnsweredPage: thisStepAnswer.lastAnswerPage || ''
            });
          }
        }

        if (order === 0) {
          setOrder(tempOrder);
        }
      },
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false
    }
  );

  const { mutate: createApplicationAnswer } = useMutation<
    AxiosResponse,
    AxiosError,
    CreateApplicationAnswerBySurveyIdRequest,
    unknown
  >(variables => createApplicationAnswerRequest(variables), {
    onSuccess: () => {
      refetchAdmissionApplication();
    }
  });

  const { mutate: updateApplicationAnswer } = useMutation<
    AxiosResponse,
    AxiosError,
    UpdateApplicationAnswerRequest,
    unknown
  >(variables => updateApplicationAnswerRequest(variables), {
    onSuccess: () => {
      refetchAdmissionApplication();
    },
    onError: err => {
      handleAutoSaveError(err);
    }
  });

  const { mutate: updateApplicationAnswerStatus } = useMutation<
    AxiosResponse,
    AxiosError,
    UpdateApplicationAnswerStatusRequest,
    unknown
  >(variables => updateApplicationAnswerStatusRequest(variables));

  const saveCallback = () => {
    recordApplicationAnswer(survey, 'save');
  };

  const handleAutoSaveError = (err: AxiosError) => {
    if (isErrorModalShowed) return;

    const { message } = (err as unknown as AxiosError).response?.data;

    Modal.error({
      title: '지원 안내',
      content: message.includes('이전 상태가 일치하지 않습니다.')
        ? '지원자님의 응답을 안전하게 저장하기 위해 마이페이지로 이동합니다. 마이페이지에서 진행 현황을 확인해주세요.'
        : `${message || '에러가 발생하였습니다.'} 마이페이지로 이동합니다.`,
      okText: '확인',
      onOk: () => {
        history.push(`/mypage`);
        setIsErrorModalShowed(false);
      }
    });
    setIsErrorModalShowed(true);
  };
  const handleNextStepButtonClick = () => {
    const applicationAnswers = admissionApplicationData?.data.answers;
    const template = admissionApplicationData?.data?.template;
    const templateItems = template?.items;
    if (!applicationAnswers || !templateItems || !template) return;

    const nextOrder = calculateNextOrder(templateItems, applicationAnswers);
    const slugUrl = template?.slugUrl;

    if (isLastSurvey || nextOrder === 0) {
      history.push(`/mypage`);
    } else if (nextOrder === template?.items?.length) {
      // TODO: 새로고침 되는 부분 refetch를 통해서 개선하기
      history.push({
        pathname: `/application/${slugUrl}/final`,
        state: { uuid: applicationUuid }
      });
      history.go(0);
    } else {
      // TODO: 새로고침 되는 부분 refetch를 통해서 개선하기
      history.push({
        pathname: `/application/${slugUrl}/${nextOrder}`,
        state: { uuid: applicationUuid }
      });
      history.go(0);
    }
  };

  const recordApplicationAnswer = (sender: any, action: RecordAnswerActionType) => {
    const applicationAnswers = admissionApplicationData?.data.answers;
    const templateItems = admissionApplicationData?.data?.template?.items;

    if (!applicationAnswers || !templateItems) {
      console.log('no answers and items quit');
      return;
    }

    const thisStepItem = templateItems.find(item => item.sequence === order - 1);
    const thisStepAnswer = applicationAnswers.find(answer => answer.itemId === thisStepItem?.id);

    if (!thisStepAnswer || !thisStepItem) {
      console.log('no thisStepAnswer or thisStepItem quit');
      return;
    }
    if (!checkIsEditable(thisStepAnswer, thisStepItem)) {
      console.log('not Editable quit');
      return;
    }

    let timeSpent;

    if (timerType === 'pageTimer') {
      if (thisStepAnswer.lastAnswerPageNumber !== sender.currentPageNo) {
        SetPageTimerSpentTime(0);
        timeSpent = 0;
      } else {
        timeSpent = (survey?.currentPage?.timeSpent || 0) + pageTimerSpentTime;
      }
    } else if (timerType === 'surveyTimer') {
      timeSpent = (survey?.timeSpent || 0) + autoSavedData.timeSpent || 0;
    }

    if (isSurveySubmitted || isErrorModalShowed) {
      console.log('survey submitted or error modal showed quit');
      return;
    }

    const isStartingPage = sender?.isShowStartingPage;
    const startingPageName = sender?.startedPage?.propertyHash.name;
    const currentPageName = sender?.currentPage?.propertyHash?.name || '';
    const currentPageNo = sender?.currentPageNo || 0;
    const eventId = generateEventId();
    updateApplicationAnswer(
      {
        params: {
          applicationUuid: applicationUuid,
          userUuid: profile?.uuid || '',
          answerId: thisStepAnswer.id
        },
        request: {
          status: thisStepAnswer.status,
          spentTime: timeSpent,
          lastAnswerPageNumber: currentPageNo,
          lastAnswerPage: isStartingPage ? startingPageName : currentPageName,
          answer: sender?.data || {},
          lastAnswerQuestion: ''
        }
      },
      {
        onSuccess: () => {
          if (action !== 'submit') {
            return;
          }

          if (thisStepAnswer.status === ApplicationAnswerStatusType.SUBMITTED) {
            setIsNextBtnVisible(true);
            setIsSurveySubmitted(true);
          } else if (
            thisStepAnswer.status === ApplicationAnswerStatusType.IN_PROGRESS ||
            thisStepAnswer.status === ApplicationAnswerStatusType.CREATED
          ) {
            updateApplicationAnswerStatus(
              {
                params: {
                  applicationUuid: applicationUuid,
                  userUuid: profile?.uuid || '',
                  answerId: thisStepAnswer.id || 0
                },
                request: {
                  changeFrom: ApplicationAnswerStatusType.IN_PROGRESS,
                  changeTo: ApplicationAnswerStatusType.SUBMITTED
                },
                queries: {
                  eventId,
                  contentName: thisStepItem?.survey.displayTitle || '',
                  courseName: parseSlugUrl(slugUrl).course
                }
              },
              {
                onSuccess: () => {
                  const { course } = parseSlugUrl(slugUrl);
                  setIsSurveySubmitted(true);
                  delayFunctionOnSafari(browser, navigator, () => {
                    refetchAdmissionApplication();
                    setIsNextBtnVisible(true);
                  });
                  sendStepSubmitEventToHackle();
                  dataLayerForSurveySubmit({
                    data: admissionApplicationData,
                    slugUrl,
                    uuid: profile?.uuid,
                    order,
                    urlQueries
                  });

                  fbqTrackSingleCustom(
                    'CompleteAdmissionStep',
                    { content_name: thisStepItem?.survey.displayTitle, course_name: course },
                    eventId
                  );
                }
              }
            );
          }
        }
      }
    );
  };

  const handleCompleteButtonClick = async (sender: any) => {
    window.scrollTo(0, 0);
    setIsSurveyInfoModalOn(false);
    recordApplicationAnswer(sender, 'submit');
    setIsCompleteBtnClicked(true);
  };

  const handleStartButtonClick = (sender: any) => {
    if (surveyDefinition && surveyDefinition.showProgressBar) {
      setIsProgressBar(true);
    }

    if (surveyDefinition && surveyDefinition.showTimerPanel) {
      setIsTimer(true);
    }

    window.scrollTo(0, 0);
    setIsSurveyInfoModalOn(true);
    setProgressBar(sender.progressText);
  };

  const handleNextButtonClick = (sender: any, option: any) => {
    window.scrollTo(0, 0);
    setProgressBar(sender.progressText);

    // TODO: 버그 픽스 필요 (2023.6.14)
    // 미리보기 페이지에서 완료 버튼 클릭시 NextButtonClick 3-4 차례 트리거링 됨
    // 이 때, 정작 실행되어야 할 완료 버튼 핸들링함수가 위에서 3-4 차례 발생된 함수로 인해 무효화되는 버그 존재
    // 미리보기 페이지에서 완료 버튼 클릭시 recordApplicationAnswer 실행되지 않도록 처리

    // 페이지 전환 시 바로 다음페이지로 이동했는지 여부
    const isNextPage = option.isNextPage;
    // 시작페이지에서 발생한 페이지 전환이었는지 여부
    const fromStartPage = option.oldCurrentPage?.propertyHash.num === -1;

    if (!isCompleteBtnClicked && isNextPage && !fromStartPage) {
      recordApplicationAnswer(sender, 'save');
    }
  };

  const sendStepSubmitEventToHackle = () => {
    if (codApplicationData?.urclassMyApplication) {
      const { product } = codApplicationData?.urclassMyApplication;
      const courseName = convertProgramToHackleCourseName(product.programId);

      const templateItems = admissionApplicationData?.data.template?.items;
      if (!templateItems) return;

      const thisStepItem = templateItems[order - 1];
      const surveyTitle = thisStepItem?.survey?.displayTitle;

      const event = {
        key: `${courseName}_submit_${finalOrOrder}`,
        value: 10000,
        properties: {
          survey_title: surveyTitle || ''
        }
      };

      track(event);
    }
  };

  const handleTimerChanged = () => {
    const surveyPanelMode = survey.showTimerPanelMode;
    setTimerPanelmode(surveyPanelMode);

    switch (surveyPanelMode) {
      case 'all':
        setTimerInfo({
          pageSpentTime: survey.currentPage.timeSpent,
          pageLimitTime: survey.maxTimeToFinishPage || survey.currentPage.propertyHash.maxTimeToFinish,
          pageRemainTime:
            (survey.maxTimeToFinishPage || survey.currentPage.propertyHash.maxTimeToFinish) -
            survey.currentPage.timeSpent,
          surveySpentTime: autoSavedData.timeSpent + survey.timeSpent,
          surveyLimitTime: survey.maxTimeToFinish,
          surveyRemainTime: survey.maxTimeToFinish - survey.timeSpent
        });
        break;
      case 'survey':
        setTimerInfo({
          pageSpentTime: null,
          pageLimitTime: null,
          pageRemainTime: null,
          surveySpentTime: autoSavedData.timeSpent + survey.timeSpent,
          surveyLimitTime: survey.maxTimeToFinish,
          surveyRemainTime: survey.maxTimeToFinish - survey.timeSpent
        });
        break;
      case 'page':
        setTimerInfo({
          pageSpentTime: survey.currentPage.timeSpent,
          pageLimitTime: survey.maxTimeToFinishPage || survey.currentPage.propertyHash.maxTimeToFinish,
          pageRemainTime:
            (survey.maxTimeToFinishPage || survey.currentPage.propertyHash.maxTimeToFinish) -
            survey.currentPage.timeSpent,
          surveySpentTime: null,
          surveyLimitTime: null,
          surveyRemainTime: null
        });
        break;
    }
  };

  const handleSurveyValueChanged = (sender: any) => {
    setSurvey(sender);
  };

  const setProgressBar = (progressText: string) => {
    const progressBarInfo = progressText.split('/');
    const current = Number(progressBarInfo[0]);
    const total = Number(progressBarInfo[1]);
    const percent = Math.round((current / total) * 100);
    setSurveyProgress({ total, current, percent });
  };

  const handleRenderQuestion = (survey: any, options: any) => {
    if (options.question.getType() !== 'comment') return;
    const element = options.htmlElement.getElementsByTagName('textarea')[0];
    const maxLength = options.question.propertyHash.maxLength;

    const characterCounter = document.createElement('div');
    characterCounter.classList.add('survey__character-counter');

    const focusingHandler = () => {
      characterCounter.classList.add('survey__character-counter--focused');
    };
    const blurringHandler = () => {
      characterCounter.classList.remove('survey__character-counter--focused');
    };
    const changingHandler = function () {
      let currLength = element.value.length;
      if (!maxLength || maxLength < 0) {
        characterCounter.innerText = currLength;
      } else {
        characterCounter.innerText = currLength + '/' + maxLength;
      }
    };
    changingHandler();

    if (!!element.nextSibling) {
      element.parentNode?.insertBefore(characterCounter, element.nextSibling);
    } else {
      element.parentNode?.appendChild(characterCounter);
    }

    element.onkeyup = changingHandler;
    element.onfocus = focusingHandler;
    element.onblur = blurringHandler;
  };

  const handleUploadFiles = async (_: any, options: any) => {
    try {
      const filesFormData = new FormData();
      options.files.forEach((file: File) => filesFormData.append('files', file));

      const { data } = await uploadFiles({
        folderCategory: 'CODESHIP',
        payload: filesFormData
      });

      const prevSurveyValue = survey.getValue(options.name);
      survey.setValue(options.name, [
        ...(prevSurveyValue ? prevSurveyValue : []),
        ...data.map(file => ({
          content: file.url,
          name: file.originalName,
          type: file.contentType,
          fileId: file.id
        }))
      ]);
    } catch (error) {
      makeToast({ type: 'error', content: '파일 업로드에 실패했습니다.' });
      console.log(error);
    }
  };

  const handleClearFiles = async (_: any, options: any) => {
    try {
      const filesToDelete: CustomSurveyFile[] = options.fileName
        ? options.value.filter((file: CustomSurveyFile) => file.name === options.fileName)
        : options.value;

      if (!filesToDelete) {
        return options.callback('success');
      }

      await Promise.all(filesToDelete.map(async ({ fileId }) => await deleteFile({ fileId })));

      options.callback('success');
    } catch (error) {
      options.callback('error');
      makeToast({ type: 'error', content: '파일 삭제에 실패했습니다.' });
      console.log(error);
    }
  };

  useEffect(() => {
    capturedSaveCallback.current = saveCallback;
  }, [saveCallback]);

  useEffect(() => {
    const executeSaveCallback = () => {
      if (!capturedSaveCallback.current) return;
      capturedSaveCallback.current();
    };
    const autoSaveTimer = setInterval(executeSaveCallback, 1000 * 10);
    return () => clearInterval(autoSaveTimer);
  }, []);

  useEffect(() => {
    if (!codApplicationData) return;

    const { name } = codApplicationData.urclassMyApplication.product;
    sendPageView(`/applications/${name}/${finalOrOrder}`);
  }, [codApplicationData]);

  useEffect(() => {
    const templateItems = admissionApplicationData?.data.template?.items;
    const applicationAnswers = admissionApplicationData?.data?.answers;
    if (!templateItems || !applicationAnswers) return;

    const thisStepItem = templateItems.find(item => item.sequence === order - 1);
    if (!thisStepItem) return;

    const thisStepAnswer = applicationAnswers.find(answer => answer.itemId === thisStepItem.id);
    if (!thisStepAnswer) return;

    if (!checkIsEditable(thisStepAnswer, thisStepItem)) {
      setIsAnswerAlreadySubmitted(true);
    }
  }, [order]);

  useEffect(() => {
    const applicationAnswers = admissionApplicationData?.data.answers;
    const templateItems = admissionApplicationData?.data.template?.items;
    const thisStepItem = templateItems?.find(item => item.sequence === order - 1);
    const thisStepAnswer = applicationAnswers?.find(answer => answer.itemId === thisStepItem?.id);

    if (order && applicationAnswers && thisStepItem && !thisStepAnswer) {
      createApplicationAnswer({
        applicationUuid: applicationUuid,
        userUuid: profile?.uuid || '',
        itemId: thisStepItem.id
      });
    }
  }, [order, admissionApplicationData, createApplicationAnswer, applicationUuid]);

  useEffect(() => {
    const applicationItems = admissionApplicationData?.data?.template?.items;
    const thisStepItem = applicationItems?.find(item => item.sequence === order - 1);

    if (order && thisStepItem) {
      const surveyDefinition = thisStepItem.survey?.definition;
      setSurveyDefinition(surveyDefinition);
    }
  }, [order]);

  useEffect(() => {
    const surveyInstance = new Model(surveyDefinition);

    // 파일 업로드는 무조건 서버와 연동
    const questions = surveyInstance.getAllQuestions();
    for (const question of questions) {
      const type = question.getType();
      if (type === 'file') {
        question.setPropertyValue('storeDataAsText', false);
      }
    }

    setSurvey(surveyInstance);
  }, [surveyDefinition]);

  useEffect(() => {
    if (survey) {
      survey.onTimerPanelInfoText.add(handleTimerChanged);
      survey.onUploadFiles.add(handleUploadFiles);
      survey.onClearFiles.add(handleClearFiles);
    }

    return () => {
      if (survey) {
        survey.onTimerPanelInfoText.remove(handleTimerChanged);
        survey.onUploadFiles.remove(handleUploadFiles);
        survey.onClearFiles.remove(handleClearFiles);
      }
    };
  }, [survey]);

  useEffect(() => {
    const templateItems = admissionApplicationData?.data.template?.items;
    if (!templateItems) return;
    const displayTitle = templateItems.find(item => item.sequence === order - 1)?.survey?.displayTitle;

    if (displayTitle) {
      setHeaderTitle(displayTitle);
    }
  }, [admissionApplicationData, order]);

  useEffect(() => {
    if (!order || !survey) return;

    const applicationAnswers = admissionApplicationData?.data.answers;
    const templateItems = admissionApplicationData?.data.template?.items;
    const thisStepItem = templateItems?.find(item => item.sequence === order - 1);
    const thisStepAnswer = applicationAnswers?.find(answer => answer.itemId === thisStepItem?.id);

    if (
      thisStepAnswer?.status === ApplicationAnswerStatusType.SUBMITTED &&
      thisStepItem?.survey?.test &&
      !thisStepItem?.survey?.hasNoTestPage
    )
      return;

    const { answer, timeSpent, currentPageNo, lastAnsweredPage } = autoSavedData;
    const lastSeenPage = survey.getPageByName(lastAnsweredPage);

    if (survey?.maxTimeToFinish) {
      setTimerType('surveyTimer');
    } else if (!survey?.maxTimeToFinishPage && survey.pages.some((page: any) => page?.propertyHash?.maxTimeToFinish)) {
      setTimerType('pageTimer');
    }

    survey.data = autoSavedData.answer;

    const noTestPage = survey.getPageByName('noTestPage');
    if (
      thisStepItem?.survey?.test &&
      thisStepItem?.survey?.hasNoTestPage &&
      thisStepAnswer?.status === ApplicationAnswerStatusType.SUBMITTED
    ) {
      survey.currentPageNo = noTestPage?.propertyHash?.num - 1;
    } else {
      survey.currentPageNo = autoSavedData.currentPageNo;
    }

    if (answer === '' && timeSpent === 0 && currentPageNo === 0 && lastAnsweredPage === '') return;

    if (survey?.maxTimeToFinish) {
      survey.maxTimeToFinish -= timeSpent;
    } else if (lastSeenPage?.maxTimeToFinish) {
      SetPageTimerSpentTime(timeSpent);
      survey.getPageByName(lastAnsweredPage).propertyHash.maxTimeToFinish -= timeSpent;
    }

    makeToast({ type: 'success', content: '작성 중인 내용을 불러왔어요.' });
  }, [survey, order, autoSavedData]);

  useEffect(() => {
    if (survey) {
      survey.locale = 'ko';
      survey.onComplete.add(handleCompleteButtonClick);
      survey.onStarted.add(handleStartButtonClick);
      survey.onValueChanged.add(handleSurveyValueChanged);
      survey.onCurrentPageChanged.add(handleNextButtonClick);
      survey.onAfterRenderQuestion.add(handleRenderQuestion);
    }

    return () => {
      if (survey) {
        survey.onComplete.remove(handleCompleteButtonClick);
        survey.onStarted.remove(handleStartButtonClick);
        survey.onValueChanged.remove(handleSurveyValueChanged);
        survey.onCurrentPageChanged.remove(handleNextButtonClick);
        survey.onAfterRenderQuestion.remove(handleRenderQuestion);
      }
    };
  }, [
    survey,
    order,
    admissionApplicationData,
    handleCompleteButtonClick,
    handleNextButtonClick,
    handleStartButtonClick
  ]);

  return (
    <GeneralTemplate
      loading={isCodApplicationLoading || loadingProfile || isAdmissionApplicationLoading || !order}
      error={!!codApplicationError || !!isAdmissionApplicationError}
      backgroundColor={'#F5F7F9'}
      mobileSidePadding={16}
    >
      {{
        header: <Header />,
        body: (
          <FormPageWrapper notification={isAnswerAlreadySubmitted}>
            {isAnswerAlreadySubmitted ? (
              <NotificationBox>
                <div className="notification_title">이미 제출한 설문지 입니다.</div>
                <p>
                  다시 제출이 필요한 상황이면 <br />
                  홈페이지 내 "문의하기" 버튼을 이용해주세요.
                </p>
              </NotificationBox>
            ) : (
              <SurveyFormBoard>
                {isSurveyInfoModalOn && (
                  <SurveyInfoFloatingWrapper>
                    {isTimer && <Timer timerPanelMode={timerPanelMode} timerInfo={timerInfo} />}
                    {isProgressBar && <ProgressBar surveyProgress={surveyProgress} />}
                  </SurveyInfoFloatingWrapper>
                )}
                <SurveyBoardHeader
                  productName={codApplicationData?.urclassMyApplication.product.name}
                  displayTitle={headerTitle}
                  isNextBtnVisible={isNextBtnVisible}
                  isMobile={isMobile}
                  isLastSurvey={isLastSurvey}
                  handleNextStepButtonClick={handleNextStepButtonClick}
                  templateItems={admissionApplicationData?.data.template.items}
                  finalOrOrder={Number(finalOrOrder)}
                  from={from}
                />
                <SurveyBoardContent survey={survey} />
              </SurveyFormBoard>
            )}
            {isMobile ? (
              <MobileNextBtnBox className={isNextBtnVisible ? 'visible' : ''}>
                <NextButton onClick={handleNextStepButtonClick}>
                  <span>{isNextBtnVisible && isLastSurvey ? '마이페이지로' : '다음 단계로'}</span>
                  <ArrowRight />
                </NextButton>
              </MobileNextBtnBox>
            ) : null}
          </FormPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default AdmissionApplicationFormPage;

const FormPageWrapper = styled.div<{ notification: boolean }>`
  padding: 60px 0;
  display: ${({ notification }) => (notification ? 'flex' : null)};
  justify-content: center;
  align-items: center;
`;

const SurveyFormBoard = styled.div`
  border-radius: 24px;
`;

const NextButton = styled.button`
  height: 40px;
  border-radius: 6px;
  background: #452cdd;
  padding: 8px 16px;
  font-weight: 700;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  > span {
    margin-top: 1px;
  }

  svg {
    margin-left: 8px;
    margin-top: 0px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const NotificationBox = styled.div`
  width: 384px;
  height: 236px;
  background: linear-gradient(112.91deg, #fbfcfc 3.51%, #fefefe 111.71%);
  box-shadow: 10.65px 10.58px 28.29px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20.5056px);
  border-radius: 22px;
  padding: 78px 0;
  margin-top: 160px;
  text-align: center;
  color: #455573;
  display: flex;
  flex-direction: column;
  align-items: center;

  .notification_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }

  > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-top: 8px;
  }

  @media screen and (max-width: 992px) {
    margin-top: 100px;
  }
`;

const MobileNextBtnBox = styled.div`
  position: fixed;
  width: 100vw;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  bottom: -72px;
  left: 0;
  z-index: 1;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  transition: bottom 0.7s ease;

  button {
    flex-grow: 1;
    width: calc(100% - 32px);
    height: 48px;
    font-size: 18px;
    line-height: 27px;
  }

  &.visible {
    bottom: 0px;
  }
`;

const SurveyInfoFloatingWrapper = styled.div`
  position: fixed;
  top: 105px;
  right: 60px;
  min-width: 226px;
  z-index: 100;

  @media screen and (max-width: 992px) {
    top: 79px;
    right: 12px;
    min-width: 174px;
  }
`;
