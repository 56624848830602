import styled from '@emotion/styled';
import { Route } from 'react-router-dom';

import { useController } from './controller';
import { GeneralTemplate } from '../../../../templates';
import { TextLinkGroup } from '../../components/course/TextLinkGroup';
import { CourseCardGroup } from '../../components/course/CourseCardGroup';

const CoursePageWrapper = styled.div`
  .navigation {
    margin: 72px 0px 45px 0px;
  }
`;

const CoursePage = () => {
  const { loading, error, activeCourses, scheduledCourses, expiredCourses } = useController();
  const dataSource = [
    {
      title: '수강 중인 코스',
      route: '/courses/active'
    },
    { title: '개강 예정 코스', route: '/courses/scheduled' },
    { title: '만료된 코스', route: '/courses/expired' }
  ];

  return (
    <GeneralTemplate loading={loading} error={!!error}>
      {{
        body: (
          <CoursePageWrapper>
            <div className="navigation">
              <TextLinkGroup dataSource={dataSource} />
            </div>
            <div className="content">
              <Route path={'/courses/active'} render={() => <CourseCardGroup courses={activeCourses} />} />
              <Route path={'/courses/scheduled'} render={() => <CourseCardGroup courses={scheduledCourses} />} />
              <Route path={'/courses/expired'} render={() => <CourseCardGroup courses={expiredCourses} />} />
            </div>
          </CoursePageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};
export default CoursePage;
