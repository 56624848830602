import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, TypographyType } from '../../../components/atoms/Typography';
import { Button } from '../../../components/molecules';
import { SizeType, StatusType } from '../../../components/molecules/Button/type';
import { ApplicationAnswerResponse, TemplateItemFullResponse } from '../../../rest/generated';
import { ColorPaletteType } from '../../../styles/color';
import { ApplicationAnswerStatusType, StepListItem, StepListItemType } from '../type';
import { ReactComponent as ActiveButtonTooltip } from '../../../assets/svg/ico-start-tooltip.svg';
import { ReactComponent as EditIcon } from '../../../assets/svg/ico-edit.svg';
import { Model } from 'survey-core';

type ApplicationStepListItemProps = {
  item: TemplateItemFullResponse;
  answer?: ApplicationAnswerResponse;
  idx: number;
  applicationUuid: string;
  slugUrl: string;
  isActive: boolean;
  isFinal: boolean;
  isApplying: boolean;
  isRollingBaseProduct: boolean;
  isCDSProgram: boolean;
  isSubmitted: boolean;
};

const ApplicationStepListItem = ({
  idx,
  item,
  answer,
  applicationUuid,
  slugUrl,
  isActive,
  isFinal,
  isApplying,
  isRollingBaseProduct,
  isCDSProgram,
  isSubmitted
}: ApplicationStepListItemProps) => {
  const history = useHistory();
  const location = useLocation();
  const urlQueries = location.search;
  const title = item?.survey?.displayTitle || ' ';
  const order = idx + 1;
  const isDone = answer?.status === ApplicationAnswerStatusType.SUBMITTED;
  const isInProgress = answer?.status === ApplicationAnswerStatusType.IN_PROGRESS;

  const type: StepListItemType = useMemo(() => {
    if (!isApplying) return isDone ? StepListItem.SUBMITTED : StepListItem.NONE;

    if (isInProgress) return StepListItem.IN_PROGRESS;
    if (isActive) return StepListItem.ACTIVE;
    if (!isDone) return StepListItem.NONE;

    if (!item.survey?.test) return StepListItem.EDITABLE;
    if (!item.survey?.hasNoTestPage) return StepListItem.SUBMITTED;

    if (!item.survey.definition || !answer?.answer) return StepListItem.SUBMITTED;

    const survey = new Model(item.survey.definition);
    survey.data = answer.answer;
    const noTestPage = survey.getPageByName('noTestPage');
    const isNoTestPageVisible = noTestPage?.getPropertyByName('isVisible');

    return isNoTestPageVisible ? StepListItem.EDITABLE : StepListItem.SUBMITTED;
  }, [isApplying, isActive, item, answer]);

  const buttonStyle = (() => {
    switch (type) {
      case StepListItem.IN_PROGRESS:
        return {
          text: '작성 중',
          className: 'in-progress',
          disabled: false
        };
      case StepListItem.ACTIVE:
        return {
          text: '시작',
          className: 'active',
          disabled: false
        };
      case StepListItem.SUBMITTED:
        return {
          text: '완료',
          className: 'submit',
          disabled: true
        };
      case StepListItem.EDITABLE:
        return {
          text: '완료',
          className: 'edit',
          disabled: true
        };
      case StepListItem.NONE:
        return {
          text: '대기',
          className: 'none',
          disabled: true
        };
    }
  })();

  const handleButtonClick = (from: 'edit' | 'notEdit') => {
    const editableState: StepListItemType[] = [StepListItem.ACTIVE, StepListItem.IN_PROGRESS, StepListItem.EDITABLE];
    if (!editableState.includes(type)) return;

    history.push({
      pathname: `/application/${slugUrl}/${isFinal ? 'final' : order}${urlQueries}`,
      state: { uuid: applicationUuid, from }
    });
  };

  return (
    <div className={`application-step-list__item ${buttonStyle.className}`} key={order}>
      <div className="application-step-list__text">
        <Typography className="inline" type={TypographyType.B1} color={ColorPaletteType.GRAY1} text={`${order}. `} />
        &nbsp;
        <Typography className="inline" type={TypographyType.B1} color={ColorPaletteType.GRAY1} text={title} />
        {!isSubmitted && (!isRollingBaseProduct || !isCDSProgram) && (
          <div
            className={`application-step-list__edit-button ${buttonStyle.className}`}
            onClick={() => handleButtonClick('edit')}
          >
            <EditIcon width={16} height={16} />
          </div>
        )}
      </div>

      <div className="application-step-list__button-wrapper">
        <Button
          className={`application-step-list__button ${buttonStyle.className}`}
          size={SizeType.SMALL}
          status={StatusType.DEFAULT}
          children={<Typography type={TypographyType.B1} color={ColorPaletteType.GRAY2} text={buttonStyle.text} />}
          disabled={buttonStyle.disabled}
          onClick={() => handleButtonClick('notEdit')}
        />
        {!isCDSProgram && (
          <div className={`application-step-list__tooltip ${buttonStyle.className}`}>
            <ActiveButtonTooltip />
            <p>바로 작성해 볼까요?</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicationStepListItem;
