import { ZkStatus } from './../../apollo/attendance/type';
import { useAttendanceStatus } from './../../contexts/AttendanceStatus';
import { useCredential } from './../../../../contexts/credential';
import { useEffect, useRef } from 'react';

export const useController = () => {
  const { handleAttendanceLoginPopup, isAttendanceLoginPopupOpen } = useCredential();
  const { zkType, isUserCheckIn, setIsCheckInZkPopupOpen, handleCheckIn } = useAttendanceStatus();
  const closeAlert = () => {
    handleAttendanceLoginPopup(false);
  };
  const handleLoginToggleSwitch = () => {
    if (isAttendanceLoginPopupOpen) {
      //? 로그인시 입실 독려 팝업이 노출 되어 있을 경우
      handleCheckIn();
      setTimeout(() => {
        handleAttendanceLoginPopup(false);
        setIsCheckInZkPopupOpen(true);
      }, 1000);
      setTimeout(() => {
        setIsCheckInZkPopupOpen(false);
      }, 3000);
      return;
    }
  };

  function useOutsideAlerter(ref: any) {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleAttendanceLoginPopup(false);
      }
    }

    useEffect(() => {
      if (isAttendanceLoginPopupOpen && zkType === ZkStatus.ACTIVE && isUserCheckIn) {
        handleAttendanceLoginPopup(false);
      }
    }, [zkType]);

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return {
    closeAlert,
    wrapperRef,
    handleLoginToggleSwitch
  };
};
