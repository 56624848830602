import React from 'react';
import styled from '@emotion/styled';
import { Modal } from 'antd';

import { useController } from './controller';
import { GeneralTemplate } from '../../templates';

import {
  Typography,
  TypographyType
} from '../../components/atoms/Typography';
import { Button } from '../../components/molecules/Button';
import {
  SizeType,
  StatusType
} from '../../components/molecules/Button/type';
import { Checkbox } from '../../components/molecules/Input/Checkbox';
import { Divider } from '../../components/atoms';
import { ColorPalette, ColorPaletteType } from '../../styles/color';


const TermPageWrapper = styled.div`
  margin: auto;
  margin-top: 163px;
  width: 310px;
  border: 1px solid ${ColorPalette.Gray5};
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 0px 22px 0px 22px;
  .term-form__title {
    text-align: center;
    margin-top: 42px;
  }
  .term-form__description {
    text-align: center;
    margin: 22px 0px 27px 0px;
  }
  .term-form__all-check {
    margin: 10px 0px 8px 0px;
  }
  .term-form__check-area {
    margin: 11px 0px 8px 0px;
  }
  .term-form__message-reminder {
    text-align: center;
    margin-top: 23px;
  }
  .term-form__confirm-area {
    margin-top: 42px;
    margin-bottom: 22px;
  }

  @media screen and (max-width: 375px) {
    margin-top: 75px;
  }
`;
const ErrorModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .modal-error-message {
    margin-top: 25px;
    text-align: center;
    max-width: 200px;
  }
  .modal-ok-button {
    &:hover {
      cursor: pointer;
    }
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 300px;
    height: 52px;
    background-color: ${ColorPalette.Gray1};
  }
`;

const TermPage: React.FC = () => {
  const controller = useController();
  return (
    <GeneralTemplate>
      {{
        body: (
          <TermPageWrapper>
            <div className="term-form__title">
              <Typography
                type={TypographyType.H1}
                color={ColorPaletteType.GRAY1}
                text="필수 약관 동의"
              />
            </div>
            <div className="term-form__description">
              <Typography
                type={TypographyType.B2_Bold}
                color={ColorPaletteType.LIGHT_BLUE}
                text="회원님의 편리한 서비스 이용을 도와드리기 위해"
              />
              <Typography
                type={TypographyType.B2_Bold}
                color={ColorPaletteType.LIGHT_BLUE}
                text="약관 동의 요청 드립니다."
              />
            </div>
            <Divider length="265px" color={ColorPalette.Gray4} />
            <div className="term-form__all-check">
              <Checkbox
                onChange={() => {
                  controller.checkAll();
                }}
                checked={controller.checkedAll}
                children={
                  <Typography
                    type={TypographyType.B1_Bold}
                    color={ColorPaletteType.GRAY2}
                    text="모두 동의하기"
                  />
                }
              />
            </div>
            <Divider length="265px" color={ColorPalette.Gray4} />
            <div className="term-form__check-area">
              <Checkbox
                className="term-form__checkbox"
                checked={controller.requiredAgree}
                onChange={() => {
                  controller.setRequiredAgree(!controller.requiredAgree);
                }}
                children={
                  <a
                    href="https://help.codestates.com/hc/ko/articles/900002811746"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography
                      type={TypographyType.B2}
                      color={ColorPaletteType.GRAY2}
                      text="서비스 이용약관 + 개인정보 취급방침 (필수)"
                    />
                  </a>
                }
              />

              <Checkbox
                className="term-form__checkbox"
                checked={controller.receivingMessagesAgreement}
                onChange={() => {
                  controller.setReceivingMessagesAgreement(
                    !controller.receivingMessagesAgreement
                  );
                }}
                children={
                  <Typography
                    type={TypographyType.B2}
                    color={ColorPaletteType.GRAY2}
                    text="문자 및 이메일 수신동의 (선택)"
                  />
                }
              />

              <Checkbox
                className="term-form__checkbox"
                checked={controller.marketingUseAgreement}
                onChange={() => {
                  controller.setMarketingUseAgreement(
                    !controller.marketingUseAgreement
                  );
                }}
                children={
                  <Typography
                    type={TypographyType.B2}
                    color={ColorPaletteType.GRAY2}
                    text="마케팅 활용 동의 (선택)"
                  />
                }
              />
            </div>
            <Divider length="265px" color={ColorPalette.Gray4} />
            <div className="term-form__message-reminder">
              <Typography
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY2}
                text="서비스 이용을 위한 필수사항들은 이메일/"
              />
              <Typography
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY2}
                text="문자를 통해 안내될 수 있습니다."
              />
            </div>
            <div className="term-form__confirm-area">
              <Button
                status={StatusType.ACTIVE}
                size={SizeType.BASIC}
                disabled={controller.submitTermsLoading}
                onClick={controller.submitTermsForm}
              >
                <Typography
                  type={TypographyType.B1}
                  color={ColorPaletteType.WHITE}
                  text="확인"
                />
              </Button>
            </div>
            <Modal
              centered={true}
              cancelButtonProps={{ style: { display: 'none' } }}
              visible={!!controller.errorMessage}
              footer={false}
              closeIcon={<></>}
              width="334px"
            >
              <ErrorModalContentWrapper>
                <Typography
                  className="modal-error-message"
                  type={TypographyType.B2}
                  color={ColorPaletteType.GRAY2}
                  text={controller.errorMessage}
                />
                <div
                  className="modal-ok-button"
                  onClick={controller.okErrorModal}
                >
                  <Typography
                    type={TypographyType.B1}
                    color={ColorPaletteType.WHITE}
                    text="확인"
                  />
                </div>
              </ErrorModalContentWrapper>
            </Modal>
          </TermPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default TermPage;
