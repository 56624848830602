/* eslint-disable react-hooks/rules-of-hooks */
import { makeToast } from './../../util/makeToast';
import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Modal } from 'antd';

import { useCredential } from '../../contexts/credential';

import { MYPAGE_GQL } from '../../apollo/mypage/query';
import { REMOVE_SOCIAL_ACCOUNT_GQL } from '../../apollo/mypage/mutation';
import { ATTEND_WORK_GQL, LEAVE_WORK_GQL } from '../../apollo/attendance/mutation';
import { SocialProvider } from '../../apollo/mypage/type';

import { SocialAuthURI } from '../../util';

import type { PaymentOrder } from '../../apollo/payment/type';
import type { AttendWorkData, LeaveWorkData } from '../../apollo/attendance/type';
import type {
  Application,
  MyPageData,
  RemoveSocialAccountData,
  RemoveSocialAccountVars
} from '../../apollo/mypage/type';

import { sendPageView } from '../../util/marketingTools/googleTagAssistant';

// TODO : commonn domain으로 수정
import type { Cohort } from '../../domain/lms/apollo/course/type';

const { KakaoAuth } = SocialAuthURI;

const SEB_PROGRAM_ID = 1;
const GMB_PROGRAM_ID = 2;
const DS_PROGRAM_ID = 3;
const PMB_PROGRAM_ID = 4;
const DA_PROGRAM_ID = 5;
const KDT_AIB_PROGRAM_ID = 6;
const KDT_BEB_PROGRAM_ID = 7;
const KDT_DEVOPS_PROGRAM_ID = 11;
const KDT_SEB_FE_PROGRAM_ID = 16;
const KDT_SEB_BE_PROGRAM_ID = 17;

const getSurveyLink = (programId: number) => {
  switch (programId) {
    case SEB_PROGRAM_ID:
      return 'https://docs.google.com/forms/d/e/1FAIpQLSe29aYEu8hEFzuxCS3xw2_2IZkxBE0OeNv3YF7X00c_I86tvg/viewform';

    case DS_PROGRAM_ID:
      return 'https://docs.google.com/forms/d/e/1FAIpQLSfjYtFOr8xClz9BBaJ8U0qIQqzJJCDl9-4ujJkR7lKtc6E_VA/viewform';

    case PMB_PROGRAM_ID:
      return 'https://docs.google.com/forms/d/17QB9tFbczu1U27nyhD5y6v0-f0O9YlQAEVXxIz5PCpU/viewform?edit_requested=true';

    case DA_PROGRAM_ID:
      return 'https://docs.google.com/forms/d/e/1FAIpQLSexFOSb2rkG_ZymKVaiYsgj41ai410zCd9LvUQjvWjhiYfsmQ/viewform?usp=sf_link';

    default:
      return '';
  }
};

export const useController = () => {
  /* state */
  const [cohorts, setCohorts] = useState<Cohort[]>([]);
  const [paymentOrders, setPaymentOrders] = useState<PaymentOrder[]>([]);
  const [applications, setApplications] = useState<Application[]>([]);

  const [githubAuthText, setGithubAuthText] = useState<string>('Github 인증 완료');
  const [isToggleAuthCheckIcon, setIsToggleAuthCheckIcon] = useState<boolean>(false);

  /* query & mutation */
  const {
    data,
    error: mypageError,
    loading: mypageLoading
  } = useQuery<MyPageData>(MYPAGE_GQL, {
    fetchPolicy: 'network-only'
  });

  const [removeSocialAccount] = useMutation<RemoveSocialAccountData, RemoveSocialAccountVars>(
    REMOVE_SOCIAL_ACCOUNT_GQL
  );

  const [urclassAttendWork] = useMutation<AttendWorkData>(ATTEND_WORK_GQL);
  const [urclassLeaveWork] = useMutation<LeaveWorkData>(LEAVE_WORK_GQL);

  const { profile, loadingProfile, refetchProfile } = useCredential();

  const handleLeaveWork = () => {
    Modal.confirm({
      title: '수강생 퇴근',
      content: '지금 퇴근 하시겠습니까?',
      onOk: async () => {
        try {
          await urclassLeaveWork();
          refetchProfile();
          makeToast({
            type: 'success',
            content: '정상 퇴근 처리 되었습니다. 오늘도 수고하셨습니다.'
          });
        } catch {
          makeToast({ type: 'error', content: '퇴근 처리에 문제가 있습니다. 관리자에게 문의해주세요.' });
        }
      }
    });
  };

  const handleAttendWork = async () => {
    Modal.confirm({
      title: '수강생 출근',
      content: '지금 출근 하시겠습니까?',

      onOk: async () => {
        try {
          await urclassAttendWork();
          refetchProfile();
          makeToast({
            type: 'success',
            content: '정상 출근 처리 되었습니다. 오늘도 수고하셨습니다.'
          });
        } catch {
          makeToast({ type: 'error', content: '출근 처리에 문제가 있습니다. 관리자에게 문의해주세요.' });
        }
      }
    });
  };

  const handleDeleteAccount = async () => {
    try {
      const { data } = await removeSocialAccount({
        variables: { service: SocialProvider.GITHUB }
      });

      if (data && data.urclassRemoveSocialAccount) {
        makeToast({
          type: 'success',
          content: '계정이 삭제 되었습니다.'
        });
        refetchProfile();
        return;
      }
      makeToast({ type: 'error', content: '계정 삭제에 문제가 있습니다. 다시 시도해 주세요.' });
    } catch (error) {
      makeToast({ type: 'error', content: '계정 삭제에 문제가 있습니다. 다시 시도해 주세요.' });
    }
  };

  const handleChangeGithubAuthText = (text: string) => {
    setGithubAuthText(text);
  };

  const handleChangeIsToogleCheckIcon = () => {
    setIsToggleAuthCheckIcon(!isToggleAuthCheckIcon);
  };

  const handleKakaoAuthentication = () => {
    localStorage.removeItem('authenticationType');
    window.location.assign(KakaoAuth);
  };

  const isGithubAccountNeed = !!applications.filter(
    application =>
      application.product.programId === SEB_PROGRAM_ID ||
      application.product.programId === DS_PROGRAM_ID ||
      application.product.programId === KDT_AIB_PROGRAM_ID ||
      application.product.programId === KDT_BEB_PROGRAM_ID ||
      application.product.programId === KDT_DEVOPS_PROGRAM_ID ||
      application.product.programId === KDT_SEB_FE_PROGRAM_ID ||
      application.product.programId === KDT_SEB_BE_PROGRAM_ID
  ).length;

  const isGoogleAuthenticated = !!profile?.googleId;
  /* effect */
  useEffect(() => {
    if (data) {
      const { urclassMyCohorts, urclassMyApplications, urclassMyPaymentOrders } = data;

      setCohorts(urclassMyCohorts);
      setPaymentOrders(urclassMyPaymentOrders);
      setApplications(urclassMyApplications);
    }
  }, [data, mypageLoading]);

  useEffect(() => {
    sendPageView('mypage');
  }, []);

  const hasCertIssuableCohort = cohorts.filter(cohort => cohort.certIssuable).length > 0;

  return {
    applications,

    isGithubAccountNeed,

    githubAuthText,
    handleChangeGithubAuthText,

    isToggleAuthCheckIcon,
    handleChangeIsToogleCheckIcon,

    isGoogleAuthenticated,
    cohorts,
    paymentOrders,
    hasCertIssuableCohort,

    mypageError,
    mypageLoading,

    profile,
    loadingProfile,
    handleAttendWork,
    handleLeaveWork,

    handleDeleteAccount,
    handleKakaoAuthentication
  };
};
