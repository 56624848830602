import queryAxios from '../../query/queryAxios';
import { ApplicationAnswerWithoutResponse, ApplicationFullResponse } from '../../rest/generated';

export interface CreateApplicationAnswerBySurveyIdRequest {
  applicationUuid: string;
  userUuid: string;
  itemId?: number;
}
export interface GetAdmissionApplicationByUserUuidAndApplicationUuidRequest {
  applicationUuid: string;
  userUuid: string;
}

export const getAdmissionApplicationByUserUuidAndApplicationUuidRequest = async (
  request: GetAdmissionApplicationByUserUuidAndApplicationUuidRequest
) => {
  const { userUuid, applicationUuid } = request;
  return await queryAxios()
    .get<ApplicationFullResponse>(`applicants/${userUuid}/applications/${applicationUuid}`)
    .then(data => data.data);
};

export const createApplicationAnswerRequest = async (request: CreateApplicationAnswerBySurveyIdRequest) => {
  const { userUuid, applicationUuid, itemId } = request;
  return await queryAxios().post<ApplicationAnswerWithoutResponse>(
    `applicants/${userUuid}/applications/${applicationUuid}/template-items/${itemId}/responses`
  );
};
