import axios, { AxiosInstance } from 'axios';
import { TOKEN_KEY } from '../hooks/useAuth/jwtTokenParser';
import { store } from '../util';
import Urls from '../util/urls';

export const API_ENDPOINT = Urls().javaAdmission;

const baseURL = `${API_ENDPOINT}`;

const queryAxios = () => {
  const instance: AxiosInstance = axios.create({ baseURL });

  // get the authentication token from local storage if it exists
  const token = store.admissionToken.get();

  instance.defaults.headers.common['Authorization'] = `Bearer ${token || undefined}`;

  instance.interceptors.request.use(config => {
    config.paramsSerializer = params => {
      const leafNodes: { key: string; value: any }[] = [];
      const getLeaf = (node: { [key: string]: any }) => {
        for (const key in node) {
          if (typeof node[key] === null || undefined) return;
          else if (typeof node[key] === 'object') {
            if (Array.isArray(node[key])) {
              node[key].forEach((value: any) => {
                leafNodes.push({ key: key, value: value });
              });
            } else getLeaf(node[key]);
          } else leafNodes.push({ key: key, value: node[key] });
        }
      };
      getLeaf(params);

      return leafNodes.map(({ key, value }) => `${key}=${value !== undefined || null ? value : ''}`).join('&');
    };
    return config;
  });
  return instance;
};

export default queryAxios;
