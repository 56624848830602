import gql from 'graphql-tag';

export const MY_CLASSROOMS_STATUS_GQL = gql`
  query urclassMyClassroomsStatus {
    urclassMyClassroomsStatus {
      totalCodeProblemCount
      restCodeProblemCount
    }
  }
`;

export const MY_CLASSROOMS_GQL = gql`
  query urclassMyClassrooms($filter: ClassroomFilter) {
    urclassMyClassrooms(filter: $filter) {
      id
      title
      description
      imageURL
      totalCodeProblemCount
      restCodeProblemCount
      dueDate
    }
  }
`;

export const MY_CLASSROOM_STATUS_GQL = gql`
  query urclassMyClassroomStatus($classroomId: Int!) {
    urclassMyClassroomStatus(classroomId: $classroomId) {
      id
      title
      description
      imageURL
      totalCodeProblemCount
      restCodeProblemCount
      dueDate
    }
  }
`;

export const CODE_PROBLEMS_GQL = gql`
  query urclassMyCodeProblems($page: Int, $perPage: Int, $filter: CodeProblemsFilter) {
    urclassMyCodeProblems(page: $page, perPage: $perPage, filter: $filter) {
      total
      items {
        id
        uuid
        title
        status
        updatedAt
        isPassed
        passedCount
        failedCount
      }
    }
  }
`;

export const CODE_PROBLEM_GQL = gql`
  query urclassCodeProblem($codeProblemUuid: String!) {
    urclassMyCodeProblem(codeProblemUuid: $codeProblemUuid) {
      id
      uuid
      classroomId
      previousCodeProblemUuid
      nextCodeProblemUuid
      language
      title
      description
      templateCode
      myCode
      isPassed
      isSubmitted
      submittedAt
      status
    }
  }
`;
