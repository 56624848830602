interface ISprintListEntry {
  title?: string;
  uuid?: string;
  type: string;
  isUserCompleted: boolean;
}

export const contentLearningProgress = (sprintList: ISprintListEntry[]) => {
  let total: number = 0;
  let pass: number = 0;

  sprintList.forEach((contentItem: ISprintListEntry) => {
    const { type, isUserCompleted } = contentItem;

    if (isUserCompleted) {
      pass = pass + 1;
    }

    if (type !== 'separator') {
      total = total + 1;
    }
  });

  return Math.round((pass / total) * 100);
};
