import gql from 'graphql-tag';

export const CHECK_IN_REQUEST_GQL = gql`
  mutation urclassCheckIn {
    urclassCheckIn {
      userId
      name
      checkInOutConfig {
        isCheckIn
        zkStatus
      }
      inActiveDayType
      bootcamp {
        id
        name
        isConfigSet
        trainingStartDate
        trainingEndDate
        isTrainingNow
        isActiveAttendanceSystem
      }
      myCheckInHistoryList {
        items {
          id
          uuid
          revisionedAt
          category
          ip
          userAgent
          clientType
          clientName
          deviceInformation
          os
        }
        total
      }
    }
  }
`;
export const CHECK_OUT_REQUEST_GQL = gql`
  mutation urclassCheckOut {
    urclassCheckOut {
      userId
      name
      checkInOutConfig {
        isCheckIn
        zkStatus
      }
      inActiveDayType
      bootcamp {
        id
        name
        isConfigSet
        trainingStartDate
        trainingEndDate
        isTrainingNow
        isActiveAttendanceSystem
      }
      myCheckInHistoryList {
        items {
          id
          uuid
          revisionedAt
          category
          ip
          userAgent
          clientType
          clientName
          deviceInformation
          os
        }
        total
      }
    }
  }
`;
