import React from 'react';
import styled from '@emotion/styled';

interface IStyledGrid {
  width: number;
  gap?: number;
  rowGap?: number;
  columnGap?: number;
}

interface IGirdProps {
  itemWidth: number;
  gridGap?: number;
  rowGap?: number;
  columnGap?: number;
  children: React.ReactNode;
}

const StyledGrid = styled.div<IStyledGrid>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${({ width }) => width}px, 1fr));
  grid-auto-columns: minmax(316px, 316px);
  grid-gap: ${({ gap }) => (gap ? `${gap}px` : '0px')};
  row-gap: ${({ rowGap }) => (rowGap ? `${rowGap}px` : '')};
  column-gap: ${({ columnGap }) => (columnGap ? `${columnGap}px` : '')};

  @media screen and (max-width: 800px) {
    justify-content: center;
  }
`;

export const Grid: React.FC<IGirdProps> = ({ itemWidth, gridGap, children, rowGap, columnGap }) => {
  return (
    <StyledGrid width={itemWidth} gap={gridGap} rowGap={rowGap} columnGap={columnGap}>
      {children}
    </StyledGrid>
  );
};
