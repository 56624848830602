import gql from 'graphql-tag';

export const MY_LEARNING_STATUS_GQL = gql`
  query urclassMyLearning($page: Int, $perPage: Int, $query: MyCheckInHistoryListQuery) {
    urclassMyLearning {
      userId
      name
      inActiveDayType
      attendanceType
      checkInOutConfig {
        isCheckIn
        zkStatus
      }
      qrConfig {
        qrCodeImageUrl
        qrStatus
      }
      bootcamp {
        id
        name
        isConfigSet
        trainingStartDate
        trainingEndDate
        isTrainingNow
        isActiveAttendanceSystem
      }
      myCheckInHistoryList(page: $page, perPage: $perPage, query: $query) {
        total
        items {
          id
          uuid
          revisionedAt
          category
          ip
          userAgent
          clientType
          clientName
          deviceInformation
          os
        }
      }
    }
  }
`;

export const GET_CERTIFICATIES_GQL = gql`
  query urclassMyIssuableCertificates($bootcampId: Int!) {
    urclassMyIssuableCertificates(bootcampId: $bootcampId) {
      name
      bootcampName
    }
  }
`;
