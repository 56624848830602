import styled from '@emotion/styled';
import { ReactComponent as ArrowRight } from '../../../assets/svg/arrow-right-formpage.svg';
import { TemplateItemFullResponse } from '../../../rest/generated';
import { ColorPalette } from '../../../styles/newColor';
import HeaderProgressCircle from './HeaderProgressCircle';

interface SurveyBoardHeaderProps {
  productName?: string;
  displayTitle?: string;
  isNextBtnVisible: boolean;
  isLastSurvey: boolean;
  isMobile: boolean;
  handleNextStepButtonClick: () => void;
  templateItems?: TemplateItemFullResponse[];
  finalOrOrder: number;
  from: 'edit' | 'notEdit';
}

const BoardHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 155px;
  background: #ffffff;
  box-shadow: 10.65px 10.58px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;

  padding: 14px 16px 10px;

  @media screen and (min-width: 992px) {
    padding: 24px;
  }

  .form-page__product-info {
    display: flex;
    flex-direction: column;
    position: relative;
    @media screen and (min-width: 992px) {
      flex-direction: row;
    }
    .form-page__h3-wrapper {
      @media screen and (min-width: 992px) {
        padding: 6px 0 6px;
        display: flex;
        align-items: center;
      }

      h3 {
        position: absolute;

        top: -34px;
        left: -8px;
        display: flex;
        align-items: center;
        padding: 2px 8px;
        background: #efedfc;
        width: fit-content;
        white-space: nowrap;
        border: 1px solid #d8d3f8;
        border-radius: 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.025em;
        color: ${ColorPalette.CozPurple600};

        @media screen and (min-width: 992px) {
          margin: 0;
          position: relative;
          top: 0;
          left: 0;
          margin: auto 8px auto 0;
          border: none;
          padding: 4px 12px 4px;
          height: 29px;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    h2 {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      letter-spacing: -0.025em;
      margin-bottom: 8px;
      color: ${ColorPalette.Gray900};
      @media screen and (min-width: 992px) {
        position: relative;

        width: fit-content;
        margin: 0;

        font-size: 20px;
        line-height: 30px;
      }
    }

    .bar {
      display: none;
      @media screen and (min-width: 992px) {
        display: inline-block;
        margin: auto 24px;
        height: 14px;
        width: 1px;
        background: #ccd3e0;
      }
    }
  }
  .form-page__admission-progress {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .form-page__btn-box {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const NextButton = styled.button`
  height: 40px;
  border-radius: 6px;
  background: #452cdd;
  padding: 8px 16px;
  font-weight: 700;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  > span {
    margin-top: 1px;
  }

  svg {
    margin-left: 8px;
    margin-top: 0px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const SurveyBoardHeader = ({
  productName,
  displayTitle,
  isNextBtnVisible,
  handleNextStepButtonClick,
  isLastSurvey,
  isMobile,
  templateItems,
  finalOrOrder,
  from
}: SurveyBoardHeaderProps) => {
  templateItems?.sort((a, b) => a.sequence - b.sequence);
  return (
    <BoardHeader>
      <div className="form-page__product-info">
        <div className="form-page__h3-wrapper">
          <h3>{productName}</h3>
        </div>
        <h2>{displayTitle}</h2>
        {!templateItems || templateItems.length <= 1 || from === 'edit' ? null : (
          <>
            <div className="bar" />
            <ul className="form-page__admission-progress">
              {templateItems.map((item: TemplateItemFullResponse, index: number) => (
                <HeaderProgressCircle
                  isNextBtnVisible={isNextBtnVisible}
                  key={item.id}
                  item={item}
                  stepsLength={templateItems.length}
                  step={index + 1}
                  finalOrOrder={isNaN(finalOrOrder) ? templateItems.length : finalOrOrder}
                />
              ))}
            </ul>
          </>
        )}
      </div>

      {!isMobile && isNextBtnVisible ? (
        <div className="form-page__btn-box">
          <NextButton onClick={handleNextStepButtonClick}>
            <span>{isLastSurvey ? '마이페이지로' : '다음 단계로'}</span>
            <ArrowRight />
          </NextButton>
        </div>
      ) : null}
    </BoardHeader>
  );
};

export default SurveyBoardHeader;
