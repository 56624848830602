import gql from "graphql-tag";

export const MY_COHORTS_GQL = gql`
  query urclassMyCohorts {
    urclassMyCohorts {
      id
      uuid
      name
      cohortType
      startDate
      endDate
      certIssuable
      certEduStartDate
      certEduEndDate
      certCourseName
      certCourseDesc
      useAttendanceCertificationFeature
      courses {
        id
        uuid
        name
        description
      }
    }
  }
`;

export const GET_ALL_COURSES_GQL = gql`
  {
    courses {
      id
      name
      description
      isActive
    }
  }
`;

export const GET_COURSES_OF_USER_GQL = gql`
  query urclassCoursesOfUser {
    urclassCoursesOfUser {
      id
      uuid
      name
      description
      status
    }
  }
`;

//해당 코스의 playlist 목록을 가져옴
export const GET_PLAYLIST_GQL = gql`
  query course($id: Int) {
    course(id: $id) {
      id
      name
      playlists {
        id
        name
        description
        thumbnailUrl
        isPublished
        userPlaylistProgress
        # playlist_content {
        #   id
        #   content {
        #     id
        #     version {
        #       id
        #       type
        #     }
        #   }
        #   isUserCompleted
        # }
      }
      description
      metadata
    }
  }
`;

export const GET_PLAYLIST_CONTENTS_GQL = gql`
  query playlist($id: Int) {
    playlist(id: $id) {
      id
      name
      course {
        id
        name
      }
      playlist_content {
        id
        content {
          id
          uuid
          version {
            id
            title
            type
          }
          isPublished
        }
        isUserCompleted
        order
      }
    }
  }
`;
