import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import LoadingPage from '../../components/LoadingPage';
import { useCredential } from '../../contexts/credential';

import {
  LOGIN_GOOGLE_GQL,
  LOGIN_NAVER_GQL,
  ADD_SOCIAL_ACCOUNT_GQL,
  LOGIN_KAKAO_GQL
} from '../../apollo/account/mutation';
import { SocialProvider } from '../../apollo/account/type';

import { store } from '../../util';
import { getServerError } from '../../util/errors/serverErrors';

import type {
  LoginGoogleVars,
  LoginGoogleData,
  LoginNaverData,
  LoginNaverVars,
  LoginKakaoData,
  LoginKakaoVars,
  AddSocialAccountData,
  AddSocialAccountVars
} from '../../apollo/account/type';
import { makeToast } from '../../util/makeToast';
import { dataLayerForSigninEvent } from '../../util/marketingTools/datalayerGa4';

const LoginRedirectPage = () => {
  const history = useHistory();
  const { login, refetchProfile } = useCredential();
  const urlParse = new URLSearchParams(history.location.search);
  const clientUrl = window.location.origin;
  const code = urlParse.get('code') || '';
  const socialProvider = urlParse.get('social_provider');

  const [loginGoogle] = useMutation<LoginGoogleData, LoginGoogleVars>(LOGIN_GOOGLE_GQL);

  const [loginNaver] = useMutation<LoginNaverData, LoginNaverVars>(LOGIN_NAVER_GQL);

  const [loginKakao] = useMutation<LoginKakaoData, LoginKakaoVars>(LOGIN_KAKAO_GQL);

  const [addSocialAccount] = useMutation<AddSocialAccountData, AddSocialAccountVars>(ADD_SOCIAL_ACCOUNT_GQL);

  useEffect(() => {
    const authenticationType = localStorage.getItem('authenticationType');

    switch (socialProvider) {
      case 'google':
        authenticationType === 'login'
          ? googleSignIn(code)
          : addAccount(SocialProvider.GOOGLE, `${clientUrl}/auth?social_provider=${socialProvider}`, code);
        break;
      case 'kakao':
        authenticationType === 'login'
          ? kakaoSignIn(code)
          : addAccount(SocialProvider.KAKAO, `${clientUrl}/auth?social_provider=${socialProvider}`, code);
        break;
      case 'github':
        authenticationType === 'login'
          ? githubSignIn(code)
          : addAccount(SocialProvider.GITHUB, `${clientUrl}/auth?social_provider=${socialProvider}`, code);
        break;

      case 'naver':
        authenticationType === 'login'
          ? naverSignIn(code)
          : addAccount(SocialProvider.NAVER, `${clientUrl}/auth?social_provider=${socialProvider}`, code);
        break;
      default:
        history.replace('/login', {
          errorMessage: '지원하지 않는 로그인 유형 입니다'
        });
        break;
    }
  }, []);

  const kakaoSignIn = async (code: string) => {
    try {
      const { data } = await loginKakao({
        variables: {
          code,
          redirectURI: `${clientUrl}/auth?social_provider=kakao`
        }
      });

      if (!(data && data.urclassLoginKakao)) {
        history.replace('/login', {
          errorMessage: '로그인 과정에 문제가 있습니다.'
        });
        return;
      }

      const { token } = data.urclassLoginKakao;
      const { pathname, search } = store.redirectURL.get();
      dataLayerForSigninEvent({ data, urlQueries: search });

      login(token);

      if (pathname) {
        history.replace({ pathname, search });
        return;
      }

      history.replace('/');
    } catch (error) {
      const socialProfile = error.graphQLErrors[0].extensions.socialProfile;

      if (error.message.indexOf('login-failed') !== -1) {
        history.replace('/additional', {
          ...socialProfile,
          service: SocialProvider.KAKAO
        });
      } else if (error.message.indexOf('need-identity-verification') !== -1) {
        const uuid = error.graphQLErrors[0].extensions.exception.uuid;

        history.replace('/identity-verification', { uuid });
      } else {
        const serverError = getServerError(error.message);
        const errorMessage = serverError.message;

        history.replace('/login', { errorMessage });
      }
    }
  };

  const googleSignIn = async (code: string) => {
    try {
      const { data } = await loginGoogle({
        variables: {
          code,
          redirectURI: `${clientUrl}/auth?social_provider=google`
        }
      });

      if (!(data && data.urclassLoginGoogle)) {
        history.replace('/login', {
          errorMessage: '로그인 과정에 문제가 있습니다.'
        });
        return;
      }

      const { token } = data.urclassLoginGoogle;
      const { pathname, search } = store.redirectURL.get();

      login(token);

      /* AI 검증 */

      /*
      if (decoded.isAI) {
        return history.replace(
          `/otp?userId=${decoded.userId}&classAgentPK=${decoded.classAgentPK}`
        );
      }
      */

      if (pathname) {
        history.replace({ pathname, search });
        return;
      }
      history.replace('/');
    } catch (error) {
      if (error.message.indexOf('login-failed') !== -1) {
        const errorMessage =
          'Google 계정으로 가입은 더 이상 지원하지 않습니다. Kakao 계정으로 회원 가입을 진행해주세요.';

        history.replace('/login', { errorMessage });
      } else if (error.message.indexOf('need-identity-verification') !== -1) {
        const uuid = error.graphQLErrors[0].extensions.exception.uuid;
        history.replace('/identity-verification', { uuid });
      } else {
        const serverError = getServerError(error.message);
        const errorMessage = serverError.message;

        history.replace('/login', { errorMessage });
      }
    }
  };

  const githubSignIn = (code: string) => {
    const errorMessage = 'Github 로그인은 더이상 지원 하지 않습니다.';

    history.replace('/', { errorMessage });
  };

  const naverSignIn = async (code: string) => {
    try {
      const { data } = await loginNaver({
        variables: {
          code,
          redirectURI: `${clientUrl}/auth?social_provider=naver`
        }
      });

      if (!(data && data.urclassLoginNaver)) {
        history.replace('/login', {
          errorMessage: '로그인 과정에 문제가 있습니다.'
        });

        return;
      }

      const { token } = data.urclassLoginNaver;
      const { pathname, search } = store.redirectURL.get();

      login(token);

      /* AI 검증 */
      /*
      if (decoded.isAI) {
        return history.replace(
          `/otp?userId=${decoded.userId}&classAgentPK=${decoded.classAgentPK}`
        );
      }
      */

      if (pathname) {
        history.replace({ pathname, search });
        return;
      }

      history.replace('/');
    } catch (error) {
      const socialProfile = error.graphQLErrors[0].extensions.socialProfile;

      if (error.message.indexOf('login-failed') !== -1) {
        history.replace('/additional', {
          ...socialProfile,
          service: SocialProvider.NAVER
        });
        return;
      } else {
        const serverError = getServerError(error.message);
        const errorMessage = serverError.message;

        history.replace('/login', { errorMessage });
      }
    }
  };

  const addAccount = async (service: SocialProvider, redirectURI: string, code: string) => {
    try {
      const { data } = await addSocialAccount({
        variables: { service, redirectURI, code }
      });

      if (!(data && data.urclassAddSocialAccount)) {
        makeToast({ type: 'error', content: '인증 과정에 문제가 있습니다.' });
        return;
      }
      makeToast({ type: 'success', content: '인증이 성공적으로 완료되었습니다.' });
      refetchProfile();
      history.replace('/mypage');
    } catch (error) {
      if (
        error.message.indexOf('already-used-google-account') !== -1 ||
        error.message.indexOf('already-used-github-account') !== -1 ||
        error.message.indexOf('already-used-kakao-account') !== -1
      ) {
        makeToast({
          type: 'error',
          content:
            '연결할 계정은 이미 가입된 계정으로, 홈페이지 내 문의하기 버튼을 통해 코스 선택 후 계정 연동 수정을 요청해 주세요.'
        });
        history.replace('/mypage');
        return;
      }
      makeToast({ type: 'error', content: '인증 과정에 문제가 있습니다.' });
      history.replace('/mypage');
    }
  };

  return <LoadingPage />;
};

export default LoginRedirectPage;
