import { ga } from 'react-ga';

export const sendPageView = (page: string) => {
  if (process.env.NODE_ENV === 'production') {
    ga('send', {
      hitType: 'pageview',
      page
    });
  }
};
