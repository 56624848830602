import React from 'react';

import BasicButton from '../../atoms/Button/BasicButton';

import { numberFormatter } from '../../../util';
import { DashboardEntry } from './style';

import type { PaymentModel } from '../../../apollo/product/type';

type IUpfrontSummary = {
  paymentModel: PaymentModel;
  handleCheckout?: () => void;
  loading: boolean;
};

const UpfrontSummary: React.FC<IUpfrontSummary> = ({
  paymentModel,
  handleCheckout,
  loading
}) => {
  return (
    <React.Fragment>
      <DashboardEntry>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <div className="payment-nav">총 상품 금액</div>
            <div className="payment-nav point">할인 금액</div>
          </div>
          <div style={{ flex: 1, textAlign: 'right' }}>
            <div className="payment-nav">
              {numberFormatter(paymentModel.originPrice)} 원
            </div>
            <div className="payment-nav point">
              {`(-)
        ${
          paymentModel &&
          numberFormatter(paymentModel.originPrice - paymentModel.price)
        } 
        원`}
            </div>
          </div>
        </div>
      </DashboardEntry>

      <DashboardEntry className="last-entry">
        <div className="total-price">
          <div className="total-price-nav">총 결제 금액</div>
          <div className="total-price-value">
            {numberFormatter(paymentModel.price)}원
          </div>
        </div>
        <BasicButton
          color="#00B9EE"
          style={{
            margin: '20px 0px 0px 0px',
            width: '100%'
          }}
          onClick={handleCheckout}
          size="large"
          loading={loading}
        >
          결제 하기
        </BasicButton>
      </DashboardEntry>
    </React.Fragment>
  );
};

export default UpfrontSummary;
