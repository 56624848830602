import styled from '@emotion/styled';
import { css } from '@emotion/react';

const unselectable = css`
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

export const CozSurveyTheme = styled.div`
  * {
    font-family: 'Spoqa Han Sans Neo', Helvetica, Arial, sans-serif;
  }

  .survey__character-counter {
    position: relative;
    right: 2px;
    top: -4px;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: #d6d6d6;
    text-align: right;

    &--focused {
      color: #295ce0;
    }
  }

  .sv-body__timer,
  .sv-progress {
    display: none;
  }

  .sv-page__title,
  .sv-panel__title {
    ${unselectable}
  }
  .sv-question {
    &__title,
    &__header,
    &__description {
      ${unselectable}
    }
    &__content {
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      li,
      label {
        ${unselectable}
      }
    }
  }

  .sv-question__content,
  .sv-completedpage {
    h1 {
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
    }
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
    }
    h5,
    span,
    div {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    h6 {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }

  div.sv-title.sv-container-modern__title {
    display: none;
  }

  .sv-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
  .sv-question__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
  .sv-description {
    color: #4f4f4f;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .sv-page__description {
    color: #4f4f4f;
    margin-top: -30px;
  }

  .sv-body__timer {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #333333;
  }
  .sv-progress,
  .sv-body__progress {
    position: relative;
    top: 40px;
    margin: 0 5%;
    background-color: #f2f2f2;
    height: 10px;
    border-radius: 5px;
  }
  .sv-progress__bar {
    border-radius: 5px;
    background-color: #452cdd;
  }
  .sv-progress__text {
    position: relative;
    top: -42px;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #858585;
  }

  .sv-text,
  .sv-dropdown {
    width: 280px;

    &:focus {
      border-color: #295ce0;
    }
  }

  .sv-text::placeholder {
    color: #a3a3a3;
  }

  .sv-root-modern {
    width: 100%;

    .sv-item__control:focus + .sv-item__decorator {
      border: 1px solid rgba(41, 92, 224, 0.3);
    }
    .sv-radio__svg {
      background: #f2f2f2;
      border: 2px solid #d6d6d6;
      border-radius: 100%;
      fill: transparent;
    }
    .sv-radio--allowhover:hover .sv-radio__svg {
      background: #d2ddf9;
      border: 2px solid #94adf0;
      fill: transparent;
    }
    .sv-radio--checked .sv-radio__svg {
      background: transparent;
      border-color: #295ce0;
      fill: #295ce0;
    }

    .sv-item__control:focus + .sv-item__decorator.sv-checkbox__decorator {
      border: none;
    }
    .sv-checkbox__decorator {
      border-radius: 4px;
      border-color: transparent;
    }
    .sv-checkbox__svg {
      background: #f2f2f2;
      border: 2px solid #d6d6d6;
      border-radius: 4px;
      fill: transparent;
    }
    .sv-checkbox--allowhover:hover .sv-checkbox__svg {
      border: none;
      background-color: #b8c9f5;
      fill: white;
    }
    .sv-checkbox--checked .sv-checkbox__svg {
      border: none;
      background-color: #295ce0;
      fill: white;
    }
  }

  .sv-rating {
    color: #141414;
  }
  .sv-rating input:focus + .sv-rating__item-text {
    outline: 1px solid rgba(41, 92, 224, 0.3);
  }
  .sv-rating input:focus + .sv-rating__min-text + .sv-rating__item-text {
    outline: none;
    background-color: #173ea1;
    color: white;
    border-color: #173ea1;
  }
  .sv-rating__item-text {
    color: #9f9f9f;
    background: #f2f2f2;
    border: 2px solid #d6d6d6;
    border-radius: 4px;
  }
  .sv-rating__item-text:hover {
    background-color: #b8c9f5;
    color: white;
    border-color: #b8c9f5;
  }
  .sv-rating__item--selected .sv-rating__item-text {
    background-color: #295ce0;
    color: white;
    border-color: #295ce0;
  }
  .sv-rating__item--selected .sv-rating__item-text:hover {
    background-color: #295ce0;
  }

  .sv-comment {
    border: 2px solid #d6d6d6;
    border-radius: 8px;
  }
  .sv-comment:focus {
    border: 2px solid #295ce0;
    border-radius: 8px;
  }

  .sv-row {
    > div {
      min-width: 1px !important;
    }
  }

  .sv-btn {
    background-color: #452cdd;
  }

  .sv-btn.sv-file__choose-btn {
    background-color: #452cdd;
  }

  .sv-file__clean-btn {
    background-color: rgba(255, 105, 71);
    display: inline;
  }

  .sv-question__title--answer {
    background-color: rgba(148, 173, 240, 0.3);
  }
  .sv-question__title--error {
    background-color: rgba(255, 105, 71, 0.2);
  }

  .sv-image {
    width: 100%;
    display: flex;
    justify-content: center;
    .sv_image_image {
      width: 90%;
      height: auto;
    }
  }

  .sv-completedpage {
    height: auto;
    padding: 50px 0px;
    background: transparent;
    img {
      width: 90%;
      height: auto;
    }
    &::before {
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 992px) {
    .sv-question__content {
      h1 {
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
      }
      h2 {
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
      }
      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      h6 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
      span,
      div {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
    }

    .sv-title {
      font-size: 20px;
      line-height: 28px;
    }
    .sv-question__title {
      font-size: 16px;
      line-height: 24px;
    }
    .sv-description {
      font-size: 14px;
      line-height: 22px;
    }
    .sv-page__description {
      margin-top: -25px;
    }

    .sv-progress,
    .sv-body__progress {
      margin: 0 16px;
      background-color: #f2f2f2;
      height: 10px;
      border-radius: 5px;
    }
    .sv-progress__bar {
      border-radius: 5px;
      background-color: #452cdd;
    }

    .sv-image {
      .sv_image_image {
        width: 100%;
      }
    }

    .sv-text,
    .sv-dropdown {
      width: 100%;
    }

    .sv-completedpage {
      img {
        width: 100%;
      }
    }
  }
`;
