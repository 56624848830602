import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Table, Tag, Popover, Radio, Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import moment from 'moment';

import { store } from '../../../../util';
import { CodeProblemSubmissionStatus } from '../../apollo/coplit/type';

import type { CodeProblem } from '../../apollo/coplit/type';

const ProblemsWrpper = styled.div`
  .table {
    tr {
      cursor: pointer;
    }
    th {
      color: #455573;
    }
    td {
      color: #455573;
    }
  }
`;

const FilterWrapper = styled.div`
  .item {
    display: block;
    height: 30px;
  }
`;

const checkedIsPassed = (passedCount?: number, failedCount?: number) => {
  if (passedCount === undefined || failedCount === undefined || passedCount + failedCount === 0) {
    return <span>-</span>;
  }

  return <span>{`${passedCount} / ${failedCount + passedCount}`}</span>;
};

type ProblemsProps = {
  isPassed?: boolean;
  setIsPassed: Function;
  codeProblems: CodeProblem[];
  codeProblemsTotal: number;
  page: number;
  setPage: Function;
  perPage: number;
  setPerPage: Function;
  status?: CodeProblemSubmissionStatus;
  setStatus: Function;
  myCodeProblemLoading: boolean;
};

export const Problems: React.FC<ProblemsProps> = ({
  isPassed,
  setIsPassed,
  codeProblems,
  codeProblemsTotal,
  page,
  setPage,
  perPage,
  setPerPage,
  status,
  setStatus,
  myCodeProblemLoading
}) => {
  const history = useHistory();

  const displayStatus = (status: CodeProblemSubmissionStatus) => {
    switch (status) {
      case 'NONE':
        return '-';

      case 'ONGOING':
        return <Tag color="green">진행중</Tag>;

      case 'COMPLETED':
        return <Tag color="geekblue">제출 완료</Tag>;

      default:
        return '-';
    }
  };

  const columns = [
    {
      title: '문제',
      dataIndex: 'title',
      key: 'title',
      render: (title: string) => <span style={{ fontWeight: 500 }}>{title}</span>
    },
    {
      title: '최근 작성 시간',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: Date) => <span>{updatedAt ? moment(updatedAt).format('YYYY-MM-DD HH:mm') : '-'}</span>
    },

    {
      title: (
        <span
          style={{
            color: typeof isPassed !== 'undefined' ? '#5E85E8' : '#455573'
          }}
        >
          <Popover
            content={
              <FilterWrapper>
                <Radio.Group
                  onChange={e => {
                    setIsPassed(e.target.value);
                    setPage(1);
                  }}
                  value={isPassed}
                >
                  <Radio value={true} className="item">
                    통과
                  </Radio>
                  <Radio value={false} className="item">
                    미통과
                  </Radio>
                </Radio.Group>
                <Button type="link" className="item" onClick={() => setIsPassed(undefined)}>
                  전체 보기
                </Button>
              </FilterWrapper>
            }
            trigger="click"
            placement="bottom"
          >
            통과 여부 ( passed / total )
            <CaretDownOutlined style={{ margin: '0px 5px' }} />
          </Popover>
        </span>
      ),
      dataIndex: 'isPassed',
      key: 'isPassed',
      render: (isPassed: boolean, codeProblem: CodeProblem) => {
        return isPassed ? <span>통과</span> : checkedIsPassed(codeProblem.passedCount, codeProblem.failedCount);
      }
    },
    {
      title: (
        <span
          style={{
            color: status ? '#5E85E8' : '#455573'
          }}
        >
          <Popover
            content={
              <FilterWrapper>
                <Radio.Group
                  onChange={e => {
                    setStatus(e.target.value);
                    setPage(1);
                  }}
                  value={status}
                >
                  <Radio className="item" value={CodeProblemSubmissionStatus.NONE}>
                    <Tag color="red">시작전</Tag>
                  </Radio>
                  <Radio className="item" value={CodeProblemSubmissionStatus.COMPLETED}>
                    {displayStatus(CodeProblemSubmissionStatus.COMPLETED)}
                  </Radio>
                  <Radio className="item" value={CodeProblemSubmissionStatus.ONGOING}>
                    {displayStatus(CodeProblemSubmissionStatus.ONGOING)}
                  </Radio>
                </Radio.Group>
                <Button type="link" className="item" onClick={() => setStatus(undefined)}>
                  전체 보기
                </Button>
              </FilterWrapper>
            }
            trigger="click"
            placement="bottom"
          >
            상태
            <CaretDownOutlined style={{ margin: '0px 5px' }} />
          </Popover>
        </span>
      ),
      dataIndex: 'status',
      key: 'status',

      render: (status: CodeProblemSubmissionStatus) => <span>{displayStatus(status)}</span>
    }
  ];

  return (
    <ProblemsWrpper>
      <Table
        loading={myCodeProblemLoading}
        className="table"
        dataSource={codeProblems}
        columns={columns}
        rowKey={'id'}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              history.push(`/codeproblem/${record.uuid}`);
            }
          };
        }}
        pagination={{
          total: codeProblemsTotal,
          pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
          showQuickJumper: true,
          showSizeChanger: true,
          onShowSizeChange: (currentPage, pageSize) => {
            setPage(1);
            setPerPage(pageSize);
            store.pageSize.set(history.location.pathname, perPage);
          },
          pageSize: perPage,
          onChange: (page: number) => {
            setPage(page);
            store.paginationIndex.set(history.location.pathname, page);
          },
          current: page
        }}
      />
    </ProblemsWrpper>
  );
};
