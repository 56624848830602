import gql from 'graphql-tag';

export const SUBMIT_QUIZ_SET_GQL = gql`
  mutation urclassSubmitQuizSet(
    $quizSetId: Int!
    $quizSubmissions: [Urclass_QuizSubmissionInput!]
  ) {
    urclassSubmitQuizSet(
      quizSetId: $quizSetId
      quizSubmissions: $quizSubmissions
    ) {
      id
      uuid
      name
      description
      isAssessment

      quizzes {
        id
        uuid
        quizSetId
        question
        type
        explanation
        order
        choices {
          id
          uuid
          quizId
          content
          order
        }
        submission {
          id
          uuid
          quizId
          isCorrect
          descriptiveAnswer
          choices {
            id
            uuid
            quizId
            content
            order
          }
        }
        solution(needSubmission: true) {
          quizId
          choices {
            id
            uuid
            quizId
            content
            order
          }
          descriptiveAnswers
        }

        isMultiChoiceType
      }
    }
  }
`;

export const RESET_QUIZ_SUBMISSIONS_GQL = gql`
  mutation urclassResetQuizSubmissions($quizSetId: Int!) {
    urclassResetQuizSubmissions(quizSetId: $quizSetId) {
      id
      uuid
      name
      description
      isAssessment

      quizzes {
        id
        uuid
        quizSetId
        question
        type
        explanation
        order
        choices {
          id
          uuid
          quizId
          content
          order
        }
        submission {
          id
          uuid
          quizId
          isCorrect
          descriptiveAnswer
          choices {
            id
            uuid
            quizId
            content
            order
          }
        }
        solution(needSubmission: true) {
          quizId
          choices {
            id
            uuid
            quizId
            content
            order
          }
          descriptiveAnswers
        }

        isMultiChoiceType
      }
    }
  }
`;
