import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { Modal, Select, Input } from 'antd';
import { APPLICATION_WITH_PAYMENT_ORDER_GQL } from '../../apollo/application/query';
import { ApplicationWithPaymentOrderData, ApplicationWithPaymentOrderVars } from '../../apollo/application/type';

import { useGlobalMessage } from '../../contexts/globalMessage';

import { GeneralTemplate } from '../../templates';
import { GeneralHeader } from '../../components/organisms/Header';
import { Typography, TypographyType } from '../../components/atoms/Typography';
import { MarginBottom } from '../../components/atoms';

import { CANCEL_APPLICATION_GQL } from '../../apollo/application/mutation';

import { ReactComponent as GirlCrying } from '../../assets/svg/girlCrying.svg';

import { getServerError } from '../../util/errors/serverErrors';
import { ColorPalette, ColorPaletteType } from '../../styles/color';

import type { CancelApplicationData, CancelApplicationVars } from '../../apollo/application/type';
import { PayMethod } from '../../apollo/payment/type';

const banks = [
  { label: '카카오뱅크', korValue: '카카오' },
  { label: '토스뱅크', korValue: '토스' },
  { label: '하나은행', korValue: '하나' },
  { label: 'IBK기업은행', korValue: '기업' },
  { label: 'KB국민은행', korValue: '국민' },
  { label: '신한은행', korValue: '신한' },
  { label: '부산은행', korValue: '부산' },
  { label: '경남은행', korValue: '경남' },
  { label: '광주은행', korValue: '광주' },
  { label: '새마을금고', korValue: '새마을' },
  { label: '신협', korValue: '신협' },
  { label: '씨티은행', korValue: '씨티' },
  { label: '우리은행', korValue: '우리' },
  { label: '우체국예금보험', korValue: '우체국' },
  { label: '전북은행', korValue: '전북' },
  { label: '제주은행', korValue: '제주' },
  { label: 'DGB대구은행', korValue: '대구' },
  { label: 'KDB산업은행', korValue: '산업' },
  { label: 'NH농협은행', korValue: '농협' },
  { label: 'SC제일은행', korValue: 'SC제일' },
  { label: 'Sh수협은행', korValue: '수협' },
  { label: '산림조합', korValue: '산림' },
  { label: '저축은행중앙회', korValue: '저축' },
  { label: '단위농협(지역농축협)', korValue: '단위농협' }
];
const CancelApplicationPageStyled = styled.div`
  text-align: center;
  .image {
    margin-top: 155px;
  }
  .question {
    margin-top: 33px;
  }

  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;

    .btn {
      border: none;
      width: 266px;
      height: 52px;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
      }
    }
    .reconsider {
      background: ${ColorPalette.Gray1};
    }

    .cancel {
      margin-top: 5px;
      background: ${ColorPalette.Gray3};
    }
  }
  @media screen and (max-width: 375px) {
    .image {
      margin-top: 50px;
    }
  }
`;

const CancelReasonModalStyled = styled.div`
  text-align: center;
  .title-area {
    margin-top: 34px;
  }
  .select-area {
    margin-top: 22px;
    .select {
      width: 266px;
    }
  }
  .button-area {
    margin-top: 155px;
    .confirm {
      background: ${ColorPalette.Gray1};
      &:hover {
        cursor: pointer;
      }
    }
    .not-allowed {
      background: ${ColorPalette.Gray3};
      &:hover {
        cursor: not-allowed;
      }
    }

    .btn {
      border: none;
      width: 266px;
      height: 52px;
      border-radius: 5px;
    }
  }
`;
const cancelReasonOptions = [
  '단순 변심',
  '일정이 안맞음',
  '타 교육기관의 코스 등록',
  '코드스테이츠의 다른 코스로 변경 지원',
  '기타'
];

const CancelApplicationPage: React.FC<RouteComponentProps> = props => {
  const history = props.history;

  const applicationUuid = (props.match.params as { uuid: string }).uuid;

  const { data, loading, error } = useQuery<ApplicationWithPaymentOrderData, ApplicationWithPaymentOrderVars>(
    APPLICATION_WITH_PAYMENT_ORDER_GQL,
    { variables: { applicationUuid } }
  );

  const [cancelReason, setCancelReason] = useState('');
  const [bank, setBank] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [holderName, setHolderName] = useState('');
  const [cancelReasonModalVisible, setCancelReasonModalVisibile] = useState(false);

  const { displayGlobalMessage } = useGlobalMessage();

  const [urclassCancelApplication] = useMutation<CancelApplicationData, CancelApplicationVars>(CANCEL_APPLICATION_GQL, {
    variables: { input: { applicationUuid, cancelReason }, refundReceiveAccount: { bank, accountNumber, holderName } }
  });

  const reconsider = () => {
    history.push('/mypage');
  };

  const decide = () => {
    setCancelReasonModalVisibile(true);
  };

  const selectReason = (reason: string) => {
    setCancelReason(reason);
  };

  const closeModal = () => {
    setCancelReasonModalVisibile(false);
    setCancelReason('');
  };

  const handleBankSelect = (value: string) => {
    setBank(value);
  };

  const handleAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!Number.isNaN(Number(e.target.value))) {
      setAccountNumber(e.target.value);
    }
  };

  const handleBankHolderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHolderName(e.target.value);
  };

  const cancelApplication = async () => {
    if (!cancelReason) {
      return;
    }
    if (
      data?.urclassMyApplication?.paidPaymentOrder?.payMethod === 'VBANK' &&
      (!bank || !accountNumber || !holderName)
    ) {
      displayGlobalMessage('환불 계좌 정보를 입력 해주세요.');
      return;
    }

    try {
      await urclassCancelApplication();

      displayGlobalMessage('지원이 취소 되었습니다.');

      history.push('/mypage');
    } catch (e) {
      const serverError = getServerError(e.message);

      displayGlobalMessage(serverError.message);
    }
  };

  return (
    <GeneralTemplate error={!!error} loading={loading}>
      {{
        header: <GeneralHeader />,
        body: (
          <CancelApplicationPageStyled>
            <div className="image">
              <GirlCrying />
            </div>
            <div className="question">
              <Typography
                type={TypographyType.B1}
                color={ColorPaletteType.GRAY2}
                text="정말로 지원을 취소하고 싶으신가요?"
              />
            </div>
            <div className="button-group">
              <button className="reconsider btn" onClick={reconsider}>
                <Typography color={ColorPaletteType.WHITE} type={TypographyType.B1} text="다시 생각해볼게요." />
              </button>
              <button className="cancel btn" onClick={decide}>
                <Typography color={ColorPaletteType.WHITE} type={TypographyType.B1} text="네. 지원을 취소할래요." />
              </button>
            </div>
            <Modal width="310px" visible={cancelReasonModalVisible} onCancel={closeModal} footer={false}>
              <CancelReasonModalStyled>
                <div className="title-area">
                  <Typography
                    type={TypographyType.H4}
                    color={ColorPaletteType.GRAY1}
                    text="지원 취소 이유가 무엇인가요?"
                  />
                </div>
                <div className="select-area">
                  <Select className="select" onSelect={selectReason} value={cancelReason}>
                    {cancelReasonOptions.map(reason => (
                      <Select.Option key={reason} value={reason}>
                        {reason}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {data?.urclassMyApplication?.paidPaymentOrder?.payMethod === PayMethod.VBANK && (
                  <div>
                    <MarginBottom />
                    <Typography
                      type={TypographyType.H4}
                      color={ColorPaletteType.GRAY1}
                      text="환불 계좌 정보를 입력 해주세요."
                    />
                    <MarginBottom />
                    <Select
                      style={{ width: 260 }}
                      placeholder="환불 받으실 은행을 선택 해주세요."
                      value={bank}
                      onSelect={handleBankSelect}
                    >
                      <Select.Option disabled value="">
                        환불 받으실 은행을 선택 해주세요.
                      </Select.Option>
                      {banks.map(bank => (
                        <Select.Option value={bank.korValue}>{bank.label}</Select.Option>
                      ))}
                    </Select>
                    <MarginBottom />
                    <Input
                      maxLength={20}
                      placeholder="- 제외하고 계좌번호를 입력 해주세요."
                      value={accountNumber}
                      onChange={handleAccountNumberChange}
                    />

                    <MarginBottom />
                    <Input
                      maxLength={60}
                      placeholder="예금주를 입력 해주세요."
                      value={holderName}
                      onChange={handleBankHolderChange}
                    />
                    <MarginBottom />
                    <Typography
                      type={TypographyType.B1}
                      color={ColorPaletteType.RED}
                      text="환불까지 영입일 기준 최대 2일이 소요됩니다."
                    />
                  </div>
                )}
                <div className="button-area">
                  <button className={`btn ${!!cancelReason ? 'confirm' : 'not-allowed'}`} onClick={cancelApplication}>
                    <Typography color={ColorPaletteType.WHITE} type={TypographyType.B1} text="확인" />
                  </button>
                </div>
              </CancelReasonModalStyled>
            </Modal>
          </CancelApplicationPageStyled>
        )
      }}
    </GeneralTemplate>
  );
};

export default CancelApplicationPage;
