import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { MY_CLASSROOMS_GQL, MY_CLASSROOMS_STATUS_GQL } from '../../apollo/coplit/query';

import type { Classroom, MyClassroomsData, MyClassroomsVars, MyClassroomsStatusData } from '../../apollo/coplit/type';

export const useController = () => {
  const [classrooms, setClassrooms] = useState<Classroom[]>([]);
  const [totalCodeProblemCount, setTotalCodeProblemCount] = useState(0);
  const [restCodeProblemCount, setRestCodeProblemCount] = useState(0);
  const [classroomsNoti, setClassroomNofi] = useState<Classroom[]>([]);

  const { data: myClassroomsData, loading: myClassroomsLoading, error: myClassroomsError } = useQuery<
    MyClassroomsData,
    MyClassroomsVars
  >(MY_CLASSROOMS_GQL);

  const { data: myClassroomsStatusData, loading: myClassroomsStatusLoading, error: myClassroomsStatusError } = useQuery<
    MyClassroomsStatusData
  >(MY_CLASSROOMS_STATUS_GQL);

  const { data: myClassroomsNotiData, loading: myClassroomsNotiLoading, error: myClassroomsNotiError } = useQuery<
    MyClassroomsData,
    MyClassroomsVars
  >(MY_CLASSROOMS_GQL, {
    variables: { filter: { isDeadline: true } }
  });

  useEffect(() => {
    if (myClassroomsData?.urclassMyClassrooms) {
      setClassrooms(myClassroomsData.urclassMyClassrooms);
    }
  }, [myClassroomsData]);

  useEffect(() => {
    if (myClassroomsStatusData?.urclassMyClassroomsStatus) {
      const { totalCodeProblemCount, restCodeProblemCount } = myClassroomsStatusData?.urclassMyClassroomsStatus;

      setTotalCodeProblemCount(totalCodeProblemCount);
      setRestCodeProblemCount(restCodeProblemCount);
    }
  }, [myClassroomsStatusData]);

  useEffect(() => {
    if (myClassroomsNotiData?.urclassMyClassrooms) {
      setClassroomNofi(myClassroomsNotiData?.urclassMyClassrooms);
    }
  }, [myClassroomsNotiData]);

  const hasNoClassroomError =
    myClassroomsError &&
    (myClassroomsError.message.includes('no-classroom-of-cohort') ||
      myClassroomsError.message.includes('no-cohort-of-user'));

  return {
    loading: myClassroomsLoading || myClassroomsStatusLoading || myClassroomsNotiLoading,
    error: myClassroomsError || myClassroomsStatusError || myClassroomsNotiError,
    hasNoClassroomError,
    classrooms,
    classroomsNoti,
    totalCodeProblemCount,
    restCodeProblemCount
  };
};
