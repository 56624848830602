import { Page, View, Text, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import Signature from '../../../assets/image/signature.png';

/* For PDF */
Font.register({
  family: 'Nanum Gothic',
  src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-ExtraBold.ttf'
});

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Nanum Gothic',
    fontSize: 14,
    textAlign: 'center',
    marginTop: '50px'
  },
  table: {
    width: 'auto',
    margin: '20px 50px 20px 50px',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableSubHeader: {
    width: '100%',
    padding: '6px',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#B2BDD1',
    fontFamily: 'Nanum Gothic',
    textAlign: 'center'
  },
  tableTitle: {
    width: '30%',
    padding: '6px',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
    backgroundColor: '#B2BDD1'
  },
  tableDescription: {
    width: '70%',
    padding: '4px',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,

    fontFamily: 'Nanum Gothic'
  },
  tableText: {
    fontSize: 10,
    fontFamily: 'Nanum Gothic'
  },
  tableCol: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  description: {
    textAlign: 'center',
    margin: '20px 50px 20px 50px',
    fontSize: 10,
    fontFamily: 'Nanum Gothic'
  },
  signatureArea: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '50px',
    justifyContent: 'flex-end'
  },
  signatureText: {
    fontSize: 10,
    margin: '10px',
    fontFamily: 'Nanum Gothic'
  },
  signature: {
    textAlign: 'right',
    width: '34px',
    height: '34px'
  }
});

export const PDFDocument = (certificate: {
  name?: string;
  birthday?: string;
  certCourseName?: string;
  certCourseDesc?: string;
  certEduDuration?: string;
  trainingDay?: string;
  trainingTime?: string;
  orgName?: string;
  phoneNumber?: string;
  address?: string;
  registrationNumber?: string;
  certificatedAt?: string;
}) => {
  return (
    <Document>
      <Page size="A4">
        <Text style={styles.title}>수강 증명서</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableSubHeader}>
              <Text style={styles.tableText}>수강생 인적사항</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableTitle}>
              <Text style={styles.tableText}>이름</Text>
            </View>
            <View style={styles.tableDescription}>
              <Text style={styles.tableText}>{certificate.name}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableTitle}>
              <Text style={styles.tableText}>생년월일</Text>
            </View>
            <View style={styles.tableDescription}>
              <Text style={styles.tableText}>{certificate.birthday}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableSubHeader}>
              <Text style={styles.tableText}>증명상세</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableTitle}>
              <Text style={styles.tableText}>수강 과정명</Text>
            </View>
            <View style={styles.tableDescription}>
              <Text style={styles.tableText}>{certificate.certCourseName}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableTitle}>
              <Text style={styles.tableText}>수강 기간</Text>
            </View>
            <View style={styles.tableDescription}>
              <Text style={styles.tableText}>{certificate.certEduDuration}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableTitle}>
              <Text style={styles.tableText}>수강 요일</Text>
            </View>
            <View style={styles.tableDescription}>
              <Text style={styles.tableText}>{certificate.trainingDay}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableTitle}>
              <Text style={styles.tableText}>수강 시간</Text>
            </View>
            <View style={styles.tableDescription}>
              <Text style={styles.tableText}>{certificate.trainingTime}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableTitle}>
              <Text style={styles.tableText}>수강 내용</Text>
            </View>
            <View style={styles.tableDescription}>
              <Text style={styles.tableText}>{certificate.certCourseDesc}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableSubHeader}>
              <Text style={styles.tableText}>학원 정보</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableTitle}>
              <Text style={styles.tableText}>학원명</Text>
            </View>
            <View style={styles.tableDescription}>
              <Text style={styles.tableText}>{certificate.orgName}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableTitle}>
              <Text style={styles.tableText}>사업자 등록번호</Text>
            </View>
            <View style={styles.tableDescription}>
              <Text style={styles.tableText}>{certificate.registrationNumber}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableTitle}>
              <Text style={styles.tableText}>주소</Text>
            </View>
            <View style={styles.tableDescription}>
              <Text style={styles.tableText}>{certificate.address}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableTitle}>
              <Text style={styles.tableText}>전화번호</Text>
            </View>
            <View style={styles.tableDescription}>
              <Text style={styles.tableText}>{certificate.phoneNumber}</Text>
            </View>
          </View>
        </View>
        <Text style={styles.description}>
          위 학생은 주식회사 코드스테이츠에서 교육 과정을 수강하고 있음을 증명합니다.
        </Text>
        <Text style={styles.description}>{certificate.certificatedAt}</Text>

        <View style={styles.signatureArea}>
          <Text style={styles.signatureText}>{certificate.orgName}</Text>
        </View>
        <View style={styles.signatureArea}>
          <Text style={styles.signatureText}>대표 김인기</Text>

          <Image style={styles.signature} src={Signature} />
        </View>
      </Page>
    </Document>
  );
};
