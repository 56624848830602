import React from 'react';
import styled from '@emotion/styled';

import { ColorPalette, ColorPaletteType } from '../../../styles/color';

export enum TypographyType {
  H0 = 'h0',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  B1 = 'b1',
  B1_Bold = 'b1_bold',
  B2 = 'b2',
  B2_Bold = 'b2_bold',
  B3 = 'b3',
  B4 = 'b4',
  B5 = 'b5',
  B6 = 'b6'
}

const handleTypographyType = (type: TypographyType) => {
  switch (type) {
    // todo 피그마에 맞게 수정 필요
    case TypographyType.H0:
      return `
          font-size: 30px;
          font-weight: 400;
      `;

    case TypographyType.H1:
      return `
          font-size: 30px;
          font-weight: bold;
      `;
    case TypographyType.H2:
      return `
          font-size: 24px;
          font-weight: bold;
      `;
    case TypographyType.H3:
      return `
          font-size: 20px;
      `;
    case TypographyType.H4:
      return `
        font-size: 20px;
        font-weight: 700;
      `;
    case TypographyType.H5:
      return `
          font-size: 20px;
          font-weight: 700;
        `;

    case TypographyType.B1:
      return `
          font-size: 14px;
      `;
    case TypographyType.B1_Bold:
      return `
        font-size: 15px;
        font-weight: 700;
      `;
    case TypographyType.B2:
      return `
          font-size: 12px;
      `;
    case TypographyType.B2_Bold:
      return `
        font-size: 13px;
        font-weight: 700;
      `;
    case TypographyType.B3:
      return `
        font-size:15px;
        font-weight:400;
      `;
    case TypographyType.B4:
      return `
        font-size:14px;
        font-weight:400;
      `;
    case TypographyType.B5:
      return `
          font-size:16px;
          font-weight:700;
        `;
    case TypographyType.B6:
      return `
        font-size:12px;
        font-weight:400
      `;

    default:
      return `
          font-size: 15px;
      `;
  }
};

type TypographyProps = {
  type: TypographyType;
  color: ColorPaletteType;
  text: string;
  className?: string;
  onClick?: () => void;
  underline?: boolean;
};

const StyledDiv = styled.div<{
  type: TypographyType;
  color: ColorPaletteType;
  underline?: boolean;
}>`
  text-decoration: ${({ underline }) => underline && 'underline'};
  color: ${({ color }) => ColorPalette[color] ?? `${color}`};
  ${({ type }) => handleTypographyType(type)}
`;

export const Typography: React.FC<TypographyProps> = ({ type, text, color, className, underline, onClick }) => (
  <StyledDiv underline={underline} className={className} type={type} color={color} onClick={onClick}>
    {text}
  </StyledDiv>
);
