import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Modal } from 'antd';

import { BasicButton } from '../common/BasicButton';
import { Color } from '../../../../style';

type ICodeEditorFooter = {
  setCode: Function;
  templateCode: string;
  nextCodeProblemUuid?: string;
  prevCodeProblemUuid?: string;
  isTestRunning: boolean;
  handleClickSubmit: () => Promise<void>;
  handleClickTest: () => void;
  handleClickReference: () => void;
};

const CodeEditorFooterWrapper = styled.div`
  background-color: #fafbfc;
  border-top: 1px solid ${Color.gray};
  padding: 12px 132px 12px 0;
  display: flex;
  justify-content: space-between;
  .item {
    padding-left: 16px;
  }

  .item.end {
    text-align: end;
  }

  @media screen and (max-width: 1206px) {
    display: inline-block;

    .item {
      text-align: start;
      margin-bottom: 15px;
    }
  }
`;

export const CodeEditorFooter: React.FC<ICodeEditorFooter> = ({
  setCode,
  templateCode,
  prevCodeProblemUuid,
  nextCodeProblemUuid,
  isTestRunning,
  handleClickSubmit,
  handleClickTest,
  handleClickReference
}) => {
  const history = useHistory();
  return (
    <CodeEditorFooterWrapper>
      <div className="item">
        <BasicButton
          color="#F2F4F7"
          hoverColor="#DFE3EC"
          onClick={handleClickReference}
          style={{ fontSize: '15px', borderRadius: '6px', fontWeight: 500, padding: '10px 24px', height: 'auto' }}
        >
          Reference
        </BasicButton>
      </div>
      <div className="item">
        <BasicButton
          color="#F2F4F7"
          hoverColor="#DFE3EC"
          onClick={() => history.push(`/codeproblem/${prevCodeProblemUuid}`)}
          disabled={!prevCodeProblemUuid}
          style={{ fontSize: '15px', borderRadius: '6px', fontWeight: 500, padding: '10px 24px', height: 'auto' }}
        >
          ← 이전 문제
        </BasicButton>
        <BasicButton
          color="#F2F4F7"
          hoverColor="#DFE3EC"
          style={{
            margin: '0px 10px',
            fontSize: '15px',
            borderRadius: '6px',
            fontWeight: 500,
            padding: '10px 24px',
            height: 'auto'
          }}
          onClick={() => history.push(`/codeproblem/${nextCodeProblemUuid}`)}
          disabled={!nextCodeProblemUuid}
        >
          다음 문제 →
        </BasicButton>
      </div>
      <div className="item end">
        <BasicButton
          color="#F2F4F7"
          hoverColor="#DFE3EC"
          style={{ fontSize: '15px', borderRadius: '6px', fontWeight: 500, padding: '10px 24px', height: 'auto' }}
          onClick={() => {
            Modal.confirm({
              title: '코드를 초기화 하시겠습니까?',
              content: '초기화시 작성한 코드는 모두 삭제됩니다. 삭제된 코드는 복구할 수 없습니다.',
              onOk: () => setCode(templateCode),
              okText: '초기화',
              cancelText: '취소',
              okType: 'danger'
            });
          }}
        >
          초기화
        </BasicButton>
        <BasicButton
          color="#F2F4F7"
          hoverColor="#DFE3EC"
          style={{
            margin: '0px 10px',
            fontSize: '15px',
            borderRadius: '6px',
            fontWeight: 500,
            padding: '10px 24px',
            height: 'auto'
          }}
          onClick={handleClickTest}
          loading={isTestRunning}
        >
          테스트 실행
        </BasicButton>
        <BasicButton
          color="#452cdd"
          hoverColor="#3C2CA2"
          style={{ fontSize: '15px', borderRadius: '6px', fontWeight: 500, padding: '10px 24px', height: 'auto' }}
          onClick={handleClickSubmit}
          disabled={isTestRunning}
        >
          제출 하기
        </BasicButton>
      </div>
    </CodeEditorFooterWrapper>
  );
};
