import { Action, AuthCodeStatus, State, ViewStatus } from '../../apollo/user/type';

export const initialState: State = {
  email: '',
  name: '',
  mobile: '',
  birthday: '',
  authCode: '',
  timer: undefined,
  leftAuthTime: 0,
  isFailedVerifyEmailAuthCode: false,
  authCodeStatus: AuthCodeStatus.WAITING,
  isEmailSentModalVisible: false,
  viewStatus: ViewStatus.EDITING_VIEW
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'INIT_PROFILE':
      return {
        ...state,
        ...action.payload
      };
    case 'INIT_AUTH_CODE_STATUS':
      return {
        ...state,
        authCodeStatus: AuthCodeStatus.WAITING
      };
    case 'CHANGE_NAME_INPUT_VALUE':
      return {
        ...state,
        name: action.payload.name
      };
    case 'CHANGE_MOBILE_INPUT_VALUE':
      return {
        ...state,
        mobile: action.payload.mobile
      };
    case 'CHANGE_BIRTHDAY_INPUT_VALUE':
      return {
        ...state,
        birthday: action.payload.birthday
      };
    case 'CHANGE_EMAIL_INPUT_VALUE':
      return {
        ...state,
        email: action.payload.email
      };
    case 'CHANGE_AUTH_CODE_INPUT_VALUE':
      return {
        ...state,
        authCode: action.payload.authCode
      };
    case 'CLICK_SEND_AUTH_CODE':
      return {
        ...state,
        authCode: '',
        authCodeStatus: AuthCodeStatus.SENT,
        timer: action.payload.timer,
        leftAuthTime: 180,
        isEmailSentModalVisible: true
      };
    case 'CLICK_RE_SEND_AUTH_CODE':
      return {
        ...state,
        authCode: '',
        timer: action.payload.timer,
        leftAuthTime: 180,
        isEmailSentModalVisible: true
      };

    case 'TICK_AUTH_TIME':
      return {
        ...state,
        leftAuthTime: state.leftAuthTime - 1
      };
    case 'SUCCESS_VERIFY_EMAIL_AUTH_CODE':
      return {
        ...state,
        authCodeStatus: AuthCodeStatus.VERIFIED,
        isFailedVerifyEmailAuthCode: false
      };
    case 'FAIL_VERIFY_EMAIL_AUTH_CODE':
      return {
        ...state,
        isFailedVerifyEmailAuthCode: true
      };
    case 'CLOSE_EMAIL_SENT_MODAL':
      return {
        ...state,
        isEmailSentModalVisible: false
      };
    case 'VERIFYING_VIEW':
      return {
        ...state,
        viewStatus: ViewStatus.VERIFYING_VIEW
      };
    case 'EDITING_VIEW':
      return {
        ...state,
        viewStatus: ViewStatus.EDITING_VIEW
      };
    default:
      return state;
  }
};
