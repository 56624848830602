import { ApplyServiceType } from './../mypage/type';
import { Application } from '../mypage/type';

export enum PaymentOrderstatus {
  CREATED,
  READY,
  PAID,
  SCHEDULED,
  UNSCHEDULED,
  CANCELLED,
  PARTIAL_CANCELLED,
  REFUNDED,
  FAILED
}

export interface PaymentOrder {
  id: number;
  merchantUID: string;
  orderName: string;
  totalAmount: number;
  status: PaymentOrderstatus;
}

export interface CreatePaymentOrderData {
  urclassCreatePaymentOrder: PaymentOrder;
}

export interface CreatePaymentOrderVars {
  paymentModelId: number;
  eventId: string;
  mediaType?: string;
}

export interface CreateOneTimePaymentOrderData {
  urclassCreateOneTimePaymentOrder: PaymentOrder;
}

export interface CreateOneTimePaymentOrderVars {
  paymentModelId: number;
  mediaType?: string;
}

export interface ValidatePaymentOrderData {
  urclassValidatePaymentOrder: boolean;
}

export interface ValidatePaymentOrderVars {
  input: {
    impUID: string;
    merchantUID: string;
  };
}

export interface Program {
  id: number;
  title: string;
  subTitle: string;
  description: string;
}
export interface ProductBundle {
  id: number;
  uuid: string;
  name: string;
  products: Product[];
}
export interface Product {
  id: number;
  uuid: string;
  name: string;
  description: string;
  program: Program;
  paymentModels: PaymentModel[];
  weekDuration?: number;
  serviceStartDate: Date;
  serviceEndDate: Date;
  useApplicationSteps: boolean;
  applyServiceType: ApplyServiceType;
}

export enum PaymentType {
  UPFRONT = 'UPFRONT',
  HYBRID = 'HYBRID',
  WEWIN = 'WEWIN',
  GOV_FUNDING = 'GOV_FUNDING',
  IMPACT_CAMPUS = 'IMPACT_CAMPUS',
  B2B = 'B2B',
  SCHOLARSHIP = 'SCHOLARSHIP'
}

export interface PaymentModel {
  id: number;
  uuid: string;
  paymentType: PaymentType;
  name: string;
  description: string;
  price: number;
  originPrice: number;
  sharingRate: number;
}

export interface ProductBundleData {
  urclassProductBundle: ProductBundle;
}

export interface ProductBundleVars {
  uuid: string;
}

export interface ProductData {
  urclassProduct: Product;
}

export interface ProductVars {
  uuid: string;
}

export interface PaymentModelData {
  urclassPaymentModelOfProductOption: PaymentModel[];
}

export interface PaymentModelVars {
  productOptionId: number;
}

export interface ApplyWewinData {
  urclassApplyWewin: {
    id: number;
    uuid: string;
    product: {
      name: string;
    };
    user: {
      uuid: string;
    };
  };
}

export interface ApplyWewinVars {
  paymentModelId: number;
  eventId: string;
  mediaType?: string;
}

export type ApplyGovFundingData = {
  urclassApplyGovFunding: {
    id: number;
    uuid: string;
    product: {
      name: string;
    };
    user: {
      uuid: string;
    };
  };
};

export type ApplyGovFundingVars = {
  paymentModelId: number;
  eventId: string;
  mediaType?: string;
};

export type ApplyB2BData = {
  urclassApplyB2B: {
    id: number;
    uuid: string;
    product: {
      name: string;
    };
    user: {
      uuid: string;
    };
  };
};

export type ApplyB2BVars = {
  paymentModelId: number;
  eventId: string;
  mediaType?: string;
};
export type ApplyData = {
  urclassApply: {
    id: number;
    uuid: string;
    product: {
      name: string;
    };
    user: {
      uuid: string;
    };
  };
};

export type ApplyVars = {
  productId: number;
  eventId: string;
  mediaType?: string;
  paymentType: PaymentType;
};
export type ApplyImpactCampusData = {
  urclassApplyImpactCampus: {
    id: number;
    uuid: string;
    product: {
      name: string;
    };
    user: {
      uuid: string;
    };
  };
};

export type ApplyImpactCampusVars = {
  paymentModelId: number;
  eventId: string;
  mediaType?: string;
};
