import React from 'react';
import styled from '@emotion/styled';

type DividerProps = {
  className?: string;
  borderType?: string;
  length?: string;
  direction?: 'vertical' | 'horizantal';
  color?: string;
};

const VerticalDiv = styled.div<{
  length?: string;
  color?: string;
  borderType?: string;
}>`
  border-top: ${({ borderType, color }) => `1px ${borderType ?? 'solid'} ${color ?? '#455573'}`};
  height: ${({ length }) => length};
`;

const HorizantalDiv = styled.div<{
  length?: string;
  color?: string;
  borderType?: string;
}>`
  border-top: ${({ borderType, color }) => `1px ${borderType ?? 'solid'} ${color ?? '#455573'}`};

  width: ${({ length }) => length};
`;

export const Divider: React.FC<DividerProps> = ({
  className,
  color,
  borderType = 'solid',
  length = '100%',
  direction = 'horizantal'
}) =>
  direction === 'vertical' ? (
    <VerticalDiv className={className} borderType={borderType} length={length} color={color} />
  ) : (
    <HorizantalDiv className={className} borderType={borderType} length={length} color={color} />
  );
