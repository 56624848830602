import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled';

import { GeneralTemplate } from '../../templates';
import { GeneralHeader } from '../../components/organisms/Header';
import LoadingPage from '../../components/LoadingPage';
import ErrorPage from '../../components/ErrorPage';
import PaymentModelOptions from '../../components/organisms/Payment/PaymentModelOptions';
import PaymentMethodOptions from '../../components/organisms/Payment/PaymentMethodOptions';
import ApplyFail from '../../components/organisms/Payment/applyResult/ApplyFail';
import OneTimePaymentContainer from '../../components/organisms/Payment/OneTimePaymentContainer';
import { Message } from '../../components/molecules/Message';

import { PRODUCT_GQL } from '../../apollo/product/query';

import { store } from '../../util';
import { fbqTrack } from '../../util/marketingTools/fbq';
import { PayMethodType, PaymentStatusType } from './type';

import type { PaymentModel, ProductData, ProductVars, Product } from '../../apollo/product/type';

const OneTimePaymentPageWrapper = styled.div`
  max-width: 1400px;

  .message {
    margin: 0px 0px 50px 10px;
  }
`;

const OneTimePaymentPage: React.FC = () => {
  store.redirectURL.remove();

  const uuid = useParams<{ uuid: string }>().uuid;
  const [resultStatus, setResultStatus] = useState(PaymentStatusType.STANDBY);

  const [selectedPaymentModel, setSelectedPaymentModel] = useState<PaymentModel | undefined>(undefined);
  const [payMethod, setPayMethod] = useState<PayMethodType>(PayMethodType.CARD);
  const { data, loading, error } = useQuery<ProductData, ProductVars>(PRODUCT_GQL, {
    variables: { uuid }
  });

  useEffect(() => {
    fbqTrack('InitiateCheckout');
  }, []);

  useEffect(() => {
    if (data && data.urclassProduct.paymentModels.length) {
      setSelectedPaymentModel(data.urclassProduct.paymentModels[0]);
    }
  }, [data]);

  const displayResult = (type: PaymentStatusType, product: Product) => {
    switch (type) {
      case PaymentStatusType.STANDBY:
        return (
          <OneTimePaymentPageWrapper className="w3-auto w3-container">
            <div className="w3-row">
              <div className="w3-col l8">
                <div className="message">
                  <Message
                    title={product.program.title}
                    subTitle={product.program.subTitle}
                    description={product.program.description}
                    dividerLength={88}
                  />
                </div>

                <PaymentModelOptions
                  paymentModels={product.paymentModels}
                  selectedPaymentModel={selectedPaymentModel}
                  setSelectedPaymentModel={setSelectedPaymentModel}
                />

                <PaymentMethodOptions
                  selectedPaymentModel={selectedPaymentModel}
                  payMethod={payMethod}
                  setPayMethod={setPayMethod}
                />
              </div>

              <div className="w3-col l4">
                <OneTimePaymentContainer
                  product={product}
                  selectedPaymentModel={selectedPaymentModel}
                  payMethod={payMethod}
                />
              </div>
            </div>
          </OneTimePaymentPageWrapper>
        );

      default:
        return <ApplyFail setResultStatus={setResultStatus} />;
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  const product = data && data.urclassProduct;

  return (
    <GeneralTemplate fixedHeader={false}>
      {{
        header: <GeneralHeader />,
        body: product ? displayResult(resultStatus, product) : <ErrorPage />
      }}
    </GeneralTemplate>
  );
};

export default OneTimePaymentPage;
