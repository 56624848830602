import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import styled from '@emotion/styled';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { useCredential } from '../../contexts/credential';

import { GeneralTemplate } from '../../templates';
import LoadingPage from '../../components/LoadingPage';
import ErrorPage from '../../components/ErrorPage';
import { MainBanner } from '../../components/organisms/WelcomePage';
import { TextButtonGroup } from '../../components/molecules/Group';
import { SectionLabel } from '../../components/atoms/Label';

import { GUEST_CARD_NEWS_LIST_GQL } from '../../apollo/cardNews/query';
import { INCREASE_CARD_NEWS_VIEW_COUNT_GQL } from '../../apollo/cardNews/mutation';
import { OrderType } from '../../apollo/cardNews/type';

import { CardNewsGroup } from '../../domain/lms/components/cardNews/CardNewsGroup';

import { eventCallbackOnScreenFrame } from '../../util';

import type {
  GuestCardNewsListData,
  GuestCardNewsListVars,
  IncreaseCardNewsViewCountData,
  IncreaseCardNewsViewCountVars
} from '../../apollo/cardNews/type';

const WelcomePageWrapper = styled.div`
  .cardnews-content {
    margin: 20px 0px;
  }
`;

export const Cover = styled.div<{ image: string }>`
  height: 50vh;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  padding-top: 100px;
`;

const WelcomePage: React.FC = () => {
  const [newestFilterPage, setNewestFilterPage] = useState(1);
  const [viewCountFilterPage, setViewCountFilterPage] = useState(1);
  const { isLogin } = useCredential();

  const [orderBy, setOrderBy] = useState<OrderType | undefined>();

  const { data, loading, error, fetchMore } = useQuery<GuestCardNewsListData, GuestCardNewsListVars>(
    GUEST_CARD_NEWS_LIST_GQL,
    {
      variables: {
        perPage: 12,
        orderBy: orderBy ? { viewCount: orderBy } : undefined
      }
    }
  );

  const [urclassIncreaseCardNewsViewCount] = useMutation<IncreaseCardNewsViewCountData, IncreaseCardNewsViewCountVars>(
    INCREASE_CARD_NEWS_VIEW_COUNT_GQL
  );

  useEffect(() => {
    const handler = eventCallbackOnScreenFrame(() => {
      const cardNewsCnt = data && data.urclassGuestCardNewsList ? data.urclassGuestCardNewsList.items.length : 0;
      const total = data && data.urclassGuestCardNewsList ? data.urclassGuestCardNewsList.total : 0;

      handleScrollEvent(
        orderBy ? viewCountFilterPage : newestFilterPage,
        cardNewsCnt,
        total,
        orderBy ? setViewCountFilterPage : setNewestFilterPage
      );
    }, {});

    window.addEventListener('scroll', handler, {
      passive: true
    });
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [data]);

  if (isLogin) {
    return <Redirect to={'/'} />;
  }

  const handleScrollEvent = (page: number, currentCount: number, total: number, setPage: Function) => {
    const pageHeight = window.document.documentElement.scrollHeight;
    const screenHeight = window.document.documentElement.offsetHeight;
    const scrollTop = window.pageYOffset;

    if (screenHeight + scrollTop === pageHeight) {
      if (currentCount < total) {
        fetchMore({
          variables: {
            page: page + 1,
            perPage: 12,
            orderBy: orderBy ? { viewCount: orderBy } : undefined
          },
          updateQuery: (previousQueryResult: GuestCardNewsListData, { fetchMoreResult }: any) => {
            setPage(page + 1);
            return {
              urclassGuestCardNewsList: {
                items: [
                  ...previousQueryResult.urclassGuestCardNewsList.items,
                  ...fetchMoreResult.urclassGuestCardNewsList.items
                ],
                total: fetchMoreResult.urclassGuestCardNewsList.total,
                __typename: 'Urclass_CardNewsList'
              }
            };
          }
        });
      }
    }
  };

  const handleClickCardNews = async (cardNewsId: number) => {
    await urclassIncreaseCardNewsViewCount({
      variables: { cardNewsId }
    });
  };

  const handleClickBanner = () => {
    window.location.assign('https://codestates.com');
  };

  const handleChangeOrdering = (value: string | number | undefined) => {
    setOrderBy(value ? (value as OrderType) : undefined);
  };

  if (error) {
    return <ErrorPage />;
  }

  const cardNewsList = data && data.urclassGuestCardNewsList ? data.urclassGuestCardNewsList.items : [];

  return (
    <GeneralTemplate>
      {{
        body: (
          <WelcomePageWrapper>
            <MainBanner onClick={handleClickBanner} />
            <SectionLabel>Magazine</SectionLabel>
            <TextButtonGroup
              labelSource={[
                { text: '최신순', value: undefined },
                { text: '조회순', value: OrderType.DESC }
              ]}
              value={orderBy}
              handleOnChange={handleChangeOrdering}
            />
            <div className="cardnews-content">
              {loading ? (
                <LoadingPage />
              ) : (
                <CardNewsGroup cardNewsList={cardNewsList} handleClickCardNews={handleClickCardNews} />
              )}
            </div>
          </WelcomePageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default WelcomePage;
