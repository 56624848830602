import gql from 'graphql-tag';

export const ATTEND_WORK_GQL = gql`
  mutation urclassAttendWork {
    urclassAttendWork
  }
`;

export const LEAVE_WORK_GQL = gql`
  mutation urclassLeaveWork {
    urclassLeaveWork
  }
`;
