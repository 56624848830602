import React from 'react';
import styled from '@emotion/styled';
import { ColorPalette } from '../../../styles/newColor';

type NavButtonType = {
  children: React.ReactNode;
  className?: string;
  isSelected?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const NavButtonWrapper = styled.button`
  cursor: pointer;
  border: none;
  appearance: none;

  margin: 0;
  padding: 8px 16px;
  border-radius: 30px;
  background-color: ${ColorPalette.BluishGray50};
  color: ${ColorPalette.BluishGray600};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.025em;
  transition: background-color 0.1s, color 0.1s;

  :hover {
    background-color: ${ColorPalette.BluishGray100};
  }

  &.selected {
    background-color: ${ColorPalette.CozBlue50};
    color: ${ColorPalette.CozBlue600};
    font-weight: 700;
  }
`;

const NavButton: React.FC<NavButtonType> = ({ children, className, isSelected = false, onClick }) => {
  return (
    <NavButtonWrapper
      className={[className, isSelected && 'selected'].filter(className => className).join(' ')}
      onClick={onClick}
    >
      {children}
    </NavButtonWrapper>
  );
};

export default NavButton;
