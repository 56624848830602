import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Progress } from 'antd';
import { ReactComponent as IcoLink } from '../../assets/svg/ico_link.svg';
import {
  ReadOutlined,
  PlayCircleOutlined,
  CheckSquareOutlined,
  AppstoreOutlined,
  FileOutlined,
  LeftOutlined
} from '@ant-design/icons';
import { SidebarTop, SidebarContentItem, SidebarContentSeperator, darkGray, lightGreen } from './SidebarStyle';
import { contentLearningProgress } from '../../../../util/contentProgress';

import type { Playlist, PlaylistContent } from '../../apollo/contents/types';

interface ISprintListEntry {
  title: string;
  uuid: string;
  type: string;
  isUserCompleted: boolean;
}

const ContentSidebarWrapper = styled.div`
  width: 320px;
  height: 100%;
  padding-bottom: 150px;
  border-right: 1px solid #dfe4ec !important;
  z-index: 5;
  background-color: white;
  overflow: auto;
  .selected {
    .content-title {
      font-weight: 700;
      color: #295ce0;
    }
  }
`;

const SidebarContentTitle = (Icon: React.ReactNode, contentTitle: string) => (
  <span>
    {Icon}
    <span style={{ paddingLeft: '10px' }}>{contentTitle}</span>
  </span>
);

// FIXME: styles props를 빼고 HOC로 교체한다.
const contentTitle = (type: string, title: string) => {
  switch (type) {
    case 'text':
      return SidebarContentTitle(<ReadOutlined style={{ color: '#999' }} />, title);
    case 'link':
      return SidebarContentTitle(<IcoLink style={{ color: '#999' }} />, title);
    case 'video':
      return SidebarContentTitle(<PlayCircleOutlined style={{ color: '#999' }} />, title);
    case 'quiz':
    case 'quiz_set':
      return SidebarContentTitle(<CheckSquareOutlined style={{ color: '#999' }} />, title);
    case 'embed':
      return SidebarContentTitle(<AppstoreOutlined style={{ color: '#999' }} />, title);
    case 'gitbook':
      return SidebarContentTitle(<ReadOutlined style={{ color: '#999' }} />, title);
    default:
      return SidebarContentTitle(<FileOutlined style={{ color: '#999' }} />, title);
  }
};

const contentsType = (
  content: ISprintListEntry,
  playlistId: number,
  current: string | null,
  isLastContent: boolean = false
) => {
  const { title, uuid, type, isUserCompleted } = content;

  if (type === 'separator') {
    return <SidebarContentSeperator key={uuid}>{title}</SidebarContentSeperator>;
  }

  return (
    <Link
      to={{
        pathname: `/content/${uuid}`,
        search: `?playlist=${playlistId}`
      }}
      key={uuid}
    >
      <SidebarContentItem className={current === `content/${uuid}` ? 'selected' : ''} isLastContent={isLastContent}>
        <div className="content-iscompleted">
          <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1">
            <g
              id="CourseProfile"
              stroke="none"
              strokeWidth="1"
              fill={isUserCompleted ? lightGreen : 'white'}
              fillRule="evenodd"
            >
              <g transform="translate(-135.000000, -351.000000)" stroke={isUserCompleted ? lightGreen : darkGray}>
                <circle id="Oval-10" cx="140.5" cy="357" r="5"></circle>
              </g>
            </g>
          </svg>
        </div>
        <span className="content-title">{contentTitle(type, title)}</span>
      </SidebarContentItem>
    </Link>
  );
};

export const ContentSidebar: React.FC<{
  playlistId: number;
  history: any;
  playlist: Playlist;
}> = ({ playlistId, playlist, history }) => {
  const sprintList: ISprintListEntry[] = [];

  const current = history.location.pathname.indexOf('playlist') === -1 ? history.location.pathname.substring(1) : '';
  playlist.playlist_content.forEach((playlistContent: PlaylistContent) => {
    if (!playlistContent.content.isPublished) {
      return;
    }

    sprintList.push({
      title: playlistContent.content.version.title,
      uuid: playlistContent.content.uuid,
      type: playlistContent.content.version.type,
      isUserCompleted: playlistContent.isUserCompleted
    });
  });

  const currentCourse = playlist.course.id;
  const currentCourseName = playlist.course.name;

  return (
    <ContentSidebarWrapper>
      <SidebarTop className="w3-bar-item">
        <Link to={`/course/${currentCourse}`}>
          <p className="current-course-name">
            <LeftOutlined className="current-course-name-icon" />
            <span>{currentCourseName}</span>
          </p>
        </Link>
        <div className="current-playlist-name">
          <span style={{ fontWeight: 700 }}>{playlist.name}</span>
        </div>
        <Progress percent={contentLearningProgress(sprintList)} size="small" strokeColor={lightGreen} />
      </SidebarTop>
      <div>
        {sprintList.map((content: ISprintListEntry, index: number) =>
          contentsType(
            content,
            playlistId,
            current,
            !sprintList[index + 1] || sprintList[index + 1].type === 'separator'
          )
        )}
      </div>
    </ContentSidebarWrapper>
  );
};
