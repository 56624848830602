import Storage from './storage';

enum Locals {
  CURRNET_COURSE_NAME = 'current_course_name',
  CURRENT_PLAYLIST = 'current_playlist'
}

interface currentPlaylist {
  id: number;
  name: string;
}

export default class Education extends Storage<Locals> {
  private static instance?: Education;

  private constructor() {
    super();
  }

  public static getInstance() {
    this.instance = new Education();

    return this.instance;
  }

  public getCurrentPlaylist(): currentPlaylist {
    const currentPlaylist = JSON.parse(
      this.get(Locals.CURRENT_PLAYLIST) || 'null'
    );

    return currentPlaylist;
  }

  public setCurrentPlaylist(currentPlaylist: currentPlaylist) {
    this.set(Locals.CURRENT_PLAYLIST, JSON.stringify(currentPlaylist));
  }

  public getCurrentCourseName(): string | null {
    return this.get(Locals.CURRNET_COURSE_NAME);
  }

  public setCurrentCourseName(currentCourseName: string) {
    this.set(Locals.CURRNET_COURSE_NAME, currentCourseName);
  }
}
