export enum CertificateType {
  URCLASS = 'URCLASS',
  HRD_NET = 'HRD_NET'
}

export enum AttendanceResultType {
  PRESENCE = 'PRESENCE',
  ABSENCE = 'ABSENCE',
  LEAVE_EARLY = 'LEAVE_EARLY',
  OFFICIAL_LEAVE = 'OFFICIAL_LEAVE',
  NOT_REFLECTED = 'NOT_REFLECTED'
}

export type CertificateData = {
  urclassMe: {
    id: number;
    name: string;
    birthday: string;
  };
  urclassOrganization: {
    id: number;
    uuid: string;
    name: string;
    address: string;
    registrationNumber?: string;
    trainingDay?: string;
    trainingTime?: string;
    phoneNumber: string;
  };
  urclassCohort: {
    id: number;
    uuid: string;
    certIssuable: boolean;
    certEduStartDate?: Date;
    certEduEndDate?: Date;
    certCourseName?: string;
    certCourseDesc?: string;
  };
};

export type CertificateVars = {
  cohortUuid: string;
};

export type Attendance = {
  trainingDate: string;
  loginIp?: string;
  isAttended: boolean;
};

export type AttendanceCertificate = {
  cohortName: string;
  name: string;
  attendanceListData: Attendance[];
};

export type AttendanceCertificateData = {
  urclassAttendanceCertificate: AttendanceCertificate;
};

export type AttendanceCertificateVars = {
  cohortUuid: string;
  startDate: Date;
  endDate: Date;
};

export type CertificateRecord = {
  date: string;
  checkInTime: string;
  checkOutTime: string;
  attendanceResult: AttendanceResultType;
};

export type CertificateOrganizationInfo = {
  trainingOrganizationName: string;
  address: string;
  trainingOrganizationCategory: string;
};

export type MakeCertificationVars = {
  certificateType: CertificateType;
  bootcampId: number;
  trainStartAt: Date;
  trainEndAt: Date;
};

export type MakeCertificationData = {
  urclassMakeCertification: {
    holidays: string[];
    type: CertificateType;
    trainingProgramName: string;
    govermentOfficeName: string;
    ncsCode: string;
    validDuration: string;
    personnel: string;
    totalTrainingTimeAndDay: string;
    mainTrainingTarget: string;
    trainingIntervalAnd: string;
    name: string;
    birthdayAndGender: string;
    trainStartAt: Date;
    trainEndAt: Date;
    trainingTime: string;
    presenceCount: number;
    leaveEarlyCount: number;
    absenceCount: number;
    officialLeaveCount: number;
    presenceRatio: string;
    certificateRecords: CertificateRecord[];
    certificateOrganizationInfo: CertificateOrganizationInfo;
  };
};

export type attendanceCertificatePdfProps = {
  holidays: string[];
  type: CertificateType;
  trainingProgramName: string;
  govermentOfficeName: string;
  ncsCode: string;
  validDuration: string;
  personnel: string;
  totalTrainingTimeAndDay: string;
  mainTrainingTarget: string;
  trainingIntervalAnd: string;
  name: string;
  birthdayAndGender: string;
  trainStartAt: Date;
  trainEndAt: Date;
  trainingTime: string;
  presenceCount: number;
  leaveEarlyCount: number;
  absenceCount: number;
  officialLeaveCount: number;
  presenceRatio: string;
  certificateRecords: CertificateRecord[];
  certificateOrganizationInfo: CertificateOrganizationInfo;
};
