export enum PaymentOrderstatus {
  CREATED = 'CREATED',
  READY = 'READY',
  PAID = 'PAID',
  SCHEDULED = 'SCHEDULED',
  UNSCHEDULED = 'UNSCHEDULED',
  CANCELLED = 'CANCELLED',
  PARTIAL_CANCELLED = 'PARTIAL_CANCELLED',
  REFUNDED = 'REFUNDED',
  FAILED = 'FAILED'
}

export enum PayMethod {
  CARD = 'CARD',
  VBANK = 'VBANK',
  SAMSUNG = 'SAMSUNG',
  TRANS = 'TRANS'
}

export interface PaymentOrder {
  id: number;
  orderName: string;
  totalAmount: number;
  payMethod: PayMethod;
  paidAt: Date;
  status: PaymentOrderstatus;
  vbankNum?: string;
  vbankName?: string;
  vbankDate?: Date;
  vbankHolder?: string;
  productUuid?: string;
}

export type PaymentOrderData = {
  urclassPaymentOrderByImpUID: {
    id: number;
    status: PaymentOrderstatus;
    payMethod: PayMethod;
    orderName: string;
    totalAmount: number;

    vbankNum?: string;
    vbankName?: string;
    vbankDate?: Date;
    vbankHolder?: string;
  };
};

export type PaymentOrderVar = {
  impUID: string;
};

export type ApprovePaymentOrderData = {
  urclassApprovePaymentOrder: PaymentOrder;
};

export type ApprovePaymentOrderVars = {
  input: {
    paymentKey: string;
    amount: number;
    merchantUID: string;
    repay: boolean;
  };
};

export type ValidatePaymentOrderData = {
  urclassValidatePaymentOrder: boolean;
};
export type ValidatePaymentOrderVars = {
  input: {
    impUID: string;
    merchantUID: string;
  };
};
