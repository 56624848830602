import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useCredential } from '../../contexts/credential';
import { store } from '../../util';
import { HRDOTP } from '../../lib/hrd/otp';

export const useController = () => {
  const history = useHistory();
  const [otpPassword, setOTPPassword] = useState('');
  const [isOTPLocked, setIsOTPLocked] = useState(false);

  const credential = useCredential();
  const searchParams = new URLSearchParams(window.location.search);

  const userId = searchParams.get('userId') || '';

  const classAgentPK = searchParams.get('classAgentPK') || '';

  const hrdOTP = new HRDOTP({ userId, classAgentPK });

  useEffect(() => {
    // otp 대기 단계
    store.otp.setReady();
    hrdOTP.loadHrdFrame();
    checkOTPStatus();
  }, []);

  const validateClassAgentPK = () => {
    const splitted = classAgentPK.split(',');
    const coursePK = splitted[0];
    const year = splitted[1];
    const month = splitted[2];

    if (
      splitted.length !== 3 ||
      isNaN(Number(coursePK)) ||
      isNaN(Number(year)) ||
      isNaN(Number(month))
    ) {
      return false;
    }

    return true;
  };

  if (!userId || !classAgentPK || !validateClassAgentPK()) {
    alert('잘못 된 접근입니다');
    credential.logout();
  }

  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOTPPassword(e.target.value);
  };

  const handleSystemError = () => {
    alert(
      'OTP 서비스 장애 입니다. 메인페이지로 이동합니다. 코스 관리자에게 문의 해주세요.'
    );
    store.otp.setClear();
    history.replace('/');
    // TODO : 셋 인터벌 지우기용 ... ㅠㅠ
    window.location.reload();
  };

  const handleValidationSuccess = () => {
    store.otp.setClear();
    alert('OTP 비밀번호가 일치합니다. 학습페이지로 이동합니다');
    history.replace('/');
    window.location.reload();
  };

  const checkOTPStatus = async () => {
    const { connStatus, otpStatus } = await hrdOTP.checkStatus();

    if (connStatus === '101') {
      hrdOTP.loadOTP();
      if (otpStatus === '102') {
        // password 5회 오류
        setIsOTPLocked(true);
      } else if (otpStatus === '101' || otpStatus === '103') {
        // OK
      } else {
        return handleSystemError();
      }
    } else {
      //  서비스 불가
      return handleSystemError();
    }
  };

  const validateOtp = async () => {
    if (otpPassword.length !== 6) {
      return alert('패스워드 6자리를 입력 해주세요');
    }
    const { retVal, errorContent, retMessage, remark } = await hrdOTP.validate({
      otpNumber: otpPassword
    });

    if (retVal === '101') {
      // otp success
      hrdOTP.hideKeyPad();

      handleValidationSuccess();
    } else {
      // otp invalid
      hrdOTP.hideKeyPad();
      hrdOTP.loadOTP();

      if (errorContent === '5') {
        setIsOTPLocked(true);
      }
      alert(
        `OTP 인증에 실패하였습니다. 비밀번호를 확인 후 다시 입력하여 주십시오.
        연속오류 횟수 :
        ${errorContent}
        ${retMessage}
        ${remark}
        `
      );
    }
  };

  const resetOTPStatus = async () => {
    const { retVal } = await hrdOTP.resetStatus();

    if (retVal === '101') {
      hrdOTP.loadOTP();

      setIsOTPLocked(false);

      alert('OTP 상태가 초기화 되었습니다');
    } else {
      return handleSystemError();
      // otp 문제 있음
    }
  };

  return {
    checkOTPStatus,
    changePassword,
    validateOtp,
    isOTPLocked,
    resetOTPStatus
  };
};
