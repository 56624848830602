import styled from '@emotion/styled';
import { Color } from '../../../style';

export const SummaryCardWrapper = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: ${Color.gray};
  border-radius: 5px;
  margin: 20px;
  background-color: white;
  padding: 20px 25px;
`;

export const OptionTitle = styled.div`
  font-size: 28px;
  color: #333;
  font-weight: 600;
  line-height: 1.125em;
  margin: 20px 10px;
`;

export const DashboardEntry = styled.div`
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${Color.gray};
  padding: 0px 10px 25px 10px;
  margin-bottom: 20px;

  &.last-entry {
    border-bottom-width: 0px;
    margin-bottom: -20px;
  }

  .icon {
    font-size: 17px;
    font-weight: 500;
    margin: 5px 0px;
    color: #505050;
  }

  .entry-main-title {
    font-size: 30px;
    font-weight: 600;
    color: #303030;
  }

  .entry-main-description {
    font-size: 17px;
    margin: 5px 0px;
    color: #707070;
    word-break: keep-all;
  }

  .entry-title {
    font-size: 17px;
    font-weight: 500;
    margin: 5px 10px;
    color: #505050;
  }

  .entry-description {
    font-size: 17px;
    margin: 5px 27px;
    color: #707070;
  }

  .payment-nav {
    font-size: 17px;
    color: #707070;
    margin: 3px 0px;
  }

  .point {
    color: red !important;
  }

  .total-price {
    display: flex;
    align-items: baseline;
  }

  .total-price-nav {
    margin-bottom: 20px;
    flex: 1;
    color: black;
    font-size: 20px;
    font-weight: 500;
  }

  .total-price-value {
    font-size: 24px;
    font-weight: 600;
    color: #00b9ee;
    text-align: right;
    flex: 1;
  }
`;

export const GuideMsg = styled.div`
  text-align: center;
  font-style: italic;
  margin: 40px 0px;
  color: ${Color.gray};
`;

export const WrapperResult = styled.div`
  @media screen and (max-width: 992px) {
    .cover {
      padding: 0px;
    }
  }
`;

export const WrapperResultInfo = styled.div`
  background-color: #efefef;
  padding: 20px;
  width: 700px;
  margin: 0px auto;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const WrapperResultInfoItem = styled.div`
  display: flex;
  align-items: baseline;

  @media screen and (max-width: 992px) {
    display: block;
    margin: 20px 0px;
  }
`;

export const ResultInfoNav = styled.div`
  flex: 1;
  font-size: 15px;
  margin: 10px 30px;
  text-align: right;

  @media screen and (max-width: 992px) {
    font-size: 13px;
    text-align: left;
    margin: 10px 0px;
  }
`;

export const ResultInfoContents = styled.div`
  flex: 8;
  font-weight: 600;
  text-align: left;

  .subDescription {
    font-size: 13px;
    color: #aaa;
    font-weight: 500;
  }

  @media screen and (max-width: 992px) {
    font-size: 13px;
    .subDescription {
      font-size: 10px;
    }
  }
`;

export const ApplyPresenterWrapper = styled.div`
  margin-bottom: 150px;
`;

export const ResultItemWrapper = styled.div`
  margin: 10px auto;
  text-align: center;

  .btn {
    width: 150px;
  }
`;

export const WewinSummaryWrapper = styled.div`
  .isa-title {
    font-size: 18px;
    font-weight: 600;
    color: #505050;
  }

  .isa-description {
    font-size: 17px;
    margin: 15px 0px;
    color: #707070;
    word-break: keep-all;
  }
`;

export const HybridSummaryWrapper = styled.div`
  .isa-title {
    font-size: 18px;
    font-weight: 600;
    color: #505050;
  }

  .isa-description {
    font-size: 17px;
    margin: 15px 0px;
    color: #707070;
  }
`;
