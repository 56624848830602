export enum CohortType {
  PART_PRE = 'PART_PRE',
  FULL_PRE = 'FULL_PRE',
  PART_IM = 'PART_IM',
  FULL_IM = 'FULL_IM'
}

export type Course = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  status: CourseStatus;
};

export type FieldCourse = {
  id: number;
  uuid: string;
  name: string;
  description: string;
};

export interface Cohort {
  id: number;
  uuid: string;
  name: string;
  cohortType: CohortType;
  description: string;
  startDate: Date;
  endDate: Date;
  certIssuable: boolean;
  certEduStartDate: Date;
  certEduEndDate: Date;
  certCourseName: string;
  certCourseDesc: string;
  useAttendanceCertificationFeature: boolean;
  courses: FieldCourse[];
}

export interface MyCohortsData {
  urclassMyCohorts: Cohort[];
}

export type CoursesOfUser = {
  urclassCoursesOfUser: Course[];
};

export enum CourseStatus {
  ACTIVE = 'ACTIVE',
  SCHEDULED = 'SCHEDULED',
  EXPIRED = 'EXPIRED'
}
