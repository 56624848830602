// Kakao Pixel
// Document:
//  https://kakaoad.github.io/kakao-pixel/Documentation.html

export const KAKAO_PIXEL_TRACK_ID = '6227192593315967120';
export const pageView = (tag?: string) => {
  if (process.env.NODE_ENV === 'production') {
    if ((window as any).kakaoPixel) {
      (window as any)?.kakaoPixel(KAKAO_PIXEL_TRACK_ID).pageView(tag);
    }
  }
};

export const completeRegistration = () => {
  if (process.env.NODE_ENV === 'production') {
    if ((window as any).kakaoPixel) {
      (window as any)?.kakaoPixel(KAKAO_PIXEL_TRACK_ID).completeRegistration();
    }
  }
};

export const purchase = (tag?: string) => {
  if (process.env.NODE_ENV === 'production') {
    if ((window as any).kakaoPixel) {
      (window as any)?.kakaoPixel(KAKAO_PIXEL_TRACK_ID).purchase(tag);
    }
  }
};
