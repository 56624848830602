import React from 'react';
import { Redirect } from 'react-router-dom';

import { PayMethod } from '../../apollo/payment/type';
import { useController } from './controller';

const PaymentSuccessPage: React.FC = () => {
  const {
    data, isUpfront, error, to
  } = useController();

  if (error) {
    return <Redirect to={to} />;
  }

  if (data && data.urclassApprovePaymentOrder) {
    if (isUpfront && data.urclassApprovePaymentOrder.payMethod === PayMethod.CARD) {
      return <span>...loading</span>;
    }

    return <Redirect to={to} />;
  }

  return <span>...loading</span>;
};

export default PaymentSuccessPage;
