import React from 'react';
import { EyeOutlined } from '@ant-design/icons';

import { Grid } from '../common/Grid';
import { EmptyContentViewer } from '../common/EmptyContentViewer';
import { ImageCard } from '../common/ImageCard';
import { IconLabel } from '../common/IconLabel';

import { numberFormatter } from '../../../../util';

import type { CardNews } from '../../../../apollo/cardNews/type';

type CardNewsGroupProps = {
  cardNewsList: CardNews[];
  handleClickCardNews: (id: number) => void;
};

export const CardNewsGroup: React.FC<CardNewsGroupProps> = ({ cardNewsList, handleClickCardNews }) => {
  if (!cardNewsList.length) {
    return (
      <EmptyContentViewer
        title={'콘텐츠를 준비중입니다.'}
        description={'더 좋은 자료를 선별중에 있습니다. 조금만 기다려주세요.'}
      />
    );
  }

  return (
    <div>
      <Grid itemWidth={300} gridGap={40}>
        {cardNewsList.map(cardNews => {
          const { id, title, description, thumbnailURL, url, viewCount } = cardNews;

          return (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              key={id}
              onClick={() => {
                handleClickCardNews(id);
              }}
            >
              <ImageCard
                title={title}
                description={description}
                imageUrl={thumbnailURL}
                footer={<IconLabel title={numberFormatter(viewCount)} Icon={<EyeOutlined />} />}
              />
            </a>
          );
        })}
      </Grid>
    </div>
  );
};
