import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { ReactComponent as Logo } from '../assets/svg/logo.svg';

const GeneralFooterWrapper = styled.div`
  background-color: #fff;
  border-top: 1px solid #ececec;

  .container {
    max-width: 1200px;
    margin: 0 auto;

    .logo {
      margin-top: 30px;
      margin-bottom: 16px;
    }

    .info {
      p {
        margin: 8px 0;
        font-size: 14px;
        color: #494949;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      padding-top: 16px;
      padding-bottom: 24px;

      .item {
        flex: 1;
        font-size: 12px;
        font-weight: bold;

        &.links {
          a {
            color: #5a5a5a;

            &:hover,
            &:focus {
              color: #5a5a5a;
            }
          }
          span {
            padding: 0 8px;
          }
        }

        &.message {
          text-align: end;
          color: #455573;
        }
      }
    }
  }
`;

const Footer = () => {
  const history = useHistory();

  return (
    <GeneralFooterWrapper>
      <div className="container">
        <Logo className="logo" onClick={() => history.push('/')} />

        <div className="info">
          <p>문의: sales@codestates.com</p>
          <p>상호명: 주식회사 코드스테이츠 | 사업자번호: 703-88-00878 | 대표자명: 김인기</p>
          <p>사업장 주소: 서울특별시 강남구 테헤란로 151, 6층 602, 603호 (하이츠빌딩)</p>
        </div>

        <div className="bottom">
          <div className="item links">
            <a href="/" target="_blank">
              이용 약관
            </a>
            <span>|</span>
            <a href="/" target="_blank">
              개인정보 취급방침
            </a>
          </div>
          <div className="item message">
            <span>구글 크롬에 최적화 되어있습니다</span>
          </div>
        </div>
      </div>
    </GeneralFooterWrapper>
  );
};

export default Footer;
