import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { store } from '.';

const RouteMiddleware: React.FC = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  // codestates.com -> urclass.codestates.com/apply/uuid?utm_source="mediaType"
  const mediaType = searchParams.get('utm_source');

  if (mediaType) {
    store.adsMediaType.set(mediaType);
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      TagManager.initialize({ gtmId: 'GTM-W3SBS22' });
    }
  }, []);

  return null;
};

export default RouteMiddleware;
