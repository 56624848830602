import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery as apolloUseQuery } from '@apollo/react-hooks';
import LoadingPage from '../../components/LoadingPage';
import { useCredential } from '../../contexts/credential';
import queryKeys from '../../query/queryKeys';
import { getAdmissionApplicationByUserUuidAndApplicationUuidRequest } from './request';

import { store } from '../../util';
import { MyApplicationData, MyApplicationVars } from '../../apollo/mypage/type';
import { MY_APPLICATION_DETAIL_GQL } from '../../apollo/mypage/query';
interface ParamsType {
  uuid: string;
}
function RedirectToDetailPage() {
  const history = useHistory();
  const params = useParams<ParamsType>();
  const location = useLocation();
  const utmInformation = location.search;
  const { uuid: applicationUuid } = params;

  const { profile } = useCredential();

  const getAdmissionApplicationByUuidParams = {
    applicationUuid,
    userUuid: profile ? profile.uuid : ''
  };

  apolloUseQuery<MyApplicationData, MyApplicationVars>(MY_APPLICATION_DETAIL_GQL, {
    variables: { uuid: applicationUuid },
    fetchPolicy: 'network-only',
    onCompleted: (cozApplicationData: MyApplicationData) => {
      const admissionToken = cozApplicationData.urclassMyApplication.admissionApplierToken;
      admissionToken && store.admissionToken.set(admissionToken);
    }
  });

  const { data: admissionApplicationData } = useQuery(
    queryKeys.APPLICATION_CARD_BY_UUID(getAdmissionApplicationByUuidParams),
    () => getAdmissionApplicationByUserUuidAndApplicationUuidRequest(getAdmissionApplicationByUuidParams),
    {
      enabled: !!profile,
      onError: err => console.log(err)
    }
  );

  useEffect(() => {
    if (!admissionApplicationData || !admissionApplicationData.template) return;
    const slugUrl = admissionApplicationData.template.slugUrl;

    history.push(`/applications/${slugUrl}/list/${applicationUuid}${utmInformation}`);
  }, [admissionApplicationData, utmInformation]);

  return <LoadingPage />;
}

export default RedirectToDetailPage;
