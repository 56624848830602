import React, { SetStateAction } from 'react';
import { List, Row, Col, Tooltip, Upload, Spin, Modal, Checkbox } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from '@emotion/styled';

import { BasicContainer } from '../../../../components/atoms/Container';
import { SectionLabel } from '../../../../components/atoms/Label';
import { ReactComponent as FileIcon } from '../../../../assets/svg/ico-file.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/svg/ico-download.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/ico-delete.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/svg/ico-link.svg';

import type { UploadChangeParam } from 'antd/lib/upload';
import type { UploadCustomRequest, OnSuccessType, OnErrorType } from '../type';
import type { Resume } from '../../common/apollo/jobs/type';

import { Color } from '../../../../style';
import Urls from '../../../../util/urls';
import { makeToast } from '../../../../util/makeToast';

type JobsResumeProps = {
  options: string[];
  isUploading: boolean;
  selectedOptions: string[];
  setIsUploading: React.Dispatch<SetStateAction<boolean>>;
  setSelectedOptions: React.Dispatch<SetStateAction<string[]>>;

  hasUploadedResume: boolean;
  resumes: Resume[];
  handleDeleteResume: (resumeId: number) => void;
  handleSetDefaultResume: (resumeId: number, hasUploadedResume: boolean) => void;
  handleResumeFileUpload: (file: File, onComplete: OnSuccessType, onError: OnErrorType) => Promise<void>;
  handleChangeUploadStatus: (
    info: UploadChangeParam,
    hasUploadedResume: boolean,
    handleSetDefaultResume: (resumeId: number, hasUploadedResume: boolean) => void
  ) => void;
};

const JobsResumeWrapper = styled.div`
  .ant-row {
    flex-flow: row;
  }
  .content-default-resume-title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .content-title {
    font-size: 15px;
    color: #333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .content {
    font-size: 15px;
  }

  .icon {
    color: ${Color.gray};
    cursor: pointer;
  }

  .icon-default-resume {
    margin-top: 5px;
    color: #295ce0;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
  .ico__delete {
    color: #455573;
    cursor: pointer;
  }
  .ico__link {
    cursor: pointer;
  }
  .download {
    color: #455573;

    @media screen and (max-width: 380px) {
      display: none;
    }
  }

  .block-message {
    text-align: center;
    margin: 30px 0px;
    color: #aaa;
    font-style: italic;
  }
`;

const ModalCheckListWrapper = styled.div`
  .modal__checklist {
    .ant-checkbox-group-item {
      margin-bottom: 10px;
      word-break: keep-all;
    }
  }
`;

const JobsResumeUploadWrapper = styled.div`
  .ant-upload {
    background-color: #f9fafb;
    border: none;
    border-radius: 12px;
    &:hover {
      background-color: #f2f4f7;
      opacity: 0.85;
    }
    .ant-upload-text {
      color: #455573;
    }
  }
  margin: 30px 0px;

  .upload-icon {
    font-size: 40px;
    margin-bottom: 4px;
    color: #455573;
  }

  .loading {
    margin: 15px 0px;
  }
`;

const JobsResume: React.FC<JobsResumeProps> = props => {
  const handleResumeFileUploadWithConfirm: UploadCustomRequest = async ({ file, onSuccess, onError }) => {
    const modal = Modal.confirm({
      title: '이력서 업로드 전 체크리스트',
      content: (
        <ModalCheckListWrapper>
          <Checkbox.Group
            className="modal__checklist"
            options={props.options}
            onChange={checkedValues => {
              props.setSelectedOptions(checkedValues as string[]);
              modal.update({
                okButtonProps: {
                  disabled: checkedValues.length !== props.options.length
                }
              });
            }}
          />
        </ModalCheckListWrapper>
      ),
      onOk: () => {
        if (file instanceof File) {
          props.handleResumeFileUpload(file, onSuccess, onError);
        }
      },
      onCancel: () => {
        props.setIsUploading(false);
        props.setSelectedOptions([]);
      },
      okText: '이력서 제출',
      cancelText: '취소',
      okButtonProps: {
        disabled: props.selectedOptions.length !== props.options.length
      },
      width: 600
    });
  };
  return (
    <JobsResumeWrapper className="section-resume">
      <SectionLabel>이력서</SectionLabel>
      <BasicContainer>
        <List itemLayout="vertical" size="large">
          {props.resumes.length ? (
            props.resumes.map((resume: Resume, idx: number) => (
              <List.Item key={resume.id}>
                <Row style={{ margin: '10px 0px' }}>
                  <Col span={2}>
                    <Tooltip title={resume.isPublished ? '' : '기본 이력서로 지정'}>
                      <FileIcon
                        className={resume.isPublished ? 'icon-default-resume' : 'icon'}
                        onClick={() => {
                          if (!resume.isPublished) {
                            props.handleSetDefaultResume(resume.id, props.hasUploadedResume);
                          }
                        }}
                      />
                    </Tooltip>
                  </Col>
                  <Col span={12}>
                    <div className={resume.isPublished ? 'content-default-resume-title' : 'content-title'}>
                      {resume.title}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="content">{moment(resume.createdAt).format('YYYY.MM.DD HH:mm')}</div>
                  </Col>
                  <Col span={2}>
                    <a className="download" href={resume.fileURL} download={resume.title}>
                      <DownloadIcon onClick={() => console.log('file download')} />
                    </a>
                  </Col>
                  <Col span={2}>
                    {!resume.isPublished ? (
                      <DeleteIcon
                        className="ico__delete"
                        onClick={() => {
                          props.handleDeleteResume(resume.id);
                        }}
                      />
                    ) : (
                      <LinkIcon
                        className="ico__link"
                        onClick={() => {
                          navigator.clipboard.writeText(`${Urls().urclass}resume/${resume.storageKey}`);
                          makeToast({
                            type: 'success',
                            content: '복사 되었습니다.'
                          });
                        }}
                      />
                    )}
                  </Col>
                  {props.resumes.length === 1 && (
                    <Col span={2}>
                      <DeleteIcon
                        className="ico__delete"
                        onClick={() => {
                          props.handleDeleteResume(resume.id);
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </List.Item>
            ))
          ) : (
            <div className="block-message">업로드된 이력서가 없습니다.</div>
          )}
        </List>
      </BasicContainer>

      <JobsResumeUploadWrapper>
        <Upload.Dragger
          multiple={false}
          customRequest={handleResumeFileUploadWithConfirm}
          onChange={info => {
            props.handleChangeUploadStatus(info, props.hasUploadedResume, props.handleSetDefaultResume);
          }}
          showUploadList={false}
          disabled={props.isUploading}
        >
          {props.isUploading ? (
            <Spin className="loading" tip="Uploading..." />
          ) : (
            <>
              <CloudUploadOutlined className="upload-icon" />
              <p className="ant-upload-text">파일을 드래그 하여 첨부하거나 클릭해주세요</p>
              <p className="ant-upload-hint">5MB 이하의 PDF파일만 업로드 가능합니다.</p>
            </>
          )}
        </Upload.Dragger>
      </JobsResumeUploadWrapper>
    </JobsResumeWrapper>
  );
};

export default JobsResume;
