import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Progress } from 'antd';

import { ImageCard } from '../common/ImageCard';
import { Grid } from '../common/Grid';

import { progressCalculation, displayDueDate } from '../../../../util';
import { ReactComponent as SortArrow } from '../../assets/svg/ico-arrow-sort.svg';

import type { Classroom } from '../../apollo/coplit/type';

const ClassroomsWrapper = styled.div`
  .ant-progress-bg {
    height: 3px !important;
    background-color: #295ce0;
  }

  .progress-info {
    font-size: 14px;
    font-weight: 500;
    color: #295ce0;
  }

  .progress-info-none {
    font-size: 14px;
    font-weight: 500;
    color: #aaa;
  }

  .progress-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .item {
      font-size: 14px;
      font-weight: 500;
      color: #aaa;
      margin-bottom: -7px;
    }
  }
  .button-header-container {
    display: flex;
    justify-content: right;
  }

  .sort-button {
    width: 123px;
    height: 30px;
    border-radius: 12px;
    background-color: #edf1f5;
    color: #667ba3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    position: right;

    .sort-arrow {
      margin: 0.2rem;
    }
  }
  .sort-button:hover {
    cursor: pointer;
  }
`;

type ClassroomsProps = {
  classrooms: Classroom[];
};

export const Classrooms: React.FC<ClassroomsProps> = ({ classrooms }) => {
  const reversedClassrooms = classrooms.slice().reverse();
  const [isReverseSort, setIsReverseSort] = useState<boolean>(false);

  return (
    <ClassroomsWrapper>
      <div className="button-header-container">
        <div className="sort-button" onClick={() => setIsReverseSort(!isReverseSort)}>
          <SortArrow className="sort-arrow" />
          {isReverseSort ? '기본순' : '역순'} 정렬
        </div>
      </div>
      <Grid itemWidth={360} gridGap={24} rowGap={80}>
        {(isReverseSort ? reversedClassrooms : classrooms).map(classroom => {
          const progress = progressCalculation(classroom.totalCodeProblemCount, classroom.restCodeProblemCount);

          return (
            <Link to={`/classroom/${classroom.id}`} key={classroom.id}>
              <ImageCard
                title={classroom.title}
                description={classroom.description}
                imageUrl={classroom.imageURL}
                footer={
                  <div>
                    <Progress size="small" showInfo={false} percent={progress} />
                    <div className="progress-nav">
                      <div className={progress ? 'progress-info' : 'progress-info-none'}>
                        {progress ? `${progress}% Complete` : 'Please begin studying!'}
                      </div>
                      {!!classroom.dueDate ? (
                        <div className="item">
                          {Number.isNaN(Number(displayDueDate(classroom.dueDate)))
                            ? displayDueDate(classroom.dueDate)
                            : `${displayDueDate(classroom.dueDate)} days left`}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                }
              />
            </Link>
          );
        })}
      </Grid>
    </ClassroomsWrapper>
  );
};
