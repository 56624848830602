import TagManager from 'react-gtm-module';
export const handleGtmMultiple = (checked: boolean, text: string) => {
  if (checked) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'quiz_selection',
        selection_type: 'check',
        selection_text: text
      }
    });
  } else {
    TagManager.dataLayer({
      dataLayer: {
        event: 'quiz_selection',
        selection_type: 'unCheck',
        selection_text: text
      }
    });
  }
};

export const handleGtmRadio = (text: string) => {
  TagManager.dataLayer({
    dataLayer: { event: 'quiz_selection', selection_type: 'radio', selection_text: text }
  });
};
