import React from 'react';
import styled from '@emotion/styled';
import { Result, Button } from 'antd';

import { GeneralTemplate } from '../../templates';
import { GeneralHeader } from '../../components/organisms/Header';

import { store } from '../../util';

const PaymentFailPageWrapper = styled.div`
  .payment__result_fail {
    text-align: center;
    .message {
      color: gray;
      font-size: 14px;
    }
    .code {
      color: gray;
      font-weight: bold;
      font-size: 14px;
    }
    .description {
    }
  }
`;

const PaymentFailPage: React.FC = () => {
  const params = new URLSearchParams(window.location.search);

  const code = params.get('code');

  const message = params.get('message');

  const INVALID_ACCESS = '잘못된 접근입니다.';

  store.paidProgramType.remove();

  return (
    <GeneralTemplate>
      {{
        header: <GeneralHeader />,
        body: (
          <PaymentFailPageWrapper>
            <Result className="payment__result_fail" status="error" title="결제를 실패 하였습니다.">
              <p>
                <span className="code">{code}</span>
              </p>
              <p>
                <span className="message">{message || INVALID_ACCESS}</span>
              </p>
              <p>
                <span className="description">
                  다시 시도 바랍니다. 위 상황이 반복 되면
                  <a href="mailto:communication@codestates.com">communication@codestates.com</a>
                  으로 문의 부탁 드립니다.
                </span>
              </p>

              <Button
                type="primary"
                onClick={() => {
                  window.location.replace('/');
                }}
              >
                메인페이지로 이동
              </Button>
            </Result>
          </PaymentFailPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default PaymentFailPage;
