import { Redirect } from 'react-router-dom';
import { store } from '../util';

// urclass page
import MainPage from '../pages/main';
import WelcomePage from '../pages/welcome';
import LoginPage from '../pages/login';
import LoginRedirectPage from '../pages/loginRedirect';

import ApplyPage from '../pages/apply';
import ApplyProductBundlePage from '../pages/applyProductBundle';
import MyPage from '../pages/mypage';
import OneTimePaymentPage from '../pages/oneTimePayment';
import PaymentResultPage from '../pages/paymentResult';
import PaymentSuccessPage from '../pages/paymentSuccess';
import PaymentFailPage from '../pages/paymentFail';
import SignUpPage from '../pages/signUp';
import PaymentMobileRedirectPage from '../pages/paymentMobileRedirect';
import ApplicationFormPage from '../pages/form/ApplicationFormPage';
import ApplicationPaymentPage from '../pages/detailApplication/ApplicationPaymentPage';
import OTPPage from '../pages/otp';
import TermPage from '../pages/term';
import CancelApplicationPage from '../pages/cancelApplication';
import CertificatesPage from '../pages/certificates';
import CertificateDownloadPage from '../pages/certificateDownload';
import EditUserProfilePage from '../pages/editUserProfile';
import IdentityVerficationPage from '../pages/identityVerification';
import DetailApplicationPage from '../pages/detailApplication/DetailApplicationPage';
import CuratedMainPage from '../pages/mainCurating';

// general
import ErrorPage from '../components/ErrorPage';
import ApplyResultPage from '../pages/applyResult';
import ApplyResultB2bPage from '../pages/applyResultB2b';

// outcomes
import JobsPage from '../pages/outcomes-jobs/jobs';
import NewJobProfilePage from '../pages/outcomes-jobs/newJobProfile';
import ResumeViewer from '../pages/outcomes-jobs/resume';

// lms
import ContentPage from '../domain/lms/pages/contents';
import ClassroomsPage from '../domain/lms/pages/classrooms';
import ClassroomPage from '../domain/lms/pages/classroom';
import CodeProblemPage from '../domain/lms/pages/codeProblem';
import CoursePage from '../domain/lms/pages/course';
import PlaylistsPage from '../domain/lms/pages/playlist';
import AdmissionApplicationFormPage from '../pages/form/AdmissionApplicationFormPage';
import RedirectToDetailPage from '../pages/detailApplication/RedirectToDetailPage';
import LoginTempararyPage from '../pages/loginTemparay';

export const URCLASS_PAGE_ROUTES = [
  {
    pathname: '/',
    exact: true,
    component: () => {
      store.redirectURL.remove();
      return <MainPage />;
    },
    redirect_component: () => {
      store.redirectURL.remove();
      return <WelcomePage />;
    },
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/curating',
    component: () => {
      store.redirectURL.remove();
      return <CuratedMainPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/loginTemparary/:uuid',
    component: () => {
      store.redirectURL.remove();
      return <LoginTempararyPage />;
    },
    access: {
      requireAuth: false
    }
  },
  {
    pathname: '/mobile/payment/callback',
    component: PaymentMobileRedirectPage,
    redirect_component: ErrorPage,
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/payment/success',
    component: PaymentSuccessPage,
    redirect_component: ErrorPage,
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/payment/fail',
    component: PaymentFailPage,
    redirect_component: ErrorPage,
    access: {
      requireAuth: true
    }
  },

  {
    pathname: '/payment/result',
    component: PaymentResultPage,
    redirect_component: ErrorPage,
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/apply',
    exact: true,
    component: ErrorPage,
    redirect_component: ErrorPage,
    access: {
      requireAuth: false
    }
  },

  {
    pathname: '/oneTimePayment',
    exact: true,
    component: ErrorPage,
    redirect_component: ErrorPage,
    access: {
      requireAuth: false
    }
  },

  {
    pathname: '/oneTimePayment/:uuid',
    component: () => {
      store.redirectURL.remove();
      return <OneTimePaymentPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/apply/result/:uuid',
    component: () => <ApplyResultPage />,
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/apply/result-b2b/:uuid',
    component: () => <ApplyResultB2bPage />,
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/apply/product-bundle/:uuid',
    component: () => {
      store.redirectURL.remove();
      return <ApplyProductBundlePage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },

  {
    pathname: '/apply/:uuid',
    component: () => {
      store.redirectURL.remove();
      return <ApplyPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },

  {
    pathname: '/additional',
    component: MainPage,
    redirect_component: SignUpPage,
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/login',
    component: () => <Redirect to="/" />,
    redirect_component: LoginPage,
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/auth',
    component: LoginRedirectPage,
    access: {
      requireAuth: false
    }
  },
  {
    pathname: '/codeproblem/:uuid',
    component: () => {
      store.redirectURL.remove();
      return <CodeProblemPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/classrooms',
    component: () => {
      store.redirectURL.remove();
      return <ClassroomsPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/classroom/:id',
    component: () => {
      store.redirectURL.remove();
      return <ClassroomPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/course/:id',
    component: () => {
      store.redirectURL.remove();
      return <PlaylistsPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/courses',
    component: () => {
      store.redirectURL.remove();
      return <CoursePage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },

  {
    pathname: '/jobs',
    component: () => {
      store.redirectURL.remove();
      return <JobsPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    exact: true,
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/application/cancel/:uuid',
    exact: true,
    component: CancelApplicationPage,
    redirect_component: ErrorPage,
    access: {
      requireAuth: true
    }
  },

  {
    pathname: '/application/:slugUrl/:steps?',
    component: () => {
      store.redirectURL.remove();
      return <AdmissionApplicationFormPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },

  {
    pathname: '/jobs/new',
    component: () => {
      store.redirectURL.remove();
      return <NewJobProfilePage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    exact: true,
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/mypage/certificates/download',
    exact: true,
    component: () => {
      store.redirectURL.remove();
      return <CertificateDownloadPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location.href);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },

  {
    pathname: '/mypage',
    component: () => {
      store.redirectURL.remove();
      return <MyPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    exact: true,
    access: {
      requireAuth: true
    }
  },

  {
    pathname: '/applications/form/:uuid/typeform/:typeformId/order/:steps?',
    component: () => {
      store.redirectURL.remove();
      return <ApplicationFormPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/applications/form/:uuid/list',
    component: () => {
      store.redirectURL.remove();
      return <DetailApplicationPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    exact: true,
    access: {
      requireAuth: true
    }
  },

  {
    pathname: '/applications/:uuid',
    component: () => {
      store.redirectURL.remove();
      return <RedirectToDetailPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    exact: true,
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/applications/:slugUrl/list/:uuid?',
    component: () => {
      store.redirectURL.remove();
      return <DetailApplicationPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    exact: true,
    access: {
      requireAuth: true
    }
  },

  {
    pathname: '/mypage/certificates',
    component: () => {
      return <CertificatesPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    exact: true,
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/resume/:uuid',
    component: ResumeViewer,
    redirect_component: ErrorPage,
    access: {
      requireAuth: false
    }
  },
  {
    pathname: '/otp',
    component: OTPPage,
    redirect_component: ErrorPage,
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/term',
    exact: true,
    component: TermPage,
    redirect_component: ErrorPage,
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/user-profile/edit',
    exact: true,
    component: EditUserProfilePage,
    redirect_component: ErrorPage,
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/identity-verification',
    exact: true,
    component: IdentityVerficationPage,
    redirect_component: ErrorPage,
    access: { requireAuth: false }
  },
  {
    pathname: '/playlist/:id',
    component: () => {
      store.redirectURL.remove();
      return <ContentPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/applications/:uuid/payment',
    component: () => {
      store.redirectURL.remove();
      return <ApplicationPaymentPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '/content/:content',
    component: () => {
      store.redirectURL.remove();
      return <ContentPage />;
    },
    redirect_component: () => {
      store.redirectURL.set(window.location);
      return <Redirect to="/login" />;
    },
    access: {
      requireAuth: true
    }
  },
  {
    pathname: '*',
    access: {
      requireAuth: true
    },
    component: () => {
      return <ErrorPage />;
    }
  }
];
