import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Grid } from '../common/Grid';
import { CourseStatus } from '../../apollo/course/type';
import { EmptyContentViewer } from '../common/EmptyContentViewer';
import { ImageCard } from '../common/ImageCard';

import { ReactComponent as ArrowRight } from '../../assets/svg/ico-arrow-right.svg';
import { ReactComponent as SortArrow } from '../../assets/svg/ico-arrow-sort.svg';

import type { Course } from '../../apollo/course/type';

type CourseCardGroupProps = { courses: Course[] };

const CourseCardGroupWrapper = styled.div`
  margin-bottom: 50px;

  .button-header-container {
    display: flex;
    justify-content: right;
  }

  .sort-button {
    width: 123px;
    height: 30px;
    border-radius: 12px;
    background-color: #edf1f5;
    color: #667ba3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    position: right;

    .sort-arrow {
      margin: 0.2rem;
    }
  }
  .sort-button:hover {
    cursor: pointer;
  }
`;

const ArrowButton = styled.div`
  text-align: right;

  .nav-text {
    font-weight: 700;
    color: #ccd3e0;
    margin-right: 10px;
  }

  .arrow {
    width: 90px;
    color: #ccd3e0;
  }
`;

export const CourseCardGroup: React.FC<CourseCardGroupProps> = ({ courses }) => {
  const reversedCourses = courses.slice().reverse();
  const [isReverseSort, setIsReverseSort] = useState<boolean>(false);

  if (!courses.length) {
    return (
      <EmptyContentViewer
        title={'코스가 비어있습니다.'}
        description={'자세한 코스 정보는 마이페이지를 확인해주세요.'}
      />
    );
  }

  return (
    <CourseCardGroupWrapper>
      <div className="button-header-container">
        <div className="sort-button" onClick={() => setIsReverseSort(!isReverseSort)}>
          <SortArrow className="sort-arrow" />
          {isReverseSort ? '기본순' : '역순'} 정렬
        </div>
      </div>

      <Grid itemWidth={360} gridGap={40}>
        {(isReverseSort ? reversedCourses : courses).map((course: Course) => {
          const { id, name, description, status } = course;

          return (
            <Link to={location => (status === CourseStatus.EXPIRED ? location : `/course/${id}/curriculum`)} key={id}>
              <ImageCard
                title={name}
                description={description}
                footer={
                  <ArrowButton>
                    <span className="nav-text">학습하기</span>
                    <ArrowRight className="arrow" />
                  </ArrowButton>
                }
                disabled={status === CourseStatus.EXPIRED}
              />
            </Link>
          );
        })}
      </Grid>
    </CourseCardGroupWrapper>
  );
};
