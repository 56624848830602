import React from 'react';
import styled from '@emotion/styled';
import { Result, Button } from 'antd';

import { useCredential } from '../../../../contexts/credential';

import { ContentTemplate, GeneralTemplate } from '../../../../templates';
import { useController } from './controller';

import { ReactComponent as LinkContentIcon } from '../../assets/svg/linkcontent.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';

import { ContentSidebar } from '../../components/contents/ContentSidebar';
import { MarkdownPreviewer } from '../../components/common/StyledMarkdown';
import ErrorPage from '../../../../components/ErrorPage';
import LoadingPage from '../../../../components/LoadingPage';
import { GeneralHeader } from '../../../../components/organisms/Header';

import QuizSetView from './organisms/QuizSetView';
import ContentNav from './organisms/ContentNav';
import NoContent from './organisms/NoContent';

import { compileContentTemplate } from '../../../../util';

import type { Playlist, PlaylistContent } from '../../apollo/contents/types';
import { PlaylistCard } from './organisms/PlaylistCard';
import { ColorPalette } from '../../../../styles/color';
import { handleGtmMultiple, handleGtmRadio } from '../../utils/tagManager';

const ResultModal = styled.div`
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 491px;
  height: 313px;
  background-color: #ffffff;
  z-index: 500;
  border-radius: 18px;

  .quiz-result-modal-header {
    padding: 1rem 25px;
    display: flex;
    width: 100%;
  }

  .quiz-result-modal-title {
    font-weight: 500;
    font-size: 1.2rem;
  }

  .quiz-modal-close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .quiz-result-modal-body {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .quiz-result-modal-item {
      text-align: center;

      .quiz-result-modal-item__value {
        font-size: 40px;
        font-weight: bolder;
        color: black;

        &.passed {
          color: #149b58;
        }

        &.failed {
          color: #fc570d;
        }

        &.correct-rate {
          color: #3402d5;
        }
      }

      .quiz-result-modal-item__label {
        font-size: 14px;
        font-weight: 500;
        color: #777;
      }
    }
  }

  .quiz-result-modal-footer {
    border-radius: 18px;
    text-align: end;
    margin: 2rem 1.5rem 1rem auto;

    .quiz-result-modal-footer__retry-btn,
    .quiz-result-modal-footer__ok-btn {
      background-color: #1f41d9;
      color: white;
    }

    .quiz-result-modal-footer__retry-btn,
    .quiz-result-modal-footer__submit-btn {
      font-size: 15px;
      width: 100px;
      height: 45px;
      margin-left: 12px;
      border-radius: 6px;
    }
  }
`;

const ResultSubmitCheckModal = styled.div`
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 18px;
  z-index: 9999;
  font-size: 17px;
  background-color: #ffffff;
  border-radius: 18px;
  width: 278px;
  height: 338px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .quiz-modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .result-check-modal-text {
    text-align: center;
    margin: 4rem auto;
    font-weight: 500;
    font-size: 1rem;
  }

  .result-check-modal-button-container {
    margin: 1rem;
  }

  .result-check-modal-button {
    width: 230px;
    height: 45px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    margin: 0.5rem;
    cursor: pointer;
  }

  .ok {
    background-color: #1f41d9;
  }

  .no {
    background-color: #a3aec7;
  }
`;

const ContentView = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 12px 36px;
  color: #455573;
  max-width: 1020px;
  .content-view__header {
    padding: 5px;
    margin: 0 auto;

    .content-view__title {
      margin: 25px auto 0;
      font-weight: 700;
    }
  }

  .content-view__body {
    .txt__content {
      display: block;
      text-align: center;
      font-weight: bold;
      font-size: 17px;
      margin-bottom: 30px;
    }

    .content-view__redirect-btn {
      background-color: #452cdd;
      border: none;
      border-radius: 6px;
      color: white;
      font-size: 16px;
      padding: 7px 20px;
      height: auto;

      &:hover {
        opacity: 0.5;
      }

      &:focus {
        opacity: 0.5;
      }
    }

    /* Moved from StyledContent */
    overflow-wrap: break-word;
    word-break: keep-all;
    margin-top: 30px;
    & > h1.title {
      font-size: 1.2em;
      position: sticky;
      padding: 8px;
      top: 0;
      z-index: 2;

      margin: -12px -12px 12px;
      overflow: hidden;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    .iframe-wrapper {
      position: relative;
      padding-top: 56.25%;
      margin: 15px 10px 20px;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
      }
    }

    pre {
      max-width: 100%;
      overflow: auto;
      padding: 10px;
      font-size: 0.9em;
    }

    @media (max-width: 992px) {
      .contents > h1.title {
        padding-left: 40px;
      }
    }

    @media screen and (max-width: 992px) {
      margin-top: 80px;
    }

    @media screen and (max-width: 1100px) {
      margin-right: 0px;
    }
  }

  .playlist-card-section {
    display: flex;
    flex-direction: column;
    padding: 0 0px 150px;
    text-align: right;
    border-top: 1px solid #eaecef;
  }

  .playlist-card-title-container {
    display: flex;
    justify-content: space-between;
    margin: 80px 0 32px 0;
  }

  .playlist-card-title {
    font-weight: 700;
    font-size: 1.3rem;
  }

  .playlist-card-button {
    width: 94px;
    height: 27px;
    border: solid 1px #d8d9d9;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    font-weight: 700;
    font-size: 12px;
  }

  .playlist-card-button:hover {
    cursor: pointer;
  }

  .playlist-card-container {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      justify-content: center;
    }

    @media screen and (max-width: 994px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media screen and (max-width: 720px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .playlist-card-container-left {
    display: flex;
    justify-content: left;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      justify-content: center;
    }

    @media screen and (max-width: 994px) {
      flex-direction: row;
      justify-content: start;
    }

    @media screen and (max-width: 720px) {
      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (max-width: 576px) {
    padding: 12px;
  }
`;

const QuizSetModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  -webkit-transition: opacity 200ms linear;
  -moz-transition: opacity 200ms linear;
  -ms-transition: opacity 200ms linear;
  -o-transition: opacity 200ms linear;
  transition: opacity 200ms linear;
`;

const QuizInfoModal = styled.div`
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 18px;
  z-index: 900;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 278px;
  height: 338px;
  border-radius: 18px;

  .quiz-modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .quiz-modal-text {
    text-align: center;
    margin: 3rem auto;
    font-weight: 500;
    font-size: 1.1rem;
  }

  .quiz-modal-button {
    width: 230px;
    height: 45px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    margin: 0.5rem;
    cursor: pointer;
  }

  .ok {
    background-color: #1f41d9;
  }
`;

const ContentPage: React.FC = () => {
  const { profile } = useCredential();

  const {
    playlistDataError,
    playlistDataLoading,
    playlist,
    playlistId,
    playlistsData,
    history,
    contentUuid,
    availablePlaylistContents,
    currentPlaylistContent,
    courseId,
    playlistsForShortcuts,
    handleToggleMobileMenu,
    isMobileMenuVisible,
    handleCloseMobileMenu,
    quizSet,
    currentQuizIndex,
    currentQuiz,
    currentQuizState,
    quizStates,
    handleInputDescriptiveAnswer,
    handleSelectChoice,
    handleMoveQuizStep,
    handleCheckSolution,
    handleShowResult,
    handleMovePrevQuiz,
    handleMoveNextQuiz,
    handleSubmitQuizSet,
    isFirstQuiz,
    isLastQuiz,
    isSolutionCheckable,
    isSubmitable,
    isValidInput,
    isCurrentQuizMultiChoiceType,
    isInViewport,
    isSubmittedQuizSetModalOpen,
    isAssessmentInfoModalOpen,
    isResultSubmitCheckModalOpen,
    isResetQuizSubmissionsModalOpen,
    contentRef,
    contentEndRef,

    quizResultModalVisible,
    setQuizResultModalVisible,
    setIsSubmittedQuizSetModalOpen,
    setIsAssessmentInfoModalOpen,
    setIsResultSubmitCheckModalOpen,
    setIsResetQuizSubmissionsModalOpen,
    handleCancelQuizResultModal,
    handleResetQuizSubmissions,
    handleClickRetryButton,
    handleClickSubmitButton,

    quizCount,
    passedQuizCount,
    failedQuizCount,
    passedPercentage,

    isSubmittedQuizSet
  } = useController();

  if (playlistDataLoading) {
    return <LoadingPage />;
  }
  if (!currentPlaylistContent) {
    return (
      <GeneralTemplate fixedHeader={false} backgroundColor={ColorPalette.Gray8}>
        {{
          header: <GeneralHeader />,
          body: <NoContent />,
          footer: <></>
        }}
      </GeneralTemplate>
    );
  }

  if (playlistDataError) {
    return <ErrorPage />;
  }

  if (!profile) {
    return <></>;
  }

  const renderContentByType = (playlistContent: PlaylistContent, playlist?: Playlist) => {
    const body = compileContentTemplate(currentPlaylistContent.content.version.body, {
      email: profile!.email,
      name: profile!.name,
      userId: profile!.id.toString(),
      cohortName: profile!.cohortName,
      githubId: profile!.githubId?.toString() || '',
      githubUserName: profile!.githubUserName || '',
      courseName: playlist?.course?.name || '',
      curriculumId: playlist?.id.toString() || '',
      curriculumName: playlist?.name || '',
      contentName: playlistContent.content.version?.title || ''
    });

    switch (playlistContent.content.version.type) {
      case 'link': {
        return (
          <div style={{ marginTop: '5%' }}>
            <Result
              status="info"
              icon={<LinkContentIcon />}
              extra={
                <div>
                  <span className="txt__content">아래 링크에서 콘텐츠를 학습하세요.</span>
                  <Button
                    className="content-view__redirect-btn"
                    onClick={() => {
                      window.open(body, '_blank');
                    }}
                  >
                    학습하러 가기
                  </Button>
                </div>
              }
            />
          </div>
        );
      }
      case 'text': {
        return <MarkdownPreviewer markdownBody={body} />;
      }
      case 'embed': {
        if (body.indexOf('slides.com') !== -1) {
          return (
            <div className="iframe-wrapper">
              <iframe
                title="embedSlideFrame"
                frameBorder="0"
                allowFullScreen={true}
                src={body[body.length - 1] === '/' ? `${body}embed` : `${body}/embed`}
                onLoad={() => <LoadingPage />}
              />
            </div>
          );
        }

        return (
          <div className="iframe-wrapper">
            <iframe title="embedFrame" frameBorder="0" allowFullScreen={true} src={body} />
          </div>
        );
      }
      case 'video': {
        if (body.indexOf('vimeo.com') !== -1) {
          const url = new URL(body);

          const [, videoId, h] = url.pathname.split('/');

          if (videoId) {
            let embedLink = new URL(`https://player.vimeo.com/video/${videoId}`);
            if (h) {
              embedLink.searchParams.set('h', h);
            }

            return (
              <div className="iframe-wrapper">
                <iframe
                  title="vimeoFrame"
                  frameBorder="0"
                  allowFullScreen={true}
                  src={embedLink.toString()}
                  onLoad={() => <LoadingPage />}
                />
              </div>
            );
          }

          return <p>유효하지 않은 비디오 링크 입니다.</p>;
        }

        if (body.indexOf('youtu.be') !== -1) {
          const videoCode = body.split('youtu.be/')[1] || '';
          const embedLink = `https://www.youtube.com/embed/${videoCode}`;

          return (
            <div className="iframe-wrapper">
              <iframe
                title="vimeoFrame"
                frameBorder="0"
                allowFullScreen={true}
                src={embedLink}
                onLoad={() => <LoadingPage />}
              />
            </div>
          );
        }

        return (
          <div className="iframe-wrapper">
            <iframe title="etcVideoFrame" frameBorder="0" allowFullScreen={true} src={body} />
          </div>
        );
      }
      case 'quiz_set': {
        return (
          <QuizSetView
            quizSet={quizSet}
            currentQuizIndex={currentQuizIndex}
            currentQuiz={currentQuiz}
            currentQuizState={currentQuizState}
            quizStates={quizStates}
            handleClickQuizStep={handleMoveQuizStep}
            handleInputDescriptiveAnswer={handleInputDescriptiveAnswer}
            handleSelectChoice={handleSelectChoice}
            handleClickCheckSolution={handleCheckSolution}
            handleClickSubmit={handleShowResult}
            handleClickPrevQuiz={handleMovePrevQuiz}
            handleClickNextQuiz={handleMoveNextQuiz}
            hadnleGtmMultiple={handleGtmMultiple}
            handleGtmRadio={handleGtmRadio}
            isFirstQuiz={isFirstQuiz}
            isLastQuiz={isLastQuiz}
            isSubmitable={isSubmitable}
            isValidInput={isValidInput}
            isSolutionCheckable={isSolutionCheckable}
            isCurrentQuizMultiChoiceType={isCurrentQuizMultiChoiceType}
            isSubmittedQuizSet={isSubmittedQuizSet}
          />
        );
      }
      case 'gitbook': {
        return (
          <div>
            <iframe width="100%" height="1200" frameBorder="0" src={body} allowFullScreen={true} />
          </div>
        );
      }
      default: {
        return <span>오류가 발생했습니다. 다시 시도해주세요.</span>;
      }
    }
  };
  return (
    <ContentTemplate mobileMenuVisible={isMobileMenuVisible} handleClickCloseMobileMenu={handleCloseMobileMenu}>
      {{
        header: (
          <GeneralHeader handleToggleMobileMenu={handleToggleMobileMenu} isMobileMenuVisible={isMobileMenuVisible} />
        ),
        body: (
          <ContentView>
            <div className="content-view__header">
              {currentPlaylistContent.content.version.type === 'gitbook' ? null : (
                <h1 className="content-view__title">
                  <h1>{currentPlaylistContent.content.version.title}</h1>
                </h1>
              )}
              {currentPlaylistContent.content.version.type === 'gitbook' ? null : (
                <div className="content-view__description">
                  <MarkdownPreviewer markdownBody={currentPlaylistContent.content.version.description} />
                </div>
              )}
            </div>

            <div>
              <div ref={contentRef} className="content-view__body">
                {renderContentByType(currentPlaylistContent, playlist)}
              </div>
            </div>
            <div className="content-view__footer">
              {playlist && (
                <>
                  <ContentNav
                    playlistContents={availablePlaylistContents}
                    playlistContent={currentPlaylistContent}
                    contentUuid={contentUuid}
                    playlistId={playlistId}
                    courseId={courseId}
                    isInViewport={isInViewport}
                    isSubmittedQuizSet={isSubmittedQuizSet}
                    playlistsData={playlistsData}
                  />
                  <div ref={contentEndRef}></div>
                </>
              )}
            </div>

            <div className="playlist-card-section">
              <div className="playlist-card-title-container">
                <div className="playlist-card-title">다른 커리큘럼 바로가기</div>
                <div
                  className="playlist-card-button"
                  onClick={() => {
                    history.replace(`/course/${courseId}/curriculum`);
                  }}
                >
                  목록보기
                </div>
              </div>
              <div
                className={
                  playlistsForShortcuts.length === 2 ? 'playlist-card-container-left' : 'playlist-card-container'
                }
              >
                {playlistsForShortcuts.length > 0
                  ? playlistsForShortcuts.map((playlistData: any) => {
                      return (
                        <PlaylistCard
                          playlistData={playlistData}
                          leftAligned={playlistsForShortcuts.length === 2 ? true : false}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
            {isSubmittedQuizSetModalOpen ? (
              <>
                <QuizSetModalBackground onClick={() => setIsSubmittedQuizSetModalOpen(false)}></QuizSetModalBackground>
                <QuizInfoModal>
                  <CloseIcon className="quiz-modal-close" onClick={() => setIsSubmittedQuizSetModalOpen(false)} />
                  <div className="quiz-modal-text">
                    이미 제출한 퀴즈입니다.
                    <br />
                    기존 제출 내역만
                    <br />
                    확인 할 수 있습니다.
                  </div>
                  <div
                    className="quiz-modal-button ok"
                    onClick={() => {
                      setIsSubmittedQuizSetModalOpen(false);
                    }}
                  >
                    네, 알겠습니다.
                  </div>
                </QuizInfoModal>
              </>
            ) : null}
            {isAssessmentInfoModalOpen ? (
              <>
                <QuizSetModalBackground onClick={() => setIsAssessmentInfoModalOpen(false)}></QuizSetModalBackground>
                <QuizInfoModal>
                  <CloseIcon className="quiz-modal-close" onClick={() => setIsAssessmentInfoModalOpen(false)} />
                  <div className="quiz-modal-text">
                    평가용 퀴즈입니다.
                    <br />단 한 번만 제출 가능하며,
                    <br />
                    풀이 결과가 평가에 반영됩니다.
                  </div>
                  <div
                    className="quiz-modal-button ok"
                    onClick={() => {
                      setIsAssessmentInfoModalOpen(false);
                    }}
                  >
                    네, 알겠습니다.
                  </div>
                </QuizInfoModal>
              </>
            ) : null}
            {quizResultModalVisible ? (
              <>
                <QuizSetModalBackground
                  onClick={() => {
                    setQuizResultModalVisible(false);
                  }}
                ></QuizSetModalBackground>
                <ResultModal>
                  <div className="quiz-result-modal-header">
                    <div className="quiz-result-modal-title">최종 결과</div>
                    <CloseIcon
                      className="quiz-modal-close"
                      onClick={() => {
                        setQuizResultModalVisible(false);
                      }}
                    />
                  </div>
                  <div className="quiz-result-modal-body">
                    <div className="quiz-result-modal-item">
                      <div className="quiz-result-modal-item__value">{quizCount}개</div>
                      <div className="quiz-result-modal-item__label">전체 퀴즈</div>
                    </div>
                    <div className="quiz-result-modal-item">
                      <div className="quiz-result-modal-item__value passed">{passedQuizCount}개</div>
                      <div className="quiz-result-modal-item__label">통과한 퀴즈</div>
                    </div>
                    <div className="quiz-result-modal-item">
                      <div className="quiz-result-modal-item__value failed">{failedQuizCount}개</div>
                      <div className="quiz-result-modal-item__label">실패한 퀴즈</div>
                    </div>
                    <div className="quiz-result-modal-item">
                      <div className="quiz-result-modal-item__value correct-rate">{passedPercentage}%</div>
                      <div className="quiz-result-modal-item__label">정답률</div>
                    </div>
                  </div>

                  <div className="quiz-result-modal-footer">
                    {quizSet?.isAssessment ? (
                      <>
                        <Button
                          className="quiz-result-modal-footer__ok-btn"
                          onClick={handleCancelQuizResultModal}
                          type="primary"
                        >
                          확인
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button className="quiz-result-modal-footer__retry-btn" onClick={handleClickRetryButton}>
                          다시풀기
                        </Button>
                        <Button className="quiz-result-modal-footer__submit-btn" onClick={handleClickSubmitButton}>
                          제출하기
                        </Button>
                      </>
                    )}
                  </div>
                </ResultModal>
              </>
            ) : null}
            {isResultSubmitCheckModalOpen ? (
              <>
                <QuizSetModalBackground onClick={() => setIsResultSubmitCheckModalOpen(false)}></QuizSetModalBackground>
                <ResultSubmitCheckModal>
                  <CloseIcon className="quiz-modal-close" onClick={() => setIsResultSubmitCheckModalOpen(false)} />
                  <div className="result-check-modal-text">풀이를 제출하시겠습니까?</div>
                  <div className="result-check-modal-button-container">
                    <div
                      className="result-check-modal-button ok"
                      onClick={() => {
                        handleSubmitQuizSet();
                        setIsResultSubmitCheckModalOpen(false);
                      }}
                    >
                      네, 제출할게요.
                    </div>
                    <div
                      className="result-check-modal-button no"
                      onClick={() => setIsResultSubmitCheckModalOpen(false)}
                    >
                      아니요, 다시 살펴볼게요.
                    </div>
                  </div>
                </ResultSubmitCheckModal>
              </>
            ) : isResetQuizSubmissionsModalOpen ? (
              <>
                <QuizSetModalBackground
                  onClick={() => setIsResetQuizSubmissionsModalOpen(false)}
                ></QuizSetModalBackground>
                <ResultSubmitCheckModal>
                  <CloseIcon className="quiz-modal-close" onClick={() => setIsResetQuizSubmissionsModalOpen(false)} />
                  <div className="result-check-modal-text">
                    문제를 처음부터 다시 푸시겠습니까? <br />
                    기존 문제 풀이 기록은 모두 사라집니다.
                  </div>
                  <div className="result-check-modal-button-container">
                    <div
                      className="result-check-modal-button ok"
                      onClick={() => {
                        handleResetQuizSubmissions();
                        setIsResetQuizSubmissionsModalOpen(false);
                      }}
                    >
                      네, 다시 풀게요.
                    </div>
                    <div
                      className="result-check-modal-button no"
                      onClick={() => {
                        setIsResetQuizSubmissionsModalOpen(false);
                      }}
                    >
                      최종 결과창으로 돌아가기
                    </div>
                  </div>
                </ResultSubmitCheckModal>
              </>
            ) : null}
          </ContentView>
        ),
        sidebar: playlist && <ContentSidebar playlistId={Number(playlistId)} history={history} playlist={playlist} />
      }}
    </ContentTemplate>
  );
};

export default ContentPage;
