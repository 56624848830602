import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import LoadingPage from '../../LoadingPage';
import ErrorPage from '../../ErrorPage';
import SummaryCard from './SummaryCard';

import { PayMethodType } from '../../../pages/apply/type';

import { CREATE_ONE_TIME_PAYMENT_ORDER_GQL } from '../../../apollo/product/mutation';
import { GET_USER_GQL } from '../../../apollo/account/query';

import { fbqTrack } from '../../../util/marketingTools/fbq';
import { store } from '../../../util';
import { requestTossPayment } from '../../../lib/tossPayment';

import type { User } from '../../../pages/apply/type';
import type {
  PaymentOrder,
  Product,
  PaymentModel,
  CreateOneTimePaymentOrderData,
  CreateOneTimePaymentOrderVars
} from '../../../apollo/product/type';
import { makeToast } from '../../../util/makeToast';

const OneTimePaymentContainer: React.FC<{
  product: Product;
  selectedPaymentModel?: PaymentModel;
  payMethod: PayMethodType;
}> = ({ product, selectedPaymentModel, payMethod }) => {
  const { data, loading, error } = useQuery(GET_USER_GQL);
  const [paymentModuleError, setPaymentModuleError] = useState(false);
  const [createOneTimePaymentOrder, { loading: checkoutLoading }] = useMutation<
    CreateOneTimePaymentOrderData,
    CreateOneTimePaymentOrderVars
  >(CREATE_ONE_TIME_PAYMENT_ORDER_GQL);

  const handleRequestPayment = async (paymentOrder: PaymentOrder, buyer: User) => {
    try {
      const { merchantUID, orderName, totalAmount } = paymentOrder;

      const paymentData = {
        orderName,
        isOnetimePayment: true,
        amount: totalAmount,
        orderId: merchantUID,
        customerName: buyer.name,
        customerEmail: buyer.email,
        customerMobilePhone: buyer.mobile,
        taxFreeAmount: totalAmount,
        payMethod
      };

      await requestTossPayment(paymentData);
    } catch (err) {
      console.log(err);
      setPaymentModuleError(true);
    }
  };

  const handleCheckout = async (buyer: User) => {
    if (product && selectedPaymentModel) {
      try {
        const mediaType = store.adsMediaType.get() ?? 'DIRECT';

        const { data } = await createOneTimePaymentOrder({
          variables: { paymentModelId: selectedPaymentModel.id, mediaType }
        });
        if (data && data.urclassCreateOneTimePaymentOrder) {
          fbqTrack('Purchase');
          handleRequestPayment(data.urclassCreateOneTimePaymentOrder, buyer);
        }
      } catch (err) {
        makeToast({ type: 'error', content: '결제진행에 문제가 있습니다.' });
      }
    } else {
      makeToast({ type: 'error', content: '결제에 필요한 정보를 모두 입력해 주세요.' });
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  if (error || paymentModuleError) {
    return <ErrorPage />;
  }

  const buyer: User = data && data.user;

  return (
    <SummaryCard
      checkoutLoading={checkoutLoading}
      applyLoading={false}
      product={product}
      paymentModel={selectedPaymentModel}
      handleCheckout={() => {
        if (!buyer) {
          makeToast({ type: 'error', content: '결제 정보에 문제가 있습니다.' });
          return;
        }
        handleCheckout(buyer);
      }}
    />
  );
};

export default OneTimePaymentContainer;
