export const mapPaymentTypeToText = (paymentType: string) => {
  switch (paymentType) {
    case 'WEWIN':
      return '후불제';
    case 'UPFRONT':
      return '선불제';
    case 'GOV_FUNDING':
      return '정부지원';
    case 'SCHOLARSHIP':
      return '장학금';
    case 'HYBRID':
      return 'HYBRID';
    case 'IMPACT_CAMPUS':
      return '장학제도 (임팩트캠퍼스)';
  }
  return '';
};
