import gql from 'graphql-tag';

export const VALIDATE_PAYMENT_ORDER_GQL = gql`
  mutation urclassValidatePaymentOrder(
    $input: Urclass_ValidatePaymentOrderInput!
  ) {
    urclassValidatePaymentOrder(input: $input)
  }
`;

export const APPROVE_PAYMENT_ORDER_GQL = gql`
  mutation urclassApprovePaymentOrder(
    $input: Urclass_ApprovePaymentOrderInput!
  ) {
    urclassApprovePaymentOrder(input: $input) {
      id
      status
      vbankNum
      vbankName
      vbankDate
      vbankHolder
      payMethod
      orderName
      totalAmount
      productUuid
    }
  }
`;
