import { createHash } from 'crypto';
import { FACEBOOK_PIXEL_ID } from '../../index';
import { deployEnvironment } from '../../util';

export const fbqTrack = (event: string, payload?: any, eventId?: string) => {
  if (process.env.NODE_ENV === deployEnvironment.production) {
    (window as any).fbq('track', event, { ...payload }, { eventID: eventId });
  }
};

export const fbqTrackSingleCustom = (event: string, payload?: any, eventId?: string) => {
  if (process.env.NODE_ENV === deployEnvironment.production) {
    (window as any).fbq('trackSingleCustom', FACEBOOK_PIXEL_ID, event, { ...payload }, { eventID: eventId });
  }
};

export const generateEventId = () => {
  return createHash('sha256').update(`${+new Date()}-${window.location.href}`).digest('hex');
};
