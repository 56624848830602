import React from 'react';
import styled from '@emotion/styled';

const RadioWrapper = styled.div`
  .radio {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 9px;
    align-items: center;
  }
`;

type RadioProps = {
  value: string;
  name?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

export const Radio: React.FC<RadioProps> = ({
  children,
  name,
  value,
  checked,
  onChange,
  className
}) => (
  <RadioWrapper className={className}>
    <label className="radio">
      <span className="radio__input">
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
      </span>
      {children}
    </label>
  </RadioWrapper>
);
