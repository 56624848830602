import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as MenuIcon } from '../../../assets/svg/ico-menu.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/ico-close.svg';

import HeaderAvatar from '../HeaderAvatar';
import HeaderTabs from '../Tabs/HeaderTabs';

import type { UserProfile } from '../../../apollo/account/type';

type HeaderMenuProps = {
  hasCohort: boolean;
  isContentPage: boolean;
  visible: boolean;
  toggleVisible: Function;
  handleLogout: () => void;
  userProfile: UserProfile;
};

const HeaderMenuWrapper = styled.div`
  display: flex;
  letter-spacing: -0.012em;

  .mobile-header {
    margin: auto 0px;
    display: none;
    cursor: pointer;
    .icon {
      color: #525c65;
      font-size: 22px;
      margin: 2px 5px;
    }
  }

  @media screen and (max-width: 992px) {
    .mobile-header {
      display: block;
    }
    .general-header {
      display: none;
    }
  }
`;

const HeaderMenu: React.FC<HeaderMenuProps> = ({ hasCohort, toggleVisible, visible, userProfile, handleLogout }) => {
  return (
    <HeaderMenuWrapper>
      <div className="mobile-header" onClick={() => (visible ? toggleVisible(false) : toggleVisible(true))}>
        {visible ? <CloseIcon className="icon" /> : <MenuIcon className="icon" />}
      </div>
      {hasCohort && <HeaderTabs className="general-header" />}

      <HeaderAvatar
        hasCohort={hasCohort}
        email={userProfile.email}
        role={userProfile.role}
        name={userProfile.name}
        handleLogout={handleLogout}
      />
    </HeaderMenuWrapper>
  );
};

export default HeaderMenu;
