import React from 'react';
import { Link } from 'react-router-dom';
import { Result } from 'antd';
import styled from '@emotion/styled';

import { ResultItemWrapper } from '../../../components/organisms/Payment/style';

import { numberFormatter } from '../../../util';

interface ICardResultProps {
  totalAmount: number;
  orderName: string;
}
export const CardResult: React.FC<ICardResultProps> = ({
  totalAmount,
  orderName
}) => {
  return (
    <CardResultWrapper
      title={'결제 완료'}
      subTitle={'결제가 성공적으로 완료 되었습니다.'}
      status="success"
      style={{ marginTop: '30px', textAlign: 'center' }}
    >
      <div className="paragraph">
        <div className="gray-text">구매한 코스</div>
        <div className="black-text">{orderName}</div>
      </div>
      <div className="paragraph">
        <div className="gray-text">결제금액</div>
        <div className="black-text">{numberFormatter(totalAmount)}원</div>
      </div>
      <div className="paragraph">
        <ResultItemWrapper>
          <Link to="/mypage">마이페이지로 이동</Link>
        </ResultItemWrapper>
      </div>
    </CardResultWrapper>
  );
};

const CardResultWrapper = styled(Result)`
  .paragraph {
    margin: 10px;
  }
  .gray-text {
    color: rgb(0, 0, 0, 0.45);
  }
  .black-text {
    color: rgb(0, 0, 0, 0.85);
  }
`;
