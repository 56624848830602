import React from 'react';
import styled from '@emotion/styled';
import { Progress } from 'antd';
import { Link } from 'react-router-dom';

import { Grid } from '../common/Grid';
import { EmptyContentViewer } from '../common/EmptyContentViewer';
import { ImageCard } from '../common/ImageCard';

type PlaylistEntry = {
  id: number;
  name: string;
  description: string;
  thumbnailUrl: string;
  isPublished: boolean;
  userPlaylistProgress: number;
};

type PlaylistCardGroupProps = { playlists: PlaylistEntry[] };

const PlaylistCardGroupWrapper = styled.div`
  margin-bottom: 50px;
  .ant-progress-bg {
    height: 3px !important;
    background-color: #295ce0;
  }

  .progress-info {
    font-size: 14px;
    font-weight: 500;
    color: #295ce0;
  }

  .progress-info-none {
    font-size: 14px;
    font-weight: 500;
    color: #d8d8d8;
  }
`;

export const PlaylistCardGroup: React.FC<PlaylistCardGroupProps> = ({ playlists }) => {
  if (!playlists.length) {
    return (
      <EmptyContentViewer
        title={'콘텐츠를 준비중입니다.'}
        description={'아직 오픈된 콘텐츠가 없습니다. 더 좋은 콘텐츠로 찾아오겠습니다.'}
      />
    );
  }

  return (
    <PlaylistCardGroupWrapper>
      <Grid itemWidth={360} gridGap={40} rowGap={80}>
        {playlists
          .filter(item => item.isPublished)
          .map(playlist => {
            const { id, name, description, thumbnailUrl, userPlaylistProgress } = playlist;

            const progress = Math.round(userPlaylistProgress * 100);

            return (
              <Link to={`/playlist/${id}`} key={id}>
                <ImageCard
                  title={name}
                  description={description}
                  imageUrl={thumbnailUrl}
                  footer={
                    <div>
                      <Progress size="small" showInfo={false} percent={progress} />
                      <div className={progress ? 'progress-info' : 'progress-info-none'}>
                        {progress ? `${progress}% Complete` : 'Please begin studying!'}
                      </div>
                    </div>
                  }
                />
              </Link>
            );
          })}
      </Grid>
    </PlaylistCardGroupWrapper>
  );
};
