import gql from 'graphql-tag';

export const APPLICATIONS_WITH_PRODUCT_UUID_GQL = gql`
  query urclassMyApplications {
    urclassMyApplications {
      id
      uuid
      applyingStatus
      product {
        uuid
        applyServiceType
        programId
      }
      admissionApplierToken
      needToPay
    }
  }
`;

export const APPLICATION_WITH_PAYMENT_ORDER_GQL = gql`
  query urclassMyApplicationsWithPaymentOrder($applicationUuid: String!) {
    urclassMyApplication(uuid: $applicationUuid) {
      id
      paidPaymentOrder {
        id
        payMethod
      }
    }
  }
`;
