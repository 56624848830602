import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { GeneralTemplate } from '../templates';
import { GeneralHeader } from './organisms/Header';
import { ReactComponent as ContentEmpty } from '../domain/lms/assets/svg/img-empty-coplit.svg';
import { ReactComponent as BackIcon } from '../assets/svg/ico-back.svg';

import { ColorPaletteType } from '../styles/color';
import { Typography, TypographyType } from './atoms/Typography';
const NoContentPageWrapper = styled.div`
  text-align: center;
  margin-top: 200px;
  .txt__content--empty {
    margin-bottom: 24px;
  }
  .link__content--empty {
    background-color: #452cdd;
    display: inline-block;
    margin-top: 40px;
    width: 224px;
    height: 48px;
    color: #fff;
    border-radius: 6px;
    padding-top: 12px;
    .txt__btn {
      vertical-align: top;
    }
    .ico__btn {
      display: inline-block;
    }
  }
`;
const NoContentPage = () => {
  return (
    <GeneralTemplate>
      {{
        header: <GeneralHeader />,
        body: (
          <NoContentPageWrapper>
            <ContentEmpty className="ico__content--empty" />
            <Typography
              className="txt__content--empty"
              color={ColorPaletteType.PURPLE}
              type={TypographyType.H4}
              text="접근 가능한 콘텐츠가 없습니다."
            />
            <Typography
              color={ColorPaletteType.BLUISHGRAY5}
              type={TypographyType.B3}
              text={'코드 문제를 연습할 수 있는 곳입니다.'}
            />
            <Typography
              color={ColorPaletteType.BLUISHGRAY5}
              type={TypographyType.B3}
              text={'수강 중인 코스에 따라 콘텐츠에 접근이 가능합니다.'}
            />
            <Link to="/" className="link__content--empty">
              <BackIcon className="ico__btn" />
              <span className="txt__btn">메인으로 돌아가기</span>
            </Link>
          </NoContentPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default NoContentPage;
