import { makeToast } from './../../util/makeToast';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { LOGIN_EMAIL_GQL } from '../../apollo/account/mutation';

import { useCredential } from '../../contexts/credential';
import { store } from '../../util';

import type { LoginEmailData, LoginEmailVars } from '../../apollo/account/type';
import { PRODUCT_GQL } from '../../apollo/product/query';
import { ProductData, ProductVars } from '../../apollo/product/type';

const BITHUMB_SEB_PROGRAM_ID = 13;
const BITHUMB_BEB_PROGRAM_ID = 14;

const BITHUMB_B2B_PROGRAM_IDS = [BITHUMB_SEB_PROGRAM_ID, BITHUMB_BEB_PROGRAM_ID];

export const useController = () => {
  // FIXME: 적절한 type을 넣어줘야함
  const history = useHistory<Record<string, any>>();

  const { login } = useCredential();

  const { pathname } = store.redirectURL.get();

  /* State */
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const [isPreApplicant, setIsPreApplicant] = useState<boolean>(pathname?.includes('apply') ? true : false);

  /* Mutation */
  const [loginEmail] = useMutation<LoginEmailData, LoginEmailVars>(LOGIN_EMAIL_GQL, {
    variables: { input: { email, password } }
  });

  useEffect(() => {
    if (history.location.state?.errorMessage) {
      setErrorMessage(history.location.state.errorMessage);
      setErrorModalVisible(true);
    }
  }, []);

  /* handler */
  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleChangeIsPreApplicant = () => {
    setIsPreApplicant(!isPreApplicant);
  };

  const okErrorModal = () => {
    const { errorMessage, ...restState } = history.location.state;
    // location state에서 에러메세지 없애기 아니면 모달이 계속 뜸
    history.replace('/login', { ...restState });
    setErrorModalVisible(false);
    setErrorMessage('');
  };

  const signIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const { data } = await loginEmail();
      if (data?.urclassLoginEmail.token) {
        login(data.urclassLoginEmail.token);
        history.push('/');
        return;
      }
      makeToast({ type: 'error', content: '이메일 혹은 패스워드를 확인 해주세요.' });
    } catch {
      makeToast({ type: 'error', content: '이메일 혹은 패스워드를 확인 해주세요.' });
    }
  };
  return {
    email,
    handleEmail,
    password,
    handlePassword,
    pathname,
    isPreApplicant,
    handleChangeIsPreApplicant,
    signIn,
    okErrorModal,
    errorMessage,
    errorModalVisible
  };
};
