import React from 'react';
import styled from '@emotion/styled';
import { Progress } from 'antd';

import { BasicContainer } from '../common/BasicContainer';
import { BlockMessage } from '../common/BlockMessage';

import { progressCalculation, displayDueDate } from '../../../../util';

import type { Classroom } from '../../apollo/coplit/type';

const ClassroomStatusCardWrapper = styled.div`
  .classroom-status-container {
    display: flex;
    flex-direction: row;

    .classroom-progress {
      flex: 1;
      text-align: center;
      margin: auto 10px auto 0px;
      align-items: center;
      .progress-title {
        margin-top: 10px;
        color: #7c8597;
      }
    }

    .classroom-status {
      flex: 6;

      .classroom-title {
        font-size: 18px;
        font-weight: 700;
        color: #262f40;
      }

      .classroom-description {
        font-size: 14px;
        color: #7c8597;
      }

      .status-content {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .item {
          flex: 1;
          font-size: 18px;
          font-weight: 700;
          color: #262f40;

          .item-nav {
            font-size: 14px;
            font-weight: 400;
            color: #7c8597;
          }
        }
      }
    }
  }
`;

type ClassroomStatusCardProps = {
  classroom?: Classroom;
};

export const ClassroomStatusCard: React.FC<ClassroomStatusCardProps> = ({ classroom }) => {
  return (
    <ClassroomStatusCardWrapper>
      <BasicContainer className="classroom-status-container">
        {classroom ? (
          <>
            <div className="classroom-progress">
              <Progress
                percent={progressCalculation(classroom.totalCodeProblemCount, classroom.restCodeProblemCount)}
                type="circle"
                width={80}
                format={percent => `${percent}%`}
              />
              <div className="progress-title">진행률</div>
            </div>
            <div className="classroom-status">
              <div className="classroom-title">{classroom.title}</div>
              <div className="classroom-description">{classroom.description}</div>
              <div className="status-content">
                <div className="item">
                  <div>{classroom.totalCodeProblemCount}개</div>
                  <div className="item-nav">전체 문제</div>
                </div>

                <div className="item">
                  <div>{classroom.restCodeProblemCount}개</div>
                  <div className="item-nav">남은 문제</div>
                </div>

                <div className="item">
                  <div>
                    {Number.isNaN(Number(displayDueDate(classroom.dueDate)))
                      ? displayDueDate(classroom.dueDate)
                      : `${displayDueDate(classroom.dueDate)} 일`}
                  </div>
                  <div className="item-nav">남은 시간</div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <BlockMessage>코플릿 정보를 가져올 수 없습니다.</BlockMessage>
        )}
      </BasicContainer>
    </ClassroomStatusCardWrapper>
  );
};
