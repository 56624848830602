export enum CareerStatus {
  NEW = 'NEW',
  EXPERIENCE = 'EXPERIENCE',
  UNKNOWN = 'UNKNOWN'
}
export enum ActivityCategory {
  PRIZE = 'PRIZE',
  OTHER_EDUCATION = 'OTHER_EDUCATION',
  CERTIFICATION = 'CERTIFICATION',
  JOB_EXPERIENCE = 'JOB_EXPERIENCE'
}

export enum LanguageSkillLevel {
  NATIVE = 'NATIVE',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}

export enum CertificateDegree {
  DOCTOR_DEGREE = 'DOCTOR_DEGREE' /* 박사 */,
  MASTER_DEGREE = 'MASTER_DEGREE' /* 석사 */,
  BACHELOR_DEGREE = 'BACHELOR_DEGREE' /* 학사 */,
  ASSOCIATE_DEGREE = 'ASSOCIATE_DEGREE' /* 전문 학사 */,
  HIGH_SCHOOL = 'HIGH_SCHOOL' /* 고등학교 졸업 */
}
export interface JobProfile {
  id: number;
  email: string;
  mobile: string;
  korName: string;
  birthday?: string;
  engFirstName: string;
  engLastName: string;
  githubUserName: string;
  favorPositions: FavorPosition[];
  blogURL?: string;
  portfolioURL?: string;
  publishedResume?: Resume;

  isRequireExtraInformation: boolean;
  careerStatus?: CareerStatus;
  lastExperienceSalary?: number;
  isLastExperienceSalaryPublic?: boolean;
  lastEducationStatus?: string;
  isNoInterestDomain?: boolean;

  interestDomains: InterestDomain[];
  skillSets: SkillSet[];
  educationDetails: EducationDetail[];
  experienceDetails: ExperienceDetail[];
  languageDetails: LanguageDetail[];
  activityDetails: ActivityDetail[];
}

export interface Resume {
  id: number;
  title: string;
  fileURL: string;
  storageKey: string;
  isPublished: boolean;
  createdAt: Date;
}

/* InterestDomain */
export interface InterestDomain {
  id: number;
  name: string;
}

export interface SkillSet {
  id: number;
  name: string;
}

export interface HighSchoolMajor {
  id: number;
  name: string;
}

export interface CollegeMajor {
  id: number;
  name: string;
}

export interface Major {
  id: number;
  name: string;
}

export interface LanguageSkill {
  id: number;
  name: string;
}

export interface EducationDetail {
  id?: number;
  majorName: string;
  certificateDegree: CertificateDegree;
  isAttending: boolean;
  isLastEducation: boolean;
  startAt: Date;
  completionAt?: Date;
  schoolName: string;
  major: Major;
}

export interface ActivityDetail {
  id?: number;
  name: string;
  description: string;
  category: ActivityCategory;
}

export interface LanguageDetail {
  id: number;
  name: string;
  languageSkillLevel: LanguageSkillLevel;
}

export interface ExperienceDetail {
  id?: number;
  jobTitle: string;
  companyName: string;
  isInService: boolean;
  startAt: Date;
  endAt?: Date;
}

export interface JobProfileInput {
  email: string;
  mobile: string;
  korName: string;
  engFirstName: string;
  engLastName: string;
  birthday: string;
  githubUserName: string;
  blogURL?: string;
  portfolioURL?: string;
  isNoInterestDomain: boolean;
  careerStatus: CareerStatus;
  totalCareerMonth?: number;
  lastExperienceSalary?: number;
  isLastExperienceSalaryPublic?: boolean;
  lastEducationStatus?: string;
}

export interface JobProfileUpdateInput {
  email?: string;
  mobile?: string;
  korName?: string;
  engFirstName?: string;
  engLastName?: string;
  birthday?: string;
  githubUserName?: string;
  blogURL?: string;
  portfolioURL?: string;
  isNoInterestDomain?: boolean;
  careerStatus?: CareerStatus;
  totalCareerMonth?: number;
  lastExperienceSalary?: number;
  isLastExperienceSalaryPublic?: boolean;
  lastEducationStatus?: string;
}

export interface JobPosition {
  id: number;
  name: string;
  jobCategoryId: number;
}

export interface CreateResumeInput {
  fileURL: string;
  key: string;
  title: string;
}

export interface FavorPosition {
  id: number;
  name: string;
  jobCategoryId: number;
}

// Query

export interface MyJobProfileData {
  urclassMyJobProfile: JobProfile;
}

export interface JobPositionData {
  urclassGetJobPositions: JobPosition[];
}

export interface MyResumesData {
  urclassMyResumes: Resume[];
}

export interface InterestDomainData {
  urclassInterestDomains: InterestDomain[];
}

export interface AutoCompletedSkillSetData {
  urclassSkillSetsAutoCompletion: SkillSet[];
}
export interface AutoCompletedSkillSetVars {
  skillSetName?: string;
}

export interface HighSchoolMajorData {
  urclassHighSchoolMajors: HighSchoolMajor[];
}

export interface CollegeMajorData {
  urclassCollegeMajors: CollegeMajor[];
}

export interface LanguageSkillData {
  urclassLanguageSkills: LanguageSkill[];
}

// Mutation

export interface CreateMyJobProfileVars {
  favorPositionIds: number[];
  interestDomainIds: number[];
  skillSetNames: string[];
  jobProfile: JobProfileInput;
  educationDetails: EducationDetailInput[];
  languageDetails: LanguageDetailInput[];
  activityDetails: ActivityDetailInput[];
  experienceDetails: ExperienceDetailInput[];
}

export interface EducationDetailInput {
  certificateDegree: CertificateDegree;
  majorId: number;
  schoolName: string;
  majorName: string;
  startAt: Date;
  completionAt?: Date;
  isAttending: boolean;
  isLastEducation: boolean;
}

export interface LanguageDetailInput {
  languageName: string;
  languageSkillLevel: LanguageSkillLevel;
}

export interface ActivityDetailInput {
  category: ActivityCategory;
  name: string;
  description: string;
}

export interface ExperienceDetailInput {
  companyName: string;
  jobTitle: string;
  startAt: Date;
  endAt?: Date;
  isInService: boolean;
}

export interface CreateMyJobProfileData {
  urclassCreateMyJobProfile: JobProfile;
}

export interface UpdateMyJobProfileVars {
  favorPositionIds: number[];
  interestDomainIds: number[];
  skillSetNames: string[];
  educationDetails: EducationDetailInput[];
  languageDetails: LanguageDetailInput[];
  activityDetails: ActivityDetailInput[];
  experienceDetails: ExperienceDetailInput[];
  jobProfile: JobProfileUpdateInput;
}

export interface UpdateMyJobProfileData {
  urclassUpdateMyJobProfile: JobProfile;
}

export interface CreateResumeUploadURLVars {
  fileName: string;
}

export interface CreateResumeUploadURLData {
  urclassCreateResumeUploadURL: {
    url: string;
    fields: string;
  };
}

export interface CreateResumeVars {
  input: CreateResumeInput;
}

export interface CreateResumeData {
  urclassCreateResume: Resume;
}

export interface DeleteResumeVars {
  resumeId: number;
}

export interface DeleteResumeData {
  urclassDeleteResume: boolean;
}

export interface PublishResumeVars {
  resumeId: number;
}

export interface PublishResumeData {
  urclassPublishResume: boolean;
}
