import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import LogoImage from '../../../assets/svg/logo-footer.svg';

const GeneralFooterWrapper = styled.div`
  .container {
    width: 100%;
    margin: 0 auto;

    .logo {
      margin: 50px 0 24px;
      width: 120px;
      height: 30px;
    }

    .info {
      p {
        margin: 3px 0;
        font-size: 14px;
        color: #494949;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      padding: 24px 0 40px;

      .item {
        flex: 1;
        font-size: 12px;
        font-weight: bold;

        &.links {
          a {
            color: #5a5a5a;

            &::after {
              margin: 0 4px;
              content: '|';
            }
            &:last-child::after {
              content: '';
            }
          }
        }

        &.message {
          text-align: end;
          color: #455573;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    margin: 0px 40px;
  }
`;

export const GeneralFooter: React.FC = () => {
  const history = useHistory();

  return (
    <GeneralFooterWrapper>
      <div className="container">
        <img alt="logo" className="logo" src={LogoImage} onClick={() => history.push('/')} />

        <div className="info">
          <p>주식회사 코드스테이츠 | 사업자번호: 703-88-00878 | 대표자명: 김인기</p>
          <p>서울특별시 강남구 테헤란로 151, 6층 602, 603호 (하이츠빌딩)</p>
        </div>

        <div className="bottom">
          <div className="item links">
            <a href="https://codestates.com" target="_blank" rel="noopener noreferrer">
              홈페이지
            </a>

            <a href="https://codestates.com/terms/policy" target="_blank" rel="noopener noreferrer">
              개인정보 처리방침
            </a>
          </div>
          <div className="item message">
            <span>구글 크롬에 최적화 되어있습니다</span>
          </div>
        </div>
      </div>
    </GeneralFooterWrapper>
  );
};
