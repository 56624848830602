import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { GeneralTemplate } from '../../templates';
import { GeneralHeader } from '../../components/organisms/Header';
import { requestTossPayment } from '../../lib/tossPayment';
import type { CreatePaymentOrderData, CreatePaymentOrderVars } from '../../apollo/product/type';

import { useMutation as apolloUseMutation, useQuery as apolloUseQuery } from '@apollo/react-hooks';
import { MY_APPLICATION_DETAIL_GQL } from '../../apollo/mypage/query';
import { PRODUCT_GQL } from '../../apollo/product/query';
import type { ProductData, ProductVars, PaymentModel } from '../../apollo/product/type';
import { MyApplicationData, MyApplicationVars } from '../../apollo/mypage/type';
import { MY_PROFILE_GQL } from '../../apollo/account/query';
import { MyProfileData } from '../../apollo/account/type';

import { CREATE_PAYMENT_ORDER_GQL } from '../../apollo/product/mutation';
import { makeToast } from '../../util/makeToast';
import { generateEventId } from '../../util/marketingTools/fbq';

import { numberFormatter } from '../../util';

const ApplicationPaymentPage: React.FC = () => {
  const params = useParams<{ uuid: string }>();
  const history = useHistory();
  const [payMethod, setPayMethod] = useState<'card' | 'vbank'>('card');
  const [productUuid, setProductUuid] = useState<string>('');
  const [paymentModel, setPaymentModel] = useState<PaymentModel | undefined>(undefined);

  const { data: applicationData, error: applicationError } = apolloUseQuery<MyApplicationData, MyApplicationVars>(
    MY_APPLICATION_DETAIL_GQL,
    {
      variables: { uuid: params.uuid }
    }
  );

  const { data: productData, error: productError } = apolloUseQuery<ProductData, ProductVars>(PRODUCT_GQL, {
    skip: !productUuid,
    variables: { uuid: productUuid }
  });
  const { data: myProfile } = apolloUseQuery<MyProfileData>(MY_PROFILE_GQL);

  const [createPaymentOrderMutation] = apolloUseMutation<CreatePaymentOrderData, CreatePaymentOrderVars>(
    CREATE_PAYMENT_ORDER_GQL
  );

  useEffect(() => {
    if (applicationData?.urclassMyApplication) {
      if (!applicationData.urclassMyApplication.needToPay) {
        makeToast({ content: '잘못된 접근 입니다.', type: 'error' });
        history.push(`/mypage`);
      }
    }
    if (applicationData?.urclassMyApplication?.product.uuid) {
      setProductUuid(applicationData?.urclassMyApplication?.product.uuid);
    }
  }, [applicationData?.urclassMyApplication, history]);

  useEffect(() => {
    if (productData?.urclassProduct.paymentModels) {
      if (!productData.urclassProduct.paymentModels.some(paymentModel => paymentModel.paymentType === 'UPFRONT')) {
        makeToast({ content: '선불제 상품이 아닙니다.', type: 'error' });
        history.push(`/mypage`);
        return;
      }
      setPaymentModel(
        productData.urclassProduct.paymentModels.find(paymentModel => paymentModel.paymentType === 'UPFRONT')
      );
    }
  }, [productData]);

  return (
    <GeneralTemplate loading={!productData?.urclassProduct} error={!!productError || !!applicationError}>
      {{
        header: <GeneralHeader />,
        body: productData && paymentModel && (
          <ApplicationPaymentPageWrapper>
            <MarginBottom size="114px" />
            <ApplicationPaymentPageHeader>결제 수단을 선택 하세요.</ApplicationPaymentPageHeader>
            <MarginBottom size="106px" />
            <Container>
              <InnerContainer>
                <LabelValueContainer>
                  <Label>결제 방법</Label>
                  <Value>선불제</Value>
                </LabelValueContainer>
                <Divder />
                <LabelValueContainer>
                  <Label>총 상품 금액</Label>
                  <Value>{numberFormatter(paymentModel.originPrice)}원</Value>
                </LabelValueContainer>

                <LabelValueContainer>
                  <Label>할인 금액</Label>
                  <Value style={{ color: 'var(--peach-peach-600, #FD1808)' }}>
                    -{numberFormatter(paymentModel.originPrice - paymentModel.price)}원
                  </Value>
                </LabelValueContainer>

                <Divder />

                <LabelValueContainer>
                  <Label>최종 결제 금액</Label>
                  <Value style={{ fontWeight: 700 }}>{numberFormatter(paymentModel.price)}원</Value>
                </LabelValueContainer>
              </InnerContainer>
              <RadioGroup>
                <Radio>
                  <RadioButton
                    id="pay-method-card"
                    type="radio"
                    checked={payMethod === 'card'}
                    onClick={setPayMethod.bind(this, 'card')}
                  />
                  <RadioLabel checked={payMethod === 'card'} htmlFor="pay-method-card">
                    신용카드
                  </RadioLabel>
                </Radio>
                <Radio>
                  <RadioButton
                    id="pay-method-vbank"
                    type="radio"
                    checked={payMethod === 'vbank'}
                    onClick={setPayMethod.bind(this, 'vbank')}
                  />
                  <RadioLabel checked={payMethod === 'vbank'} htmlFor="pay-method-vbank">
                    무통장 입금
                  </RadioLabel>
                </Radio>
              </RadioGroup>
              <CTA
                onClick={async () => {
                  const { data, errors } = await createPaymentOrderMutation({
                    variables: { paymentModelId: paymentModel.id, eventId: generateEventId() }
                  });

                  if (errors) {
                    makeToast({ content: '오류가 발생 하였습니다. 관리자에게 문의 해주세요', type: 'error' });
                  }

                  if (data && myProfile) {
                    const { orderName, merchantUID, totalAmount } = data.urclassCreatePaymentOrder;
                    const { name, email, mobile } = myProfile.urclassMe;
                    const request = {
                      orderName: orderName,
                      orderId: merchantUID,
                      amount: totalAmount,
                      customerName: name,
                      customerEmail: email,
                      customerMobilePhone: mobile,
                      taxFreeAmount: 0,
                      payMethod,
                      productUuid
                    };

                    await requestTossPayment(request);
                  }
                }}
              >
                결제하기
              </CTA>
            </Container>
          </ApplicationPaymentPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

const ApplicationPaymentPageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ApplicationPaymentPageHeader = styled.div`
  color: #1e1e1e;
  font-family: SpoqaHanSans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 42px */
  letter-spacing: -0.28px;
`;

const Container = styled.div`
  border-radius: 10px;
  border: 1px solid var(--grayscale-white, #fff);
  background: var(--grayscale-white, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  padding: 40px 30px 24px 30px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const InnerContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 1px solid var(--bluish-gray-bluish-gray-200, #dfe3ec);
`;

const Divder = styled.div`
  border: 0.5px solid var(--bluish-gray-bluish-gray-300, #dfe3ec);
  align-self: stretch;
  stroke-width: 1px;
  stroke: var(--bluish-gray-bluish-gray-300, #ccd3e0);
`;

const LabelValueContainer = styled.div`
  display: flex;
  width: 238px;
  justify-content: space-between;
  align-items: flex-start;
`;

const Label = styled.label`
  color: var(--bluish-gray-bluish-gray-600, #667ba3);
  font-family: Spoqa Han Sans Neo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const Value = styled.div`
  color: var(--bluish-gray-bluish-gray-700, #455573);
  text-align: right;

  /* N2 */
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const MarginBottom = styled.div<{ size?: string }>`
  margin-bottom: ${({ size }) => size || '20px'};
`;

const CTA = styled.button`
  border-radius: 5px;
  background: var(--purple-purple-600, #452cdd);

  display: flex;
  padding: 14px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  /* text */
  color: var(--grayscale-white, #fff);
  text-align: center;
  font-family: Spoqa Han Sans Neo;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  :hover {
    cursor: pointer;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
`;

const Radio = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const RadioButton = styled.input`
  vertical-align: middle;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--bluish-gray-bluish-gray-400, #b2bdd1);
  :checked {
    border: 4px solid var(--purple-purple-600, #452cdd);
  }
`;

const RadioLabel = styled.label<{ checked?: boolean }>`
  font-family: SpoqaHanSans;
  font-size: 16px;
  font-style: normal;
  line-height: 150%; /* 24px */
  letter-spacing: -0.16px;
  font-weight: ${({ checked }) => (checked ? 700 : 400)};
  color: ${({ checked }) => (checked ? 'var(--purple-purple-600, #452CDD)' : '#000')};
`;

export default ApplicationPaymentPage;
