import '@react-pdf-viewer/core/lib/styles/index.css';

import React from 'react';
import styled from '@emotion/styled';
import { Progress } from 'antd';

import type { JobProfile } from '../../common/apollo/resume/type';

import {
  Worker,
  Viewer,
  LoadError,
  CharacterMap,
  AnnotationType,
  Plugin,
  PluginOnAnnotationLayerRender
} from '@react-pdf-viewer/core';

type ResumeViewerProps = {
  profile?: JobProfile;
};

const ResumeViewerWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;

  .progress {
    color: white;
  }
`;

const DocumentMessage = styled.div`
  color: white;
`;

const ResumeViewer: React.FC<ResumeViewerProps> = ({ profile }) => {
  const characterMap: CharacterMap = {
    isCompressed: true,
    url: 'https://unpkg.com/pdfjs-dist@2.4.456/cmaps/'
  };

  const targetBlankPlugin = (): Plugin => {
    const onRenderAnnotations = (e: PluginOnAnnotationLayerRender) => {
      e.annotations
        .filter(annotation => annotation.annotationType === AnnotationType.Link)
        .forEach(annotation => {
          if (annotation.url) {
            Array.from(e.container.querySelectorAll('.rpv-core-annotation-link a')).forEach(linkEle => {
              linkEle.setAttribute('target', '_blank');
            });
          }
        });
    };

    return {
      onAnnotationLayerRender: onRenderAnnotations
    };
  };

  const fileUrl = profile && profile.publishedResume && profile.publishedResume.fileURL;

  if (!profile) {
    return <DocumentMessage>Loading PDF...</DocumentMessage>;
  }

  return (
    <ResumeViewerWrapper>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
        <Viewer
          characterMap={characterMap}
          fileUrl={fileUrl || ''}
          renderError={(error: LoadError) => <DocumentMessage>파일 불러오기에 문제가 있습니다.</DocumentMessage>}
          renderLoader={(percentages: number) => <Progress className="progress" percent={Math.round(percentages)} />}
          plugins={[targetBlankPlugin()]}
        />
      </Worker>
    </ResumeViewerWrapper>
  );
};

export default ResumeViewer;
