/* Base error classes */

export class BaseError extends Error {
  public title: string;

  constructor(title: string, message: string) {
    super(message);
    this.title = title;
  }
}

export class ApplicationError extends BaseError {
  constructor({ title, message }: { title: string; message: string }) {
    super(title, message);
  }
}

export class ServerError extends BaseError {
  constructor({ title, message }: { title?: string; message?: string } = {}) {
    super(title || 'server-error', message || '서버 에러가 발생했습니다.');
  }
}
