import React from 'react';
import styled from '@emotion/styled';

import {
  Typography,
  TypographyType
} from '../../../components/atoms/Typography';
import { ColorPalette, ColorPaletteType } from '../../../styles/color';

type Props = {
  message: string;
  onOk: () => void;
};

const GlobalMessageStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .message-area {
    margin-top: 50px;
    text-align: center;
    max-width: 200px;
  }
  .button-area {
    margin-top: 30px;
    .btn {
      &:hover {
        cursor: pointer;
      }
      border-radius: 5px;
      width: 300px;
      height: 52px;
      background: ${ColorPalette.Gray1};
    }
  }
`;

export const GlobalMessage: React.FC<Props> = props => {
  const { message, onOk } = props;
  return (
    <GlobalMessageStyled>
      <div className="message-area">
        <Typography
          color={ColorPaletteType.GRAY2}
          type={TypographyType.B2}
          text={message}
        />
      </div>
      <div className="button-area">
        <button className="btn" onClick={onOk}>
          <Typography
            color={ColorPaletteType.WHITE}
            type={TypographyType.B1}
            text="확인"
          />
        </button>
      </div>
    </GlobalMessageStyled>
  );
};
