import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Modal } from 'antd';

import {
  Typography,
  TypographyType
} from '../../../components/atoms/Typography';
import { Radio } from '../../../components/molecules/Input/Radio';
import { Button } from '../../../components/molecules/Button';
import {
  SizeType,
  StatusType
} from '../../../components/molecules/Button/type';
import { ColorPaletteType } from '../../../styles/color';

const ImpactCampusCheckListFormWrapper = styled.div`
  margin: auto;

  word-break: keep-all;

  .form__title {
    margin-bottom: 30px;

    .title {
      font-weight: 700;
    }

    .subtitle {
      margin-top: 10px;
    }
  }

  .form__input-item {
    margin-bottom: 20px;

    ul {
      position: relative;
      list-style: none;
      margin-top: 10px;
      margin-left: 25px;
      padding-left: 1em;
    }
    ul li:before {
      content: '*';
      position: absolute;
      left: 0;
    }
  }

  .form__footer {
    margin-top: 40px;

    .footer__buttons {
      margin-top: 20px;

      display: flex;
      justify-content: space-between;

      button {
        width: 200px;
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  text-align: center;

  button {
    margin-top: 20px;
  }
`;

type ImpactCampusCheckListFormProps = {
  loading: boolean;
  selectedScholarshipOption: string | undefined;
  closeApplyImpactCampusModal: () => void;
  handleApply: () => void;
  handleChangeScholarshipOption: (option: string) => void;
};

const ImpactCampusCheckListForm: React.FC<ImpactCampusCheckListFormProps> = ({
  loading,
  selectedScholarshipOption,
  closeApplyImpactCampusModal,
  handleApply,
  handleChangeScholarshipOption
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  return (
    <ImpactCampusCheckListFormWrapper>
      {/* 이중 모달 */}
      <StyledModal
        width={350}
        visible={isModalVisible}
        footer={null}
        closable={false}
      >
        <Typography
          type={TypographyType.H3}
          color={ColorPaletteType.GRAY1}
          text="조건이 선택되지 않았습니다."
        />
        <Typography
          type={TypographyType.H3}
          color={ColorPaletteType.GRAY1}
          text="해당 되는 조건을 체크해주세요."
        />
        <Button
          status={StatusType.ACTIVE}
          size={SizeType.SMALL}
          children={
            <Typography
              type={TypographyType.B1}
              color={ColorPaletteType.WHITE}
              text="확인"
            />
          }
          onClick={handleCloseModal}
        />
      </StyledModal>
      <div className="form__title">
        <Typography
          className="title"
          type={TypographyType.H3}
          color={ColorPaletteType.GRAY1}
          text="장학제도는 취업 취약계층을 위한 제도입니다."
        />
        <Typography
          className="title"
          type={TypographyType.H3}
          color={ColorPaletteType.GRAY1}
          text="아래 조건에 해당되나요?"
        />
      </div>

      <div className="form__input">
        <div className="form__input-item">
          <Radio
            className="form__input-radio"
            children={
              <Typography
                type={TypographyType.B1}
                color={ColorPaletteType.GRAY1}
                text="경력단절여성법 기준 경력단절여성"
              />
            }
            value="1"
            checked={'1' === selectedScholarshipOption}
            onChange={e => {
              handleChangeScholarshipOption(e.target.value);
            }}
          />
        </div>
        <div className="form__input-item">
          <Radio
            className="form__input-radio"
            children={
              <Typography
                type={TypographyType.B1}
                color={ColorPaletteType.GRAY1}
                text="2021년 기준 가구 중위소득 100% 이하"
              />
            }
            value="2"
            checked={'2' === selectedScholarshipOption}
            onChange={e => {
              handleChangeScholarshipOption(e.target.value);
            }}
          />
        </div>
        <div className="form__input-item">
          <Radio
            className="form__input-radio"
            children={
              <Typography
                type={TypographyType.B1}
                color={ColorPaletteType.GRAY1}
                text="한국장학재단 학자금 지원구간 기준 6구간 이하"
              />
            }
            value="3"
            checked={'3' === selectedScholarshipOption}
            onChange={e => {
              handleChangeScholarshipOption(e.target.value);
            }}
          />
        </div>
        <div className="form__input-item">
          <Radio
            className="form__input-radio"
            children={
              <div>
                <Typography
                  type={TypographyType.B1}
                  color={ColorPaletteType.GRAY1}
                  text="국민취업제도 Ⅰ유형 또는 Ⅱ유형에 해당하나"
                />
                <Typography
                  type={TypographyType.B1}
                  color={ColorPaletteType.GRAY1}
                  text="중복수급 등의 이유로 제도에 참여할 수 없는 경우"
                />
              </div>
            }
            value="4"
            checked={'4' === selectedScholarshipOption}
            onChange={e => {
              handleChangeScholarshipOption(e.target.value);
            }}
          />
        </div>
        <div className="form__input-item">
          <Radio
            className="form__input-radio"
            children={
              <Typography
                type={TypographyType.B1}
                color={ColorPaletteType.GRAY1}
                text="국민취업지원제도 기준 특정 계층"
              />
            }
            value="5"
            checked={'5' === selectedScholarshipOption}
            onChange={e => {
              handleChangeScholarshipOption(e.target.value);
            }}
          />
          <ul>
            <li>
              <Typography
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY2}
                text="생계급여 수급자"
              />
            </li>
            <li>
              <Typography
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY2}
                text="실업급여 수급 중이거나 종료 후 6개월이 지나지 않은자"
              />
            </li>
            <li>
              <Typography
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY2}
                text="자치단체 청년수당 수급 중이거나 종료 후 6개월이 지나지 않은 자"
              />
            </li>
            <li>
              <Typography
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY2}
                text="정부 재정지원 직접 일자리 참여 중이거나 종료 후 6개월이 지나지
                않은자"
              />
            </li>
          </ul>
        </div>

        <div className="form__input-item">
          <Radio
            className="form__input-radio"
            children={
              <Typography
                type={TypographyType.B1}
                color={ColorPaletteType.GRAY1}
                text="2020년도 취업성공 패키지 참여자"
              />
            }
            value="6"
            checked={'6' === selectedScholarshipOption}
            onChange={e => {
              handleChangeScholarshipOption(e.target.value);
            }}
          />
          <ul>
            <li>
              <Typography
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY2}
                text="2020년도 취업 성공패키지 Ⅰ유형 또는 Ⅱ유형 중 만 35세 이상 69세
                이하로"
              />
              <Typography
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY2}
                text="사업에 참여하고 있거나 최근 6개월 이내 사업 참여가 종료된
              경우"
              />
            </li>
            <Typography
              type={TypographyType.B2}
              color={ColorPaletteType.GRAY2}
              text="(단, 신청시점 기준 재직자 제외)"
            />
          </ul>
        </div>
      </div>
      <div className="form__footer">
        <Typography
          className="subtitle"
          type={TypographyType.B1}
          color={ColorPaletteType.GRAY2}
          text="위 조건에 맞으시다면 선택 후 지원을 시작해주세요."
        />

        <div className="footer__buttons">
          <Button
            status={StatusType.DEFAULT}
            size={SizeType.BASIC}
            children={
              <Typography
                type={TypographyType.B1}
                color={ColorPaletteType.GRAY1}
                text="이전 단계로 돌아가기"
              />
            }
            onClick={closeApplyImpactCampusModal}
          />
          <Button
            status={StatusType.ACTIVE}
            size={SizeType.BASIC}
            disabled={loading}
            children={
              <Typography
                type={TypographyType.B1}
                color={ColorPaletteType.WHITE}
                text="지원 계속하기"
              />
            }
            onClick={async () => {
              if (!selectedScholarshipOption) {
                handleOpenModal();
                return;
              }
              await handleApply();
              return;
            }}
          />
        </div>
      </div>
    </ImpactCampusCheckListFormWrapper>
  );
};

export default ImpactCampusCheckListForm;
