import React from 'react';

import BasicButton from '../../../components/atoms/Button/BasicButton';
import { DashboardEntry, WewinSummaryWrapper } from './style';

type IWewinSummary = {
  handleApply?: () => void;
  loading: boolean;
  programId: number;
};

const WewinSummary: React.FC<IWewinSummary> = ({
  handleApply,
  loading,
  programId
}) => {
  const displayWewinMessage = (programId: number) => {
    if (programId === 5) {
      return (
        <>
          <div>
            <div className="isa-title">비용 지불 없이 시작합니다.</div>
            <div className="isa-description">
              소득 3000만원 이상으로 취업하면, 소득의 일부를 코드스테이츠와
              공유합니다.
            </div>
          </div>

          <div className="isa-description">
            <div>취업 시 월 소득의 12%를 18회 공유</div>
          </div>
        </>
      );
    }

    if (programId === 4) {
      return (
        <>
          <div>
            <div className="isa-title">비용 지불 없이 시작합니다.</div>
            <div className="isa-description">
              연 소득 3,000만원 이상일 경우 월 소득 14%를 18회 공유합니다.
            </div>
          </div>
        </>
      );
    }

    if (programId === 3) {
      return (
        <>
          <div>
            <div className="isa-title">비용 지불 없이 시작합니다.</div>
            <div className="isa-description">
              소득 3000만원 이상으로 취업하면, 소득의 일부를 코드스테이츠와
              공유합니다.
            </div>
          </div>

          <div className="isa-description">
            <div>취업 시 월 소득의 20% 24회 공유</div>
            <b>(1기 한정 18% 24회 공유)</b>
          </div>
        </>
      );
    }

    if (programId === 2) {
      return (
        <>
          <div>
            <div className="isa-title">비용 지불 없이 시작합니다.</div>
            <div className="isa-description">
              소득 2800만원 이상으로 취업하면, 소득의 일부를 코드스테이츠와
              공유합니다.
            </div>
          </div>

          <div className="isa-description">
            <div>취업 시 월 소득의 12% 18회 공유</div>
          </div>
        </>
      );
    }

    return (
      <>
        <div>
          <div className="isa-title">비용 지불 없이 시작합니다.</div>
          <div className="isa-description">
            소득 3000만원 이상으로 취업하면, 소득의 일부를 코드스테이츠와
            공유합니다.
          </div>
        </div>

        <div className="isa-description">
          <div>취업 시 월 소득의 17% 24회 공유</div>
        </div>
      </>
    );
  };

  return (
    <WewinSummaryWrapper>
      <DashboardEntry className="last-entry">
        {displayWewinMessage(programId)}
        <BasicButton
          color="#00B9EE"
          style={{
            margin: '20px 0px 0px 0px',
            width: '100%'
          }}
          onClick={handleApply}
          size="large"
          loading={loading}
        >
          지원 하기
        </BasicButton>
      </DashboardEntry>
    </WewinSummaryWrapper>
  );
};

export default WewinSummary;
