import React from 'react';
import styled from '@emotion/styled';
import { ColorPalette } from '../../../styles/color';

type DefaultTextboxProps = {
  id?: string;
  className?: string;
  value?: string | number | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
};

const DefaultTextboxWrapper = styled.input`
  height: 45px;
  width: 265px;
  border-radius: 5px;
  padding: 0px 0px 0px 10px;
  border: 1px solid ${ColorPalette.Gray4};
`;

const DefaultTextBox: React.FC<DefaultTextboxProps> = props => {
  return (
    <DefaultTextboxWrapper
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
    />
  );
};

export default DefaultTextBox;
