import React, { useState } from 'react';
import styled from '@emotion/styled';

import InActiveQrFloatingBody from '../../assets/image/qrattendance/unactive-qr-floating-button.png';
import ActiveQrFloatingBody from '../../assets/image/qrattendance/active-qr-floating-button.png';
import SmallBalloon from '../../assets/image/qrattendance/qr-small-balloon.png';
import BigBalloon from '../../assets/image/qrattendance/qr-big-balloon.png';

import CloseIcon from '../../assets/svg/ico-close.svg';

import { QrStatus } from '../../apollo/attendance/type';
import { useAttendanceStatus } from '../../contexts/AttendanceStatus';
import { useController } from './controller';

type Props = {
  visible: boolean;
};

const QrAttendanceCheckerWrapper = styled.div`
  .big-popup__wrapper {
    background: no-repeat center center url(${BigBalloon});
    background-size: cover;
    position: fixed;
    width: 290px;
    height: 570px;
    bottom: 50px;
    right: 25px;
    text-align: center;
    padding: 5rem 1.5rem;
    color: #ffffff;

    .btn__close {
      margin: 0 0 0 auto;
      width: 20px;
      height: 20px;
      background: no-repeat center center url(${CloseIcon});
      background-size: cover;
      cursor: pointer;
    }

    .popup-title {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 0.5rem;
    }

    .popup-sub-title {
      font-weight: 500;
      font-size: 13px;
    }

    .popup-image {
      width: 208px;
      height: 242px;
      border-radius: 24px;
      margin: 1rem auto;
      box-sizing: border-box;
      background-color: #ffffff;
      border: 1px solid #4000c7;
    }

    .popup-image-title {
      color: #000000;
      font-weight: 500;
      font-size: 1.2rem;
      margin: 1rem;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .popup-image-qr {
      width: 160px;
      height: 160px;
      margin: 0 auto;
    }

    .popup-desc {
      color: #a3afc7;
      font-size: 11px;
    }
  }

  .small-popup__wrapper {
    background: no-repeat center center url(${SmallBalloon});
    background-size: cover;
    position: fixed;
    width: 300px;
    height: 300px;
    bottom: 50px;
    right: 25px;
    text-align: center;
    padding: 1rem 1rem;
    color: #ffffff;

    .btn__close {
      margin: 60px 0 0 auto;
      width: 20px;
      height: 20px;
      background: no-repeat center center url(${CloseIcon});
      background-size: cover;
      cursor: pointer;
    }

    .popup-title {
      font-weight: 600;
      font-size: 18px;
      margin: 0.5rem 0;
    }

    .popup-desc {
      color: #a3afc7;
      font-size: 11px;
      margin: 1rem 0;
    }
  }
`;

const handleQrStatus = (props: any) => {
  switch (props.type) {
    case QrStatus.NONE:
      return;
    case QrStatus.IN_CLASS_GUIDE:
      return ActiveQrFloatingBody;
    case QrStatus.CHECK_IN_GUIDE:
      return ActiveQrFloatingBody;
    case QrStatus.CHECK_OUT_GUIDE:
      return ActiveQrFloatingBody;
    case QrStatus.INACTIVE:
      return InActiveQrFloatingBody;
    default:
      return;
  }
};

const EmojiButton = styled.div<{
  type: QrStatus | undefined;
}>`
  position: fixed;
  right: 36px;
  bottom: 36px;
  border-radius: 20px;
  width: 60px;
  height: 60px;
  background-size: 80px 80px;
  background: no-repeat -11px -11px url(${props => handleQrStatus(props)});
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.25);
  z-index: 99;

  &:hover {
    border: 2px solid #4c6ee2;
  }
`;

const QrAttendanceChecker: React.FC<Props> = props => {
  const { qrImageUrl, qrStatus, bootcampClassName } = useAttendanceStatus();
  const { isQrPopupOpen, setIsQrPopupOpen } = useController();

  return props.visible ? (
    <QrAttendanceCheckerWrapper>
      <EmojiButton onClick={() => setIsQrPopupOpen(!isQrPopupOpen)} type={qrStatus} />
      {isQrPopupOpen ? (
        qrStatus === QrStatus.IN_CLASS_GUIDE ? (
          <div className="big-popup__wrapper">
            <div className="btn__close" onClick={() => setIsQrPopupOpen(false)}></div>
            <div className="popup-title">QR 입실 / 퇴실</div>
            <div className="popup-sub-title">QR 입실 / 퇴실 가능시간(08:30~19:30)</div>
            <div className="popup-image">
              <div className="popup-image-title">{bootcampClassName}</div>
              <img className="popup-image-qr" src={qrImageUrl || undefined} alt="QR-image" />
            </div>
            <div className="popup-desc">*QR코드 미인증시 출석 인증이 불가합니다.</div>
          </div>
        ) : qrStatus === QrStatus.CHECK_IN_GUIDE ? (
          <div className="big-popup__wrapper">
            <div className="btn__close" onClick={() => setIsQrPopupOpen(false)}></div>
            <div className="popup-title">QR 입실시간입니다.</div>
            <div className="popup-sub-title">QR 정상 입실 시간 (08:30~09:10)</div>
            <div className="popup-image">
              <div className="popup-image-title">{bootcampClassName}</div>
              <img className="popup-image-qr" src={qrImageUrl || undefined} alt="QR-image" />
            </div>
            <div className="popup-desc">
              *QR 입실 / 퇴실 가능시간 (08:30~19:30)
              <br />
              *QR코드 미인증시 출석 인증이 불가합니다.
            </div>
          </div>
        ) : qrStatus === QrStatus.CHECK_OUT_GUIDE ? (
          <div className="big-popup__wrapper">
            <div className="btn__close" onClick={() => setIsQrPopupOpen(false)}></div>
            <div className="popup-title">QR 퇴실 시간입니다.</div>
            <div className="popup-sub-title">QR 정상 퇴실 시간 (18:00~19:30)</div>
            <div className="popup-image">
              <div className="popup-image-title">{bootcampClassName}</div>
              <img className="popup-image-qr" src={qrImageUrl || undefined} alt="QR-image" />
            </div>
            <div className="popup-desc">
              *QR 입실 / 퇴실 가능시간 (08:30~19:30)
              <br />
              *QR코드 미인증시 출석 인증이 불가합니다.
            </div>
          </div>
        ) : qrStatus === QrStatus.INACTIVE ? (
          <div className="small-popup__wrapper">
            <div className="btn__close" onClick={() => setIsQrPopupOpen(false)}></div>
            <div className="popup-title">QR 입/퇴실 시간이 아닙니다.</div>
            <div className="popup-desc">
              *QR 입실 / 퇴실 가능시간 (08:30~19:30)
              <br />
              *QR코드 미인증시 출석 인증이 불가합니다.
            </div>
          </div>
        ) : (
          <></>
        )
      ) : null}
    </QrAttendanceCheckerWrapper>
  ) : (
    <></>
  );
};
export default QrAttendanceChecker;
