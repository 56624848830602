import { ReactComponent as CloseIcon } from '../../assets/svg/ico-close.svg';
import { Typography, TypographyType } from '../../../../components/atoms/Typography';
import { ColorPaletteType } from '../../../../styles/color';

import styled from '@emotion/styled';
import { Skeleton } from 'antd';
import { useAttendanceStatus } from '../../contexts/AttendanceStatus';
import { useController } from './controller';
import { useCredential } from '../../../../contexts/credential';
import AttendanceSwitch from '../attendanceSwitch';

type Props = {
  visible: boolean;
};

const AttendanceLoginAlertWrapper = styled.div`
  .attendence-popup {
    width: 240px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    padding-bottom: 36px;
    .attendence-popup__button-group {
      overflow: hidden;

      .attendence-popup__button {
        box-sizing: content-box;
        float: right;
        margin: 16px 16px 20px 0;
        width: 20px;
        height: 20px;
        background: none;
        border: none;
        cursor: pointer;
      }
    }

    .attendence-popup__title + .attendence-popup__title {
      margin-bottom: 16px;
    }

    .txt__popup--noti + .txt__popup--noti {
      margin-bottom: 30px;
    }
  }
  .body-wrapper {
    z-index: 7;

    .cardnews-content {
      margin: 20px 0px;
    }
  }
`;

const AttendanceLoginAlert: React.FC<Props> = props => {
  const { closeAlert, wrapperRef, handleLoginToggleSwitch } = useController();
  const { isAttendanceLoginPopupOpen } = useCredential();
  const { userName, checkInLoading, isZkSwitch } = useAttendanceStatus();

  return props.visible && isAttendanceLoginPopupOpen ? (
    <AttendanceLoginAlertWrapper ref={wrapperRef}>
      <div className="attendence-popup">
        <div className="attendence-popup__button-group">
          <button className="attendence-popup__button" onClick={closeAlert}>
            <CloseIcon fill="#8c9cba" />
          </button>
        </div>

        <Skeleton loading={!userName} active paragraph={false}>
          <Typography
            className="attendence-popup__title"
            type={TypographyType.H4}
            color={ColorPaletteType.BLUISHGRAY4}
            text={`${userName}님`}
          />
        </Skeleton>

        <Typography
          className="attendence-popup__title"
          type={TypographyType.H4}
          color={ColorPaletteType.BLUISHGRAY4}
          text="안녕하세요"
        />

        <Typography
          className="txt__popup--noti"
          type={TypographyType.B4}
          color={ColorPaletteType.BLUISHGRAY2}
          text={`아래 째깍이를 깨워`}
        />
        <Typography
          className="txt__popup--noti"
          type={TypographyType.B4}
          color={ColorPaletteType.BLUISHGRAY2}
          text={`입실해주세요.`}
        />
        <AttendanceSwitch
          isZkSwitch={isZkSwitch}
          handleToggleSwitch={handleLoginToggleSwitch}
          checkInLoading={checkInLoading}
        />
      </div>
    </AttendanceLoginAlertWrapper>
  ) : (
    <></>
  );
};

export default AttendanceLoginAlert;
