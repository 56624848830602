import React from 'react';
import styled from '@emotion/styled';

import { ColorPalette } from '../../../styles/color';
import { StatusType, SizeType } from './type';

const handleStatusType = (status: StatusType) => {
  switch (status) {
    case StatusType.DEFAULT:
      return `
          background: ${ColorPalette.White};
          border: 1px solid ${ColorPalette.Gray4};
      `;
    case StatusType.DISABLED:
      return `
          background: ${ColorPalette.Gray4};
          &:hover {
            cursor: not-allowed;
          }
      `;
    case StatusType.ACTIVE:
      return `
          background: ${ColorPalette.CozPurple600};
      `;

    default:
      return `
          background: ${ColorPalette.White};
          border: 1px solid ${ColorPalette.Gray4};
      `;
  }
};

const handleSizeType = (size: SizeType) => {
  switch (size) {
    case SizeType.BASIC:
      return `
          width: 266px;
          height: 52px;
      `;
    case SizeType.MEDIUM:
      return `
        width:232px;
        height:44px;
      `;
    case SizeType.MEDIUMSMALL:
      return `width:208px;height:44px;`;
    case SizeType.SMALL:
      return `
          width: 72px;
          height: 32px;
      `;
    default:
      return `
          width: 266px;
          height: 52px;
      `;
  }
};

type ButtonProps = {
  status: StatusType;
  size: SizeType;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
};

const StyledButton = styled.button<{
  status: StatusType;
  size: SizeType;
}>`
  &:hover {
    cursor: pointer;
  }

  :disabled {
    cursor: not-allowed;
  }

  outline: none;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  ${({ status }) => handleStatusType(status)}
  ${({ size }) => handleSizeType(size)}
`;

export const Button: React.FC<ButtonProps> = ({ status, size, className, onClick, disabled, children }) => (
  <StyledButton className={className} status={status} size={size} onClick={onClick} disabled={disabled}>
    {children}
  </StyledButton>
);
