import React from 'react';
import styled from '@emotion/styled';

import ContentCoverImage from '../../assets/image/common/img-empty-content.png';

type EmptyContentViewerProps = {
  title: string;
  description: string;
};

const EmptyContentViewerWrapper = styled.div`
  border-radius: 30px;
  border: 1px solid #dfe3ec;
  padding: 127px 0;
  .motive-blue {
    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .image {
    display: block;
    width: 255px;
    height: 187px;
    margin: 0 auto 22px;
    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .message {
    text-align: center;
    .title {
      word-break: keep-all;
      font-size: 36px;
      font-weight: 700;
      color: #455573;
      letter-spacing: -0.025rem;
      margin-bottom: 9px;
    }

    .description {
      word-break: keep-all;
      font-size: 17px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.025rem;
      color: #667ba3;
    }
  }

  @media screen and (max-width: 992px) {
    .motive-blue {
      display: none;
    }

    .image {
      display: none;
    }

    .message {
      padding: 20px 0px;
      text-align: center;

      .title {
        font-size: 25px;
      }

      .description {
        font-size: 14px;
        max-width: 100%;
      }
    }
  }
`;

export const EmptyContentViewer: React.FC<EmptyContentViewerProps> = ({ title, description }) => (
  <EmptyContentViewerWrapper>
    <div className="motive-blue" />
    <img alt="content cover" src={ContentCoverImage} className="image" />
    <div className="message">
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </div>
  </EmptyContentViewerWrapper>
);
