import gql from 'graphql-tag';

export const MY_JOB_PROFILE_GQL = gql`
  query {
    urclassMyJobProfile {
      id
      email
      mobile
      korName
      engFirstName
      engLastName
      birthday
      githubUserName
      blogURL
      portfolioURL

      isRequireExtraInformation
      careerStatus
      lastExperienceSalary
      isLastExperienceSalaryPublic
      lastEducationStatus
      isNoInterestDomain

      favorPositions {
        id
        name
        jobCategoryId
      }

      interestDomains {
        id
        name
      }

      skillSets {
        id
        name
      }

      educationDetails {
        id
        majorName
        certificateDegree
        isAttending
        isLastEducation
        startAt
        completionAt
        schoolName
        major {
          id
          name
        }
      }

      experienceDetails {
        id
        jobTitle
        companyName
        isInService
        startAt
        endAt
      }

      languageDetails {
        id
        name
        languageSkillLevel
      }

      activityDetails {
        id
        name
        description
        category
      }
    }
  }
`;

export const JOB_POSITION_GQL = gql`
  query {
    urclassGetJobPositions {
      id
      name
      jobCategoryId
    }
  }
`;

export const MY_RESUMES_GQL = gql`
  query {
    urclassMyResumes {
      id
      title
      fileURL
      storageKey
      isPublished
      createdAt
    }
  }
`;

export const INTEREST_DOMAIN_GQL = gql`
  query {
    urclassInterestDomains {
      id
      name
    }
  }
`;

export const AUTO_COMPLTE_SKILL_SET_GQL = gql`
  query urclassSkillSetsAutoCompletion($skillSetName: String!) {
    urclassSkillSetsAutoCompletion(skillSetName: $skillSetName) {
      id
      name
    }
  }
`;

export const HIGH_SCHOOL_MAJOR_GQL = gql`
  query urclassHighSchoolMajors {
    urclassHighSchoolMajors {
      id
      name
    }
  }
`;

export const COLLEGE_MAJOR_GQL = gql`
  query urclassCollegeMajors {
    urclassCollegeMajors {
      id
      name
    }
  }
`;

export const LANGUAGE_SKILL_GQL = gql`
  query urclassLanguageSkills {
    urclassLanguageSkills {
      id
      name
    }
  }
`;
