import { ApplicationAnswerStatusType } from '../pages/detailApplication/type';
import { ApplicationAnswerResponse, TemplateItemFullResponse } from '../rest/generated';

export const calculateNextOrder = (items: TemplateItemFullResponse[], answers: ApplicationAnswerResponse[]) => {
  const answerItemIds = answers.map(answer => answer.itemId);
  const unsubmittedItems = items.filter(
    item =>
      !answerItemIds.includes(item.id) ||
      answers.find(answer => answer.itemId === item.id)?.status === ApplicationAnswerStatusType.CREATED ||
      answers.find(answer => answer.itemId === item.id)?.status === ApplicationAnswerStatusType.IN_PROGRESS
  );

  if (unsubmittedItems.length === 0) return 0;

  const unsubmittedItemsSequence: number[] = [];
  unsubmittedItems.forEach((item: TemplateItemFullResponse) => {
    if (item.sequence === 0 || !!item.sequence) {
      unsubmittedItemsSequence.push(item.sequence);
    }
  });

  const nextStep =
    unsubmittedItemsSequence.reduce((minimum: number, current: number) => {
      return minimum > current ? current : minimum;
    }) + 1;

  return nextStep;
};

export const calculateActiveStepIndex = (answers: ApplicationAnswerResponse[], items: TemplateItemFullResponse[]) => {
  if (answers.length === 0) return 0;
  if (answers.filter(answer => answer.status === ApplicationAnswerStatusType.SUBMITTED).length === items.length)
    return -1;

  const submittedAnswerItemIds = answers
    .filter(answer => answer.status === ApplicationAnswerStatusType.SUBMITTED)
    .map(answer => answer.itemId);
  const unsubmittedItems = items.filter(item => !submittedAnswerItemIds.includes(item.id));
  const unsubmittedItemsSequence: number[] = [];
  unsubmittedItems.forEach((item: TemplateItemFullResponse) => {
    if (item.sequence === 0 || !!item.sequence) {
      unsubmittedItemsSequence.push(item.sequence);
    }
  });

  const activeStep = unsubmittedItemsSequence.reduce((minimum: number, current: number) => {
    return minimum > current ? current : minimum;
  });

  return activeStep;
};

export const checkIsLastSurvey = (answers: ApplicationAnswerResponse[], items: TemplateItemFullResponse[]) => {
  return answers.filter(answer => answer.status === ApplicationAnswerStatusType.SUBMITTED).length === items.length;
};

export const checkIsEditable = (answer: ApplicationAnswerResponse, item: TemplateItemFullResponse) => {
  return !(item.survey?.test && !item.survey?.hasNoTestPage && answer.status === ApplicationAnswerStatusType.SUBMITTED);
};
