import { Button } from 'antd';
import styled from '@emotion/styled';

export const BasicButton = styled(Button)<{
  color?: string;
  hoverColor?: string;
}>`
  background-color: ${({ color }) => (color ? color : '')};
  border-color: ${({ color }) => (color ? color : '')};
  color: ${({ color }) => (color === '#F2F4F7' ? '#262F40' : '#fff')};
  :hover {
    background-color: ${({ color, hoverColor }) => (hoverColor ? hoverColor : color)};
    border-color: ${({ color, hoverColor }) => (hoverColor ? hoverColor : color)};
    color: ${({ color }) => (color === '#F2F4F7' ? '#262F40' : '#fff')};
    opacity: ${({ hoverColor }) => (hoverColor ? '' : 0.5)};
  }

  :focus {
    background-color: ${({ color }) => (color ? color : '')};
    border-color: ${({ color }) => (color ? color : '')};
    color: ${({ color }) => (color === '#F2F4F7' ? '#0F1319' : '#fff')};
  }
`;
