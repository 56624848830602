import React from 'react';
import styled from '@emotion/styled';

import { BasicContainer } from '../common/BasicContainer';
import { BlockMessage } from '../common/BlockMessage';

import { displayDueDate } from '../../../../util';

import type { Classroom } from '../../apollo/coplit/type';

const ClassroomNotiCardWrapper = styled.div`
  .notification-container {
    margin-left: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 153px;
    overflow: auto;

    .notification-classroom-title {
      font-size: 17px;
      font-weight: 600;

      .item {
        margin-bottom: 4px;
      }
    }

    .notification-classroom-value {
      font-size: 17px;
      font-weight: 600;
      color: red;

      .item {
        margin-bottom: 4px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .notification-container {
      margin-left: 0px;
    }
  }
`;

type ClassroomNotiCardProps = {
  classrooms: Classroom[];
};

export const ClassroomsNotiCard: React.FC<ClassroomNotiCardProps> = ({ classrooms }) => (
  <ClassroomNotiCardWrapper>
    <BasicContainer className="notification-container">
      <div className="notification-classroom-title">
        {classrooms.map((classroom: Classroom) => (
          <div className="item" key={classroom.id}>
            {classroom.title}
          </div>
        ))}
      </div>
      <div className="notification-classroom-value">
        {classrooms.map((classroom: Classroom) => (
          <div className="item" key={classroom.id}>
            {Number.isNaN(Number(displayDueDate(classroom.dueDate)))
              ? displayDueDate(classroom.dueDate)
              : `D-${displayDueDate(classroom.dueDate)}`}
          </div>
        ))}
      </div>
      {!classrooms.length && <BlockMessage>공지 사항이 없습니다.</BlockMessage>}
    </BasicContainer>
  </ClassroomNotiCardWrapper>
);
