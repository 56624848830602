import React from 'react';
import styled from '@emotion/styled';

const DividerWrapper = styled.div<{ length: number }>`
  width: ${({ length }) => `${length}px`};
  height: 3px;
  margin-top: 30px;
  margin-bottom: 21px;
  border-radius: 1.5px;
  background-color: #00b9ee;
`;

const Divider: React.FC<{ length: number }> = ({ length }) => (
  <DividerWrapper length={length} />
);

export default Divider;
