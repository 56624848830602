import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { ColorPalette } from '../../../styles/newColor';
import { TemplateItemFullResponse } from '../../../rest/generated';

interface Props {
  step: number;
  stepsLength?: number;
  finalOrOrder: number | string;
  item: TemplateItemFullResponse;
  isNextBtnVisible?: boolean;
}

const Wrapper = styled.li<{ borderColor: string }>`
  position: relative;
  list-style-type: none;
  display: flex;
`;
const Circle = styled.div<{ background: string; color: string; borderColor: string }>`
  background: ${({ background }) => background};
  z-index: 2;
  width: 16px;
  height: 16px;
  position: relative;
  border: ${({ borderColor }) => `1px ${borderColor} solid`};
  border-radius: 50%;
  .form-header__circle {
    color: ${({ color }) => color};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 9px;
    line-height: 1;
    height: 8px;
  }
  @media screen and (min-width: 992px) {
    width: 20px;
    height: 20px;

    .form-header__circle {
      font-size: 10px;
    }
  }
`;

const Line = styled.div<{ borderColor: string }>`
  display: flex;
  align-self: center;
  width: 10px;
  height: 1px;
  border: ${({ borderColor }) => `1px ${borderColor || '#CCD3E0'} solid`};
  @media screen and (min-width: 992px) {
    width: 26px;
  }
`;
const HeaderProgressCircle = ({ step, finalOrOrder, item, isNextBtnVisible }: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleCSS = useCallback(() => {
    if (step < finalOrOrder)
      return { background: ColorPalette.CozPurple600, color: '#FFFFFF', borderColor: ColorPalette.CozPurple600 };
    else if (finalOrOrder < step)
      return {
        background: ColorPalette.BluishGray300,
        color: '#FFFFFF',
        borderColor: ColorPalette.BluishGray300
      };
    else if (isNextBtnVisible)
      return { background: ColorPalette.CozPurple600, color: '#FFFFFF', borderColor: ColorPalette.CozPurple600 };
    return {
      background: ColorPalette.CozPurple100,
      color: ColorPalette.CozPurple600,
      borderColor: ColorPalette.CozPurple600
    };
  }, [step, isNextBtnVisible]);
  const { color, background, borderColor } = useMemo(() => handleCSS(), [isNextBtnVisible]);

  return (
    <Wrapper borderColor={borderColor}>
      {step > 1 && <Line borderColor={borderColor} />}
      <Circle
        onMouseOver={() => setIsHovering(true)}
        onMouseOut={() => setIsHovering(false)}
        background={background}
        color={color}
        borderColor={borderColor}
      >
        <span className={`form-header__circle`}>{step}</span>
      </Circle>

      {isHovering && <HoverBox title={item.survey.displayTitle} step={step} />}
    </Wrapper>
  );
};

export default HeaderProgressCircle;

const HoverWrapper = styled.div`
  @media screen and (max-width: 992px) {
    display: none;
  }
  position: absolute;
  display: inline-block;
  bottom: -30px;
  display: flex;
  flex-direction: row;
  height: 26px;
  min-width: fit-content;
  background: #ffffff;
  white-space: nowrap;
  padding: 4px 8px 4px;
  line-height: 18px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  color: ${ColorPalette.BluishGray700};
`;

const HoverBox = ({ step, title }: { step: number; title: string }) => {
  return <HoverWrapper>{`${step}.${title}`}</HoverWrapper>;
};
