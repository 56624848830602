import React from 'react';
import { RouteChildrenProps } from 'react-router-dom';

import { ReactComponent as CheckEmailCheckedIcon } from '../../assets/svg/check-verified-or-not-email.svg';
import * as Organisms from '../../components/organisms';
import * as Templates from '../..//templates';
import * as Atoms from '../..//components/atoms';
import styled from '@emotion/styled';

import { Input, Button, Modal } from 'antd';
import { ReactComponent as EmailIcon } from '../../assets/svg/email.svg';
import { Typography, TypographyType } from '../../components/atoms/Typography';
import { ColorPaletteType } from '../../styles/color';
import { AuthCodeStatus, ViewStatus } from '../../apollo/user/type';
import { useController } from './controller';

const StyledInput = styled(Input)`
  margin-top: 10px;
  width: 100%;
  height: 45px;
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
  width: 265px;
  height: 52px;
  background: #333333;
  color: #ffffff;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background: #333333;
    color: #ffffff;
  }
  &:disabled {
    color: #828282;
    background: #e0e0e0;
    cursor: not-allowed;
  }
  &:focus {
    background: #333333;
    color: #ffffff;
  }
`;

const EditUserProfilePageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .container {
    width: 265px;
    margin-top: 77px;

    h2 {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
    }

    .text-info-basic {
      position: relative;
      font-size: 13px;
      font-weight: 400;
      color: #828282;
      display: flex;
      justify-content: space-between;

      .text-info-basic__back {
        display: flex;
        position: relative;

        svg {
          position: relative;
          top: 3px;
        }
      }
    }

    .divider {
      margin: 8px 0px 9px 0px;
    }

    .text-invalid {
      font-size: 13px;
      color: #df6955;
      margin-top: 12px;
    }

    .info-email {
      margin-top: 36px;
      font-weight: 400;
      .field-email {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .input-email {
          border-radius: 5px;
          font-size: 13px;
          line-height: 16px;
        }
        .button-send-auth-code {
          font-size: 15px;
          line-height: 19px;
        }
        .sent {
          color: #828282;
          background: #ffffff;
        }
        .verified {
          color: #828282;
          background: #ffffff;
        }
      }
    }
    .button-resend-auth-code {
      cursor: pointer;
      width: fit-content;
      margin: 16px auto 0;
    }
  }
`;

const EmailSentModalWrapper = styled.div`
  text-align: center;

  .icon-email {
    margin-top: 40px;
  }
  .title {
    margin-top: 39px;
    color: #333333;
    font-size: 20px;
    font-weight: 700;
  }
  .description {
    margin-top: 29px;
    color: #828282;
    font-size: 13px;
    font-weight: 400;
  }
  .button-ok {
    margin-top: 72px;
  }
`;

const EditUserProfilePage: React.FC<RouteChildrenProps> = props => {
  const {
    issueEmailAuthCodeLoading,

    handleAuthCodeChange,
    handleEmailChange,
    handleIssueEmailAuthCode,
    handleMobileChange,
    handleNameChange,
    handleVerifyEmailAuthCode,
    handleSubmitUserProfile,
    handleBirthdayChange,
    convertSecondToMSSFormat,

    isEmailValid,
    isFormValid,

    profile,
    state,
    dispatch
  } = useController();
  return (
    <Templates.GeneralTemplate>
      {{
        loading: false,
        error: false,
        header: <Organisms.Header.GeneralHeader />,
        body: (
          <EditUserProfilePageWrapper>
            <div className="container">
              <form
                className="info-basic"
                onSubmit={async e => {
                  e.preventDefault();
                  await handleSubmitUserProfile();
                }}
              >
                <h2>회원 정보</h2>
                <div className="text-info-basic">기본정보</div>
                <Atoms.Divider className="divider" color="#E0E0E0" />
                <StyledInput value={state.name} onChange={handleNameChange} placeholder="이름 (김코즈)" />
                <StyledInput
                  value={state.mobile}
                  onChange={handleMobileChange}
                  placeholder="전화번호 (+82 10-XXXX-XXXX)"
                />
                <StyledInput
                  value={state.birthday}
                  onChange={handleBirthdayChange}
                  placeholder="생년월일 (0000.00.00)"
                />
                {!isFormValid && <div className="text-invalid">정보를 정확하게 입력 해주세요.</div>}
                <StyledButton disabled={!isFormValid} onClick={handleSubmitUserProfile}>
                  기본 정보 수정
                </StyledButton>
              </form>
              <div className="info-email">
                <div className="text-info-basic">
                  <span className="text-info-basic__front">
                    <Typography type={TypographyType.B2} color={ColorPaletteType.GRAY2} text="이메일" />
                  </span>
                  {state.viewStatus === ViewStatus.EDITING_VIEW && profile?.isEmailVerified && (
                    <span className="text-info-basic__back">
                      <Typography
                        type={TypographyType.B2}
                        color={ColorPaletteType.COZ_BLUE_600}
                        text="인증 완료 &nbsp;"
                      />
                      <CheckEmailCheckedIcon />
                    </span>
                  )}
                </div>

                <Atoms.Divider className="divider" color="#E0E0E0" />
                <div className="field-email">
                  <StyledInput
                    disabled={
                      state.authCodeStatus === AuthCodeStatus.SENT ||
                      (profile?.isEmailVerified && state.viewStatus === ViewStatus.EDITING_VIEW)
                    }
                    className="input-email"
                    value={state.email}
                    onChange={handleEmailChange}
                    placeholder="codestates@codestates.com"
                  />
                  {state.viewStatus === ViewStatus.EDITING_VIEW && profile?.isEmailVerified ? (
                    <StyledButton onClick={() => dispatch({ type: 'VERIFYING_VIEW' })}>이메일 수정</StyledButton>
                  ) : (
                    <StyledButton
                      disabled={issueEmailAuthCodeLoading || !isEmailValid}
                      loading={issueEmailAuthCodeLoading}
                      onClick={handleIssueEmailAuthCode}
                    >
                      인증코드 요청
                    </StyledButton>
                  )}
                </div>

                {state.authCodeStatus === AuthCodeStatus.SENT && (
                  <div>
                    <StyledInput
                      suffix={state.leftAuthTime > 0 && <span>{convertSecondToMSSFormat(state.leftAuthTime)}</span>}
                      value={state.authCode}
                      placeholder="인증코드 입력"
                      onChange={handleAuthCodeChange}
                    />
                    {state.isFailedVerifyEmailAuthCode && <div className="text-invalid">유효하지 않은 코드입니다.</div>}
                    <StyledButton disabled={state.authCode.length !== 6} onClick={handleVerifyEmailAuthCode}>
                      인증 완료
                    </StyledButton>
                    <div className="button-resend-auth-code">
                      <Typography
                        type={TypographyType.B3}
                        color={ColorPaletteType.GRAY1}
                        text="인증코드 재요청"
                        underline={true}
                        onClick={handleIssueEmailAuthCode}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Modal
              cancelButtonProps={{ style: { display: 'none' } }}
              closeIcon={<></>}
              onCancel={() => dispatch({ type: 'CLOSE_EMAIL_SENT_MODAL' })}
              width="310px"
              visible={state.isEmailSentModalVisible}
              footer={false}
            >
              <EmailSentModalWrapper>
                <EmailIcon className="icon-email" />
                <div className="title">인증코드가 발송되었습니다</div>
                <div className="description">메일함에서 인증코드를 확인 바랍니다.</div>
                <StyledButton className="button-ok" onClick={() => dispatch({ type: 'CLOSE_EMAIL_SENT_MODAL' })}>
                  확인
                </StyledButton>
              </EmailSentModalWrapper>
            </Modal>
          </EditUserProfilePageWrapper>
        )
      }}
    </Templates.GeneralTemplate>
  );
};

export default EditUserProfilePage;
