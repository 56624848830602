import React from 'react';
import styled from '@emotion/styled';

const HighlightTitleWrapper = styled.div`
  font-size: 34px;
  line-height: 44px;
  font-weight: 700;
  color: black;
  max-width: 600px;
  word-break: keep-all;
`;

const HighlightTitle: React.FC<{ title: string }> = ({ title }) => {
  const message = title.split(/\n/g);
  return (
    <>
      {message.map((ele: string) => (
        <HighlightTitleWrapper key={ele}>{ele.trim()}</HighlightTitleWrapper>
      ))}
    </>
  );
};

export default HighlightTitle;
