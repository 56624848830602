import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Result, Button, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { GeneralTemplate } from '../templates';
import ErrorImage from '../assets/image/404.png';
import styled from '@emotion/styled';
import { GeneralHeader } from './organisms/Header';

const ErrorPageWrapper = styled.div`
  max-width: 360px;
  margin: 0 auto;
  background-color: #fff;
  margin-top: 200px;
  .btn__error {
    background-color: #eceef3;
    border: none;
    border-radius: 6px;
    width: 112px;
    height: 32px;
    color: #455573;
    font-size: 12px;
    font-weight: 700;
    margin-top: 34px;
  }
  .ant-result {
    padding: 0;
  }
  .ant-result-content {
    background-color: #fff;
  }
  .wrap__error--content {
    margin-top: 82px;
    .txt__error {
      display: block;
      color: #262f40;
      font-weight: 700;
      font-size: 24px;
    }
  }
`;
//todo : 400이하로 내려갔을 때 이미지 100%, padding

const { Paragraph } = Typography;

const ErrorPage: React.FC<{ errorList?: any }> = ({ errorList = [] }) => {
  const history = useHistory();
  const isValidUUID = (uuid: string) => {
    const urlUuid = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
    return urlUuid.test(uuid);
  };
  const makeErrImg = () => {
    return React.createElement('img', {
      src: ErrorImage,
      className: 'img__error',
      style: { width: '100%' }
    });
  };
  const backToBeforePage = () => {
    if (document.referrer === '') {
      // url을 direct로 입력할 경우
      history.push('/');
    } else {
      history.goBack();
    }
  };
  useEffect(() => {
    if (isValidUUID(history.location.pathname.substring(1))) {
      history.replace({
        pathname: `/content${history.location.pathname}`,
        search: `${window.location.search}`
      });
    }
  }, []);

  return (
    <GeneralTemplate>
      {{
        header: <GeneralHeader />,
        body: (
          <ErrorPageWrapper>
            <Result
              icon={makeErrImg()}
              status="warning"
              extra={
                <div className="wrap__error--content">
                  <span className="txt__error">앗, 죄송해요.</span>
                  <span className="txt__error">페이지를 찾을 수 없어요.</span>
                  <Button className="btn__error" type="default" onClick={backToBeforePage}>
                    돌아가기
                  </Button>
                </div>
              }
            >
              <div>
                {errorList.map((errMsg: string) => (
                  <Paragraph key={errMsg}>
                    <CloseCircleOutlined color="red" />
                    {`  ${errMsg}`}
                  </Paragraph>
                ))}
              </div>
            </Result>
          </ErrorPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default ErrorPage;
