import { Maybe } from 'graphql/jsutils/Maybe';
import { Cohort } from '../../domain/lms/apollo/course/type';
import { PaymentOrder } from '../payment/type';
import { PaymentType } from '../product/type';

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSONObject: any;
};

export type User = {
  id: number;
  name: string;
  email: string;
  mobile: string;
  githubId?: string;
  googleId?: string;
};

export enum ApplyingStatus {
  APPLYING = 'APPLYING',
  SUBMITTED = 'SUBMITTED',
  UNSUBMITTED = 'UNSUBMITTED',
  CANCELLED = 'CANCELLED'
}

export enum AdmissionApplyingStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  INCOMPLETED = 'INCOMPLETED',
  IN_PROGRESS = 'IN_PROGRESS'
}

export enum EvaluationStatus {
  INITIALIZE = 'INITIALIZE',
  EVALUATING = 'EVALUATING',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  RESIGNED = 'RESIGNED'
}

export enum IntentionStatus {
  INITIALIZE = 'INITIALIZE',
  CONFIRMED = 'CONFIRMED',
  REVOKED = 'REVOKED'
}

export enum BoardingStatus {
  INITIALIZE = 'INITIALIZE',
  BOARDED = 'BOARDED',
  DENY = 'DENY',
  CANCEL = 'CANCEL',
  GET_OFF = 'GET_OFF'
}

export enum SubmissionStatus {
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS',
  Submitted = 'SUBMITTED',
  TemporarySaved = 'TEMPORARY_SAVED',
  Terminated = 'TERMINATED',
  Unsubmitted = 'UNSUBMITTED',
  Recreated = 'RECREATED'
}

export enum ApplicationStepType {
  FORM = 'FORM',
  INTERVIEW = 'INTERVIEW'
}

export enum ApplyServiceType {
  TYPEFORM = 'TYPEFORM',
  ADMISSION = 'ADMISSION'
}

export type ApplicationStep = {
  id: number;
  title: string;
  typeformId?: string;
  type: ApplicationStepType;
};

export type Submission = {
  id: number;
  uuid: string;
  body: string;
  applicationStepId: string;
  createdAt: Date;
};

export type ApplicationPaymentModel = {
  id: number;
  name: string;
  paymentType: PaymentType;
};

export type ApplicationProduct = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  serviceStartDate: Date;
  serviceEndDate: Date;
  weekDuration?: number;
  applyStartDate?: Date;
  applyEndDate?: Date;
  announcementDate?: Date;
  programId: number;
  applyServiceUuid?: string;
  applyServiceType: ApplyServiceType;
};

export type Application = {
  id: number;
  uuid: string;
  applyingStatus: ApplyingStatus;
  evaluationStatus: EvaluationStatus;
  intentionStatus: IntentionStatus;
  boardingStatus: BoardingStatus;
  cancelledAt: Date;
  confirmedAt: Date;
  failedAt: Date;
  paymentType: PaymentType;
  product: ApplicationProduct;
  needToPay: boolean
  userApplicationSteps: ApplicationStep[];
  submissions: Submission[];
  admissionApplierToken?: string;
};

export enum InterlockType {
  Bootcamp = 'BOOTCAMP',
  Wewin = 'WEWIN'
}

export type InterlockProduct = {
  __typename?: 'InterlockProduct';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  interlockType: InterlockType;
  interlockUuid: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
};

export type SurveySet = {
  __typename?: 'SurveySet';
  announcementDate?: Maybe<Scalars['DateTime']>;
  applyEndDate?: Maybe<Scalars['DateTime']>;
  applyStartDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayOptions?: Maybe<Scalars['JSONObject']>;
  id: Scalars['Int'];
  interlockType: InterlockType;
  isActive: Scalars['Boolean'];
  products?: Maybe<Array<InterlockProduct>>;
  slugUrl: Scalars['String'];
  submitOptions?: Maybe<Scalars['JSONObject']>;
  surveys?: Maybe<Array<SurveySetSurveyNode>>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
};

export type AdmissionApplication = {
  applyingStatus: ApplyingStatus;
  surveySet: SurveySet;
  submissions: SurveySubmission[];
};

export interface MyPageData {
  urclassMyCohorts: Cohort[];
  urclassMyPaymentOrders: PaymentOrder[];
  urclassMyApplications: Application[];
}

export type MyApplicationsData = {
  urclassMyApplications: Application[];
};

export type MyApplicationData = {
  urclassMyApplication: Application;
};

export type MyApplicationVars = {
  uuid: string;
};

export enum SocialProvider {
  GITHUB = 'GITHUB',
  GOOGLE = 'GOOGLE'
}

export interface RemoveSocialAccountVars {
  service: SocialProvider;
}

export interface RemoveSocialAccountData {
  urclassRemoveSocialAccount: boolean;
}

export type SurveySetSurveyNode = {
  __typename?: 'SurveySetSurveyNode';
  sequence: Scalars['Int'];
  survey: Survey;
};

export type Survey = {
  __typename?: 'Survey';
  columns?: Maybe<Scalars['JSONObject']>;
  createdAt: Scalars['DateTime'];
  definition?: Maybe<Scalars['JSONObject']>;
  description?: Maybe<Scalars['String']>;
  displayOptions?: Maybe<Scalars['JSONObject']>;
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isUsed: Scalars['Boolean'];
  slugUrl?: Maybe<Scalars['String']>;
  submitOptions?: Maybe<Scalars['JSONObject']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
  displayTitle: Scalars['String'];
};

export type SurveySubmission = {
  __typename?: 'SurveySubmission';
  application: Application;
  applicationId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  submissionStatus: SubmissionStatus;
  submittedResponse?: Maybe<Scalars['JSONObject']>;
  survey: Survey;
  surveyId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
};
