import React from 'react';
import styled from '@emotion/styled';

import { useController } from './controller';
import { GeneralTemplate } from '../../templates';

import { GeneralHeader } from '../../components/organisms/Header';
import { Typography, TypographyType } from '../../components/atoms/Typography';
import { SizeType, StatusType } from '../../components/molecules/Button/type';
import { Button } from '../../components/molecules/Button';

import { ReactComponent as PaymentSuccessImg } from '../../assets/svg/paymentSuccess.svg';

import { ColorPaletteType } from '../../styles/color';


const ApplyResultPageWrapper = styled.div`
  .emphasis {
    font-weight: 700;
  }

  .inline {
    display: inline-block;
  }

  .apply-result {
    margin-top: 131px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .apply-result-title {
      margin-bottom: 71px;
    }

    .ga-urclass-apply-btn {
      margin-top: 73px;
    }
  }
`;

const ApplyResultB2bPage = () => {
  const {
    cozApplicationLoading,
    handleApplyClick
  } = useController();

  return (
    <GeneralTemplate loading={cozApplicationLoading} fixedHeader={true}>
      {{
        header: <GeneralHeader />,
        body: (
          <ApplyResultPageWrapper>
            <div className='apply-result'>
              <Typography
                className='apply-result-title'
                type={TypographyType.H1}
                color={ColorPaletteType.GRAY1}
                text={'부트캠프 지원서 작성을 시작해 볼까요?'}
              />

              <PaymentSuccessImg />

              <Button
                className='ga-urclass-apply-btn'
                size={SizeType.BASIC}
                status={StatusType.ACTIVE}
                children={<Typography type={TypographyType.B1} color={ColorPaletteType.WHITE} text='확인' />}
                onClick={handleApplyClick}
              />
            </div>
          </ApplyResultPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default ApplyResultB2bPage;
