import { useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { URCLASS_TEMPARARY_LOGIN_GQL } from '../../apollo/account/mutation';
import { GeneralHeader } from '../../components/organisms/Header';
import { useCredential } from '../../contexts/credential';
import { ColorPalette } from '../../styles/color';
import { GeneralTemplate } from '../../templates';
import { makeToast } from '../../util/makeToast';
import Loader from '../certificateDownload/components/Loader';

const LoginTemparayWrapper = styled.div``;

const LoginTempararyPage: React.FC = () => {
  const history = useHistory();
  const uuid = useParams<{ uuid: string }>().uuid;

  const { login } = useCredential();

  const [loginTemparary] = useMutation(URCLASS_TEMPARARY_LOGIN_GQL);

  const signInTemparary = async (uuid: string) => {
    try {
      const { data } = await loginTemparary({
        variables: {
          uuid
        }
      });

      if (data?.urclassTempararyLogin.token) {
        login(data?.urclassTempararyLogin.token);
        makeToast({ type: 'success', content: '임시 로그인을 성공했습니다. 5분간 유효합니다.' });
        history.push('/');
      }
    } catch (e) {
      makeToast({ type: 'error', content: '잘못된 접근 입니다.' });
      history.push('/');
    }
  };

  useEffect(() => {
    if (!uuid) {
      makeToast({ type: 'error', content: '잘못된 접근 입니다.' });
      history.push('/');
    }

    signInTemparary(uuid);
  }, []);

  // 확인 시 10분 동안 로그인이 지속됩니다.
  // 구글 계정 연동 후, 구글 계정을 통해 재로그인 해주세요.
  return (
    <GeneralTemplate fixedHeader={false} backgroundColor={ColorPalette.Gray8}>
      {{
        header: <GeneralHeader />,
        body: (
          <LoginTemparayWrapper>
            <Loader title="임시 로그인 진행중 입니다" />;
          </LoginTemparayWrapper>
        ),
        footer: <></>
      }}
    </GeneralTemplate>
  );
};

export default LoginTempararyPage;
