import gql from 'graphql-tag';

export const GET_PAYMENTORDER_BY_MERCHANT_UID_GQL = gql`
  query urclassPaymentOrderByImpUID($impUID: String!) {
    urclassPaymentOrderByImpUID(impUID: $impUID) {
      id
      status
      vbankNum
      vbankName
      vbankDate
      vbankHolder
      payMethod
      orderName
      totalAmount
    }
  }
`;
