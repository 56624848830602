import React from 'react';
import { Result, Button } from 'antd';

import { ResultItemWrapper } from '../style';
import { PaymentStatusType } from '../../../../pages/apply/type';

const ApplyFail: React.FC<{ setResultStatus: Function }> = ({
  setResultStatus
}) => (
  <div>
    <Result
      status="warning"
      title={'결제가 취소 되었습니다.'}
      subTitle={'다시 결제를 시도해 주세요.'}
    >
      <ResultItemWrapper>
        <Button
          size="large"
          type="link"
          onClick={() => setResultStatus(PaymentStatusType.STANDBY)}
        >
          옵션 선택으로 돌아가기
        </Button>
      </ResultItemWrapper>
    </Result>
  </div>
);

export default ApplyFail;
