import styled from '@emotion/styled';

export const Color = {
  main: '#03BAEE',
  lightGreen: '#7ED321',
  green: '#19a619',
  lightSkyblue: 'rgba(3, 102, 214, 0.2)',
  skyblue: 'rgba(3, 102, 214, 0.8)',
  blueGray: 'rgb(240, 242, 245)',
  lightGray: '#fbfbfb',
  gray: '#DFE2E7',
  darkGray: 'rgb(151, 151, 151)',
  logoBlack: 'rgb(51, 51, 51)',
  danger: '#ff7875'
};

export const Container = styled.div`
  margin-top: 20px;
  height: ${({ height }: { height?: string }) => (height ? height : '100%')};
  border-style: solid;
  border-width: 1px;
  border-color: #f2f4f7;
  border-radius: 12px;
  padding: 20px 20px;
  background-color: white;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
`;
