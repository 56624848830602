// JobProfileForm page 1
import moment from 'moment';
import styled from '@emotion/styled';
import { Form, Input, Checkbox, Typography, Select, DatePicker, Button } from 'antd';
import { isEmpty, isNil } from 'lodash';
import Grid from '../../../../components/atoms/Grid';
import { RequireMark } from '../../../../components/atoms/RequireMark';

import type { JobPosition, FavorPosition } from '../apollo/jobs/type';
import React from 'react';
import { StyledButton } from './JobProfileForm';

enum PositionCategory {
  SOFTWARE = 1,
  DATA
}

const StyledEmploymentEssentialForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  section {
    padding-left: 30px;

    .title {
      display: flex;
    }

    .content {
      .sub-title {
        display: flex;
      }

      max-width: 800px;
      width: 100%;

      /* 선호포지션 */
      .job-position__checkbox {
        margin-left: 0px;
      }

      .favor-position__list {
        margin: 20px 0px 40px 0px;

        .favor-position__list--item {
          margin: 5px 0px;
          color: #048cd6;
          font-weight: 500;
        }
      }

      .container {
        width: 50%;
        margin-bottom: 20px;

        .flex {
          margin-bottom: -25px;
          width: 100%;

          display: flex;
          gap: 15px;
          justify-content: space-between;
        }

        .warning {
          margin-top: -18px;
          color: #ff4d4f;
        }

        .ant-picker {
          width: 100%;
        }
      }
    }
  }
`;

type EmploymentEssentialFormProps = {
  /* 선호 포지션 */
  jobPositions: JobPosition[];
  favorPositions: FavorPosition[];
  handleCheckBox: (checked: boolean, position: FavorPosition) => void;

  /* 기본정보 */
  korName?: string;
  engFirstName?: string;
  engLastName?: string;
  email?: string;
  mobile?: string;
  birthday?: string;
  handleChangeKorName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeEngFirstName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeEngLastName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeMobile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeBirthday: (dates: any, dateString: string) => void;
  korNameValid: (korName?: string) => boolean;
  engNameValid: (engName?: string) => boolean;
  emailValid: (email?: string) => boolean;
  idValid: (id?: string) => boolean;

  githubUserName?: string;
  blogURL?: string;
  portfolioURL?: string;
  handleChangeBlogURL: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeGithubUserName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePortfolioURL: (event: React.ChangeEvent<HTMLInputElement>) => void;

  /* 스킬셋 */
  jobProfileSkillSetNames: string[];
  skillSetInput: string;
  autoCompletedSkillSets: string[];
  changeSkillSetNames: (skillSets: string[]) => void;
  changeSkillSetInput: (skillSetInput: string) => void;

  /* 페이지 이동 */
  formDataValidCheck: (isGithubReq?: boolean) => boolean;
  handleChangeNextPage: () => void;
};
const EmploymentEssentialForm: React.FC<EmploymentEssentialFormProps> = ({
  jobPositions,
  favorPositions,
  handleCheckBox,
  korName,
  engFirstName,
  engLastName,
  email,
  mobile,
  birthday,
  handleChangeKorName,
  handleChangeEngFirstName,
  handleChangeEngLastName,
  handleChangeEmail,
  handleChangeMobile,
  handleChangeBirthday,
  korNameValid,
  engNameValid,
  emailValid,
  idValid,
  githubUserName,
  blogURL,
  portfolioURL,
  handleChangeGithubUserName,
  handleChangePortfolioURL,
  handleChangeBlogURL,
  jobProfileSkillSetNames,
  autoCompletedSkillSets,
  skillSetInput,
  changeSkillSetInput,
  changeSkillSetNames,
  handleChangeNextPage,
  formDataValidCheck
}) => {
  const isGithubNameRequired = favorPositions.some(
    ({ jobCategoryId }) => jobCategoryId === PositionCategory.SOFTWARE || jobCategoryId === PositionCategory.DATA
  );

  return (
    <StyledEmploymentEssentialForm>
      <section>
        <div className="title">
          <Typography.Title level={4}>선호 포지션</Typography.Title>
          <RequireMark />
          <Typography.Text type="secondary">순위는 체크한 순서대로 결정됩니다.</Typography.Text>
        </div>
        <div className="content">
          <Grid itemWidth={250} rowGap={10}>
            {jobPositions.map(position => (
              <Checkbox
                className="job-position__checkbox"
                onChange={e => handleCheckBox(e.target.checked, position)}
                key={position.id}
                checked={favorPositions.some(favorPosition => favorPosition.id === position.id)}
              >
                {position.name}
              </Checkbox>
            ))}
          </Grid>

          <div className="favor-position__list">
            {favorPositions.map((position, idx) => (
              <div className="favor-position__list--item" key={position.id}>{`${idx + 1}순위 : ${position.name}`}</div>
            ))}
          </div>
        </div>
      </section>
      {/* ------------------------------------------------------------------------------------------- */}
      <section>
        <Form>
          <div className="title">
            <Typography.Title level={4}>기본정보</Typography.Title>
          </div>

          <div className="content">
            <div className="container">
              <div className="sub-title">
                <Typography.Title level={5}>국문이름</Typography.Title> <RequireMark />
              </div>

              <Form.Item
                hasFeedback
                validateStatus={isEmpty(korName) ? '' : !korNameValid(korName) ? 'error' : 'success'}
                help={
                  korName && !korNameValid(korName)
                    ? '* 국문 이름에는 영어, 공백, 특수문자를 사용할 수 없습니다.'
                    : undefined
                }
              >
                <Input placeholder="고두헌" value={korName} onChange={handleChangeKorName} />
              </Form.Item>
            </div>

            <div className="container">
              <div className="sub-title">
                <Typography.Title level={5}>영문 이름</Typography.Title>
                <RequireMark />
                <Typography.Text type="secondary">입력하신 영문 이름으로 고유 주소가 생성됩니다.</Typography.Text>
              </div>

              <div className="flex">
                <Form.Item
                  hasFeedback
                  validateStatus={isEmpty(engFirstName) ? '' : !engNameValid(engFirstName) ? 'error' : 'success'}
                >
                  <Input placeholder="DuHeon" value={engFirstName} onChange={handleChangeEngFirstName} />
                </Form.Item>
                <Form.Item
                  hasFeedback
                  validateStatus={isEmpty(engLastName) ? '' : !engNameValid(engLastName) ? 'error' : 'success'}
                >
                  <Input placeholder="Go" value={engLastName} onChange={handleChangeEngLastName} />
                </Form.Item>
              </div>
              {engFirstName && engLastName && (!engNameValid(engFirstName) || !engNameValid(engLastName)) && (
                <div className="warning">* 영문 이름에는 한글, 공백, 특수문자를 사용할 수 없습니다.</div>
              )}
            </div>

            <div className="container">
              <div className="sub-title">
                <Typography.Title level={5}>생년월일</Typography.Title> <RequireMark />
              </div>
              <DatePicker
                value={isNil(birthday) ? undefined : moment(birthday)}
                format={'YYYY/MM/DD'}
                placeholder="yyyy/mm/dd"
                disabledDate={current => current && current >= moment().endOf('day')}
                onChange={handleChangeBirthday}
              />
            </div>

            <div className="container">
              <div className="sub-title">
                <Typography.Title level={5}>이메일</Typography.Title>
                <RequireMark />
                <Typography.Text type="secondary">Gmail만 입력 가능합니다.</Typography.Text>
              </div>

              <Form.Item
                hasFeedback
                validateStatus={isEmpty(email) ? '' : !emailValid(email) ? 'error' : 'success'}
                help={email && !emailValid(email) ? '* 이메일 형식을 확인해 주세요.' : undefined}
              >
                <Input placeholder="duheon.go@gmail.com" value={email} onChange={handleChangeEmail} />
              </Form.Item>
            </div>
            <div className="container">
              <div className="sub-title">
                <Typography.Title level={5}>전화번호</Typography.Title> <RequireMark />
              </div>

              <Form.Item>
                <Input placeholder="01012345678" value={mobile && mobile.trim()} onChange={handleChangeMobile} />
              </Form.Item>
            </div>
          </div>
        </Form>
      </section>
      {/* ------------------------------------------------------------------------------------------- */}
      <section>
        <Form>
          <div className="title">
            <Typography.Title level={4}>스킬셋 및 포트폴리오</Typography.Title>
          </div>

          <div className="content">
            <div className="container">
              <div className="sub-title">
                <Typography.Title level={5}>스킬셋</Typography.Title>
              </div>
              <Typography.Text type="secondary">
                개발스택이나 디자인 툴,마케팅 툴 등 직무 관련 스킬을 입력해주세요.
              </Typography.Text>

              <div>
                <Select
                  showSearch
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="ex. JavaScript, Figma, Mailchimp ..."
                  value={jobProfileSkillSetNames}
                  onChange={selectedValue => {
                    if (selectedValue) {
                      changeSkillSetNames(selectedValue);
                    }
                  }}
                  onSearch={selectedValue => {
                    if (selectedValue) {
                      changeSkillSetInput(selectedValue);
                    }
                  }}
                >
                  {[skillSetInput ?? undefined, ...autoCompletedSkillSets].map(skillName => (
                    <Select.Option value={skillName}>{skillName}</Select.Option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="container">
              <div className="sub-title">
                <Typography.Title level={5}>Github ID </Typography.Title>
                {isGithubNameRequired && <RequireMark />}
              </div>
              <Form.Item
                hasFeedback
                validateStatus={isEmpty(githubUserName) ? '' : !idValid(githubUserName) ? 'error' : 'success'}
                help={
                  githubUserName && !idValid(githubUserName)
                    ? '* Github ID는 한글, 공백을 사용할 수 없습니다.'
                    : undefined
                }
              >
                <Input placeholder="codestates" value={githubUserName} onChange={handleChangeGithubUserName} />
              </Form.Item>
            </div>

            <div className="container">
              <Typography.Title level={5}>블로그 주소</Typography.Title>
              <Input placeholder="medium.com/code-states" value={blogURL} onChange={handleChangeBlogURL} />
            </div>
            <div className="container">
              <Typography.Title level={5}>포트폴리오 주소</Typography.Title>
              <Input placeholder="myportfolio.com" value={portfolioURL} onChange={handleChangePortfolioURL} />
            </div>
          </div>
        </Form>
      </section>

      <div className="modal-navigate-container">
        <div className="button"></div>

        <div className="button">
          <StyledButton
            type="link"
            disabled={!formDataValidCheck(isGithubNameRequired)}
            onClick={() => {
              handleChangeNextPage();
            }}
          >
            다음
          </StyledButton>
        </div>
      </div>
    </StyledEmploymentEssentialForm>
  );
};

export default EmploymentEssentialForm;
