import React from 'react';
import styled from '@emotion/styled';
import CourseCover from '../../../domain/lms/assets/image/common/img-cover.jpg';

type CardNewsProps = {
  title: string;
  description?: string;
  imageUrl?: string;
};

const CardNewsWrapper = styled.div<{
  height?: number;
  imageUrl: string;
}>`
  height: ${({ height }) => (height ? `${height}px` : '')};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .image-container {
    width: 100%;
  }

  .image {
    background-image: url(${({ imageUrl }) => imageUrl});
    background-size: cover;
    padding-top: 56.25%;
    border-radius: 10px;
    border: 1px solid #eee;

    :hover {
      opacity: 0.6;
    }
  }

  .title {
    margin: 15px 0 10px 0;
    font-size: 25px;
    font-weight: bold;
    color: #455573;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    :hover {
      opacity: 0.6;
    }
  }

  .description {
    height: 40px;
    font-size: 13px;
    line-height: 1.54;
    color: #8c939f;
    margin-bottom: 16px;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const CardNews: React.FC<CardNewsProps> = ({ title, description, imageUrl = CourseCover }) => (
  <CardNewsWrapper imageUrl={imageUrl}>
    <div className="content">
      <div className="image-container">
        <div className="image" />
      </div>
      <div className="title" title={title}>
        {title}
      </div>
      <div className="description" title={description}>
        {description}
      </div>
    </div>
  </CardNewsWrapper>
);

export default CardNews;
