import { createInstance, getUserId } from '@hackler/react-sdk';
import { URLSearchParams } from 'url';
import { Credential } from '../../contexts/credential';
import { detectDevice } from '../detectDevice/detectDevice';

const HACKLE_BROWSER_SDK_KEY =
  process.env.REACT_APP_ENV === 'production' ? 'mhFCYUInX7mBa5YEHCiL4TlwHDVXSpva' : 'cabyd0QJgxM4kIslHi0mtArAYe2FMGbT';

export const hackleClient = createInstance(HACKLE_BROWSER_SDK_KEY);

export const getUser = (credential: Credential, searchParams: URLSearchParams) => {
  const utm_source = searchParams.get('utm_source');
  const utm_medium = searchParams.get('utm_medium');
  const utm_campaign = searchParams.get('utm_campaign');
  const utm_content = searchParams.get('utm_content');
  const utm_term = searchParams.get('utm_term');
  const course_name_type = searchParams.get('type');

  const hackleUser = {
    id: getUserId(),
    device: detectDevice(),
    properties: {
      id: 0,
      utm_source: utm_source ? utm_source : '',
      utm_medium: utm_medium ? utm_medium : '',
      utm_campaign: utm_campaign ? utm_campaign : '',
      utm_content: utm_content ? utm_content : '',
      utm_term: utm_term ? utm_term : '',
      course_name_type: course_name_type ? course_name_type : ''
    }
  };
  if (credential.profile) {
    hackleUser.properties.id = credential.profile.id;
  }
  return hackleUser;
};
