import React from 'react';
import styled from '@emotion/styled';
import { GeneralHeader } from '../components/organisms/Header';

type CodeEditorTemplateProps = {
  children: {
    header?: React.ReactNode;
    body: React.ReactNode;
  };
};

const CodeEditorTemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: white;
  .body {
    width: 100%;
    flex: 1;
    background-color: rgb(251, 251, 251);
    .wrapper__modal {
    }
  }
`;

const BodyWrapper = styled.div``;

// TODO: header, sidebar, body 구성으로 변경
const CodeEditorTemplate: React.FC<CodeEditorTemplateProps> = ({ children }) => {
  const { header, body } = children;

  return (
    <CodeEditorTemplateWrapper>
      <GeneralHeader />
      <BodyWrapper className="body">{body}</BodyWrapper>
    </CodeEditorTemplateWrapper>
  );
};

export default CodeEditorTemplate;
