import React from 'react';

import SignupForm from './SignupForm';

type LoginFormProps = {
  isPreApplicant: boolean;
  handleChangeIsPreApplicant: () => void;
  redirectUrl?: string;
  googleAuth: string;
  githubAuth: string;
  kakaoAuth: string;
  naverAuth: string;
  email: string;
  password: string;
  handleSignIn: (e: React.FormEvent<HTMLFormElement>) => void;
  handleEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const LoginForm: React.FC<LoginFormProps> = ({ kakaoAuth, googleAuth, isPreApplicant, handleChangeIsPreApplicant }) => {
  return (
    <SignupForm kakaoAuth={kakaoAuth} handleChangeIsPreApplicant={handleChangeIsPreApplicant} googleAuth={googleAuth} />
  );
};

export default LoginForm;
