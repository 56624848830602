export enum PayMethodType {
  CARD = 'card',
  VBANK = 'vbank'
}

export const ApplyErrorCode = {
  ALREADY_APPLIED_ANOTHER_PRODUCT: 'already-applied-another-product',
  ALREADY_APPLIED: 'already-applied',
  INVALID_APPLY_DURATION: 'invalid-apply-duration',
  WRONG_PAYMENT_MODEL: 'wrong-payment-model',
  NO_APPLICATION: 'no-application',
  CLOSED_APPLICATION: 'Application is closed'
} as const;

export interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  mobile: string;
}

export enum PaymentStatusType {
  STANDBY,
  SUCCESS,
  PAYMENT_FAIL,
  CONFIRM_FAIL,
  APPLY_SUCCESS
}

export interface IVbank {
  vbank_num: string;
  vbank_name: string;
  vbank_holder: string;
  vbank_date: number;
  paid_amount: string;
}

export type AnswerChangeStateRequest = 'CREATED' | 'IN_PROGRESS' | 'CANCELLED' | 'SUBMITTED' | 'RECREATED';

export interface GaDataType {
  id: number;
  uuid: string;
  product: {
    name: string;
  };
  user: {
    uuid: string;
  };
}
