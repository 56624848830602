import Lottie from 'react-lottie';
import animationData from './mypage-card-loader.json';
import styled from '@emotion/styled';
import { ColorPalette } from '../../../../styles/color';

type LoadingProps = {
  width?: number;
  height?: number;
  isFull?: boolean;
};

const MyPageCardLoaderWrapper = styled.div`
  background: #f7f7f7;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  height: 383px;
  width: 308px;
  position: relative;
`;
const MyPageCardLoaderContent = styled.div`
  width: 100%;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  .mypage-loader-lottie {
  }
  .mypage-loader-description {
    position: absolute;
    left: 50%;
    top: 80%;
    transform: translate(-50%, -50%);

    width: 100%;
    text-align: center;
    color: ${ColorPalette.Gray7};
  }
`;

const MyPageCardLoader = ({ width = 100, height = 100, isFull = false }: LoadingProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <MyPageCardLoaderWrapper>
      <MyPageCardLoaderContent>
        <div className="mypage-loader-lottie">
          <Lottie options={defaultOptions} height={height} width={width} />
        </div>
        <span className="mypage-loader-description">지원 카드를 불러오고 있어요</span>
      </MyPageCardLoaderContent>
    </MyPageCardLoaderWrapper>
  );
};

export default MyPageCardLoader;
