import gql from 'graphql-tag';

export const USER_COHORTS_GQL = gql`
  query urclassCohortsOfUser {
    urclassCohortsOfUser {
      id
      uuid
      name
      certEduStartDate
      certEduEndDate
    }
  }
`;
