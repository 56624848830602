import React from 'react';
import { ReactComponent as ChevronDown } from '../../../assets/svg/ico-chevron-down.svg';
import styled from '@emotion/styled';
import { ColorPalette } from '../../../styles/newColor';

type DropDownButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

const DropDownButtonWrapper = styled.button`
  cursor: pointer;
  appearance: none;
  border: none;

  margin: 0;
  padding: 8px 16px;
  border-radius: 30px;
  background-color: ${ColorPalette.CozBlue50};
  color: ${ColorPalette.CozBlue600};
  display: flex;
  column-gap: 6px;
  align-items: center;

  .NavDropDownButton-title {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: -0.025em;
  }
`;

export const DropDownButton: React.FC<DropDownButtonProps> = ({ children, onClick }) => {
  return (
    <DropDownButtonWrapper onClick={onClick}>
      <p className="NavDropDownButton-title">{children}</p>
      <ChevronDown />
    </DropDownButtonWrapper>
  );
};

export const DropDownMenu = styled.ul<{ isOpen: boolean }>`
  width: 146px;
  background-color: ${ColorPalette.BluishGray50};
  padding: 8px 0;
  border-radius: 17px;

  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
`;

export const DropDownItem = styled.li`
  cursor: pointer;
  padding: 8px 16px;
  color: ${ColorPalette.BluishGray600};
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: -0.025em;
  transition: color 0.1s;

  :hover,
  &.selected {
    color: ${ColorPalette.BluishGray700};
    font-weight: 700;
  }
`;

export default Object.assign(
  {},
  {
    Button: DropDownButton,
    Menu: DropDownMenu,
    Item: DropDownItem
  }
);
