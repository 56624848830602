import React from 'react';
import styled from '@emotion/styled';

type DefaultInputProps = {
  value?: string | number | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  type?: string;
  disabled?: boolean;
};

const DefaultInputWrapper = styled.div<{
  disabled?: boolean;
  value?: string | number;
}>`
  input {
    height: 42px;
    border-radius: 2px;
    border: solid 0.8px #979797;
    background-color: ${({ disabled }) => (disabled ? '#f3f3f3' : '#ffffff')};
    padding: 13px 16px;
    font-size: 13px;
    opacity: ${({ value }) => (value ? 1 : 0.3)};

    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};

    &:focus {
      border: solid 1px #455573;
      background-color: #ffffff;
      color: #455573;
      outline: none;
      opacity: 0.8;
    }
  }
`;

const DefaultInput: React.FC<DefaultInputProps> = ({ value, onChange, placeholder, className, type, disabled }) => (
  <DefaultInputWrapper disabled={disabled} value={value}>
    <input
      disabled={disabled}
      className={className}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      type={type}
    />
  </DefaultInputWrapper>
);

export default DefaultInput;
