import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';

import GeneralHeaderTemplate from './templates/GeneralHeaderTemplate';
import ResumeViewer from './components/ResumeViewer';
import ResumeHeader from './components/Header';
import LoadingPage from '../../../components/LoadingPage';
import ServerErrorPage from '../../../components/ServerErrorPage';

import { JOB_PROFILE_GQL } from '../common/apollo/resume/query';
import {
  INCREASE_RESUME_CONTACT_VIEW_COUNT_GQL,
  INCREASE_RESUME_DOWNLOAD_COUNT_GQL,
  INCREASE_RESUME_PAGE_VIEW_COUNT_GQL
} from '../common/apollo/resume/mutation';

import { store, dayDiff } from '../../../util';

import type {
  JobProfile,
  JobProfileData,
  JobProfileVars,
  IncreaseResumeContactViewCountData,
  IncreaseResumeContactViewCountVars,
  IncreaseResumeDownloadCountData,
  IncreaseResumeDownloadCountVars,
  IncreaseResumePageViewCountData,
  IncreaseResumePageViewCountVars
} from '../common/apollo/resume/type';
import Footer from './components/Footer';

const ResumeWrapper = styled.div`
  width: 768px;
  height: 100%;
  overflow: auto;
`;

const ResumeBackgroundCover = styled.div`
  background-color: #333;
`;

const Resume: React.FC = () => {
  const [profile, setProfile] = useState<JobProfile | undefined>(undefined);

  const params = useParams<{ uuid: string }>();
  const { data, loading, error } = useQuery<JobProfileData, JobProfileVars>(JOB_PROFILE_GQL, {
    variables: { publicUID: params.uuid }
  });

  const [increaseResumeViewCount] = useMutation<IncreaseResumeContactViewCountData, IncreaseResumeContactViewCountVars>(
    INCREASE_RESUME_CONTACT_VIEW_COUNT_GQL
  );

  const [increaseResumeDownloadCount] = useMutation<IncreaseResumeDownloadCountData, IncreaseResumeDownloadCountVars>(
    INCREASE_RESUME_DOWNLOAD_COUNT_GQL
  );

  const [increaseResumePageViewCount] = useMutation<IncreaseResumePageViewCountData, IncreaseResumePageViewCountVars>(
    INCREASE_RESUME_PAGE_VIEW_COUNT_GQL
  );

  const now = Date.now();
  const timeStamp = store.timeStamp.getTimeToVisit(params.uuid);

  const handleContactClick = async (visible: boolean) => {
    try {
      if (visible) {
        await increaseResumeViewCount({
          variables: { publicUID: params.uuid }
        });
      }
    } catch (error) {}
  };

  const handleDownload = async () => {
    try {
      await increaseResumeDownloadCount({
        variables: { publicUID: params.uuid }
      });
    } catch (error) {}
  };

  useEffect(() => {
    const originTitle = document.title;

    return () => {
      document.title = originTitle;
    };
  }, []);

  useEffect(() => {
    setProfile(data && data.urclassJobProfile);
  }, [data]);

  useEffect(() => {
    if (profile) {
      document.title = `${profile.korName} - Resume`;
    }
  }, [profile]);

  if (!timeStamp || dayDiff(timeStamp) > 1) {
    store.timeStamp.setTimeToVisit(params.uuid, now.toString());
    increaseResumePageViewCount({
      variables: { publicUID: params.uuid }
    });
  }

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ServerErrorPage />;
  }

  return (
    <ResumeBackgroundCover>
      <GeneralHeaderTemplate fullResponsive>
        {{
          header: (
            <ResumeHeader profile={profile} handleContactClick={handleContactClick} handleDownload={handleDownload} />
          ),
          body: (
            <ResumeWrapper>
              <ResumeViewer profile={profile} />
            </ResumeWrapper>
          ),
          footer: <Footer />
        }}
      </GeneralHeaderTemplate>
    </ResumeBackgroundCover>
  );
};

export default Resume;
