import React from 'react';
import styled from '@emotion/styled';
import { Divider } from 'antd';

type Label = {
  text: string;
  value: string | number | undefined;
};

type TextButtonGroupProps = {
  labelSource: Label[];
  value?: string;
  handleOnChange?: (value: string | number | undefined) => void;
};

const TextButtonGroupWrapper = styled.div`
  margin: 15px 0px;

  .label {
    color: #cfcfcf;
    cursor: pointer;

    &.current {
      font-weight: 500;
      color: #5e85e8;
    }
  }
`;

const TextButtonGroup: React.FC<TextButtonGroupProps> = ({ labelSource, value, handleOnChange }) => (
  <TextButtonGroupWrapper>
    {labelSource.map((label: Label, idx: number) => {
      if (idx === labelSource.length - 1) {
        return (
          <span
            className={label.value === value ? 'label current' : 'label'}
            onClick={() => handleOnChange && handleOnChange(label.value)}
            key={label.text}
          >
            {label.text}
          </span>
        );
      }

      return (
        <React.Fragment key={label.text}>
          <span
            className={label.value === value ? 'label current' : 'label'}
            onClick={() => handleOnChange && handleOnChange(label.value)}
          >
            {label.text}
          </span>
          <Divider type="vertical" />
        </React.Fragment>
      );
    })}
  </TextButtonGroupWrapper>
);

export default TextButtonGroup;
