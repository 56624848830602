export enum StatusType {
  DEFAULT = 'default',
  DISABLED = 'disabled',
  ACTIVE = 'active'
}

export enum SizeType {
  BASIC = 'basic',
  MEDIUM = 'medium',
  MEDIUMSMALL = 'mediumsmall',
  SMALL = 'small'
}
