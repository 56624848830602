import React from 'react';
import { Progress } from 'antd';
import styled from '@emotion/styled';

import { BasicContainer } from '../common/BasicContainer';
import { progressCalculation } from '../../../../util';

const ClassroomsStatusCardWrapper = styled.div`
  .progress-container {
    margin-right: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 153px;

    .info-element-progress {
      flex: 1;
      text-align: center;
      margin: 0px 20px 0px 0px;

      .progress-title {
        margin-top: 10px;
        color: #777;
      }
    }

    .info-element-title {
      flex: 2;
      text-align: start;
      font-size: 17px;

      .item {
        margin-bottom: 4px;
        font-weight: 500;
      }
    }

    .info-element-value {
      flex: 2;
      text-align: end;
      font-size: 17px;

      .item {
        margin-bottom: 4px;
        font-weight: 600;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .progress-container {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }
`;

type ClassroomsStatusCardProps = {
  totalCodeProblemCount: number;
  restCodeProblemCount: number;
};

export const ClassroomsStatusCard: React.FC<ClassroomsStatusCardProps> = ({
  totalCodeProblemCount,
  restCodeProblemCount
}) => (
  <ClassroomsStatusCardWrapper>
    <BasicContainer className="progress-container">
      <div className="info-element-progress">
        <Progress
          percent={progressCalculation(totalCodeProblemCount, restCodeProblemCount)}
          type="dashboard"
          width={80}
          format={percent => `${percent}%`}
        />
        <div className="progress-title">진행률</div>
      </div>
      <div className="info-element-title">
        <div className="item">전체 문제</div>
        <div className="item">남은 문제</div>
      </div>
      <div className="info-element-value">
        <div className="item">{`${totalCodeProblemCount} 개`}</div>
        <div className="item">{`${restCodeProblemCount} 개`}</div>
      </div>
    </BasicContainer>
  </ClassroomsStatusCardWrapper>
);
