import React from 'react';
import { Link } from 'react-router-dom';

import BasicTab from '../../atoms/Tab/BasicTab';

interface HeaderTabsProps {
  className?: string;
}

const HeaderTabs: React.FC<HeaderTabsProps> = ({ className }) => (
  <div className={className}>
    <Link to={'/courses'}>
      <BasicTab>수강 중인 코스</BasicTab>
    </Link>
    <Link to={'/classrooms'}>
      <BasicTab>코플릿</BasicTab>
    </Link>
    <Link to={'/jobs'}>
      <BasicTab>Jobs</BasicTab>
    </Link>
  </div>
);

export default HeaderTabs;
