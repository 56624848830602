import React from 'react';
import styled from '@emotion/styled';
import { List, Tag, Button } from 'antd';
import { GithubOutlined, LinkOutlined, FileTextOutlined } from '@ant-design/icons';

import { EntryTitle } from '../../../../components/atoms/Title';
import { SectionLabel } from '../../../../components/atoms/Label';
import { BasicContainer } from '../../../../components/atoms/Container';

import { JobProfile, FavorPosition, InterestDomain } from '../../common/apollo/jobs/type';
import {
  convertActivityCategoryToDisplayName,
  convertCertificateDegreeToDisplayName,
  convertLanguageSkillLevelToDisplayName
} from '../../common/utils';
import moment from 'moment';

type JobProfileProps = {
  profile: JobProfile;
  handleEdit: () => void;
};

export const JobsProfileWrapper = styled.div`
  .btn {
    display: block;
    text-align: right;
  }

  .profile-item {
    margin: 5px 0px;
    font-size: 16px;
    font-weight: 400;
    color: #333;

    .content-with-icon {
      font-size: 16px;
      font-weight: 400;
      color: #333;
      margin: 0px 5px;
    }
  }

  .profile-detail-item {
    width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .contact-container {
    margin-bottom: 20px;
    font-size: 15px;
  }

  .skill-set-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    margin-bottom: 20px;
  }

  .education-container {
    font-size: 15px;
  }

  .language-container {
    margin-bottom: 20px;
    font-size: 15px;
  }

  .activity-container {
    margin-top: 20px;
    font-size: 15px;
  }

  .require-message {
    color: #f5222d;
    font-size: 17px;
  }

  .empty-message {
    font-size: 17px;
  }
`;

const JobsProfile: React.FC<JobProfileProps> = ({ profile, handleEdit }) => {
  const {
    email,
    mobile,
    korName,
    engFirstName,
    engLastName,
    githubUserName,
    blogURL,
    portfolioURL,
    favorPositions,
    interestDomains,
    isNoInterestDomain,
    skillSets,
    lastEducationStatus,
    educationDetails,
    languageDetails,
    activityDetails,
    experienceDetails
  } = profile;

  /* 관심 무관이 아니면서, 관심분야가 없는 경우 */
  const isRequireFillInterestDomain = !isNoInterestDomain && interestDomains.length === 0;

  /* 학력정보가 없는 경우 */
  const isRequireFillEducationDetail = educationDetails.length === 0;

  /* 기타 정보가 없는 경우 */
  const isNoLanguageDetails = languageDetails.length === 0;
  const isNoActivityDetails = activityDetails.length === 0;
  const isNoExperienceDetails = experienceDetails.length === 0;

  return (
    <JobsProfileWrapper className="section-profile">
      <SectionLabel>프로필</SectionLabel>
      <BasicContainer>
        <List itemLayout="vertical" size="large">
          <List.Item>
            <div className="profile-item">
              {favorPositions.map((position: FavorPosition) => (
                <Tag key={position.id}>{position.name}</Tag>
              ))}
            </div>
          </List.Item>
          <List.Item>
            <div className="profile-item">
              {isRequireFillInterestDomain ? (
                <div className="require-message">관심분야를 입력해주세요</div>
              ) : (
                interestDomains.map((domain: InterestDomain) => <Tag key={domain.id}>{domain.name}</Tag>)
              )}
            </div>
          </List.Item>
          <List.Item>
            <EntryTitle>{korName}</EntryTitle>
            <EntryTitle>{`${engFirstName} ${engLastName}`}</EntryTitle>

            <div className="contact-container">
              <div>{mobile}</div>
              <div>{email}</div>
            </div>

            <div className="skill-set-container">
              {skillSets.map(skillSet => (
                <Tag key={skillSet.id}>{skillSet.name}</Tag>
              ))}
            </div>

            {!!githubUserName && (
              <div className="profile-item">
                <GithubOutlined />
                <span className="content-with-icon">{`github.com/${githubUserName}`}</span>
              </div>
            )}

            <div className="profile-item">
              <LinkOutlined />
              <span className="content-with-icon">{blogURL ? blogURL : '-'}</span>
            </div>

            {portfolioURL && (
              <div className="profile-item">
                <FileTextOutlined />
                <span className="content-with-icon">{portfolioURL}</span>
              </div>
            )}
          </List.Item>
          <List.Item>
            <div className="education-container">
              {isRequireFillEducationDetail ? (
                <div className="require-message">학력정보를 입력해주세요</div>
              ) : (
                <div>
                  <div>{lastEducationStatus ? convertCertificateDegreeToDisplayName(lastEducationStatus) : ''}</div>
                  {educationDetails.map(educationDetail => (
                    <div className="profile-detail-item">
                      {`${educationDetail.schoolName} |
                ${moment(educationDetail.startAt).format('YYYY.MM')} ~
                ${educationDetail.completionAt ? moment(educationDetail.completionAt).format('YYYY.MM') : ''}
                | ${educationDetail.majorName}`}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </List.Item>
          <List.Item>
            <div className="language-container">
              {isNoLanguageDetails ? (
                <div className="empty-message">언어 정보 없음</div>
              ) : (
                languageDetails.map(languageDetail => (
                  <div className="profile-detail-item">{`${
                    languageDetail.name
                  } | ${convertLanguageSkillLevelToDisplayName(languageDetail.languageSkillLevel)}`}</div>
                ))
              )}
            </div>
            <div className="activity-container">
              {isNoActivityDetails ? (
                <div className="empty-message">교육/자격/수상 정보 없음</div>
              ) : (
                activityDetails.map(activityDetail => (
                  <div className="profile-detail-item">{`${convertActivityCategoryToDisplayName(
                    activityDetail.category
                  )} | ${activityDetail.name} | ${activityDetail.description}`}</div>
                ))
              )}
            </div>
          </List.Item>
          <List.Item>
            <div className="experience-container">
              {isNoExperienceDetails ? (
                <div className="empty-message">경력 정보 없음</div>
              ) : (
                experienceDetails.map(experienceDetail => (
                  <div className="profile-detail-item">{`${experienceDetail.companyName} | ${
                    experienceDetail.jobTitle
                  } | ${moment(experienceDetail.startAt).format('YYYY.MM')} ~ ${
                    experienceDetail.endAt ? moment(experienceDetail.endAt).format('YYYY.MM') : ''
                  }`}</div>
                ))
              )}
            </div>
          </List.Item>
        </List>
        <div>
          <Button type="link" className="btn" onClick={handleEdit}>
            <span>프로필 수정 →</span>
          </Button>
        </div>
      </BasicContainer>
    </JobsProfileWrapper>
  );
};

export default JobsProfile;
