import { makeToast } from './../../../util/makeToast';
import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import axios from 'axios';
import sortBy from 'lodash/sortBy';

import { JobProfileFormModel } from '../common/viewModels/JobProfileFormModel';
import { MAX_FORM_MODAL_PAGE } from '../common/components/JobProfileForm';

import { MY_JOB_PROFILE_GQL, MY_RESUMES_GQL } from '../common/apollo/jobs/query';
import {
  DELETE_RESUME_GQL,
  PUBLISH_RESUME_GQL,
  CREATE_RESUME_UPLOAD_URL_GQL,
  CREATE_RESUME_GQL
} from '../common/apollo/jobs/mutation';

import type { UploadChangeParam } from 'antd/lib/upload';
import type {
  MyJobProfileData,
  MyResumesData,
  DeleteResumeVars,
  DeleteResumeData,
  PublishResumeVars,
  PublishResumeData,
  CreateResumeUploadURLData,
  CreateResumeUploadURLVars,
  CreateResumeData,
  CreateResumeVars
} from '../common/apollo/jobs/type';
import type { OnSuccessType, OnErrorType } from './type';

const MAX_FILE_SIZE = 5242880; // 5M

export const useController = () => {
  const [jobProfileModalVisible, setJobProfileModalVisible] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  /* Modal state */
  const [page, setPage] = useState<number>(1);

  /* Modal handler */
  const handleResetFormModal = () => {
    setPage(1);
  };

  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
    refetch: profileRefetch
  } = useQuery<MyJobProfileData>(MY_JOB_PROFILE_GQL);

  const {
    data: resumeData,
    loading: resumeLoading,
    error: resumeError,
    refetch: resumeRefetch
  } = useQuery<MyResumesData>(MY_RESUMES_GQL);

  const [urclassDeleteResume] = useMutation<DeleteResumeData, DeleteResumeVars>(DELETE_RESUME_GQL);
  const [urclassPublishResume] = useMutation<PublishResumeData, PublishResumeVars>(PUBLISH_RESUME_GQL);

  const [urclassCreateUploadUrl] = useMutation<CreateResumeUploadURLData, CreateResumeUploadURLVars>(
    CREATE_RESUME_UPLOAD_URL_GQL
  );

  const [urclassCreateResume] = useMutation<CreateResumeData, CreateResumeVars>(CREATE_RESUME_GQL);

  const createResume = async (fileURL: string, key: string, title: string) => {
    try {
      const { data } = await urclassCreateResume({
        variables: {
          input: {
            fileURL,
            key,
            title
          }
        }
      });

      if (data?.urclassCreateResume) {
        return data.urclassCreateResume.id;
      }

      return;
    } catch (err) {
      return;
    }
  };

  const deleteResume = async (resumeId: number) => {
    try {
      const { data } = await urclassDeleteResume({ variables: { resumeId } });
      if (data && data.urclassDeleteResume) {
        makeToast({
          type: 'success',
          content: '이력서가 삭제되었습니다.'
        });
        resumeRefetch();
        return;
      }
      makeToast({ type: 'error', content: '이력서 삭제에 문제가 있습니다.' });
    } catch (err) {
      makeToast({ type: 'error', content: '이력서 삭제에 문제가 있습니다.' });
    }
  };

  const publishResume = async (resumeId: number) => {
    try {
      const { data } = await urclassPublishResume({ variables: { resumeId } });
      if (data && data.urclassPublishResume) {
        makeToast({
          type: 'success',
          content: '기본 이력서로 등록되었습니다.'
        });
        resumeRefetch();
        return;
      }
      makeToast({ type: 'error', content: '기본 이력서 등록에 문제가 있습니다.' });
    } catch (err) {
      makeToast({ type: 'error', content: '기본 이력서 등록에 문제가 있습니다.' });
    }
  };

  const resumeFileUpload = async (file: File, onComplete: OnSuccessType, onError: OnErrorType) => {
    try {
      if (file.type !== 'application/pdf') {
        throw Error('Invalid-file-type');
      }

      if (file.size > MAX_FILE_SIZE) {
        throw Error('Exceeds-maximum-allowed-size');
      }

      const { data } = await urclassCreateUploadUrl({
        variables: { fileName: file.name }
      });

      if (data?.urclassCreateResumeUploadURL) {
        const { url, fields } = data.urclassCreateResumeUploadURL;

        const formData = new FormData();
        const fieldsCore = JSON.parse(fields);

        Object.keys(fieldsCore).forEach((ele: string) => formData.append(ele, fieldsCore[ele]));
        formData.append('file', file);

        // response: XML string
        const { data: response }: any = await axios.post(url, formData);

        // @ts-ignore
        onComplete?.(response, file);
        setSelectedOptions([]);
        return;
      }
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      setSelectedOptions([]);
      onError?.(error as any);
    }
  };

  const changeUploadStatus = (
    info: UploadChangeParam,
    hasUploadedResume: boolean,
    handleSetDefaultResume: (resumeId: number, hasUploadedResume: boolean) => void
  ) => {
    const { status, response, error } = info.file;

    switch (status) {
      case 'uploading':
        setIsUploading(true);
        return;

      case 'done':
        const location = response.match(/(?:<Location>)(.*?)(?=<\/Location>)/);
        const key = response.match(/(?:<Key>)(.*?)(?=<\/Key>)/);

        createResume(location[1], key[1], info.file.name)
          .then((result: number | undefined) => {
            if (result) {
              setIsUploading(false);

              hasUploadedResume && handleSetDefaultResume(result, hasUploadedResume);
              resumeRefetch();
              makeToast({
                type: 'success',
                content: '이력서가 업로드 되었습니다.'
              });
              return;
            }

            setIsUploading(false);
            makeToast({ type: 'error', content: '이력서 업로드에 문제가 있습니다.' });
          })
          .catch(makeToast({ type: 'error', content: '이력서 업로드에 문제가 있습니다.' }));

        return;

      default:
        if (error?.message.indexOf('max-resume-count-exceeded') !== -1) {
          setIsUploading(false);
          makeToast({ type: 'error', content: '이력서는 최대 5개 까지만 업로드 가능합니다.' });
          return;
        }

        if (error?.message.indexOf('Invalid-file-type') !== -1) {
          setIsUploading(false);
          makeToast({ type: 'error', content: 'pdf 파일만 업로드 가능합니다.' });
          return;
        }

        if (error?.message.indexOf('Exceeds-maximum-allowed-size') !== -1) {
          setIsUploading(false);
          makeToast({ type: 'error', content: '이력서 파일 크기는 최대 5MB 까지 입니다.' });
          return;
        }

        setIsUploading(false);
        makeToast({ type: 'error', content: '이력서 업로드에 문제가 있습니다.' });
        return;
    }
  };

  const openJobProfileModal = () => {
    setJobProfileModalVisible(true);
  };

  const closeJobProfileModal = () => {
    setJobProfileModalVisible(false);
    handleResetFormModal();
  };

  const profile = profileData?.urclassMyJobProfile;

  const jobProfileFormModel = JobProfileFormModel({
    initialProfile: profile,
    handleComplete: () => {
      setJobProfileModalVisible(false);
      handleResetFormModal();
      profileRefetch();
    },
    handleChangePreviousPage: () => {
      if (page > 1) {
        setPage(page - 1);
      }
    },
    handleChangeNextPage: () => {
      if (page < MAX_FORM_MODAL_PAGE) {
        setPage(page + 1);
      }
    }
  });

  const resumes = sortBy(resumeData?.urclassMyResumes, '-isPublished') ?? [];

  const options = [
    '이력서에 포함된 모든 링크를 크롬 시크릿모드에서 점검을 하였습니다.',
    '이력서 파일명을 "이름_포지션.pdf"로 설정하였습니다.'
  ];

  return {
    profileError,
    resumeError,
    loading: !!(profileLoading || resumeLoading),

    // flag
    hasUploadedResume: !!resumes.length,
    jobProfileModalVisible,

    // state
    resumeRefetch,
    resumes,
    profile,
    isUploading,
    selectedOptions,

    // method
    openJobProfileModal,
    closeJobProfileModal,
    createResume,
    deleteResume,
    publishResume,
    resumeFileUpload,
    changeUploadStatus,
    setIsUploading,
    setSelectedOptions,

    // etc
    options,
    page,
    jobProfileFormModel
  };
};
