import queryAxios from '../../query/queryAxios';
import { ApplicationByProductResponse, ApplicationResponse, ProductUuidResponse } from '../../rest/generated';
import { AnswerChangeStateRequest } from './type';

export interface GetSlugUrlByProductUuidRequest {
  productUuid: string;
}
export interface ReviveApplicationByUserUuidAndApplicationUuidRequest {
  applicationUuid: string;
  userUuid: string;
  changeTo: string;
}
export const getSlugUrlRequest = async (request: GetSlugUrlByProductUuidRequest) => {
  return await queryAxios()
    .get<ProductUuidResponse>(`products/uuid/${request.productUuid}`)
    .then(data => data.data);
};

export const changeApplicationState = async (request: ReviveApplicationByUserUuidAndApplicationUuidRequest) => {
  return await queryAxios().patch<ApplicationResponse>(
    `applicants/${request.userUuid}/applications/${request.applicationUuid}/state-change`,
    {
      changeFrom: 'CANCELLED',
      changeTo: request.changeTo
    }
  );
};

export const getApplicationData = async ({ userUuid, productUuid }: {
  userUuid: string;
  productUuid: string
}): Promise<ApplicationByProductResponse> => {
  return queryAxios()
    .get<ApplicationByProductResponse>(`applicants/${userUuid}/products/${productUuid}`)
    .then(data => data.data);
};

export const checkApplicationAnswersSubmitted = async (userUuid: string | undefined, applicationUuid: string) => {
  return await queryAxios()
    .get(`applicants/${userUuid}/applications/${applicationUuid}`)
    .then(data => data.data)
    .then(application => {
      const surveyLength = application.template.items.length;
      const submittedSurveyLength = application.answers.filter(
        ({ status }: { status: AnswerChangeStateRequest }) => status === 'SUBMITTED'
      ).length;
      return surveyLength === submittedSurveyLength;
    });
};
