import { makeToast } from './../../../../util/makeToast';
import { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';

import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';

import { QuizType } from '../../apollo/quiz/type';

import { SUBMIT_QUIZ_SET_GQL, RESET_QUIZ_SUBMISSIONS_GQL } from '../../apollo/quiz/mutation';
import { GET_QUIZ_SOLUTION_GQL, GET_QUIZ_SET_GQL } from '../../apollo/quiz/query';
import { GET_PLAYLIST_CONTENTS_GQL } from '../../apollo/contents/query';
import { GET_PLAYLIST_GQL } from '../../apollo/course/query';
import { UPDATE_USER_COMPLETE_GQL } from '../../apollo/course/mutation';

import { storage } from '../../../../util';

import type {
  QuizSolution,
  QuizSet,
  SubmitQuizSetData,
  SubmitQuizSetVars,
  QuizSolutionData,
  ResetQuizSubmissionsData,
  ResetQuizSubmissionsVars,
  QuizSetData
} from '../../apollo/quiz/type';
import type { PlaylistContentData } from '../../apollo/contents/types';

/* ex) url
 * 1) : urclass.codestates.com/:content?playlist=playlistId
 * 2) : urclass.codestates.com/playlist/:id
 */

export const useController = () => {
  const history = useHistory();
  const { id, content: contentUuid } = useParams<{
    id: string;
    content: string;
  }>();
  const playlistId = id || history.location.search.split('playlist=')[1];
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  /* Content */
  const [playlistsForShortcuts, setPlaylistsForShortcuts] = useState([]);
  const contentRef = useRef<HTMLDivElement>(null);
  const contentEndRef = useRef<HTMLDivElement>(null);

  const { isInViewport, setIsInViewport } = useIntersectionObserver(contentEndRef);
  const [updateUserComplete] = useMutation(UPDATE_USER_COMPLETE_GQL);

  const { data: playlistData, error: playlistDataError, loading: playlistDataLoading } = useQuery<PlaylistContentData>(
    GET_PLAYLIST_CONTENTS_GQL,
    {
      variables: { id: Number(playlistId) },
      skip: !playlistId
    }
  );

  const playlist = playlistData && playlistData.playlist;
  const courseId = (playlist && playlist.course.id) || 0;
  const availablePlaylistContents =
    playlist?.playlist_content.filter(
      pc => pc.content.version && pc.content.version.type !== 'separator' && pc.content.isPublished
    ) ?? [];
  const currentPlaylistContent = availablePlaylistContents.find(pc => pc.content.uuid === contentUuid);

  /********** Quiz **********/

  // FIXME: GET_QUIZ_SET_GQL의 id가 Int! 인데, undefined가 들어갈수도 있음.
  const { data: quizSetData } = useQuery<QuizSetData>(GET_QUIZ_SET_GQL, {
    variables: { id: currentPlaylistContent?.content?.quizSetId },
    skip: !currentPlaylistContent?.content?.quizSetId
  });
  const { refetch: fetchQuizSolution } = useQuery<QuizSolutionData>(GET_QUIZ_SOLUTION_GQL, {
    skip: true
  });
  const [submitQuizSet] = useMutation<SubmitQuizSetData, SubmitQuizSetVars>(SUBMIT_QUIZ_SET_GQL);
  const [resetQuizSubmissions] = useMutation<ResetQuizSubmissionsData, ResetQuizSubmissionsVars>(
    RESET_QUIZ_SUBMISSIONS_GQL
  );
  // 퀴즈 모달 상태
  const [isResultSubmitCheckModalOpen, setIsResultSubmitCheckModalOpen] = useState<boolean>(false);
  const [isResetQuizSubmissionsModalOpen, setIsResetQuizSubmissionsModalOpen] = useState<boolean>(false);
  const [isSubmittedQuizSetModalOpen, setIsSubmittedQuizSetModalOpen] = useState<boolean>(false);
  const [isAssessmentInfoModalOpen, setIsAssessmentInfoModalOpen] = useState<boolean>(false);
  // 현재 선택된 퀴즈 인덱스
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  // 현재 퀴즈셋
  const [quizSet, setQuizSet] = useState<QuizSet | undefined>();
  // 각 퀴즈의 로컬 상태
  const [quizStates, setQuizStates] = useState<
    {
      quizId: number;
      descriptiveAnswerInput?: string;
      selectedChoiceIds?: number[];
      isSubmitted: boolean;
      isCorrect: boolean;
      isDone: boolean;
      hasInput: boolean;
      solution?: QuizSolution;
    }[]
  >([]);
  // 퀴즈 결과 모달 노출 여부
  const [quizResultModalVisible, setQuizResultModalVisible] = useState<boolean>(false);
  // 첫 번째 퀴즈 여부
  const isFirstQuiz = currentQuizIndex === 0;
  // 마지막 퀴즈 여부
  const isLastQuiz = !!quizSet?.quizzes.length && currentQuizIndex === quizSet.quizzes.length - 1;
  // 현재 퀴즈
  const currentQuiz = quizSet?.quizzes[currentQuizIndex];
  // 현재 퀴즈 로컬 상태
  const currentQuizState = quizStates[currentQuizIndex];
  // 정답 확인 가능 여부
  const isSolutionCheckable =
    !quizSet?.isAssessment &&
    (!!currentQuizState?.descriptiveAnswerInput || (currentQuizState?.selectedChoiceIds?.length ?? 0) > 0);
  // 이미 제출한 퀴즈셋 여부
  const isSubmittedQuizSet = !!quizSet?.quizzes.find(qz => qz.submission);
  // 최종 제출 가능 여부
  const isValidInput =
    (quizSet?.isAssessment && !quizStates?.find(state => state.hasInput === false)) ||
    (!quizSet?.isAssessment && !quizStates?.find(state => state.isDone === false));

  const isSubmitable =
    (quizSet?.isAssessment && !isSubmittedQuizSet && isValidInput) || (!quizSet?.isAssessment && isValidInput);
  // 현재 퀴즈 복수 선택 유형 여부
  const isCurrentQuizMultiChoiceType = !!currentQuiz?.isMultiChoiceType;
  // 현재 퀴즈셋의 정답없음 개수
  const noSolutionQuizCount =
    quizSet?.quizzes.filter(quiz => {
      return !quiz.hasSolution;
    }).length ?? 0;
  // 현재 퀴즈셋의 전체 퀴즈 개수
  const quizCount = quizSet?.quizzes.length ?? 0;
  // 현재 퀴즈셋에서 통과 퀴즈 개수
  const passedQuizCount = quizStates.filter(qs => qs.isCorrect).length + noSolutionQuizCount;
  // 현재 퀴즈셋에서 실패 퀴즈 개수
  const failedQuizCount = quizStates.filter(qs => !qs.isCorrect).length - noSolutionQuizCount;
  // 현재 퀴즈셋에서 정답률
  const passedPercentage = quizCount ? Math.floor((100 / quizCount) * passedQuizCount) : 0;

  /********************/

  /* Playlists */
  const { data: playlistsData, error: playlistsDataError, loading: playlistsDataLoading } = useQuery(GET_PLAYLIST_GQL, {
    variables: { id: courseId }
  });

  /* useEffects */
  useEffect(() => {
    if (!playlist) {
      return;
    }

    const { name, course } = playlist;

    if (availablePlaylistContents.length > 0 && !contentUuid) {
      storage.education.setCurrentPlaylist({ name, id: Number(playlistId) });
      storage.education.setCurrentCourseName(course.name);
      history.replace({
        pathname: `/content/${availablePlaylistContents[0].content.uuid}`,
        search: `?playlist=${playlistId}`
      });
      return;
    }
  }, [playlist]);

  useEffect(() => {
    if (!quizSetData?.urclassQuizSet) {
      setQuizSet(undefined);
      return;
    }

    setQuizSet(quizSetData.urclassQuizSet);
    resetQuizStates(quizSetData.urclassQuizSet);
  }, [quizSetData]);

  // 컨텐츠가 바뀌었을 때 isInViewport 초기화
  useEffect(() => {
    setIsInViewport(false);
  }, [contentUuid]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPlaylistContent?.content]);

  let timerId: ReturnType<typeof setTimeout> | null;

  const debounce = <F extends (...args: any[]) => void>(
    func: F,
    delay: number
  ): ((...args: Parameters<F>) => void) => {
    return (...args: Parameters<F>): void => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        func.apply(this, args);
        timerId = null;
      }, delay);
    };
  };

  const updateUserCompleteHandler = async () => {
    await updateUserComplete({
      variables: {
        playlistId: Number(playlistId),
        contentId: currentPlaylistContent?.content.id
      },
      fetchPolicy: 'network-only',
      refetchQueries: [
        {
          query: GET_PLAYLIST_CONTENTS_GQL,
          variables: { id: Number(playlistId) }
        }
      ]
    });
  };

  // 컨텐츠의 길이가 뷰포트의 70% 이하일 때 컨텐츠 진도 컴플릿 처리
  useEffect(() => {
    if (contentRef.current?.clientHeight) {
      if (contentRef.current?.clientHeight / window.innerHeight < 0.7) {
        setIsInViewport(true);
        // 서버에 학습 완료 요청
        debounce(updateUserCompleteHandler, 1000);
      }
    }
  }, [contentRef.current?.clientHeight]);

  // 스크롤 위치가 화면의 70% 이상일 때 컨텐츠 진도 컴플릿 처리
  useEffect(() => {
    function handleScroll() {
      const scrollPoint = window.scrollY + window.innerHeight;
      const totalPageHeight = document.documentElement.scrollHeight;
      if (scrollPoint >= totalPageHeight * 0.7) {
        setIsInViewport(true);
        // 서버에 학습 완료 요청
        debounce(updateUserCompleteHandler, 1000);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!quizSet) {
      return;
    }

    if (quizSet.isAssessment) {
      if (isSubmittedQuizSet) {
        setIsSubmittedQuizSetModalOpen(true);
      } else {
        setIsAssessmentInfoModalOpen(true);
      }
    }
  }, [quizSet?.id]);

  useEffect(() => {
    let playlistsForShortcuts: any = [];
    if (playlistsData) {
      const publishedPlaylists = playlistsData.course.playlists.filter((playlist: any) => {
        return playlist.isPublished === true;
      });

      // 1. 첫번째 커리큘럼인 경우
      if (Number(playlistId) === publishedPlaylists[0].id) {
        playlistsForShortcuts.push(publishedPlaylists[0]);
        playlistsForShortcuts.push(publishedPlaylists[1]);
        playlistsForShortcuts.push(publishedPlaylists[2]);
      } else if (Number(playlistId) === publishedPlaylists[publishedPlaylists.length - 1].id) {
        // 2. 마지막 커리큘럼인 경우
        playlistsForShortcuts.push(publishedPlaylists[publishedPlaylists.length - 3]);
        playlistsForShortcuts.push(publishedPlaylists[publishedPlaylists.length - 2]);
        playlistsForShortcuts.push(publishedPlaylists[publishedPlaylists.length - 1]);
      } else {
        // 3. 중간 커리큘럼인 경우
        for (let i = 0; i < publishedPlaylists.length; i++) {
          if (Number(playlistId) === publishedPlaylists[i].id) {
            playlistsForShortcuts.push(publishedPlaylists[i - 1]);
            playlistsForShortcuts.push(publishedPlaylists[i]);
            playlistsForShortcuts.push(publishedPlaylists[i + 1]);
          }
        }
      }
      playlistsForShortcuts = playlistsForShortcuts.filter((playlist: any) => playlist !== undefined);
      setPlaylistsForShortcuts(playlistsForShortcuts);
    }
  }, [playlistsData]);


  /* Methods */
  
  const resetQuizStates = (quizSet: QuizSet) => {
    setCurrentQuizIndex(0);

    const newStates = quizSet.quizzes.map(quiz => {
      const state = {
        quizId: quiz.id,
        descriptiveAnswerInput: quiz.submission?.descriptiveAnswer,
        selectedChoiceIds: quiz.submission?.choices.map(choice => choice.id),
        isSubmitted: !!quiz.submission,
        isCorrect: !!quiz.submission?.isCorrect,
        solution: quiz.solution,
        isDone: false,
        hasInput: false
      };
      state.isDone = state.isSubmitted || !!state.solution;
      state.hasInput = state.isDone;

      return state;
    });

    setQuizStates(newStates);
  };

  const handleToggleMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  const handleCloseMobileMenu = () => {
    setIsMobileMenuVisible(false);
  };

  const handleMoveQuizStep = (index: number) => {
    setCurrentQuizIndex(index);
  };

  const handleInputDescriptiveAnswer = (input: string) => {
    if (!currentQuizState) {
      return;
    }

    const copy = [...quizStates];
    const item = copy[currentQuizIndex];
    item.descriptiveAnswerInput = input;
    item.isDone = false;
    item.hasInput = input.length > 0;

    copy.splice(currentQuizIndex, 1, item);

    setQuizStates(copy);
  };

  const handleSelectChoice = (choiceId: number) => {
    if (!currentQuizState) {
      return;
    }
    const copy = [...quizStates];
    const item = copy[currentQuizIndex];

    if (!isCurrentQuizMultiChoiceType) {
      item.selectedChoiceIds = [];
    } else {
      item.selectedChoiceIds = item.selectedChoiceIds ?? [];
    }

    const choiceIndex = item.selectedChoiceIds.findIndex(cid => cid === choiceId);

    if (choiceIndex === -1) {
      item.selectedChoiceIds.push(choiceId);
    } else {
      item.selectedChoiceIds.splice(choiceIndex, 1);
    }
    item.isDone = false;
    item.hasInput = item.selectedChoiceIds!.length > 0;
    copy.splice(currentQuizIndex, 1, item);

    setQuizStates(copy);
  };

  const handleShowResult = async () => {
    if (!quizSet) {
      return;
    }

    // 평가용이라면 제출 처리 후에 모달 노출
    if (quizSet.isAssessment) {
      try {
        await submitQuizSet({
          variables: {
            quizSetId: quizSet.id,
            quizSubmissions: quizStates.map(quizState => {
              return {
                quizId: quizState.quizId,
                selectedChoiceIds: quizState.selectedChoiceIds,
                descriptiveAnswerInput: quizState.descriptiveAnswerInput
              };
            })
          }
        });
        makeToast({ type: 'success', content: '풀이가 제출되었습니다.' });
      } catch (err) {
        makeToast({
          type: 'error',
          content: '풀이 제출 도중 오류가 발생했습니다.'
        });
        return;
      }
    }

    setQuizResultModalVisible(true);
  };

  const handleSubmitQuizSet = async () => {
    if (!quizSet) {
      return;
    }

    try {
      await submitQuizSet({
        variables: {
          quizSetId: quizSet.id,
          quizSubmissions: quizStates.map(quizState => {
            return {
              quizId: quizState.quizId,
              selectedChoiceIds: quizState.selectedChoiceIds,
              descriptiveAnswerInput: quizState.descriptiveAnswerInput
            };
          })
        }
      });
      setQuizResultModalVisible(false);
      makeToast({ type: 'success', content: '풀이가 제출되었습니다.' });
    } catch (err) {
      makeToast({
        type: 'error',
        content: '풀이 제출 도중 오류가 발생했습니다.'
      });
      return;
    }
  };

  const handleClickRetryButton = () => {
    setIsResetQuizSubmissionsModalOpen(true);
  };

  const handleClickSubmitButton = () => {
    setIsResultSubmitCheckModalOpen(true);
  };

  const cleanText = (text: string) => {
    return text.toLowerCase().replace(/\s/g, '');
  };

  const checkCurrentInput = (solution: QuizSolution) => {
    if (!currentQuiz) {
      return false;
    }

    /* 주관식의 정답이 존재하지 않는 경우, true 처리 */
    if (!currentQuiz.hasSolution) {
      return true;
    }

    switch (currentQuiz.type) {
      case QuizType.CHOICE: {
        return !!(
          currentQuizState.selectedChoiceIds &&
          solution.choices.map(choice => currentQuizState.selectedChoiceIds!.includes(choice.id)).filter(Boolean)
            .length === solution.choices.length
        );
      }
      case QuizType.DESCRIPTIVE: {
        return !!(
          currentQuizState.descriptiveAnswerInput &&
          solution.descriptiveAnswers
            .map(answer => cleanText(answer))
            .find(answer => answer === cleanText(currentQuizState.descriptiveAnswerInput!))
        );
      }
    }
  };

  const handleCheckSolution = async () => {
    if (!currentQuiz) {
      return;
    }

    const {
      data: {
        urclassQuiz: { solution }
      }
    } = await fetchQuizSolution({
      quizId: currentQuiz.id
    });

    const isCorrect = checkCurrentInput(solution);
    const copy = [...quizStates];
    copy.splice(currentQuizIndex, 1, {
      ...copy[currentQuizIndex],
      isDone: true,
      isCorrect,
      solution
    });

    setQuizStates(copy);
  };

  const handleMovePrevQuiz = () => {
    if (isFirstQuiz) {
      return;
    }

    setCurrentQuizIndex(currentQuizIndex - 1);
  };

  const handleMoveNextQuiz = () => {
    if (isLastQuiz) {
      return;
    }

    setCurrentQuizIndex(currentQuizIndex + 1);
  };

  const handleCancelQuizResultModal = () => {
    setQuizResultModalVisible(false);
  };

  const handleResetQuizSubmissions = async () => {
    if (!quizSet) {
      return;
    }

    const { data } = await resetQuizSubmissions({
      variables: {
        quizSetId: quizSet.id
      }
    });

    if (data && data.urclassResetQuizSubmissions) {
      const quizSet = data.urclassResetQuizSubmissions;
      resetQuizStates(quizSet);
    }

    setQuizResultModalVisible(false);
  };
  
  return {
    playlistDataError,
    playlistDataLoading,
    playlist,
    playlistId,
    playlistsData,
    history,
    contentUuid,
    availablePlaylistContents,
    currentPlaylistContent,
    courseId,
    playlistsForShortcuts,
    handleToggleMobileMenu,
    isMobileMenuVisible,
    handleCloseMobileMenu,
    quizSet,
    currentQuizIndex,
    currentQuiz,
    currentQuizState,
    quizStates,
    handleInputDescriptiveAnswer,
    handleSelectChoice,
    handleMoveQuizStep,
    handleCheckSolution,
    handleShowResult,
    handleMovePrevQuiz,
    handleMoveNextQuiz,
    handleSubmitQuizSet,
    isFirstQuiz,
    isLastQuiz,
    isSolutionCheckable,
    isSubmitable,
    isValidInput,
    isCurrentQuizMultiChoiceType,
    isInViewport,
    isSubmittedQuizSetModalOpen,
    isAssessmentInfoModalOpen,
    isResultSubmitCheckModalOpen,
    isResetQuizSubmissionsModalOpen,
    contentRef,
    contentEndRef,

    quizResultModalVisible,
    setQuizResultModalVisible,
    setIsSubmittedQuizSetModalOpen,
    setIsAssessmentInfoModalOpen,
    setIsResultSubmitCheckModalOpen,
    setIsResetQuizSubmissionsModalOpen,
    handleCancelQuizResultModal,
    handleResetQuizSubmissions,
    handleClickRetryButton,
    handleClickSubmitButton,

    quizCount,
    passedQuizCount,
    failedQuizCount,
    passedPercentage,

    isSubmittedQuizSet
  };
};
