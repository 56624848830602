import gql from 'graphql-tag';

export const CERTIFICATE_GQL = gql`
  query certificate($cohortUuid: String!) {
    urclassMe {
      id
      name
      birthday
    }

    urclassOrganization {
      id
      uuid
      name
      address
      registrationNumber
      phoneNumber
      trainingDay
      trainingTime
    }

    urclassCohort(uuid: $cohortUuid) {
      id
      uuid
      certIssuable
      certEduStartDate
      certEduEndDate
      certCourseName
      certCourseDesc
    }
  }
`;

export const ATTENDANCE_CERTIFICATE_GQL = gql`
  query urclassAttendanceCertificate($cohortUuid: String!, $startDate: DateTime!, $endDate: DateTime!) {
    urclassAttendanceCertificate(cohortUuid: $cohortUuid, startDate: $startDate, endDate: $endDate) {
      attendanceListData {
        trainingDate
        loginIp
        isAttended
      }
      name
      cohortName
    }
  }
`;
