import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Modal } from "antd";
import { DoubleLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { useController } from "./controller";
import { GeneralTemplate } from "../../../../templates";
import { ClassroomStatusCard } from "../../components/coplit/ClassroomStatusCard";
import { Problems } from "../../components/coplit/Problems";

const DEFAULT_SCREEN_MIN_WIDTH = 400;

const ClassroomPageWrapper = styled.div`
  .status-card {
    margin-top: 20px;
  }

  .problems {
    margin-top: 40px;
  }

  .back-button {
    display: block;
    margin: 30px 0px 0px 0px;
    color: #295ce0;

    .title {
      margin: 0px 5px;
    }
  }
`;

const ClassroomPage: React.FC = () => {
  const {
    loading,
    error,
    currentClassroom,
    isPassed,
    setIsPassed,
    codeProblems,
    codeProblemsTotal,

    page,
    setPage,
    perPage,
    setPerPage,
    status,
    setStatus,

    myCodeProblemLoading
  } = useController();

  useEffect(() => {
    if (window.outerWidth < DEFAULT_SCREEN_MIN_WIDTH) {
      Modal.warning({
        title: "해당 기능은 모바일 환경을 지원하지 않습니다.",
        content: "데스크탑 혹은 테블릿기기를 이용해주세요."
      });
    }
  }, []);

  return (
    <GeneralTemplate loading={loading} error={!!error}>
      {{
        body: (
          <ClassroomPageWrapper>
            <Link to="/classrooms" className="back-button">
              <DoubleLeftOutlined />
              <span className="title">코플릿 목록 보기</span>
            </Link>
            <div className="status-card">
              <ClassroomStatusCard classroom={currentClassroom} />
            </div>
            <div className="problems">
              <Problems
                isPassed={isPassed}
                setIsPassed={setIsPassed}
                codeProblems={codeProblems}
                codeProblemsTotal={codeProblemsTotal}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                status={status}
                setStatus={setStatus}
                myCodeProblemLoading={myCodeProblemLoading}
              />
            </div>
          </ClassroomPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default ClassroomPage;
