import styled from '@emotion/styled';
import LoaderImg from '../../../assets/image/codestates_loader.gif';

type LoaderProps = {
  title: string;
  text?: string;
};

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;

  span {
    display: block;
    font-weight: 700;
  }
  .img__loader {
    width: 106px;
    height: 24px;
    margin: 0 auto;
  }
  .txt__loader {
    color: #262f40;
    font-size: 24px;
    margin: 36px 0 10px;
  }
  .txt__loader--noti {
    color: #8c9cba;
    font-size: 15px;
  }
`;
const Loader = ({ title, text }: LoaderProps) => {
  return (
    <LoaderWrapper>
      <img alt="이미지 로더" src={LoaderImg} className="img__loader" />
      <span className="txt__loader">{title}</span>
      <span className="txt__loader--noti">{text}</span>
    </LoaderWrapper>
  );
};

export default Loader;
