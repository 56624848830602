import React from 'react';
import styled from '@emotion/styled';
import { Modal } from 'antd';

import { useController } from './controller';
import { GeneralTemplate } from '../../templates';
import { LoginForm } from './organisms';

import { GeneralHeader } from '../../components/organisms/Header';
import { Typography, TypographyType } from '../../components/atoms/Typography';

import { ColorPalette, ColorPaletteType } from '../../styles/color';
import { SocialAuthURI } from '../../util';

const { GithubAuth, GoogleAuth, NaverAuth, KakaoAuth } = SocialAuthURI;

const ErrorModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .modal-error-message {
    text-align: center;
    max-width: 200px;
  }
  .modal-ok-button {
    &:hover {
      cursor: pointer;
    }
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 300px;
    height: 52px;
    background-color: ${ColorPalette.Gray1};
  }
`;

export const LoginPage: React.FC = () => {
  const controller = useController();

  return (
    <GeneralTemplate fixedHeader={false} backgroundColor={ColorPalette.Gray8}>
      {{
        header: <GeneralHeader />,
        body: (
          <div>
            <LoginForm
              isPreApplicant={controller.isPreApplicant}
              handleChangeIsPreApplicant={controller.handleChangeIsPreApplicant}
              redirectUrl={controller.pathname}
              googleAuth={GoogleAuth}
              githubAuth={GithubAuth}
              naverAuth={NaverAuth}
              kakaoAuth={KakaoAuth}
              handleSignIn={controller.signIn}
              email={controller.email}
              handleEmail={controller.handleEmail}
              password={controller.password}
              handlePassword={controller.handlePassword}
            />
            <Modal
              centered={true}
              cancelButtonProps={{ style: { display: 'none' } }}
              visible={controller.errorModalVisible}
              footer={false}
              closeIcon={<></>}
              width="334px"
            >
              <ErrorModalContentWrapper>
                <Typography
                  className="modal-error-message"
                  type={TypographyType.B2}
                  color={ColorPaletteType.GRAY2}
                  text={controller.errorMessage}
                />
                <div className="modal-ok-button" onClick={controller.okErrorModal}>
                  <Typography type={TypographyType.B1} color={ColorPaletteType.WHITE} text="확인" />
                </div>
              </ErrorModalContentWrapper>
            </Modal>
          </div>
        ),
        footer: <></>
      }}
    </GeneralTemplate>
  );
};

export default LoginPage;
