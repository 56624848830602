import gql from 'graphql-tag';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from 'apollo-utilities';

import Urls from '../util/urls';
import { store } from '../util';

const httpLink = createHttpLink({
  uri: Urls('graphql').http
});

const admissionLink = createHttpLink({
  uri: Urls('graphql').admission
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
      clientUrl: window.location.href
    }
  };
});

const resolvers = {
  Mutation: {
    toggleLogin: (_: any, { login }: any, { cache }: any) => {
      const newLoginState = { value: login, __typename: 'isLogin' };
      const data = { isLogin: newLoginState };
      cache.writeData({ data });
      return null;
    },
    updatePartnersUserInfo: (_: any, { userEmail, userId, isConfirmed }: any, { cache }: any) => {
      const newProfile = {
        userEmail,
        userId,
        isConfirmed,
        __typename: 'partners-user-info'
      };
      const data = { partnersUserInfo: newProfile };
      cache.writeData({ data });
      return null;
    }
  }
};

export const PARTNERS_USER_INFO = gql`
  query partnersUserInfo {
    isLogin @client {
      value
    }

    partnersUserInfo @client {
      userEmail
      userId
      isConfirmed
    }
  }
`;

export const TOGGLE_LOGIN = gql`
  mutation ToggleLogin($login: boolean!) {
    toggleLogin(login: $login) @client {
      isLogin
    }
  }
`;

export const UPDATE_PARTNERS_USER_INFO = gql`
  mutation updatePartnersUserInfo($userEmail: string, $userId: number, $isConfirmed: boolean) {
    updatePartnersUserInfo(userEmail: $userEmail, userId: $userId, isConfirmed: $isConfirmed) @client {
      userId
      userEmail
      isConfirmed
    }
  }
`;

export const loginState = async (isLogin: boolean) => {
  if (!isLogin) {
    localStorage.removeItem('token');
  }
  await client.mutate({
    mutation: TOGGLE_LOGIN,
    variables: { login: isLogin }
  });
};

const errorLink = onError(({ graphQLErrors, networkError }): any => {
  const unauthorizedMessages = ['token-invalid', 'Access denied'];
  if (graphQLErrors) {
    if (graphQLErrors.find(gqlError => unauthorizedMessages.includes(gqlError.message))) {
      const event = new Event('logout');
      window.dispatchEvent(event);
    }

    graphQLErrors.map(
      ({ message, locations, path }) => `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    );
  }
  if (networkError) {
    const msgNetworkError = `[Network error]: ${JSON.stringify(networkError)}`;
    console.log(msgNetworkError);
  }

  return null;
});

const rightLink = ApolloLink.from([authLink, httpLink]);
const link = split(
  operator => operator.getContext().clientName === 'admissionGQL',
  admissionLink,
  split(({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition';
  }, rightLink)
);

const cache = new InMemoryCache();

const client = new ApolloClient({
  cache,
  resolvers,
  link: errorLink.concat(link)
});

const initCache = () => {
  // 로컬스토리지에 토큰이 있으면 로그인 처리
  const currentToken = store.token.get();
  const isLogin = !!currentToken;

  cache.writeQuery({
    query: PARTNERS_USER_INFO,
    data: {
      isLogin: {
        value: isLogin,
        __typename: 'isLogin'
      },

      partnersUserInfo: {
        userEmail: '',
        userId: 0,
        isConfirmed: false,
        __typename: 'partners-user-info'
      }
    }
  });
};

initCache();

export default client;
