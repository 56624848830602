import { makeToast } from './../../util/makeToast';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { useCredential } from '../../contexts/credential';
import { SIGN_UP_GQL } from '../../apollo/account/mutation';
import { SocialProvider } from '../../apollo/account/type';

import * as kp from '../../util/marketingTools/kakaoPixel';
import { store } from '../../util';

import type { SignUpData, SignUpVars } from '../../apollo/account/type';

import { useTrack } from '@hackler/react-sdk';
import { dataLayerForSignupEvent } from '../../util/marketingTools/datalayerGa4';

type PushStateType = {
  email: string;
  phoneNumber: string;
  accessToken: string;
  service: SocialProvider;
};

export const useController = () => {
  const { login } = useCredential();
  const history = useHistory<PushStateType>();
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState('');
  const [requiredAgree, setRequiredAgree] = useState(false);
  const [marketingUseAgreement, setMarketingUseAgreement] = useState(false);
  const [receivingMessagesAgreement, setReceivingMessagesAgreement] = useState(false);

  const track = useTrack();

  const mediaType = store.adsMediaType.get() ?? 'DIRECT';

  const checkedAll = requiredAgree && marketingUseAgreement && receivingMessagesAgreement;

  const checkAll = () => {
    setRequiredAgree(!checkedAll);
    setReceivingMessagesAgreement(!checkedAll);
    setMarketingUseAgreement(!checkedAll);
  };

  const [signUpMutation, { loading: signUpMutationLoading }] = useMutation<SignUpData, SignUpVars>(SIGN_UP_GQL);

  useEffect(() => {
    if (!history.location.state) {
      setErrorMessage('가입과정에 문제가 있습니다.');
      history.push('/');
    } else {
      const { email, phoneNumber } = history.location.state;

      if (!email || !phoneNumber) {
        setErrorMessage('가입과정에 문제가 있습니다.');
        history.push('/');
        return;
      }

      setEmail(email);
      setMobile(phoneNumber);
    }
  }, [history]);

  const handleValidateNameField = (name: string) => name.length > 1 && !name.match(/[ -~₩]/g);

  const isNameFieldValid = handleValidateNameField(name);

  const okErrorModal = () => {
    setErrorMessage('');
  };

  const signup = async () => {
    // validation

    if (!requiredAgree) {
      setErrorMessage('필수 항목에 동의하지 않으셨습니다.');
      return;
    }

    const { accessToken, service } = history.location.state;

    try {
      const { data } = await signUpMutation({
        variables: {
          mobile,
          email,
          name,
          marketingUseAgreement,
          receivingMessagesAgreement,
          accessToken,
          service,
          mediaType,

          serviceTermsAgreement: requiredAgree,
          privacyTermsAgreement: requiredAgree
        }
      });

      if (!(data && data.urclassSignUp)) {
        setErrorMessage('가입과정에 문제가 있습니다.');
        return;
      }

      // Kakao Pixel
      kp.completeRegistration();

      const token: string = data.urclassSignUp.token;
      const { pathname, search } = store.redirectURL.get();
      //gm
      dataLayerForSignupEvent({ data, urlQueries: search });

      const hackleEvent = {
        key: `complete_registration`,
        value: 10000
      };
      track(hackleEvent);

      login(token);

      if (pathname) {
        history.push({ pathname, search });
        return;
      }

      history.push('/');
    } catch (error) {
      console.log(error);

      if (error.message.indexOf('github-account-with-same-info-exist') !== -1) {
        setErrorMessage('이미 가입된 Github 계정이 있습니다.');
        return;
      }
      makeToast({ type: 'error', content: '가입에 문제가 있습니다.' });
      history.replace('/login');
    }
  };

  return {
    errorMessage,
    okErrorModal,

    checkAll,
    checkedAll,
    name,
    setName,
    isNameFieldValid,
    requiredAgree,
    receivingMessagesAgreement,
    marketingUseAgreement,
    setRequiredAgree,
    setMarketingUseAgreement,
    setReceivingMessagesAgreement,

    handleValidateNameField,

    signup,
    signUpMutationLoading
  };
};
