/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { GeneralTemplate } from '../../templates';
import { GeneralHeader } from '../../components/organisms/Header';
import { ColorPalette } from '../../styles/color';
import LoadingPage from '../../components/LoadingPage';
import { ApplicationFullResponse } from '../../rest/generated';
import { useParams } from 'react-router-dom';
import { Application, ApplyServiceType, MyApplicationData, MyApplicationVars } from '../../apollo/mypage/type';
import { useQuery as apolloUseQuery } from '@apollo/react-hooks';
import { MY_APPLICATION_DETAIL_GQL } from '../../apollo/mypage/query';
import { sendPageView } from '../../util/marketingTools/googleTagAssistant';
import AdmissionApplicationSteps from './AdmissionApplicationSteps';
import { useQuery } from '@tanstack/react-query';

import queryKeys from '../../query/queryKeys';
import { useCredential } from '../../contexts/credential';
import ApplicationSteps from './ApplicationSteps';
import { getAdmissionApplicationByUserUuidAndApplicationUuidRequest } from './request';
import { store } from '../../util';

const MyPageWrapper = styled.div`
  background: ${ColorPalette.White};
  margin: 54px 40px 0px 40px;
  padding: 57px;

  .view-more-mobile {
    display: none;
  }

  .view-more-wrapper {
    padding-left: 20px;
    cursor: pointer;
  }

  .my-info-wrapper {
    display: flex;

    .account {
      .welcome {
        display: flex;
        align-items: center;

        .placeholder {
          flex: 1;
        }
      }

      .profiles {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 35px;
        gap: 5px;
        .un-authenticated {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 53px;
          height: 27px;
          border-radius: 5px;
          background-color: #f2f2f2;
          .text {
            font-size: 13px;
            color: #df6955;
          }
        }
        .divider {
          color: #828282;
          font-size: 15px;
          font-weight: 400;
        }
        .mobile {
          color: #828282;
          font-size: 15px;
          font-weight: 400;
        }
      }

      .edit-user-profile {
        margin-top: 10px;
        text-decoration-line: underline;
        color: #333333;
      }
    }

    .toolbar {
      flex: 1;
      display: flex;
      flex-direction: column;

      .toolbar-row {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .attendance {
        margin-left: 30px;

        .attendance-label {
          display: inline-block;
          vertical-align: middle;
        }

        .attendance-switch {
          display: inline-block;
          margin-left: 5px;
          vertical-align: middle;
        }
      }

      .kakao-auth {
        margin-top: 20px;

        .kakao-auth-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 170px;
          height: 54px;
          border: none;
          border-radius: 45px;
          background-color: ${ColorPalette.Red};

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .mypage-no-aplication {
    text-align: center;

    .mypage-no-aplication__text-group {
      margin-top: 130px;
      margin-bottom: 31px;
    }
    .mypage-no-aplication__button {
      margin-bottom: 36px;
    }
  }

  .mypage-application-group {
    margin-top: 26px;

    display: grid;

    grid-template-columns: repeat(auto-fit, 308px);
    grid-auto-rows: 1fr;
    column-gap: 33px;
    row-gap: 29px;
  }

  /* mobile */
  @media screen and (max-width: 960px) {
    margin: 54px 0px;
    padding: 0px 20px;

    .view-more-mobile {
      display: flex;
    }

    .view-more-pc {
      display: none;
    }

    .my-info-wrapper {
      flex-direction: column;

      .account {
        .profiles {
          margin-top: 12px;
          align-items: flex-start;
          flex-direction: column;
          .divider {
            display: none;
          }
        }
      }

      .toolbar {
        align-items: start;

        .toolbar-row {
          &:first-of-type {
            flex-direction: row;
            align-items: flex-end;
            margin-top: 50px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    margin: 54px 0px;
    padding: 0px;
  }
`;

const DetailApplicationPage = () => {
  const params = useParams<{ uuid: string }>();
  const { uuid: applicationUuid } = params;
  const { profile } = useCredential();
  const [selectedApplication, setSelectedApplication] = useState<Application | undefined>();
  const [selectedAdmissionApplication, setSelectedAdmissionApplication] = useState<ApplicationFullResponse>();

  const {
    data: cozApplicationData,
    error: cozApplicationError,
    loading: cozApplicationLoading
  } = apolloUseQuery<MyApplicationData, MyApplicationVars>(MY_APPLICATION_DETAIL_GQL, {
    variables: { uuid: applicationUuid },
    fetchPolicy: 'network-only',
    onCompleted: (cozApplicationData: MyApplicationData) => {
      const admissionToken = cozApplicationData.urclassMyApplication.admissionApplierToken;
      admissionToken && store.admissionToken.set(admissionToken);
    }
  });
  const productApplyServiceType = cozApplicationData?.urclassMyApplication.product.applyServiceType;
  const programId = cozApplicationData?.urclassMyApplication.product.programId;
  const getAdmissionApplicationByUuidParams = {
    applicationUuid: applicationUuid,
    userUuid: profile?.uuid || ''
  };

  const {
    data: admissionApplicationData,
    isLoading: isAdmissionApplicationLoading,
    isError: isAdmissionApplicationError
  } = useQuery(
    queryKeys.APPLICATION_CARD_BY_UUID(getAdmissionApplicationByUuidParams),
    () => getAdmissionApplicationByUserUuidAndApplicationUuidRequest(getAdmissionApplicationByUuidParams),
    {
      enabled:
        cozApplicationData?.urclassMyApplication.product.applyServiceType === ApplyServiceType.ADMISSION && !!profile
    }
  );

  useEffect(() => {
    if (!admissionApplicationData) return;

    setSelectedAdmissionApplication(admissionApplicationData);
  }, [admissionApplicationData]);

  useEffect(() => {
    if (cozApplicationData) {
      const { urclassMyApplication } = cozApplicationData;
      setSelectedApplication(urclassMyApplication);
    }
  }, [cozApplicationData, applicationUuid]);

  useEffect(() => {
    if (!selectedApplication) return;
    sendPageView(`applications/${selectedApplication.product.name}/list`);
  }, [selectedApplication, applicationUuid]);

  return (
    <GeneralTemplate>
      {{
        loading: cozApplicationLoading && isAdmissionApplicationLoading,
        error: cozApplicationError && isAdmissionApplicationError,
        header: <GeneralHeader />,
        body: (
          <MyPageWrapper>
            {productApplyServiceType === ApplyServiceType.TYPEFORM && selectedApplication ? (
              <ApplicationSteps selectedApplication={selectedApplication} />
            ) : productApplyServiceType === ApplyServiceType.ADMISSION &&
              selectedAdmissionApplication &&
              selectedApplication ? (
              <AdmissionApplicationSteps
                applicationUuid={applicationUuid}
                selectedAdmissionApplication={selectedAdmissionApplication}
                programId={programId}
              />
            ) : (
              <LoadingPage />
            )}
          </MyPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default DetailApplicationPage;
