import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as GoogleIcon } from '../../../assets/svg/ico-google.svg';
import { ReactComponent as GithubIcon } from '../../../assets/svg/ico-github.svg';

import BasicButton from '../../atoms/Button/BasicButton';

const LoginFormWrapper = styled.div`
  .login-form__message {
    margin-bottom: 90px;
    .login-form__message-title {
      span {
        width: 178px;
        height: 58px;
        font-size: 24px;
        font-weight: bold;
        color: #455573;
      }
    }

    .login-form__message-title--general {
      span {
        width: 178px;
        height: 58px;
        font-size: 24px;
        font-weight: bold;
        color: #455573;
      }
    }

    .login-form__message-title-general--mobile {
      display: none;
    }

    .login-form__message-description {
      width: 186px;
      height: 19px;
      margin: 16px 0px;
      font-size: 16px;
      color: #455573;
    }

    @media screen and (max-width: 992px) {
      margin-bottom: 0px;

      .login-form__message-title {
        margin-bottom: 92px;
      }

      .login-form__message-title--general {
        span {
          display: none;
        }
      }

      .login-form__message-title-general--mobile {
        display: block;
        text-align: center;
        font-size: 14px;
        height: 17px;
        color: #455573;
      }

      .login-form__message-description {
        font-size: 14px;
      }
    }
  }

  .login-form__platforms {
    .login-form__button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-radius: 6px;
      padding: 26px 16px;

      .login-form__button-icon {
        width: 19px;
        height: 19px;
        object-fit: contain;
      }

      .login-form__button-title {
        text-align: right;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
      }
    }

    .login-form__button--github {
      margin: 16px 0px;
    }

    @media screen and (max-width: 992px) {
      .login-form__button {
        .login-form__button-icon {
          width: 15px;
          height: 15px;
          object-fit: contain;
        }

        .login-form__button-title {
          text-align: right;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }

  .login-form__footer-message {
    display: none;

    @media screen and (max-width: 992px) {
      margin-top: 32px;
      display: block;
      text-align: center;

      p {
        margin: 0;
        font-size: 14px;
        color: #455573;

        &.highlight {
          font-weight: 600;
          color: #0189ff;
        }
      }
    }
  }

  .button {
    width: 100%;
    margin: 5px 0px;
  }
  .login__form-email {
    margin: 5px 0px;
    width: 100%;
  }
  .login__form-password {
    margin: 5px 0px;
    width: 100%;
  }
  .login__form-divider {
    color: #aaa;
  }
`;

type LoginFormProps = {
  redirectUrl?: string;
  googleAuth: string;
  githubAuth: string;
  naverAuth: string;
  handleSignIn: (e: React.FormEvent<HTMLFormElement>) => void;
  email: string;
  handleEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  password: string;
  handlePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const LoginForm: React.FC<LoginFormProps> = props => {
  return (
    <LoginFormWrapper>
      <div className="login-form__message">
        {props.redirectUrl ? (
          <p className="login-form__message-title">
            <span>
              간편하게
              <br />
              로그인하세요
            </span>
            <p className="login-form__message-description">지원 전 로그인이 필요합니다.</p>
          </p>
        ) : (
          <p className="login-form__message-title--general">
            <span>
              간편하게
              <br />
              로그인하세요
            </span>
            <p className="login-form__message-title-general--mobile">간편하게 로그인하세요</p>
          </p>
        )}
      </div>

      <div className="login-form__platforms">
        <BasicButton
          color="#dd4b39"
          className={'login-form__button login-form__button--google'}
          onClick={() => {
            window.location.assign(props.googleAuth);
            localStorage.setItem('authenticationType', 'login');
          }}
        >
          <GoogleIcon className="login-form__button-icon" />
          <div className="login-form__button-title">
            {props.redirectUrl ? 'G-mail로 로그인하기' : 'G-mail 로그인하기'}
          </div>
        </BasicButton>

        <BasicButton
          color="#455573"
          className={'login-form__button login-form__button--github'}
          onClick={() => {
            window.location.assign(props.githubAuth);
            localStorage.setItem('authenticationType', 'login');
          }}
        >
          <GithubIcon className="login-form__button-icon" />
          <div className="login-form__button-title">
            {props.redirectUrl ? 'Github로 로그인하기' : 'Github 로그인하기'}
          </div>
        </BasicButton>

        {/*
          * 네이버 로그인은 무기한 연기됩니다.
        <BasicButton
          color="#03cf5d"
          className={'login-form__button login-form__button--naver'}
          onClick={() => {
            window.location.assign(props.naverAuth);
            localStorage.setItem('authenticationType', 'login');
          }}
        >
          <NaverIcon className={'login-form__button-icon'} />
          <div className="login-form__button-title">
            {props.redirectUrl ? '네이버로 로그인하기' : '네이버 로그인하기'}
          </div>
        </BasicButton> */}
      </div>

      <div className="login-form__footer-message">
        <p className="highlight">당신의 소중한 커리어 </p>
        <p>믿을 수 있는 곳에서 가치를 키워가세요.</p>
      </div>
    </LoginFormWrapper>
  );
};

export default LoginForm;
