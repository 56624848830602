import styled from '@emotion/styled';
import { Color } from '../../../../style';

export const CodeProblemPageWrapper = styled.div`
  background-color: #fafbfc;
  color: #262f40;
  .editor-header {
    background-color: #fafbfc;
    border-bottom: 1px solid ${Color.gray};
    padding: 10px 16px;
    font-weight: 600;
    color: black;

    .icon {
      color: #262f40;
      font-size: 15px;
      font-weight: 900;
    }
    .problem-title {
      margin: 0px 10px;
      color: #455573;
    }
  }

  .editor-body {
    .codeproblem-description {
      white-space: normal;
      word-break: break-all;
      margin: 5px 10px;
      height: calc(100vh - 204px);
      overflow: scroll;
      background-color: #fafbfc;
    }

    .codeproblem-editor {
      width: 100%;
      height: 100%;
      background-color: white;
    }

    .codeproblem-console {
      overflow: auto;
      background-color: #fafbfc;
      height: 100%;

      .codeproblem-console-title {
        padding: 10px;
        border-bottom: 1px solid ${Color.gray};
        border-top: 1px solid ${Color.gray};
        font-weight: 600;
      }
      .codeproblem-console-content {
        position: relative;
        height: auto;
      }
    }

    .split {
      position: relative !important;
      height: calc(100vh - 175px) !important;
    }

    .Resizer {
      background: #dfe2e7;
      z-index: 1;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -moz-background-clip: padding;
      -webkit-background-clip: padding;
      background-clip: padding-box;
    }

    .Resizer:hover {
      -webkit-transition: all 2s ease;
      transition: all 2s ease;
    }

    .Resizer.horizontal {
      height: 10px;
      margin: -5px 0;
      border-top: 5px solid rgba(255, 255, 255, 0);
      border-bottom: 5px solid rgba(255, 255, 255, 0);
      cursor: row-resize;
      width: 100%;
    }

    .Resizer.horizontal:hover {
      border-top: 5px solid rgba(0, 0, 0, 0.5);
      border-bottom: 5px solid rgba(0, 0, 0, 0.5);
    }

    .Resizer.vertical {
      width: 11px;
      margin: 0 -5px;
      border-left: 5px solid rgba(255, 255, 255, 0);
      border-right: 5px solid rgba(255, 255, 255, 0);
      cursor: col-resize;
    }

    .Resizer.vertical:hover {
      border-left: 5px solid rgba(0, 0, 0, 0.5);
      border-right: 5px solid rgba(0, 0, 0, 0.5);
    }

    .Resizer.disabled {
      cursor: not-allowed;
    }
    .Resizer.disabled:hover {
      border-color: transparent;
    }
  }
`;
