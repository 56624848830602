import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as CheckboxBorderIcon } from '../../../assets/svg/ico-checkbox-border.svg';
import { ReactComponent as CheckboxCheckIcon } from '../../../assets/svg/ico-checkbox-checked.svg';
import { ColorPalette } from '../../../styles/color';

const CheckboxWrapper = styled.div`
  .checkbox {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 9px;
  }

  .checkbox__control {
    display: inline-grid;
    box-sizing: border-box;

    width: 15px;
    height: 15px;
    border-radius: 1px;

    box-shadow: 0 0 0 1px ${ColorPalette.Gray4} inset;

    svg {
      transform: scale(0);
      transform-origin: bottom left;
    }

    .checkbox__control-border {
      position: absolute;
    }

    .checkbox__control-checkicon {
      position: absolute;
      margin-top: 4px;
      margin-left: 3px;
    }
  }

  .checkbox__input {
    display: grid;
    grid-template-areas: 'checkbox';

    > * {
      grid-area: checkbox;
    }

    input {
      opacity: 0;
      width: 15px;
      height: 15px;

      &:checked + .checkbox__control svg {
        transform: scale(1);
      }
    }
  }
`;

type CheckboxProps = {
  children: React.ReactNode;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

export const Checkbox: React.FC<CheckboxProps> = ({ children, checked, onChange, className }) => (
  <CheckboxWrapper className={className}>
    <label className="checkbox">
      <span className="checkbox__input">
        <input type="checkbox" name="checkbox" checked={checked} onChange={onChange} />
        <span className="checkbox__control">
          <CheckboxBorderIcon className="checkbox__control-border" />
          <CheckboxCheckIcon className="checkbox__control-checkicon" />
        </span>
      </span>
      {children}
    </label>
  </CheckboxWrapper>
);
