import gql from 'graphql-tag';

export const MY_PROFILE_GQL = gql`
  query {
    urclassMe {
      id
      uuid
      email
      name
      mobile
      role
      birthday
      githubId
      googleId
      kakaoId
      githubUserName
      hasCohort
      cohortName
      isWorking
      useAttendanceFeature
      isRequiredTermsAgreements
      isEmailVerified
      isNeedIdentityVerification
      lastEnrolledAt
    }
  }
`;

export const GET_USER_GQL = gql`
  query user {
    user {
      id
      name
      mobile
      email
      role
    }
  }
`;

export const GET_USER_TOKEN = gql`
  query urclassMyTokenHistory {
    urclassMyTokenHistory {
      issuedAt
      expiredAt
    }
  }
`;
