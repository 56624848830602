import styled from '@emotion/styled';
import { GeneralHeader } from '../../components/organisms/Header';
import { GeneralTemplate } from '../../templates';
import { NewPaymentForm } from '../apply/organisms';
import { useController } from './controller';

const ApplyProductBundlePageWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ApplyProductBundlePage = () => {
  const controller = useController();
  return (
    <GeneralTemplate fixedHeader={false} loading={!controller.product} error={!!controller.productBundleError}>
      {{
        header: <GeneralHeader />,
        body: (
          <ApplyProductBundlePageWrapper>
            <NewPaymentForm
              product={controller.product!}
              productName={controller.productBundleName}
              selectedPaymentModel={controller.selectedPaymentModel}
              handlePaymentFormSubmit={controller.handlePaymentFormSubmit}
              handleChangeSelectedPaymentModel={controller.handleChangeSelectedPaymentModel}
              paymentModels={controller.paymentModels}
              loading={!controller.product}
              programId={controller.product?.program.id!}
              hideDate={false}
            />
          </ApplyProductBundlePageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default ApplyProductBundlePage;
