import gql from 'graphql-tag';

export const JOB_PROFILE_GQL = gql`
  query jobProfile($publicUID: String!) {
    urclassJobProfile(publicUID: $publicUID) {
      id
      email
      mobile
      korName
      engFirstName
      engLastName
      githubUserName
      favorPositions {
        id
        name
        jobCategoryId
      }
      publishedResume {
        id
        title
        fileURL
      }
    }
  }
`;
