import React from 'react';
import styled from '@emotion/styled';
import NavButton from './NavButton';

type NavButtonGroupProps = {
  value?: string;
  options: { name: string; value?: string }[];
  onSelected: (newValue?: string) => void;
  className?: string;
};

const NavButtonGroupWrapper = styled.div`
  .flex-wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 12px;
  }
`;

const NavButtonGroup: React.FC<NavButtonGroupProps> = ({ value, options, onSelected, className }) => {
  return (
    <NavButtonGroupWrapper className={className}>
      <div className="flex-wrapper">
        {options.map(option => (
          <NavButton
            key={`navButton-${option.value}`}
            onClick={() => onSelected(option.value)}
            className={value === option.value ? 'selected' : undefined}
          >
            {option.name}
          </NavButton>
        ))}
      </div>
    </NavButtonGroupWrapper>
  );
};

export default NavButtonGroup;
