import url from 'url';
import crypto from 'crypto';
import moment from 'moment';
import Education from './storage/education';

export const deployEnvironment = {
  production: 'production',
  local: 'local',
  dev: 'dev',
  qa: 'qa'
};

export const parseSlugUrl = (slugUrl?: string) => ({
  course: slugUrl?.split('-')[0] || '',
  cohort: slugUrl?.split('-')[1] || ''
});

export const compileContentTemplate = (text: string, variables: { [key: string]: string }) => {
  let result = text;
  Object.keys(variables).forEach(key => {
    const regex = new RegExp(`\\\${{${key}}}`, 'gi');
    result = result.replace(regex, variables[key]);
  });
  return result;
};

export const isFalsy = (obj: any) => {
  return obj === undefined || obj === null || obj === false;
};

export const numberFormatter = (value: number | string): string => {
  if (typeof value === 'number') {
    return value.toLocaleString();
  }

  return Number(value.replace(/[^0-9]/g, '')).toLocaleString();
};

export const isNumber = (value: any): boolean => (Number.isNaN(Number(value)) ? false : true);

export const dateFormatter = (date: Date) => moment(date).format('YYYY-MM-DD HH:mm:ss');

// reference: https://stackoverflow.com/a/16533568
export const stringToColor = (value: string) => {
  const djb2 = (str: string) => {
    let hash = 5381;
    for (let i = 0; i < str.length; i += 1) {
      hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
    }
    return hash;
  };

  const hashStringToColor = (str: string) => {
    const hash = djb2(str);
    const r = (hash & 0xff0000) >> 16;
    const g = (hash & 0x00ff00) >> 8;
    const b = hash & 0x0000ff;
    const rhex = `0${r.toString(16)}`.substr(-2);
    const ghex = `0${g.toString(16)}`.substr(-2);
    const bhex = `0${b.toString(16)}`.substr(-2);

    return `#${rhex}${ghex}${bhex}`;
  };

  return hashStringToColor(value);
};

export const getBadgeColor = (badgeName: string): string => {
  const antdColors = [
    'magenta',
    'red',
    'volcano',
    'orange',
    'gold',
    'lime',
    'green',
    'cyan',
    'blue',
    'geekblue',
    'purple'
  ];

  const index = badgeName.length % antdColors.length;
  return antdColors[index];
};

export const filterSelectOption = (input: string, optionText: string): boolean => {
  return optionText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const displayDueDate = (dueDate?: Date): string | number => {
  if (dueDate) {
    if (moment(dueDate).diff(moment(), 'days') < 0) {
      return 'Time over';
    }

    return moment(dueDate).diff(moment(), 'days');
  }

  return '∞';
};

export const getGravatar = (email: string): string => {
  const size = 500;
  const md5 = crypto.createHash('md5').update(email).digest('hex');

  return `https://gravatar.com/avatar/${md5}?s=${size}&d=retro`;
};

const google = {
  response_type: 'code',
  scope: 'profile email',
  redirect_uri: `${window.location.origin}/auth?social_provider=google`,
  client_id: '430860350629-p0iei83mun2uhg4ma0be52qbv8p97k8e.apps.googleusercontent.com'
};

const kakao = {
  redirect_uri: `${window.location.origin}/auth?social_provider=kakao`,
  rest_api_key: '43984033602adcda52af84344f1daa74'
};

const naver = {
  response_type: 'code',
  redirect_uri: `${window.location.origin}/auth?social_provider=naver`,
  state: crypto.randomBytes(20).toString('hex')
};

export const progressCalculation = (total: number, rest: number) => Math.floor(((total - rest) / total) * 100);

const githubClientId = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'cafa9c78a4e8f5663e33';
    case 'dev':
      return '682454fdba31207b3708';
    case 'qa':
      return 'c13af1f2b4f26536d8ed';
    case 'qa2':
      return '75e4fbd6e5a80de039ae';
    case 'local':
      return 'e4b599cbbbbd9e0e5e0f';

    default:
      return 'e4b599cbbbbd9e0e5e0f';
  }
};

const naverClientId = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'buzomwmjVQ4jWmqM33oJ';
    case 'dev':
      return 'buzomwmjVQ4jWmqM33oJ';
    // Naver 측 검수로 인해, production client key 사용
    /* case 'dev':
      return 'ofOeeLLOtVeRGvueOuXE'; */
    case 'qa':
      return 'C6hHWsQTWIBx8s0eR5e3';
    case 'local':
      return 'MaG4PWABXXSjpJGglQuc';

    default:
      return 'MaG4PWABXXSjpJGglQuc';
  }
};

const GithubAuth = `https://github.com/login/oauth/authorize?client_id=${githubClientId()}`;
const GoogleAuth = `https://accounts.google.com/o/oauth2/v2/auth?response_type=${google.response_type}&scope=${google.scope}&redirect_uri=${google.redirect_uri}&propmt=consent&client_id=${google.client_id}`;
const NaverAuth = `https://nid.naver.com/oauth2.0/authorize?response_type=${
  naver.response_type
}&client_id=${naverClientId()}&redirect_uri=${naver.redirect_uri}&state=${naver.state}`;

const KakaoAuth = `https://kauth.kakao.com/oauth/authorize?client_id=${kakao.rest_api_key}&redirect_uri=${kakao.redirect_uri}&response_type=code`;

export const SocialAuthURI = {
  GithubAuth,
  GoogleAuth,
  NaverAuth,
  KakaoAuth
};

export const eventCallbackOnScreenFrame = (
  callback: Function,
  { dismissCondition = () => false, triggerCondition = () => true }
) => {
  if (!callback) {
    throw Error('Invalid required arguments');
  }

  let tick = false;

  return () => {
    if (tick) {
      return;
    }

    tick = true;
    return requestAnimationFrame(() => {
      if (dismissCondition()) {
        tick = false;
        return;
      }

      if (triggerCondition()) {
        tick = false;
        return callback();
      }
    });
  };
};

export const dayDiff = (targetDate: number): number => {
  const now = Date.now();

  return (now - targetDate) / 86400000;
};

export const convertQueryStringToObject = (queryString: string) => {
  const queryObject: { [key: string]: string } = {};
  queryString.replace(/[?&]+([^=&]+)=([^&]*)/g, (str: string, key: string, value: string) => {
    queryObject[key] = value;
    return '';
  });

  return queryObject;
};

export const storage = {
  education: Education.getInstance()
};

export const store = {
  adsMediaType: {
    get: (): string | null => localStorage.getItem('ads_media_type'),
    set: (media: string) => localStorage.setItem('ads_media_type', media),
    remove: () => localStorage.removeItem('ads_media_type')
  },
  paidProgramType: {
    get: (): string | null => localStorage.getItem('paid_program_type'),
    set: (programType: string) => localStorage.setItem('paid_program_type', programType),
    remove: (): void => localStorage.removeItem('paid_program_type')
  },
  applyProductName: {
    get: (): string | null => localStorage.getItem('apply_product_name'),
    set: (name: string) => localStorage.setItem('apply_product_name', name),
    remove: () => localStorage.removeItem('apply_product_name')
  },

  applyUuid: {
    get: (): string | null => localStorage.getItem('apply_uuid'),
    set: (uuid: string) => localStorage.setItem('apply_uuid', uuid),
    remove: () => localStorage.removeItem('apply_uuid')
  },

  serviceStartDate: {
    get: (): Date | null => {
      try {
        return localStorage.getItem('service_start_date') && JSON.parse(localStorage.getItem('service_start_date')!);
      } catch (error) {
        return null;
      }
    },
    set: (serviceStartDate: Date) => localStorage.setItem('service_start_date', JSON.stringify(serviceStartDate)),
    remove: () => localStorage.removeItem('service_start_date')
  },

  scrollPosition: {
    get: (pathname: string): number => Number(localStorage.getItem(`scrollPosition_${pathname}`)),

    set: (pathname: string, value: number) => localStorage.setItem(`scrollPosition_${pathname}`, value.toString())
  },

  paginationIndex: {
    get: (pathname: string): number => Number(localStorage.getItem(`paginationIndex_${pathname}`)),

    set: (pathname: string, value: number) => localStorage.setItem(`paginationIndex_${pathname}`, value.toString())
  },

  pageSize: {
    get: (pathname: string): number => Number(localStorage.getItem(`pageSize_${pathname}`)),

    set: (pathname: string, value: number) => localStorage.setItem(`pageSize_${pathname}`, value.toString())
  },

  token: {
    get: (): string | null => localStorage.getItem('token'),
    set: (token: string) => localStorage.setItem('token', token),
    remove: () => localStorage.removeItem('token')
  },
  admissionToken: {
    get: (): string | null => localStorage.getItem('admissionToken'),
    set: (token: string) => localStorage.setItem('admissionToken', token),
    remove: () => localStorage.removeItem('admissionToken')
  },

  redirectURL: {
    set: (newRedirectURL: string | Location) => {
      if (typeof newRedirectURL === 'string') {
        localStorage.setItem('redirect_url', newRedirectURL);
        return;
      }

      if (typeof newRedirectURL === 'object') {
        localStorage.setItem('redirect_url', newRedirectURL.href);
        return;
      }
    },
    get: (): {
      url?: string;
      pathname?: string;
      search: string;
    } => {
      const redirectURL = localStorage.getItem('redirect_url') || '';
      const parsedURL = url.parse(redirectURL);

      return {
        url: parsedURL.href,
        pathname: parsedURL.pathname ?? undefined,
        search: parsedURL.search ?? ''
      };
    },
    remove: () => {
      localStorage.removeItem('redirect_url');
    }
  },
  otp: {
    isRequired: () => {
      const otpStatus = localStorage.getItem('otp_status');
      // ready => 시도 했으나 인증 x  => 해야함
      // clear => 시도 했고 clear => 할필요 없음
      // null => 한적 없음 => 할 필요 없음

      if (otpStatus === 'ready') {
        return true;
      }

      return false;
    },
    setReady: () => {
      localStorage.setItem('otp_status', 'ready');
    },
    setClear: () => {
      localStorage.setItem('otp_status', 'clear');
    }
  },
  timeStamp: {
    getTimeToVisit: (uuid: string): number | null => {
      return Number(localStorage.getItem(uuid));
    },
    setTimeToVisit: (uuid: string, time: string) => {
      localStorage.setItem(uuid, time);
    }
  },
  totalLearningTime: {
    get: (): string | null => localStorage.getItem('totalLearningTime'),
    set: (totalLearningTime: string) => localStorage.setItem('totalLearningTime', totalLearningTime),
    remove: () => localStorage.removeItem('totalLearningTime')
  },
  loginTime: {
    get: (): string | null => localStorage.getItem('loginTime'),
    set: (loginTime: string) => localStorage.setItem('loginTime', loginTime),
    remove: () => localStorage.removeItem('loginTime')
  },
  loginCount: {
    get: (): string | null => localStorage.getItem('loginCount'),
    set: (loginCount: string) => localStorage.setItem('loginCount', loginCount),
    remove: () => localStorage.removeItem('loginCount')
  },
  recentlyLoginedAt: {
    get: (): string | null => localStorage.getItem('recentlyLoginedAt'),
    set: (recentlyLoginedAt: string) => localStorage.setItem('recentlyLoginedAt', recentlyLoginedAt),
    remove: () => localStorage.removeItem('recentlyLoginedAt')
  }
};
