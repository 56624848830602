import React from 'react';
import styled from '@emotion/styled';

const EntryTitleWrapper = styled.div`
  display: block;
  margin: 10px 0;
  font-weight: 600;
  font-size: 16px;
  color: #444;
`;

const EntryTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <EntryTitleWrapper>{children}</EntryTitleWrapper>
);

export default EntryTitle;
