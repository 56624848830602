import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { SectionLabel } from '../atoms/Label';

import { storage } from '../../util/index';
import { Container } from '../../style';

import { useCredential } from '../../contexts/credential';

const LearningStatusWrapper = styled.div`
  margin-top: 50px;

  .contents-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .contents-item {
    display: inline-block;
    margin: 0px 10px;
  }

  .link {
    margin: 0px 20px;
  }
`;

const LearningStatus: React.FC = () => {
  const currentPlaylist = storage.education.getCurrentPlaylist();
  const currentCourseName = storage.education.getCurrentCourseName();

  const { profile, userCohort } = useCredential();

  return (
    <LearningStatusWrapper>
      <SectionLabel>Into your course</SectionLabel>
      <Container className="w3-container">
        {currentPlaylist ? (
          <>
            <div className="contents-item">
              {currentCourseName} / {currentPlaylist ? `${currentPlaylist.name}` : ''}
            </div>
            <div className="contents-item">
              <Link to={`/playlist/${currentPlaylist.id}`}>
                <span>학습하러 가기</span>
              </Link>
            </div>
          </>
        ) : (
          <div className="contents-item">현재 학습중인 콘텐츠가 없습니다.</div>
        )}
      </Container>
      {profile?.hasCohort && userCohort ? (
        <>
          <SectionLabel>수강 중인 과정 ({profile?.name}님)</SectionLabel>
          <Container className="w3-container">
            <div className="contents-item-container">
              <div className="contents-item">
                {profile?.cohortName} ({String(userCohort.certEduStartDate)?.slice(0, 10)} ~{' '}
                {String(userCohort.certEduEndDate)?.slice(0, 10)})
                <Link className="link" to={`/courses/active`}>
                  <span>수강페이지 가기</span>
                </Link>
              </div>
              <div className="contents-item">최근 수강일: {profile?.lastEnrolledAt}</div>
            </div>
          </Container>
        </>
      ) : null}
    </LearningStatusWrapper>
  );
};

export default LearningStatus;
