import gql from 'graphql-tag';

export const MYPAGE_GQL = gql`
  query {
    urclassMyCohorts {
      id
      uuid
      name
      cohortType
      startDate
      endDate
      certIssuable
      certEduStartDate
      certEduEndDate
      certCourseName
      certCourseDesc
      useAttendanceCertificationFeature
      courses {
        id
        uuid
        name
        description
      }
    }
    urclassMyPaymentOrders {
      id
      orderName
      totalAmount
      status
      paidAt
      payMethod
    }

    urclassMyApplications {
      id
      uuid
      applyingStatus
      evaluationStatus
      intentionStatus
      boardingStatus
      confirmedAt
      paymentType
      admissionApplierToken
      needToPay
      product {
        id
        name
        programId
        serviceStartDate
        serviceEndDate
        weekDuration
        applyStartDate
        applyEndDate
        applyServiceType
        applyServiceUuid
        announcementDate
      }

      userApplicationSteps {
        id
        title
        typeformId
        type
        order
      }

      submissions {
        id
        uuid
        body
        applicationStepId
        createdAt
      }
    }
  }
`;

export const MY_APPLICATIONS_GQL = gql`
  query {
    urclassMyApplications {
      id
      applyingStatus
      evaluationStatus
      intentionStatus
      boardingStatus
    }
  }
`;

export const MY_APPLICATION_GQL = gql`
  query myApplication($uuid: String!) {
    urclassMyApplication(uuid: $uuid) {
      id
      product {
        id
        name
        programId
      }
      admissionApplierToken
    }
  }
`;

export const MY_APPLICATION_DETAIL_GQL = gql`
  query myApplication($uuid: String!) {
    urclassMyApplication(uuid: $uuid) {
      id
      uuid
      admissionApplierToken
      needToPay
      product {
        id
        uuid
        name
        applyEndDate
        applyServiceType
        applyServiceUuid
        programId
      }
      userApplicationSteps {
        id
        title
        typeformId
      }
      submissions {
        id
        uuid
        body
        applicationStepId
        createdAt
      }
    }
  }
`;

export const GET_ADMISSION_APPLICATION_GQL = gql`
  query Application($uuid: String!) {
    application(uuid: $uuid) {
      applyingStatus
      surveySet {
        slugUrl
        title
        surveys {
          sequence
          survey {
            title
            definition
            uuid
            displayTitle
          }
        }
      }
      submissions {
        uuid
        submissionStatus
        submittedResponse
        survey {
          title
        }
      }
    }
  }
`;

export const GET_ADMISSION_SURVEY_GQL = gql`
  query Query($uuid: String!) {
    survey(uuid: $uuid) {
      definition
    }
  }
`;

export const CREATE_SURVEY_SUBMISSION_GQL = gql`
  mutation Mutation($input: NewSurveySubmissionInput!) {
    createSurveySubmission(input: $input) {
      id
    }
  }
`;

export const UPDATE_SURVEY_SUBMISSION_GQL = gql`
  mutation UpdateSurveySubmission($input: UpdateSurveySubmissionInput!, $uuid: String!) {
    updateSurveySubmission(input: $input, uuid: $uuid) {
      id
    }
  }
`;

export const UPDATE_APPLICATION_GQL = gql`
  mutation UpdateApplication($applicationUuid: String!, $input: UpdateApplicationInput!) {
    updateApplication(applicationUuid: $applicationUuid, input: $input) {
      uuid
    }
  }
`;

export const CREATE_SURVEY_SUBMISSION_UPLOAD_URL = gql`
  mutation Mutation($applicationUuid: String!, $fileName: String!, $surveySubmissionUuid: String!) {
    createSurveySubmissionUploadUrl(
      applicationUuid: $applicationUuid
      fileName: $fileName
      surveySubmissionUuid: $surveySubmissionUuid
    )
  }
`;
