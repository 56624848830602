import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';

import { MyProfileData, UserProfile } from '../apollo/account/type';
import { MY_PROFILE_GQL } from '../apollo/account/query';
import { UserCohort, UserCohortData } from '../apollo/userCohort/type';
import { USER_COHORTS_GQL } from '../apollo/userCohort/query';
import { LOG_OUT_GQL } from '../apollo/account/mutation';

import { store } from '../util';
import TagManager from 'react-gtm-module';
import { makeToast } from '../util/makeToast';

export type Credential = {
  isLogin: boolean;
  hasCohort: boolean;
  profile: UserProfile | undefined;
  loadingProfile: boolean;
  refetchProfile: () => void;
  login: (token: string, isFirstLogin?: boolean) => void;
  logout: () => void;
  checkout: () => Promise<void>;
  isRequiredTermsAgreements: boolean;
  isAttendanceLoginPopupOpen: boolean;
  handleAttendanceLoginPopup: (isAttendanceLoginPopupOpen: boolean) => void;
  userCohort: UserCohort;
};
const CredentialContext = React.createContext<Credential>({
  isLogin: false,
  isAttendanceLoginPopupOpen: false,
  hasCohort: false,
  profile: undefined,
  loadingProfile: false,
  refetchProfile: () => {},
  login: (token: string, isFirstLogin?: boolean) => {},
  logout: () => {},
  checkout: async () => {},
  isRequiredTermsAgreements: true,
  handleAttendanceLoginPopup: (isAttendanceLoginPopupOpen: boolean) => {},
  userCohort: { id: 0, uuid: '', name: '', certEduStartDate: '', certEduEndDate: '' }
});

export const CredentialConsumer = CredentialContext.Consumer;

export const useCredential = () => {
  return useContext(CredentialContext);
};

export const CredentialProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const history = useHistory();
  const [isLogin, setIsLogin] = useState<boolean>(!!(store.token.get() || undefined));
  const [hasCohort, setHasCohort] = useState<boolean>(false);
  const [isRequiredTermsAgreements, setIsRequiredTermsAgreements] = useState(true);
  const [profile, setProfile] = useState<UserProfile | undefined>();
  const [isAttendanceLoginPopupOpen, setIsAttendanceLoginPopupOpen] = useState<boolean>(false);
  const [userCohort, setUserCohort] = useState<any>();
  const [getMyProfile, { data, loading, error }] = useLazyQuery<MyProfileData>(MY_PROFILE_GQL, {
    fetchPolicy: 'network-only'
  });
  const [urclassLogOut, { loading: urclassLogOutLoading }] = useMutation(LOG_OUT_GQL, {
    onCompleted: () => {
      store.loginCount.remove();
      store.recentlyLoginedAt.remove();
    }
  });
  const [getCohorts, { data: userCohorts }] = useLazyQuery<UserCohortData>(USER_COHORTS_GQL, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (profile) {
      // 현재 진행 중인 개강반만
      if (userCohorts?.urclassCohortsOfUser) {
        const currentCohort = userCohorts?.urclassCohortsOfUser.filter(cohort => {
          const start = new Date(cohort.certEduStartDate);
          const end = new Date(cohort.certEduEndDate);
          const now = new Date();
          return now.getTime() > start.getTime() && now.getTime() < end.getTime();
        });
        setUserCohort(currentCohort[0]);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (!profile) return;
    else {
      TagManager.dataLayer({
        dataLayer: {
          userId: profile.uuid
        }
      });
    }
  }, [profile]);

  useEffect(() => {
    if (isLogin) {
      getMyProfile();
    }

    const logoutHandler = () => {
      logout();
    };
    window.addEventListener('logout', logoutHandler);

    return () => {
      window.removeEventListener('logout', logoutHandler);
    };
  }, []);

  useEffect(() => {
    if (data && data.urclassMe) {
      const profile = data.urclassMe;
      setProfile(profile);
      setIsRequiredTermsAgreements(profile.isRequiredTermsAgreements);
      setHasCohort(data.urclassMe.hasCohort);

      getCohorts();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      logout();

      if (error.message.includes('token-invalid')) {
        makeToast({ content: '로그아웃 되었습니다. 다시 로그인해 주세요.' });
      }
    }
  }, [error]);

  const login = (token: string) => {
    store.token.set(token);
    setIsLogin(true);
    setIsAttendanceLoginPopupOpen(true);
    getMyProfile();
  };

  const checkout = async () => {
    try {
      if (!urclassLogOutLoading) {
        await urclassLogOut();
      }
    } catch {
      // token에 문제 있을때 로그아웃 하는 경우
      history.push('/login');
      makeToast({ type: 'error', content: '토큰이 만료 되었습니다.' });
    }
  };

  const logout = async () => {
    store.token.remove();
    store.admissionToken.remove();
    setIsLogin(false);
    setProfile(undefined);
  };

  const refetchProfile = () => {
    getMyProfile();
  };

  const handleAttendanceLoginPopup = (isAttendanceLoginPopupOpen: boolean) => {
    setIsAttendanceLoginPopupOpen(isAttendanceLoginPopupOpen);
  };

  return (
    <CredentialContext.Provider
      value={{
        isLogin,
        hasCohort,
        profile,
        refetchProfile,
        login,
        logout,
        checkout,
        isRequiredTermsAgreements,
        isAttendanceLoginPopupOpen,
        handleAttendanceLoginPopup,
        loadingProfile: loading,
        userCohort
      }}
    >
      {children}
    </CredentialContext.Provider>
  );
};
