import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';

import { Typography, TypographyType } from '../../../components/atoms/Typography';
import { Button } from '../../../components/molecules/Button';
import { SizeType, StatusType } from '../../../components/molecules/Button/type';
import { ColorPalette, ColorPaletteType } from '../../../styles/color';

const B2BApplyFormWrapper = styled.div`
  margin: auto;
  margin-top: 80px;

  width: 310px;

  padding: 47px 22px 22px;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${ColorPalette.Gray4};
  background-color: ${ColorPalette.White};

  .b2b-apply-form__title {
    text-align: center;

    word-break: keep-all;

    .b2b-apply-form__title-product-name {
      line-height: 1.3;
      font-weight: 700;

      margin-bottom: 8px;
    }

    .b2b-apply-form__title-service-date {
      margin-bottom: 28px;
    }
  }

  .b2b-apply-form__body {
    hr {
      margin: 13.5px 0px 13.5px 0px;
      color: ${ColorPalette.Gray4};
    }

    .b2b-info__container {
      margin: 36px 0px 36px 0px;

      .b2b-info__text {
        margin: 0px 10px 0px 12px;
        line-height: 24px;
      }

      .emphasis {
        font-weight: 700;
        white-space: pre-line;
      }

      /* https://stackoverflow.com/questions/1409649/how-to-change-the-height-of-a-br  */

      br {
        display: block;
        content: '';
        margin-top: 10px;
      }
    }
  }

  .b2b-apply-form__submit {
    margin-top: 3px;
  }
`;

type B2BApplyFormProps = {
  loading: boolean;
  productName: string;
  productDescription: string;
  hideDate?: boolean;
  isCDSProgram?: boolean;
  productServiceStartDate: Date;
  productServiceEndDate: Date;
  handlePaymentFormSubmit: () => void;
};

const B2BApplyForm: React.FC<B2BApplyFormProps> = ({
  loading,
  productName,
  productDescription,
  productServiceStartDate,
  productServiceEndDate,
  handlePaymentFormSubmit,
  isCDSProgram,
  hideDate = false
}) => (
  <B2BApplyFormWrapper>
    <div className='b2b-apply-form__title'>
      <Typography
        className='b2b-apply-form__title-product-name'
        type={TypographyType.H3}
        color={ColorPaletteType.GRAY1}
        text={productName}
      />
      {!hideDate && (
        <Typography
          className='b2b-apply-form__title-service-date'
          type={TypographyType.B1}
          color={ColorPaletteType.GRAY2}
          text={`${moment(productServiceStartDate).format('YYYY.MM.DD')} ~ ${moment(productServiceEndDate).format(
            'YYYY.MM.DD'
          )}`}
        />
      )}
    </div>

    <div className='b2b-apply-form__body'>
      <hr />

      <div className='b2b-info__container'>
        <Typography
          className='b2b-info__text emphasis'
          type={TypographyType.B1}
          color={ColorPaletteType.GRAY1}
          text={productDescription}
        />
      </div>

      <hr />
      <Button
        className='b2b-apply-form__submit ga-urclass-apply-btn'
        status={StatusType.ACTIVE}
        size={SizeType.BASIC}
        disabled={loading}
        children={
          <Typography
            text={isCDSProgram ? '진행하기' : '지원하기'}
            type={TypographyType.B1}
            color={ColorPaletteType.WHITE}
          />
        }
        onClick={handlePaymentFormSubmit}
      />
    </div>
  </B2BApplyFormWrapper>
);
export default B2BApplyForm;
