import { ApplyServiceType } from './../../apollo/mypage/type';
import { makeToast } from './../../util/makeToast';
import { useQuery } from '@tanstack/react-query';
import { useQuery as apolloUseQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { sendPageView } from '../../util/marketingTools/googleTagAssistant';
import { MyApplicationData, MyApplicationVars } from '../../apollo/mypage/type';

import { MY_APPLICATION_DETAIL_GQL } from '../../apollo/mypage/query';
import { getSlugUrlRequest } from './request';
import queryKeys from '../../query/queryKeys';

export const useController = () => {
  const history = useHistory();
  const location = useLocation<{ applicationUuid: string }>();
  const params = useParams<{ uuid: string }>();
  const applicationUuid = location?.state?.applicationUuid || '';
  const { uuid: productUuid } = params;

  const [productName, setProductName] = useState<string | undefined>('');

  const {
    data: cozApplicationData,
    loading: cozApplicationLoading,
    error: cozApplicationError
  } = apolloUseQuery<MyApplicationData, MyApplicationVars>(MY_APPLICATION_DETAIL_GQL, {
    variables: { uuid: applicationUuid },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setProductName(cozApplicationData?.urclassMyApplication.product.name);
    }
  });

  const getSlugUrlByUuidParams = {
    productUuid
  };
  const { data: slugUrlData } = useQuery(
    queryKeys.APPLY_PRODUCT_SLUG_URL_BY_UUID(getSlugUrlByUuidParams),
    () => getSlugUrlRequest(getSlugUrlByUuidParams),
    {
      enabled: cozApplicationData?.urclassMyApplication.product.applyServiceType === ApplyServiceType.ADMISSION
    }
  );

  useEffect(() => {
    if (!productName) return;
    sendPageView(`apply/result-b2b/${productName}`);
  }, [productName]);

  // handler
  const handleApplyClick = () => {
    const applyServiceType: undefined | ApplyServiceType.TYPEFORM | ApplyServiceType.ADMISSION =
      cozApplicationData?.urclassMyApplication.product.applyServiceType;

    if (applyServiceType === ApplyServiceType.TYPEFORM) {
      history.push({
        pathname: `/applications/form/${applicationUuid}/list`,
        state: { uuid: applicationUuid }
      });
    } else if (applyServiceType === ApplyServiceType.ADMISSION) {
      if (applicationUuid && slugUrlData) {
        history.push(`/applications/${slugUrlData.slugUrl}/list/${applicationUuid}`);
      } else {
        makeToast({ type: 'error', content: '오류가 발생했습니다. 마이페이지에서 지원해주세요.' });
        history.push('/mypage');
      }
    } else {
      makeToast({ type: 'error', content: '질문지 타입 오류, 관리자에게 문의' });
      history.push('/mypage');
    }
  };

  return {
    cozApplicationLoading,
    handleApplyClick
  };
};
