const APPLICATION_CARD = 'application-card';
const APPLY_PRODUCT_SLUG_URL = 'slug-url';
const APPLICATION = 'application';
const DETAIL = 'detail';
const REVIVE = 'revive';
const CANCELLED = 'cancelled';
const queryKeys = {
  //slug url
  APPLY_PRODUCT_SLUG_URL_BY_UUID: ({ productUuid }: { productUuid: string }) =>
    [APPLY_PRODUCT_SLUG_URL, { productUuid }] as const,
  //TODO: 아래 두 쿼리 키 하나의 이름으로 통일
  //application card
  APPLICATION_CARD_BY_UUID: ({ applicationUuid, userUuid }: { applicationUuid: string; userUuid: string }) =>
    [APPLICATION_CARD, { applicationUuid, userUuid }] as const,

  APPLICATION_DETAIL_BY_UUID: ({ userUuid, applicationUuid }: { userUuid: string; applicationUuid: string }) =>
    [APPLICATION, DETAIL, { userUuid, applicationUuid }] as const,

  //revive application
  CANCELLED_APPLICATION_BY_PRODUCT_UUID: ({ userUuid, productUuid }: { userUuid: string; productUuid: string }) =>
    [APPLICATION, CANCELLED, { userUuid, productUuid }] as const
};

export default queryKeys;
