import { Page, View, Text, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import Signature from '../../../assets/image/signature.png';
import CodestatesLogo from '../../../assets/image/logo-codestates.png';
import KdtLogo from '../../../assets/image/logo-kdt.png';
import { makeCalendars } from '../../../util/makeCalender';
import { attendanceCertificatePdfProps, AttendanceResultType, CertificateType } from '../../../apollo/certificate/type';
import moment from 'moment';
/* For PDF */
Font.register({
  family: 'Nanum Gothic',
  fonts: [
    { src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Bold.ttf' },
    { src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Bold.ttf', fontWeight: 'light' }
  ]
});
const styles = StyleSheet.create({
  page: {
    padding: '20px'
  },
  title: {
    marginTop: '40px',
    fontFamily: 'Nanum Gothic',
    fontSize: '20px',
    color: '#262F45',
    textAlign: 'center'
  },
  noti: {
    marginTop: '10px'
  },
  notiList: {
    margin: '2px 0'
  },
  notiSubList: {
    margin: '1px 5px'
  },
  logoLeft: {
    position: 'absolute',
    left: '20px',
    top: '20px',
    width: '100px',
    height: '20px'
  },
  logoRight: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: '80px',
    height: '20px'
  },
  section: {
    margin: '20px 0 10px',
    padding: '30px 0',
    borderTop: '1px solid #B2BDD1',
    borderBottom: '1px solid #B2BDD1'
  },
  tableOrganization: {
    width: '100%',
    border: '1px solid #B2BDD1'
  },
  tablePersonal: {
    width: '100%',
    margin: '10px 0',
    border: '1px solid #B2BDD1'
  },
  tableCalendar: {
    flexDirection: 'row',
    width: '100%',
    margin: '10px 0',
    position: 'relative',
    border: '1px solid #B2BDD1'
  },
  tableText: {
    fontSize: 8,
    color: '#262F45',
    fontFamily: 'Nanum Gothic'
  },
  tableDataText: {
    fontSize: 8,
    color: '#262F45',
    fontWeight: 'light',
    fontFamily: 'Nanum Gothic'
  },
  tableCalendarMonthText: {
    fontSize: 7,
    color: '#262F45',
    fontFamily: 'Nanum Gothic',
    textAlign: 'center'
  },
  tableCalendarText: {
    fontSize: 5,
    color: '#262F45',
    fontFamily: 'Nanum Gothic',
    fontWeight: 'light',
    textAlign: 'center'
  },
  tableCalendarDateText: {
    fontSize: 5,
    fontFamily: 'Nanum Gothic',
    color: '#262F45',
    fontWeight: 'light',
    textAlign: 'center'
  },
  tableCalendarSaturdayText: {
    fontSize: 5,
    fontFamily: 'Nanum Gothic',
    color: '#295CE0',
    fontWeight: 'light',
    textAlign: 'center'
  },
  tableCalendarHolidayText: {
    fontSize: 5,
    fontFamily: 'Nanum Gothic',
    color: '#FF360A',
    fontWeight: 'light',
    textAlign: 'center'
  },
  tableCalendarTimeText: {
    fontSize: 4,
    fontFamily: 'Nanum Gothic',
    color: '#262F45',
    fontWeight: 'light',
    textAlign: 'center'
  },
  tableCalendarPresenceText: {
    fontSize: 8,
    fontFamily: 'Nanum Gothic',
    color: '#00B86A',
    fontWeight: 'light',
    textAlign: 'center'
  },
  tableCalendarAbsenceText: {
    fontSize: 8,
    fontFamily: 'Nanum Gothic',
    color: '#FF360A',
    fontWeight: 'light',
    textAlign: 'center'
  },
  tableCalendarLeaveText: {
    fontSize: 8,
    fontFamily: 'Nanum Gothic',
    color: '#ff6e0a',
    fontWeight: 'light',
    textAlign: 'center'
  },
  tableCalendarOfficialLeaveText: {
    fontSize: 8,
    fontFamily: 'Nanum Gothic',
    color: '#00B86A',
    fontWeight: 'light',
    textAlign: 'center'
  },
  tableCalendarNotReflectedText: {
    fontSize: 8,
    fontFamily: 'Nanum Gothic',
    color: '#262F45',
    fontWeight: 'light',
    textAlign: 'center'
  },
  note: {
    fontSize: 6,
    color: '#525A67',
    fontFamily: 'Nanum Gothic',
    marginTop: 0
  },
  notiTextTitle: {
    fontSize: 10,
    color: '#262F45',
    fontFamily: 'Nanum Gothic'
  },
  notiText: {
    fontSize: 8,
    fontWeight: 'light',
    color: '#262F45',
    fontFamily: 'Nanum Gothic'
  },
  tableFirstRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    borderTop: '1px solid #B2BDD1'
  },
  tableCalendarRow: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#eceff4',
    borderBottom: '1px solid #B2BDD1',
    width: '100%'
  },
  tableCalendarLastRow: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#eceff4',
    width: '100%'
  },
  tableIndex: {
    width: '15%',
    padding: '4px',
    backgroundColor: '#ECEFF4',
    fontFamily: 'Nanum Gothic',
    textAlign: 'center',
    borderRight: '1px solid #B2BDD1'
  },
  tableIndexRight: {
    width: '15%',
    padding: '4px',
    backgroundColor: '#ECEFF4',
    fontFamily: 'Nanum Gothic',
    textAlign: 'center',
    borderRight: '1px solid #B2BDD1',
    borderLeft: '1px solid #B2BDD1'
  },
  tableData: {
    width: '85%',
    padding: '4px',
    backgroundColor: '#fff',
    fontFamily: 'Nanum Gothic'
  },
  tableDataShort: {
    width: '35%',
    padding: '4px',
    fontFamily: 'Nanum Gothic'
  },
  tableCalendarMonthCell: {
    width: '15%',
    fontFamily: 'Nanum Gothic',
    paddingTop: '32px',
    backgroundColor: '#ECEFF4',
    borderRight: '1px solid #B2BDD1'
  },
  tableCalendarHRDMonthCell: {
    width: '15%',
    fontFamily: 'Nanum Gothic',
    paddingTop: '20px',
    backgroundColor: '#ECEFF4',
    borderRight: '1px solid #B2BDD1'
  },
  tableCalendarIndex: {
    width: '20px',
    fontFamily: 'Nanum Gothic',
    padding: '4px 0'
  },
  tableCalendarCell: {
    width: '15px',
    fontFamily: 'Nanum Gothic',
    padding: '4px 0',
    borderLeft: '1px solid #B2BDD1'
  },
  tableCalendarInvalidCell: {
    width: '15px',
    fontFamily: 'Nanum Gothic',
    padding: '4px 0',
    borderLeft: '1px solid #B2BDD1',
    backgroundColor: '#e9ecf1'
  },
  tableCalendarDataCell: {
    width: '15px',
    fontFamily: 'Nanum Gothic',
    padding: '4px 0',
    backgroundColor: '#fff',
    borderLeft: '1px solid #B2BDD1'
  },
  tableCalendarDatInvalidCell: {
    width: '15px',
    fontFamily: 'Nanum Gothic',
    padding: '4px 0',
    backgroundColor: '#e9ecf1',
    borderLeft: '1px solid #B2BDD1'
  },
  tableCalendarDataTimeCell: {
    width: '15px',
    fontFamily: 'Nanum Gothic',
    padding: '4px 0',
    backgroundColor: '#fff',
    borderLeft: '1px solid #B2BDD1'
  },
  tableCalendarDataTimeInvalidCell: {
    width: '15px',
    fontFamily: 'Nanum Gothic',
    padding: '4px 0',
    backgroundColor: '#e9ecf1',
    borderLeft: '1px solid #B2BDD1'
  },
  signature: {
    textAlign: 'center',
    width: '300px',
    margin: 'auto'
  },
  signatureSign: {
    position: 'relative',
    zIndex: 1
  },
  signatureDateText: {
    color: '#56585e',
    fontSize: 10,
    marginTop: '5px',
    marginBottom: '4px',
    fontFamily: 'Nanum Gothic'
  },
  signatureSignText: {
    position: 'relative',
    fontFamily: 'Nanum Gothic',
    fontSize: 12
  },
  signatureSignImg: {
    position: 'absolute',
    top: '-45px',
    right: '-5px',
    width: '100px',
    height: '100px',
    zIndex: 999
  }
});

const ATTENDANCE_CHECK_MESSAGE = {
  URCLASS: {
    TITLE: 'Urclass 출석확인서 안내문구',
    CONTENT: [
      '1. 출석상태 표시 설명( 출석:[○] 조퇴:[▲] 결석:[×] )',
      '2. 출석입력요청 출결사항(공가)이 미반영된 출석부입니다.',
      '3. 입실/퇴실 시간은 각각 최초 입실/최종 퇴실 시간에 해당합니다.(수강생의 입퇴실 이력에 따라 출석결과와 차이를 보일 수 있습니다.)',
      '4. 코드스테이츠 학습플랫폼 ‘UrClass’에 기록된 학습시간을 기준으로 출석상태를 표시하며 집체교육은 HRD-net QR코드로 출결을 관리하므로 사실과 다를 수 있습니다.',
      '(28,800초 이상: 출석 / 28,800초 미만 14,400초 이상: 조퇴 / 14,400초 미만: 결석)',
      '5. 출석률 계산시 조퇴 3회는 결석 1회로 반영되며, 3회 누적 전까지는 출석으로 반영됩니다.',
      '6. 해당 출석부를 제출 기관에서 인정하지 않는 경우 코드스테이츠(impact.kdt@codestates.com)에 문의해주시기 바랍니다.'
    ]
  },
  HRD_NET: {
    TITLE: 'HRD-Net 출석확인서 안내문구',
    CONTENT: [
      '1. 출석상태 표시 설명(출석:[○], 지각, 조퇴, 외출:[▲] 결석:[×] 공가: [▦] 미반영: [-])',
      '2. 위 출석부는 HRD-Net에 등록된 수강생의 출석결과를 기준으로 합니다.',
      '3. 출석률 계산시 조퇴 3회는 결석 1회로 반영되고, 3회 누적 전까지는 출석으로 반영됩니다.',
      '4. 출석률 계산시 공가는 출석으로 반영됩니다.',
      '5. QR을 통한 출석만 입퇴실 시간을 함께 확인할 수 있습니다.',
      '6. 당일의 출석은 입실/ 퇴실 시간만 제공됩니다. (출석 결과, 출석률에 반영되지 않습니다.)',
      '7. 해당 출석부를 제출 기관에서 인정하지 않는 경우 코드스테이츠(impact.kdt@codestates.com)에 문의해주시기 바랍니다.'
    ]
  }
} as const;

export const PDFDocument = (certificateData: attendanceCertificatePdfProps) => {
  const issuedDate = moment().format('YYYY.M.D');
  const issuedMinuite = new Date().getMinutes() < 9 ? '0' + new Date().getMinutes() : new Date().getMinutes();
  const issuedDateforHRD =
    new Date().getFullYear() +
    '년 ' +
    (new Date().getMonth() + 1) +
    '월 ' +
    new Date().getDate() +
    '일 ' +
    new Date().getHours() +
    ':' +
    issuedMinuite;
  const calendars = makeCalendars(certificateData.trainStartAt, certificateData.trainEndAt);
  const convertToAttendanceFigure = (attendanceType: AttendanceResultType) => {
    switch (attendanceType) {
      case AttendanceResultType.ABSENCE:
        return <Text style={styles.tableCalendarAbsenceText}>x</Text>;
      case AttendanceResultType.LEAVE_EARLY:
        return <Text style={styles.tableCalendarLeaveText}>▵</Text>;
      case AttendanceResultType.OFFICIAL_LEAVE:
        return <Text style={styles.tableCalendarOfficialLeaveText}>■</Text>;
      case AttendanceResultType.PRESENCE:
        return <Text style={styles.tableCalendarPresenceText}>o</Text>;
      case AttendanceResultType.NOT_REFLECTED:
        return <Text style={styles.tableCalendarNotReflectedText}>-</Text>;
    }
  };
  const convertToCertificateType = (type: string) => {
    switch (type) {
      case 'urclass':
        return CertificateType.URCLASS;
      case 'hrd-net':
        return CertificateType.HRD_NET;
    }
  };
  return (
    <Document>
      <Page style={styles.page} size="A4">
        <Image style={styles.logoLeft} src={KdtLogo} />
        <Image style={styles.logoRight} src={CodestatesLogo} />
        <Text style={styles.title}> 출석확인서</Text>
        <View style={styles.section}>
          <View style={styles.tableOrganization}>
            <View style={styles.tableFirstRow}>
              <View style={styles.tableIndex}>
                <Text style={styles.tableText}>훈련기관명</Text>
              </View>
              <View style={styles.tableData}>
                <Text style={styles.tableDataText}>
                  {certificateData.certificateOrganizationInfo.trainingOrganizationName}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableIndex}>
                <Text style={styles.tableText}>소재지</Text>
              </View>
              <View style={styles.tableData}>
                <Text style={styles.tableDataText}>{certificateData.certificateOrganizationInfo.address}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableIndex}>
                <Text style={styles.tableText}>훈련과정명</Text>
              </View>
              <View style={styles.tableData}>
                <Text style={styles.tableDataText}>{certificateData.trainingProgramName}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableIndex}>
                <Text style={styles.tableText}>훈련기관유형</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text style={styles.tableDataText}>
                  {certificateData.certificateOrganizationInfo.trainingOrganizationCategory}
                </Text>
              </View>
              <View style={styles.tableIndexRight}>
                <Text style={styles.tableText}>관할관서</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text style={styles.tableDataText}>{certificateData.govermentOfficeName}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableIndex}>
                <Text style={styles.tableText}>NCS(코드)</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text style={styles.tableDataText}>{certificateData.ncsCode}</Text>
              </View>
              <View style={styles.tableIndexRight}>
                <Text style={styles.tableText}>인정유효기간</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text style={styles.tableDataText}>{certificateData.validDuration}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableIndex}>
                <Text style={styles.tableText}>정원</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text style={styles.tableDataText}>{certificateData.personnel}</Text>
              </View>
              <View style={styles.tableIndexRight}>
                <Text style={styles.tableText}>훈련시간 / 일수</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text style={styles.tableDataText}>{certificateData.totalTrainingTimeAndDay}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableIndex}>
                <Text style={styles.tableText}>주훈련대상</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text style={styles.tableDataText}>{certificateData.mainTrainingTarget}</Text>
              </View>
              <View style={styles.tableIndexRight}>
                <Text style={styles.tableText}>훈련기간 / 회차</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text style={styles.tableDataText}>{certificateData.trainingIntervalAnd}</Text>
              </View>
            </View>
          </View>
          <View style={styles.tablePersonal}>
            <View style={styles.tableFirstRow}>
              <View style={styles.tableIndex}>
                <Text style={styles.tableText}>성명</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text style={styles.tableDataText}>{certificateData.name}</Text>
              </View>
              <View style={styles.tableIndexRight}>
                <Text style={styles.tableText}>주민등록번호</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text style={styles.tableDataText}>{`${certificateData.birthdayAndGender.slice(
                  0,
                  -1
                )}-${certificateData.birthdayAndGender.slice(-1)}******`}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableIndex}>
                <Text style={styles.tableText}>훈련기간</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text style={styles.tableDataText}>{`${moment(certificateData.trainStartAt).format(
                  'YYYY-MM-DD'
                )} ~ ${moment(certificateData.trainEndAt).format('YYYY-MM-DD')}`}</Text>
              </View>
              <View style={styles.tableIndexRight}>
                <Text style={styles.tableText}>훈련시간</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text style={styles.tableDataText}>{certificateData.trainingTime}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableIndex}>
                <Text style={styles.tableText}>출석결과</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text
                  style={styles.tableDataText}
                >{`출석 ${certificateData.presenceCount} / 결석 ${certificateData.absenceCount} / 지각, 조퇴, 외출 ${certificateData.leaveEarlyCount} / 공가 ${certificateData.officialLeaveCount}`}</Text>
              </View>
              <View style={styles.tableIndexRight}>
                <Text style={styles.tableText}>출석률</Text>
              </View>
              <View style={styles.tableDataShort}>
                <Text style={styles.tableDataText}>{certificateData.presenceRatio}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableIndex}>
                <Text style={styles.tableText}>발급일자</Text>
              </View>
              <View style={styles.tableData}>
                <Text style={styles.tableDataText}>{issuedDateforHRD}</Text>
              </View>
            </View>
          </View>
          <View>
            <Text style={styles.note}>* 당일의 출석은 출석 결과와 출석률에 반영되지 않습니다.</Text>
          </View>
          {calendars.map(calendar => {
            return (
              <View style={styles.tableCalendar}>
                <View style={styles.tableCalendarMonthCell}>
                  <Text style={styles.tableCalendarMonthText}>{`${calendar.year}년 ${calendar.month + 1}월`}</Text>
                </View>
                <View>
                  <View style={styles.tableCalendarRow}>
                    <View style={styles.tableCalendarIndex}>
                      <Text style={styles.tableCalendarText}>날짜</Text>
                    </View>
                    {calendar.days.map((data, idx) => {
                      return (
                        <View
                          style={data.isValidDay ? styles.tableCalendarCell : styles.tableCalendarInvalidCell}
                          key={`date${idx}`}
                        >
                          <Text style={styles.tableCalendarText}>{data.date}</Text>
                        </View>
                      );
                    })}
                  </View>
                  <View style={styles.tableCalendarRow}>
                    <View style={styles.tableCalendarIndex}>
                      <Text style={styles.tableCalendarText}>요일</Text>
                    </View>
                    {calendar.days.map((data, idx) => {
                      return (
                        <View
                          style={data.isValidDay ? styles.tableCalendarCell : styles.tableCalendarInvalidCell}
                          key={`day${idx}`}
                        >
                          <Text
                            style={
                              data.sunday ||
                              certificateData.holidays.includes(
                                `${calendar.year}-${
                                  calendar.month + 1 < 10 ? `0${calendar.month + 1}` : calendar.month + 1
                                }-${idx + 1 < 10 ? `0${idx + 1}` : idx + 1}`
                              )
                                ? styles.tableCalendarHolidayText
                                : data.saturday
                                ? styles.tableCalendarSaturdayText
                                : styles.tableCalendarDateText
                            }
                          >
                            {data.day}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                  <View style={styles.tableCalendarRow}>
                    <View style={styles.tableCalendarIndex}>
                      <Text style={styles.tableCalendarText}>입실</Text>
                    </View>
                    {calendar.days.map((data, idx) => {
                      return (
                        <View
                          style={
                            data.isValidDay ? styles.tableCalendarDataTimeCell : styles.tableCalendarDataTimeInvalidCell
                          }
                          key={`checkinTime${idx}`}
                        >
                          <Text style={styles.tableCalendarTimeText}>
                            {data.sunday ||
                            data.saturday ||
                            !data.isValidDay ||
                            certificateData.certificateRecords.filter(record => {
                              return (
                                record.date ===
                                `${calendar.year}-${
                                  calendar.month + 1 < 10 ? `0${calendar.month + 1}` : calendar.month + 1
                                }-${idx + 1 < 10 ? `0${idx + 1}` : idx + 1}`
                              );
                            }).length === 0
                              ? ''
                              : certificateData.certificateRecords.filter(record => {
                                  return (
                                    record.date ===
                                    `${calendar.year}-${
                                      calendar.month + 1 < 10 ? `0${calendar.month + 1}` : calendar.month + 1
                                    }-${idx + 1 < 10 ? `0${idx + 1}` : idx + 1}`
                                  );
                                })[0].checkInTime.length === 0
                              ? '-'
                              : certificateData.certificateRecords.filter(record => {
                                  return (
                                    record.date ===
                                    `${calendar.year}-${
                                      calendar.month + 1 < 10 ? `0${calendar.month + 1}` : calendar.month + 1
                                    }-${idx + 1 < 10 ? `0${idx + 1}` : idx + 1}`
                                  );
                                })[0].checkInTime}
                          </Text>
                        </View>
                      );
                    })}
                  </View>

                  <View style={styles.tableCalendarRow}>
                    <View style={styles.tableCalendarIndex}>
                      <Text style={styles.tableCalendarText}>퇴실</Text>
                    </View>
                    {calendar.days.map((data, idx) => {
                      return (
                        <View
                          style={
                            data.isValidDay ? styles.tableCalendarDataTimeCell : styles.tableCalendarDataTimeInvalidCell
                          }
                          key={`checkOutTime${idx}`}
                        >
                          <Text style={styles.tableCalendarTimeText}>
                            {data.sunday ||
                            data.saturday ||
                            !data.isValidDay ||
                            certificateData.certificateRecords.filter(record => {
                              return (
                                record.date ===
                                `${calendar.year}-${
                                  calendar.month + 1 < 10 ? `0${calendar.month + 1}` : calendar.month + 1
                                }-${idx + 1 < 10 ? `0${idx + 1}` : idx + 1}`
                              );
                            }).length === 0
                              ? ''
                              : certificateData.certificateRecords.filter(record => {
                                  return (
                                    record.date ===
                                    `${calendar.year}-${
                                      calendar.month + 1 < 10 ? `0${calendar.month + 1}` : calendar.month + 1
                                    }-${idx + 1 < 10 ? `0${idx + 1}` : idx + 1}`
                                  );
                                })[0].checkOutTime.length === 0
                              ? '-'
                              : certificateData.certificateRecords.filter(record => {
                                  return (
                                    record.date ===
                                    `${calendar.year}-${
                                      calendar.month + 1 < 10 ? `0${calendar.month + 1}` : calendar.month + 1
                                    }-${idx + 1 < 10 ? `0${idx + 1}` : idx + 1}`
                                  );
                                })[0].checkOutTime}
                          </Text>
                        </View>
                      );
                    })}
                  </View>

                  <View style={styles.tableCalendarLastRow}>
                    <View style={styles.tableCalendarIndex}>
                      <Text style={styles.tableCalendarText}>출석</Text>
                    </View>
                    {calendar.days.map((data, idx) => {
                      return (
                        <View
                          style={data.isValidDay ? styles.tableCalendarDataCell : styles.tableCalendarDatInvalidCell}
                          key={`attendance${idx}`}
                        >
                          {data.sunday ||
                          data.saturday ||
                          !data.isValidDay ||
                          certificateData.certificateRecords.filter(record => {
                            return (
                              record.date ===
                              `${calendar.year}-${
                                calendar.month + 1 < 10 ? `0${calendar.month + 1}` : calendar.month + 1
                              }-${idx + 1 < 10 ? `0${idx + 1}` : idx + 1}`
                            );
                          }).length === 0 ? (
                            <Text></Text>
                          ) : (
                            convertToAttendanceFigure(
                              certificateData.certificateRecords.filter(record => {
                                return (
                                  record.date ===
                                  `${calendar.year}-${
                                    calendar.month + 1 < 10 ? `0${calendar.month + 1}` : calendar.month + 1
                                  }-${idx + 1 < 10 ? `0${idx + 1}` : idx + 1}`
                                );
                              })[0].attendanceResult
                            )
                          )}
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            );
          })}

          <View style={styles.noti}>
            {convertToCertificateType(certificateData.type) === CertificateType.URCLASS ? (
              <View>
                <Text style={styles.notiTextTitle}>{ATTENDANCE_CHECK_MESSAGE.URCLASS.TITLE}</Text>
              </View>
            ) : (
              <View>
                <Text style={styles.notiTextTitle}>{ATTENDANCE_CHECK_MESSAGE.HRD_NET.TITLE}</Text>
              </View>
            )}
            {convertToCertificateType(certificateData.type) === CertificateType.URCLASS ? (
              <View style={styles.notiList}>
                <View style={styles.notiText}>
                  {ATTENDANCE_CHECK_MESSAGE.URCLASS.CONTENT.map(content => (
                    <View style={styles.notiList}>
                      <Text style={styles.notiText}>{content}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ) : (
              <View style={styles.notiList}>
                <View style={styles.notiText}>
                  {ATTENDANCE_CHECK_MESSAGE.HRD_NET.CONTENT.map(content => (
                    <View style={styles.notiList}>
                      <Text style={styles.notiText}>{content}</Text>
                    </View>
                  ))}
                </View>
              </View>
            )}
          </View>
        </View>
        <View style={styles.signature}>
          <View style={styles.signatureSign}>
            <Image style={styles.signatureSignImg} src={Signature} />
            <Text style={styles.signatureSignText}>주식회사 코드스테이츠 대표 김인기 (인)</Text>
            <Text style={styles.signatureDateText}>발급일자 : {issuedDateforHRD} </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
