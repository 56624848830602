import { makeToast } from './../../../../util/makeToast';
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';

import { JOB_POSITION_GQL, INTEREST_DOMAIN_GQL, AUTO_COMPLTE_SKILL_SET_GQL } from '../apollo/jobs/query';
import { CREATE_JOB_PROFILE_GQL, UPDATE_JOB_PROFILE_GQL } from '../apollo/jobs/mutation';

import {
  CreateMyJobProfileData,
  CreateMyJobProfileVars,
  UpdateMyJobProfileData,
  UpdateMyJobProfileVars,
  AutoCompletedSkillSetData,
  AutoCompletedSkillSetVars,
  JobPositionData,
  JobProfile,
  FavorPosition,
  InterestDomainData,
  InterestDomain,
  CareerStatus,
  EducationDetail,
  ActivityDetail,
  ExperienceDetail,
  LanguageDetail
} from '../apollo/jobs/type';
import { useDebounce } from '../../../../util/hooks/useDebounce';
import moment from 'moment';
import { isNil } from 'lodash';

type JobProfileFormModelValueType = {
  initialProfile?: JobProfile;
  handleComplete: () => void;
  handleChangePreviousPage: () => void;
  handleChangeNextPage: () => void;
};

export const JobProfileFormModel = ({
  initialProfile,
  handleComplete,
  handleChangePreviousPage,
  handleChangeNextPage
}: JobProfileFormModelValueType) => {
  const [korName, setKorName] = useState<string | undefined>(undefined);
  const [engFirstName, setEngFirstName] = useState<string | undefined>(undefined);
  const [engLastName, setEngLastName] = useState<string | undefined>(undefined);
  const [mobile, setMobile] = useState<string | undefined>(undefined);
  const [birthday, setBirthday] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [githubUserName, setGithubUserName] = useState<string | undefined>(undefined);
  const [blogURL, setBlogURL] = useState<string | undefined>(undefined);
  const [portfolioURL, setPortfolioURL] = useState<string | undefined>(undefined);
  const [favorPositions, setFavorPositions] = useState<FavorPosition[]>([]);

  /* 관심분야 */
  const [jobProfileInterestDomains, setJobProfileInterestDomains] = useState<InterestDomain[]>([]);
  const [isNoInterestDomain, setIsNoInterestDomain] = useState<boolean | undefined>(undefined);

  /* 스킬셋 */
  const [jobProfileSkillSetNames, setJobProfileSkillSetNames] = useState<string[]>([]);
  const [skillSetInput, setSkillSetInput, debouncedSkillSetInput] = useDebounce();
  const [skillSetAutoCompleteQuery, setSkillSetAutoCompleteQuery] = useState<string | undefined>();

  const [autoCompleteSkillSets, { data: autoCompletedSkillSetData }] = useLazyQuery<
    AutoCompletedSkillSetData,
    AutoCompletedSkillSetVars
  >(AUTO_COMPLTE_SKILL_SET_GQL, {
    variables: {
      skillSetName: skillSetAutoCompleteQuery
    },
    fetchPolicy: 'network-only'
  });

  const autoCompletedSkillSets: string[] = autoCompletedSkillSetData
    ? autoCompletedSkillSetData.urclassSkillSetsAutoCompletion.map(skillSet => skillSet.name)
    : [];

  const changeSkillSetInput = (skillSetInput: string) => {
    setSkillSetInput(skillSetInput);
  };

  const changeSkillSetNames = (skillSetNames: string[]) => {
    /* 공백 제거 */
    setJobProfileSkillSetNames(skillSetNames.filter(skillSetName => skillSetName.replace(/\s/g, '').length));
  };

  useEffect(() => {
    if (debouncedSkillSetInput) {
      setSkillSetAutoCompleteQuery(debouncedSkillSetInput);
      autoCompleteSkillSets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSkillSetInput]);

  /* 학력정보 */
  const [educationDetails, setEducationDetails] = useState<EducationDetail[]>([]);
  /* 외국어 구사 능력 */
  const [languageDetails, setLanguageDetails] = useState<LanguageDetail[]>([]);
  /* 교육/수상/자격/관련 경험 */
  const [activityDetails, setActivityDetails] = useState<ActivityDetail[]>([]);
  /* 경력정보 */
  const [experienceDetails, setExperienceDetails] = useState<ExperienceDetail[]>([]);

  const changeEducationDetails = (educationDetails: EducationDetail[]) => {
    setEducationDetails(educationDetails);
  };
  const changeLanguageDetails = (languageDetails: LanguageDetail[]) => {
    setLanguageDetails(languageDetails);
  };
  const changeActivityDetails = (activityDetails: ActivityDetail[]) => {
    setActivityDetails(activityDetails);
  };
  const changeExperienceDetails = (experienceDetails: ExperienceDetail[]) => {
    setExperienceDetails(experienceDetails);
  };

  /* Extra Inforomation */
  const [isRequireExtraInformation, setIsRequireExtraInformation] = useState<boolean | undefined>(undefined);
  const [careerStatus, setCareerStatus] = useState<CareerStatus>(CareerStatus.UNKNOWN);
  const [lastExperienceSalary, setLastExperienceSalary] = useState<number | undefined>(undefined);
  const [isLastExperienceSalaryPublic, setIsLastExperienceSalaryPublic] = useState<boolean | undefined>(undefined);
  const [lastEducationStatus, setLastEducationStatus] = useState<string | undefined>(undefined);

  const handleCareerStatus = (careerStatus: CareerStatus) => {
    setCareerStatus(careerStatus);
  };

  const handleIsLastExperienceSalaryPublic = (checked: boolean) => {
    setIsLastExperienceSalaryPublic(checked);
  };

  const handleLastExperienceSalary = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (isNil(e)) {
      setLastExperienceSalary(undefined);
    } else {
      /* 9자리 이상 입력 불가 */
      if (!Number.isNaN(Number(e.target.value)) && String(e.target.value).length < 10) {
        setLastExperienceSalary(Number(e.target.value));
      }
    }
  };

  const { data, loading, error } = useQuery<JobPositionData>(JOB_POSITION_GQL);
  const { data: interestDomainData } = useQuery<InterestDomainData>(INTEREST_DOMAIN_GQL);

  const [createJobProfile, { loading: createJobProfileLoading }] = useMutation<
    CreateMyJobProfileData,
    CreateMyJobProfileVars
  >(CREATE_JOB_PROFILE_GQL);

  const [updateJobProfile, { loading: updateJobProfileLoading }] = useMutation<
    UpdateMyJobProfileData,
    UpdateMyJobProfileVars
  >(UPDATE_JOB_PROFILE_GQL);

  const handleCreateJobProfile = async () => {
    try {
      if (email && mobile && korName && engFirstName && engLastName && favorPositions.length && birthday) {
        const { data } = await createJobProfile({
          variables: {
            favorPositionIds: favorPositions.map((position: FavorPosition) => position.id),
            interestDomainIds: jobProfileInterestDomains.map((domain: InterestDomain) => domain.id) ?? [],
            skillSetNames: jobProfileSkillSetNames,

            educationDetails: educationDetails.map(educationDetail => {
              return {
                majorId: educationDetail.major.id,
                majorName: educationDetail.majorName,
                certificateDegree: educationDetail.certificateDegree,
                isAttending: educationDetail.isAttending,
                isLastEducation: educationDetail.isLastEducation,
                startAt: educationDetail.startAt,
                completionAt: educationDetail.completionAt,
                schoolName: educationDetail.schoolName
              };
            }),
            activityDetails: activityDetails.map(activityDetail => {
              return {
                category: activityDetail.category,
                name: activityDetail.name,
                description: activityDetail.description
              };
            }),
            languageDetails: languageDetails.map(languageDetail => {
              return {
                languageName: languageDetail.name,
                languageSkillLevel: languageDetail.languageSkillLevel
              };
            }),
            experienceDetails: experienceDetails.map(experienceDetail => {
              return {
                jobTitle: experienceDetail.jobTitle,
                companyName: experienceDetail.companyName,
                isInService: experienceDetail.isInService,
                startAt: experienceDetail.startAt,
                endAt: experienceDetail.endAt
              };
            }),
            jobProfile: {
              email,
              mobile,
              korName,
              engFirstName,
              engLastName,
              birthday,
              githubUserName: githubUserName || '',
              blogURL,
              portfolioURL,
              isNoInterestDomain: isNoInterestDomain ?? false,
              careerStatus,
              totalCareerMonth: experienceDetails.reduce((acc, cur) => {
                // TODO : 재직중인 경력의 경우, 총 경력 산출 정책 필요 (by. 두현)
                if (!cur.isInService) {
                  acc = acc + moment(cur.endAt!).diff(cur.startAt, 'months');
                }
                return acc;
              }, 0),
              lastEducationStatus: educationDetails.find(educationDetail => educationDetail.isLastEducation)
                ?.certificateDegree,
              lastExperienceSalary: lastExperienceSalary ?? 0,
              isLastExperienceSalaryPublic
            }
          }
        });

        if (data && data.urclassCreateMyJobProfile) {
          makeToast({
            type: 'success',
            content: '프로필이 성공적으로 등록되었습니다.'
          });
          handleComplete();
          return;
        }
        makeToast({ type: 'error', content: '이력서 생성에 문제가 있습니다.' });
      }
    } catch (err) {
      makeToast({ type: 'error', content: '이력서 생성에 문제가 있습니다.' });
    }
  };

  const handleUpdateJobProfile = async () => {
    try {
      const { data } = await updateJobProfile({
        variables: {
          favorPositionIds: favorPositions.map((position: FavorPosition) => position.id),
          interestDomainIds: jobProfileInterestDomains.map((domain: InterestDomain) => domain.id),
          skillSetNames: jobProfileSkillSetNames,
          educationDetails: educationDetails.map(educationDetail => {
            return {
              majorId: educationDetail.major.id,
              majorName: educationDetail.majorName,
              certificateDegree: educationDetail.certificateDegree,
              isAttending: educationDetail.isAttending,
              isLastEducation: educationDetail.isLastEducation,
              startAt: educationDetail.startAt,
              completionAt: educationDetail.completionAt,
              schoolName: educationDetail.schoolName
            };
          }),
          activityDetails: activityDetails.map(activityDetail => {
            return {
              category: activityDetail.category,
              name: activityDetail.name,
              description: activityDetail.description
            };
          }),
          languageDetails: languageDetails.map(languageDetail => {
            return {
              languageName: languageDetail.name,
              languageSkillLevel: languageDetail.languageSkillLevel
            };
          }),
          experienceDetails: experienceDetails.map(experienceDetail => {
            return {
              jobTitle: experienceDetail.jobTitle,
              companyName: experienceDetail.companyName,
              isInService: experienceDetail.isInService,
              startAt: experienceDetail.startAt,
              endAt: experienceDetail.endAt
            };
          }),
          jobProfile: {
            email,
            mobile,
            korName,
            engFirstName,
            engLastName,
            birthday,
            githubUserName,
            blogURL,
            portfolioURL,
            isNoInterestDomain,
            careerStatus,
            totalCareerMonth: experienceDetails.reduce((acc, cur) => {
              // TODO : 재직중인 경력의 경우, 총 경력 산출 정책 필요 (by. 두현)
              if (!cur.isInService) {
                acc = acc + moment(cur.endAt!).diff(cur.startAt, 'months');
              }
              return acc;
            }, 0),
            lastEducationStatus: educationDetails.find(educationDetail => educationDetail.isLastEducation)
              ?.certificateDegree,
            lastExperienceSalary: lastExperienceSalary ?? 0,
            isLastExperienceSalaryPublic
          }
        }
      });

      if (data && data.urclassUpdateMyJobProfile) {
        makeToast({
          type: 'success',
          content: '프로필이 성공적으로 수정되었습니다.'
        });
        handleComplete();
        return;
      }
      makeToast({ type: 'error', content: '이력서 수정과정에 문제가 있습니다.' });
    } catch (err) {
      makeToast({ type: 'error', content: '이력서 수정과정에 문제가 있습니다.' });
    }
  };

  const korNameValid = (korName?: string): boolean => {
    if (!korName || korName.match(/[ -~]/)) {
      return false;
    }
    return true;
  };

  const engNameValid = (engName?: string): boolean => {
    if (!engName || engName.match(/[^a-zA-Z]/g)) {
      return false;
    }
    return true;
  };

  const emailValid = (email?: string): boolean => {
    if (!email || email.indexOf('@gmail.com') === -1) {
      return false;
    }

    return email.match(/[ ]/g) ? false : true;
  };

  const idValid = (id?: string): boolean => {
    if (!id || id.match(/[^a-zA-Z0-9-]/g)) {
      return false;
    }
    return true;
  };

  const formDataValidCheck = (isGithubReq?: boolean): boolean =>
    korNameValid(korName) &&
    engNameValid(engFirstName) &&
    engNameValid(engLastName) &&
    mobile &&
    emailValid(email) &&
    (isGithubReq ? idValid(githubUserName) : true) &&
    favorPositions.length
      ? true
      : false;

  const handleCheckBox = (checked: boolean, position: FavorPosition) => {
    if (checked) {
      setFavorPositions((prevState: FavorPosition[]) => [...prevState, position]);
    } else {
      setFavorPositions((prevState: FavorPosition[]) =>
        prevState.filter((favorPosition: FavorPosition) => favorPosition.id !== position.id)
      );
    }
  };

  const handleChangeKorName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKorName(e.target.value.trim());
  };

  const handleChangeEngFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEngFirstName(e.target.value.trim());
  };

  const handleChangeEngLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEngLastName(e.target.value.trim());
  };

  const handleChangeMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!Number.isNaN(Number(e.target.value))) {
      setMobile(e.target.value);
    }
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.trim());
  };

  const handleChangeBirthday = (dates: moment.Moment | null, dateString: string) => {
    setBirthday(dateString);
  };

  const handleChangeGithubUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGithubUserName(e.target.value.trim());
  };

  const handleChangeBlogURL = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBlogURL(e.target.value);
  };

  const handleChangePortfolioURL = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPortfolioURL(e.target.value);
  };

  const handleInterestDomainCheckBox = (checked: boolean, interestDomain: InterestDomain) => {
    if (checked) {
      setJobProfileInterestDomains((prevState: InterestDomain[]) => [...prevState, interestDomain]);
    } else {
      setJobProfileInterestDomains((prevState: InterestDomain[]) =>
        prevState.filter(
          (jobProfileInterestDomain: InterestDomain) => jobProfileInterestDomain.id !== interestDomain.id
        )
      );
    }
  };

  const handleNoInterestDomainCheckBox = (checked: boolean) => {
    setIsNoInterestDomain(checked);
    if (checked) {
      setJobProfileInterestDomains([]);
    }
  };

  useEffect(() => {
    if (initialProfile) {
      const {
        email,
        mobile,
        korName,
        engFirstName,
        engLastName,
        birthday,
        githubUserName,
        blogURL,
        portfolioURL,
        favorPositions,

        isRequireExtraInformation,
        careerStatus,
        lastExperienceSalary,
        isLastExperienceSalaryPublic,
        lastEducationStatus,
        isNoInterestDomain,

        interestDomains,
        skillSets,
        educationDetails,
        languageDetails,
        activityDetails,
        experienceDetails
      } = initialProfile;

      setEmail(email);
      setMobile(mobile);
      setKorName(korName);
      setEngFirstName(engFirstName);
      setEngLastName(engLastName);
      setBirthday(birthday);
      setGithubUserName(githubUserName);
      setBlogURL(blogURL);
      setPortfolioURL(portfolioURL);
      setFavorPositions(favorPositions);
      setJobProfileInterestDomains(interestDomains);
      setJobProfileSkillSetNames(skillSets.map(ss => ss.name));

      /* extra */
      setIsRequireExtraInformation(isRequireExtraInformation);
      setCareerStatus(careerStatus ?? CareerStatus.UNKNOWN);
      setLastExperienceSalary(lastExperienceSalary);
      setIsLastExperienceSalaryPublic(isLastExperienceSalaryPublic);
      setLastEducationStatus(lastEducationStatus);
      setIsNoInterestDomain(isNoInterestDomain);

      /* page  학력/활동/언어/경력 */
      setEducationDetails(educationDetails);
      setLanguageDetails(languageDetails);
      setActivityDetails(activityDetails);
      setExperienceDetails(experienceDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const jobPositions = data && data.urclassGetJobPositions ? data.urclassGetJobPositions : [];
  const interestDomains =
    interestDomainData && interestDomainData.urclassInterestDomains ? interestDomainData.urclassInterestDomains : [];

  return {
    jobPositions,
    interestDomains,
    loading,
    error,

    korName,
    engFirstName,
    engLastName,
    mobile,
    email,
    birthday,
    githubUserName,
    blogURL,
    portfolioURL,
    favorPositions,
    jobProfileInterestDomains,

    jobProfileSkillSetNames,
    autoCompletedSkillSets,
    skillSetInput,
    changeSkillSetInput,
    changeSkillSetNames,

    isRequireExtraInformation,
    careerStatus,
    lastExperienceSalary,
    isLastExperienceSalaryPublic,
    lastEducationStatus,
    isNoInterestDomain,

    korNameValid,
    engNameValid,
    emailValid,
    idValid,

    formDataValidCheck,

    handleCheckBox,
    handleCreateJobProfile,
    handleUpdateJobProfile,

    createJobProfileLoading,
    updateJobProfileLoading,

    handleChangeKorName,
    handleChangeEngFirstName,
    handleChangeEngLastName,
    handleChangeEmail,
    handleChangeMobile,
    handleChangeBirthday,
    handleChangeBlogURL,
    handleChangeGithubUserName,
    handleChangePortfolioURL,
    handleInterestDomainCheckBox,
    handleNoInterestDomainCheckBox,
    handleLastExperienceSalary,
    handleIsLastExperienceSalaryPublic,
    handleCareerStatus,

    handleChangePreviousPage,
    handleChangeNextPage,

    educationDetails,
    languageDetails,
    activityDetails,
    experienceDetails,
    changeEducationDetails,
    changeLanguageDetails,
    changeActivityDetails,
    changeExperienceDetails
  };
};
