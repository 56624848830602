import styled from '@emotion/styled';
import { TimerInfoType } from '../AdmissionApplicationFormPage';

type TimerPanelMode = 'all' | 'survey' | 'page' | null;
interface TimerProps {
  timerPanelMode: TimerPanelMode;
  timerInfo: TimerInfoType;
}

const convertSecondToMinute = (seconds: number) => {
  if (seconds < 60) {
    return `${seconds}초`;
  } else {
    return `${Math.floor(seconds / 60)}분 ${seconds % 60}초`;
  }
};

const Timer = ({ timerPanelMode, timerInfo }: TimerProps) => {
  return isNaN(Number(timerInfo.pageLimitTime)) || isNaN(Number(timerInfo.surveyLimitTime)) ? (
    <></>
  ) : (
    <TimerBox timerPanelMode={timerPanelMode}>
      {timerInfo.surveyRemainTime !== null && (
        <div className="timer-box">
          <span className="timer-category">총 질문지 답변 시간</span>
          <span className="timer-time">{`${convertSecondToMinute(timerInfo.surveyRemainTime || 0)}`}</span>
        </div>
      )}
      {timerInfo.pageRemainTime !== null && (
        <div className="timer-box">
          <span className="timer-category">페이지 답변 시간</span>
          <span className="timer-time">{`${convertSecondToMinute(timerInfo.pageRemainTime || 0)}`}</span>
        </div>
      )}
    </TimerBox>
  );
};

export default Timer;

const TimerBox = styled.div<{ timerPanelMode: TimerPanelMode }>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  z-index: 10;
  padding: 8px 20px;
  min-width: 226px;
  display: flex;
  flex-direction: column;
  align-items: ${({ timerPanelMode }) => (timerPanelMode === 'all' ? null : 'center')};
  margin-bottom: 12px;

  .timer-box {
    display: flex;
    align-items: center;

    .timer-category {
      display: inline-block;
      width: ${({ timerPanelMode }) => (timerPanelMode === 'all' ? '109px' : null)};
      text-align: ${({ timerPanelMode }) => (timerPanelMode === 'all' ? 'right' : null)};
      margin-right: 8px;

      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.025em;
      color: #262f40;
    }

    .timer-time {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.025em;
      color: #452cdd;
    }
  }

  @media screen and (max-width: 992px) {
    padding: 8px 12px;
    min-width: 174px;
    margin-bottom: 8px;

    .timer-box {
      .timer-category {
        width: ${({ timerPanelMode }) => (timerPanelMode === 'all' ? '94px' : null)};
        margin-right: 4px;
        font-size: 12px;
        line-height: 18px;
      }

      .timer-time {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
`;
