import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';

import { MY_CLASSROOM_STATUS_GQL, CODE_PROBLEMS_GQL } from '../../apollo/coplit/query';
import { CodeProblemSubmissionStatus } from '../../apollo/coplit/type';
import { eventCallbackOnScreenFrame, store } from '../../../../util';

import type {
  MyClassroomStatusVars,
  MyClassroomStatusData,
  Classroom,
  MyCodeProblemsData,
  MyCodeProblemsVars,
  CodeProblem
} from '../../apollo/coplit/type';

export const useController = () => {
  const params = useParams<{ id: string }>();
  const classroomId = parseInt(params.id, 10);
  const history = useHistory();
  const [currentClassroom, setCurrentClassroom] = useState<Classroom | undefined>();
  const [page, setPage] = useState(store.paginationIndex.get(history.location.pathname) || 1);
  const [perPage, setPerPage] = useState(store.pageSize.get(history.location.pathname) || 50);
  const [isPassed, setIsPassed] = useState<boolean | undefined>(undefined);
  const [status, setStatus] = useState<CodeProblemSubmissionStatus | undefined>(undefined);
  const [codeProblems, setCodeProblems] = useState<CodeProblem[]>([]);
  const [codeProblemsTotal, setCodeProblemsTotal] = useState(0);

  const {
    data: myClassroomStatusData,
    loading: myClassroomStatusLoading,
    error: myClassroomStatusError
  } = useQuery<MyClassroomStatusData, MyClassroomStatusVars>(MY_CLASSROOM_STATUS_GQL, {
    variables: { classroomId },
    fetchPolicy: 'network-only'
  });

  const {
    data: myCodeProblemData,
    loading: myCodeProblemLoading,
    error: myCodeProblemError
  } = useQuery<MyCodeProblemsData, MyCodeProblemsVars>(CODE_PROBLEMS_GQL, {
    variables: { page, perPage, filter: { classroomId, isPassed, status } },
    fetchPolicy: 'network-only'
  });

  const handleScrollEvent = () => {
    store.scrollPosition.set(history.location.pathname, window.pageYOffset);
  };

  useEffect(() => {
    if (myCodeProblemData?.urclassMyCodeProblems) {
      setCodeProblems(myCodeProblemData.urclassMyCodeProblems.items);
      setCodeProblemsTotal(myCodeProblemData.urclassMyCodeProblems.total);
    }

    const initPosition: number = store.scrollPosition.get(history.location.pathname);

    if (codeProblems.length && initPosition) {
      window.scrollTo(0, initPosition);
    }
  }, [myCodeProblemData, codeProblems.length]);

  useEffect(() => {
    const handler = eventCallbackOnScreenFrame(handleScrollEvent, {});

    window.addEventListener('scroll', handler, {
      passive: true
    });

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  useEffect(() => {
    if (myClassroomStatusData?.urclassMyClassroomStatus) {
      setCurrentClassroom(myClassroomStatusData.urclassMyClassroomStatus);
    }
  }, [myClassroomStatusData]);

  return {
    loading: myClassroomStatusLoading,
    error: myClassroomStatusError || myCodeProblemError,
    currentClassroom,
    isPassed,
    setIsPassed,
    codeProblems,
    codeProblemsTotal,

    page,
    setPage,
    perPage,
    setPerPage,
    status,
    setStatus,

    myCodeProblemLoading
  };
};
