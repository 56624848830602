import React from 'react';
import styled from '@emotion/styled';
import { CardNewsType } from '../types';
import CardNews from './CardNews';

type CardNewsGroupProps = {
  cardNewsList: CardNewsType[];
};

const Grid = styled.div<{ itemWidth: number; gap: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${({ itemWidth }) => itemWidth}px, 1fr));
  grid-auto-columns: minmax(316px, 316px);
  grid-gap: ${({ gap }) => `${gap}px`};

  @media screen and (max-width: 800px) {
    justify-content: center;
  }
`;

const CardNewsGroup: React.FC<CardNewsGroupProps> = ({ cardNewsList }) => {
  return (
    <div>
      <Grid itemWidth={300} gap={40}>
        {cardNewsList.map(cardNews => {
          const { id, title, description, thumbnailURL, url } = cardNews;

          return (
            <a href={url} target="_blank" rel="noopener noreferrer" key={id}>
              <CardNews title={title} description={description} imageUrl={thumbnailURL} />
            </a>
          );
        })}
      </Grid>
    </div>
  );
};

export default CardNewsGroup;
