import React, { useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { MY_APPLICATIONS_GQL } from '../../apollo/mypage/query';
import { Application, ApplyingStatus, MyApplicationsData } from '../../apollo/mypage/type';
import { data as cardNewsList } from './data';
import { Course } from './types';
import throttle from 'lodash/throttle';

import { GeneralTemplate } from '../../templates';
import { SectionLabel } from '../../components/atoms/Label';
import { GeneralHeader } from '../../components/organisms/Header';
import LearningStatus from '../../components/organisms/LearningStatus';
import { ApplicationBanner, GotoWebsiteBanner } from '../../components/organisms/MainPage';
import NavDropDownGroup from './components/NavDropDownGroup';
import NavButtonGroup from './components/NavButtonGroup';
import CardNewsGroup from './components/CardNewsGroup';

const MainPageWrapper = styled.div`
  .cardNews-nav__mobile {
    display: none;
    margin: 4px 0 16px auto;
    @media screen and (max-width: 992px) {
      display: block;
    }
  }

  .cardNews-nav__desktop {
    display: none;
    margin: 32px 0 16px 0;
    @media screen and (min-width: 993px) {
      display: block;
    }
  }
`;

const courseOptions = [
  { name: '전체 보기', value: undefined },
  ...Object.entries(Course).map(([key, value]) => ({ name: value, value: key }))
];
const itemToShow = 12;

const CuratedMainPage: React.FC = () => {
  const history = useHistory();
  const [course, setCourse] = useState<string>();
  const [page, setPage] = useState(1);

  const {
    data: myApplicationData,
    loading: myApplicationLoading,
    error: myApplicationDataError
  } = useQuery<MyApplicationsData>(MY_APPLICATIONS_GQL);

  const CardNewsListByCourse = useMemo(() => {
    return cardNewsList.filter(news => (course ? news.course === course : true)).sort((a, b) => b.id - a.id);
  }, [course]);

  const handleCourseChange = (newCourse?: string) => {
    if (newCourse === course) return;
    setCourse(newCourse);
    setPage(1);
    history.replace(newCourse ? `${history.location.pathname}?course=${newCourse}` : history.location.pathname);
  };

  const displayApplicationStepLinkBanner =
    myApplicationData &&
    myApplicationData.urclassMyApplications.find(
      (application: Application) => application.applyingStatus === ApplyingStatus.APPLYING
    );

  const displayGotoWebsiteBanner = !myApplicationData?.urclassMyApplications.length;

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const courseParam = Object.keys(Course).find(value => value === search.get('course'));
    setCourse(courseParam);
  }, []);

  useEffect(() => {
    const handler = throttle(() => {
      const pageHeight = window.document.documentElement.scrollHeight;
      const screenHeight = window.document.documentElement.offsetHeight;
      const scrollTop = window.pageYOffset;
      const total = CardNewsListByCourse.length;

      if (screenHeight + scrollTop >= pageHeight - 450) {
        if (itemToShow * page < total) {
          setPage(page + 1);
        }
      }
    }, 400);

    window.addEventListener('scroll', handler, {
      passive: true
    });
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [CardNewsListByCourse, page]);

  return (
    <GeneralTemplate error={!!myApplicationDataError} loading={myApplicationLoading}>
      {{
        header: <GeneralHeader />,
        body: (
          <MainPageWrapper>
            <div className="body-wrapper">
              {displayApplicationStepLinkBanner && (
                <ApplicationBanner onClick={() => history.push('/mypage#application')} />
              )}
              {displayGotoWebsiteBanner && (
                <GotoWebsiteBanner onClick={() => window.location.assign('https://codestates.com')} />
              )}
              <LearningStatus />
              <SectionLabel marginBottom={0}>Magazine</SectionLabel>
              <div className="cardNews-content"></div>
              <div className="cardNews-nav">
                <NavDropDownGroup
                  className="cardNews-nav__mobile"
                  value={course}
                  onSelected={(newValue?: string) => handleCourseChange(newValue)}
                  options={courseOptions}
                />
                <NavButtonGroup
                  className="cardNews-nav__desktop"
                  value={course}
                  onSelected={(newValue?: string) => handleCourseChange(newValue)}
                  options={courseOptions}
                />
              </div>
              <CardNewsGroup cardNewsList={CardNewsListByCourse.slice(0, itemToShow * page)} />{' '}
            </div>
          </MainPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default CuratedMainPage;
