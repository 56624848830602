export enum ColorPaletteType {
  BLUISHGRAY1 = 'BluishGray1',
  BLUISHGRAY2 = 'BluishGray2',
  BLUISHGRAY3 = 'BluishGray3',
  BLUISHGRAY4 = 'BluishGray4',
  BLUISHGRAY5 = 'BluishGray5',
  GRAY1 = 'Gray1',
  GRAY2 = 'Gray2',
  GRAY3 = 'Gray3',
  GRAY4 = 'Gray4',
  GRAY5 = 'Gray5',
  GRAY6 = 'Gray6',
  GRAY7 = 'Gray7',
  GRAY8 = 'Gray8',
  GRAY9 = 'Gray9',
  WHITE = 'White',
  RED = 'Red',
  LIGHT_BLUE = 'LightBlue',
  BLUE = 'Blue',
  GREEN2 = 'Green2',
  GREEN3 = 'Green3',
  KAKAO_YELLOW = 'KakaoYellow',
  PURPLE = 'Purple',
  ORANGE = 'Orange',
  PEACH = 'Peach',
  BLACK = 'Black',
  BROWN = 'Brown',
  COZ_BLUE_600 = 'CozBlue600',
  COZ_PURPLE_600 = 'CozPurple600'
}

export const ColorPalette = {
  BluishGray1: '#ECEFF4',
  BluishGray2: '#8C9CBA',
  BluishGray3: '#CCD3E0',
  BluishGray4: '#262F40',
  BluishGray5: '#455573',
  Gray1: '#333333',
  Gray2: '#828282',
  Gray3: '#BDBDBD',
  Gray4: '#E0E0E0',
  Gray5: '#F2F2F2',
  Gray6: '#5C5C5C',
  Gray7: '#A3A3A3',
  Gray8: '#f5f7f9',
  Gray9: '#d6d6d6',
  Green2: '#00B86A',
  Green3: '#6FCF97',
  White: '#FFFFFF',
  KakaoYellow: '#FEE500',
  Red: 'rgba(223, 105, 85, 1)', // #DF6955
  LightBlue: 'rgba(71, 189, 255, 1)', //#47BDFF
  Blue: '#5E85E8',
  Purple: '#452CDD',
  Orange: '#ff6e0a',
  Peach: '#ff6947',
  Black: '#000',
  Brown: '#531F14',
  CozBlue600: '#295CE0',
  CozPurple600: '#452CDD'
};
