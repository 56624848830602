import ReactDOM from 'react-dom';
import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from '@emotion/styled';
import { ApolloProvider } from '@apollo/client';
import { HackleProvider } from '@hackler/react-sdk';

import App from './App';
import LoadingPage from './components/LoadingPage';

import { CredentialConsumer, CredentialProvider } from './contexts/credential';
import { GlobalMessageContextProvider } from './contexts/globalMessage';

import client from './apollo/client';
import { getUser, hackleClient } from './util/marketingTools/hackle';
import { AttendanceStatusProvider } from './domain/lms/contexts/AttendanceStatus';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './query/client';

import ReactGA from 'react-ga';

const AppWrapper = styled.div`
  background-color: #fbfbfb;
  height: 100vh;
`;

export const FACEBOOK_PIXEL_ID = '211293532962545';

const TRACKING_ID = 'UA-87589011-6';
ReactGA.initialize(TRACKING_ID);

const Urclass: React.FC = () => {
  document.title = '코드스테이츠 학습 플랫폼 UrClass';

  const searchParams = new URLSearchParams(window.location.search);

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <CredentialProvider>
            <AttendanceStatusProvider>
              <GlobalMessageContextProvider>
                <CredentialConsumer>
                  {credential => (
                    <HackleProvider hackleClient={hackleClient} user={getUser(credential, searchParams)}>
                      <Suspense fallback={<LoadingPage />}>
                        <AppWrapper>
                          <App />
                        </AppWrapper>
                      </Suspense>
                    </HackleProvider>
                  )}
                </CredentialConsumer>
              </GlobalMessageContextProvider>
            </AttendanceStatusProvider>
          </CredentialProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </Router>
  );
};

ReactDOM.render(<Urclass />, document.getElementById('root'));
