import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';

import { UPDATE_USER_COMPLETE_GQL } from '../../../apollo/course/mutation';
import { GET_PLAYLIST_GQL, GET_PLAYLIST_CONTENTS_GQL } from '../../../apollo/course/query';
import type { PlaylistContent } from '../../../apollo/contents/types';

type ContentNavProps = {
  playlistContents: PlaylistContent[];
  contentUuid: string;
  playlistId: string;
  courseId: number;
  isInViewport: boolean;
  playlistContent: PlaylistContent;
  isSubmittedQuizSet: boolean;
  playlistsData: any;
};

const ContentNavigation = styled.div`
  padding: 80px 0px 150px;
  text-align: right;
  margin-top: 10rem;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 992px) {
    width: 100%;
  }

  @media screen and (max-width: 1100px) {
    margin-right: 0px;
  }
`;

const NavStudyStatus = styled.div`
  float: right;
  margin: 1rem 0;
  .desc {
    margin: 0rem 0.5rem;
    font-size: 18px;
    font-weight: 500;
  }
`;

const NavBtnContainer = styled.div`
  float: ${({ direction }: { direction: string }) => (direction === 'prev' ? 'left' : 'right')};

  text-align: center;
`;

const ContentNav: React.FC<ContentNavProps> = ({
  playlistContents,
  playlistContent,
  contentUuid,
  playlistId,
  courseId,
  isInViewport,
  isSubmittedQuizSet,
  playlistsData
}) => {
  const [next, setNext] = useState<string>('');
  const [prev, setPrev] = useState<string>('');
  const [iscompleted, setIsCompleted] = useState(false);
  const [currentContentId, setCurrentContentId] = useState(0);

  const [updateUserComplete] = useMutation(UPDATE_USER_COMPLETE_GQL);

  useEffect(() => {
    if (playlistContents.length) {
      playlistContents.forEach((playlistContent: PlaylistContent, index: number) => {
        if (playlistContent.content.uuid === contentUuid) {
          setCurrentContentId(playlistContent.content.id);
          setNext(playlistContents[index + 1]?.content ? playlistContents[index + 1].content.uuid : '');
          setPrev(playlistContents[index - 1] ? playlistContents[index - 1].content.uuid : '');
          setIsCompleted(playlistContent.isUserCompleted);
        }
      });
    }
  }, [contentUuid, playlistContents]);


  const updateUserCompleteHandler = async () => {
    await updateUserComplete({
      variables: {
        playlistId: Number(playlistId),
        contentId: currentContentId
      },
      fetchPolicy: 'no-cache',
      refetchQueries: [
        { query: GET_PLAYLIST_GQL, variables: { id: courseId } },
        {
          query: GET_PLAYLIST_CONTENTS_GQL,
          variables: { id: Number(playlistId) }
        }
      ]
    });
  };

  useEffect(() => {
    if (playlistContent.content.version.type === 'quiz_set' && isSubmittedQuizSet) {
      setIsCompleted(true);
      updateUserCompleteHandler();
    }
  }, [isSubmittedQuizSet]);

  useEffect(() => {
  if (playlistContent.content.version.type !== 'quiz_set' && isInViewport) {
      setIsCompleted(true);
      // 서버에 학습 완료 요청
      updateUserCompleteHandler();
      return;
    }
  }, [isInViewport]);

  useEffect(() => {
  }, [playlistsData])

  return (
    <ContentNavigation className="content-nav">
      <div>
        <NavStudyStatus>
        {
            iscompleted ? (<div style={{ width: '200px'}}>
            <SmileOutlined style={{ color: "#4000c7", fontSize: "20px"}}/>
            <span className='desc' style={{ color: "#4000c7" }}>학습 완료!</span>
            </div>) : (<>
              <LoadingOutlined />
              <span className='desc'>학습 중</span>
              </>)
          }
        </NavStudyStatus>
      </div>
      <div>
        <NavBtnContainer direction="prev">
          {prev ? (
            <Link
              to={{
                pathname: `/content/${prev}`,
                search: `?playlist=${playlistId}`
              }}
            >
              <Button>
                <LeftOutlined />
                Prev
              </Button>
            </Link>
          ) : (
            <div />
          )}
        </NavBtnContainer>
        <NavBtnContainer direction="next">
          {next ? (
            <Link
              to={{
                pathname: `/content/${next}`,
                search: `?playlist=${playlistId}`
              }}
            >
              <Button>
                Next
                <RightOutlined />
              </Button>
            </Link>
          ) : (
            <div />
          )}
        </NavBtnContainer>
      </div>
    </ContentNavigation>
  );
};

export default ContentNav;
