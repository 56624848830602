import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { MY_JOB_PROFILE_GQL } from '../common/apollo/jobs/query';
import { JobProfileFormModel } from '../common/viewModels/JobProfileFormModel';

import type { MyJobProfileData } from '../common/apollo/jobs/type';
import { MAX_FORM_MODAL_PAGE } from '../common/components/JobProfileForm';

export const useController = () => {
  const history = useHistory();
  const { data, loading, error } = useQuery<MyJobProfileData>(MY_JOB_PROFILE_GQL);

  /* Modal state */
  const [page, setPage] = useState<number>(1);

  const jobProfileFormModel = JobProfileFormModel({
    handleComplete: () => {
      history.replace('/jobs');
    },
    handleChangePreviousPage: () => {
      if (page > 1) {
        setPage(page - 1);
      }
    },
    handleChangeNextPage: () => {
      if (page < MAX_FORM_MODAL_PAGE) {
        setPage(page + 1);
      }
    }
  });

  const profile = data && data.urclassMyJobProfile;
  const hasError = error && error.message.indexOf('no-jobProfile') === -1;

  return {
    loading,
    profile,
    hasError,
    jobProfileFormModel,
    page
  };
};
