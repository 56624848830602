import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import styled from '@emotion/styled';
import { DropDownButton, DropDownMenu, DropDownItem } from './NavDropDown';

type NavDropDownGroupProps = {
  options: { name: string; value?: string }[];
  value?: string;
  onSelected: (newValue?: string) => void;
  className?: string;
};

const DropDownGroupWrapper = styled.div`
  position: relative;
  width: fit-content;
`;

const NavDropDownGroup: React.FC<NavDropDownGroupProps> = ({ options, value, onSelected, className }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const selectedName = useMemo(() => {
    return options.find(option => option.value === value)?.name ?? '-';
  }, [value, options]);

  const handleMenuToggle = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      const { current } = ref;
      if (!current || current.contains(e.target as Node)) {
        return;
      }
      setIsMenuOpen(false);
    };
    window.addEventListener('mouseup', onClickOutside);

    return () => window.removeEventListener('mouseup', onClickOutside);
  }, []);

  return (
    <DropDownGroupWrapper className={className} onClick={handleMenuToggle} ref={ref}>
      <DropDownButton>{selectedName}</DropDownButton>
      <DropDownMenu isOpen={isMenuOpen}>
        {options.map(option => (
          <DropDownItem
            key={`dropDownItem-${option.value}`}
            className={option.value === value ? 'selected' : undefined}
            onClick={() => onSelected(option.value)}
          >
            {option.name}
          </DropDownItem>
        ))}
      </DropDownMenu>
    </DropDownGroupWrapper>
  );
};

export default NavDropDownGroup;
