export type Browser = 'chrome' | 'firefox' | 'safari' | 'opera' | 'edge' | 'No browser detection';

export const detectBrowser = () => {
  let userAgent = navigator.userAgent;
  let browserName: Browser;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge';
  } else {
    browserName = 'No browser detection';
  }

  return browserName;
};

export const detectIsiOS = (navigator: Navigator) => {
  const iOSList = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];
  return iOSList.includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};

export const delayFunctionOnSafari = (browser: Browser, navigator: Navigator, exacutor: () => void) => {
  const isiOS = detectIsiOS(navigator);
  if (browser === 'safari' || isiOS) {
    setTimeout(() => {
      exacutor();
    }, 750);
  } else {
    exacutor();
  }
};
