import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import moment from 'moment';
import { ColorPalette, ColorPaletteType } from '../../styles/color';
import { Application } from '../../apollo/mypage/type';
import { Typography, TypographyType } from '../../components/atoms/Typography';
import { Button } from '../../components/molecules';
import { SizeType, StatusType } from '../../components/molecules/Button/type';

const Wrapper = styled.div`
  margin: auto;
  .inline {
    display: inline-block;
  }
  .application-step-deadline__text {
    margin-top: 21px;
  }
  .application-step-notification__text {
    color: ${ColorPalette.Peach};
    margin: -10px 0 35px;
  }
  .application-step-link {
    height: 58px;
    margin: 24px 0px 10px 0px;
    padding: 13px 13px 13px 33px;
    border-radius: 8px;
    border: solid 1px ${ColorPalette.Gray4};
    .application-step-link__text {
      display: inline-block;
      margin-top: 8px;
    }
    .application-step-link__button {
      display: inline-block;
      float: right;
      vertical-align: middle;
    }
  }
  .application-step-list__group {
    margin-top: 14px;
    .application-step-list__item {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin: 10px 0px 10px 0px;
      padding: 11px 13px 13px 33px;
      border-radius: 8px;
      border: solid 1px ${ColorPalette.Gray4};
      .application-step-list__text {
        display: inline-block;
        margin-top: 6px;
      }
      .application-step-list__button-wrapper {
        display: flex;
        align-items: center;
        .application-step-list__button {
          display: inline-block;
          background: ${ColorPalette.Gray5};
        }
      }
    }
    .submit {
      background: ${ColorPalette.Gray5};
    }
    .active {
      border: solid 2px ${ColorPalette.Gray1};
      font-weight: 700;
      button {
        border: none;
        background: ${ColorPalette.Gray1} !important;
        font-weight: 400;
        div {
          color: ${ColorPalette.White} !important;
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    margin-top: 59px;
    padding: 0px;
  }
`;

type Props = {
  selectedApplication: Application;
};

const ApplicationSteps = ({ selectedApplication }: Props) => {
  const history = useHistory();
  const { product, userApplicationSteps, submissions } = selectedApplication;

  const { applyEndDate } = product;

  // 지원 마감 정보 표기
  const deadline = moment(applyEndDate).diff(new Date(), 'days');
  const isDisplayDeadlineText = !isNaN(deadline);

  return (
    <Wrapper className="ga-urclass-applications-page">
      <Typography
        className="ga-urclass-applications-courseName"
        type={TypographyType.H2}
        color={ColorPaletteType.GRAY1}
        text={product.name}
      />

      {isDisplayDeadlineText ? (
        <div className="application-step-deadline__text">
          <p>
            {'지원 마감일까지 '}
            <strong>{deadline.toString()}</strong>일 남았습니다.
          </p>
        </div>
      ) : (
        <></>
      )}

      <div className="application-step-notification__text">
        <p>
          <strong>취・창업 적합성 검사, 학습 적합성 검사, HPC(Human Potential Check)</strong>는 다시 제출하실 수
          없습니다.
        </p>
      </div>

      <div className="application-step-list__group">
        {userApplicationSteps.map((userApplicationStep, idx, arr) => {
          const title = userApplicationStep.title;
          const order = idx + 1;
          const typeformId = userApplicationStep.typeformId;
          const isActiveStep = submissions.length === idx;
          const finalOrOrder = order === arr.length ? 'final' : order;
          // 해당 절차 완료 여부
          const isDone = !!submissions[idx];

          let buttonText;
          let styleClassName;

          if (isDone) {
            buttonText = '완료';
            styleClassName = 'submit';
          } else {
            if (isActiveStep) {
              buttonText = '시작!';
              styleClassName = 'active';
            } else {
              buttonText = '대기';
              styleClassName = '';
            }
          }

          return (
            <div className={`application-step-list__item ${styleClassName}`} key={order}>
              <div className="application-step-list__text">
                <Typography
                  className="inline"
                  type={TypographyType.B1}
                  color={ColorPaletteType.GRAY1}
                  text={`${order}. `}
                />
                &nbsp;
                <Typography className="inline" type={TypographyType.B1} color={ColorPaletteType.GRAY1} text={title} />
              </div>

              <div className="application-step-list__button-wrapper">
                <Button
                  className={`application-step-list__button ${styleClassName}`}
                  size={SizeType.SMALL}
                  status={StatusType.DEFAULT}
                  children={<Typography type={TypographyType.B1} color={ColorPaletteType.GRAY2} text={buttonText} />}
                  disabled={!isActiveStep}
                  onClick={() => {
                    if (isActiveStep) {
                      history.push(
                        `/applications/form/${selectedApplication.uuid}/typeform/${typeformId}/order/${finalOrOrder}`
                      );
                    }
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default ApplicationSteps;
