import styled from '@emotion/styled';
import { Color } from '../../../../style';

export const { gray, darkGray, lightGreen } = Color;

export const SidebarTop = styled.div`
  padding: 17px;
  width: 100%;
  margin-bottom: 10px;

  .current-course-name {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #452cdd;

    .current-course-name-icon {
      margin-right: 5px;
      font-weight: 700;
      color: #452cdd;
    }
  }

  .current-playlist-name {
    padding-top: 10px;
  }
  @media (max-width: 992px) {
    margin: 60px 0 10px;
  }
`;

export const SidebarContentItem = styled.div<{ isLastContent?: boolean }>`
  color: black;
  margin-left: 15px;
  padding: 16px 0px;
  border-left: ${props => (props.isLastContent ? '1px solid white' : `1px solid ${darkGray}`)};
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
  cursor: pointer;

  .content-iscompleted {
    flex: 0 0 auto;
    margin: -22px 0px 0px -6px;
  }

  .content-title {
    font-size: 15px;
    flex: 0 1 auto;
    margin: -22px 4px auto 14px;
    text-decoration: none;
    overflow: hidden;
    transition: all 0.2s ease 0s;
    color: #353535;
    font-weight: 400;
  }

  &:hover {
    font-weight: 800;
  }

  &.content-separator {
    color: #6e6e6e;
    font-weight: 600;
    border-bottom: 1px solid #b2bdd1;
    background-color: ${gray};
    padding: 2px 15px;
    font-size: 0.9em;
  }

  &.selected {
    font-weight: 800;
  }
`;

export const SidebarContentSeperator = styled.div`
  font-weight: 600;
  color: #667ba3;
  font-size: 0.9em;
  word-break: break-word;
  margin: 0px 0px 20px;
  padding: 10px 15px 4px;
  background-color: #f5f7f9;
  border-bottom: 1px solid #dfe4ec;
  width: 100%;
`;
