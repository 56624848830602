import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons'

export interface ISectionTitleBoldWrapper {
  width?: string;
}

const SectionTitleBoldWrapper = styled.div<ISectionTitleBoldWrapper>`
  margin: 50px 0;
  width: ${({ width }) => (width ? width : '100%')};

  span,
  .tooltip {
    font-weight: 600;
    font-size: 20px;
    color: black;
    padding-bottom: 10px;
  }

  .tooltip {
    margin-left: 6px;
    opacity: 0.3;
    font-weight: 500;
  }
`;

const SectionTitleBold: React.FC<{
  children: React.ReactNode;
  className?: string;
  description?: string;
}> = ({ children, className, description }) => (
  <SectionTitleBoldWrapper className={className}>
    <span>{children}</span>
    {description && (
      <Tooltip
        title={description}
        overlayStyle={{ wordBreak: 'keep-all', whiteSpace: 'pre-line' }}
      >
        <QuestionCircleOutlined className="tooltip" />
      </Tooltip>
    )}
  </SectionTitleBoldWrapper>
);

export default SectionTitleBold;
