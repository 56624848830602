import React from 'react';
import HighlightTitle from '../../atoms/Title/HighlightTitle';
import HighlightSubTitle from '../../atoms/Title/HighlightSubTitle';
import Description from '../../atoms/Description';
import CommonDivider from '../../atoms/Divider/CommonDivider';

const Message: React.FC<{
  title: string;
  subTitle: string;
  description?: string;
  dividerLength: number;
  width?: string;
}> = ({ title, subTitle, description, dividerLength, width }) => (
  <React.Fragment>
    <HighlightTitle title={title} />
    <CommonDivider length={dividerLength} />
    <HighlightSubTitle width={width}>{subTitle}</HighlightSubTitle>
    <Description width={width}>{description}</Description>
  </React.Fragment>
);

export default Message;
