import styled from '@emotion/styled';

export const TabPaneContainer = styled.div<{ height?: string }>`
  margin-top: 0px;
  height: ${({ height }) => (height ? height : 'auto')};
  overflow: scroll;
  border-style: solid;
  border-width: 1px;
  border-color: #b2bdd1;
  background-color: white;
`;
