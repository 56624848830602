import React from 'react';
import styled from '@emotion/styled';

import { ColorPalette } from '../../../styles/color';

type TableProps = {
  className?: string;
  columns: string[];
  rows: (string | React.ReactNode)[][];
};

const StyledTable = styled.table`
  margin-top: 10px;
  color: ${ColorPalette.Gray2};
  text-align: center;

  thead {
    tr {
      border-top: 1px solid ${ColorPalette.Gray4};

      th {
        padding: 11px;
        background-color: #f2f2f2;
        font-weight: 400;
      }
    }
  }

  tbody {
    tr {
      border-top: 1px solid ${ColorPalette.Gray4};

      &:last-child {
        border-bottom: 1px solid ${ColorPalette.Gray4};
      }

      td {
        padding: 11px;
      }
    }
  }
`;

const Table: React.FC<TableProps> = ({ className, columns, rows }) => {
  return (
    <StyledTable className={className}>
      <thead>
        <tr>
          {columns.map((column, index) => {
            return <th key={index}>{column}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => {
          return (
            <tr key={rowIndex}>
              {row.map((data, dataIndex) => {
                return <td key={dataIndex}>{data}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

export default Table;
