export const Course = {
  SEBFE: '프론트엔드 개발',
  SEBBE: '백엔드 개발',
  BEB: '블록체인',
  AIB: '인공지능',
  DOB: '데브옵스',
  GMB: '그로스마케팅',
  PMB: '프로덕트매니지먼트'
};

export type CardNewsType = {
  id: number;
  course: string;
  url: string;
  title: string;
  description: string;
  thumbnailURL: string;
};
