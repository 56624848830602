import React from 'react';
import styled from '@emotion/styled';

import { BasicButton } from '../common/BasicButton';

import type { TestingResult } from '../../apollo/coplit/type';

interface ICodeEditorSubmitModalCard {
  handleSubmitModalClose: () => void;
  submitCodeProblem: Function;
  testingResult?: TestingResult;
}

const SubmitErrorMessage = styled.div`
  margin: 20px 0px 40px 0px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
`;

const Wrapper = styled.div`
  .modal {
    margin: 20px 0px 50px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .item {
      font-size: 40px;
      font-family: sans-serif;
      font-weight: bolder;
      color: #455573;
      text-align: center;

      .item-nav {
        font-size: 14px;
        font-weight: 500;
        color: #667ba3;
      }
    }

    .item.passed {
      font-size: 40px;
      font-family: sans-serif;
      font-weight: bolder;
      color: #00b86a;
      text-align: center;

      .item-nav {
        font-size: 14px;
        font-weight: 500;
        color: #667ba3;
      }
    }

    .item.failed {
      font-size: 40px;
      font-family: sans-serif;
      font-weight: bolder;
      color: #ff360a;
      text-align: center;

      .item-nav {
        font-size: 14px;
        font-weight: 500;
        color: #667ba3;
      }
    }
  }

  .button-group {
    text-align: end;
  }
`;

export const CodeEditorSubmitModalCard: React.FC<ICodeEditorSubmitModalCard> = ({
  handleSubmitModalClose,
  submitCodeProblem,
  testingResult
}) => {
  const displaySubmitResult = (testingResult?: TestingResult) => {
    if (testingResult) {
      const { runtimeError, isInfinityLoop, contents } = testingResult;

      if (runtimeError) {
        return <SubmitErrorMessage>문법 오류가 있습니다. 코드를 다시 확인해주세요.</SubmitErrorMessage>;
      }

      if (isInfinityLoop) {
        return <SubmitErrorMessage>실행 시간을 초과하였습니다. 코드를 다시한번 확인해주세요.</SubmitErrorMessage>;
      }

      const totalTest = contents.length;
      const passedTest = contents.filter(result => result.isPassed).length;

      return (
        <div className="modal">
          <div className="item">
            <div>{`${totalTest}개`}</div>
            <div className="item-nav">전체 테스트</div>
          </div>

          <div className="item passed">
            <div>{`${passedTest}개`}</div>
            <div className="item-nav">통과한 테스트</div>
          </div>

          <div className="item failed">
            <div>{`${totalTest - passedTest}개`}</div>
            <div className="item-nav">실패한 테스트</div>
          </div>

          <div className="item">
            <div>{totalTest ? Math.floor((passedTest / totalTest) * 100) : 0}%</div>
            <div className="item-nav">정답률</div>
          </div>
        </div>
      );
    }

    return <SubmitErrorMessage>테스트를 먼저 실행해 주세요.</SubmitErrorMessage>;
  };

  return (
    <Wrapper>
      {displaySubmitResult(testingResult)}
      <div className="button-group">
        <BasicButton
          color="#F2F4F7"
          hoverColor="#DFE3EC"
          style={{
            margin: '0px 10px',
            fontSize: '15px',
            borderRadius: '6px',
            fontWeight: 500,
            padding: '10px 24px',
            height: 'auto'
          }}
          onClick={handleSubmitModalClose}
        >
          다시풀기
        </BasicButton>
        <BasicButton
          color="#452cdd"
          hoverColor="#3C2CA2"
          style={{ fontSize: '15px', borderRadius: '6px', fontWeight: 500, padding: '10px 24px', height: 'auto' }}
          onClick={() => submitCodeProblem()}
          disabled={!testingResult || testingResult.isInfinityLoop || !!testingResult.runtimeError}
        >
          제출하기
        </BasicButton>
      </div>
    </Wrapper>
  );
};
