import styled from '@emotion/styled';

const RequireMarkTag = styled.span`
  color: red;
  margin: 0px 5px;
`;

export const RequireMark = () => {
  return <RequireMarkTag>*</RequireMarkTag>;
};
