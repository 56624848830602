import React, { useState } from 'react';
import { RouteChildrenProps, useLocation } from 'react-router-dom';
import { Button, Input } from 'antd';
import { useCredential } from '../../contexts/credential';

import * as Templates from '../..//templates';

import styled from '@emotion/styled';
import { useMutation } from '@apollo/react-hooks';
import { LOGIN_IDENTITY_VERIFICATION_CODE_GQL } from '../../apollo/account/mutation';
import type { LoginIdentityVerificationCodeData, LoginIdentityVerificationCodeVars } from '../../apollo/account/type';
import { isEmpty } from 'lodash';
import { ColorPalette } from '../../styles/color';
import { makeToast } from '../../util/makeToast';

const IdentityVerficationPageWrapper = styled.div`
  max-width: 440px;
  background-color: #fff;
  border-radius: 44px;
  text-align: center;
  margin: 128px auto;
  padding: 32px 0 60px;

  .img__wrapper {
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto 14px;
  }
  .tit__wrapper {
    font-size: 20px;
    font-weight: 700;
    color: #455573;
  }
  .desc__wrapper {
    margin-top: 34px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #455573;
  }
  .txt__wrapper--notice {
    display: block;
    margin: 38px 0;
    font-size: 20px;
    font-weight: 700;
    color: #5e85e8;
  }

  .input__wrapper {
    display: block;
    margin: 0 auto 16px;
    width: 240px;
    height: 48px;
    border: 1px solid #b2bdd1;
    border-radius: 6px;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
  }

  .btn__wrapper {
    width: 240px;
    height: 48px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background-color: #452cdd;
  }
  .btn__wrapper--disabled {
    color: #c2c2c2;
    background-color: #f2f2f2;
  }
  @media (max-width: 600px) {
    background-color: #f5f7f9;
    margin: 60px auto;
  }
`;

const IdentityVerficationPage: React.FC<RouteChildrenProps> = props => {
  const history = props.history;
  const location = useLocation<{ uuid: string }>();

  const uuid = location.state.uuid;
  const { isLogin, login } = useCredential();
  const [code, setCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const changeCodeInput = (input: string) => {
    if (input.length > 6) {
      return;
    }
    input = input.replace(/[^A-Za-z0-9]/gi, '');
    setCode(input);
  };
  const pressEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleVerificationButtonClick();
    }
  };

  const resetCode = () => {
    setCode('');
  };

  const [urclassLoginIdentityVerificationCode] = useMutation<
    LoginIdentityVerificationCodeData,
    LoginIdentityVerificationCodeVars
  >(LOGIN_IDENTITY_VERIFICATION_CODE_GQL);

  const handleVerificationButtonClick = async () => {
    // TransportLayer Status
    setIsLoading(true);

    try {
      const { data } = await urclassLoginIdentityVerificationCode({ variables: { uuid, code } });

      if (data) {
        const { token: newToken } = data.urclassLoginIdentityVerificationCode;

        // 로그인 상태 관리
        login(newToken);

        // 사용자 출석 관리자(째깍이) 상태 관리
        makeToast({ type: 'success', content: '본인 인증에 성공 했습니다.' });

        return;
      }
    } catch (e: any) {
      setIsLoading(false);
      // 잘못된 접근인 경우
      if (
        e.message === 'invalid-identity-verification-access' ||
        e.message === 'not-found-identity-verification-code'
      ) {
        makeToast({ type: 'error', content: '잘못된 접근 입니다.' });
        history.push('/');
        return;
      }

      if (e.message === 'identity-verification-code-expired') {
        // 만료된 경우
        makeToast({ type: 'error', content: '인증 코드가 만료되었습니다. 재시도 해주세요.' });
        history.push('/');
        return;
      }

      // 잘못된 코드인 경우
      if (e.message === 'invalid-identity-verification-code') {
        makeToast({ type: 'error', content: '인증 코드가 잘못되었습니다.' });
        resetCode();
        return;
      }

      // 서버에러
      makeToast({ type: 'error', content: '문제가 발생했습니다. 관리자에게 문의해주세요.' });
      history.push('/');
    }
  };

  if (!uuid) {
    makeToast({ type: 'error', content: '잘못 된 접근입니다.' });
    history.push('/');
    return null;
  }

  if (isLogin) {
    history.push('/', {});
    return null;
  }

  return (
    <Templates.GeneralTemplate backgroundColor={ColorPalette.Gray8}>
      {{
        body: (
          <IdentityVerficationPageWrapper>
            <img
              className="img__wrapper"
              alt="알림톡 발송 이미지"
              src="https://codestates-urclass.s3.ap-northeast-2.amazonaws.com/common/img_message.png"
            />
            <strong className="tit__wrapper">
              카카오톡으로
              <br /> 인증 코드가 발송되었습니다.
            </strong>
            <p className="desc__wrapper">
              K-Digital Training 과정 수강을 위해 <br />
              매일 로그인 시, 본인 인증이 필요합니다.
              <br />
              카카오톡에서 인증 코드를 확인하고 아래에 입력해주세요.
            </p>
            <span className="txt__wrapper--notice">인증 코드는 10분간 유효합니다.</span>
            <Input
              type="text"
              autoFocus
              placeholder="인증번호 입력"
              className="input__wrapper"
              onKeyPress={e => {
                pressEnterKey(e);
              }}
              onChange={e => {
                changeCodeInput(e.target.value);
              }}
              value={code}
            />
            <Button
              className={isEmpty(code) || isLoading ? 'btn__wrapper btn__wrapper--disabled' : 'btn__wrapper'}
              disabled={isEmpty(code) || isLoading}
              onClick={handleVerificationButtonClick}
            >
              인증하기
            </Button>
          </IdentityVerficationPageWrapper>
        )
      }}
    </Templates.GeneralTemplate>
  );
};

export default IdentityVerficationPage;
