import { useCredential } from './../../../../contexts/credential';
import { useAttendanceStatus } from '../../contexts/AttendanceStatus';
import { useState, useEffect } from 'react';

export const useController = () => {
  const { isLogin } = useCredential();
  const { fetchMyLearningStatus, setIsDataErr } = useAttendanceStatus();

  const [isQrPopupOpen, setIsQrPopupOpen] = useState<boolean>(false);

  // handler
  const runAtCheckInTimeLoggingIn = () => {
    // 로그인 중 08:30am에 팝업 띄우기
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth();
    let date = now.getDate();
    let millisecondUntilCheckInTime = Number(new Date(year, month, date, 8, 30, 0, 0)) - Number(now);
    if (millisecondUntilCheckInTime < 0) {
      millisecondUntilCheckInTime += 86400000; // it's after 8:30am, try 8:30am tomorrow.
    }
    setTimeout(function () {
      setIsQrPopupOpen(true);
    }, millisecondUntilCheckInTime);
  };

  const runAtCheckOutTimeLoggingIn = () => {
    // 로그인 중 18:00pm에 팝업 띄우기
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth();
    let date = now.getDate();
    let millisecondUntilCheckInTime = Number(new Date(year, month, date, 18, 0, 0, 0)) - Number(now);
    if (millisecondUntilCheckInTime < 0) {
      millisecondUntilCheckInTime += 86400000; // it's after 18:00pm, try 18:00pm tomorrow.
    }
    setTimeout(() => {
      setIsQrPopupOpen(true);
    }, millisecondUntilCheckInTime);
  };

  const runAtCheckInOutTimeAtLogIn = () => {
    // 0830 ~ 0910 / 1800 ~ 1930 로그인 시 팝업 띄우기
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();

    if (
      (currentHour === 8 && currentMinute >= 30) ||
      (currentHour > 8 && currentHour < 9) ||
      (currentHour === 9 && currentMinute <= 10)
    ) {
      return true;
    }

    if (
      (currentHour === 18 && currentMinute >= 0) ||
      (currentHour > 18 && currentHour < 19) ||
      (currentHour === 19 && currentMinute <= 30)
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    try {
      if (isLogin) {
        runAtCheckInTimeLoggingIn();
        runAtCheckOutTimeLoggingIn();
        setInterval(runAtCheckInTimeLoggingIn, 86400000);
        setInterval(runAtCheckOutTimeLoggingIn, 86400000);
      }
    } catch {
      console.log('error');
    }
  }, []);

  useEffect(() => {
    try {
      if (isLogin) {
        fetchMyLearningStatus();

        if (runAtCheckInOutTimeAtLogIn()) {
          setIsQrPopupOpen(true);
        }
      }
    } catch {
      setIsDataErr(true);
    }
  }, [isLogin]);

  return {
    isQrPopupOpen,
    setIsQrPopupOpen
  };
};
