import React from 'react';
import moment from 'moment';
import { Result } from 'antd';
import styled from '@emotion/styled';

import { numberFormatter } from '../../../util';

interface IVbankResultProps {
  vbankName?: string;
  vbankNum?: string;
  vbankHolder?: string;
  vbankDate?: Date;
  totalAmount?: number;
  orderName: string;
}

export const VbankResult: React.FC<IVbankResultProps> = ({
  vbankName,
  vbankNum,
  vbankHolder,
  vbankDate,
  totalAmount,
  orderName
}) => {
  if (!vbankName || !vbankNum || !vbankHolder || !vbankDate || !totalAmount) {
    return (
      <Result
        status="error"
        title="가상계좌 정보 누락"
        subTitle="communication@codestates.com 으로 연락 주세요"
        extra={<div>가상 계좌 입금 정보가 누락 되었습니다. 관리자에게 문의 주세요.</div>}
      />
    );
  }
  return (
    <VbankResultWrapper
      status="info"
      title="입금 대기"
      subTitle="가상계좌 정보가 발행 되었습니다. 결제 후 지원과정은 마이페이지의 지원하기 버튼을 눌러주세요"
    >
      <div className="paragraph">
        <div className="gray-text">입금정보</div>
        <div className="black-text">{`${vbankName} ${vbankNum}`}</div>
        <div className="gray-text">{`예금주:${vbankHolder}`}</div>
      </div>
      <div className="paragraph">
        <div className="gray-text">구매 코스</div>
        <div className="black-text">{orderName}</div>
      </div>
      <div className="paragraph">
        <div className="gray-text">결제 금액</div>
        <div className="black-text">{numberFormatter(totalAmount)}원</div>
      </div>
      <div className="paragraph">
        <div className="gray-text">입금기한</div>
        <div className="black-text">{`${moment(vbankDate).format(
          'YYYY-MM-DD HH:mm'
        )} 까지`}</div>
      </div>
      <div className="paragraph">
        <div className="gray-text sub-description">
          (미입금 시, 해당 지원은 자동 취소되며 한정 코스의 경우 가상 계좌
          재주문이 제한될 수 있습니다.)
        </div>
      </div>
    </VbankResultWrapper>
  );
};

const VbankResultWrapper = styled(Result)`
  .paragraph {
    margin: 10px;
  }
  .gray-text {
    color: rgb(0, 0, 0, 0.45);
  }
  .black-text {
    color: rgb(0, 0, 0, 0.85);
  }
  .sub-description {
    font-size: 12px;
  }
`;
