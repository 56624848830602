export const ApplicationAnswerStatusType = {
  CREATED: 'CREATED',
  IN_PROGRESS: 'IN_PROGRESS',
  TEMPORARY_SAVED: 'TEMPORARY_SAVED',
  TERMINATED: 'TERMINATED',
  CANCELLED: 'CANCELLED',
  SUBMITTED: 'SUBMITTED',
  UNSUBMITTED: 'UNSUBMITTED',
  RECREATED: 'RECREATED'
} as const;

export const StepListItem = {
  SUBMITTED: 'SUBMITTED',
  ACTIVE: 'ACTIVE',
  EDITABLE: 'EDITABLE',
  NONE: 'NONE',
  IN_PROGRESS: 'IN_PROGRESS'
} as const;
export type StepListItemType = typeof StepListItem[keyof typeof StepListItem];
