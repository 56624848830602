import styled from '@emotion/styled';

export const BasicContainer = styled.div<{ height?: string }>`
  height: ${({ height }) => (height ? height : 'auto')};
  border-style: solid;
  border-width: 1px;
  border-color: #dfe4ec;
  border-radius: 10px;
  padding: 20px 20px;
  background-color: white;
`;
