export enum CodeProblemSubmissionStatus {
  NONE = 'NONE',
  ONGOING = 'ONGOING',
  COMPLETED = 'COMPLETED'
}

export type Classroom = {
  id: number;
  title: string;
  description: string;
  imageURL: string;
  totalCodeProblemCount: number;
  restCodeProblemCount: number;
  dueDate?: Date;
};

export type ClassroomFilter = {
  isDeadline: boolean;
};

export type CodeProblem = {
  id: number;
  title: string;
  uuid: string;
  status: CodeProblemSubmissionStatus;
  updatedAt: Date;
  isPassed: boolean;
  passedCount?: number;
  failedCount?: number;
};

export type CodeProblemDetail = {
  id: number;
  classroomId: number;
  uuid: string;
  previousCodeProblemUuid?: string;
  nextCodeProblemUuid?: string;
  language: string;
  title: string;
  description: string;
  templateCode: string;
  myCode?: string;
  isPassed: boolean;
  isSubmitted: boolean;
  submittedAt?: Date;
  status: CodeProblemSubmissionStatus;
};

export type FieldTestingDetailResult = {
  isPassed: boolean;
  title: string;
  test?: string;
  detail?: string;
};

export type CodeProblemsFilter = {
  classroomId?: number;
  isPassed?: boolean;
  status?: CodeProblemSubmissionStatus;
};

// query

export type MyClassroomsStatusData = {
  urclassMyClassroomsStatus: {
    totalCodeProblemCount: number;
    restCodeProblemCount: number;
  };
};

export type MyClassroomsVars = {
  filter?: ClassroomFilter;
};

export type MyClassroomsData = {
  urclassMyClassrooms: Classroom[];
};

export type MyClassroomStatusVars = {
  classroomId: number;
};

export type MyClassroomStatusData = {
  urclassMyClassroomStatus: Classroom;
};

export type MyCodeProblemsVars = {
  page?: number;
  perPage?: number;
  filter?: CodeProblemsFilter;
};

export type MyCodeProblemsData = {
  urclassMyCodeProblems: {
    total: number;
    items: CodeProblem[];
  };
};

export type CodeProblemVars = {
  codeProblemUuid: string;
};

export type CodeProblemData = {
  urclassMyCodeProblem: CodeProblemDetail;
};

// mutation

export type StartCodeProblemVars = {
  id: number;
};

export type StartCodeProblemData = {
  urclassStartCodeProblem: boolean;
};

export type SubmitCodeProblemVars = {
  id: number;
};

export type SubmitCodeProblemData = {
  urclassSubmitCodeProblem: boolean;
};

export type CodeProblemReferenceVars = {
  id: number;
};

export type CodeProblemReferenceData = {
  urclassCodeProblemReference: string;
};

export type RunCodeProblemTestVars = {
  codeProblemId: number;
  userCode: string;
};

export type TestingResult = {
  isAllPassed: boolean;
  testingCount: number;
  runtimeError?: string;
  isInfinityLoop: boolean;
  contents: FieldTestingDetailResult[];
};

export type RunCodeProblemTestData = {
  urclassRunCodeProblemTest: TestingResult;
};
