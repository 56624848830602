import gql from 'graphql-tag';

export const START_CODE_PROBLEM_GQL = gql`
  mutation startCodeProblem($id: Int!) {
    urclassStartCodeProblem(id: $id)
  }
`;

export const SUBMIT_CODE_PROBLEM_GQL = gql`
  mutation submitCodeProblem($id: Int!) {
    urclassSubmitCodeProblem(id: $id)
  }
`;

export const CODE_PROBLEM_REFERENCE_GQL = gql`
  mutation codeProblemReference($id: Int!) {
    urclassCodeProblemReference(id: $id)
  }
`;

export const RUN_CODE_PROBLEM_TEST_GQL = gql`
  mutation runCodeProblemTest($codeProblemId: Int!, $userCode: String!) {
    urclassRunCodeProblemTest(
      codeProblemId: $codeProblemId
      userCode: $userCode
    ) {
      isAllPassed
      testingCount
      runtimeError
      isInfinityLoop
      contents {
        isPassed
        title
        test
        detail
      }
    }
  }
`;
