import React from 'react';
import { message } from 'antd';
import ToastSuccessIco from '../assets/svg/ico-toast-success.svg';
import ToastErrorIco from '../assets/svg/ico-toast-error.svg';
import ToastInfoIco from '../assets/svg/ico-toast-info.svg';
import CloseIcon from '../assets/svg/ico-close-toast.svg';

type ToastInput = {
  content: React.ReactNode | string;
  subContent?: string;
  type?: ToastType;
};

type ToastType = 'success' | 'error' | 'info';

const getToastKey = (toastType: ToastType) => {
  return `${toastType.toUpperCase()}_TOAST`;
};

const ICONS: Record<ToastType, string> = {
  success: ToastSuccessIco,
  error: ToastErrorIco,
  info: ToastInfoIco
};

export const makeToast = ({ content, type = 'info' }: ToastInput) => {
  return message.info({
    content: React.createElement(
      'span',
      null,
      content,
      React.createElement(
        'button',
        {
          onClick: () => {
            message.destroy(getToastKey(type));
          },
          className: 'btn__checkin--close'
        },
        React.createElement('img', { src: CloseIcon, type: 'button', alt: 'close icon', className: 'ico__close' })
      )
    ),
    key: getToastKey(type),
    className: `alert__toast alert__${type}`,
    icon: React.createElement('img', {
      src: ICONS[type],
      className: 'ico__toast'
    })
  });
};
