import { useHistory } from 'react-router-dom';
import { useCredential } from '../../contexts/credential';
import React, { useEffect, useState } from 'react';
import { useQuery as apolloUseQuery } from '@apollo/client/react/hooks/useQuery';
import { PaymentType, ProductData, ProductVars } from '../../apollo/product/type';
import { PRODUCT_GQL } from '../../apollo/product/query';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '../../query/queryKeys';
import {
  changeApplicationState,
  checkApplicationAnswersSubmitted,
  getApplicationData,
  getSlugUrlRequest
} from './request';
import { ApplyServiceType } from '../../apollo/mypage/type';
import { parseSlugUrl, store } from '../../util';
import { useMutation } from '@apollo/react-hooks';
import {
  ApprovePaymentOrderData,
  ApprovePaymentOrderVars,
  PaymentOrderstatus,
  PayMethod
} from '../../apollo/payment/type';
import { APPROVE_PAYMENT_ORDER_GQL } from '../../apollo/payment/mutation';
import { ROLLING_BASE_PRODUCT_SLUG_URLS } from '../../util/constant';
import { Modal } from 'antd';
import { sleep } from '../../util/sleep';
import { makeToast } from '../../util/makeToast';
import { ApplicationAnswerStatusType } from '../detailApplication/type';
import { getServerError } from '../../util/errors/serverErrors';
import { ApplyErrorCode } from '../apply/type';

export const useController = () => {
  let to: string = '';
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const { profile } = useCredential();

  const paymentKey = params.get('paymentKey') || '';
  const orderId = params.get('orderId') || '';
  const amount = params.get('amount') || '';
  const repay = params.get('repay') || '';
  const productUuid = params.get('productUuid') || '';

  const [isPaid, setIsPaid] = React.useState<boolean>(false);
  const [isUpfront, setIsUpfront] = useState<boolean>(false);

  const queryParams = {
    getSlugUrlByUuidParams: {
      productUuid
    },
    getReviveByUuidParams: {
      productUuid,
      userUuid: profile?.uuid || ''
    }
  };

  const {
    data: productData,
    loading: productLoading,
    error: productError
  } = apolloUseQuery<ProductData, ProductVars>(PRODUCT_GQL, {
    variables: { uuid: productUuid },
    skip: !productUuid
  });

  const { data: slugUrlData } = useQuery(
    queryKeys.APPLY_PRODUCT_SLUG_URL_BY_UUID(queryParams.getSlugUrlByUuidParams),
    () => getSlugUrlRequest(queryParams.getSlugUrlByUuidParams),
    {
      enabled: productData?.urclassProduct.applyServiceType === ApplyServiceType.ADMISSION,
      onSuccess: slugUrlData => {
        const _course = parseSlugUrl(slugUrlData?.slugUrl).course;
      }
    }
  );

  const [urclassApprovePaymentOrder, { loading, data, error }] = useMutation<
    ApprovePaymentOrderData,
    ApprovePaymentOrderVars
  >(APPROVE_PAYMENT_ORDER_GQL, {
    variables: {
      input: { paymentKey, amount: Number(amount), merchantUID: orderId, repay: repay === 'true' }
    }
  });

  const handleApplicationStateChange = async (applicationUuid: string) => {
    if (!slugUrlData) return;
    const modalContent = `${
      ROLLING_BASE_PRODUCT_SLUG_URLS.includes(slugUrlData.slugUrl || '')
        ? '취소한 지원서를 복구합니다.'
        : '이어서 지원서 작성을 진행하시겠어요?'
    }`;

    const modalOkText = `${
      ROLLING_BASE_PRODUCT_SLUG_URLS.includes(slugUrlData.slugUrl || '') ? '확인' : '지원서 작성'
    }`;

    const isApplicationAnswersSubmitted = await checkApplicationAnswersSubmitted(profile?.uuid, applicationUuid);

    return Modal.warning({
      title: '해당 코스에 지원 취소한 기록이 있어요.',
      content: modalContent,
      okText: modalOkText,
      onOk: async () => {
        try {
          const isRollingBaseProduct = ROLLING_BASE_PRODUCT_SLUG_URLS.includes(slugUrlData.slugUrl || '');

          const { data: reviveApplicationData } = await changeApplicationState({
            applicationUuid,
            userUuid: profile?.uuid || '',
            changeTo: isApplicationAnswersSubmitted ? 'COMPLETED' : 'IN_PROGRESS'
          });
          if (reviveApplicationData) {
            await sleep(1500);
            const pathname = isRollingBaseProduct
              ? '/mypage'
              : `/applications/${slugUrlData.slugUrl}/list/${applicationUuid}`;
            history.push({
              pathname: `${pathname}`,
              state: { revive: true }
            });
          }
        } catch (err) {
          handleApplyError(err);
        }
      }
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const paymentOrder = await urclassApprovePaymentOrder();
        if (paymentOrder) {
          setIsPaid(paymentOrder.data?.urclassApprovePaymentOrder.status === PaymentOrderstatus.PAID);
        }
      } catch (err) {
        handleApplyError(err);
      }
    })();
  }, []);


  useEffect(() => {
    if (productData && productData.urclassProduct.paymentModels.length) {
      setIsUpfront(productData.urclassProduct.paymentModels[0].paymentType === PaymentType.UPFRONT);
    }
  }, [productData]);


  /** @description
   * upfront + 카드 결제 성공시 지원서 성태 변경 로직
   * 계좌이체는 계좌 생성일 때 성공으로 간주함. 상태변경은 서버에서 해주고 있음. */
  useEffect(() => {
    const isPayMethodIsVbank = data?.urclassApprovePaymentOrder.payMethod === PayMethod.VBANK;

    if (isUpfront && isPaid && profile && !isPayMethodIsVbank) {
      try {
        (async () => {
          const applicationData = await getApplicationData({ userUuid: profile?.uuid, productUuid });

          if (
            applicationData &&
            applicationData.uuid &&
            applicationData.applyingStatus === ApplicationAnswerStatusType.CANCELLED
          ) {
            await handleApplicationStateChange(applicationData.uuid);
          }
        })();
      } catch (err) {
        handleApplyError(err);
      }
    }
  }, [data, isUpfront, profile, isPaid]);

  if (error) {
    const errMessage = getServerError(error.message);
    const errRedirectParams = new URLSearchParams();

    errRedirectParams.set('code', errMessage.title);
    errRedirectParams.set('message', errMessage.message);

    to = `/payment/fail?${errRedirectParams.toString()}`;
  }

  if (data && data.urclassApprovePaymentOrder) {
    const redirectParams = new URLSearchParams();

    const paymentOrder = data.urclassApprovePaymentOrder;

    store.adsMediaType.remove();
    store.applyUuid.remove();
    store.applyProductName.remove();
    store.paidProgramType.remove();

    if (paymentOrder.payMethod === PayMethod.CARD) {
      redirectParams.set('method', 'card');
      redirectParams.set('paid_amount', paymentOrder.totalAmount.toString());
    } else if (paymentOrder.payMethod === PayMethod.VBANK) {
      redirectParams.set('method', 'vbank');
      redirectParams.set('vbank_name', paymentOrder.vbankName!);
      redirectParams.set('vbank_num', paymentOrder.vbankNum!);
      redirectParams.set('vbank_holder', paymentOrder.vbankHolder!);
      redirectParams.set('vbank_date', paymentOrder.vbankDate!.toString());
      redirectParams.set('paid_amount', paymentOrder.totalAmount!.toString());
    }
    redirectParams.set('is_success', 'true');

    to = `/apply/result/${paymentOrder.productUuid}?${redirectParams.toString()}`;
  }

  const handleApplyError = (e: unknown) => {
    const error = e as Error;
    const errorCode = error.message;

    switch (errorCode) {
      case ApplyErrorCode.ALREADY_APPLIED_ANOTHER_PRODUCT:
        return Modal.confirm({
          title: '이미 지원 중인 과정이 있습니다.',
          content: '마이페이지에서 지원 중인 과정을 취소한 후 다시 지원해주세요 .',
          okText: '마이페이지 가기',
          onOk: () => {
            history.push('/mypage');
          },
          cancelText: '닫기'
        });

      case ApplyErrorCode.ALREADY_APPLIED:
        makeToast({ type: 'success', content: '이미 진행중인 단계가 있습니다. 확인해 주세요.' });
        history.push(`/mypage`);
        break;

      default:
        makeToast({ type: 'error', content: '결제 진행에 문제가 있습니다.' });
    }
  };


  return {
    data,
    isUpfront,
    error,
    to
  };
};