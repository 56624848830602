import React from 'react';
import styled from '@emotion/styled';

interface IDescriptionWrapper {
  width?: string;
}

interface IDescription {
  children: React.ReactNode;
  className?: string;
  width?: string;
}

const DescriptionWrapper = styled.div<IDescriptionWrapper>`
  font-size: 17px;
  line-height: 28px;
  color: #707070;
  margin: 30px 0px;
  width: ${({ width }) => (width ? width : '570px')};

  @media screen and (max-width: 785px) {
    width: 100%;
  }
`;

const Description: React.FC<IDescription> = ({ children, className, width }) => (
  <DescriptionWrapper width={width} className={className}>
    {children}
  </DescriptionWrapper>
);

export default Description;
