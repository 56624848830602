// TODO : 전역으로 관리하면 좋을 듯.
import { isDesktop, isMobile } from 'react-device-detect';

export const detectDevice = () => {
  if (isDesktop) {
    return 'PC';
  } else if (isMobile) {
    return 'Mobile';
  } else return 'Tablet';
};
