/* eslint-disable jsx-a11y/anchor-is-valid */
// JobProfileForm page 2
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Typography, Select, Table, Input, Checkbox, DatePicker, Button, Divider, Radio } from 'antd';
import {
  ActivityCategory,
  ActivityDetail,
  CareerStatus,
  CertificateDegree,
  CollegeMajorData,
  EducationDetail,
  ExperienceDetail,
  HighSchoolMajorData,
  LanguageDetail,
  LanguageSkill,
  LanguageSkillData,
  LanguageSkillLevel,
  Major
} from '../apollo/jobs/type';
import {
  convertActivityCategoryToDisplayName,
  convertCertificateDegreeToDisplayName,
  convertLanguageSkillLevelToDisplayName
} from '../utils';
import { CheckCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { COLLEGE_MAJOR_GQL, HIGH_SCHOOL_MAJOR_GQL, LANGUAGE_SKILL_GQL } from '../apollo/jobs/query';
import { isNil } from 'lodash';
import moment from 'moment';
import { RequireMark } from '../../../../components/atoms/RequireMark';
import { StyledButton } from './JobProfileForm';
import { makeToast } from '../../../../util/makeToast';

const StyledEmploymentOptionalForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  section {
    padding-left: 30px;
    padding-right: 30px;

    .title {
      display: flex;
    }

    .content {
      width: 100%;

      .career-status-radio {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .sub-title {
        display: flex;
      }

      .table {
        margin-bottom: 20px;
      }

      .salary {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 20px;

        .salary__input {
          width: 150px;
        }
      }

      .input__group {
        display: flex;
        flex-direction: column;

        margin-top: 10px;
        margin-bottom: 10px;

        .input {
          max-width: 800px;
          margin-bottom: 10px;
        }

        .flex {
          display: flex;
          max-width: 50%;
          margin-bottom: 10px;

          gap: 5px;

          .sub-flex {
            display: flex;
            flex-direction: column;
          }
        }

        .end-container {
          display: flex;
          gap: 10px;
          align-items: center;
        }

        button {
          width: 100px;
          margin-top: 20px;
        }
      }
    }
  }
`;

type EmploymentOptionalFormType = {
  careerStatus?: CareerStatus;
  handleCareerStatus: (careerStatus: CareerStatus) => void;
  lastExperienceSalary?: number;
  isLastExperienceSalaryPublic?: boolean;
  handleLastExperienceSalary: (event: React.ChangeEvent<HTMLInputElement> | undefined) => void;
  handleIsLastExperienceSalaryPublic: (checked: boolean) => void;
  educationDetails: EducationDetail[];
  languageDetails: LanguageDetail[];
  activityDetails: ActivityDetail[];
  experienceDetails: ExperienceDetail[];
  changeEducationDetails: (educationDetails: EducationDetail[]) => void;
  changeLanguageDetails: (languageDetails: LanguageDetail[]) => void;
  changeActivityDetails: (actvityDetails: ActivityDetail[]) => void;
  changeExperienceDetails: (experienceDetails: ExperienceDetail[]) => void;

  /* 페이지 이동 */
  handleChangeNextPage: () => void;
  handleChangePreviousPage: () => void;
};
const EmploymentOptionalForm: React.FC<EmploymentOptionalFormType> = ({
  careerStatus,
  handleCareerStatus,
  lastExperienceSalary,
  isLastExperienceSalaryPublic,
  handleLastExperienceSalary,
  handleIsLastExperienceSalaryPublic,
  educationDetails,
  languageDetails,
  activityDetails,
  experienceDetails,
  changeEducationDetails,
  changeLanguageDetails,
  changeActivityDetails,
  changeExperienceDetails,
  handleChangeNextPage,
  handleChangePreviousPage
}) => {
  /* Form 내부 상태 관리 */
  const { data: highSchoolMajorsData } = useQuery<HighSchoolMajorData>(HIGH_SCHOOL_MAJOR_GQL);
  const { data: collegeSchoolMajorsData } = useQuery<CollegeMajorData>(COLLEGE_MAJOR_GQL);

  /* 학력 */
  const [certificateDegreeInput, setCertificateDegreeInput] = useState<CertificateDegree | undefined>();
  const [schoolNameInput, setSchoolNameInput] = useState<string>('');
  const [majorStandardId, setMajorStandardId] = useState<number | undefined>();
  const [majorStandardName, setMajorStandardName] = useState<string | undefined>();
  const [majorNameInput, setMajorNameInput] = useState<string>('');
  const [eduStartAtInput, setEduStartAtInput] = useState<moment.Moment | null>(null);
  const [eduCompletionInput, setEduCompletionEndAtInput] = useState<moment.Moment | null>(null);
  const [isAttendingInput, setIsAttendingInput] = useState<boolean>(false);
  const [isLastEducationInput, setIsLastEducationInput] = useState<boolean>(false);

  /* options */
  const [majorOptions, setMajorOptions] = useState<Major[]>([]);

  /* action */
  const resetEduForm = () => {
    setCertificateDegreeInput(undefined);
    setSchoolNameInput('');
    setMajorStandardId(undefined);
    setMajorStandardName(undefined);
    setMajorNameInput('');
    setEduStartAtInput(null);
    setEduCompletionEndAtInput(null);
    setIsAttendingInput(false);
    setIsLastEducationInput(false);
  };

  const isValidEducationInput = () => {
    // 구분 / 학교명 / 전공 구분 / 입학일 은 필수로 입력되어야합니다.
    if (!certificateDegreeInput || !schoolNameInput || !majorStandardId || !majorStandardName || !eduStartAtInput) {
      return false;
    }

    // 재학중이 아니면, 졸업일이 입력되어야합니다.
    if (!isAttendingInput) {
      if (!eduCompletionInput) {
        return false;
      }
    }

    switch (certificateDegreeInput) {
      case CertificateDegree.HIGH_SCHOOL:
        return true;
      default:
        if (!majorNameInput) {
          return false;
        }
        return true;
    }
  };
  const handleAddEducationDetail = () => {
    const isValid = isValidEducationInput();

    if (educationDetails.filter(edu => edu.isLastEducation).length >= 1 && isLastEducationInput) {
      makeToast({
        type: 'error',
        content: '최종학력이 이미 등록되어 있습니다. 잘못 등록된 경우, 삭제 후 재 등록을 시도해 주세요.'
      });
      return;
    }

    if (!isValid) {
      return;
    } else {
      changeEducationDetails([
        ...educationDetails,
        {
          majorName: majorNameInput,
          certificateDegree: certificateDegreeInput!,
          isAttending: isAttendingInput,
          isLastEducation: isLastEducationInput,
          startAt: eduStartAtInput!.toDate(),
          completionAt: eduCompletionInput?.toDate(),
          schoolName: schoolNameInput,
          major: {
            id: majorStandardId!,
            name: majorStandardName!
          }
        }
      ]);
      resetEduForm();

      makeToast({
        type: 'success',
        content: '추가되었습니다.'
      });
    }
  };

  useEffect(() => {
    if (certificateDegreeInput === CertificateDegree.HIGH_SCHOOL) {
      setMajorOptions(highSchoolMajorsData?.urclassHighSchoolMajors ?? []);
      setMajorNameInput('');
    } else {
      setMajorOptions(collegeSchoolMajorsData?.urclassCollegeMajors ?? []);
      setMajorNameInput('');
    }
  }, [certificateDegreeInput]);

  /* 내부 Form 상태 */
  const { data: languageSkillsData } = useQuery<LanguageSkillData>(LANGUAGE_SKILL_GQL);

  /* 외국어 */
  const [languageIdInput, setLanguageIdInput] = useState<number | undefined>();
  const [languageNameInput, setLanguageNameInput] = useState<string | undefined>();
  const [languageSkillLevelInput, setLanguageSkillLevelInput] = useState<LanguageSkillLevel | undefined>();

  /* options */
  const [languageOptions, setLanguageOptions] = useState<LanguageSkill[]>([]);
  const [languageAddOptionName, setLanguageAddOptionName] = useState<string | undefined>();

  /* actions */
  const resetLngForm = () => {
    setLanguageIdInput(undefined);
    setLanguageNameInput(undefined);
    setLanguageSkillLevelInput(undefined);
  };

  useEffect(() => {
    if (languageSkillsData) {
      setLanguageOptions(languageSkillsData.urclassLanguageSkills);
    }
  }, [languageSkillsData]);

  const isValidLanguageSkillInput = () => {
    if (!languageNameInput || !languageIdInput || !languageSkillLevelInput) {
      return false;
    }

    return true;
  };

  const handleAddLanguageDetail = () => {
    const isValid = isValidLanguageSkillInput();

    if (isValid) {
      changeLanguageDetails([
        ...languageDetails,
        {
          id: languageIdInput!,
          name: languageNameInput!,
          languageSkillLevel: languageSkillLevelInput!
        }
      ]);
      resetLngForm();
      makeToast({
        type: 'success',
        content: '추가되었습니다.'
      });
    } else {
      makeToast({ type: 'error', content: '외국어 정보가 모두 입력되었는지 확인해주세요.' });
    }
  };

  /* 활동 */
  const [activityCategoryInput, setActivityCategoryInput] = useState<ActivityCategory | undefined>();
  const [activityName, setActivityName] = useState<string | undefined>();
  const [activityDescription, setActivityDescription] = useState<string | undefined>();

  /* placeholder */
  const getActivityNamePlaceholder = () => {
    switch (activityCategoryInput) {
      case ActivityCategory.CERTIFICATION:
        return 'MOS Master';
      case ActivityCategory.JOB_EXPERIENCE:
        return '우리동네 아지트 프로젝트';
      case ActivityCategory.OTHER_EDUCATION:
        return '0000 파이썬 데이터사이언스 기초 과정';
      case ActivityCategory.PRIZE:
        return '0000 대학생 마케팅 공모전 금상';
    }
  };
  const getActivityDescriptionPlaceholder = () => {
    switch (activityCategoryInput) {
      case ActivityCategory.CERTIFICATION:
        return `마이크로소프트 오피스 활용능력 시험\nWord/Excel/PowerPoint/Outlook`;
      case ActivityCategory.JOB_EXPERIENCE:
        return '동네 기반의 공간 추천 서비스 기획 및 개발';
      case ActivityCategory.OTHER_EDUCATION:
        return '데이터사이언스 집중 교육 과정 수료(6주)';
      case ActivityCategory.PRIZE:
        return '0000 제품 리브랜딩 및 마케팅 콘텐츠 제작';
    }
  };

  /* actions */
  const resetActivityForm = () => {
    setActivityCategoryInput(undefined);
    setActivityName(undefined);
    setActivityDescription(undefined);
  };

  const isValidActivityInput = () => {
    if (!activityCategoryInput || !activityName || !activityDescription) {
      return false;
    }

    return true;
  };

  const handleAddActivityCategoryDetail = () => {
    const isValid = isValidActivityInput();

    if (isValid) {
      changeActivityDetails([
        ...activityDetails,
        {
          name: activityName!,
          description: activityDescription!,
          category: activityCategoryInput!
        }
      ]);
      resetActivityForm();
      makeToast({
        type: 'success',
        content: '추가되었습니다.'
      });
    } else {
      makeToast({ type: 'error', content: '활동 정보가 모두 입력되었는지 확인해주세요.' });
    }
  };

  /* 경력 */

  const [companyNameInput, setCompanyNameInput] = useState<string | undefined>();
  const [jobTitleInput, setJobTitleInput] = useState<string | undefined>();
  const [expStartAtInput, setExpStartAtInput] = useState<moment.Moment | null>(null);
  const [expEndAtInput, setExpEndAtInput] = useState<moment.Moment | null>(null);
  const [isInServiceInput, setIsInServiceInput] = useState<boolean>(false);

  /* actions */
  const resetExpForm = () => {
    setCompanyNameInput(undefined);
    setJobTitleInput(undefined);
    setExpStartAtInput(null);
    setExpEndAtInput(null);
    setIsInServiceInput(false);
  };

  const isValidExperienceInput = () => {
    if (!companyNameInput || !jobTitleInput || !expStartAtInput) {
      return false;
    }

    if (!isInServiceInput) {
      if (!expEndAtInput) {
        return false;
      }
    }
    return true;
  };

  const handleAddExperienceDetail = () => {
    const isValid = isValidExperienceInput();

    if (isValid) {
      changeExperienceDetails([
        ...experienceDetails,
        {
          jobTitle: jobTitleInput!,
          companyName: companyNameInput!,
          isInService: isInServiceInput!,
          startAt: expStartAtInput!.toDate(),
          endAt: expEndAtInput?.toDate()
        }
      ]);
      resetExpForm();
      makeToast({
        type: 'success',
        content: '추가되었습니다.'
      });
    } else {
      makeToast({ type: 'error', content: '경력 정보가 모두 입력되었는지 확인해주세요.' });
    }
  };

  const isAvailableNextPage =
    careerStatus !== CareerStatus.UNKNOWN && educationDetails.filter(edu => edu.isLastEducation).length === 1;

  return (
    <StyledEmploymentOptionalForm>
      <section>
        <div className="title">
          <Typography.Title level={4}>학력</Typography.Title>
        </div>

        <div className="content">
          <Table
            className="table"
            pagination={false}
            bordered
            dataSource={educationDetails}
            columns={[
              {
                title: '구분',
                dataIndex: 'certificateDegree',
                render: value => <span>{convertCertificateDegreeToDisplayName(value)}</span>
              },
              {
                title: '학교 명',
                dataIndex: 'schoolName'
              },
              {
                title: '전공 구분',
                render: (value, record) => <span>{record.major.name}</span>
              },
              {
                title: '전공 명',
                dataIndex: 'majorName'
              },
              {
                title: '입학',
                dataIndex: 'startAt',
                render: value => <span>{moment(value).format('YYYY-MM-DD')}</span>
              },
              {
                title: '졸업',
                dataIndex: 'completionAt',
                render: (value, record) => (
                  <span>{record.isAttending || !value ? '-' : moment(value).format('YYYY-MM-DD')}</span>
                )
              },
              {
                title: '재학중',
                dataIndex: 'isAttending',
                render: value => <span>{value ? '재학중' : '졸업'}</span>
              },
              {
                title: '최종학력 여부',
                dataIndex: 'isLastEducation',
                align: 'center',
                render: value => <span>{!!value ? <CheckCircleTwoTone /> : '-'}</span>
              },
              {
                align: 'center',
                render: (value: EducationDetail, record, index) => (
                  <a
                    role="button"
                    onClick={() => {
                      changeEducationDetails(educationDetails.filter((edu, idx) => idx !== index));
                    }}
                  >
                    삭제
                  </a>
                )
              }
            ]}
          />
          <Typography.Text type="secondary">
            <RequireMark />
            최종 학력은 필수로 입력해주세요.
          </Typography.Text>

          <div className="input__group">
            <div className="flex">
              <div className="sub-flex">
                <div className="sub-title">
                  <Typography.Title level={5}>구분</Typography.Title>
                </div>
                <Select
                  style={{ width: 250 }}
                  onChange={value => {
                    setCertificateDegreeInput(value as CertificateDegree);
                  }}
                  value={certificateDegreeInput}
                  placeholder="선택"
                >
                  {[
                    CertificateDegree.DOCTOR_DEGREE,
                    CertificateDegree.MASTER_DEGREE,
                    CertificateDegree.BACHELOR_DEGREE,
                    CertificateDegree.ASSOCIATE_DEGREE,
                    CertificateDegree.HIGH_SCHOOL
                  ].map(option => (
                    <Select.Option value={option}>{convertCertificateDegreeToDisplayName(option)}</Select.Option>
                  ))}
                </Select>
              </div>

              <div className="sub-flex">
                <div className="sub-title">
                  <Typography.Title level={5}>학교명</Typography.Title>
                </div>
                <Input
                  disabled={certificateDegreeInput === undefined}
                  value={schoolNameInput}
                  placeholder={certificateDegreeInput === undefined ? undefined : 'ex. 한국대학교'}
                  onChange={e => {
                    setSchoolNameInput(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="flex">
              <div className="sub-flex">
                <div className="sub-title">
                  <Typography.Title level={5}>전공구분</Typography.Title>
                </div>
                <Select
                  style={{ width: 250 }}
                  onChange={value => {
                    setMajorStandardName(value);
                    setMajorStandardId(majorOptions.find(mo => mo.name === value)!.id);
                  }}
                  value={majorStandardName}
                  disabled={!certificateDegreeInput}
                >
                  {majorOptions.map(option => (
                    <Select.Option value={option.name}>{option.name}</Select.Option>
                  ))}
                </Select>
              </div>
              <div className="sub-flex">
                <div className="sub-title">
                  <Typography.Title level={5}>전공</Typography.Title>
                </div>
                <Input
                  value={majorNameInput}
                  disabled={certificateDegreeInput === CertificateDegree.HIGH_SCHOOL || majorStandardId === undefined}
                  onChange={e => {
                    setMajorNameInput(e.target.value);
                  }}
                  placeholder={
                    certificateDegreeInput === CertificateDegree.HIGH_SCHOOL || majorStandardId === undefined
                      ? undefined
                      : 'ex. 철학과'
                  }
                />
              </div>
            </div>
            <div className="flex">
              <div className="sub-flex">
                <Typography.Title level={5}>입학</Typography.Title>
                <DatePicker
                  value={isNil(eduStartAtInput) ? undefined : moment(eduStartAtInput)}
                  format={'YYYY/MM/DD'}
                  placeholder="yyyy/mm/dd"
                  disabledDate={current => current && current >= moment().endOf('day')}
                  onChange={selectedValue => {
                    setEduStartAtInput(!selectedValue ? null : selectedValue);
                  }}
                />
              </div>
            </div>

            <div className="flex">
              <div className="sub-flex">
                <Typography.Title level={5}>졸업</Typography.Title>
                <div className="end-container">
                  <DatePicker
                    disabled={isAttendingInput === true}
                    value={isNil(eduCompletionInput) ? undefined : moment(eduCompletionInput)}
                    format={'YYYY/MM/DD'}
                    placeholder="yyyy/mm/dd"
                    disabledDate={current => current && current >= moment().endOf('day')}
                    onChange={selectedValue => {
                      setEduCompletionEndAtInput(!selectedValue ? null : selectedValue);
                    }}
                  />
                  <Checkbox
                    checked={isAttendingInput}
                    onChange={e => {
                      setIsAttendingInput(e.target.checked);

                      /* 체크시, 졸업일 데이터 초기화 */
                      if (e.target.checked) {
                        setEduCompletionEndAtInput(null);
                      }
                    }}
                  >
                    재학중
                  </Checkbox>
                </div>
              </div>
            </div>
            <Checkbox
              checked={isLastEducationInput}
              onChange={e => {
                setIsLastEducationInput(e.target.checked);
              }}
            >
              최종학력 여부
            </Checkbox>
            <Button type="primary" onClick={handleAddEducationDetail} disabled={!isValidEducationInput()}>
              추가
            </Button>
          </div>
        </div>
      </section>
      <Divider />
      <section>
        <div className="title">
          <Typography.Title level={4}>외국어</Typography.Title>
        </div>
        <div className="content">
          <Table
            className="table"
            pagination={false}
            bordered
            dataSource={languageDetails}
            columns={[
              {
                title: '언어',
                dataIndex: 'name'
              },
              {
                title: '구사수준',
                dataIndex: 'languageSkillLevel',
                render: value => <span>{convertLanguageSkillLevelToDisplayName(value)}</span>
              },
              {
                render: (value: LanguageDetail, record, index) => (
                  <a
                    role="button"
                    onClick={() => {
                      changeLanguageDetails(languageDetails.filter((ld, idx) => idx !== index));
                    }}
                  >
                    삭제
                  </a>
                )
              }
            ]}
          />

          <div className="input__group">
            <div className="input">
              <Typography.Title level={5}>외국어</Typography.Title>
              <Select
                style={{ width: 250 }}
                placeholder="선택"
                onChange={value => {
                  setLanguageIdInput(value as number);
                  setLanguageNameInput(languageOptions.find(lo => lo.id === value)!.name);
                }}
                value={languageIdInput}
                dropdownRender={option => (
                  <div>
                    {option}
                    <Divider style={{ margin: '4px 0' }} />
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input
                        style={{ flex: 'auto' }}
                        value={languageAddOptionName}
                        onChange={e => {
                          setLanguageAddOptionName(e.target.value);
                        }}
                      />
                      <a
                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                        onClick={() => {
                          if (languageAddOptionName) {
                            setLanguageOptions([
                              ...languageOptions,
                              {
                                id: -1,
                                name: languageAddOptionName
                              }
                            ]);
                            /* 초기화 */
                            setLanguageAddOptionName(undefined);
                          }
                        }}
                      >
                        <PlusOutlined /> 추가하기
                      </a>
                    </div>
                  </div>
                )}
              >
                {languageOptions.map(option => (
                  <Select.Option value={option.id}>{option.name}</Select.Option>
                ))}
              </Select>
            </div>
            <div className="input">
              <Typography.Title level={5}>구사수준</Typography.Title>
              <Select
                style={{ width: 250 }}
                placeholder="선택"
                onChange={value => {
                  setLanguageSkillLevelInput(value as LanguageSkillLevel);
                }}
                value={languageSkillLevelInput}
              >
                {[
                  LanguageSkillLevel.NATIVE,
                  LanguageSkillLevel.HIGH,
                  LanguageSkillLevel.MEDIUM,
                  LanguageSkillLevel.LOW
                ].map(skillLevel => (
                  <Select.Option value={skillLevel}>{convertLanguageSkillLevelToDisplayName(skillLevel)}</Select.Option>
                ))}
              </Select>
            </div>
            <Button type="primary" onClick={handleAddLanguageDetail} disabled={!isValidLanguageSkillInput()}>
              추가
            </Button>
          </div>
        </div>
      </section>
      <Divider />
      <section>
        <div className="title">
          <Typography.Title level={4}>교육/수상/자격/관련경험</Typography.Title>
        </div>
        <div className="content">
          <Typography.Text type="secondary">
            코드스테이츠 교육 외 외부교육, 수상내역, 자격증, 직무관련 경험을 자유롭게 입력해주세요. (외부 부트캠프,
            사이드프로젝트, 동아리 포함)
          </Typography.Text>

          <Table
            className="table"
            pagination={false}
            bordered
            dataSource={activityDetails}
            columns={[
              {
                title: '구분',
                dataIndex: 'category',
                render: value => <span>{convertActivityCategoryToDisplayName(value)}</span>
              },
              {
                title: '이름',
                dataIndex: 'name'
              },
              {
                title: '내용',
                dataIndex: 'description'
              },
              {
                title: '삭제',
                render: (value: ActivityDetail, record, index) => (
                  <a
                    role="button"
                    onClick={() => {
                      changeActivityDetails(activityDetails.filter((ad, idx) => idx !== index));
                    }}
                  >
                    삭제
                  </a>
                )
              }
            ]}
          />
        </div>

        <div className="input__group">
          <div className="input">
            <Typography.Title level={5}>구분</Typography.Title>
            <Select
              style={{ width: 250 }}
              onChange={value => {
                setActivityCategoryInput(value as ActivityCategory);
              }}
              value={activityCategoryInput}
              placeholder="선택"
            >
              {[
                ActivityCategory.CERTIFICATION,
                ActivityCategory.JOB_EXPERIENCE,
                ActivityCategory.OTHER_EDUCATION,
                ActivityCategory.PRIZE
              ].map(ac => (
                <Select.Option value={ac}>{convertActivityCategoryToDisplayName(ac)}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="input">
            <Typography.Title level={5}>이름</Typography.Title>
            <Input
              value={activityName}
              onChange={e => {
                setActivityName(e.target.value);
              }}
              placeholder={getActivityNamePlaceholder()}
            />
          </div>

          <div className="input">
            <Typography.Title level={5}>내용</Typography.Title>
            <Input.TextArea
              value={activityDescription}
              onChange={e => {
                setActivityDescription(e.target.value);
              }}
              placeholder={getActivityDescriptionPlaceholder()}
            />
          </div>
          <Button type="primary" onClick={handleAddActivityCategoryDetail} disabled={!isValidActivityInput()}>
            추가
          </Button>
        </div>
      </section>
      <Divider />
      <section>
        <div className="title">
          <Typography.Title level={4}>
            경력
            <RequireMark />
          </Typography.Title>
        </div>
        <div className="content">
          <Radio.Group
            className="career-status-radio"
            value={careerStatus}
            onChange={e => {
              if (e.target.value === CareerStatus.NEW) {
                changeExperienceDetails([]);
                handleIsLastExperienceSalaryPublic(true);
                handleLastExperienceSalary(undefined);
              }
              handleCareerStatus(e.target.value);
            }}
          >
            <Radio value={CareerStatus.NEW}>신입</Radio>
            <Radio value={CareerStatus.EXPERIENCE}>경력</Radio>
          </Radio.Group>
          <div className="sub-title">직전 회사 연봉</div>
          <div className="salary">
            <Input
              className="salary__input"
              disabled={!isLastExperienceSalaryPublic || careerStatus !== CareerStatus.EXPERIENCE}
              value={lastExperienceSalary}
              type="number"
              onChange={handleLastExperienceSalary}
            />
            <Typography.Text>만원</Typography.Text>
            <Checkbox
              onChange={e => {
                const isChecked = e.target.checked;
                handleIsLastExperienceSalaryPublic(!isChecked);

                // 미공개시 초기화
                if (isChecked) {
                  handleLastExperienceSalary(undefined);
                }
              }}
              checked={!isLastExperienceSalaryPublic}
              disabled={careerStatus !== CareerStatus.EXPERIENCE}
            >
              미공개
            </Checkbox>
          </div>

          <Table
            className="table"
            pagination={false}
            bordered
            dataSource={experienceDetails}
            columns={[
              {
                title: '회사이름',
                dataIndex: 'companyName'
              },
              {
                title: '부서/직책',
                dataIndex: 'jobTitle'
              },
              {
                title: '입사일',
                dataIndex: 'startAt',
                render: value => <span>{moment(value).format('YYYY-MM-DD')}</span>
              },
              {
                title: '퇴사일',
                dataIndex: 'endAt',
                render: (value, record) => (
                  <span>{record.isInService || !value ? '-' : moment(value).format('YYYY-MM-DD')}</span>
                )
              },
              {
                title: '재직중',
                dataIndex: 'isInService',
                align: 'center',
                render: value => <span>{value ? <CheckCircleTwoTone /> : '-'}</span>
              },
              {
                title: '삭제',
                render: (value: ExperienceDetail, record, index) => (
                  <a
                    role="button"
                    onClick={() => {
                      changeExperienceDetails(experienceDetails.filter((exp, idx) => idx !== index));
                    }}
                  >
                    삭제
                  </a>
                )
              }
            ]}
          />
          <div className="input__group">
            <div className="input">
              <Typography.Title level={5}>회사이름</Typography.Title>
              <Input
                placeholder="코드스테이츠"
                onChange={e => {
                  setCompanyNameInput(e.target.value);
                }}
                value={companyNameInput}
                disabled={careerStatus !== CareerStatus.EXPERIENCE}
              />
              <div className="input">
                <Typography.Title level={5}>부서/직책</Typography.Title>
                <Input
                  placeholder="IT 제품팀 PM"
                  value={jobTitleInput}
                  onChange={e => {
                    setJobTitleInput(e.target.value);
                  }}
                  disabled={careerStatus !== CareerStatus.EXPERIENCE}
                />
              </div>
              <div className="input">
                <Typography.Title level={5}>입사</Typography.Title>
                <DatePicker
                  value={isNil(expStartAtInput) ? undefined : moment(expStartAtInput)}
                  format={'YYYY/MM/DD'}
                  placeholder="yyyy/mm/dd"
                  disabledDate={current => current && current >= moment().endOf('day')}
                  onChange={selectedValue => {
                    setExpStartAtInput(!selectedValue ? null : selectedValue);
                  }}
                  disabled={careerStatus !== CareerStatus.EXPERIENCE}
                />
              </div>
              <div className="input">
                <Typography.Title level={5}>퇴사</Typography.Title>
                <div className="end-container">
                  <DatePicker
                    disabled={isInServiceInput || careerStatus !== CareerStatus.EXPERIENCE}
                    value={isNil(expEndAtInput) ? undefined : moment(expEndAtInput)}
                    format={'YYYY/MM/DD'}
                    placeholder="yyyy/mm/dd"
                    disabledDate={current => current && current >= moment().endOf('day')}
                    onChange={selectedValue => {
                      setExpEndAtInput(!selectedValue ? null : selectedValue);
                    }}
                  />
                  <Checkbox
                    checked={isInServiceInput}
                    onChange={e => {
                      setIsInServiceInput(e.target.checked);

                      /* 체크시, 퇴사일 데이터 초기화 */
                      if (e.target.checked) {
                        setExpEndAtInput(null);
                      }
                    }}
                    disabled={careerStatus !== CareerStatus.EXPERIENCE}
                  >
                    재직중
                  </Checkbox>
                </div>
              </div>
            </div>

            <Button type="primary" onClick={handleAddExperienceDetail} disabled={!isValidExperienceInput()}>
              추가
            </Button>
          </div>
        </div>
      </section>

      <div className="modal-navigate-container">
        <div className="button">
          <StyledButton
            type="link"
            disabled={false}
            onClick={() => {
              handleChangePreviousPage();
            }}
          >
            이전
          </StyledButton>
        </div>

        <div className="button">
          <StyledButton
            type="link"
            disabled={!isAvailableNextPage}
            onClick={() => {
              handleChangeNextPage();
            }}
          >
            다음
          </StyledButton>
        </div>
      </div>
    </StyledEmploymentOptionalForm>
  );
};

export default EmploymentOptionalForm;
