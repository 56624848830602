import React, { useState } from 'react';
import styled from '@emotion/styled';
import classnames from 'classnames';
import { Steps, Button, Checkbox, Radio, Tooltip } from 'antd';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';

import { MarkdownPreviewer } from '../organisms/quizMarkdown';
import { QuizType } from '../../../apollo/quiz/type';

import type { QuizSet, Quiz, QuizSolution, QuizChoice } from '../../../apollo/quiz/type';

type QuizSetViewProps = {
  quizSet?: QuizSet;
  currentQuizIndex: number;
  currentQuiz?: Quiz;
  currentQuizState?: {
    quizId: number;
    descriptiveAnswerInput?: string;
    selectedChoiceIds?: number[];
    isSubmitted: boolean;
    isCorrect: boolean;
    isDone: boolean;
    hasInput: boolean;
    solution?: QuizSolution;
  };
  quizStates: {
    quizId: number;
    descriptiveAnswerInput?: string;
    selectedChoiceIds?: number[];
    isSubmitted: boolean;
    isCorrect: boolean;
    isDone: boolean;
    hasInput: boolean;
    solution?: QuizSolution;
  }[];
  handleClickQuizStep: (index: number) => void;
  handleClickNextQuiz: () => void;
  handleClickPrevQuiz: () => void;
  handleInputDescriptiveAnswer: (input: string) => void;
  handleSelectChoice: (choiceId: number) => void;
  handleClickCheckSolution: (quizId: number) => void;
  handleClickSubmit: () => void;
  hadnleGtmMultiple: (checked: boolean, text: string) => void;
  handleGtmRadio: (text: string) => void;
  isFirstQuiz: boolean;
  isLastQuiz: boolean;
  isSubmitable: boolean;
  isValidInput: boolean;
  isSolutionCheckable: boolean;
  isCurrentQuizMultiChoiceType: boolean;
  isSubmittedQuizSet: boolean;
};

const QuizSetWrapper = styled.div`
  margin: 0 48px;

  .ant-tooltip {
    z-index: 999;
  }

  .quiz-indicator {
    display: flex;
    overflow-x: auto;
    margin-bottom: 16px;
    padding: 10px;
    .quiz-indicator-wrapper {
      margin: 0 auto;
    }
    .ant-steps-item {
      padding-left: 0;
    }

    .ant-steps-item.ant-steps-item-process {
      margin-right: 0px;

      .ant-steps-item-icon {
        margin-right: 0px;
      }

      .ant-steps-item-title::after {
        background-color: #bfbfbf;
        left: 0%;
      }
    }

    .ant-steps-item.ant-steps-item-process.normal {
      margin-right: 0px;

      .ant-steps-item-icon {
        background: rgb(249, 251, 253);
        border-color: #dfe4ec;
        margin-right: 0px;

        .ant-steps-icon {
          color: #455573;
        }
      }

      .ant-steps-item-title::after {
        background-color: #dfe4ec;
        left: 0%;
      }
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
      background: #8c9cba;
      border-color: #8c9cba;
    }
    .ant-steps-item.ant-steps-item-process.correct {
      margin-right: 0px;

      .ant-steps-item-icon {
        background: #ebfff7;
        border-color: #00b86a;
        margin-right: 0px;

        .ant-steps-icon {
          color: black;
        }
      }

      .ant-steps-item-title::after {
        background-color: #bfbfbf;
        left: 0%;
      }
    }

    .ant-steps-item.ant-steps-item-process.incorrect {
      margin-right: 0px;

      .ant-steps-item-icon {
        background: #ffeeeb;
        border-color: #ff360a;
        margin-right: 0px;

        .ant-steps-icon {
          color: black;
        }
      }

      .ant-steps-item-title::after {
        border-color: #bfbfbf;
        left: 0%;
      }
    }

    .ant-steps-item.ant-steps-item-process.done {
      margin-right: 0px;

      .ant-steps-item-icon {
        background: #f2f5fd;
        border-color: #8c9cba;
        margin-right: 0px;

        .ant-steps-icon {
          color: black;
        }
      }

      .ant-steps-item-title::after {
        border-color: #bfbfbf;
        left: 0%;
      }
    }
  }

  .quiz-field-label {
    position: absolute;
    top: 16px;
    left: -36px;
    font-weight: bold;

    &.correct {
      color: #00b86a;
    }
    &.noSolution {
      color: #00b86a;
    }

    &.incorrect {
      color: #ff360a;
    }
  }

  .quiz-question {
    .quiz-question__content {
      padding: 8px;
      border: 1px solid #dfe4ec;
      border-radius: 6px;
      background-color: white;
      min-height: 200px;

      .markdown-preview {
        background-color: white;
      }
      .markdown-body {
        p {
          color: #455573;
        }
      }
    }
  }

  .quiz-choices {
    margin-top: 16px;

    .quiz-choices-wrapper {
      display: block;

      .quiz-choice {
        position: relative;
        border: 1px solid #dfe4ec;
        border-radius: 6px;
        margin-bottom: 10px;

        &.correct {
          background-color: #ecffea;

          input[type='checkbox']:not(:checked) + .ant-checkbox-inner {
            background-color: #fbe2e2;
            border: 2px solid #dfe4ec;
          }
        }

        &.incorrect {
          background-color: #ffeeeb;
        }

        .quiz-choice__label {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 10px;
          cursor: pointer;
        }

        .quiz-choice__tooltip {
        }

        .quiz-choice__title {
          display: flex;
          align-items: center;
          overflow: auto;
          p {
            color: #455573;
          }

          .quiz-choice__order {
            margin-right: 10px;
          }
          .quiz-choice__order--checked {
            font-weight: bold;
            margin-right: 10px;
          }

          .quiz-choice__content--checked {
            font-weight: 700;
          }
        }

        .quiz-choice__checkbox {
          margin-right: 10px;
          .ant-checkbox {
            .ant-checkbox-inner {
              border-radius: 5px;
              border-color: #dfe4ec;
              width: 20px;
              height: 20px;
            }
            .ant-checkbox-inner:after {
              left: 25%;
            }
          }
          .ant-checkbox-checked {
            .ant-checkbox-input {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .quiz-descriptive-answer {
    position: relative;

    .quiz-descriptive-answer__content {
      display: block;
      width: 100%;
      margin-top: 16px;
      padding: 8px;
      border: 1px solid #dfe4ec;
      outline: none;
    }
  }

  .quiz-descriptive {
    position: relative;

    .quiz-descriptive__input {
      display: block;
      width: 100%;
      margin-top: 16px;
      padding: 12px 16px;
      border-radius: 6px;
      border: 1px solid #dfe4ec;
      outline: none;

      &.correct {
        background-color: #e9ffdb;
      }

      &.incorrect {
        background-color: #fbe2e2;
      }
      &.noSolution {
        background-color: #e9ffdb;
      }
    }
  }

  .quiz-explanation {
    position: relative;

    .quiz-explanation__content {
      margin-top: 16px;
      padding: 8px;
      border: 1px solid #eceff4;
      border-radius: 6px;
      background-color: white;
      min-height: 200px;
      p {
        color: #455573;
      }

      .markdown-preview {
        background-color: white;
      }
    }
  }

  .quiz-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .quiz-navigation__left {
      flex: 1;
      display: flex;
      justify-content: flex-start;
    }

    .quiz-navigation__center {
      flex: 1;
      display: flex;
      justify-content: center;
    }

    .quiz-navigation__right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }

    .quiz-navigation__prev-btn,
    .quiz-navigation__next-btn {
      color: white;
      border-radius: 4px;
      border: none;
      background-color: #b2bdd1;
    }
    .quiz-navigation__submit-btn {
      color: white;
      border-radius: 4px;
      border: none;
      background-color: #452cdd;
      .ant-btn[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f0f0f0;
        border: none;
        text-shadow: none;
        -webkit-box-shadow: none;
      }
    }
    .quiz-navigation__check-solution-btn {
      color: white;
      border-radius: 4px;
      border: none;
      background-color: #295ce0;

      &:disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f0f0f0;
        border: none;
        text-shadow: none;
        -webkit-box-shadow: none;
      }
    }
  }
`;

const QuizSetModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  -webkit-transition: opacity 200ms linear;
  -moz-transition: opacity 200ms linear;
  -ms-transition: opacity 200ms linear;
  -o-transition: opacity 200ms linear;
  transition: opacity 200ms linear;
`;

const QuizSetModal = styled.div`
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 18px;
  z-index: 900;
  font-size: 17px;
  background-color: #ffffff;
  border-radius: 18px;
  width: 278px;
  height: 338px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .quiz-modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .quiz-modal-text {
    text-align: center;
    margin: 3rem auto;
    font-weight: 500;
    font-size: 1.1rem;
  }

  .quiz-modal-button-container {
    margin: 1rem;
  }

  .quiz-modal-button {
    width: 230px;
    height: 45px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    margin: 0.5rem;
    cursor: pointer;
  }

  .text-highlight {
    color: #4000c7;
  }

  .ok {
    background-color: #1f41d9;
  }

  .no {
    background-color: #a3aec7;
  }
`;

const QuizSetView: React.FC<QuizSetViewProps> = ({
  quizSet,
  currentQuizIndex,
  currentQuiz,
  currentQuizState,
  quizStates,
  handleClickCheckSolution,
  handleClickNextQuiz,
  handleClickPrevQuiz,
  handleClickQuizStep,
  handleInputDescriptiveAnswer,
  handleSelectChoice,
  hadnleGtmMultiple,
  handleGtmRadio,
  isFirstQuiz,
  isLastQuiz,
  isSolutionCheckable,
  isSubmitable,
  isValidInput,
  isCurrentQuizMultiChoiceType,
  isSubmittedQuizSet,
  ...props
}) => {
  const [isQuizModalOpen, setIsQuizModalOpen] = useState(false);
  const choiceAlphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
  const isReadOnly = quizSet?.isAssessment && isSubmittedQuizSet;

  if (!quizSet || !currentQuiz || !currentQuizState) {
    return <> </>;
  }
  const isChoiceCorrect = (choice: QuizChoice) => {
    return !!currentQuizState.solution?.choices?.find(correctChoice => correctChoice.id === choice.id);
  };

  const isChoiceSelected = (choice: QuizChoice) => {
    return !!currentQuizState.selectedChoiceIds?.find(selectedChoiceId => selectedChoiceId === choice.id);
  };

  const handleClickSubmit = () => {
    setIsQuizModalOpen(true);
  };
  const makeSortedQuizSet = () => {
    const tempQuizSet = [...quizSet.quizzes];
    const sortedQuizSet = tempQuizSet.sort((a, b) => {
      return a.order - b.order;
    });
    return sortedQuizSet.map((quiz, index) => (
      <Steps.Step
        key={quiz.id}
        className={
          index === currentQuizIndex
            ? 'current'
            : !quizStates[index].hasInput
            ? 'normal'
            : quizSet.isAssessment
            ? 'done'
            : quizStates[index].isCorrect
            ? 'correct'
            : 'incorrect'
        }
        status="process"
      />
    ));
  };

  return (
    <QuizSetWrapper>
      {isQuizModalOpen ? (
        <>
          <QuizSetModalBackground
            onClick={() => {
              setIsQuizModalOpen(false);
            }}
          ></QuizSetModalBackground>
          <QuizSetModal>
            <CloseIcon className="quiz-modal-close" onClick={() => setIsQuizModalOpen(false)} />

            <div className="quiz-modal-text">
              {quizSet.isAssessment ? (
                <>
                  <span className="text-highlight">평가용 퀴즈</span>는 단 한번만
                  <br />
                  제출할 수 있습니다.
                  <br />
                  정말 이대로 제출할까요?
                </>
              ) : (
                <div>문제를 최종 제출하시겠습니까?</div>
              )}
            </div>
            <div className="quiz-modal-button-container">
              <div
                className="quiz-modal-button ok"
                onClick={() => {
                  props.handleClickSubmit();
                  setIsQuizModalOpen(false);
                }}
              >
                네, 제출할게요.
              </div>
              <div className="quiz-modal-button no" onClick={() => setIsQuizModalOpen(false)}>
                아니요, 다시 살펴볼게요.
              </div>
            </div>
          </QuizSetModal>
        </>
      ) : null}

      <div className="quiz-indicator">
        <div className="quiz-indicator-wrapper">
          <Steps
            current={currentQuizIndex}
            onChange={index => {
              handleClickQuizStep(index);
            }}
          >
            {makeSortedQuizSet()}
          </Steps>
        </div>
      </div>

      <div className="quiz-question">
        <div className="quiz-question__content">
          <MarkdownPreviewer className="markdown-preview" markdownBody={currentQuiz.question} />
        </div>
      </div>

      {currentQuiz.type === QuizType.CHOICE && (
        <div className="quiz-choices">
          <div className="quiz-choices-wrapper">
            {currentQuiz.choices?.map((choice: QuizChoice, index: number) => {
              return (
                <div
                  key={index}
                  className={classnames('quiz-choice', {
                    correct: currentQuizState.isDone && isChoiceCorrect(choice),
                    incorrect: currentQuizState.isDone && isChoiceSelected(choice) && !isChoiceCorrect(choice)
                  })}
                >
                  {currentQuizState.isDone && (
                    <span
                      className={classnames('quiz-field-label', {
                        correct: isChoiceCorrect(choice),
                        incorrect: isChoiceSelected(choice) && !isChoiceCorrect(choice)
                      })}
                    >
                      {isChoiceCorrect(choice) ? '정답' : isChoiceSelected(choice) ? '오답' : ''}
                    </span>
                  )}

                  {isCurrentQuizMultiChoiceType ? (
                    <label className="quiz-choice__label">
                      <Checkbox
                        className="quiz-choice__checkbox"
                        onChange={e => {
                          hadnleGtmMultiple(e.target.checked, choice.content);
                          handleSelectChoice(e.target.value);
                        }}
                        value={choice.id}
                        checked={isChoiceSelected(choice)}
                        disabled={isReadOnly}
                      />
                      <Tooltip
                        className="quiz-choice__tooltip"
                        getPopupContainer={triggerNode => triggerNode}
                        title="해당 복수 정답 미선택으로 오답처리 되었습니다."
                        placement="right"
                        visible={currentQuizState.isDone && isChoiceCorrect(choice) && !isChoiceSelected(choice)}
                      >
                        <div className="quiz-choice__title">
                          <span
                            className={isChoiceSelected(choice) ? 'quiz-choice__order--checked' : 'quiz-choice__order'}
                          >
                            {choiceAlphabets[choice.order]}.
                          </span>
                          <span
                            className={
                              isChoiceSelected(choice) ? 'quiz-choice__content--checked' : 'quiz-choice__content'
                            }
                          >
                            <MarkdownPreviewer markdownBody={choice.content} />
                          </span>
                        </div>
                      </Tooltip>
                    </label>
                  ) : (
                    <label className="quiz-choice__label">
                      <Radio
                        className={'quiz-choice__checkbox'}
                        onChange={e => {
                          handleGtmRadio(choice.content);
                          handleSelectChoice(e.target.value);
                        }}
                        value={choice.id}
                        checked={isChoiceSelected(choice)}
                        disabled={isReadOnly}
                      />
                      <div className="quiz-choice__title">
                        <span
                          className={isChoiceSelected(choice) ? 'quiz-choice__order--checked' : 'quiz-choice__order'}
                        >
                          {choiceAlphabets[choice.order]}.
                        </span>
                        <span
                          className={
                            isChoiceSelected(choice) ? 'quiz-choice__content--checked' : 'quiz-choice__content'
                          }
                        >
                          <MarkdownPreviewer markdownBody={choice.content} />
                        </span>
                      </div>
                    </label>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {currentQuiz.type === QuizType.DESCRIPTIVE && (
        <div className="quiz-descriptive">
          {currentQuizState.isDone && (
            <label
              className={classnames('quiz-field-label', {
                correct: currentQuizState.isCorrect,
                incorrect: !currentQuizState.isCorrect && currentQuiz.hasSolution,
                noSolution: !currentQuiz.hasSolution
              })}
            >
              {currentQuizState.isCorrect || !currentQuiz.hasSolution ? '정답' : '오답'}
            </label>
          )}
          <input
            type="text"
            className={classnames('quiz-descriptive__input', {
              correct: currentQuizState.isDone && currentQuizState.isCorrect,
              noSolution: currentQuizState.isDone && currentQuiz.hasSolution === false,
              incorrect: currentQuizState.isDone && currentQuizState.isCorrect === false
            })}
            value={currentQuizState.descriptiveAnswerInput || ''}
            onChange={e => handleInputDescriptiveAnswer(e.target.value)}
            placeholder="정답을 입력해주세요"
            readOnly={isReadOnly}
          />
        </div>
      )}

      {/* 주관식 풀었는데 틀렸을 경우 답을 보여줍니다. 단, 정답이 있어야 합니다. */}
      {currentQuiz.type === QuizType.DESCRIPTIVE &&
        currentQuizState.isDone &&
        !currentQuizState.isCorrect &&
        currentQuiz.hasSolution && (
          <>
            {currentQuizState.solution?.descriptiveAnswers?.map(answer => (
              <div className="quiz-descriptive-answer">
                <label className={classnames('quiz-field-label')}>정답</label>
                <div className="quiz-descriptive-answer__content">
                  <MarkdownPreviewer className="markdown-preview" markdownBody={answer} />
                </div>
              </div>
            ))}
          </>
        )}

      {/* 해설이 있는 경우에만 해설을 보여줍니다. */}
      {currentQuizState.isDone && currentQuiz.explanation && (
        <div className="quiz-explanation">
          <label className="quiz-field-label">해설</label>

          <div className="quiz-explanation__content">
            <MarkdownPreviewer className="markdown-preview" markdownBody={currentQuiz.explanation} />
          </div>
        </div>
      )}

      <div className="quiz-navigation">
        <div className="quiz-navigation__left">
          {!isFirstQuiz && (
            <Button className="quiz-navigation__prev-btn" onClick={handleClickPrevQuiz}>
              이전 문제
            </Button>
          )}
        </div>
        {/* 비 평가용 퀴즈 의 정답이 없는 경우는 해설확인으로 변경합니다. */}
        <div className="quiz-navigation__center">
          {quizSet?.isAssessment ? (
            <Tooltip title="평가용 퀴즈는 정답을 확인할 수 없습니다.">
              <Button
                className="quiz-navigation__check-solution-btn"
                onClick={() => handleClickCheckSolution(currentQuiz.id)}
                disabled={!isSolutionCheckable}
              >
                정답 확인
              </Button>
            </Tooltip>
          ) : (
            <Button
              className="quiz-navigation__check-solution-btn"
              onClick={() => handleClickCheckSolution(currentQuiz.id)}
              disabled={!isSolutionCheckable}
            >
              {`${currentQuiz.hasSolution ? '정답 확인' : '해설 확인'}`}
            </Button>
          )}
        </div>

        <div className="quiz-navigation__right">
          {isLastQuiz ? (
            isSubmitable ? (
              <Button className="quiz-navigation__submit-btn" onClick={handleClickSubmit} disabled={!isSubmitable}>
                최종 제출
              </Button>
            ) : (
              <Tooltip
                title={
                  quizSet?.isAssessment
                    ? isValidInput
                      ? '이미 제출하여 다시 제출할 수 없습니다.'
                      : '모든 문제의 정답을 입력해야 제출할 수 있습니다.'
                    : '모든 문제 정답을 확인해야 제출할 수 있습니다.'
                }
              >
                <Button className="quiz-navigation__submit-btn" disabled={!isSubmitable}>
                  최종 제출
                </Button>
              </Tooltip>
            )
          ) : (
            <Button className="quiz-navigation__next-btn" onClick={handleClickNextQuiz}>
              다음 문제
            </Button>
          )}
        </div>
      </div>
    </QuizSetWrapper>
  );
};

export default QuizSetView;
