import gql from 'graphql-tag';

export const MAKE_CERTIFICATION = gql`
  mutation urclassMakeCertification(
    $certificateType: CertificateType!
    $bootcampId: Int!
    $trainStartAt: DateTime!
    $trainEndAt: DateTime!
  ) {
    urclassMakeCertification(
      certificateType: $certificateType
      bootcampId: $bootcampId
      trainStartAt: $trainStartAt
      trainEndAt: $trainEndAt
    ) {
      type
      holidays
      trainingProgramName
      govermentOfficeName
      ncsCode
      validDuration
      personnel
      totalTrainingTimeAndDay
      mainTrainingTarget
      trainingIntervalAnd
      name
      birthdayAndGender
      trainStartAt
      trainEndAt
      trainingTime
      presenceCount
      leaveEarlyCount
      absenceCount
      officialLeaveCount
      presenceRatio
      certificateOrganizationInfo {
        trainingOrganizationName
        trainingOrganizationCategory
        address
      }
      certificateRecords {
        date
        checkInTime
        checkOutTime
        attendanceResult
      }
    }
  }
`;
