import React from 'react';
import styled from '@emotion/styled';
import { Survey } from 'survey-react-ui';
import { CozSurveyTheme } from '../CozSurveyTheme';

interface SurveyBoardContentProps {
  survey: any;
}

const SurveyBoardContent = ({ survey }: SurveyBoardContentProps) => {
  return (
    <SurveyBoardContentWrapper>
      <CozSurveyTheme>
        <Survey model={survey} />
      </CozSurveyTheme>
    </SurveyBoardContentWrapper>
  );
};

export default React.memo(SurveyBoardContent);

const SurveyBoardContentWrapper = styled.div`
  margin-top: 16px;
  padding: 34px 17px;
  background: #ffffff;

  box-shadow: 10.65px 10.58px 20px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
`;
