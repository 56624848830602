import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { GeneralTemplate } from '../../templates';
import { Header } from '../../components/organisms/Header';

import { MY_APPLICATION_GQL } from '../../apollo/mypage/query';
import { MY_PROFILE_GQL } from '../../apollo/account/query';

import { store } from '../../util';

import type { MyApplicationVars, MyApplicationData } from '../../apollo/mypage/type';
import type { MyProfileData } from '../../apollo/account/type';
import { useEffect } from 'react';
import { sendPageView } from '../../util/marketingTools/googleTagAssistant';

const FormPageWrapper = styled.div`
  .form-frame {
    margin-top: 0px;
    width: 100%;
    height: 70vh;
    border-width: 0px;
  }
`;

const ApplicationFormPage = () => {
  const { uuid, typeformId, finalOrOrder } = useParams<{ uuid: string; typeformId: string; finalOrOrder: string }>();
  const { data, loading, error } = useQuery<MyApplicationData, MyApplicationVars>(MY_APPLICATION_GQL, {
    variables: { uuid }
  });
  const {
    data: myProfileData,
    loading: myProfileLoading,
    error: myProfileError
  } = useQuery<MyProfileData>(MY_PROFILE_GQL);

  const token = store.token.get();

  const searchParam = new URLSearchParams();

  /*
   * applicationuuid: (in server)유저 지원 상품 식별
   * uctoken: (in server)유저 인증 식별
   * productcohort: (in typeform)유저 지원 상품 식별
   * name: (in typeform) 유저 이름 식별
   * email : (in typeform) bonus
   * mobile : (in typeform) bonus
   */
  searchParam.append('applicationuuid', uuid);
  searchParam.append('uctoken', token || '');
  searchParam.append('productcohort', data?.urclassMyApplication.product.name || '');
  searchParam.append('name', myProfileData?.urclassMe.name || '');
  searchParam.append('email', myProfileData?.urclassMe.email || '');
  searchParam.append('mobile', myProfileData?.urclassMe.mobile || '');
  searchParam.append('userid', myProfileData?.urclassMe.id.toString() || '');

  useEffect(() => {
    if (data) {
      const { name } = data.urclassMyApplication.product;
      sendPageView(`/applications/${name}/${finalOrOrder}`);
    }
  }, [data]);

  return (
    <GeneralTemplate loading={loading || myProfileLoading} error={!!error || !!myProfileError} backgroundColor={'#fff'}>
      {{
        header: <Header />,
        body: (
          <FormPageWrapper>
            <iframe
              title="form-frame"
              className="form-frame"
              src={`https://codestates.typeform.com/to/${typeformId}#${searchParam.toString()}`}
            />
          </FormPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default ApplicationFormPage;
