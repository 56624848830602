export const formatDateStream = (input: string, separator?: string) => {
  const splitted = input.split('');
  separator = separator ?? '.';

  if (splitted[4] !== separator && splitted.length >= 4) {
    splitted.splice(4, 0, separator);
  }
  if (splitted[7] !== separator && splitted.length >= 7) {
    splitted.splice(7, 0, separator);
  }

  return splitted.join('').split(separator).filter(Boolean).join(separator);
};
