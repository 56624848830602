import React from 'react';
import styled from '@emotion/styled';
import { Button, Input } from 'antd';

import { useController } from './controller';

export const OTPPageWrapper = styled.div`
  #hrdOtpFrame {
    width: 100%;
    height: 100%;
  }
  .otp__password {
    margin-top: 300px;
    text-align: center;

    #otpVal {
      width: 50%;
    }

    .otp__password-ok-button {
      margin-top: 3px;
    }
  }

  .otp__status-reset {
    margin-top: 300px;
    text-align: center;
  }
`;

const OTPPage: React.FC = () => {
  const controller = useController();

  return (
    <OTPPageWrapper>
      <div id="hrdOtpFrame"></div>
      {!controller.isOTPLocked && (
        <div className="otp__password">
          <div>
            <Input
              placeholder="OTP 번호를 올바르게 입력 해주세요"
              type="password"
              id="otpVal"
              onChange={controller.changePassword}
            />
          </div>
          <div>
            <Button
              className="otp__password-ok-button"
              type="primary"
              onClick={controller.validateOtp}
            >
              입력확인
            </Button>
          </div>
        </div>
      )}

      {controller.isOTPLocked && (
        <div className="otp__status-reset">
          <Button
            className="otp__password-reset-button"
            type="primary"
            onClick={controller.resetOTPStatus}
          >
            초기화
          </Button>
        </div>
      )}
    </OTPPageWrapper>
  );
};

export default OTPPage;
