import React from 'react';

import UpfrontSummary from './UpfrontSummary';
import HybridSummary from './HybridSummary';
import WewinSummary from './WewinSummay';
import ProductInfo from './ProductInfo';

import { PaymentType } from '../../../apollo/product/type';
import { SummaryCardWrapper } from './style';

import type { Product, PaymentModel } from '../../../apollo/product/type';

interface ISummaryCardProps {
  product: Product;
  paymentModel: PaymentModel | undefined;
  handleCheckout?: () => void;
  handleApply?: () => void;
  checkoutLoading: boolean;
  applyLoading: boolean;
}

const SummaryCard: React.FC<ISummaryCardProps> = ({
  product,
  paymentModel,
  handleCheckout,
  handleApply,
  checkoutLoading,
  applyLoading
}) => {
  const displaySummary = (paymentModel: PaymentModel) => {
    switch (paymentModel.paymentType) {
      case PaymentType.IMPACT_CAMPUS:
        return <div></div>;
      case PaymentType.WEWIN:
        return (
          <WewinSummary
            handleApply={handleApply}
            loading={applyLoading}
            programId={product.program.id}
          />
        );

      case PaymentType.HYBRID:
        return (
          <HybridSummary
            paymentModel={paymentModel}
            handleCheckout={handleCheckout}
            loading={checkoutLoading}
            programId={product.program.id}
          />
        );

      default:
        return (
          <UpfrontSummary
            paymentModel={paymentModel}
            handleCheckout={handleCheckout}
            loading={checkoutLoading}
          />
        );
    }
  };

  return (
    <SummaryCardWrapper>
      <ProductInfo product={product} paymentModel={paymentModel} />
      {paymentModel && displaySummary(paymentModel)}
    </SummaryCardWrapper>
  );
};

export default SummaryCard;
