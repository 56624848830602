import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { UPDATE_USER_TERMS_GQL } from '../../apollo/user/mutation';

import { store } from '../../util';
import { getServerError } from '../../util/errors/serverErrors';

import type { UpdateUserTermsData, UpdateUserTermsVars } from '../../apollo/user/type';

export const useController = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [requiredAgree, setRequiredAgree] = useState(false);
  const [marketingUseAgreement, setMarketingUseAgreement] = useState(false);
  const [receivingMessagesAgreement, setReceivingMessagesAgreement] = useState(false);

  const [updateUserTerms, { loading: submitTermsLoading }] = useMutation<UpdateUserTermsData, UpdateUserTermsVars>(
    UPDATE_USER_TERMS_GQL,
    {
      variables: {
        input: {
          receivingMessagesAgreement,
          marketingUseAgreement,
          serviceTermsAgreement: requiredAgree,
          privacyTermsAgreement: requiredAgree
        }
      }
    }
  );
  const checkedAll = requiredAgree && marketingUseAgreement && receivingMessagesAgreement;

  const checkAll = () => {
    setRequiredAgree(!checkedAll);
    setReceivingMessagesAgreement(!checkedAll);
    setMarketingUseAgreement(!checkedAll);
  };

  const okErrorModal = () => {
    setErrorMessage('');
  };

  const submitTermsForm = async () => {
    if (!requiredAgree) {
      setErrorMessage('필수 항목에 동의하지 않으셨습니다.');
      return;
    }

    try {
      const { data } = await updateUserTerms();

      if (!data || !data.urclassUpdateUserTerms) {
        setErrorMessage('가입과정에 문제가 있습니다.');
        return;
      }

      const { pathname, search } = store.redirectURL.get();

      if (pathname) {
        history.push({ pathname, search });
        return;
      }
      history.push('/');
    } catch (e) {
      const serverError = getServerError(e.message);
      setErrorMessage(serverError.message);
    }
  };

  return {
    errorMessage,
    okErrorModal,

    checkAll,
    checkedAll,
    requiredAgree,
    receivingMessagesAgreement,
    marketingUseAgreement,
    setRequiredAgree,
    setMarketingUseAgreement,
    setReceivingMessagesAgreement,
    submitTermsForm,
    submitTermsLoading
  };
};
