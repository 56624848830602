import { useQuery as apolloUseQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { sendPageView } from '../../util/marketingTools/googleTagAssistant';
import { MyApplicationData, MyApplicationVars } from '../../apollo/mypage/type';

import { MY_APPLICATION_DETAIL_GQL } from '../../apollo/mypage/query';

export const useController = () => {
  const history = useHistory();
  const location = useLocation<{ applicationUuid: string }>();
  const searchParams = new URLSearchParams(window.location.search);
  const applicationUuid = location?.state?.applicationUuid || '';

  const [productName, setProductName] = useState<string | undefined>('');

  // vbank : 대기 표시 , method=vbank
  // card : 결제 완료 표시 , method = card
  // wewin : 지원 완료 표시 , 아무것도 안옴.
  const method = searchParams.get('method');

  // params >> vbank 정보
  const vbank_num = searchParams.get('vbank_num') || '';
  const vbank_name = searchParams.get('vbank_name') || '';
  const vbank_date_param = searchParams.get('vbank_date');
  const vbank_date = vbank_date_param ? +new Date(vbank_date_param) : 0;
  const vbank_holder = searchParams.get('vbank_holder') || '';
  const paid_amount = searchParams.get('paid_amount') || '';

  const vbankInfo =
    method === 'vbank'
      ? {
          vbank_holder,
          vbank_name,
          vbank_num,
          vbank_date,
          paid_amount
        }
      : undefined;

  const isVbankError =
    method === 'vbank' && (!vbank_date || !vbank_num || !vbank_name || !vbank_holder || !paid_amount);

  const {
    data: cozApplicationData,
    loading: cozApplicationLoading,
    error: cozApplicationError
  } = apolloUseQuery<MyApplicationData, MyApplicationVars>(MY_APPLICATION_DETAIL_GQL, {
    variables: { uuid: applicationUuid },
    fetchPolicy: 'network-only',
    skip: method === 'vbank',
    onCompleted: () => {
      setProductName(cozApplicationData?.urclassMyApplication.product.name);
    }
  });

  useEffect(() => {
    if (!productName) return;
    sendPageView(`apply/result/${productName}`);
  }, [productName]);

  // handler
  const handleClickMyPageButton = () => {
    history.push('/mypage');
  };

  return {
    method,

    vbankInfo,
    isVbankError,
    cozApplicationLoading,
    cozApplicationError,
    cozApplicationData,
    handleClickMyPageButton
  };
};
