import gql from 'graphql-tag';

export const INCREASE_CARD_NEWS_VIEW_COUNT_GQL = gql`
  mutation urclassIncreaseCardNewsViewCount($cardNewsId: Int!) {
    urclassIncreaseCardNewsViewCount(cardNewsId: $cardNewsId) {
      id
      uuid
      title
      description
      thumbnailURL
      url
      viewCount
    }
  }
`;
