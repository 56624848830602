import React from 'react';
import styled from '@emotion/styled';
import { Modal } from 'antd';

import { useController } from './controller';
import { GeneralTemplate } from '../../templates';

import { Typography, TypographyType } from '../../components/atoms/Typography';
import { Divider } from '../../components/atoms';
import { DefaultTextbox } from '../../components/atoms/Textbox';
import { Button } from '../../components/molecules/Button';
import { SizeType, StatusType } from '../../components/molecules/Button/type';
import { Checkbox } from '../../components/molecules/Input/Checkbox';

import { ColorPalette, ColorPaletteType } from '../../styles/color';

const SignUpPageWrapper = styled.div`
  margin: auto;
  margin-top: 80px;

  width: 310px;

  padding: 47px 22px 22px;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${ColorPalette.Gray4};
  background-color: ${ColorPalette.White};

  .signup-form__title {
    margin-bottom: 30px;
    text-align: center;
  }

  .signup-form__field--divider {
    margin: 5px 0px 5px 0px;
  }
  .signup-form__personal-info-area {
    .signup-form__field--subtext {
      height: 33px;
      margin: 3px 0px 3px 0px;
    }
  }

  .signup-form__agreement-all {
    margin-top: 11px;
  }

  .signup-form__agreement-area {
    margin-top: 11px;
  }
  .signup-form__reminder-area {
    margin-top: 20px;
    text-align: center;
  }
  .signup-form__message {
    width: 233px;

    margin: auto;

    margin-top: 21px;
    margin-bottom: 21px;

    text-align: center;
  }

  .signup-form__submit {
    margin-top: 18px;
  }
`;

const ErrorModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .modal-error-message {
    text-align: center;
    max-width: 200px;
  }
  .modal-ok-button {
    &:hover {
      cursor: pointer;
    }
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 300px;
    height: 52px;
    background-color: ${ColorPalette.Gray1};
  }
`;

const SignUpPage: React.FC = () => {
  const controller = useController();

  return (
    <GeneralTemplate>
      {{
        body: (
          <SignUpPageWrapper>
            <div className="signup-form__title">
              <Typography type={TypographyType.H1} color={ColorPaletteType.GRAY1} text="약관 동의 및" />
              <Typography type={TypographyType.H1} color={ColorPaletteType.GRAY1} text="실명 확인" />
            </div>

            <div className="signup-form__personal-info-area">
              <DefaultTextbox
                value={controller.name}
                placeholder="실명"
                onChange={e => {
                  controller.setName(e.target.value);
                }}
              />

              <Typography
                className="signup-form__field--subtext"
                type={TypographyType.B2}
                color={controller.isNameFieldValid ? ColorPaletteType.WHITE : ColorPaletteType.RED}
                text="지원 절차를 위해서는 정확한 실명이 필요합니다."
              />
            </div>

            <Divider className="signup-form__field--divider" length="265px" color={ColorPalette.Gray4} />
            <div className="signup-form__agreement-all">
              <Checkbox
                className="signup-form__field--checkbox"
                checked={controller.checkedAll}
                onChange={controller.checkAll}
                children={
                  <Typography type={TypographyType.B1_Bold} color={ColorPaletteType.GRAY2} text="모두 동의하기" />
                }
              />
            </div>
            <Divider className="signup-form__field--divider" length="265px" color={ColorPalette.Gray4} />
            <div className="signup-form__agreement-area">
              <Checkbox
                className="signup-form__field--checkbox"
                checked={controller.requiredAgree}
                onChange={() => {
                  controller.setRequiredAgree(!controller.requiredAgree);
                }}
                children={
                  <a href="https://codestates.com/terms/policy" target="_blank" rel="noopener noreferrer">
                    <Typography
                      type={TypographyType.B2}
                      color={ColorPaletteType.GRAY2}
                      text="[필수] 개인정보 수집 및 이용 동의"
                      underline={true}
                    />
                  </a>
                }
              />

              <Checkbox
                className="signup-form__field--checkbox"
                checked={controller.receivingMessagesAgreement}
                onChange={() => {
                  controller.setReceivingMessagesAgreement(!controller.receivingMessagesAgreement);
                }}
                children={
                  <Typography
                    type={TypographyType.B2}
                    color={ColorPaletteType.GRAY2}
                    text="[선택] 문자 및 이메일 수신동의"
                  />
                }
              />

              <Checkbox
                className="signup-form__field--checkbox"
                checked={controller.marketingUseAgreement}
                onChange={() => {
                  controller.setMarketingUseAgreement(!controller.marketingUseAgreement);
                }}
                children={
                  <Typography type={TypographyType.B2} color={ColorPaletteType.GRAY2} text="[선택] 마케팅 활용 동의" />
                }
              />
            </div>
            <Divider className="signup-form__field--divider" length="265px" color={ColorPalette.Gray4} />
            <div className="signup-form__reminder-area">
              <Typography
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY2}
                text="서비스 이용을 위한 필수사항들은 이메일/"
              />
              <Typography
                type={TypographyType.B2}
                color={ColorPaletteType.GRAY2}
                text="문자를 통해 안내될 수 있습니다."
              />
            </div>

            <Button
              className="signup-form__submit"
              status={
                !controller.isNameFieldValid || controller.signUpMutationLoading
                  ? StatusType.DISABLED
                  : StatusType.ACTIVE
              }
              size={SizeType.BASIC}
              disabled={!controller.isNameFieldValid || controller.signUpMutationLoading}
              onClick={async () => {
                if (controller.isNameFieldValid) {
                  await controller.signup();
                }
              }}
            >
              <Typography type={TypographyType.B1} color={ColorPaletteType.WHITE} text="확인" />
            </Button>
            <Modal
              centered={true}
              cancelButtonProps={{ style: { display: 'none' } }}
              visible={!!controller.errorMessage}
              footer={false}
              closeIcon={<></>}
              width="334px"
            >
              <ErrorModalContentWrapper>
                <Typography
                  className="modal-error-message"
                  type={TypographyType.B2}
                  color={ColorPaletteType.GRAY2}
                  text={controller.errorMessage}
                />
                <div className="modal-ok-button" onClick={controller.okErrorModal}>
                  <Typography type={TypographyType.B1} color={ColorPaletteType.WHITE} text="확인" />
                </div>
              </ErrorModalContentWrapper>
            </Modal>
          </SignUpPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default SignUpPage;
