import styled from '@emotion/styled';
import { ReactComponent as ServerError } from '../assets/svg/500-error.svg';

const ServerErrorPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .error-container {
    width: 294px;
    height: 379px;
    border-radius: 43px;
    box-shadow: 6.650000095367432px 6.579999923706055px 28.290000915527344px 0px #0000001f;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(#fbfcfc, #fefefe);
  }

  .error-description {
    color: #818da4;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    width: 214px;
    height: 72px;
    margin-top: 1rem;
  }

  .error-back-button {
    width: 112px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eceef3;
    border-radius: 6px;
    color: #455573;
    font-weight: 700;
    font-size: 12px;
    margin-top: 2rem;
  }

  .error-back-button:hover {
    cursor: pointer;
  }
`;

const ServerErrorPage = () => {
  return (
    <ServerErrorPageWrapper>
      <div className="error-container">
        <ServerError />
        <div className="error-description">더 나은 서비스를 위해 시스템 점검 중입니다.</div>
      </div>
      <div className="error-back-button" onClick={() => window.location.replace('/')}>
        돌아가기
      </div>
    </ServerErrorPageWrapper>
  );
};

export default ServerErrorPage;
