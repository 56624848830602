import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

type TextLink = { title: string; route: string };

type TextLinkGroupProps = {
  dataSource: TextLink[];
};

const TextLinkGroupWrapper = styled.div`
  .link {
    font-size: 20px;
    color: #c2c2c2;
    border-color: #c2c2c2;

    &:not(style) ~ :not(style) {
      margin-inline-start: 32px;
    }
  }

  @media screen and (max-width: 992px) {
    text-align: center;

    .link {
      font-size: 18px;
      margin: 0px 16px;
    }
  }

  @media screen and (max-width: 450px) {
    .link {
      font-size: 14px;
    }
  }
`;

export const TextLinkGroup: React.FC<TextLinkGroupProps> = ({ dataSource }) => {
  return (
    <TextLinkGroupWrapper>
      {dataSource.map((item: TextLink) => (
        <NavLink
          className="link"
          to={item.route}
          activeStyle={{
            color: '#455573',
            borderBottom: '2px solid',
            fontWeight: 600,
            paddingBottom: '8px'
          }}
          key={item.title}
        >
          {item.title}
        </NavLink>
      ))}
    </TextLinkGroupWrapper>
  );
};
