import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useController } from './controller';
import { GeneralTemplate } from '../../templates';
import LearningStatus from '../../components/organisms/LearningStatus';
import { SectionLabel } from '../../components/atoms/Label';
import { TextButtonGroup } from '../../components/molecules/Group';
import { GeneralHeader } from '../../components/organisms/Header';
import { GotoWebsiteBanner, ApplicationBanner } from '../../components/organisms/MainPage';
import { CardNewsGroup } from '../../domain/lms/components/cardNews/CardNewsGroup';
import { OrderType } from '../../apollo/cardNews/type';

const MainPageWrapper = styled.div`
  .attendence-popup {
    width: 240px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    .attendence-popup__button-group {
      overflow: hidden;
      .attendence-popup__button {
        box-sizing: content-box;
        float: right;
        margin: 16px 16px 20px 0;
        width: 20px;
        height: 20px;
        background: none;
        border: none;
        cursor: pointer;
      }
    }
    .attendence-popup__title {
      font-size: 20px;
    }
    .attendence-popup__text {
      font-size: 14px;
      margin: 17px 0 14px;
    }
    .attendence-popup__time {
      font-size: 12px;
    }
    .attendence-popup__attendence {
      width: 168px;
      border: none;
      margin: 36px 0 32px;
      font-size: 14px;
      padding: 10px 0;
      background-color: #452cdd;
      color: #fff;
      border-radius: 4px;
    }
  }
  .body-wrapper {
    z-index: 7;
    .cardnews-content {
      margin: 20px 0px;
    }
  }
`;

const MainPage = () => {
  const history = useHistory();
  const {
    loading,
    error,
    cardNewsList,
    orderBy,
    displayApplicationStepLinkBanner,
    displayGotoWebsiteBanner,
    handleChangeOrdering,
    handleClickCardNews
  } = useController();

  return (
    <GeneralTemplate error={!!error} loading={loading}>
      {{
        header: <GeneralHeader />,
        body: (
          <MainPageWrapper>
            <div className="body-wrapper">
              {displayApplicationStepLinkBanner && (
                <ApplicationBanner onClick={() => history.push('/mypage#application')} />
              )}
              {displayGotoWebsiteBanner && (
                <GotoWebsiteBanner onClick={() => window.location.assign('https://codestates.com')} />
              )}
              <LearningStatus />

              <SectionLabel>Magazine</SectionLabel>
              <TextButtonGroup
                labelSource={[
                  { text: '최신순', value: undefined },
                  { text: '조회순', value: OrderType.DESC }
                ]}
                value={orderBy}
                handleOnChange={handleChangeOrdering}
              />
              <div className="cardnews-content">
                <CardNewsGroup cardNewsList={cardNewsList} handleClickCardNews={handleClickCardNews} />
              </div>
            </div>
          </MainPageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default MainPage;
