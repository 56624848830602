import queryAxios from '../../query/queryAxios';
import { ApplicationFullResponse } from '../../rest/generated';

interface Params {
  applicationUuid: string;
  userUuid: string;
}
export const getAdmissionApplicationCardRequest = async (params: Params) => {
  return await queryAxios()
    .get<ApplicationFullResponse>(`applicants/${params.userUuid}/applications/${params.applicationUuid}`)
    .then(data => data.data)
    .catch(err => console.log(err));
};
