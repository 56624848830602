import styled from '@emotion/styled';

import { ColorPalette, ColorPaletteType } from '../../../styles/color';
import { Typography, TypographyType } from '../../../components/atoms/Typography';
import { SizeType, StatusType } from '../../../components/molecules/Button/type';
import { Button } from '../../../components/molecules/Button';
import { ReactComponent as CalendarIco } from '../../../assets/svg/ico_calendar.svg';

const StyledBirthdayFormModal = styled.div`
  width: 100%;
  max-width: 334px;
  text-align: center;

  > * {
    width: 100%;
  }
  .txt__message {
    display: inline-block;
    width: auto;
  }
  .wrap__duration {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    color: #8c9cba;
    margin-top: 32px;
    .wrap__duration--input {
      text-align: left;
      border: 1px solid #dfe3ec;
      border-radius: 8px;
      width: 292px;
      height: 44px;
      padding: 8px 16px;
      &:focus-within {
        border: 1px solid #b8c9f5;
      }

      .img__duration {
        vertical-align: middle;
        margin-right: 12px;
      }

      .input__duration {
        border: none;
        margin-top: 2px;
        padding: 0;
        vertical-align: middle;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .txt__message--noti {
    display: block;
    text-align: left;
    margin: 12px 0 32px;
  }

  .birthday-input {
    margin-top: 20px;
    padding: 8px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-sizing: border-box;
    color: ${ColorPalette.Gray2};
    outline: none;
  }

  .validation-message {
    margin-top: 10px;
    padding-left: 10px;
    text-align: start;
  }
  .btn__confirm {
    background-color: #295ce0;
    width: 292px;
    height: 48px;
  }
  .btn__confirm--disabled {
    background-color: #f0f0f0;
  }
`;

type Props = {
  isBirthdayValid: boolean;
  birthdayInputValue: string;
  onBirthdayInputChange: (val: string) => void;
  onBirthdayFormSubmit: () => void;
};

export const BirthdayForm = ({
  isBirthdayValid,
  birthdayInputValue,
  onBirthdayInputChange,
  onBirthdayFormSubmit
}: Props) => {
  return (
    <StyledBirthdayFormModal>
      <Typography
        className="message"
        type={TypographyType.H2}
        color={ColorPaletteType.BLUISHGRAY4}
        text="증명서 발급을 위해"
      />
      <Typography className="txt__message" type={TypographyType.H2} color={ColorPaletteType.PURPLE} text="생년월일" />
      <Typography
        className="txt__message"
        type={TypographyType.H2}
        color={ColorPaletteType.BLUISHGRAY4}
        text="을 입력해주세요."
      />
      <div className="wrap__duration">
        <div className="wrap__duration--input">
          <CalendarIco className="img__duration" />
          <input
            type="text"
            className="input__duration"
            placeholder="yyyy.mm.dd"
            value={birthdayInputValue}
            onChange={e => onBirthdayInputChange(e.target.value)}
          />
        </div>
      </div>

      <Typography
        className="txt__message--noti"
        type={TypographyType.B2}
        color={ColorPaletteType.BLUISHGRAY5}
        text="* 형식에 맞춰 숫자만 입력해주세요."
      />

      <Button
        className={isBirthdayValid ? 'btn__confirm' : 'btn__confirm btn__confirm--disabled'}
        size={SizeType.BASIC}
        status={StatusType.ACTIVE}
        children={
          <Typography
            type={TypographyType.B1}
            color={isBirthdayValid ? ColorPaletteType.WHITE : ColorPaletteType.GRAY9}
            text={'발급 받기 >'}
          />
        }
        onClick={onBirthdayFormSubmit}
        disabled={!isBirthdayValid}
      />
    </StyledBirthdayFormModal>
  );
};
