import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';
import EmploymentEssentialForm from './EmploymentEssentialForm';
import EmploymentOptionalForm from './EmploymentOptionalForm';
import InterestDomainForm from './InterestDomainForm';

import type {
  JobPosition,
  FavorPosition,
  InterestDomain,
  EducationDetail,
  LanguageDetail,
  ActivityDetail,
  ExperienceDetail
} from '../apollo/jobs/type';
import { CareerStatus } from '../apollo/jobs/type';

export const MAX_FORM_MODAL_PAGE = 3;

enum PositionCategory {
  SOFTWARE = 1,
  DATA
}

type IJobProfileForm = {
  edit?: boolean;
  page: number;
  jobProfileFormModel: {
    jobPositions: JobPosition[];
    interestDomains: InterestDomain[];
    korName?: string;
    engFirstName?: string;
    engLastName?: string;
    mobile?: string;
    email?: string;
    birthday?: string;
    githubUserName?: string;
    blogURL?: string;
    portfolioURL?: string;
    favorPositions: FavorPosition[];
    jobProfileInterestDomains: InterestDomain[];
    skillSetInput: string;
    jobProfileSkillSetNames: string[];
    autoCompletedSkillSets: string[];
    changeSkillSetInput: (skillSetInput: string) => void;
    changeSkillSetNames: (skillSetNames: string[]) => void;

    /* extra field */
    careerStatus?: CareerStatus;
    totalCareerMonth?: number;
    lastExperienceSalary?: number;
    isLastExperienceSalaryPublic?: boolean;
    lastEducationStatus?: string;
    isNoInterestDomain?: boolean;

    korNameValid: (korName?: string) => boolean;
    engNameValid: (engName?: string) => boolean;
    emailValid: (email?: string) => boolean;
    idValid: (id?: string) => boolean;

    formDataValidCheck: (isGithubReq?: boolean) => boolean;

    handleCheckBox: (checked: boolean, position: FavorPosition) => void;
    handleInterestDomainCheckBox: (checked: boolean, interestDomain: InterestDomain) => void;
    handleNoInterestDomainCheckBox: (checked: boolean) => void;
    handleCreateJobProfile: () => Promise<void>;
    handleUpdateJobProfile: () => Promise<void>;

    createJobProfileLoading: boolean;
    updateJobProfileLoading: boolean;

    handleChangeKorName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeEngFirstName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeEngLastName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeMobile: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeBlogURL: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeBirthday: (dates: moment.Moment | null, dateString: string) => void;
    handleChangeGithubUserName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangePortfolioURL: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCareerStatus: (careerStatus: CareerStatus) => void;
    handleLastExperienceSalary: (event: React.ChangeEvent<HTMLInputElement> | undefined) => void;
    handleIsLastExperienceSalaryPublic: (checked: boolean) => void;
    handleChangePreviousPage: () => void;
    handleChangeNextPage: () => void;

    educationDetails: EducationDetail[];
    languageDetails: LanguageDetail[];
    activityDetails: ActivityDetail[];
    experienceDetails: ExperienceDetail[];
    changeEducationDetails: (educationDetails: EducationDetail[]) => void;
    changeLanguageDetails: (languageDetails: LanguageDetail[]) => void;
    changeActivityDetails: (actvityDetails: ActivityDetail[]) => void;
    changeExperienceDetails: (experienceDetails: ExperienceDetail[]) => void;
  };
};

export const JobProfileFormWrapper = styled.div`
  margin-top: -50px;
  .require-info {
    display: flex;

    @media screen and (max-width: 992px) {
      display: block;
    }

    .require-info-left {
      flex: 1;
      margin-right: 15px;

      @media screen and (max-width: 992px) {
        margin-right: 0px;
      }
    }

    .require-info-right {
      flex: 1;
      margin-left: 15px;

      @media screen and (max-width: 992px) {
        margin-left: 0px;
      }
    }
  }

  .input.job-position {
    max-width: 570px;
    width: 100%;

    .job-position__checkbox {
      margin-left: 0px;
    }
  }

  .input {
    width: 350px;
    margin: 10px 0px 25px 0px;

    .description.warning {
      font-size: 13px;
      color: red;
      margin: 5px 0px;
    }

    .description {
      font-size: 13px;
      color: #777;
      margin: 5px 0px;
    }

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  .message {
    background-color: #eff1f3;
    margin: 50px 0px;
    padding: 10px;

    .description {
      margin: 10px;
      font-size: 17px;
      font-weight: 600;
    }
  }

  .form-view {
    padding: 10px;
  }

  .modal-navigate-container {
    display: flex;
    justify-content: space-between;
  }

  .input.interest-domain {
    max-width: 570px;
    width: 100%;

    .interest-domain__checkbox {
      margin-left: 0px;
    }
  }

  .button {
    text-align: right;
  }
`;

export const SelectedItemBorad = styled.div`
  margin: 20px 0px 40px 0px;

  .item {
    margin: 5px 0px;
    color: #048cd6;
    font-weight: 500;
  }
`;

export const StyledButton = styled(Button)`
  margin: 20px 0px;
  font-size: 20px;
  font-weight: 600;
`;

const JobProfileForm: React.FC<IJobProfileForm> = ({ page, edit, jobProfileFormModel }) => {
  const {
    jobPositions,
    interestDomains,
    korName,
    engFirstName,
    engLastName,
    mobile,
    email,
    birthday,
    githubUserName,
    blogURL,
    portfolioURL,
    favorPositions,
    jobProfileInterestDomains,
    korNameValid,
    engNameValid,
    emailValid,
    idValid,
    formDataValidCheck,
    handleCheckBox,
    handleCreateJobProfile,
    handleUpdateJobProfile,

    createJobProfileLoading,
    updateJobProfileLoading,

    handleChangeKorName,
    handleChangeEngFirstName,
    handleChangeEngLastName,
    handleChangeEmail,
    handleChangeMobile,
    handleChangeBirthday,
    handleChangeBlogURL,
    handleChangeGithubUserName,
    handleChangePortfolioURL,
    handleInterestDomainCheckBox,
    handleNoInterestDomainCheckBox,
    handleCareerStatus,
    handleLastExperienceSalary,
    handleChangePreviousPage,
    handleChangeNextPage,

    careerStatus,
    lastExperienceSalary,
    isLastExperienceSalaryPublic,
    handleIsLastExperienceSalaryPublic,
    isNoInterestDomain,

    jobProfileSkillSetNames,
    autoCompletedSkillSets,
    skillSetInput,
    changeSkillSetInput,
    changeSkillSetNames,

    educationDetails,
    languageDetails,
    activityDetails,
    experienceDetails,
    changeEducationDetails,
    changeLanguageDetails,
    changeActivityDetails,
    changeExperienceDetails
  } = jobProfileFormModel;

  const switchFormView = (page: number) => {
    switch (page) {
      /* 채용 필수 정보 입력 */
      case 1:
        return (
          <EmploymentEssentialForm
            jobPositions={jobPositions}
            favorPositions={favorPositions}
            handleCheckBox={handleCheckBox}
            korName={korName}
            engFirstName={engFirstName}
            engLastName={engLastName}
            email={email}
            mobile={mobile}
            birthday={birthday}
            handleChangeKorName={handleChangeKorName}
            handleChangeEngFirstName={handleChangeEngFirstName}
            handleChangeEngLastName={handleChangeEngLastName}
            handleChangeEmail={handleChangeEmail}
            handleChangeMobile={handleChangeMobile}
            handleChangeBirthday={handleChangeBirthday}
            korNameValid={korNameValid}
            engNameValid={engNameValid}
            emailValid={emailValid}
            idValid={idValid}
            githubUserName={githubUserName}
            blogURL={blogURL}
            portfolioURL={portfolioURL}
            skillSetInput={skillSetInput}
            jobProfileSkillSetNames={jobProfileSkillSetNames}
            autoCompletedSkillSets={autoCompletedSkillSets}
            changeSkillSetInput={changeSkillSetInput}
            changeSkillSetNames={changeSkillSetNames}
            handleChangeBlogURL={handleChangeBlogURL}
            handleChangeGithubUserName={handleChangeGithubUserName}
            handleChangePortfolioURL={handleChangePortfolioURL}
            handleChangeNextPage={handleChangeNextPage}
            formDataValidCheck={formDataValidCheck}
          />
        );
      /* 채용 상세 정보 입력 */
      case 2:
        return (
          <EmploymentOptionalForm
            careerStatus={careerStatus}
            handleCareerStatus={handleCareerStatus}
            lastExperienceSalary={lastExperienceSalary}
            isLastExperienceSalaryPublic={isLastExperienceSalaryPublic}
            handleIsLastExperienceSalaryPublic={handleIsLastExperienceSalaryPublic}
            handleLastExperienceSalary={handleLastExperienceSalary}
            educationDetails={educationDetails}
            languageDetails={languageDetails}
            activityDetails={activityDetails}
            experienceDetails={experienceDetails}
            changeEducationDetails={changeEducationDetails}
            changeLanguageDetails={changeLanguageDetails}
            changeActivityDetails={changeActivityDetails}
            changeExperienceDetails={changeExperienceDetails}
            handleChangeNextPage={handleChangeNextPage}
            handleChangePreviousPage={handleChangePreviousPage}
          />
        );

      case 3:
      default:
        return (
          <InterestDomainForm
            edit={edit}
            interestDomains={interestDomains}
            isNoInterestDomain={isNoInterestDomain}
            jobProfileInterestDomains={jobProfileInterestDomains}
            handleInterestDomainCheckBox={handleInterestDomainCheckBox}
            handleNoInterestDomainCheckBox={handleNoInterestDomainCheckBox}
            handleChangePreviousPage={handleChangePreviousPage}
            handleCreateJobProfile={handleCreateJobProfile}
            handleUpdateJobProfile={handleUpdateJobProfile}
            createJobProfileLoading={createJobProfileLoading}
            updateJobProfileLoading={updateJobProfileLoading}
          />
        );
    }
  };

  return (
    <JobProfileFormWrapper>
      <div className="message">
        <div className="description">
          입력한 정보는 채용 연결을 위하여 코드스테이츠 파트너사에 전달됩니다.
          <br />
          프로필을 자세하게 적을수록 채용 연결 확률이 높아집니다.
          <br />
          (프로필은 작성 후 수정이 가능합니다.)
        </div>
      </div>
      <div className="form-view">{switchFormView(page)}</div>
    </JobProfileFormWrapper>
  );
};

export default JobProfileForm;
