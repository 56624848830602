import React from 'react';
import styled from '@emotion/styled';

import BannerCover from '../../../assets/image/motif.png';
import { ReactComponent as ArrowRight } from '../../../assets/svg/ico-arrow-right.svg';

type ApplicationBannerProps = {
  onClick?: () => void;
};

const ApplicationBannerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  padding: 20px;
  background: linear-gradient(rgba(32, 42, 69, 0.9), rgba(32, 42, 69, 0.9)), url(${BannerCover});

  background-size: cover;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  cursor: pointer;

  .banner-title {
    color: #eee;
    font-size: 20px;
    font-weight: 600;
    word-break: keep-all;
  }

  .banner-description {
    color: #afafaf;
    word-break: keep-all;
    font-size: 14px;
    font-weight: 500;
  }

  @media screen and (max-width: 600px) {
    display: block;
  }
`;

const ArrowButton = styled.div`
  text-align: right;
  margin-top: 20px;

  .nav-text {
    font-weight: 700;
    color: #cfcfcf;
    margin-right: 10px;
  }

  .arrow {
    width: 90px;
  }
`;

const ApplicationBanner: React.FC<ApplicationBannerProps> = ({ onClick }) => {
  return (
    <ApplicationBannerWrapper onClick={onClick}>
      <div>
        <div className="banner-title">지원중인 과정이 있습니다.</div>
        <div className="banner-description">코드스테이츠와 함께 새로운 커리어를 시작하세요.</div>
      </div>

      <ArrowButton>
        <span className="nav-text">계속 진행하기</span>
        <ArrowRight className="arrow" />
      </ArrowButton>
    </ApplicationBannerWrapper>
  );
};

export default ApplicationBanner;
