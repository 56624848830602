export enum PayMethodType {
  CARD = 'card',
  VBANK = 'vbank'
}

export interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  mobile: string;
}

export enum PaymentStatusType {
  STANDBY,
  SUCCESS,
  PAYMENT_FAIL,
  CONFIRM_FAIL,
  APPLY_SUCCESS
}

export interface IVbank {
  vbank_num: string;
  vbank_name: string;
  vbank_holder: string;
  vbank_date: number;
  paid_amount: string;
}
