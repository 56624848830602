import React from 'react';
import styled from '@emotion/styled';
import { Redirect } from 'react-router-dom';

import { useController } from './controller';
import { GeneralTemplate } from '../../../templates';

import LoadingPage from '../../../components/LoadingPage';
import JobProfileForm from '../common/components/JobProfileForm';

const NewJobProfilePageWrapper = styled.div`
  margin: 100px 0px;
`;

const NewJobProfilePage: React.FC = () => {
  const controller = useController();

  if (controller.loading) {
    return <LoadingPage />;
  }

  if (controller.hasError) {
    return <Redirect to="/" />;
  }

  if (controller.profile) {
    return <Redirect to="/jobs" />;
  }

  return (
    <GeneralTemplate>
      {{
        body: (
          <NewJobProfilePageWrapper>
            <JobProfileForm page={controller.page} jobProfileFormModel={controller.jobProfileFormModel} />
          </NewJobProfilePageWrapper>
        )
      }}
    </GeneralTemplate>
  );
};

export default NewJobProfilePage;
