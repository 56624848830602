import React from 'react';
import styled from '@emotion/styled';
import { Modal } from 'antd';

import { useController } from './controller';
import { GovFundingApplyForm, ImpactCampusCheckListForm, NewPaymentForm } from './organisms';

import ErrorPage from '../../components/ErrorPage';
import { ApplyFail, PaymentFail, PaymentConfirmFail } from '../../components/organisms/Payment';
import { GeneralHeader } from '../../components/organisms/Header';
import { GeneralTemplate } from '../../templates';

import { PaymentStatusType } from './type';

import type { Product } from '../../apollo/product/type';
import B2BApplyForm from './organisms/B2BApplyForm';

const ApplyPageWrapper = styled.div`
  .message {
    width: 100%;
    overflow-wrap: break-word;
    margin: 0px 0px 50px 10px;
  }
`;

// UR_W01_02_0101_지원하기
const ApplyPage: React.FC = () => {
  const {
    profile,
    product,
    slugUrlData,
    productLoading,
    productError,

    selectedPaymentModel,
    handleChangeSelectedPaymentModel,

    handlePaymentFormSubmit,
    isApplyMutationLoading,

    selectedScholarshipOption,
    isImpactCampusModalVisible,
    handleChangeScholarshipOption,
    closeApplyImpactCampusModal,
    handleApplyImpactCampus,

    handleApplyPaymentForm,

    resultStatus,
    setResultStatus
  } = useController();

  const isSoftskillCourse = slugUrlData?.slugUrl?.includes('softskill');
  const isResumeUploadProgram = !!(product?.program.id === 37);

  const displayResult = (type: PaymentStatusType, product: Product) => {
    switch (type) {
      case PaymentStatusType.STANDBY:
        return (
          <ApplyPageWrapper>
            {product.paymentModels.some(model => model.paymentType === 'GOV_FUNDING') ? (
              <GovFundingApplyForm
                loading={isApplyMutationLoading}
                productProgramId={product.program.id}
                productName={product.name}
                productDescription={product.description}
                productServiceStartDate={product.serviceStartDate}
                productServiceEndDate={product.serviceEndDate}
                hideDate={isSoftskillCourse || isResumeUploadProgram}
                handlePaymentFormSubmit={handlePaymentFormSubmit}
              />
            ) : product.paymentModels.some(model => model.paymentType === 'B2B') ? (
              <B2BApplyForm
                loading={isApplyMutationLoading}
                productName={product.name}
                productDescription={product.description}
                productServiceStartDate={product.serviceStartDate}
                productServiceEndDate={product.serviceEndDate}
                isCDSProgram={product.program.id === 33}
                hideDate={product.program.id === 33 || isSoftskillCourse || isResumeUploadProgram}
                handlePaymentFormSubmit={handlePaymentFormSubmit}
              />
            ) : (
              <div className="form__container">
                <Modal width={500} visible={isImpactCampusModalVisible} footer={null} closable={false}>
                  <ImpactCampusCheckListForm
                    loading={isApplyMutationLoading}
                    selectedScholarshipOption={selectedScholarshipOption}
                    handleChangeScholarshipOption={handleChangeScholarshipOption}
                    closeApplyImpactCampusModal={closeApplyImpactCampusModal}
                    handleApply={handleApplyImpactCampus}
                  />
                </Modal>
                <NewPaymentForm
                  loading={isApplyMutationLoading}
                  programId={product.program.id}
                  paymentModels={product.paymentModels}
                  selectedPaymentModel={selectedPaymentModel}
                  hideDate={isSoftskillCourse || isResumeUploadProgram}
                  handleChangeSelectedPaymentModel={handleChangeSelectedPaymentModel}
                  handlePaymentFormSubmit={handleApplyPaymentForm}
                  product={product}
                />
                {/*
                <PaymentForm
                  loading={isApplyMutationLoading}
                  programId={product.program.id}
                  productName={product.name}
                  productServiceStartDate={product.serviceStartDate}
                  productServiceEndDate={product.serviceEndDate}
                  paymentModels={product.paymentModels}
                  selectedPaymentModel={selectedPaymentModel}
                  hideDate={isSoftskillCourse || isResumeUploadProgram}
                  handleChangeSelectedPaymentModel={handleChangeSelectedPaymentModel}
                  selectedPayMethod={selectedPayMethod}
                  handleChangePayMethod={handleChangePayMethod}
                  handlePaymentFormSubmit={handlePaymentFormSubmit}
                />
                */}
              </div>
            )}
          </ApplyPageWrapper>
        );

      // ? ? 영역 Admission_프로세스개선_210224.pdf : 2-page
      case PaymentStatusType.PAYMENT_FAIL:
        return <PaymentFail setResultStatus={setResultStatus} />;

      // ? ? 영역 Admission_프로세스개선_210224.pdf : 2-page
      case PaymentStatusType.CONFIRM_FAIL:
        return <PaymentConfirmFail setResultStatus={setResultStatus} />;

      // TODO : PaymentStatusType 재설계 필요
      // apply/result/uuid 로 가지 못한 경우로, 시스템상 예상하지 못한 경우입니다.
      // Fail로 갑니다.

      // ㄴ 준열) 22년도에 gm요청으로 수정.결제가 없는 kdt는 result생략함

      case PaymentStatusType.APPLY_SUCCESS:
      case PaymentStatusType.SUCCESS:
      default:
        return <ApplyFail setResultStatus={setResultStatus} />;
    }
  };

  return (
    <GeneralTemplate fixedHeader={false} loading={productLoading || !profile} error={!!productError}>
      {{
        header: <GeneralHeader />,
        body: product ? displayResult(resultStatus, product) : <ErrorPage />
      }}
    </GeneralTemplate>
  );
};

export default ApplyPage;
