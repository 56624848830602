import styled from '@emotion/styled';

interface Props {
  children: React.ReactNode;
  color?: string;
}
export const TextButton = ({ children, color }: Props) => {
  return (
    <TextButtonContainer color={color}>{children}</TextButtonContainer>
  );
};

const TextButtonContainer = styled.button<{ color?: string }>`
  display: flex;
  align-items: center;
  background: inherit;
  border: none;
  box-shadow: none;
  overflow: visible;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.006em;

  color: ${({ color }) => color || '#455573'};

  &:hover {
    background-color: #f5f7f9;
    box-shadow: inset 0 0 0 1px transparent;
  }

  &:active {
    background-color: #eceff4;
  }
`;