import React from 'react';
import styled from '@emotion/styled';
import classnames from 'classnames';

import { GeneralHeader } from '../components/organisms/Header';

type ContentTemplateProps = {
  mobileMenuVisible: boolean;
  handleClickCloseMobileMenu: () => void;
  children: {
    header?: React.ReactNode;
    sidebar?: React.ReactNode;
    body: React.ReactNode;
  };
};

const ContentTemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: white;
`;

const HeaderWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
`;

const SidebarWrapper = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  height: 100%;

  @media (max-width: 993px) {
    display: none;
  }
`;
const MobileSidebarWrapper = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);

  &.show {
    display: block;
  }
`;

const BodyWrapper = styled.div`
  margin-top: 60px;
  padding-left: 320px;
  width: calc(100% - 36px);
  min-height: 100vh;
  background-color: #fff;

  @media (max-width: 993px) {
    margin-left: 0;
    padding-left: 0;
    width: 100%;
  }
`;

const ContentTemplate: React.FC<ContentTemplateProps> = ({
  mobileMenuVisible,
  handleClickCloseMobileMenu,
  children
}) => {
  const { header, sidebar, body } = children;

  return (
    <ContentTemplateWrapper>
      <HeaderWrapper>{header ? header : <GeneralHeader />}</HeaderWrapper>
      <SidebarWrapper>{sidebar ? sidebar : <></>}</SidebarWrapper>
      <MobileSidebarWrapper className={classnames({ show: mobileMenuVisible })} onClick={handleClickCloseMobileMenu}>
        {sidebar ? sidebar : null}
      </MobileSidebarWrapper>
      <BodyWrapper>{body}</BodyWrapper>
    </ContentTemplateWrapper>
  );
};

export default ContentTemplate;
