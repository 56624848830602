import gql from 'graphql-tag';

export const CREATE_PAYMENT_ORDER_GQL = gql`
  mutation createPaymentOrder($paymentModelId: Int!, $eventId: String!, $mediaType: String) {
    urclassCreatePaymentOrder(paymentModelId: $paymentModelId, eventId: $eventId, mediaType: $mediaType) {
      id
      merchantUID
      orderName
      totalAmount
      status
    }
  }
`;
export const CREATE_ONE_TIME_PAYMENT_ORDER_GQL = gql`
  mutation urclassCreateOneTimePaymentOrder($paymentModelId: Int!, $mediaType: String) {
    urclassCreateOneTimePaymentOrder(paymentModelId: $paymentModelId, mediaType: $mediaType) {
      id
      orderName
      merchantUID
      totalAmount
      status
    }
  }
`;
export const VALIDATE_PAYMENT_ORDER_GQL = gql`
  mutation validatePaymentOrder($input: Urclass_ValidatePaymentOrderInput!) {
    urclassValidatePaymentOrder(input: $input)
  }
`;

export const APPLY_GQL = gql`
  mutation appy($productId: Int!, $eventId: String!, $mediaType: String, $paymentType: PaymentType!) {
    urclassApply(productId: $productId, eventId: $eventId, mediaType: $mediaType, paymentType: $paymentType) {
      id
      uuid
      product{
        name
      }
      user{
        uuid
      }
    }
  }
`;
export const APPLY_WEWIN_GQL = gql`
  mutation applyWewin($paymentModelId: Int!, $eventId: String!, $mediaType: String) {
    urclassApplyWewin(paymentModelId: $paymentModelId, eventId: $eventId, mediaType: $mediaType) {
      id
      uuid
      product {
        name
      }
      user {
        uuid
      }
    }
  }
`;

export const APPLY_GOV_FUNDING_GQL = gql`
  mutation applyGovFunding($paymentModelId: Int!, $eventId: String!, $mediaType: String) {
    urclassApplyGovFunding(paymentModelId: $paymentModelId, eventId: $eventId, mediaType: $mediaType) {
      id
      uuid
      product {
        name
      }
      user {
        uuid
      }
    }
  }
`;

export const APPLY_B2B_GQL = gql`
  mutation applyB2B($paymentModelId: Int!, $eventId: String!, $mediaType: String) {
    urclassApplyB2B(paymentModelId: $paymentModelId, eventId: $eventId, mediaType: $mediaType) {
      id
      uuid
      product {
        name
      }
      user {
        uuid
      }
    }
  }
`;

export const APPLY_IMPACT_CAMPUS_GQL = gql`
  mutation applyImpactCampus($paymentModelId: Int!, $eventId: String!, $mediaType: String) {
    urclassApplyImpactCampus(paymentModelId: $paymentModelId, eventId: $eventId, mediaType: $mediaType) {
      id
      uuid
      product {
        name
      }
      user {
        uuid
      }
    }
  }
`;
