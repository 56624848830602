export type CardNews = {
  id: number;
  uuid: string;
  title: string;
  description: string;
  thumbnailURL: string;
  url: string;
  viewCount: number;
};

export enum OrderType {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type CardNewsOrderByOptions = {
  viewCount?: OrderType;
};

// query

export type CardNewsListVars = {
  page?: number;
  perPage?: number;
  orderBy?: CardNewsOrderByOptions;
};

export type CardNewsListData = {
  urclassCardNewsList: {
    items: CardNews[];
    total: number;
  };
};

export type GuestCardNewsListVars = {
  page?: number;
  perPage?: number;
  orderBy?: CardNewsOrderByOptions;
};

export type GuestCardNewsListData = {
  urclassGuestCardNewsList: {
    items: CardNews[];
    total: number;
  };
};

// mutation

export type IncreaseCardNewsViewCountVars = {
  cardNewsId: number;
};

export type IncreaseCardNewsViewCountData = {
  urclassIncreaseCardNewsViewCount: CardNews;
};
