import { ServerError } from './';

/*
 * Server Errors
 */

export const RejectCardCompany = new ServerError({
  title: 'reject-card-company',
  message: '카드 잔액이 부족하거나 재발급 등으로 결제 승인이 거절되었습니다.'
});

export const KakaoPhoneNumberIsRequired = new ServerError({
  title: 'kakao-phone-number-is-required',
  message: '연락처가 연결된 카카오톡 계정으로 다시 가입해 주세요.'
});

export const MustAgreeRequiredTerm = new ServerError({
  title: 'must-agree-required-term',
  message: '필수 항목에 동의하지 않으셨습니다.'
});

export const AlreadyCancelledApplication = new ServerError({
  title: 'already-cancelled-application',
  message: '이미 취소된 지원 입니다.'
});
export const ProductApplyingClosed = new ServerError({
  title: 'product-applying-closed',
  message: '상품 지원기간이 종료 되었습니다.'
});

const Errors = [
  RejectCardCompany,
  KakaoPhoneNumberIsRequired,
  MustAgreeRequiredTerm,
  AlreadyCancelledApplication,
  ProductApplyingClosed
];

export function getServerError(errTitle: string): ServerError {
  return (
    Errors.find((err: ServerError) => errTitle.includes(err.title)) ||
    new ServerError()
  );
}
