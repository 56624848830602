import moment from 'moment-timezone';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MY_LEARNING_STATUS_GQL } from '../../lms/apollo/attendance/query';
import {
  CheckInHistoryList,
  InActiveDayType,
  MyLearningStatusData,
  MyLearningStatusVar,
  ZkStatus,
  AttendanceType,
  QrStatus
} from '../../lms/apollo/attendance/type';
import { CHECK_IN_REQUEST_GQL, CHECK_OUT_REQUEST_GQL } from '../apollo/attendance/mutation';
import { makeToast } from '../../../util/makeToast';

// TODO : Somebody help this context type
const AttendanceStatusContext = createContext<{
  userName: string;
  zkType: ZkStatus;
  inActiveDayType?: InActiveDayType;
  isZkBtnClick: boolean;
  isCheckInZkPopupOpen: boolean;
  isCheckOutZkPopupOpen: boolean;
  isDataErr: boolean;
  isCheckInErr: boolean;
  isCheckOutErr: boolean;
  isUserCheckIn: boolean;
  isZkErr: boolean;
  isZkSwitch: boolean;
  checkInLoading: boolean;
  checkOutLoading: boolean;
  checkInHistoryData?: CheckInHistoryList;
  attendanceType?: AttendanceType;
  qrStatus?: QrStatus;
  bootcampClassName: string;
  qrImageUrl: string | null;
  backToMain: () => void;
  fetchMyLearningStatus: () => void;
  handleCheckIn: () => void;
  handleCheckOut: () => void;
  setIsDataErr: (isDataErr: boolean) => void;
  setIsUserCheckIn: (isUserCheckIn: boolean) => void;
  setIsZkBtnClick: (isZkBtnClick: boolean) => void;
  setIsCheckInZkPopupOpen: (isCheckInZkPopupOpen: boolean) => void;
  setIsCheckOutZkPopupOpen: (isCheckOutZkPopupOpen: boolean) => void;
  setIsZkSwitch: (isZkSwitch: boolean) => void;
  resetErr: () => void;
}>({
  userName: '',
  zkType: ZkStatus.NONE,
  attendanceType: AttendanceType.NONE,
  qrStatus: QrStatus.NONE,
  bootcampClassName: '',
  qrImageUrl: '',
  inActiveDayType: undefined,
  isZkBtnClick: false,
  isCheckInZkPopupOpen: false,
  isCheckOutZkPopupOpen: false,
  isCheckInErr: false,
  isCheckOutErr: false,
  isDataErr: false,
  isUserCheckIn: false,
  isZkErr: false,
  isZkSwitch: false,
  checkInLoading: false,
  checkOutLoading: false,
  checkInHistoryData: undefined,
  backToMain: () => {},
  fetchMyLearningStatus: () => {},
  handleCheckIn: () => {},
  handleCheckOut: () => {},
  resetErr: () => {},
  setIsZkBtnClick: (isZkBtnClick: boolean) => {},
  setIsDataErr: (isDataErr: boolean) => {},
  setIsUserCheckIn: (isUserCheckIn: boolean) => {},
  setIsCheckInZkPopupOpen: (isCheckInZkPopupOpen: boolean) => {},
  setIsCheckOutZkPopupOpen: (isCheckOutZkPopupOpen: boolean) => {},
  setIsZkSwitch: (isZkSwitch: boolean) => {}
});

export const useAttendanceStatus = () => {
  return useContext(AttendanceStatusContext);
};

export const AttendanceStatusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  //query & mutation
  const [fetchMyLearningStatus, { data, error }] = useLazyQuery<MyLearningStatusData, MyLearningStatusVar>(
    MY_LEARNING_STATUS_GQL,
    {
      fetchPolicy: 'network-only',
      variables: {
        perPage: 200,
        query: { gt: { revisionedAt: moment().hour(0).minute(0).second(0).millisecond(0).toDate() } }
      }
    }
  );

  const [makeCheckIn, { loading: checkInLoading }] = useMutation<MyLearningStatusData>(CHECK_IN_REQUEST_GQL);
  const [makeCheckOut, { loading: checkOutLoading }] = useMutation<MyLearningStatusData>(CHECK_OUT_REQUEST_GQL);

  const [userName, setUserName] = useState<string>('');
  const [zkType, setZkType] = useState<ZkStatus>(ZkStatus.NONE);
  const [inActiveDayType, setInActiveDayType] = useState<InActiveDayType | undefined>(undefined);
  const [isZkBtnClick, setIsZkBtnClick] = useState<boolean>(false);
  const [isCheckInErr, setIsCheckInErr] = useState<boolean>(false);
  const [isCheckOutErr, setIsCheckOutErr] = useState<boolean>(false);
  const [isDataErr, setIsDataErr] = useState<boolean>(false);
  const [isUserCheckIn, setIsUserCheckIn] = useState<boolean>(false);
  const [isCheckInZkPopupOpen, setIsCheckInZkPopupOpen] = useState<boolean>(false);
  const [isCheckOutZkPopupOpen, setIsCheckOutZkPopupOpen] = useState<boolean>(false);
  const [isZkSwitch, setIsZkSwitch] = useState<boolean>(false);
  const [checkInHistoryData, setCheckInHistoryData] = useState<CheckInHistoryList>();

  const [attendanceType, setAttendanceType] = useState<AttendanceType>(AttendanceType.NONE);
  const [bootcampClassName, setBootcampClassName] = useState<string>('');
  const [qrImageUrl, setQrImageUrl] = useState<string | null>('');
  const [qrStatus, setQrStatus] = useState<QrStatus>(QrStatus.NONE);

  const handleCheckIn = async () => {
    try {
      setIsZkSwitch(true);
      await setIsUserCheckIn(true);
      await makeCheckIn({ refetchQueries: [{ query: MY_LEARNING_STATUS_GQL }], awaitRefetchQueries: true });
      makeToast({
        type: 'success',
        content: (
          <div className="wrap__txt--toast">
            <strong>[입실 확인]</strong>
            <span className="txt__toast--sub">학습시간이 기록됩니다. (인정 시간: AM 8:30 ~ PM 6:30)</span>
          </div>
        )
      });
    } catch (err) {
      if (err.message === 'already-checkin-status') {
        setIsCheckInErr(true);
        return;
      } else {
        setIsDataErr(true);
        setIsUserCheckIn(false);
      }
    }
  };
  const handleCheckOut = async () => {
    try {
      await makeCheckOut({ refetchQueries: [{ query: MY_LEARNING_STATUS_GQL }], awaitRefetchQueries: true });
      setIsZkBtnClick(false);
      setIsCheckOutZkPopupOpen(false);
    } catch (err) {
      if (err.message === 'already-checkout-status') {
        setIsCheckOutZkPopupOpen(false);
        setIsCheckOutErr(true);
        return;
      } else {
        setIsDataErr(true);
        setIsUserCheckIn(true);
        setIsCheckOutZkPopupOpen(false);
        return;
      }
    }
  };

  const backToMain = () => {
    resetErr();
    fetchMyLearningStatus();
  };

  const resetErr = () => {
    setIsCheckInErr(false);
    setIsCheckOutErr(false);
    setIsDataErr(false);
  };

  const isZkErr = isCheckInErr || isCheckOutErr || isDataErr;

  useEffect(() => {
    if (data && data?.urclassMyLearning.bootcamp) {
      const {
        name,
        checkInOutConfig,
        myCheckInHistoryList,
        inActiveDayType,
        attendanceType,
        qrConfig,
        bootcamp
      } = data.urclassMyLearning;
      setUserName(name);
      setZkType(checkInOutConfig.zkStatus);
      setCheckInHistoryData(myCheckInHistoryList);
      setIsUserCheckIn(checkInOutConfig.isCheckIn);
      setInActiveDayType(inActiveDayType);
      setIsZkSwitch(isUserCheckIn);
      setAttendanceType(attendanceType);
      setQrImageUrl(qrConfig.qrCodeImageUrl);
      setQrStatus(qrConfig.qrStatus);
      setBootcampClassName(bootcamp.name);
    }
  }, [data]);

  return (
    <AttendanceStatusContext.Provider
      value={{
        userName,
        zkType,
        inActiveDayType,
        attendanceType,
        qrImageUrl,
        qrStatus,
        bootcampClassName,
        backToMain,
        handleCheckIn,
        handleCheckOut,
        checkInLoading,
        checkOutLoading,
        isZkBtnClick,
        isDataErr,
        isCheckInZkPopupOpen,
        isCheckOutZkPopupOpen,
        isCheckInErr,
        isCheckOutErr,
        isUserCheckIn,
        isZkErr,
        isZkSwitch,
        fetchMyLearningStatus,
        checkInHistoryData,
        setIsZkBtnClick,
        setIsUserCheckIn,
        setIsDataErr,
        setIsCheckInZkPopupOpen,
        setIsCheckOutZkPopupOpen,
        setIsZkSwitch,
        resetErr
      }}
    >
      {children}
    </AttendanceStatusContext.Provider>
  );
};
