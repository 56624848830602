import styled from '@emotion/styled';
import { Color } from '../../../style';

interface IOptionContainer {
  center?: boolean;
}

const OptionContainer = styled.div<IOptionContainer>`
  margin: 10px;
  padding: 20px 17px;
  text-align: ${({ center }) => (center ? 'center' : '')};
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: ${Color.gray};
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;

  .description {
    font-size: 13px;
    font-weight: 400;
    color: #666;
    margin: 5px 0px;
    word-break: keep-all;
  }

  &:hover {
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1), 0 1px 7px 0 rgba(0, 0, 0, 0.1);
  }

  &.selected {
    padding: 19px 16px;
    border-style: solid;
    border-width: 2px;
    border-color: #00b9ee;
    border-radius: 5px;
  }
`;

export default OptionContainer;
