import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Dropdown, Menu } from 'antd';

import { useController } from './controller';
import { GeneralTemplate } from '../../templates';

import { SocialAccountView, ApplicationCard } from './organisms';
import { GeneralHeader } from '../../components/organisms/Header';
import { Switch } from '../../components/molecules';

import { Typography, TypographyType } from '../../components/atoms/Typography';
import { Button } from '../../components/molecules/Button';
import { SizeType, StatusType } from '../../components/molecules/Button/type';

import { ReactComponent as ViewMore } from '../../assets/svg/ico-view-more.svg';
import { ReactComponent as KakaoIcon } from '../../assets/svg/ico-kakao.svg';
import { ColorPalette, ColorPaletteType } from '../../styles/color';

const MypageWrapper = styled.div`
  background: ${ColorPalette.White};
  margin: 54px 40px 0px 40px;
  padding: 57px;

  .view-more-mobile {
    display: none;
  }

  .view-more-wrapper {
    padding-left: 20px;
    cursor: pointer;
  }

  .my-info-wrapper {
    display: flex;

    .account {
      .welcome {
        display: flex;
        align-items: center;

        .placeholder {
          flex: 1;
        }
      }

      .profiles {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 35px;
        gap: 5px;
        .un-authenticated {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 53px;
          height: 27px;
          border-radius: 5px;
          background-color: #f2f2f2;
          .text {
            font-size: 13px;
            color: #df6955;
          }
        }
        .divider {
          color: #828282;
          font-size: 15px;
          font-weight: 400;
        }
        .mobile {
          color: #828282;
          font-size: 15px;
          font-weight: 400;
        }
      }

      .edit-user-profile {
        margin-top: 10px;
        text-decoration-line: underline;
        color: #333333;
      }
      .kakao-auth {
        margin: 12px 0;

        .kakao-auth-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 176px;
          height: 40px;
          border: none;
          border-radius: 6px;
          background-color: ${ColorPalette.KakaoYellow};
          color: #531f14;
          letter-spacing: 0.025em;
          &:hover {
            cursor: pointer;
          }
          .ico__kakao {
            margin-right: 8px;
          }
        }
      }
    }

    .toolbar {
      padding-top: 6px;
      flex: 1;
      display: flex;
      flex-direction: column;

      .toolbar-row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .attendance {
        margin-left: 30px;

        .attendance-label {
          display: inline-block;
          padding-top: 1px;
          color: #455573;
        }

        .attendance-switch {
          display: inline-block;
          margin: 3px 0 0 5px;

          vertical-align: top;
        }
      }
    }
  }

  .mypage-no-aplication {
    text-align: center;

    .mypage-no-aplication__text-group {
      margin-top: 130px;
      margin-bottom: 31px;
    }
    .mypage-no-aplication__button {
      margin-bottom: 36px;
    }
  }

  .mypage-application-group {
    margin-top: 26px;

    display: grid;

    grid-template-columns: repeat(auto-fit, 308px);
    grid-auto-rows: 1fr;
    column-gap: 33px;
    row-gap: 29px;
  }

  /* mobile */
  @media screen and (max-width: 1094px) {
    margin: 54px 0px;
    padding: 0px 20px;

    .view-more-mobile {
      display: block;
    }

    .view-more-pc {
      display: none;
    }

    .my-info-wrapper {
      flex-direction: column;

      .account {
        .profiles {
          margin-top: 12px;
          align-items: flex-start;
          flex-direction: column;
          .divider {
            display: none;
          }
        }
      }

      .toolbar {
        display: block;

        .toolbar-row {
          display: block;
          &:first-of-child {
            margin-top: 50px;
          }
          .social {
            display: block;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    margin: 54px 0px;
    padding: 0px;
  }
`;

const MyPage: React.FC = () => {
  const controller = useController();
  const {
    applications,

    cohorts,

    isGithubAccountNeed,
    githubAuthText,
    handleChangeGithubAuthText,

    isToggleAuthCheckIcon,
    handleChangeIsToogleCheckIcon,

    mypageError,
    mypageLoading,

    profile,
    loadingProfile,

    handleDeleteAccount
  } = controller;

  const isActiveStudent = !!cohorts.length;

  return (
    <GeneralTemplate>
      {{
        loading: mypageLoading || loadingProfile || !profile,
        error: !!mypageError,
        header: <GeneralHeader />,
        body: (
          <React.Fragment>
            <MypageWrapper>
              <div>
                <div className="my-info-wrapper">
                  <div className="account">
                    <div className="welcome">
                      <Typography
                        className="welcome-text"
                        type={TypographyType.H1}
                        color={ColorPaletteType.GRAY1}
                        text={profile ? profile.name : ''}
                      />
                      <Typography
                        className="welcome-text"
                        type={TypographyType.H2}
                        color={ColorPaletteType.GRAY1}
                        text="님, 환영합니다!"
                      />
                      <div className="placeholder" />
                      <Dropdown
                        placement="bottomLeft"
                        overlay={
                          <Menu>
                            <Menu.Item key="0">
                              <Link to={`/mypage/certificates`}>증명서 발급</Link>
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={['click']}
                      >
                        <div className="view-more-mobile">
                          <div className="view-more-wrapper">
                            <ViewMore className="view-more" />
                          </div>
                        </div>
                      </Dropdown>
                    </div>
                    <div className="profiles">
                      <Typography
                        className="email"
                        type={TypographyType.B1}
                        color={ColorPaletteType.GRAY2}
                        text={profile?.email || ''}
                      />
                      {profile && !profile.isEmailVerified && (
                        <div className="un-authenticated">
                          <span className="text">미인증</span>
                        </div>
                      )}
                      <span className="divider">|</span>

                      <span className="mobile">{profile?.mobile}</span>
                    </div>
                    <div>
                      {profile && !profile.kakaoId && (
                        <div className="kakao-auth">
                          <button className="kakao-auth-btn" onClick={controller.handleKakaoAuthentication}>
                            <KakaoIcon className="ico__kakao" />
                            <Typography
                              className="mypage-no-aplication__text"
                              type={TypographyType.B4}
                              color={ColorPaletteType.BROWN}
                              text="카카오 계정 연결하기"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                    <div>
                      <Link className="edit-user-profile" to="/user-profile/edit">
                        회원 정보 수정
                      </Link>
                    </div>
                  </div>
                  <div className="toolbar">
                    <div className="toolbar-row">
                      {isActiveStudent ? (
                        <div className="social">
                          <SocialAccountView
                            isGoogleAuthenticated={controller.isGoogleAuthenticated}
                            isGithubAccountNeed={isGithubAccountNeed}
                            isGithubAuthenticated={!!profile?.githubId}
                            handleDeleteAccount={handleDeleteAccount}
                            githubAuthText={githubAuthText}
                            handleChangeGithubAuthText={handleChangeGithubAuthText}
                            isToggleAuthCheckIcon={isToggleAuthCheckIcon}
                            handleChangeIsToogleCheckIcon={handleChangeIsToogleCheckIcon}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {profile?.useAttendanceFeature && (
                        <div className="attendance">
                          <Typography
                            className="attendance-label"
                            type={TypographyType.B3}
                            color={ColorPaletteType.GRAY2}
                            text={profile?.isWorking ? '교실 퇴장' : '교실 입장'}
                          />
                          <Switch
                            className="attendance-switch"
                            checked={profile?.isWorking}
                            onClick={() => {
                              if (profile?.isWorking) {
                                controller.handleLeaveWork();
                              } else {
                                controller.handleAttendWork();
                              }
                            }}
                          />
                        </div>
                      )}
                      <Dropdown
                        placement="bottomLeft"
                        overlay={
                          <Menu>
                            <Menu.Item key="0">
                              <Link to={`/mypage/certificates`}>증명서 발급</Link>
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={['click']}
                      >
                        <div className="view-more-pc">
                          <div className="view-more-wrapper">
                            <ViewMore className="view-more" />
                          </div>
                        </div>
                      </Dropdown>
                    </div>
                    <div className="toolbar-row"></div>
                  </div>
                </div>

                {applications.length === 0 ? (
                  <div className="mypage-no-aplication">
                    <div className="mypage-no-aplication__text-group">
                      <Typography
                        className="mypage-no-aplication__text"
                        type={TypographyType.H3}
                        color={ColorPaletteType.GRAY2}
                        text="아직 지원중인 코스가 없습니다."
                      />
                      <Typography
                        className="mypage-no-aplication__text"
                        type={TypographyType.H3}
                        color={ColorPaletteType.GRAY2}
                        text="코드스테이츠 웹사이트에서 코스 지원을 해주세요."
                      />
                    </div>
                    <Button
                      className="mypage-no-aplication__button"
                      size={SizeType.BASIC}
                      status={StatusType.ACTIVE}
                      children={
                        <a href="http://codestates.com/" target="_blank" rel="noopener noreferrer">
                          <Typography type={TypographyType.B1} color={ColorPaletteType.WHITE} text="코스 지원하기" />
                        </a>
                      }
                    />
                    <Typography
                      className="mypage-no-aplication__text"
                      type={TypographyType.B2}
                      color={ColorPaletteType.GRAY1}
                      text="※ 결제 대기중인 코스는 결제 완료 후에 표시됩니다. "
                    />
                  </div>
                ) : (
                  <div className="mypage-application-group">
                    {applications.map(application => (
                      <ApplicationCard key={application.uuid} application={application} />
                    ))}
                  </div>
                )}
              </div>
            </MypageWrapper>
          </React.Fragment>
        )
      }}
    </GeneralTemplate>
  );
};

export default MyPage;
