export default function Urls(path = '') {
  const port = window.location.port;

  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return {
        http: `https://server.urclass.io/${path}`,
        admin: `https://admin.codestates.com/${path}`,
        urclass: `https://urclass.codestates.com/${path}`,
        partners: `https://partners.codestates.com/${path}`,
        zendesk: `https://help-urclass-codestates.zendesk.com/hc/ko`,
        admission: `https://api.admission.codestates.com/applier/v1/${path}`,
        javaAdmission: `https://api-admission.codestates.com/api/v2/${path}`
      };
    case 'local':
      return {
        http: `http://localhost:3002/${path}`,
        admin: `http://admin.localhost:${port}/${path}`,
        urclass: `http://localhost:${port}/${path}`,
        partners: `http://partners.localhost:${port}/${path}`,
        zendesk: `https://help-urclass-codestates.zendesk.com/hc/ko`,
        admission: `http://localhost:6002/applier/v1/${path}`,
        javaAdmission: `http://localhost:8080/api/v2/${path}`
      };
    case 'dev':
      return {
        http: `https://dev-server.urclass.io/${path}`,
        admin: `https://dev-admin.codestates.com/${path}`,
        urclass: `https://dev-urclass.codestates.com/${path}`,
        partners: `https://dev-partners.codestates.com/${path}`,
        zendesk: `https://help-urclass-codestates.zendesk.com/hc/ko`,
        admission: `http://localhost:6002/applier/v1/${path}`,
        javaAdmission: `http://localhost:8080/api/v2/${path}`
      };
    case 'qa':
      return {
        http: `https://qa-server.urclass.io/${path}`,
        admin: `https://staging-admin.codestates.com/${path}`,
        urclass: `https://staging-urclass.codestates.com/${path}`,
        partners: `https://qa-partners.codestates.com/${path}`,
        zendesk: `https://help-urclass-codestates.zendesk.com/hc/ko`,
        admission: ` https://api.admission.codestates.xyz/applier/v1/${path}`,
        javaAdmission: `https://dev-api.admission.codestates.xyz/api/v2/${path}`
      };
    case 'qa2':
      return {
        http: `https://qa2-server.urclass.io/${path}`,
        admin: `https://qa2-admin.codestates.com/${path}`,
        urclass: `https://qa2-urclass.codestates.com/${path}`,
        partners: `https://qa2-partners.codestates.com/${path}`,
        zendesk: `https://help-urclass-codestates.zendesk.com/hc/ko`,
        admission: ` https://api.admission.codestates.xyz/applier/v1/${path}`,
        javaAdmission: `https://dev-api.admission.codestates.xyz/api/v2/${path}`
      };
    case 'exp-qa':
      return {
        http: `https://qa-lms-integrate-server.urclass.io/${path}`,
        admin: `https://qa-lms-integrate-admin.codestates.com/${path}`,
        urclass: `https://qa-lms-integrate-urclass.codestates.com/${path}`,
        partners: `https://qa-lms-integrate-partners.codestates.com/${path}`,
        zendesk: `https://help-urclass-codestates.zendesk.com/hc/ko`,
        admission: ` https://api.admission.codestates.xyz/applier/v1/${path}`,
        javaAdmission: `https://dev-api.admission.codestates.xyz/api/v2/${path}`
      };
    default:
      return {
        http: `http://localhost:3002/${path}`,
        admin: `http://admin.localhost:${port}/${path}`,
        urclass: `http://localhost:${port}/${path}`,
        partners: `http://partners.localhost:${port}/${path}`,
        zendesk: `https://help-urclass-codestates.zendesk.com/hc/ko`,
        admission: `http://localhost:6002/applier/v1/${path}`,
        javaAdmission: `https://dev-api.admission.codestates.xyz/api/v2/${path}`
      };
  }
}
