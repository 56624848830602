import React from 'react';
import styled from '@emotion/styled';

type IGeneralHeaderTemplate = {
  children: {
    header?: React.ReactNode;
    body: React.ReactNode;
    footer?: React.ReactNode;
  };
  fullResponsive?: boolean;
};

const GeneralHeaderTemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const BodyWrapper = styled.div<{ fullResponsive?: boolean }>`
  flex: 1;
  min-width: ${({ fullResponsive }) => (fullResponsive ? '0px' : '1200px')};
  margin: 0 auto 50px auto;
`;

const GeneralHeaderTemplate: React.FC<IGeneralHeaderTemplate> = ({ children, fullResponsive }) => {
  const { header, body, footer } = children;
  return (
    <GeneralHeaderTemplateWrapper>
      <div>{header}</div>
      <BodyWrapper fullResponsive={fullResponsive}>{body}</BodyWrapper>
      {footer}
    </GeneralHeaderTemplateWrapper>
  );
};

export default GeneralHeaderTemplate;
