import { useQuery } from '@apollo/react-hooks';
import { Result } from 'antd';

import ErrorPage from '../../components/ErrorPage';
import LoadingPage from '../../components/LoadingPage';

import { PayMethod } from '../../apollo/payment/type';
import { GET_PAYMENTORDER_BY_MERCHANT_UID_GQL } from '../../apollo/payment/query';

import { CardResult } from './presentation/cardResult';
import { VbankResult } from './presentation/vbankResult';

import type {
  PaymentOrderData,
  PaymentOrderVar,
} from '../../apollo/payment/type';

const PaymentResultPage = () => {
  const param = new URLSearchParams(window.location.search);
  const impUID = param.get('imp_uid') || '';

  const { data, loading, error } = useQuery<PaymentOrderData, PaymentOrderVar>(
    GET_PAYMENTORDER_BY_MERCHANT_UID_GQL,
    {
      skip: !impUID,
      variables: { impUID }
    }
  );

  if (!impUID) {
    return <ErrorPage errorList={['잘못 된 접근이에요']}></ErrorPage>;
  }

  if (loading) return <LoadingPage />;

  // payment order 생성 안되어 있음.
  if (error || !data) {
    return (
      <Result
        status="error"
        title="결제 실패"
        subTitle="communication@codestates.com 으로 문의 바랍니다."
        extra={<div>결제 주문 생성에 실패 했어요</div>}
      />
    );
  }
  const isSuccess = param.get('is_success');

  // 결제 금액 검증 실패
  if (isSuccess === 'false') {
    return (
      <Result
        status="error"
        title="잘못된 결제 입니다."
        extra={<div>다시 시도 해주세요.</div>}
      />
    );
  }
  const {
    vbankDate,
    vbankHolder,
    vbankName,
    vbankNum,
    totalAmount,
    orderName,
    payMethod
  } = data.urclassPaymentOrderByImpUID;
  const isVbank = payMethod === PayMethod.VBANK;

  return isVbank ? (
    <VbankResult
      vbankDate={vbankDate}
      vbankHolder={vbankHolder}
      vbankName={vbankName}
      vbankNum={vbankNum}
      totalAmount={totalAmount}
      orderName={orderName}
    />
  ) : (
    <CardResult orderName={orderName} totalAmount={totalAmount} />
  );
};

export default PaymentResultPage;
