import queryAxios from '../../query/queryAxios';
import {
  AnswerChangeStateRequest,
  ApplicationAnswerSaveRequest,
  ApplicationAnswerWithoutResponse,
  ApplicationFullResponse
} from '../../rest/generated';
export interface CreateApplicationAnswerBySurveyIdRequest {
  applicationUuid: string;
  userUuid: string;
  itemId?: number;
}

export interface UpdateApplicationAnswerRequest {
  params: {
    applicationUuid: string;
    userUuid: string;
    answerId?: number;
  };
  request: ApplicationAnswerSaveRequest;
}

export interface UpdateApplicationAnswerStatusRequest {
  params: {
    applicationUuid: string;
    userUuid: string;
    answerId: number;
  };
  request: AnswerChangeStateRequest;
  queries?: {
    eventId: string;
    contentName: string;
    courseName: string;
  };
}

interface UploadFileResponse {
  id: number;
  originalName: string;
  size: number;
  contentType: string;
  url: string;
}

export const getApplicationDetailRequest = async (userUuid: string, applicationUuid: string) => {
  return await queryAxios().get<ApplicationFullResponse>(`applicants/${userUuid}/applications/${applicationUuid}`);
};

export const createApplicationAnswerRequest = async (request: CreateApplicationAnswerBySurveyIdRequest) => {
  const { userUuid, applicationUuid, itemId } = request;
  return await queryAxios().post<ApplicationAnswerWithoutResponse>(
    `applicants/${userUuid}/applications/${applicationUuid}/template-items/${itemId}/answers`
  );
};

export const updateApplicationAnswerRequest = async (variables: UpdateApplicationAnswerRequest) => {
  const { params, request } = variables;
  const { applicationUuid, userUuid, answerId } = params;

  return await queryAxios().put<ApplicationAnswerWithoutResponse>(
    `applicants/${userUuid}/applications/${applicationUuid}/answers/${answerId}`,
    request
  );
};

export const updateApplicationAnswerStatusRequest = async (variables: UpdateApplicationAnswerStatusRequest) => {
  const { params, request } = variables;
  const queries = variables.queries;
  const { applicationUuid, userUuid, answerId } = params;

  return await queryAxios().patch<ApplicationAnswerWithoutResponse>(
    `applicants/${userUuid}/applications/${applicationUuid}/answers/${answerId}/state-change`,
    request,
    {
      params: {
        contentName: queries?.contentName,
        courseName: queries?.courseName,
        eventId: queries?.eventId
      }
    }
  );
};

export const uploadFiles = async ({
  folderCategory,
  payload
}: {
  folderCategory: 'CODESHIP' | 'ADMISSION';
  payload: FormData;
}) => {
  return queryAxios().post<Array<UploadFileResponse>>('files', payload, {
    params: { folderCategory }
  });
};

export const deleteFile = async ({ fileId }: { fileId: number }) => {
  return queryAxios().delete(`files/${fileId}`);
};
