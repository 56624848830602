import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';

import { Divider } from '../../../components/atoms';
import { PaymentModel, Product } from '../../../apollo/product/type';
import { mapPaymentTypeToText } from '../../../util/textMapper/mapPaymentTypeToText';
import { numberFormatter } from '../../../util';

const FlexBox = styled.div`
  display: flex;
  width: 100%;
`;

const NewPaymentFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const DecidePaymentTypeLayout = styled.div`
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PaymentCardContainer = styled.div`
  border-radius: 10px;
  border: 1px solid var(--Gray6, #f2f2f2);
  background: var(--White, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Large3x = styled.div`
  color: #1e1e1e;

  /* Large3x 700 */
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;
const H3 = styled.div<{ bold?: boolean }>`
  color: var(--Gray-1, #333);
  text-align: center;

  /* H3_Bold */
  font-family: Spoqa Han Sans Neo;
  font-size: 20px;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  line-height: normal;
`;

const N2 = styled.div`
  color: var(--Gray-3, #828282);

  /* N2 */
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const B1 = styled.div`
  color: var(--Gray-1, #333);

  /* B1_Bold */
  font-family: Spoqa Han Sans Neo;
  font-size: 15px;
  font-style: normal;
  line-height: 24px;
`;

const B1_List = styled.li`
  color: var(--Gray-1, #333);
  list-style: numeric;

  /* B1_Bold */
  font-family: Spoqa Han Sans Neo;
  font-size: 15px;
  font-style: normal;
  line-height: 24px;
  text-indent: -15px;
  margin-left: 15px;
`;
const B2 = styled.div`
  color: var(--Gray-3, #828282);

  /* B2 */
  font-family: Spoqa Han Sans Neo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const NormalText = styled.div`
  color: var(--White, #fff);
  /* Normal 500 */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
`;

const SelectBox = styled.button<{ selected?: boolean }>`
  border-radius: 5px;
  background: var(--White, #fff);
  padding: 10px 15px;
  width: 100%;
  text-align: left;
  border: 2px solid ${({ selected }) => (selected ? 'var(--Light-Blue, #47BDFF)}' : 'var(--Gray-5, #E0E0E0)')};
`;

const PaymentTypeDescription = styled.span`
  color: var(--bluish-gray-bluish-gray-600, #667ba3);

  /* Normal 500 */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;

const PaymentTypeTitle = styled.span`
  color: var(--bluish-gray-bluish-gray-800, #262f40);

  /* Normal 700 */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

const PrimaryButton = styled.button`
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  width: 100%;
  background: var(--purple-purple-600, #452cdd);
  :hover {
    cursor: pointer;
  }
`;

const MarginBottom = styled.div<{ size?: number }>`
  margin-bottom: ${({ size }) => (size ? size : 0)}px;
`;

interface NewPaymentFormProps {
  loading: boolean;
  programId: number;
  hideDate?: boolean;

  paymentModels: PaymentModel[];
  selectedPaymentModel?: PaymentModel;
  handleChangeSelectedPaymentModel: (paymentModel: PaymentModel) => void;
  handlePaymentFormSubmit: () => void;
  product: Product;
  productName?: string;
}

const NewPaymentForm: React.FC<NewPaymentFormProps> = ({
  product,
  productName,
  paymentModels,
  selectedPaymentModel,
  handleChangeSelectedPaymentModel,
  handlePaymentFormSubmit
}) => {
  return (
    <NewPaymentFormWrapper>
      <DecidePaymentTypeLayout>
        <Large3x>결제 방식을 선택하세요.</Large3x>
        <MarginBottom size={38} />
        <NormalText style={{ color: '#1E1E1E', fontWeight: 600, textAlign: 'center' }}>
          지원 절차가 모두 완료되고, 지원 과정에 합격하면 결제는 그 다음에 해요.
        </NormalText>
        <MarginBottom size={38} />
        <PaymentCardContainer>
          <MarginBottom size={32} />
          <FlexBox style={{ justifyContent: 'center' }}>
            <H3 bold={true}>{productName || product.name} </H3>
          </FlexBox>
          <MarginBottom size={8} />
          <N2>
            {moment(product.serviceStartDate).format('YYYY.MM.DD') +
              '-' +
              moment(product.serviceEndDate).format('YYYY.MM.DD')}
          </N2>
          <MarginBottom size={28} />
          <B2 style={{ width: '100%' }}>결제모델 선택</B2>
          <MarginBottom size={9} />
          {paymentModels.map((paymentModel, index) => (
            <>
              <SelectBox
                selected={selectedPaymentModel?.paymentType === paymentModel.paymentType}
                onClick={handleChangeSelectedPaymentModel.bind(null, paymentModel)}
              >
                <PaymentTypeDescription>{paymentModel.name} </PaymentTypeDescription>
                <PaymentTypeTitle>{mapPaymentTypeToText(paymentModel.paymentType)}</PaymentTypeTitle>
              </SelectBox>
              {index !== paymentModels.length - 1 && <MarginBottom size={9} />}
            </>
          ))}
          <MarginBottom size={26} />
          <Divider color="#E0E0E0" />
          <MarginBottom size={15} />

          {selectedPaymentModel?.paymentType === 'UPFRONT' ? (
            <>
              <FlexBox style={{ flexDirection: 'column', padding: '0px 13px' }}>
                <FlexBox style={{ justifyContent: 'space-between' }}>
                  <B2 style={{ color: '#333' }}>총 상품 금액</B2>
                  <N2>{numberFormatter(selectedPaymentModel.originPrice)}원</N2>
                </FlexBox>
                <MarginBottom size={6} />
                <FlexBox style={{ justifyContent: 'space-between' }}>
                  <B2 style={{ color: '#333' }}>할인 금액</B2>
                  <N2 style={{ color: '#FD1808' }}>
                    -{numberFormatter(selectedPaymentModel.originPrice - selectedPaymentModel.price)}원
                  </N2>
                </FlexBox>
              </FlexBox>
              <MarginBottom size={13} />
              <Divider color="#E0E0E0" />
              <FlexBox style={{ flexDirection: 'column', padding: '0px 13px' }}>
                <MarginBottom size={12} />
                <FlexBox style={{ justifyContent: 'space-between' }}>
                  <B2>최종 결제 금액</B2>
                  <B1 style={{ fontWeight: 'bold' }}>{numberFormatter(selectedPaymentModel.price)}원</B1>
                </FlexBox>
                <MarginBottom size={42} />
              </FlexBox>
            </>
          ) : selectedPaymentModel?.paymentType === 'WEWIN' ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <B2 style={{ width: '100%' }}>최종 결제 금액</B2>
              <MarginBottom size={19} />
              {/*TODO : 결제 모델에 따른 값 구분 필요  */}
              <B1 style={{ fontWeight: 700 }}>월 소득이 275만원 이상 일 때 (세전 기준), </B1>
              <B1 style={{ fontWeight: 700 }}>55만원씩 12회에 걸쳐 분할 납부합니다.</B1>

              <MarginBottom size={50} />
            </div>
          ) : (
            <>
              <B2 style={{ width: '100%' }}>지원자격</B2>
              <MarginBottom size={19} />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <B1_List style={{ fontWeight: 700 }}>
                  경제적 상황에 관계없이 잠재력을 펼치고 싶은 분 : 저소득층(중위소득 75%)
                </B1_List>
                <B1_List style={{ fontWeight: 700 }}>고등학교 졸업자 및 대학교 중퇴자</B1_List>
                <B1_List style={{ fontWeight: 700 }}>테크 분야에서 영향력을 넓히고 싶은 여성</B1_List>
              </div>
              <MarginBottom size={30} />
            </>
          )}
          <PrimaryButton onClick={handlePaymentFormSubmit}>
            <NormalText>선택한 결제 방식으로 지원 시작하기</NormalText>
          </PrimaryButton>
        </PaymentCardContainer>
      </DecidePaymentTypeLayout>
    </NewPaymentFormWrapper>
  );
};
export default NewPaymentForm;
