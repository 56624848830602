import '@react-pdf-viewer/core/lib/styles/index.css';
import moment from 'moment';
import Loader from './Loader';
import styled from '@emotion/styled';

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { BlobProvider } from '@react-pdf/renderer';
import { useQuery } from '@apollo/react-hooks';

import { useGlobalMessage } from '../../../contexts/globalMessage';

import { CERTIFICATE_GQL } from '../../../apollo/certificate/query';

import { PDFDocument as EduCertDocs } from '../pdfs/EduCertPdfs';

import { ReactComponent as DownloadIco } from '../../../assets/svg/ico-download.svg';

import type { CertificateData, CertificateVars } from '../../../apollo/certificate/type';
const ViewerWrapper = styled.div`
  margin-top: 50px;
  text-align: right;
  .wrap__link {
    margin: 0 auto 30px;
    max-width: 650px;
    .link__back {
      width: 112px;
      height: 32px;
      padding: 7px 0;
      vertical-align: middle;
      display: inline-block;
      text-align: center;
      background-color: #eceef3;
      color: #455573;
      font-size: 12px;
      font-weight: 700;
      border-radius: 6px;
    }
    .link__download {
      width: 134px;
      height: 32px;
      padding: 7px 0;
      vertical-align: middle;
      display: inline-block;
      text-align: center;
      background-color: #452ccd;
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      border-radius: 6px;
      margin-left: 12px;
      .ico__download {
        width: 17px;
        height: 17px;
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }
`;

export const EduCertificateComp = () => {
  const history = useHistory();
  const { displayGlobalMessage } = useGlobalMessage();

  const searchParams = new URLSearchParams(history.location.search);

  const cohortUuid = searchParams.get('cohort_uuid') || '';

  const isSkipQuery = !cohortUuid;

  const { data, loading, error } = useQuery<CertificateData, CertificateVars>(CERTIFICATE_GQL, {
    variables: { cohortUuid },
    skip: isSkipQuery,
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data) {
      //조회 해보니
      if (!data.urclassMe.birthday || !moment(data.urclassMe.birthday).isValid()) {
        // 유저 생일 데이터가 온전치 못하거나
        displayGlobalMessage('생년월일 데이터가 유효하지 않습니다.');
      }

      if (!data.urclassCohort.certIssuable) {
        // 증명서 발급 지원 하지 않는 개강반
        displayGlobalMessage('잘못 된 접근 입니다.');
      }
    }

    if (!data && error) {
      // 정보 조회가 안되거나
      displayGlobalMessage('잘못 된 접근 입니다');
    }
  }, [data, error]);

  const isRenderPDF =
    data &&
    !!data.urclassMe.birthday &&
    moment(data.urclassMe.birthday).isValid() &&
    data.urclassCohort.certIssuable &&
    !isSkipQuery;

  const dateFormat = 'YYYY년 MM월 DD일';

  if (!isRenderPDF || loading) {
    return <Loader title={'PDF 생성중 ...'} text={'오랜 시간이 소요되는 경우 새로고침 해주세요.'} />;
  }
  return (
    <BlobProvider
      document={EduCertDocs({
        name: data?.urclassMe.name,
        birthday: moment(data?.urclassMe.birthday).format(dateFormat),
        certCourseName: data?.urclassCohort.certCourseName,
        certCourseDesc: data?.urclassCohort.certCourseDesc,
        certEduDuration: `${moment(data?.urclassCohort.certEduStartDate).format(dateFormat)} ~ ${moment(
          data?.urclassCohort.certEduEndDate
        ).format(dateFormat)}`,
        trainingDay: data?.urclassOrganization.trainingDay,
        trainingTime: data?.urclassOrganization.trainingTime,
        orgName: data?.urclassOrganization.name,
        phoneNumber: data?.urclassOrganization.phoneNumber,
        address: data?.urclassOrganization.address,
        registrationNumber: data?.urclassOrganization.registrationNumber,
        certificatedAt: moment().format(dateFormat)
      })}
    >
      {({ url, error }) => {
        if (error) {
          // pdf parsing error
          return <div>PDF생성에 실패 하였습니다. 잠시 후 다시 시도해주세요.</div>;
        }

        if (url) {
          return (
            <ViewerWrapper>
              <div className="wrap__link">
                <a className="link__back" href="/mypage/certificates">
                  돌아가기
                </a>
                <a
                  className="link__download"
                  href={url}
                  download={`${data?.urclassMe.name}_${moment().format('YYMMDD')}_수강증명서.pdf`}
                >
                  다운받기
                  <DownloadIco className="ico__download" />
                </a>
              </div>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                <Viewer
                  defaultScale={1.1}
                  characterMap={{
                    isCompressed: true,
                    url: 'https://unpkg.com/pdfjs-dist@2.4.456/cmaps/'
                  }}
                  fileUrl={url || ''}
                />
              </Worker>
            </ViewerWrapper>
          );
        }
        return <Loader title={'PDF 생성중 ...'} text={'오랜 시간이 소요되는 경우 새로고침 해주세요.'} />;
      }}
    </BlobProvider>
  );
};
