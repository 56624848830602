import { Result, Spin } from 'antd';

const LoadingPage = () => {
  return (
    <div className="w3-display-middle">
      <Result
        status="info"
        icon={<Spin size="large" />}
        subTitle="Loading..."
      />
    </div>
  );
};

export default LoadingPage;
