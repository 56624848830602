import '@react-pdf-viewer/core/lib/styles/index.css';
import { useHistory } from 'react-router-dom';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { BlobProvider } from '@react-pdf/renderer';

import moment from 'moment';
import Loader from './Loader';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/react-hooks';
import { useGlobalMessage } from '../../../contexts/globalMessage';
import { CertificateType } from '../../../apollo/certificate/type';
import { PDFDocument } from '../pdfs/AttendanceCertPdfs';

import { ReactComponent as DownloadIco } from '../../../assets/svg/ico-download.svg';
import { MakeCertificationData, MakeCertificationVars } from '../../../apollo/certificate/type';
import { MAKE_CERTIFICATION } from '../../../apollo/certificate/mutation';
import { useEffect } from 'react';
import { CertType } from '../../../util/enums/CertType';
import { makeToast } from '../../../util/makeToast';
import NoAttendanceRecordPage from './NoAttendanceRecord';

type Props = {
  certificateType: CertType;
};
const ViewerWrapper = styled.div`
  margin-top: 50px;
  text-align: right;
  .wrap__link {
    margin: 0 auto 30px;
    max-width: 650px;
    .link__back {
      width: 112px;
      height: 32px;
      padding: 7px 0;
      vertical-align: middle;
      display: inline-block;
      text-align: center;
      background-color: #eceef3;
      color: #455573;
      font-size: 12px;
      font-weight: 700;
      border-radius: 6px;
    }
    .link__download {
      width: 134px;
      height: 32px;
      padding: 7px 0;
      vertical-align: middle;
      display: inline-block;
      text-align: center;
      background-color: #452ccd;
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      border-radius: 6px;
      margin-left: 12px;
      .ico__download {
        width: 17px;
        height: 17px;
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }
`;

export const AttendanceCertificateComp = ({ certificateType }: Props) => {
  const history = useHistory();
  const { displayGlobalMessage } = useGlobalMessage();

  const searchParams = new URLSearchParams(history.location.search);
  const startDate = moment(searchParams.get('cert_start_date')).format('YYYY-MM-DD');
  const endDate = moment(searchParams.get('cert_end_date')).format('YYYY-MM-DD');
  const cohortId = searchParams.get('cohort_id') || '';

  const convertToCertificateType = (certType: CertType): CertificateType => {
    switch (certType) {
      case CertType.ATTENDANCE_HRD_CERT:
        return CertificateType.HRD_NET;
      case CertType.ATTENDANCE_URCLASS_CERT:
        return CertificateType.URCLASS;
      default:
        return CertificateType.URCLASS;
    }
  };
  const certificate = convertToCertificateType(certificateType);

  const [makeCertificate, { data: certificateData, error: certificateErr, loading: certificateLoading }] = useMutation<
    MakeCertificationData,
    MakeCertificationVars
  >(MAKE_CERTIFICATION);

  useEffect(() => {
    if (cohortId.length !== 0) {
      makeCertificate({
        variables: {
          bootcampId: parseInt(cohortId),
          trainEndAt: new Date(endDate),
          trainStartAt: new Date(startDate),
          certificateType: certificate
        }
      })
        .then(() => {
          return;
        })
        .catch(() => {
          history.push('/mypage/certificates');
          makeToast({ type: 'error', content: '출석확인서 생성에 실패했습니다.' });
        });
    } else {
      history.push('/mypage/certificates');
      makeToast({ type: 'error', content: '출석확인서 생성에 실패했습니다.' });
    }
  }, []);

  useEffect(() => {
    if (certificateErr) {
      history.push('/mypage/certificates');
      if (certificateErr.message.includes('no-hrd-information-in-urclass')) {
        displayGlobalMessage('시스템에 등록되지 않은 사용자 입니다. 관리자에게 확인 요청 부탁 드립니다.');
      } else {
        displayGlobalMessage('발급에 문제가 있습니다. 관리자에게 문의해주세요');
      }
    }
  }, [certificateErr]);

  if (!certificateData || certificateLoading) {
    return <Loader title={'PDF 생성중 ...'} text={'오랜 시간이 소요되는 경우 새로고침 해주세요.'} />;
  }

  if (
    certificateData.urclassMakeCertification.absenceCount === 0 &&
    certificateData.urclassMakeCertification.presenceCount === 0 &&
    certificateData.urclassMakeCertification.leaveEarlyCount === 0 &&
    certificateData.urclassMakeCertification.officialLeaveCount === 0
  ) {
    return <NoAttendanceRecordPage />;
  }

  const { name, trainingProgramName } = certificateData.urclassMakeCertification;

  const filename = `${trainingProgramName} ${name} (단위기간_${startDate.replaceAll('-', '')}_${endDate.replaceAll(
    '-',
    ''
  )}).pdf`;

  return (
    <BlobProvider document={PDFDocument({ ...certificateData.urclassMakeCertification })}>
      {({ url, loading }) => {
        if (loading) {
          return <Loader title={'PDF 생성중 ...'} text={'오랜 시간이 소요되는 경우 새로고침 해주세요.'} />;
        }

        if (url) {
          return (
            <ViewerWrapper>
              <div className="wrap__link">
                <a className="link__back" href="/mypage/certificates">
                  돌아가기
                </a>
                <a className="link__download" href={url} download={filename}>
                  다운받기
                  <DownloadIco className="ico__download" />
                </a>
              </div>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                <Viewer
                  defaultScale={1.1}
                  characterMap={{
                    isCompressed: true,
                    url: 'https://unpkg.com/pdfjs-dist@2.4.456/cmaps/'
                  }}
                  fileUrl={url || ''}
                />
              </Worker>
            </ViewerWrapper>
          );
        }
        // 1. pdf module load error
        // 2. pdf parsing error
        return <Loader title={'PDF 생성중 ...'} text={'오랜 시간이 소요되는 경우 새로고침 해주세요.'} />;
      }}
    </BlobProvider>
  );
};
