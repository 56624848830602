import React, { useContext, useState } from 'react';

type ContextProps = {
  message: string;
  notificationVisible: boolean;
  displayGlobalMessage: (message: string) => void;
  hideGlobalMessage: () => void;
};
const GlobalMessageContext = React.createContext<ContextProps>({
  notificationVisible: false,
  message: '',
  displayGlobalMessage: () => {},
  hideGlobalMessage: () => {}
});

export const useGlobalMessage = () => {
  return useContext(GlobalMessageContext);
};

export const GlobalMessageContextProvider: React.FC = ({ children }) => {
  const [message, setMessage] = useState('');
  const [notificationVisible, setNotificationVisible] = useState(false);

  const displayGlobalMessage = (message: string) => {
    setMessage(message);
    setNotificationVisible(true);
  };

  const hideGlobalMessage = () => {
    setNotificationVisible(false);
    setMessage('');
  };

  return (
    <GlobalMessageContext.Provider
      value={{
        message,
        notificationVisible,
        displayGlobalMessage,
        hideGlobalMessage
      }}
    >
      {children}
    </GlobalMessageContext.Provider>
  );
};
