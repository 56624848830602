import React from 'react';
import moment from 'moment-timezone';
import { IVbank } from '../../apply/type';
import styled from '@emotion/styled';
import { MarginBottom } from '../../../components/atoms';

import { numberFormatter } from '../../../util';
import copy from 'copy-to-clipboard';
import { makeToast } from '../../../util/makeToast';

type VbankResultProps = { vbankInfo: IVbank };

const VbankResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CautionMessage = styled.div`
  color: var(--peach-peach-600, #fd1808);
  font-family: SpoqaHanSans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 42px */
  letter-spacing: -0.56px;
`;

const HeaderMessage = styled.div`
  color: #1e1e1e;
  font-family: SpoqaHanSans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 42px */
  letter-spacing: -0.28px;
`;

const VbankInfoResultCard = styled.div`
  display: inline-flex;
  padding: 40px 30px 24px 30px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 10px;
  border: 1px solid var(--grayscale-white, #fff);
  background: var(--grayscale-white, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
`;

const VbankInfoCard = styled.div`
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border: 1px solid var(--bluish-gray-bluish-gray-200, #dfe3ec);
`;

const LabelValueContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const Label = styled.label`
  color: var(--Gray-3, #828282);
  font-family: Spoqa Han Sans Neo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; /* 176.923% */
  letter-spacing: -0.13px;
`;

const Value = styled.div`
  color: var(--Gray-1, #333);
  font-family: Spoqa Han Sans Neo;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.15px;
`;
const VbankDepositInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
`;
const SmallCautionMessage = styled.div`
  color: var(--Gray-3, #828282);
  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
`;

const CopyVbankNumButton = styled.button`
  display: flex;
  padding: 14px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 5px;
  background: var(--purple-purple-600, #452cdd);

  color: var(--grayscale-white, #fff);
  text-align: center;
  font-family: Spoqa Han Sans Neo;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  :hover {
    cursor: pointer;
  }
`;
export const VbankResult: React.FC<VbankResultProps> = ({ vbankInfo }) => {
  return (
    <VbankResultWrapper>
      <MarginBottom size={114} />
      <CautionMessage>지원이 취소될 수 있습니다.</CautionMessage>
      <HeaderMessage>입금을 서둘러주세요!</HeaderMessage>
      <MarginBottom size={64} />
      <VbankInfoResultCard>
        <VbankInfoCard>
          <LabelValueContainer>
            <Label>주문 금액 |</Label>
            <Value>{numberFormatter(vbankInfo.paid_amount)}</Value>
          </LabelValueContainer>
          <LabelValueContainer>
            <Label>입금 정보 |</Label>
            <VbankDepositInfoContainer>
              <Value>{vbankInfo.vbank_name + ' ' + vbankInfo.vbank_num}</Value>
              <Value>예금주: {vbankInfo.vbank_holder}</Value>
              <SmallCautionMessage>입금 기한 내 미입금 시, 지원은 자동 취소됩니다.</SmallCautionMessage>
            </VbankDepositInfoContainer>
          </LabelValueContainer>
          <LabelValueContainer>
            <Label>입금 기한 |</Label>
            <Value>{moment(new Date(vbankInfo.vbank_date)).format('YYYY-MM-DD HH:mm')}까지</Value>
          </LabelValueContainer>
        </VbankInfoCard>
        <CopyVbankNumButton
          onClick={() => {
            copy(vbankInfo.vbank_num);
            makeToast({ content: `계좌번호(${vbankInfo.vbank_num})가 복사 되었습니다.`, type: 'success' });
          }}
        >
          입금계좌 복사하기
        </CopyVbankNumButton>
      </VbankInfoResultCard>
    </VbankResultWrapper>
  );
};
