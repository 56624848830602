import React from 'react';
import styled from '@emotion/styled';

import CommonDivider from '../../atoms/Divider/CommonDivider';

import BannerCover from '../../../assets/image/motif.png';
import { ReactComponent as ArrowRight } from '../../../assets/svg/ico-arrow-right.svg';

type MainBannerProps = {
  onClick?: () => void;
};

const MainBannerWrapper = styled.div`
  margin-top: 50px;
  padding: 40px;

  background: linear-gradient(rgba(32, 42, 69, 0.9), rgba(32, 42, 69, 0.9)), url(${BannerCover});

  background-size: cover;
  border-radius: 10px;
  color: white;
  border: 1px solid #dfdfdf;

  .banner-title {
    color: #eee;
    font-size: 32px;
    font-weight: 600;
    word-break: keep-all;
  }

  .banner-description {
    color: #afafaf;
    width: 50%;
    word-break: keep-all;
    font-size: 16px;
    font-weight: 500;
  }

  @media screen and (max-width: 600px) {
    .banner-description {
      width: 100%;
    }
  }
`;

const ArrowButton = styled.div`
  text-align: right;
  margin-top: 20px;
  cursor: pointer;

  .nav-text {
    font-weight: 700;
    color: #cfcfcf;
    margin-right: 10px;
  }

  .arrow {
    width: 90px;
  }
`;

const MainBanner: React.FC<MainBannerProps> = ({ onClick }) => (
  <MainBannerWrapper>
    <div className="banner-title">책임감 있는 교육, 코드스테이츠</div>
    <CommonDivider length={88} />
    <div className="banner-description">
      코드스테이츠는 누구나 본인의 배경 및 경제적 상황과 상관없이 자신에게 가장 잘 맞는 커리어를 직접 선택하고
      시작하는데 필요한 전 과정을 만들어 가고 있습니다.
    </div>

    <ArrowButton onClick={onClick}>
      <span className="nav-text">더 알아보기</span>
      <ArrowRight className="arrow" />
    </ArrowButton>
  </MainBannerWrapper>
);

export default MainBanner;
