import { UserProfile } from '../account/type';

export type UpdateUserTermsData = {
  urclassUpdateUserTerms: UserProfile;
};

export type UpdateUserTermsVars = {
  input: {
    marketingUseAgreement: boolean;
    receivingMessagesAgreement: boolean;
    serviceTermsAgreement: boolean;
    privacyTermsAgreement: boolean;
  };
};

export type UpdateUserProfileData = {
  urclassUpdateUserProfile: UserProfile;
};

export type UpdateUserProfileVars = {
  input: {
    birthday?: string;
    mobile?: string;
    name?: string;
    email?: string;
  };
};

export type IssueEmailAuthCodeData = {
  urclassIssueEmailAuthCode: boolean;
};
export type IssueEmailAuthCodeVars = {
  email: string;
};
export type VerifyEmailAuthCodeData = {
  urclassVerifyEmailAuthCode: boolean;
};

export type VerifyEmailAuthCodeVars = {
  input: {
    code: string;
    email: string;
  };
};

export type UpdateVerifiedEmailData = {
  urclassUpdateVerifiedEmail: UserProfile;
};

export type UpdateVerifiedEmailVars = {
  input: {
    code: string;
    email: string;
  };
};

export enum AuthCodeStatus {
  WAITING,
  SENT,
  VERIFIED
}

export enum ViewStatus {
  VERIFYING_VIEW,
  EDITING_VIEW
}
export type State = {
  email: string;
  name: string;
  mobile: string;
  birthday: string;
  authCode: string;
  timer?: NodeJS.Timeout;
  leftAuthTime: number;
  authCodeStatus: AuthCodeStatus;
  isFailedVerifyEmailAuthCode: boolean;
  isEmailSentModalVisible: boolean;
  viewStatus: ViewStatus;
};

export type Action =
  | { type: 'INIT_PROFILE'; payload: { email: string; birthday?: string; name: string; mobile: string } }
  | { type: 'CHANGE_MOBILE_INPUT_VALUE'; payload: { mobile: string } }
  | { type: 'CHANGE_NAME_INPUT_VALUE'; payload: { name: string } }
  | { type: 'CHANGE_BIRTHDAY_INPUT_VALUE'; payload: { birthday: string } }
  | { type: 'CHANGE_EMAIL_INPUT_VALUE'; payload: { email: string } }
  | { type: 'CHANGE_AUTH_CODE_INPUT_VALUE'; payload: { authCode: string } }
  | { type: 'CLICK_SEND_AUTH_CODE'; payload: { timer: NodeJS.Timeout } }
  | { type: 'CLICK_RE_SEND_AUTH_CODE'; payload: { timer: NodeJS.Timeout } }
  | { type: 'TICK_AUTH_TIME' }
  | { type: 'INIT_AUTH_CODE_STATUS' }
  | { type: 'SUCCESS_VERIFY_EMAIL_AUTH_CODE' }
  | { type: 'FAIL_VERIFY_EMAIL_AUTH_CODE' }
  | { type: 'CLOSE_EMAIL_SENT_MODAL' }
  | { type: 'EDITING_VIEW' }
  | { type: 'VERIFYING_VIEW' };
