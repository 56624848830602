import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Popover, List, Tag } from 'antd';
import { CaretDownOutlined, DownloadOutlined, LinkOutlined } from '@ant-design/icons';

import BasicTab from '../../../../components/atoms/Tab/BasicTab';
import BasicButton from '../../../../components/atoms/Button/BasicButton';

import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import { Color } from '../../../../style';

import type { FavorPosition, JobProfile } from '../../common/apollo/resume/type';

import { EntryTitle } from '../../../../components/atoms/Title';
import { makeToast } from '../../../../util/makeToast';

const ProfileItem = styled.div`
  margin: 5px 0px;
  font-size: 16px;
  font-weight: 400;
  color: #333;

  .content-with-icon {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    margin: 0px 5px;
  }
`;

const ResumeHeaderWrapper = styled.div`
  padding: 20px;
  margin: auto 0px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: white;

  .content {
    max-width: 1200px;
    margin: 0px auto;

    display: flex;
    justify-content: space-between;
  }

  .logo {
    font-size: 1.5em;
    height: 30px;
    padding-top: 5px;
    cursor: pointer;
  }

  .action {
    .button {
      width: 120px;
      height: 37px;
      margin: auto 0px;
    }

    .download {
      float: right;
      margin-left: 5px;

      .download-icon {
        margin: 0px 5px;
      }
      color: #333;
    }
  }
`;

const JobsProfileCardWrapper = styled.div`
  width: 320px;
`;

type ResumeHeaderProps = {
  profile?: JobProfile;
  handleContactClick: (visible: boolean) => void;
  handleDownload: () => void;
};

const ResumeHeader: React.FC<ResumeHeaderProps> = ({ profile, handleContactClick, handleDownload }) => {
  const history = useHistory();

  return (
    <ResumeHeaderWrapper>
      <div className="content">
        <Logo className="logo" onClick={() => history.push('/')} />

        {profile && (
          <div className="action">
            <Popover
              placement="bottomRight"
              content={
                <JobsProfileCardWrapper>
                  <List itemLayout="vertical" size="large">
                    <List.Item>
                      <EntryTitle>{profile.korName}</EntryTitle>
                      <EntryTitle>{`${profile.engFirstName} ${profile.engLastName}`}</EntryTitle>
                    </List.Item>
                    <List.Item>
                      <ProfileItem>{profile.mobile}</ProfileItem>
                      <ProfileItem>
                        {profile.email}
                        <CopyToClipboard
                          text={profile.email}
                          onCopy={makeToast({
                            type: 'success',
                            content: `${profile.email}가 복사 되었습니다.`
                          })}
                        >
                          <LinkOutlined className="content-with-icon" />
                        </CopyToClipboard>
                      </ProfileItem>
                    </List.Item>
                    <List.Item>
                      {profile.favorPositions.map((position: FavorPosition) => (
                        <Tag color="blue" key={position.id}>
                          {position.name}
                        </Tag>
                      ))}
                    </List.Item>
                  </List>
                </JobsProfileCardWrapper>
              }
              trigger="click"
              onVisibleChange={handleContactClick}
              overlayStyle={{ position: 'fixed' }}
            >
              <BasicButton color={Color.main} className="button">
                Contact
                <CaretDownOutlined />
              </BasicButton>
            </Popover>

            <a href={profile.publishedResume && profile.publishedResume.fileURL} download onClick={handleDownload}>
              <BasicTab className="download">
                <DownloadOutlined className="download-icon" />
                Download
              </BasicTab>
            </a>
          </div>
        )}
      </div>
    </ResumeHeaderWrapper>
  );
};

export default ResumeHeader;
