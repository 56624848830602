import React from 'react';
import styled from '@emotion/styled';

type SectionLabelProps = {
  children: string | number | React.ReactNode;
  marginBottom?: number;
};

const SectionLabelWrapper = styled.div<{ marginBottom: number }>`
  margin-top: 50px;
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  font-size: 20px;
  font-weight: 600;
  color: #455573;
`;

const SectionLabel: React.FC<SectionLabelProps> = ({ children, marginBottom = 25 }) => (
  <SectionLabelWrapper marginBottom={marginBottom}>{children}</SectionLabelWrapper>
);

export default SectionLabel;
