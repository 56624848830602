import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown, Menu } from 'antd';

import { ApplicationCardViewModel } from '../viewModel';

import { Typography, TypographyType } from '../../../components/atoms/Typography';
import { Button } from '../../../components/molecules/Button';
import { SizeType, StatusType } from '../../../components/molecules/Button/type';

import { ReactComponent as ViewMore } from '../../../assets/svg/ico-view-more.svg';

import { ColorPalette, ColorPaletteType } from '../../../styles/color';
import { mapPaymentTypeToText } from '../../../util/textMapper/mapPaymentTypeToText';

import {
  Application,
  ApplyingStatus,
  ApplyServiceType,
  BoardingStatus,
  EvaluationStatus,
  IntentionStatus
} from '../../../apollo/mypage/type';
import { Link, useHistory } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import MyPageCardLoader from './Loader/MyPageCardLoader';

import queryKeys from '../../../query/queryKeys';
import { useCredential } from '../../../contexts/credential';

import { getAdmissionApplicationCardRequest } from '../request';
import { store } from '../../../util';

type ApplicationCardProps = {
  application: Application;
};

const ApplicationCardWrapper = styled.div<{ status: 'active' | 'inactive' }>`
  position: relative;

  ${({ status }) => {
    switch (status) {
      case 'active':
        return `
        border: solid 2px ${ColorPalette.Gray1};
        `;
      case 'inactive':
        return `
        border: solid 1px ${ColorPalette.Gray4};
        `;
    }
  }}
  border-radius: 8px;

  padding: 26px 20px 23px 20px;
  background-color: ${ColorPalette.White};

  .mypage-application__body {
    .card-header {
      display: flex;
      justify-content: space-between;

      .view-more {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .underline {
      text-decoration: underline;

      text-underline-offset: 6px;

      -webkit-text-decoration-color: ${ColorPalette.Gray4}; /* Safari */
      text-decoration-color: ${ColorPalette.Gray4};

      margin-bottom: 18px;
    }

    .mypage-application__card-title {
      font-weight: bold;
      margin-bottom: 12px;

      word-break: keep-all;
    }

    .mypage-application__card-service-info {
      margin-bottom: 8px;
    }

    .mypage-application__card-payment-type {
      margin-bottom: 51px;
    }
  }

  .ghost {
    width: 100%;
    height: 100px;
  }

  .mypage-application__footer {
    position: absolute;
    bottom: 23px;

    .mypage-application__card-progress-line {
      .progress-line__date {
        display: inline-block;
      }

      .progress-line__text {
        display: inline-block;

        vertical-align: middle;
        float: right;
      }

      .gauge {
        display: inline-block;

        margin: 0px 0px 0px 7px;

        vertical-align: middle;
        float: right;

        .gauge-item {
          display: inline-block;

          margin: 0px 1px 0px 1px;

          width: 4px;
          height: 11px;
        }

        .on {
          background: ${ColorPalette.Gray1};
        }

        .off {
          background: ${ColorPalette.Gray4};
        }
      }
    }

    .hide {
      color: ${ColorPalette.White};
    }

    .mypage-application__card__button {
      margin-top: 13px;
    }

    .mypage-application__card-announcement {
      display: flex;
      justify-content: flex-end;
      gap: 4px;

      .mypage-application__card-announcement-date {
        font-weight: 700;
      }
    }
  }
`;

const ApplicationCard: React.FC<ApplicationCardProps> = ({ application }) => {
  const history = useHistory();
  const [viewModel, setViewModel] = useState<any>();
  const { profile } = useCredential();

  const getAdmissionApplicationByUuidParams = {
    applicationUuid: application.uuid,
    userUuid: profile ? profile.uuid : ''
  };

  const admissionToken = application.admissionApplierToken;
  admissionToken && store.admissionToken.set(admissionToken);

  const {
    data: admissionApplicationData,
    isLoading: isAdmissionApplicationLoading,
    isError: isAdmissionApplicationError
  } = useQuery(
    queryKeys.APPLICATION_CARD_BY_UUID(getAdmissionApplicationByUuidParams),
    () => getAdmissionApplicationCardRequest(getAdmissionApplicationByUuidParams),
    {
      enabled: application.product.applyServiceType === ApplyServiceType.ADMISSION
    }
  );

  const isSoftskillCourse = admissionApplicationData?.template?.slugUrl?.includes('softskill');

  const handleSelectDetailApplication = () => {
    if (application.product.applyServiceType === ApplyServiceType.TYPEFORM) {
      history.push({
        pathname: `/applications/form/${application.uuid}/list`,
        state: { uuid: application.uuid }
      });
    }
    if (
      application.product.applyServiceType === ApplyServiceType.ADMISSION &&
      admissionApplicationData &&
      admissionApplicationData.template
    ) {
      const { slugUrl } = admissionApplicationData.template;
      const url = `/applications/${slugUrl}/list/${application.uuid}`;
      history.push({
        pathname: url,
        state: { token: application.admissionApplierToken }
      });
    }
  };

  useEffect(() => {
    if (admissionApplicationData && application.product.applyServiceType === ApplyServiceType.ADMISSION) {
      const { applyingStatus, evaluationStatus, template, answers } = admissionApplicationData;

      const applicationInfo = {
        applyingStatus,
        evaluationStatus,
        template,
        answers
      };

      const viewModel = new ApplicationCardViewModel(application, applicationInfo);
      setViewModel(viewModel);
    }
  }, [admissionApplicationData]);

  useEffect(() => {
    if (application.product.applyServiceType === ApplyServiceType.TYPEFORM) {
      const viewModel = new ApplicationCardViewModel(application);

      setViewModel(viewModel);
    }
  }, []);
  if (application.product.applyServiceType === ApplyServiceType.ADMISSION) {
    if (isAdmissionApplicationLoading || !viewModel) {
      return <MyPageCardLoader />;
    }
    if (isAdmissionApplicationError) {
      return <div>Error</div>;
    }
  }
  if (application.product.applyServiceType === ApplyServiceType.TYPEFORM) {
    if (!viewModel) {
      return <div>Error</div>;
    }
  }

  return (
    <ApplicationCardWrapper status={viewModel.cardAndButtonState.cardStatus}>
      <>
        <div className="mypage-application__body">
          <div className="card-header">
            <Typography
              className="underline"
              type={TypographyType.B1}
              color={ColorPaletteType.GRAY2}
              text={viewModel.topPhrase}
            />
            {viewModel.hasViewMoreButtonDropdown &&
              !viewModel.isCDSProgram &&
              !isSoftskillCourse &&
              !viewModel.isResumeUploadProgram && (
                <Dropdown
                  placement="bottomLeft"
                  overlay={
                    <Menu>
                      {viewModel.canApplicationCancelled && (
                        <Menu.Item key="0">
                          <Link to={`/application/cancel/${application.uuid}`}>
                            {viewModel.isCDSProgram ? '제출 취소' : '지원 취소'}
                          </Link>
                        </Menu.Item>
                      )}
                      {viewModel.hasViewMoreButtonForEdit && (
                        <Menu.Item key="1" onClick={() => handleSelectDetailApplication()}>
                          {viewModel.isCDSProgram ? '수정' : '지원서 수정'}
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <ViewMore className="view-more" />
                </Dropdown>
              )}
          </div>

          <Typography
            className="mypage-application__card-title"
            type={TypographyType.H2}
            color={ColorPaletteType.GRAY1}
            text={viewModel.productName}
          />
          {!isSoftskillCourse && !viewModel.isResumeUploadProgram && (
            <>
              <Typography
                className="mypage-application__card-service-info"
                type={TypographyType.B1}
                color={ColorPaletteType.GRAY2}
                text={viewModel.productServiceGuide}
              />

              {viewModel.isLevel1VODProgram || (
                <Typography
                  className="mypage-application__card-payment-type"
                  type={TypographyType.B1}
                  color={ColorPaletteType.GRAY2}
                  text={mapPaymentTypeToText(viewModel.paymentType)}
                />
              )}
            </>
          )}
        </div>

        <div className="ghost" />
        <div className="mypage-application__footer">
          {!isSoftskillCourse && !viewModel.isResumeUploadProgram && (
            <div className="mypage-application__card-progress-line">
              {!viewModel.isCDSProgram && (
                <Typography
                  className="progress-line__date"
                  type={TypographyType.B1}
                  color={ColorPaletteType.GRAY1}
                  text={viewModel.dateGuide}
                />
              )}
              {viewModel.isDisplayProgressStatus ? (
                <div className="gauge">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(ele => (
                    <div
                      key={ele}
                      className={`gauge-item ${viewModel.numericStepProgrees >= ele * 10 ? 'on' : 'off'}`}
                    />
                  ))}
                </div>
              ) : (
                <></>
              )}
              {viewModel.isDisplayProgressStatus ? (
                <Typography
                  className="progress-line__text"
                  type={TypographyType.B1}
                  color={ColorPaletteType.GRAY1}
                  text={viewModel.numericStepProgrees + '% 완료'}
                />
              ) : (
                <></>
              )}
            </div>
          )}
          {/*
          {viewModel.isBeforeAnnouncementDate &&
            !viewModel.isRollingBaseCourse &&
            !viewModel.isCDSProgram &&
            !viewModel.isLevel1VODProgram &&
            !isSoftskillCourse &&
            !viewModel.isResumeUploadProgram && (
              <div className="mypage-application__card-announcement">
                <Typography type={TypographyType.B1} color={ColorPaletteType.GRAY1} text={'합격자 발표 '} />

                <Typography
                  className="mypage-application__card-announcement-date"
                  type={TypographyType.B1}
                  color={ColorPaletteType.GRAY1}
                  text={viewModel.productAnnouncementDate}
                />
              </div>
            )}

*/}
          <Button
            className="mypage-application__card__button"
            size={SizeType.BASIC}
            status={viewModel.cardAndButtonState.buttonStatus}
            children={
              <Typography
                type={TypographyType.B1}
                color={viewModel.cardAndButtonState.buttonTextColor}
                text={viewModel.cardAndButtonState.buttonText}
              />
            }
            onClick={
              viewModel.cardAndButtonState.buttonStatus === StatusType.ACTIVE
                ? () => {
                    switch (application.applyingStatus) {
                      case ApplyingStatus.APPLYING:
                        return handleSelectDetailApplication();
                      case ApplyingStatus.SUBMITTED:
                        if (
                          application.evaluationStatus === EvaluationStatus.PASSED &&
                          application.intentionStatus === IntentionStatus.CONFIRMED &&
                          application.boardingStatus === BoardingStatus.BOARDED
                        ) {
                          history.push('/courses/active');
                        }

                        if (application.needToPay) {
                          history.push(`/applications/${application.uuid}/payment`);
                        }
                    }
                  }
                : () => {}
            }
          />
        </div>
      </>
    </ApplicationCardWrapper>
  );
};

export default ApplicationCard;
