import TagManager from 'react-gtm-module';
import { detectDevice } from '../detectDevice/detectDevice';
import moment from 'moment';
import { store } from '..';

interface Params {
  data: any;
  urlQueries?: string;
  slugUrl?: string;
  uuid?: string;
  order?: number;
}

const utms = (urlQueries: string) => {
  const searchParams = new URLSearchParams(urlQueries);
  const utmSource = searchParams.get('utm_source') || '';
  const utmMedium = searchParams.get('utm_medium') || '';
  const utmCampaign = searchParams.get('utm_campaign') || '';
  const utmContent = searchParams.get('utm_content') || '';
  const utmTerm = searchParams.get('utm_term') || '';
  return {
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_content: utmContent,
    utm_term: utmTerm
  };
};

export const dataLayerForApplyEvent = ({ data, slugUrl, urlQueries }: Params) => {
  console.log(2, slugUrl);

  const { id: applicationId, user } = data;

  const utm = urlQueries && utms(urlQueries);
  TagManager.dataLayer({
    dataLayer: {
      event: `apply_urclass_course`,
      cardinalNumber: slugUrl?.split('-').slice(-1)[0] || 'ISP에 문의주세요,slugUrl이 없대요',
      courseName: slugUrl?.split('-').slice(0, -1).join('-') || 'ISP에 문의주세요, slugUrl이 없대요',
      UUID: user.uuid,
      applyId: applicationId,
      device: detectDevice(),
      ...utm
    }
  });
};

export const dataLayerForSignupEvent = ({ data, urlQueries }: Params) => {
  if (!data?.urclassSignUp?.user) {
    return;
  }
  const { uuid } = data.urclassSignUp.user;

  const utm = urlQueries && utms(urlQueries);

  TagManager.dataLayer({
    dataLayer: {
      event: 'signup',
      UUID: uuid,
      device: detectDevice(),
      ...utm
    }
  });
};

export const dataLayerForSigninEvent = ({ data, urlQueries }: Params) => {
  if (!data?.urclassLoginKakao.userLoginMetrics) {
    return;
  }
  const { loginCount, recentlyLoginedAt, uuid } = data.urclassLoginKakao.userLoginMetrics;
  const recentLoginDate = moment(recentlyLoginedAt).format('YYYY-MM-DD');
  const utm = urlQueries && utms(urlQueries);
  store.loginCount.set(String(loginCount));
  store.recentlyLoginedAt.set(recentLoginDate);

  TagManager.dataLayer({
    dataLayer: {
      event: 'signin',
      UUID: uuid,
      loginCount,
      recentLoginDate,
      device: detectDevice(),
      ...utm
    }
  });
};

export const dataLayerForSurveySubmit = ({ data, slugUrl, uuid, order, urlQueries }: Params) => {
  if (!slugUrl) return;
  const templateItems = data?.data?.template?.items;
  const loginCount = store.loginCount.get();
  const recentLoginDate = store.recentlyLoginedAt.get();
  const utm = urlQueries && utms(urlQueries);
  TagManager.dataLayer({
    dataLayer: {
      event: `submit_process_urclass`,
      cardinalNumber: slugUrl.split('-').slice(-1)[0],
      courseName: slugUrl.split('-').slice(0, -1).join('-'),
      device: detectDevice(),
      UUID: uuid,
      totalProcess: templateItems.length,
      presentProcess: order,
      loginCount,
      recentLoginDate,
      ...utm
    }
  });
};
