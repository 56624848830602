import styled from '@emotion/styled';
import moment from 'moment-timezone';

import NormalBody from '../../assets/image/attendance/body-normal.png';
import CheckoutableBody from '../../assets/image/attendance/body-checkoutable.png';
import CheckinableBody from '../../assets/image/attendance/body-checkinable.png';
import SleepBody from '../../assets/image/attendance/body-sleep.png';

import SmallBalloon from '../../assets/image/attendance/balloon-small.png';
import BigBalloon from '../../assets/image/attendance/balloon-big.png';

import BackIcon from '../../assets/svg/ico-back.svg';
import CloseIcon from '../../assets/svg/ico-close.svg';
import CheckOutIcon from '../../assets/image/attendance/switch-checkout.png';
import { Typography, TypographyType } from '../../../../components/atoms/Typography';
import { ColorPaletteType } from '../../../../styles/color';
import { useController } from './controller';
import { ZkStatus, CheckInHistoryCategory, InActiveDayType } from '../../apollo/attendance/type';
import { useAttendanceStatus } from '../../contexts/AttendanceStatus';
import AttendanceSwitch from '../attendanceSwitch';

type Props = {
  visible: boolean;
};
type ZkBodyProps = {
  type: ZkStatus;
  isClick: boolean;
  isCheckInZkPopupOpen: boolean;
  isUserCheckIn: boolean;
  isCheckOutZkPopupOpen: boolean;
};

const handleZKStatus = (props: ZkBodyProps) => {
  switch (props.type) {
    case ZkStatus.NONE:
      return;
    case ZkStatus.ACTIVE:
      if (props.isUserCheckIn) {
        if (!props.isClick && !props.isCheckInZkPopupOpen) {
          return NormalBody;
        }
        return CheckoutableBody;
      } else {
        if (props.isCheckOutZkPopupOpen) {
          return CheckoutableBody;
        }
        return CheckinableBody;
      }

    case ZkStatus.INACTIVE:
      return SleepBody;
    default:
      return NormalBody;
  }
};
const EmojiButton = styled.div<{
  type: ZkStatus;
  isClick: boolean;
  isCheckInZkPopupOpen: boolean;
  isCheckOutZkPopupOpen: boolean;
  isUserCheckIn: boolean;
}>`
  position: fixed;
  right: 36px;
  bottom: 36px;
  border-radius: 25px;
  width: 60px;
  height: 60px;
  background: no-repeat -9px -9px url(${props => handleZKStatus(props)});
  background-size: 80px 80px;
  box-shadow: 0px 2px 32px
    ${props =>
      props.type === ZkStatus.ACTIVE && !props.isUserCheckIn ? 'rgba(255, 110, 10,100)' : 'rgba(0, 0, 0, 0.25)'};
  animation: ${props =>
    props.type === ZkStatus.ACTIVE && !props.isUserCheckIn ? 'horonghorong 3s 1s infinite ease' : 'none'};
  cursor: pointer;
  @keyframes horonghorong {
    0% {
      box-shadow: 0px 1px 10px rgba(255, 110, 10, 100);
    }
    50% {
      box-shadow: 0px 1px 22px rgba(255, 110, 10, 100);
    }
    100% {
      box-shadow: 0px 1px 10px rgba(255, 110, 10, 100);
    }
  }
`;
const AttendanceCheckerWrapper = styled.div`
  .popup__wrapper {
    background: no-repeat center center url(${SmallBalloon});
    background-size: cover;
    position: fixed;
    width: 340px;
    height: 294px;
    bottom: 82px;
    right: 4px;
    text-align: center;

    .btn__close {
      position: absolute;
      right: 50px;
      top: 50px;
      background: no-repeat center center url(${CloseIcon});
      background-size: cover;
      width: 24px;
      height: 24px;
      border: none;
      cursor: pointer;
    }

    .txt__popup--greeting {
      margin-top: 65px;
    }
    .txt__popup--sleep {
      margin-bottom: 4px;
    }
    .txt__popup--status {
      display: inline-block;
    }
    .txt__popup--status + .txt__popup--status {
      margin-bottom: 28px;
    }
    .txt__popup--noti {
      display: block;
    }
    .txt__popup--holiday {
      margin-top: 13px;
    }
    .txt__popup--checkin {
      display: inline-block;
    }
    .txt__popup--checkin:first-of-type {
      display: block;
      margin-top: 100px;
    }
    .txt__popup--checkout {
      margin: 70px 0 10px;
    }

    .txt__popup--time {
      display: block;
      margin-top: 12px;
    }
    .txt__popup--error {
      margin-top: 100px;
    }
    .txt__popup--errornoti {
      display: inline-block;
    }
    .btn__back--error {
      display: block;
      margin: 12px auto 0;
      border: none;
      background: none;
      color: #8c9cba;
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }

    .btn__checkout {
      width: 208px;
      height: 43px;
      background-color: #452cdd;
      color: #fff;
      border-radius: 6px;
      margin-top: 26px;
      border: none;
      cursor: pointer;
    }
    .btn__checkout--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .btn__popup--status {
      position: relative;
      display: block;
      margin: 28px auto 14px;
      border: none;
      width: 184px;
      height: 48px;
      box-sizing: content-box;
      border-radius: 900px;
      cursor: pointer;
    }
    .btn__popup--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .btn__popup--sleep {
      cursor: not-allowed;
      margin: 16px auto;
      background-color: #f2f2f2;
      .ico__btn {
        background: no-repeat center center url(${CheckOutIcon});
        background-size: cover;
        border: none;
        width: 60px;
        height: 60px;
        position: absolute;
        left: -5px;
        top: -5px;
        z-index: 1;
      }
      .txt__btn {
        color: #d6d6d6;
        position: absolute;
        left: 66px;
        top: 14px;
        z-index: 0;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .btn__popup--history {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 13px;
      font-weight: 400;
      color: #8c9cba;
      display: block;
      margin: 14px auto 0;
    }
  }
  .popup__wrapper--history {
    background: no-repeat center center url(${BigBalloon});
    background-size: cover;
    position: fixed;
    width: 288px;
    height: 456px;
    bottom: 82px;
    right: 4px;
    text-align: center;
    .btn__history {
      position: absolute;
      width: 24px;
      height: 24px;
      border: none;
      cursor: pointer;
    }
    .btn__close {
      top: 50px;
      right: 35px;
      background: no-repeat center center url(${CloseIcon});
      background-size: cover;
    }
    .btn__back {
      top: 50px;
      left: 35px;
      background: no-repeat center center url(${BackIcon});
      background-size: cover;
    }

    .tit__popup--check {
      margin: 46px 0 6px;
    }
    .header__table {
      display: flex;
      width: 252px;
      padding-bottom: 4px;
      border-bottom: 1px solid #dfe3ec;
      margin: 10px auto 0;
      justify-content: space-around;
      .tit__table {
        font-size: 14px;
        font-weight: 400;
        color: #8c9cba;
      }
    }
    .body__table {
      width: 254px;
      height: 271px;
      margin: 6px auto 0;
      overflow: auto;
      color: #455573;
      .item__table {
        margin: 6px 0;
        .txt__table {
          display: inline-block;
          width: 82px;
        }
        .txt__table--blue {
          color: #295ce0;
        }
        .txt__table--expired {
          color: #ff360a;
        }
        .txt__table--batch {
          color: #00b86a;
        }
      }
    }
  }
`;

const AttendanceChecker: React.FC<Props> = props => {
  const {
    userName,
    zkType,
    inActiveDayType,
    checkInHistoryData,
    backToMain,
    handleCheckOut,
    isCheckInZkPopupOpen,
    isCheckOutZkPopupOpen,
    isUserCheckIn,
    isDataErr,
    isCheckInErr,
    isCheckOutErr,
    isZkErr,
    isZkSwitch,
    checkInLoading,
    checkOutLoading
  } = useAttendanceStatus();
  const {
    isMainViewOpen,
    isZkBtnClick,
    isHistoryPopup,
    handleClickHistoryPopup,
    handleClickZkBtn,
    handleClickZkCloseBtn,
    handleClickZkBackBtn,
    handleToggleSwitch
  } = useController();
  const handleInActiveDayStr = (type: InActiveDayType) => {
    switch (type) {
      case InActiveDayType.HOLIDAY:
        return '째깍이도 공휴일엔 쉬어요.';
      case InActiveDayType.WEEKEND:
        return '째깍이도 주말엔 쉬어요.';
    }
  };
  const handleZkPopup = (type: ZkStatus) => {
    switch (type) {
      case ZkStatus.ACTIVE:
        return (
          <div>
            <Typography
              className="txt__popup--greeting"
              type={TypographyType.B5}
              color={ColorPaletteType.BLUISHGRAY5}
              text={`${userName}님 안녕하세요!`}
            />

            <Typography
              className="txt__popup--status"
              type={TypographyType.B5}
              color={ColorPaletteType.PURPLE}
              text={isUserCheckIn ? '입실 상태' : '퇴실 상태'}
            />
            <Typography
              className="txt__popup--status"
              type={TypographyType.B5}
              color={ColorPaletteType.BLUISHGRAY5}
              text={isUserCheckIn ? '입니다.' : '입니다. 입실해주세요.'}
            />
            <AttendanceSwitch
              isZkSwitch={isZkSwitch}
              handleToggleSwitch={handleToggleSwitch}
              checkInLoading={checkInLoading}
              checkOutLoading={checkOutLoading}
            />
          </div>
        );
      case ZkStatus.INACTIVE:
        return (
          <div>
            <Typography
              className="txt__popup--greeting txt__popup--sleep"
              type={TypographyType.B5}
              color={ColorPaletteType.BLUISHGRAY5}
              text={`째깍이는 지금 잘 시간 🌜`}
            />
            <Typography
              className={inActiveDayType ? 'txt__popup--noti txt__popup--holiday' : 'txt__popup--noti'}
              type={TypographyType.B6}
              color={ColorPaletteType.BLUISHGRAY5}
              text={inActiveDayType ? handleInActiveDayStr(inActiveDayType) : '오전 6시 ~ 오후 10시 사이에'}
            />
            {!inActiveDayType && (
              <Typography
                className="txt__popup--noti"
                type={TypographyType.B6}
                color={ColorPaletteType.BLUISHGRAY5}
                text={`입실이 가능합니다.`}
              />
            )}
            <button className={'btn__popup--status  btn__popup--sleep'} disabled={true}>
              <div>
                <span className="ico__btn"></span>
                <span className="txt__btn">째깍이 깨워 입실</span>
              </div>
            </button>
          </div>
        );
      case ZkStatus.NONE:
        return (
          <div className="popup__wrapper">
            <>
              <button className="btn__close" onClick={handleClickZkCloseBtn} />
              <Typography
                className="txt__popup--error"
                type={TypographyType.H4}
                color={ColorPaletteType.BLUISHGRAY5}
                text={isDataErr ? '앗, 문제가 발생했어요.' : '이미 처리되어'}
              />
              <Typography
                className="txt__popup--errornoti"
                type={TypographyType.H4}
                color={isCheckOutErr ? ColorPaletteType.BLUE : ColorPaletteType.ORANGE}
                text={isDataErr ? '관리자에게 문의' : isCheckInErr ? '입실 상태' : '퇴실 상태'}
              />
              <Typography
                className="txt__popup--errornoti"
                type={TypographyType.H4}
                color={ColorPaletteType.BLUISHGRAY5}
                text={isDataErr ? '해주세요.' : '입니다.'}
              />
              <button onClick={backToMain} className="btn__back--error">
                돌아가기
              </button>
            </>
          </div>
        );
    }
  };

  return props.visible ? (
    <AttendanceCheckerWrapper>
      <EmojiButton
        type={zkType}
        onClick={handleClickZkBtn}
        isClick={isZkBtnClick}
        isCheckInZkPopupOpen={isCheckInZkPopupOpen}
        isCheckOutZkPopupOpen={isCheckOutZkPopupOpen}
        isUserCheckIn={isUserCheckIn}
      />
      {/* 째깍이 메인 팝업 */}
      {isMainViewOpen && (
        <div className="popup__wrapper">
          {handleZkPopup(zkType)}
          <button className="btn__popup--history" onClick={handleClickHistoryPopup}>
            오늘 출결 내역 보기
          </button>
        </div>
      )}
      {/* 째깍이 에러 팝업 */}
      {isZkErr && (
        <div className="popup__wrapper">
          <>
            <button className="btn__close" onClick={handleClickZkCloseBtn} />
            <Typography
              className="txt__popup--error"
              type={TypographyType.H4}
              color={ColorPaletteType.BLUISHGRAY5}
              text={isDataErr ? '앗, 문제가 발생했어요.' : '이미 처리되어'}
            />
            <Typography
              className="txt__popup--errornoti"
              type={TypographyType.H4}
              color={isCheckOutErr ? ColorPaletteType.BLUE : ColorPaletteType.ORANGE}
              text={isDataErr ? '관리자에게 문의' : isCheckInErr ? '입실 상태' : '퇴실 상태'}
            />
            <Typography
              className="txt__popup--errornoti"
              type={TypographyType.H4}
              color={ColorPaletteType.BLUISHGRAY5}
              text={isDataErr ? '해주세요.' : '입니다.'}
            />
            <button onClick={backToMain} className="btn__back--error">
              돌아가기
            </button>
          </>
        </div>
      )}
      {/* 로그인시 체크인 팝업 */}
      {isCheckInZkPopupOpen && (
        <div className="popup__wrapper">
          <Typography
            className="txt__popup--checkin"
            type={TypographyType.B5}
            color={ColorPaletteType.BLUISHGRAY5}
            text={`오늘도 `}
          />
          <Typography
            className="txt__popup--checkin"
            type={TypographyType.B5}
            color={ColorPaletteType.BLUISHGRAY5}
            text={'힘차게 달려보아요🙌'}
          />
          <Typography
            className="txt__popup--time"
            type={TypographyType.B4}
            color={ColorPaletteType.BLUISHGRAY2}
            text={`${moment().format('H:mm A')}`}
          />
        </div>
      )}
      {/* 퇴실 확인 팝업 */}
      {isCheckOutZkPopupOpen && (
        <div className="popup__wrapper">
          <Typography
            className="txt__popup--checkout"
            type={TypographyType.H5}
            color={ColorPaletteType.BLUISHGRAY4}
            text={`정말 퇴실하실건가요? `}
          />

          <Typography
            className="txt__popup--noti"
            type={TypographyType.B4}
            color={ColorPaletteType.GRAY7}
            text={'퇴실하면 더 이상'}
          />
          <Typography
            className="txt__popup--noti"
            type={TypographyType.B4}
            color={ColorPaletteType.GRAY7}
            text={'학습시간이 기록되지 않아요.'}
          />
          <button
            className={checkOutLoading ? 'btn__checkout btn__checkout--disabled' : 'btn__checkout'}
            onClick={handleCheckOut}
            disabled={checkOutLoading}
          >
            네, 퇴실합니다 😊
          </button>
        </div>
      )}
      {/* 입/퇴실 내역 팝업 */}
      {isZkBtnClick && isHistoryPopup && (
        <div className="popup__wrapper--history">
          <button className="btn__history btn__back" onClick={handleClickZkBackBtn} />
          <button className="btn__history btn__close" onClick={handleClickZkCloseBtn} />
          <Typography
            className="tit__popup--check"
            type={TypographyType.H4}
            color={ColorPaletteType.BLUISHGRAY4}
            text="오늘 출결 내역"
          />
          <Typography
            className="txt__popup--date"
            type={TypographyType.B2}
            color={ColorPaletteType.BLUISHGRAY2}
            text={moment().format('YY.MM.DD')}
          />
          <div className="header__table">
            <span className="tit__table">분류</span>
            <span className="tit__table">내용</span>
            <span className="tit__table">시간</span>
          </div>
          <div className="body__table">
            {checkInHistoryData?.items.map((history, idx) => {
              return (
                <div className="item__table" key={`item__table${idx}`}>
                  <span
                    className={
                      history.category === CheckInHistoryCategory.CHECKOUT_BATCH || idx === 0
                        ? 'txt__table txt__table--blue'
                        : 'txt__table'
                    }
                  >
                    {history.category === CheckInHistoryCategory.CHECKIN ||
                    history.category === CheckInHistoryCategory.CHECKIN_BY_ADMIN
                      ? '입실'
                      : '퇴실'}
                  </span>
                  <span
                    className={
                      history.category === CheckInHistoryCategory.CHECKOUT_BATCH
                        ? 'txt__table txt__table--batch'
                        : 'txt__table'
                    }
                  >
                    {history.category === CheckInHistoryCategory.CHECKOUT_BATCH ? '일괄' : '-'}
                  </span>
                  <span className="txt__table">{moment(history.revisionedAt).format('h:mm A')}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </AttendanceCheckerWrapper>
  ) : (
    <></>
  );
};
export default AttendanceChecker;
