// 2023.2.15 선발 방식 변경에 따른 임시 대응
// 임시 대응 적용 코스

export const ROLLING_BASE_PRODUCT_SLUG_URLS = [
  'SEBFE-45',
  'SEBBE-45',
  'AIB-19',
  'GMB-14',
  'UUB-1',
  'PMB-19',
  'SEBFE-46',
  'SEBBE-46',
  'DOB-5',
  'BEB-10',
  'BE-46',
  'FE-46'
];

export const GO_TO_FIRST_STEP_SLUG_URLS = [
  'SEBFE-45',
  'SEBFE-46',
  'SEBFE-47',
  'SEBBE-45',
  'SEBBE-46',
  'SEBBE-47',
  'AIB-19',
  'AIB-20',
  'AIB-21',
  'PMB-18',
  'PMB-19',
  'PMB-20',
  'kbpmpo-1'
];
