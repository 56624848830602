import styled from '@emotion/styled';

interface ProgressBarProps {
  surveyProgress: {
    total: number | null;
    current: number | null;
    percent: number | null;
  };
}

const ProgressBar = ({ surveyProgress }: ProgressBarProps) => {
  return (
    <ProgressBarBox percent={surveyProgress.percent}>
      <div className="progress-header">
        <span className="progress-title">질문지 진행도</span>
        <span className="progress-number">{`${surveyProgress.current}/${surveyProgress.total}`}</span>
      </div>
      <div className="progress-graph-track">
        <div className="progress-bar" />
      </div>
    </ProgressBarBox>
  );
};

export default ProgressBar;

const ProgressBarBox = styled.div<{ percent: number | null }>`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  z-index: 10;
  padding: 8px 16px;
  min-width: 226px;

  .progress-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .progress-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.025em;
      color: #262f40;
      margin-right: 4px;
    }

    .progress-number {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.025em;
      color: #452cdd;
    }
  }

  .progress-graph-track {
    margin: 4px 0;
    width: calc(100% - 32px;);
    height: 6px;
    background: #f0f0f0;
    border-radius: 3px;

    .progress-bar {
      width: ${({ percent }) => percent && `${percent}%`};
      background: #452cdd;
      border-radius: 3px;
      height: 100%;
    }
  }

  @media screen and (max-width: 992px) {
    min-width: 174px;
    padding: 8px 12px;

    .progress-header {
      margin-bottom: 6px;

      .progress-title {
        font-size: 12px;
        line-height: 18px;
      }

      .progress-number {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .progress-graph-track {
      width: calc(100% - 24px;);
      height: 6px;

      .progress-bar {
        width: ${({ percent }) => percent && `${percent}%`};
        background: #452cdd;
      }
    }
  }
`;
