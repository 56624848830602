import { useEffect, useState } from 'react';

import { makeToast } from './../../util/makeToast';

import Urls from '../../util/urls';
import { Modal } from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { AxiosError, AxiosResponse } from 'axios';
import { useQuery as apolloUseQuery, useMutation as apolloUseMutation } from '@apollo/react-hooks';

import { useMutation, useQuery } from '@tanstack/react-query';

import * as kp from '../../util/marketingTools/kakaoPixel';
import { googleAdsConversion } from '../../util/marketingTools/googleAdsConversion';
import { ApplicationAnswerStatusType } from '../detailApplication/type';
import { PRODUCT_BUNDLE_GQL } from '../../apollo/product/query';

import { dataLayerForApplyEvent } from '../../util/marketingTools/datalayerGa4';

import { detectDevice } from '../../util/detectDevice/detectDevice';
import { sendPageView } from '../../util/marketingTools/googleTagAssistant';
import { parseSlugUrl, store } from '../../util/';
import queryKeys from '../../query/queryKeys';

import { APPLICATIONS_WITH_PRODUCT_UUID_GQL as APPLICATIONS_GQL } from '../../apollo/application/query';
import type { ProductBundleData, ProductBundleVars, PaymentModel, Product } from '../../apollo/product/type';
import { Application, ApplyServiceType } from '../../apollo/mypage/type';

import { APPLY_GQL } from '../../apollo/product/mutation';
import { ApplyData, ApplyVars, PaymentType } from '../../apollo/product/type';

import { fbqTrack, generateEventId } from '../../util/marketingTools/fbq';
import TagManager from 'react-gtm-module';

import { sleep } from '../../util/sleep';
import { useCredential } from '../../contexts/credential';
import {
  changeApplicationState,
  checkApplicationAnswersSubmitted,
  getApplicationData,
  getSlugUrlRequest,
  ReviveApplicationByUserUuidAndApplicationUuidRequest
} from '../apply/request';

import { GO_TO_FIRST_STEP_SLUG_URLS, ROLLING_BASE_PRODUCT_SLUG_URLS } from '../../util/constant';
export const ApplyErrorCode = {
  ALREADY_APPLIED_ANOTHER_PRODUCT: 'already-applied-another-product',
  ALREADY_APPLIED: 'already-applied',
  INVALID_APPLY_DURATION: 'invalid-apply-duration',
  WRONG_PAYMENT_MODEL: 'wrong-payment-model',
  NO_APPLICATION: 'no-application',
  CLOSED_APPLICATION: 'Application is closed'
} as const;
export const SEB_PROGRAM_ID = 1;
export const GMB_PROGRAM_ID = 2;
export const DS_PROGRAM_ID = 3;
export const PMB_PROGRAM_ID = 4;
export const DA_PROGRAM_ID = 5;
export const AIB_PROGRAM_ID = 6;
export const KDT_AIB_PROGRAM_ID = 6;
export const KDT_BEB_PROGRAM_ID = 7;
export const KDT_PMB_PROGRAM_ID = 8;
export const KDT_GMB_PROGRAM_ID = 9;
export const TAJAN_SCHOOL_PROGRAM_ID = 10;
export const KDT_DEV_OPS_PROGRAM_ID = 11;
export const KDT_SEB_FE_PROGRAM_ID = 16;
export const KDT_SEB_BE_PROGRAM_ID = 17;
export const KDT_UUB_PROGRAM_ID = 27;

const convertProductToAdsProgramType = (product: Product) => {
  const program = product.program;

  switch (program.id) {
    // SEB
    case SEB_PROGRAM_ID:
      return 'web';

    // GMB
    case GMB_PROGRAM_ID:
      return 'growth';

    // AIB - DS
    case DS_PROGRAM_ID:
      return 'Ds';

    // PMB
    case PMB_PROGRAM_ID:
      return 'Pm';

    // DA
    case DA_PROGRAM_ID:
      return 'da';

    default:
      return program.title;
  }
};

export const useController = () => {
  const params = useParams<{ uuid: string }>();
  const location = useLocation();

  const urlQueries = location.search;
  const history = useHistory();

  const { mutateAsync: reviveApplication } = useMutation<
    AxiosResponse,
    AxiosError,
    ReviveApplicationByUserUuidAndApplicationUuidRequest,
    unknown
  >(variables => changeApplicationState(variables));

  const { profile } = useCredential();

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const [matchedApplication, setMatchedApplication] = useState<Application>();
  const [matchedProduct, setMatchedProduct] = useState<Product>();

  const [course, setCourse] = useState('');
  const queryParams = {
    getSlugUrlByUuidParams: {
      productUuid: matchedProduct?.uuid!
    },
    getReviveByUuidParams: {
      productUuid: matchedProduct?.uuid,
      userUuid: profile?.uuid || ''
    }
  };

  const { data: slugUrlData } = useQuery(
    queryKeys.APPLY_PRODUCT_SLUG_URL_BY_UUID(queryParams.getSlugUrlByUuidParams),
    () => getSlugUrlRequest(queryParams.getSlugUrlByUuidParams),
    {
      enabled: selectedProduct?.applyServiceType === ApplyServiceType.ADMISSION,
      onSuccess: slugUrlData => {
        const _course = parseSlugUrl(slugUrlData?.slugUrl).course;
        setCourse(_course);
      }
    }
  );

  const { data: productBundleData, error: productBundleError } = apolloUseQuery<ProductBundleData, ProductBundleVars>(
    PRODUCT_BUNDLE_GQL,
    {
      variables: { uuid: params.uuid }
    }
  );

  const [apply] = apolloUseMutation<ApplyData, ApplyVars>(APPLY_GQL, {
    onCompleted: data => {
      const { id: applicationId, product, user } = data.urclassApply;
      TagManager.dataLayer({
        dataLayer: {
          event: `apply_course_urclass`,
          // TODO : slugURL 기능개발 되면 추가작업, 그전까진 상품명을 보낸다.
          courseName: product.name,
          UUID: user.uuid,
          applyId: applicationId,
          device: detectDevice()
        }
      });
    }
  });

  const handleApplyError = (e: unknown) => {
    const error = e as Error;
    const errorCode = error.message;
    switch (errorCode) {
      case ApplyErrorCode.ALREADY_APPLIED_ANOTHER_PRODUCT:
        return Modal.confirm({
          title: '이미 지원 중인 과정이 있습니다.',
          content: '마이페이지에서 지원 중인 과정을 취소한 후 다시 지원해주세요 .',
          okText: '마이페이지 가기',
          onOk: () => {
            history.push('/mypage');
          },
          cancelText: '닫기'
        });

      case ApplyErrorCode.ALREADY_APPLIED:
        if (matchedApplication) {
          try {
            makeToast({ type: 'success', content: '이미 진행중인 단계가 있습니다. 확인해 주세요.' });
            history.push(`/applications/${slugUrlData?.slugUrl}/list/${matchedApplication?.uuid}${urlQueries}`);
          } catch (err) {
            console.log(err);
          }
        } else {
          makeToast({ type: 'error', content: '연결된 지원서를 찾을 수 없습니다.' });
        }
        return;

      default:
        makeToast({ type: 'error', content: '결제진행에 문제가 있습니다.' });
    }
  };

  const { data: applicationsData } = apolloUseQuery<{
    urclassMyApplications: Application[];
  }>(APPLICATIONS_GQL);

  const admissionToken = applicationsData?.urclassMyApplications[0]?.admissionApplierToken;

  /* useEffect */

  useEffect(() => {
    if (!selectedProduct) return;
    sendPageView(`apply/${selectedProduct.name}`);
  }, [selectedProduct]);

  useEffect(() => {
    fbqTrack('InitiateCheckout');
  }, []);

  useEffect(() => {
    if (applicationsData && productBundleData) {
      const { urclassMyApplications } = applicationsData;
      const matchedMyApplication = urclassMyApplications.find(app =>
        productBundleData.urclassProductBundle.products.some(product => product.uuid === app.product.uuid)
      );
      const matchedApplyingProduct = productBundleData.urclassProductBundle.products.find(product =>
        urclassMyApplications.some(app => app.product.uuid === product.uuid)
      );

      if (matchedApplyingProduct) {
        setMatchedProduct(matchedApplyingProduct);
      }
      setMatchedApplication(matchedMyApplication);
    }
  }, [applicationsData, productBundleData]);

  const checkApplicationStateChange = async () => {
    if (admissionToken && profile && selectedProduct) {
      const userUuid = profile.uuid;
      const productUuid = selectedProduct.uuid;

      try {
        const applicationData = await getApplicationData({ userUuid, productUuid });
        const isCancelledApplication =
          applicationData && applicationData.applyingStatus === ApplicationAnswerStatusType.CANCELLED;

        if (isCancelledApplication && applicationData.uuid) {
          // 지원 취소 내역이 있는 상품 재지원 시, 기존 지원서 부활(타입폼에선 적용안됨)
          handleReviveApplication(applicationData.uuid);
          return true;
        }
      } catch (error) {
        console.log(error);
      }
    }

    return false;
  };
  const handleReviveApplication = async (applicationUuid: string) => {
    if (!slugUrlData) return;
    const modalContent = `${
      ROLLING_BASE_PRODUCT_SLUG_URLS.includes(slugUrlData.slugUrl || '')
        ? '취소한 지원서를 복구합니다.'
        : '이어서 지원서 작성을 진행하시겠어요?'
    }`;

    const modalOkText = `${
      ROLLING_BASE_PRODUCT_SLUG_URLS.includes(slugUrlData.slugUrl || '') ? '확인' : '지원서 작성'
    }`;

    const isApplicationAnswersSubmitted = await checkApplicationAnswersSubmitted(profile?.uuid, applicationUuid);

    return Modal.warning({
      title: '해당 코스에 지원 취소한 기록이 있어요.',
      content: modalContent,
      okText: modalOkText,
      onOk: async () => {
        try {
          const isRollingBaseProduct = ROLLING_BASE_PRODUCT_SLUG_URLS.includes(slugUrlData.slugUrl || '');

          const { data: reviveApplicationData } = await reviveApplication({
            applicationUuid,
            userUuid: profile?.uuid || '',
            changeTo: isApplicationAnswersSubmitted ? 'COMPLETED' : 'IN_PROGRESS'
          });
          if (reviveApplicationData) {
            await sleep(1500);
            const pathname = isRollingBaseProduct
              ? '/mypage'
              : `/applications/${slugUrlData.slugUrl}/list/${applicationUuid}`;
            history.push({
              pathname: `${pathname}`,
              state: { revive: true }
            });
          }
        } catch (err) {
          const { response } = err as unknown as AxiosError;

          if (
            response &&
            response.data &&
            response.data.message &&
            response.data.message === '접수중인 지원서가 이미 존재합니다.'
          ) {
            handleApplyError({ message: ApplyErrorCode.ALREADY_APPLIED_ANOTHER_PRODUCT });
          } else {
            handleApplyError({ message: 'default' });
          }
        }
      }
    });
  };
  const handleSuccessApply = (paymentType: PaymentType, applicationUuid: string) => {
    // 유입 미디어 로컬 스토리지 제거
    store.adsMediaType.remove();
    store.applyUuid.remove();
    store.applyProductName.remove();

    if (paymentType === PaymentType.B2B) {
      history.push({
        pathname: `/apply/result-b2b/${selectedProduct?.uuid}`,
        state: { applicationUuid }
      });
      return;
    }

    history.push({
      pathname: `/apply/result/${selectedProduct?.uuid}`,
      state: { applicationUuid }
    });
  };

  const handlePaymentFormSubmit = async () => {
    const mediaType = store.adsMediaType.get() ?? 'DIRECT';

    const isAlreadyApplied = applicationsData?.urclassMyApplications.some(
      application =>
        productBundleData?.urclassProductBundle.products.some(
          product => application.product.uuid === product?.uuid && application.applyingStatus !== 'CANCELLED'
        )
    );

    if (isAlreadyApplied) {
      console.log('hlhlhlhlh');
      handleApplyError({ message: ApplyErrorCode.ALREADY_APPLIED });
      return;
    }
    console.log('hlhlhlhlh2');

    const hasApplicationRevived = await checkApplicationStateChange();
    if (hasApplicationRevived) return;

    if (selectedProduct) {
      const eventId = generateEventId();

      try {
        const { data } = await apply({
          variables: {
            eventId,
            productId: selectedProduct.id,
            paymentType: selectedProduct.paymentModels[0].paymentType,
            mediaType
          }
        });
        const adsProgramType = convertProductToAdsProgramType(selectedProduct);
        googleAdsConversion(Urls().urclass, adsProgramType);

        const kpTag =
          selectedProduct.program.id === 1
            ? 'SEB Apply'
            : selectedProduct.program.id === 2
            ? 'GMB Apply'
            : selectedProduct.program.id === 4
            ? 'PMB Apply'
            : '';

        kp.purchase(kpTag);

        fbqTrack('Purchase', { currency: 'KRW', value: 1.0, content_name: course }, eventId);

        dataLayerForApplyEvent({
          data: data?.urclassApply,
          slugUrl: slugUrlData?.slugUrl,
          urlQueries: window.location.search
        });

        handleSuccessApply(selectedProduct.paymentModels[0].paymentType!, data?.urclassApply.uuid!);
      } catch (err) {
        handleApplyError(err);
      }
    }
  };

  const handleChangeSelectedPaymentModel = (paymentModel: PaymentModel) => {
    if (productBundleData) {
      try {
        const product = productBundleData?.urclassProductBundle.products.find(
          product => product.paymentModels[0].id === paymentModel.id
        );

        if (!product) {
          throw new Error();
        }
        setSelectedProduct(product);
      } catch (e) {}
    }
  };

  useEffect(() => {
    if (productBundleData) {
      setSelectedProduct(productBundleData.urclassProductBundle.products[0]);
      store.serviceStartDate.set(productBundleData.urclassProductBundle.products[0].serviceStartDate);
      store.applyProductName.set(productBundleData.urclassProductBundle.products[0].name);
    }
  }, [productBundleData]);

  return {
    product: productBundleData?.urclassProductBundle.products[0],
    productBundleName: productBundleData?.urclassProductBundle.name,
    handlePaymentFormSubmit,
    handleChangeSelectedPaymentModel,
    selectedPaymentModel: selectedProduct?.paymentModels[0],
    paymentModels:
      productBundleData?.urclassProductBundle.products.map(product => product.paymentModels[0]).filter(Boolean) || [],
    productBundleError
  };
};
