import moment from 'moment';
type DayType = {
  date: number | undefined;
  day?: string | undefined;
  sunday: boolean;
  saturday: boolean;
  isValidDay: boolean;
};

export const makeCalendar = (year: number, month: number, startDate: string, endDate: string) => {
  const days: DayType[] = [];
  const calendar = {
    year,
    month,
    days
  };
  const totalDays = moment(`${year}-${month + 1}`, 'YYYY-MM-DD').daysInMonth();
  let day = Number(moment(`${year}-${month + 1}-1`, 'YYYY-MM-DD').format('E'));

  for (let i = 1; i <= totalDays; i++) {
    if (day === 6) {
      days.push({
        date: i,
        day: weekdayToKoreanString(day),
        saturday: true,
        sunday: false,
        isValidDay: moment(`${year}-${month + 1}-${i}`, 'YYYY-MM-DD').isBetween(
          moment(startDate, 'YYYY-MM-DD').subtract(1, 'day'),
          moment(endDate, 'YYYY-MM-DD').add(1, 'day')
        )
      });
    } else if (day === 7) {
      days.push({
        date: i,
        day: weekdayToKoreanString(day),
        saturday: false,
        sunday: true,
        isValidDay: moment(`${year}-${month + 1}-${i}`, 'YYYY-MM-DD').isBetween(
          moment(startDate, 'YYYY-MM-DD').subtract(1, 'day'),
          moment(endDate, 'YYYY-MM-DD').add(1, 'day')
        )
      });
    } else {
      days.push({
        date: i,
        day: weekdayToKoreanString(day),
        saturday: false,
        sunday: false,
        isValidDay: moment(`${year}-${month + 1}-${i}`, 'YYYY-MM-DD').isBetween(
          moment(startDate, 'YYYY-MM-DD').subtract(1, 'day'),
          moment(endDate, 'YYYY-MM-DD').add(1, 'day')
        )
      });
    }

    day = day + 1;
    if (day > 7) {
      day = 1;
    }
  }

  if (totalDays < 31) {
    for (let i = 0; i < 31 - totalDays; i++) {
      days.push({ date: undefined, day: undefined, saturday: false, sunday: false, isValidDay: false });
    }
  }

  calendar.days = days;
  return calendar;
};

const weekdayToKoreanString = (weekday: number) => {
  if (weekday === 1) {
    return '월';
  }
  if (weekday === 2) {
    return '화';
  }
  if (weekday === 3) {
    return '수';
  }
  if (weekday === 4) {
    return '목';
  }
  if (weekday === 5) {
    return '금';
  }
  if (weekday === 6) {
    return '토';
  }
  if (weekday === 7) {
    return '일';
  }
};

export const makeCalendars = (startDate: Date, endDate: Date) => {
  const startDateToString = moment(startDate).format('YYYY-MM-DD');
  const endDateToString = moment(endDate).format('YYYY-MM-DD');
  const yearGap = moment(endDateToString, 'YYYY-MM-DD').year() - moment(startDateToString).year();
  const monthGap = moment(endDateToString, 'YYYY-MM-DD').month() - moment(startDateToString).month() + yearGap * 12;
  const calendars = [];
  let year =
    yearGap > 0 ? moment(endDateToString, 'YYYY-MM-DD').year() - 1 : moment(endDateToString, 'YYYY-MM-DD').year();
  let month = moment(startDateToString, 'YYYY-MM-DD').month();

  for (let i = 0; i <= monthGap; i++) {
    if (month > 11) {
      month = 0;
      year++;
    }
    calendars.push(makeCalendar(year, month, startDateToString, endDateToString));

    month++;
  }
  return calendars;
};
