import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { ColorPalette, ColorPaletteType } from '../../styles/color';
import { Typography, TypographyType } from '../../components/atoms/Typography';
import ApplicationStepListItem from './components/ApplicationStepListItem';
import { ApplicationFullResponse, TemplateItemFullResponse } from '../../rest/generated';
import { calculateActiveStepIndex } from '../../util/admission';
import moment from 'moment';
import { ReactComponent as EditIcon } from '../../assets/svg/ico-edit.svg';
import { ROLLING_BASE_PRODUCT_SLUG_URLS } from '../../util/constant';

type Props = {
  selectedAdmissionApplication: ApplicationFullResponse;
  applicationUuid: string;
  programId?: number;
};

const AdmissionApplicationSteps = ({ applicationUuid, selectedAdmissionApplication, programId }: Props) => {
  const { template, answers, applyingStatus } = selectedAdmissionApplication;
  const { product, slugUrl, items } = template;
  const { applyEndAt } = product;
  const isSoftskillCourse = slugUrl.includes('softskill');

  // 지원 마감 정보 표기
  const deadline = moment(applyEndAt).diff(new Date(), 'days');
  const isApplying = moment(applyEndAt).isAfter(new Date());
  const isDisplayDeadlineText = !isNaN(deadline);

  const itemSequence = [...items].sort((a, b) => a.sequence - b.sequence).map(item => item.id);
  const sortedItems = [...items].sort((a, b) => a.sequence - b.sequence);
  const sortedAnswers = [...answers].sort((a, b) => itemSequence.indexOf(a.itemId) - itemSequence.indexOf(b.itemId));

  const activeStepIndex = calculateActiveStepIndex(sortedAnswers, items);

  const isRollingBaseProduct = ROLLING_BASE_PRODUCT_SLUG_URLS.includes(
    selectedAdmissionApplication.template.slugUrl || ''
  );
  const isSubmitted = applyingStatus === 'COMPLETED';
  const isCDSProgram = programId === 33;
  const isLevel1VODProgram = programId === 35;
  const isResumeUploadProgram = programId === 37;

  return (
    <Wrapper className="ga-urclass-applications-page">
      <Typography
        className="ga-urclass-applications-courseName"
        type={TypographyType.H2}
        color={ColorPaletteType.GRAY1}
        text={product.name}
      />
      {programId === 33 ? (
        <div></div>
      ) : (
        <>
          {isApplying && isDisplayDeadlineText && !isSoftskillCourse && !isResumeUploadProgram ? (
            <div className="application-step-deadline__text">
              <p>
                {'지원 마감일까지 '}
                <strong>{deadline.toString()}</strong>일 남았습니다.
              </p>
            </div>
          ) : (
            <></>
          )}

          {isSoftskillCourse || isLevel1VODProgram || isResumeUploadProgram || (
            <>
              <div className="application-step-editable-info__text">
                <p>
                  수정 버튼(<EditIcon width={14} height={14} />)이 있는 지원단계는 버튼을 눌러 수정할 수 있습니다.
                </p>
              </div>
            </>
          )}
        </>
      )}
      <div className="application-step-list__group">
        {sortedItems.map((item: TemplateItemFullResponse, idx: number, items: TemplateItemFullResponse[]) => {
          if (item.survey)
            return (
              <ApplicationStepListItem
                item={item}
                answer={sortedAnswers.find(answer => answer.itemId === item.id)}
                key={item.id}
                idx={idx}
                applicationUuid={applicationUuid}
                slugUrl={slugUrl}
                isActive={activeStepIndex === idx}
                isFinal={items.length === idx + 1}
                isApplying={isApplying}
                isRollingBaseProduct={isRollingBaseProduct}
                isCDSProgram={isCDSProgram}
                isSubmitted={isSubmitted}
              />
            );
        })}
      </div>
    </Wrapper>
  );
};

export default AdmissionApplicationSteps;

const bounce = keyframes`
  0%, 66.6%, 100% {
    transform: translate(-50%, calc(-100% - 4px));
  }

  33.3% {
    transform: translate(-50%, -100%);
  }
`;

const Wrapper = styled.div`
  margin: auto;

  .inline {
    display: inline-block;
  }

  .application-step-deadline__text {
    margin-top: 21px;

    p {
      margin-bottom: 4px;
    }
  }

  .application-step-editable-info__text {
    margin-bottom: 14px;

    svg {
      margin: 0 2px;
    }
  }

  .application-step-notification__text {
    color: ${ColorPalette.Peach};
    margin: -10px 0 35px;
  }

  .application-step-list-notification__text--temp {
    margin-top: 30px;
    text-align: center;
  }

  .application-step-link {
    height: 58px;

    margin: 24px 0px 10px 0px;
    padding: 13px 13px 13px 33px;

    border-radius: 8px;
    border: solid 1px ${ColorPalette.Gray4};

    .application-step-link__text {
      display: inline-block;
      margin-top: 8px;
    }

    .application-step-link__button {
      display: inline-block;

      float: right;
      vertical-align: middle;
    }
  }

  .application-step-list__group {
    margin-top: 14px;

    .application-step-list__item {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      margin: 10px 0px 10px 0px;
      padding: 11px 13px 13px 33px;

      border-radius: 8px;
      border: solid 1px ${ColorPalette.Gray4};

      .application-step-list__text {
        display: flex;
        align-items: center;
      }

      .application-step-list__button-wrapper {
        display: flex;
        align-items: center;
        position: relative;

        .application-step-list__button {
          display: inline-block;
          background: ${ColorPalette.Gray5};
        }
      }
    }

    .application-step-list__item.submit,
    .application-step-list__item.edit {
      background: ${ColorPalette.Gray5};
    }

    .application-step-list__item.active,
    .application-step-list__item.in-progress {
      border: solid 2px ${ColorPalette.Gray1};
      font-weight: 700;

      .application-step-list__button {
        border: none;
        background: ${ColorPalette.Gray1} !important;
        font-weight: 400;

        div {
          color: ${ColorPalette.White} !important;
        }
      }
    }
  }

  .application-step-list__edit-button {
    width: 22px;
    height: 22px;
    border-radius: 3px;
    margin-left: 5px;

    display: none;
    align-items: center;
    justify-content: center;

    background-color: transparent;
    color: ${ColorPalette.Gray1};
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;

    &.edit {
      display: flex;
    }

    &:hover {
      background-color: ${ColorPalette.Gray4};
    }
  }

  .application-step-list__tooltip {
    height: 45px;

    display: none;
    position: absolute;
    top: 6px;
    left: 50%;
    animation-name: ${bounce};
    animation-duration: 1500ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    > p {
      width: 100%;
      position: absolute;
      z-index: 1;
      top: 10.5px;

      text-align: center;
      font-size: 11px;
      line-height: 18px;
      color: ${ColorPalette.CozBlue600};
    }

    &.active {
      display: block;
    }
  }

  @media screen and (max-width: 992px) {
    margin-top: 59px;
    padding: 0px;
  }
`;
